import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "../../../atom/Table/Table";
import { Pagination } from "../../../atom/Pagination/Pagination";
import { getPatientDiagnosticHistoryDetails } from "../../../../services/carePlan/carePlan";
import { showToastMessage } from "../../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../../constants/variables";

const tableHeaders = {
  healthConcernDate: "Date",
  healthConcern: "Health Concern",
  note: "Note",
};

const sortingHeaderMap: Record<string, string> = {
  healthConcernDate: "healthConcernDate",
  healthConcern: "HealthConcern",
  note: "Note",
};

export function DiagnosisHistory() {
  const activePatient = useSelector((state: any) => state.activePatient);
  const carePlanDetails = useSelector((state: any) => state.activeCarePlan);

  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });

  const fetchData = async (newPaginationData?: any) => {
    try {
      const paginationDataAsParameter = newPaginationData || paginationData;
      const patientId = activePatient.patientDetails.id;

      if (patientId !== "") {
        const appAppointmentListResponse =
          await getPatientDiagnosticHistoryDetails({
            paginationDataAsParameter,
            patientId,
            carePlanId: carePlanDetails.carePlanDetails.carePlanId,
          });
        if (appAppointmentListResponse.status) {
          setPaginationData({
            ...paginationData,
            pageNo: appAppointmentListResponse.data.pageNo,
            totalPages: appAppointmentListResponse.data.totalPages,
            totalElements: appAppointmentListResponse.data.totalElements,
            pageSize: appAppointmentListResponse.data.pageSize,
          });
          setTableData(appAppointmentListResponse.data.dataList);
        }
      }
    } catch (error) {
      showToastMessage("Error fetching all users:", TOAST_TYPE_OPTIONS.ERROR);
      console.error("Error fetching all users:", error);
    }
  };

  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    fetchData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };

  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize,
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    fetchData({ ...paginationData, pageNo, pageSize });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="accordion-collapse">
      <div className="table-responsive care-manager-table">
        <Table
          headers={tableHeaders}
          data={tableData}
          className="table table-bordered table-striped table-hover"
          sortingFunction={sortingFunction}
          sortingHeaderMap={sortingHeaderMap}
        />
        {paginationData.totalPages >= 0 && (
          <Pagination
            currentPage={paginationData.pageNo}
            totalPages={paginationData.totalPages}
            pageSize={paginationData.pageSize}
            onPageChange={(page, pageSize) => {
              updatePaginationData(page, pageSize);
            }}
          />
        )}
      </div>
    </div>
  );
}
