import React, { useState, ChangeEvent, useEffect } from "react";
import { useSelector } from "react-redux";
import { Input } from "../../atom/Input/Input";
import { Image } from "../../atom/Images/Image";

import { Button } from "../../atom/Buttons/Button";
import {
  FILE_SIZE_MAX,
  TOAST_TYPE_OPTIONS,
} from "../../../constants/variables";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../services/utils/validateDataAsPerRules";
import { formatDate } from "../../../services/utils/formatDate";
import { allKeysEmpty } from "../../../services/utils/allKeyEmpty";
import {
  CreateDiagnosticResultRecord,
  createDiagnosticResult,
  updateDiagnosticResult,
} from "../../../services/labAndDiagnosticResult/diagnosticResult";
import { SearchDropdown } from "../../atom/SearchDropdown/SearchDropdown";
import { getSearchForReferralIcd10Values } from "../../../services/referrals/searchReferralFilter";
import { getIcd10InfoService } from "../../../services/referrals/referrals";
import Logger from "../../../utils/Logger";
import { Textarea } from "../../atom/Textarea/Textarea";
import { FileField } from "../../atom/FileField/FileField";

export interface CreateDiagnosticResultProps {
  editData: any;
  onSubmitAction: () => void;
}

export function CreateDiagnosticResult({
  editData,
  onSubmitAction,
}: CreateDiagnosticResultProps) {
  const activePatient = useSelector((state: any) => state.activePatient);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [uploadFile, setUploadFile] = useState<File | undefined>();
  const [filesFromResponse, setFilesFromResponse] = useState<
    string | undefined
  >();
  const [editFlag, setEditFlag] = useState(false);
  const [icd10, setIcd10] = useState([]);
  const [displayValueForIcd10, setDisplayValueForIcd10] = useState("");
  const [formData, setFormData] = useState({
    documentName: "",
    diagnosticResult: "",
    diagnosedDate: "",
    providerName: "",
    comment: "",
    diagnosticResultPath: "",
  });
  const [errors, setErrors] = useState<any>({
    documentName: "",
    diagnosticResult: "",
    diagnosedDate: "",
    providerName: "",
    comment: "",
    diagnosticResultPath: "",
  });
  const validationRules: any = {
    documentName: {
      required: false,
      dataType: "text",
      characterLimit: 50,
      displayName: "Document Name",
    },
    diagnosticResult: {
      required: true,
      dataType: "number",
      characterLimit: 50,
      displayName: "Diagnostic Type",
    },
    diagnosedDate: {
      required: true,
      dataType: "date",
      characterLimit: 250,
      isPastDateAllow: true,
      isFutureDateAllow: true,
      displayName: "Diagnosed Date",
    },
    providerName: {
      required: false,
      dataType: "text",
      characterLimit: 50,
      displayName: "Provider Name",
    },
    comment: {
      required: false,
      dataType: "any",
      characterLimit: 1000,
      displayName: "Comment",
    },
    diagnosticResultPath: {
      required: true,
      dataType: "any",
      characterLimit: 500,
      displayName: "Upload Document",
    },
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    const newErrors = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: name,
      fieldName: name,
      value,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: newErrors,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleReset = () => {
    setSelectedImage(null);
    setFilesFromResponse(undefined);
  };
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setUploadFile(file);
    if (file) {
      const fileName = file.name;
      const fileType = file.type;
      const fileSize = file.size;
      if (fileSize > FILE_SIZE_MAX) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          fileSlide1: "The selected file exceeds 10 MB",
          diagnosticResultPath: "",
        }));
      } else {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          fileSlide1: "",
          diagnosticResultPath: "",
        }));
      }
      if (fileName) {
        formData.diagnosticResultPath = fileName;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        if (fileType === "application/pdf") {
          handleReset();
          setFilesFromResponse(URL.createObjectURL(file));
        } else if (fileType === "image/jpeg") {
          handleReset();
          setSelectedImage(base64String);
        } else if (fileType === "image/png") {
          handleReset();
          setSelectedImage(base64String);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleClear = () => {
    setDisplayValueForIcd10("");
    setFormData({
      documentName: "",
      diagnosticResult: "",
      diagnosedDate: "",
      providerName: "",
      comment: "",
      diagnosticResultPath: "",
    });
    setErrors({
      documentName: "",
      diagnosticResult: "",
      diagnosedDate: "",
      providerName: "",
      comment: "",
      diagnosticResultPath: "",
    });
    handleReset();
    setUploadFile(undefined);
    setEditFlag(false);
  };

  const handleSubmit = async () => {
    if (activePatient?.patientDetails?.id === "") {
      showToastMessage("Please select patient", TOAST_TYPE_OPTIONS.ERROR);
      return;
    }
    const formErrors = validateDataAsPerRules({
      formData,
      rules: validationRules,
    });
    setErrors({
      ...errors,
      ...formErrors,
    });
    if (Object.keys(formErrors).length > 0) {
      showToastMessage("Please fill correct value", TOAST_TYPE_OPTIONS.ERROR);
      return;
    }
    const { diagnosedDate, ...tempFormData } = formData;
    const isEmpty = allKeysEmpty(tempFormData);
    if (isEmpty) {
      showToastMessage(
        "Please fill at least one field",
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }
    const requestObject: CreateDiagnosticResultRecord = {
      patientId: activePatient.patientDetails.id,
      patientName:
        activePatient.patientDetails.firstName +
        activePatient.patientDetails.middleName +
        activePatient.patientDetails.lastName,
      uploadFile,
      ...formData,
    };
    let createLabResultResponse: {
      status?: boolean;
      message?: string;
      data?: any;
    } = {};
    if (editFlag) {
      createLabResultResponse = await updateDiagnosticResult(
        requestObject,
        editData.id
      );
    } else {
      createLabResultResponse = await createDiagnosticResult(requestObject);
      setEditFlag(false);
    }
    if (createLabResultResponse.status) {
      showToastMessage(
        createLabResultResponse.message,
        TOAST_TYPE_OPTIONS.SUCCESS
      );
      onSubmitAction();
      handleClear();
    } else {
      showToastMessage(
        createLabResultResponse.message,
        TOAST_TYPE_OPTIONS.ERROR
      );
    }
  };

  const updateReferredIcd10List = async (value: string) => {
    const referredIcd10Values = await getSearchForReferralIcd10Values(value);
    setIcd10(referredIcd10Values);
  };

  const checkForUpdateData = async () => {
    if (editData?.id && editData?.id !== "") {
      setEditFlag(true);
      setFormData({
        documentName: editData.documentName,
        diagnosticResult: editData.diagnosticResult,
        diagnosedDate: formatDate(editData.diagnosedDate, "YYYY-MM-DD"),
        providerName: editData.providerName,
        comment: editData.comment,
        diagnosticResultPath: editData.diagnosticResultPath,
      });
      const getIcd10Details = await getIcd10InfoService(
        editData?.diagnosticResult
      );
      if (getIcd10Details.status) {
        setDisplayValueForIcd10(
          `${getIcd10Details.data.code} ${getIcd10Details.data.shortDescription}`
        );
      }
      Logger.logInfo("Edit Diagnostic Result", { data: editData });
      if (editData?.images) {
        if (editData?.diagnosticResultPath.endsWith(".pdf")) {
          setFilesFromResponse(editData?.images?.loadFileOrImg);
        } else {
          setSelectedImage(editData?.images?.loadFileOrImg);
        }
      }
    }
  };
  useEffect(() => {
    checkForUpdateData();
  }, [editData]);

  return (
    <div className="row g-3">
      <div className="col-lg-6 col-sm-6">
        <div>
          {selectedImage && (
            <Image
              src={selectedImage}
              alt={formData.diagnosticResultPath}
              title={formData.diagnosticResultPath}
              width="100%"
            />
          )}
        </div>
        <div>
          {filesFromResponse && (
            <embed
              src={filesFromResponse}
              width="100%"
              height="500"
              type="application/pdf"
            />
          )}
        </div>
      </div>
      <div className="col-lg-6 col-sm-6">
        <form>
          <div>
            <Input
              type="text"
              id="documentName"
              name="documentName"
              value={formData.documentName}
              onChange={handleChange}
              placeholder={validationRules.documentName.displayName}
              className="form-control form-control-sm"
              label={validationRules.documentName.displayName}
              error={errors.documentName}
            />
          </div>

          <div>
            <Input
              type="date"
              id="diagnosedDate"
              name="diagnosedDate"
              value={formData.diagnosedDate}
              onChange={handleChange}
              placeholder={validationRules.diagnosedDate.displayName}
              className="form-control form-control-sm"
              label={validationRules.diagnosedDate.displayName}
              error={errors.diagnosedDate}
              required={validationRules.diagnosedDate.required}
            />
          </div>
          <div>
            <SearchDropdown
              onSelect={(option) => {
                setFormData({
                  ...formData,
                  diagnosticResult: option.value,
                });
                setDisplayValueForIcd10(option.label);
              }}
              searchCallback={updateReferredIcd10List}
              searchField
              placeholder={validationRules.diagnosticResult.displayName}
              options={icd10}
              displayValue={displayValueForIcd10}
              label={validationRules.diagnosticResult.displayName}
              error={errors.diagnosticResult}
              required={validationRules.diagnosticResult.required}
            />
          </div>
          <div>
            <Input
              type="text"
              id="providerName"
              name="providerName"
              value={formData.providerName}
              onChange={handleChange}
              placeholder={validationRules.providerName.displayName}
              className="form-control form-control-sm"
              label={validationRules.providerName.displayName}
              error={errors.providerName}
              required={validationRules.providerName.required}
            />
          </div>
          <div>
            <Textarea
              className="form-control form-control-sm "
              id="comment"
              name="comment"
              placeholder={validationRules.comment.displayName}
              label={validationRules.comment.displayName}
              rows={2}
              value={formData.comment}
              onChange={handleChange}
              required={validationRules.comment.required}
              error={errors.comment}
            />
          </div>
          <div>
            <FileField
              name="fileSlide1"
              accept="image/*.pdf"
              filePath={formData.diagnosticResultPath}
              error={errors.diagnosticResultPath}
              onChange={handleImageChange}
              required={validationRules.diagnosticResultPath.required}
              label={validationRules.diagnosticResultPath.displayName}
            />
          </div>
          <div className="col-12 d-flex justify-content-between mt-5">
            <div>
              {!editFlag && (
                <Button
                  onClick={handleClear}
                  variant="outlined"
                  className="btn btn-danger btn-sm"
                  label="Clear"
                />
              )}
            </div>

            <Button
              onClick={handleSubmit}
              variant="outlined"
              className="btn btn-primary btn-sm px-4"
              label={editFlag ? "Update" : "Save & Continue"}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
