import { getLoginUserRoles } from "../../dataLayer/login/login";
import {
  checkTokenLinkAPI,
  createUser,
  deleteUser,
  getAllUsers,
  updateUser,
  userPasswordReset,
} from "../../dataLayer/users/users";

export interface CreateUserParams {
  userName: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  officeKey: number | null;
  email?: string;
  statusId: string;
  roleId: string;
  organizationId: string;
}

export async function createUserService(
  params: CreateUserParams,
): Promise<any> {
  const {
    firstName,
    lastName,
    officeKey,
    email,
    statusId,
    roleId,
    userName,
    organizationId,
  } = params;

  try {
    const requestObject = {
      firstName,
      lastName,
      username: userName,
      officeKey,
      email,
      statusId: parseInt(statusId, 10),
      roleId: parseInt(roleId, 10),
      organizationId: parseInt(organizationId, 10),
    };
    const newUser = await createUser(requestObject);
    return newUser;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating user.",
      details: error.message,
    };
  }
}

interface DeleteUserParams {
  userId: number;
}

export async function deleteUserService(
  params: DeleteUserParams,
): Promise<any> {
  const { userId } = params;

  if (!userId) {
    return {
      status: false,
      code: 500,
      message: "User ID is required.",
      details: "User ID is required.",
    };
  }

  try {
    const deleteUserResponse = await deleteUser(userId);
    return {
      status: true,
      code: 200,
      data: deleteUserResponse,
      message: "User deleted successfully.",
    };
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting user.",
      details: error.message,
    };
  }
}

export async function getAllUsersOptions(
  isSuperUserAsParameter: boolean = false,
): Promise<any> {
  try {
    const rolesResp = await getLoginUserRoles();
    if (!rolesResp.status) {
      return [];
    }

    let roles = rolesResp.data;
    if (!isSuperUserAsParameter) {
      roles = roles.filter((role: any) => role.roleName !== "SUPER_ADMIN");
    }

    return roles.map((role: any) => ({
      label: role.roleName,
      value: role.id,
    }));
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all users.",
      details: error.message,
    };
  }
}

export async function getAllUsersService({
  paginationDataAsParameter,
}: any): Promise<any> {
  try {
    const allUsers = await getAllUsers({ paginationDataAsParameter });
    if (allUsers.status) {
      const rolesResp = await getLoginUserRoles();
      return { allUsers: allUsers.data, rolesResp };
    }
    return allUsers;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all users.",
      details: error.message,
    };
  }
}

interface UpdateUserParams {
  userId: number;
  organizationId: number | string;
  updatedUserData: Partial<CreateUserParams>;
}

export async function updateUserService(
  params: UpdateUserParams,
): Promise<any> {
  const { userId, updatedUserData } = params;

  if (!userId) {
    return {
      status: false,
      code: 500,
      message: "User ID is required.",
      details: "User ID is required.",
    };
  }

  try {
    const {
      firstName,
      lastName,
      officeKey,
      email,
      statusId,
      roleId,
      organizationId,
    } = updatedUserData;
    const requestObject = {
      firstName,
      lastName,
      officeKey,
      email,
      statusId,
      roleId,
      organizationId,
    };
    const updatedUser = await updateUser(userId, requestObject);
    return updatedUser;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error updating user.",
      details: error.message,
    };
  }
}

interface ResetPasswordParams {
  newPassword: string;
  confirmPassword: string;
}

export async function resetPasswordService(
  params: ResetPasswordParams,
): Promise<any> {
  const { newPassword, confirmPassword } = params;

  if (
    !newPassword ||
    !confirmPassword ||
    newPassword.length < 8 ||
    confirmPassword.length < 8
  ) {
    return {
      status: false,
      code: 400,
      message:
        "Both password and confirm password are required and must be at least 8 characters long.",
    };
  }

  if (newPassword !== confirmPassword) {
    return {
      status: false,
      code: 400,
      message: "Password and confirm password must match.",
    };
  }

  try {
    const response = await userPasswordReset(newPassword);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: error.response?.status || 500,
      message: "Error resetting password.",
      details: error.message,
    };
  }
}

export async function validateTheAccessToken(token: string): Promise<any> {
  if (!token) {
    return {
      status: false,
      code: 400,
      message: "Token didn't found.",
    };
  }
  try {
    const response = await checkTokenLinkAPI(token);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: error.response?.status || 500,
      message: "Error validating Access token",
      details: error.message,
    };
  }
}

export function isUserHaveOnlyViewPermission({
  permissions,
  module,
}: {
  permissions: { privilegesSet: Record<string, string>[] };
  module: string;
}) {
  let response = false;
  if (permissions.privilegesSet) {
    if (module === "PATIENT") {
      response = permissions.privilegesSet.some(
        (item) => item.privilegeName === "VIEW_PATIENT",
      );
    }
    if (module === "INSURANCE") {
      response = permissions.privilegesSet.some(
        (item) => item.privilegeName === "VIEW_INSURANCE",
      );
    }
  }

  return response;
}

export function isSuperUser({
  permissions,
}: {
  permissions: { roleName: string };
}) {
  let response = false;
  if (permissions.roleName === "SUPER_ADMIN") {
    response = true;
  }

  return response;
}
