import Logger from "../../utils/Logger";
import {
  addCareTeamFromProviderSearch,
  createNewCareTeam,
  deleteCareTeamAPI,
  getAllCareTeamList,
  getCareTeamDetailsAPI,
  getSearchForCareTeamEmployeeListAPI,
  updateExistingCareTeam,
} from "../../dataLayer/careTeam/careTeam";
import { formatDate } from "../utils/formatDate";

export async function getAllCareTeam({
  paginationDataAsParameter,
  patientId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
}): Promise<any> {
  try {
    const allCareTeams = await getAllCareTeamList({
      paginationDataAsParameter,
      patientId,
    });
    return allCareTeams;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all CareTeams.",
      details: error.message,
    };
  }
}

export async function createCareTeamWithSearchResultProvider(
  formData: any,
): Promise<any> {
  try {
    const requestData = {
      status: formData.status,
      fromDate: formatDate(formData.fromDate),
      toDate: formatDate(formData.endDate),
      employeeId: formData.employeeId,
      patientId: formData.patientId,
      notes: formData.notes,
      moduleName: formData.moduleName,
    };
    Logger.logInfo("creating careTeam with search result provider", {
      data: requestData,
    });
    const createCareTeamResponse =
      await addCareTeamFromProviderSearch(requestData);

    return createCareTeamResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating CareTeam.",
      details: error.message,
    };
  }
}

export async function createCareTeam(formData: any): Promise<any> {
  try {
    const requestData = {
      ...formData,
      organization: formData.organization,
      providerName: formData.providerName,
      providerType: formData.providerType,
      contactNumber: formData.contactNumber,
      fax: formData.fax,
      email: formData.email,
      status: formData.status,
      fromDate: formatDate(formData.fromDate),
      toDate: formatDate(formData.endDate),
    };
    Logger.logInfo("creating careTeam", { data: requestData });
    const createCareTeamResponse = await createNewCareTeam(requestData);

    return createCareTeamResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating CareTeam.",
      details: error.message,
    };
  }
}

export async function updateCareTeamServices(
  formData: any,
  careTeamId: string,
): Promise<any> {
  try {
    const requestData = {
      status: formData.status,
      employeeId: formData.employeeId,
      notes: formData.notes,
      fromDate: formatDate(formData.fromDate),
      toDate: formatDate(formData.endDate),
      patientId: formData.patientId,
      moduleName: formData.moduleName,
    };
    Logger.logInfo("updating careTeam", {
      data: { requestData, careTeamId },
    });
    const updateCareTeamResponse = await updateExistingCareTeam(
      requestData,
      careTeamId,
    );

    return updateCareTeamResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating careTeam.",
      details: error.message,
    };
  }
}

export async function deleteCareTeamService(
  careTeamId: string | number,
): Promise<any> {
  if (!careTeamId) {
    return {
      status: false,
      code: 500,
      message: "CareTeam ID is required.",
      details: "CareTeam ID is required.",
    };
  }

  try {
    Logger.logInfo("deleting careTeam", { data: { careTeamId } });
    const response = await deleteCareTeamAPI(careTeamId);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting careTeam value.",
      details: error.message,
    };
  }
}

export async function getCareTeamDetailsService(
  careTeamId: string | number,
): Promise<any> {
  if (!careTeamId) {
    return {
      status: false,
      code: 500,
      message: "CareTeam ID is required.",
      details: "CareTeam ID is required.",
    };
  }

  try {
    const response = await getCareTeamDetailsAPI(careTeamId);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error getting careTeam value.",
      details: error.message,
    };
  }
}
export interface CreateCareTeamMemberRecord {
  title: string;
  firstName: string;
  lastName: string;
  organization: string;
  providerTypeId: string;
  address1: string;
  address2: string;
  zipCode: string;
  country: string;
  state: string;
  city: string;
  officeNumber: string;
  contactNumber: string;
  fax: string;
  email: string;
  status: string;
  fromDate: string;
  endDate: string;
  notes: string;
  patientId?: string;
  moduleName?: string;
  speciality: string | number;
  practice: string | number;
  taxonomy: string | number;
  upin: string;
  npi: string | number;
}

export async function createNewCareTeamMember(
  params: CreateCareTeamMemberRecord,
): Promise<any> {
  try {
    const requestObject = {
      ...params,
      fromDate: formatDate(params.fromDate),
      toDate: formatDate(params.endDate),
      organizationName: params.organization,
    };
    Logger.logInfo("creating careTeam", { data: requestObject });
    const newUser = await createNewCareTeam(requestObject);
    return newUser;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating user.",
      details: error.message,
    };
  }
}

export async function getSearchForCareTeamEmployeeList(
  nameFilter: string,
  patientId: string | number,
  providerTypes: any[],
): Promise<any> {
  try {
    if (nameFilter === "") {
      return [];
    }
    const response = await getSearchForCareTeamEmployeeListAPI(
      nameFilter,
      patientId,
      providerTypes,
    );
    if (response.isSuccess) {
      const options = response.dataList.map(
        (item: { providerName: string; employeeId: string }) => ({
          label: `${item.providerName}`,
          value: item.employeeId,
          data: item,
        }),
      );
      return options;
    }
    return [];
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching employee list values.",
      details: error.message,
    };
  }
}
