import React from "react";

export function Breadcrumb() {
  return (
    <nav aria-label="breadcrumb m-0 p-0">
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">
          <a href="/">Home</a>
        </li>
      </ol>
    </nav>
  );
}
