import ApiCaller from "../../utils/apiCaller";
import { API_ENDPOINTS } from "../../constants/apiList";
import { STATUS_CODES } from "../../constants/variables";

// eslint-disable-next-line complexity
export async function getAllCaseManagerList({
  paginationDataAsParameter,
  patientId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
}): Promise<any> {
  const { pageNo, pageSize, sortingFieldName, sortingOrder } =
    paginationDataAsParameter;
  let getCaseManagersUrl = `${API_ENDPOINTS.getAllCaseManagerList}/${patientId}`;
  if (pageSize) {
    getCaseManagersUrl = `${getCaseManagersUrl}?pageNo=${pageNo}&pageSize=${pageSize}`;
  }
  if (
    sortingFieldName &&
    sortingFieldName !== "" &&
    sortingOrder &&
    sortingOrder !== ""
  ) {
    getCaseManagersUrl = `${getCaseManagersUrl}&sortBy=${sortingFieldName}&sortDir=${sortingOrder}`;
  }

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(getCaseManagersUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "CaseManager Data Retrieved Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMsg?.[0] || "Failed to Retrieve CaseManager Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function createNewCaseManager(requestObject: any) {
  const createNewCaseManagerUrl = API_ENDPOINTS.createNewCaseManager;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(createNewCaseManagerUrl, requestObject);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "New CaseManager added successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to add new CaseManager",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function updateExistingCaseManager(
  requestObject: any,
  caseManagerId: string,
) {
  const updateExistingCaseManagerUrl = `${API_ENDPOINTS.updateCaseManager}/${caseManagerId}`;

  try {
    const response = await ApiCaller.put<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(updateExistingCaseManagerUrl, requestObject);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Update CaseManager successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to update CaseManager",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function deleteCaseManagerAPI(
  caseManagerId: number | string,
): Promise<any> {
  const deleteCaseManager = `${API_ENDPOINTS.deleteNewCaseManager}/${caseManagerId}`;

  try {
    const response: { isSuccess: boolean; responseMsg?: Array<string> } =
      await ApiCaller.delete(deleteCaseManager);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "CaseManager Deleted successfully",
        data: response,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getCareManagerForCareTeamId({
  patientId,
  carePlanId,
}: {
  patientId: string;
  carePlanId: string;
}): Promise<any> {
  const getCareManagerUrl = `${API_ENDPOINTS.getCareManagerDetails}/${patientId}/${carePlanId}`;

  try {
    const response: {
      isSuccess: boolean;
      responseMsg?: Array<string>;
      data: Record<string, string>;
    } = await ApiCaller.get(getCareManagerUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "careTeamIdManager Details Retrieved successfully",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function AddNewCaseManager(requestObject: any) {
  const addNewCaseManagerUrl = API_ENDPOINTS.createNewCaseManager;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(addNewCaseManagerUrl, requestObject);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "New CaseManager added successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to add new CaseManager",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getSearchFilterForOrganizationInCaseManager(
  fieldName: string,
): Promise<any> {
  if (fieldName) {
    const getUsersUrl = `${API_ENDPOINTS.searchOrganizationWithWord}/${fieldName}`;

    try {
      const response = await ApiCaller.get(getUsersUrl);
      return response;
    } catch (error: any) {
      return {
        status: false,
        code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
        message: error?.message || "API failed",
        details: error?.response?.data?.error || "API call failed",
      };
    }
  }
  return null;
}

export async function getSearchForCaseManagerListAPI(
  searchString: string,
  patientId: string | number,
  providerTypes: any[],
): Promise<any> {
  if (searchString) {
    const searchStringUrl = `${API_ENDPOINTS.searchCaseManagerList}/${searchString}?patientId=${patientId}&providerTypeArray=${providerTypes}`;

    try {
      const response = await ApiCaller.get(searchStringUrl);
      return response;
    } catch (error: any) {
      return {
        status: false,
        code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
        message: error?.message || "API failed",
        details: error?.response?.data?.error || "API call failed",
      };
    }
  }
  return null;
}
