/* eslint-disable complexity */
import ApiCaller from "../../utils/apiCaller";
import { API_ENDPOINTS } from "../../constants/apiList";
import { STATUS_CODES } from "../../constants/variables";

export async function getAllTasksAPI({
  paginationDataAsParameter,
}: {
  paginationDataAsParameter: any;
}): Promise<any> {
  const { pageNo, pageSize, sortingFieldName, sortingOrder } =
    paginationDataAsParameter;
  let getAllTaskUrl = `${API_ENDPOINTS.getAllTasks}`;
  if (pageSize) {
    getAllTaskUrl = `${getAllTaskUrl}?pageNo=${pageNo}&pageSize=${pageSize}`;
  }
  if (
    sortingFieldName &&
    sortingFieldName !== "" &&
    sortingOrder &&
    sortingOrder !== ""
  ) {
    getAllTaskUrl = `${getAllTaskUrl}&sortBy=${sortingFieldName}&sortDir=${sortingOrder}`;
  }

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(getAllTaskUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Task Data Retrieved Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to Retrieve Memo Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function createNewTaskAPI(formData: any): Promise<any> {
  const createNewTaskUrl = API_ENDPOINTS.createNewTask;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(createNewTaskUrl, formData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Task Add Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to Retrieve Memo Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function updateExistingTaskAPI(
  formData: any,
  taskId: string,
): Promise<any> {
  const updateTaskUrl = `${API_ENDPOINTS.updateTasks}/${taskId}`;

  try {
    const response = await ApiCaller.put<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(updateTaskUrl, formData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Task update Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to update task",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function deleteTaskAPI(taskId: number | string): Promise<any> {
  const deleteTaskUrl = `${API_ENDPOINTS.deleteTasks}/${taskId}`;

  try {
    const response: { isSuccess: boolean; responseMsg?: Array<string> } =
      await ApiCaller.delete(deleteTaskUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Task Deleted successfully",
        data: response,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function filterTasksAPI({
  paginationDataAsParameter,
  filters,
}: {
  paginationDataAsParameter: any;
  filters: {
    taskTypeId?: number;
    timeType?: number;
    status?: string;
    search?: string;
  };
}): Promise<any> {
  const { pageNo, pageSize, sortingFieldName, sortingOrder } =
    paginationDataAsParameter;
  let filterParams = "";
  if (filters.taskTypeId !== undefined) {
    filterParams += `taskTypeId=${filters.taskTypeId}`;
  }
  if (filters.timeType !== undefined) {
    filterParams += filterParams
      ? `&timeline=${filters.timeType}`
      : `timeline=${filters.timeType}`;
  }
  if (filters.status) {
    filterParams += filterParams
      ? `&status=${filters.status}`
      : `status=${filters.status}`;
  }
  if (filters.search) {
    filterParams += filterParams
      ? `&search=${encodeURIComponent(filters.search)}`
      : `search=${encodeURIComponent(filters.search)}`;
  }

  let getAllTaskUrl = `${API_ENDPOINTS.filterTask}`;
  if (filterParams) {
    getAllTaskUrl += `?${filterParams}`;
  }

  if (
    sortingFieldName &&
    sortingFieldName !== "" &&
    sortingOrder &&
    sortingOrder !== ""
  ) {
    getAllTaskUrl += `&sortBy=${sortingFieldName}&sortDir=${sortingOrder}`;
  }
  if (pageNo >= 0 && pageSize) {
    getAllTaskUrl += `&pageNo=${pageNo}&pageSize=${pageSize}`;
  }

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(getAllTaskUrl);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Filtered Task Data Retrieved Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to Retrieve Task Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getTaskDetails(taskId: number | string): Promise<any> {
  const deleteTaskUrl = `${API_ENDPOINTS.getTasksDetails}/${taskId}`;

  try {
    const response: {
      isSuccess: boolean;
      responseMsg?: Array<string>;
      data: Record<string, string>;
    } = await ApiCaller.get(deleteTaskUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Task retrieve successfully",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getTaskCount(): Promise<any> {
  const taskCountUrl = `${API_ENDPOINTS.getTaskCount}`;

  try {
    const response: {
      isSuccess: boolean;
      responseMsg?: Array<string>;
      data: Record<string, string>;
    } = await ApiCaller.get(taskCountUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Task count retrieve successfully",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
