import Logger from "../../utils/Logger";
import {
  AddNewCaseManager,
  createNewCaseManager,
  deleteCaseManagerAPI,
  getAllCaseManagerList,
  getCareManagerForCareTeamId,
  getSearchFilterForOrganizationInCaseManager,
  getSearchForCaseManagerListAPI,
  updateExistingCaseManager,
} from "../../dataLayer/caseManager/caseManager";
import { formatDate } from "../utils/formatDate";

export async function getAllCaseManager({
  paginationDataAsParameter,
  patientId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
}): Promise<any> {
  try {
    const allCaseManagers = await getAllCaseManagerList({
      paginationDataAsParameter,
      patientId,
    });
    return allCaseManagers;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all CaseManagers.",
      details: error.message,
    };
  }
}

export async function createCaseManager(formData: any): Promise<any> {
  try {
    const requestData = {
      organization: formData.organization,
      providerName: formData.providerName,
      providerType: formData.providerType,
      contactNumber: formData.contactNumber,
      fax: formData.fax,
      email: formData.email,
      status: formData.status,
      fromDate: formatDate(formData.fromDate),
      endDate: formatDate(formData.endDate),
    };
    Logger.logInfo("creating a new care manager", { data: requestData });
    const createCaseManagerResponse = await createNewCaseManager(requestData);

    return createCaseManagerResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating CaseManager.",
      details: error.message,
    };
  }
}

export async function updateCaseManagerServices(
  formData: any,
  caseManagerId: string,
): Promise<any> {
  try {
    const requestData = {
      organization: formData.organization,
      providerName: formData.providerName,
      providerType: formData.providerType,
      contactNumber: formData.contactNumber,
      fax: formData.fax,
      email: formData.email,
      status: formData.status,
      fromDate: formatDate(formData.fromDate),
      endDate: formatDate(formData.endDate),
      patientId: formData.patientId,
    };
    Logger.logInfo("updating a care manager", { data: requestData });
    const updateCaseManagerResponse = await updateExistingCaseManager(
      requestData,
      caseManagerId,
    );

    return updateCaseManagerResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating caseManager.",
      details: error.message,
    };
  }
}

export async function deleteCaseManagerService(
  caseManagerId: string | number,
): Promise<any> {
  if (!caseManagerId) {
    return {
      status: false,
      code: 500,
      message: "CaseManager ID is required.",
      details: "CaseManager ID is required.",
    };
  }

  try {
    Logger.logInfo("deleting a care manager", { data: caseManagerId });
    const response = await deleteCaseManagerAPI(caseManagerId);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting caseManager value.",
      details: error.message,
    };
  }
}

export async function getCareManagerDetailsForCareTeam({
  carePlanId,
  patientId,
}: {
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  if (!carePlanId) {
    return {
      status: false,
      code: 500,
      message: "care Plan ID is required.",
      details: "care Plan ID is required.",
    };
  }

  try {
    const response = await getCareManagerForCareTeamId({
      carePlanId,
      patientId,
    });
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting caseManager value.",
      details: error.message,
    };
  }
}

export async function getSearchForOrganizationValuesInCaseManager(
  FieldName: string,
): Promise<any> {
  try {
    const response =
      await getSearchFilterForOrganizationInCaseManager(FieldName);
    if (response.isSuccess) {
      const options = response.dataList.map(
        (item: { organizationName: string; id: string }) => ({
          label: `${item.organizationName}`,
          value: item.id,
        }),
      );
      return options;
    }
    return [];
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching Organization values.",
      details: error.message,
    };
  }
}

export interface AddNewCaseManagerRecord {
  title: string;
  firstName: string;
  lastName: string;
  organization: string;
  providerType: string;
  address1: string;
  address2: string;
  zipCode: string;
  country: string;
  state: string;
  city: string;
  officeNumber: string;
  otherNumber: string;
  fax: string;
  email: string;
  speciality: string;
  practice: string;
  taxonomy: string;
  npi: string;
  uPin: string;
  status: string;
  fromDate: string;
  endDate: string;
  notes: string;
  patientId: string;
}

export async function createNewCaseManagers(
  params: AddNewCaseManagerRecord,
): Promise<any> {
  try {
    const requestObjects = {
      title: params.title,
      firstName: params.firstName,
      lastName: params.lastName,
      organization: params.organization,
      address1: params.address1,
      address2: params.address2,
      officeNumber: params.officeNumber,
      otherNumber: params.otherNumber,
      country: params.country,
      state: params.state,
      city: params.city,
      zipCode: params.zipCode,
      fax: params.fax,
      email: params.email,
      speciality: params.speciality,
      practice: params.practice,
      taxonomy: params.taxonomy,
      npi: params.npi,
      upin: params.uPin,
      status: params.status,
      fromDate: params.fromDate,
      endDate: params.endDate,
      notes: params.notes,
      patientId: params.patientId,
    };
    const newUser = await AddNewCaseManager(requestObjects);
    return newUser;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating user.",
      details: error.message,
    };
  }
}

export async function getSearchForCaseManagerListFromEmployee(
  nameFilter: string,
  patientId: string | number,
  providerTypes: any[],
): Promise<any> {
  try {
    if (nameFilter === "") {
      return [];
    }
    const response = await getSearchForCaseManagerListAPI(
      nameFilter,
      patientId,
      providerTypes,
    );
    if (response.isSuccess) {
      const options = response.dataList.map(
        (item: { providerName: string; employeeId: string }) => ({
          label: `${item.providerName}`,
          value: item.employeeId,
          data: item,
        }),
      );
      return options;
    }
    return [];
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching employee list values.",
      details: error.message,
    };
  }
}
