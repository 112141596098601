import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "../../../components/atom/Table/Table";
import { Card } from "../../../components/atom/Cards/Cards";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import { Pagination } from "../../../components/atom/Pagination/Pagination";
import { FullScreenLoader } from "../../../components/atom/FullScreenLoader/FullScreenLoader";
import {
  getAllFormsServices,
  deleteFormService,
  getFormByIdService,
  getFormFileImageByIdService,
} from "../../../services/FormsAndIRA/forms";
import { CreateForm } from "../../../components/organism/CreateForm/CreateForm";
import { ModalComponent } from "../../../components/molecule/Modal/ModalComponent";
import { Image } from "../../../components/atom/Images/Image";
import { Button } from "../../../components/atom/Buttons/Button";
import { downloadFile } from "../../../services/utils/downloadFile";

interface FormsProps {
  displayLabel: string;
}
export function Forms({ displayLabel }: FormsProps) {
  interface FormsListType {
    id: number;
    patientId: number;
    categoryId: number;
    categoryValue: string;
    sourceId: number;
    sourceValue: string;
    dateOfPerformTest: string;
    uploadDocument: string;
    dateOfUploadDocument: string;
  }
  const iframePdfRef = useRef<HTMLIFrameElement>(null);
  const iframeImgRef = useRef<HTMLIFrameElement>(null);
  const [fullScreenLoaderStatus, setFullScreenLoaderStatus] = useState(false);
  const activePatient = useSelector((state: any) => state.activePatient);
  const [formsDataForTable, setFormsDataForTable] = useState<FormsListType[]>(
    [],
  );
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [visible, setVisible] = useState("Table");
  const [editData, setEditData] = useState<any>();
  const [selectedData, setSelectedData] = useState<any>();
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [selectedPdf, setSelectedPdf] = useState<string>("");
  const [paginationData, setPaginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });
  const headersForTable: Record<string, string> = {
    categoryValue: "Category",
    uploadDocument: "Uploaded Document",
    dateOfPerformTest: "Date of performance test",
    sourceValue: "Source",
    provider: "Provider",
    action: "Action",
  };
  const sortingHeaderMap: Record<string, string> = {
    categoryValue: "Category",
    uploadDocument: "uploadDocument",
    dateOfPerformTest: "dateOfPerformTest",
    sourceValue: "Source",
    provider: "Provider",
  };

  const getAllFormsDataForTable = async (newPaginationData?: any) => {
    try {
      const paginationDataAsParameter = newPaginationData || paginationData;
      const patientId = activePatient.patientDetails.id;
      if (patientId !== "") {
        const appFormsListResponse = await getAllFormsServices({
          paginationDataAsParameter,
          patientId,
        });
        if (appFormsListResponse.isSuccess) {
          setPaginationData({
            ...paginationData,
            pageNo: appFormsListResponse.pageNo,
            totalPages: appFormsListResponse.totalPages,
            totalElements: appFormsListResponse.totalElements,
            pageSize: appFormsListResponse.pageSize,
          });
          const { dataList } = appFormsListResponse;
          if (dataList) {
            setFormsDataForTable(appFormsListResponse.dataList);
          } else {
            setFormsDataForTable([]);
          }
        } else {
          setFormsDataForTable([]);
        }
      }
    } catch (error) {
      showToastMessage("Error fetching all users:", TOAST_TYPE_OPTIONS.ERROR);
      console.error("Error fetching all users:", error);
    }
  };

  const deleteFormValue = async (rowData: any) => {
    const deleteFormResponse = await deleteFormService(rowData.id);
    if (deleteFormResponse.status) {
      showToastMessage(deleteFormResponse.message, TOAST_TYPE_OPTIONS.SUCCESS);
      getAllFormsDataForTable();
    } else {
      showToastMessage(deleteFormResponse.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  const handleViewAction = async (rowData: any) => {
    setSelectedData(rowData);
    setFullScreenLoaderStatus(true);
    const fileSrc = await getFormFileImageByIdService(rowData.id);
    setFullScreenLoaderStatus(false);
    setVisible("View");
    if (rowData?.uploadDocument?.endsWith(".pdf")) {
      setSelectedPdf(`${fileSrc}#toolbar=0`);
    } else {
      setSelectedImage(fileSrc);
    }
  };

  const editRowAction = async (rowData: any) => {
    setFullScreenLoaderStatus(true);
    const formData = await getFormByIdService(rowData.id);
    setFullScreenLoaderStatus(false);
    if (formData.status) {
      setEditData(formData.data);
      setVisible("Form");
    } else {
      showToastMessage(formData.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  const handleDownloadAction = async (rowData: any) => {
    setFullScreenLoaderStatus(true);
    const fileSrc = await getFormFileImageByIdService(rowData.id);
    downloadFile(fileSrc, rowData.uploadDocument);
    setFullScreenLoaderStatus(false);
  };

  useEffect(() => {
    getAllFormsDataForTable();
  }, []);

  useEffect(() => {
    if (!activePatient.patientDetails.id) {
      setFormsDataForTable([]);
    }
  }, [activePatient]);

  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize,
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    getAllFormsDataForTable({ ...paginationData, pageNo, pageSize });
  };

  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    getAllFormsDataForTable({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };
  const onSubmitAction = () => {
    getAllFormsDataForTable();
    setEditData(undefined);
    setVisible("Table");
  };
  const handlePrintClick = async (rowData: any) => {
    setShowPrintModal(true);
    setSelectedData(rowData);
  };

  const handlePrint = async () => {
    setShowPrintModal(false);
    if (!selectedData) return;

    setFullScreenLoaderStatus(true);
    const fileSrc = await getFormFileImageByIdService(selectedData.id);
    setFullScreenLoaderStatus(false);

    if (iframePdfRef.current && fileSrc && iframeImgRef.current) {
      const iframeDoc =
        iframeImgRef.current.contentDocument ||
        iframeImgRef.current.contentWindow?.document;
      if (iframeDoc) {
        // Reset iframe content before setting new content
        iframeDoc.open();
        iframeDoc.close();

        if (selectedData?.uploadDocument?.endsWith(".pdf")) {
          iframePdfRef.current.src = fileSrc;
          iframePdfRef.current.onload = () => {
            iframePdfRef.current?.contentWindow?.print();
          };
        } else {
          // Create the new document content
          const docContent = `
            <html>
              <head>
                <style>
                  body {
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100vh;
                  }
                  img {
                    max-width: 100%;
                    max-height: 100%;
                  }
                </style>
              </head>
              <body onload="window.print()">
                <img src="${fileSrc}" />
              </body>
            </html>
          `;

          // Write the new document content to the iframe
          iframeDoc.open();
          iframeDoc.write(docContent);
          iframeDoc.close();
        }
      }
    }
    setSelectedData(undefined);
  };

  const handleClosePrintModal = () => {
    setShowPrintModal(false);
  };

  const handleBackTable = () => {
    setVisible("Table");
    setSelectedImage("");
    setSelectedPdf("");
    setSelectedData(undefined);
    setEditData(undefined);
  };

  const addNewForm = () => {
    setVisible("Form");
    setEditData(undefined);
  };

  return (
    <div>
      {fullScreenLoaderStatus && (
        <FullScreenLoader show={fullScreenLoaderStatus} />
      )}
      <Card>
        <div className="card-title d-flex justify-content-between align-items-center">
          <h5>{displayLabel}</h5>
          <div className="d-flex text-end">
            {visible === "Table" ? (
              <Button
                variant="primary"
                className="primary btn btn-primary d-flex align-items-center  py-1 float-end btn-sm px-5 ms-3"
                onClick={() => addNewForm()}
              >
                <i className="fa fa-plus me-2 fs-5" /> Add New Form
              </Button>
            ) : (
              <Button
                variant="primary"
                className="primary btn btn-primary d-flex align-items-center  py-1 float-end btn-sm px-5 ms-3"
                onClick={() => handleBackTable()}
                label="Back"
              />
            )}
          </div>
        </div>
        {visible === "Table" && (
          <div className="table-responsive care-manager-table">
            <Table
              className="table table-bordered table-striped table-hover"
              data={formsDataForTable}
              headers={headersForTable}
              enableDeleteAction={deleteFormValue}
              enableViewAction={(rowData) => {
                handleViewAction(rowData);
              }}
              enableEditAction={(rowData) => {
                editRowAction(rowData);
              }}
              enableDownloadAction={(rowData) => {
                handleDownloadAction(rowData);
              }}
              enablePrintAction={(rowData) => {
                handlePrintClick(rowData);
              }}
              sortingHeaderMap={sortingHeaderMap}
              sortingFunction={sortingFunction}
            />
            {paginationData.totalPages >= 0 && (
              <Pagination
                currentPage={paginationData.pageNo}
                totalPages={paginationData.totalPages}
                pageSize={paginationData.pageSize}
                onPageChange={(page, pageSize) => {
                  updatePaginationData(page, pageSize);
                }}
              />
            )}
          </div>
        )}
        {visible === "View" && (
          <>
            <div>
              {selectedImage && (
                <Image
                  src={selectedImage}
                  alt={selectedData?.uploadDocument}
                  title={selectedData?.uploadDocument}
                  width="100%"
                />
              )}
            </div>
            <div>
              {selectedPdf && (
                <iframe
                  title={selectedData?.uploadDocument}
                  src={selectedPdf}
                  width="100%"
                  height="500"
                />
              )}
            </div>
          </>
        )}
        {visible === "Form" && (
          <CreateForm editData={editData} onSubmitAction={onSubmitAction} />
        )}

        <iframe ref={iframePdfRef} className="d-none" title="print-pdf-frame" />
        <iframe ref={iframeImgRef} className="d-none" title="print-img-frame" />
        {showPrintModal && (
          <ModalComponent
            buttonShow="Add New Form"
            buttonLeft="Cancel"
            buttonRight="Printer"
            title="Print Options"
            onSubmit={handlePrint}
            className="text-end"
            showAddUserPopup={showPrintModal}
            setShowAddUserPopup={setShowPrintModal}
            closeAction={handleClosePrintModal}
            showToggleAsButton={false}
          >
            <p>Do you want to print the document?</p>
          </ModalComponent>
        )}
      </Card>
    </div>
  );
}
