import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserDetailsState {
  username: string;
  roles: Array<string>;
  id: number;
  email: string;
  accessToken: string;
  userPermission: Record<string, string>;
  organizationId: string;
}
interface PayloadData {
  data: {
    username: string;
    roles: Array<string>;
    id: number;
    email: string;
    accessToken: string;
    organizationId: string;
  };
  userPermission: Record<string, string>;
}

const initialState: UserDetailsState = {
  username: "",
  roles: [],
  id: -1,
  email: "",
  accessToken: "",
  userPermission: {},
  organizationId: "",
};

const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    setUserDetails: (
      state: UserDetailsState,
      action: PayloadAction<Partial<PayloadData>>,
    ) => {
      state.accessToken =
        action?.payload?.data?.accessToken ?? state.accessToken;
      state.username = action?.payload?.data?.username ?? state.username;
      state.email = action?.payload?.data?.email ?? state.email;
      state.id = action?.payload?.data?.id ?? state.id;
      state.roles = action?.payload?.data?.roles ?? state.roles;
      state.organizationId =
        action?.payload?.data?.organizationId ?? state.organizationId;
      state.userPermission =
        action?.payload?.userPermission ?? state.userPermission;
    },
    clearUserDetails: (state: UserDetailsState) => {
      state.email = initialState.email;
      state.accessToken = initialState.accessToken;
      state.id = initialState.id;
      state.roles = initialState.roles;
      state.username = initialState.username;
      state.userPermission = initialState.userPermission;
    },
  },
});

export const { setUserDetails, clearUserDetails } = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
