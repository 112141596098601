import { MODULE_NAME_OPTION } from "../../../../constants/variables";

export function displayTitleForModal({
  moduleName,
  editFlag,
}: {
  moduleName: string;
  editFlag: boolean;
}) {
  let returnString = "";
  if (editFlag) {
    returnString = "Update ";
  } else {
    returnString = "Add ";
  }

  if (moduleName === MODULE_NAME_OPTION.careTeam) {
    returnString += "Care Team";
  } else {
    returnString += "Case Manager ";
  }
  return returnString;
}
