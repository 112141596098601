import Logger from "../../utils/Logger";
import {
  createNewMemo,
  deleteMemoAPI,
  getAllMemoList,
  updateExistingMemo,
} from "../../dataLayer/memos/memos";
import { formatDate } from "../utils/formatDate";

export async function getAllMemos({
  paginationDataAsParameter,
}: {
  paginationDataAsParameter: any;
}): Promise<any> {
  try {
    const allMemos = await getAllMemoList({
      paginationDataAsParameter,
    });
    return allMemos;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all memos.",
      details: error.message,
    };
  }
}

export async function createMemo(formData: any): Promise<any> {
  try {
    const requestData = {
      ...formData,
      memoDate: formatDate(formData.memoDate),
    };
    Logger.logInfo("Creating new memo", { data: requestData });
    const createMemoResponse = await createNewMemo(requestData);
    return createMemoResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating memo.",
      details: error.message,
    };
  }
}

export async function updateMemoServices(
  formData: any,
  memoId: string,
): Promise<any> {
  try {
    const requestData = {
      ...formData,
      memoDate: formatDate(formData.memoDate),
    };
    Logger.logInfo("Updating memo", { data: requestData });
    const updateMemoResponse = await updateExistingMemo(requestData, memoId);

    return updateMemoResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating memo.",
      details: error.message,
    };
  }
}

export async function deleteMemoService(memoId: string | number): Promise<any> {
  if (!memoId) {
    return {
      status: false,
      code: 500,
      message: "Memos ID is required.",
      details: "Memos ID is required.",
    };
  }

  try {
    Logger.logInfo("Deleting memo", { data: memoId });
    const response = await deleteMemoAPI(memoId);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting memo value.",
      details: error.message,
    };
  }
}
