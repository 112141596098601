import {
  forgotPasswordViaEmail,
  getLoginUserRoles,
  loginViaEmail,
} from "../../dataLayer/login/login";
import { generateErrorResponse } from "../validation/generateErrorResponse";

interface LoginParams {
  username: string;
  password: string;
  officeKey: string;
}
export async function login(params: LoginParams): Promise<any> {
  const { username, password, officeKey } = params;

  if (!username || !password || !officeKey) {
    return {
      status: false,
      code: 500,
      message: "Username, password, and office key are required.",
      details: "Username, password, and office key are required.",
    };
  }

  if (officeKey.length < 6) {
    return {
      status: false,
      code: 500,
      message: "Invalid Username, password or Office Key.",
      details: "Invalid Username, password or Office Key.",
    };
  }
  const requestData = {
    username,
    password,
    officeKey,
  };
  const response = await loginViaEmail(requestData);
  if (response.status) {
    localStorage.setItem("accessToken", response.data.data.accessToken);
    const userRoles = await getLoginUserRoles();
    if (userRoles.status) {
      const userPermission = userRoles.data.find(
        (role: { id: number }) => role.id === response.data.data.roleId,
      );
      response.data.userPermission = userPermission;
    }
  } else {
    return generateErrorResponse(response?.message, response?.code);
  }
  return response;
}
interface ForgotParams {
  email: string;
}
export async function forgotPassword(params: ForgotParams): Promise<any> {
  const { email } = params;
  if (!email) {
    return {
      status: false,
      code: 500,
      message: "User email are required.",
      details: "User email are required.",
    };
  }

  const requestData = {
    email,
  };
  const response = await forgotPasswordViaEmail(requestData);
  return response;
}
