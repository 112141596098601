import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Table } from "../../../atom/Table/Table";
import { Button } from "../../../atom/Buttons/Button";
import {
  getGoalsForCarePlan,
  updateGoalForPatient,
} from "../../../../services/carePlan/carePlan";
import { CompleteGoalModal } from "./CompleteGoalModal/CompleteGoalModal";
import { getOptionsForDropdown } from "../../../../services/dropDownMenuOptions/dropDownMenuOptions";
import { getIdByLegalValue } from "../../../../services/utils/getIdByLegalValue";
import { showToastMessage } from "../../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../../constants/variables";

interface ProblemsAndGoalsProps {
  toggleAddGoalScreen: (state: boolean) => void;
  setEditDataForGoals: (rowData: any) => void;
  setCarePlanViewFlagStatus: (state: boolean) => void;
  isViewMode?: boolean;
}
interface EditGoal {
  barrier: string;
  carePlanId: number;
  createdBy: string;
  createdOn: string;
  goalBarrierDueDate: string;
  goalInterventionsDueDate: string;
  goals: string;
  id: number;
  interventions: string;
  lastModifiedBy: string;
  lastModifiedOn: string;
  priority: number;
  priorityValue: string;
  problems: string;
  reason: string | null;
  status: string | null;
  statusValue: string | null;
}

const tableHeaders = {
  priorityValue: "Priority",
  problems: "Problem",
  goals: [
    { key: "goals", label: "Goal" },
    { key: "interventions", label: "interventions" },
    { key: "goalInterventionsDueDate", label: "Due Date" },
  ],
  barrier: "Barrier",
  goalBarrierDueDate: "Due Date",
  completedDate: "Completed Date",
  action: "Action",
};

export function ProblemsAndGoals({
  toggleAddGoalScreen,
  setEditDataForGoals,
  setCarePlanViewFlagStatus,
  isViewMode,
}: ProblemsAndGoalsProps) {
  const carePlanDetails = useSelector((state: any) => state.activeCarePlan);
  const activePatient = useSelector((state: any) => state.activePatient);
  const [showGoalCompleteModal, setShowGoalCompleteModal] = useState(false);
  const [goalDataForAction, setGoalDataForAction] = useState<EditGoal>();
  const [tableData, setTableData] = useState([]);
  const [statusOptionList, setStatusOptionList] = useState();
  const addGoal = () => {
    toggleAddGoalScreen(true);
  };

  const fetchData = async () => {
    const getGoalForPatientResponse = await getGoalsForCarePlan({
      carePlanId: carePlanDetails.carePlanDetails.carePlanId,
      patientId: activePatient.patientDetails.id,
    });
    if (getGoalForPatientResponse.status) {
      setTableData(getGoalForPatientResponse.data);
    }
    const getOptionForStatusResponse =
      await getOptionsForDropdown("CarePlanGoalStatus");
    setStatusOptionList(getOptionForStatusResponse);
  };

  const editGoalAction = (rowData: any) => {
    if (rowData.statusValue === "Completed") {
      showToastMessage(
        "Cannot edit completed care plan",
        TOAST_TYPE_OPTIONS.ERROR,
      );
      return;
    }
    setEditDataForGoals(rowData);
    toggleAddGoalScreen(true);
    setCarePlanViewFlagStatus(false);
  };

  const completeButtonAction = (rowData: any) => {
    setGoalDataForAction(rowData);
    setShowGoalCompleteModal(true);
  };

  const toggleCompleteGoalModal = () => {
    setShowGoalCompleteModal(!showGoalCompleteModal);
  };

  const confirmGoalComplete = async () => {
    const legalValueForCompleteStatus = getIdByLegalValue(
      "Completed",
      statusOptionList,
    );
    if (goalDataForAction && goalDataForAction?.id) {
      const requestObject = {
        ...goalDataForAction,
        id: goalDataForAction.id,
        carePlanStatus: legalValueForCompleteStatus,
      };
      const createGoalResponse = await updateGoalForPatient({
        formData: requestObject,
        carePlanId: carePlanDetails?.carePlanDetails?.carePlanId,
        patientId: activePatient.patientDetails.id,
      });
      if (createGoalResponse.status) {
        toggleCompleteGoalModal();
        showToastMessage(
          createGoalResponse.message,
          TOAST_TYPE_OPTIONS.SUCCESS,
        );
      } else {
        showToastMessage(createGoalResponse.message, TOAST_TYPE_OPTIONS.ERROR);
      }
    }
  };

  const confirmGoalNotComplete = async (reason: string) => {
    const legalValueForCompleteStatus = getIdByLegalValue(
      "Not-Completed",
      statusOptionList,
    );
    if (goalDataForAction && goalDataForAction?.id) {
      const requestObject = {
        ...goalDataForAction,
        id: goalDataForAction.id,
        carePlanStatus: legalValueForCompleteStatus,
        reason,
      };
      const createGoalResponse = await updateGoalForPatient({
        formData: requestObject,
        carePlanId: carePlanDetails?.carePlanDetails?.carePlanId,
        patientId: activePatient.patientDetails.id,
      });
      if (createGoalResponse.status) {
        showToastMessage(
          createGoalResponse.message,
          TOAST_TYPE_OPTIONS.SUCCESS,
        );
        toggleCompleteGoalModal();
      } else {
        showToastMessage(createGoalResponse.message, TOAST_TYPE_OPTIONS.ERROR);
      }
    }
  };
  const viewButtonAction = (rowData: any) => {
    setEditDataForGoals(rowData);
    setCarePlanViewFlagStatus(true);
    addGoal();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="accordion-collapse">
      <div className="d-flex justify-content-between align-items-baseline py-2">
        <div className="d-flex align-items-center">
          <p className="fs-6">Number of active goals -</p>
          <p className="fs-5 fw-bold ms-2">{tableData.length}</p>
        </div>

        {isViewMode ? (
          <div />
        ) : (
          <Button
            className="btn btn-primary btn-sm d-flex align-items-center px-2 py-0"
            onClick={addGoal}
          >
            <i className="fa fa-plus me-2 fs-5 py-2" />
            Add Goals
          </Button>
        )}
      </div>

      <div className="table-responsive care-manager-table mt-2">
        <Table
          headers={tableHeaders}
          data={tableData}
          className="table table-bordered table-striped"
          enableEditAction={editGoalAction}
          enableCompleteAction={completeButtonAction}
          enableViewAction={viewButtonAction}
          isEditDisabled={() => isViewMode ?? false}
        />
      </div>
      {showGoalCompleteModal && (
        <CompleteGoalModal
          onClose={toggleCompleteGoalModal}
          onConfirm={() => {
            confirmGoalComplete();
          }}
          onReasonSubmit={(reason: string) => {
            confirmGoalNotComplete(reason);
          }}
        />
      )}
    </div>
  );
}
