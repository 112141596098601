import { getAllOrganization } from "../../dataLayer/masterData/masterData";
import {
  getAllPcpValues,
  getCaseManagerAndAdminList,
  getCaseManagerList,
  getDropdownOptions,
  getListOfCountry,
  getRaceAndEthnicityList,
} from "../../dataLayer/dropDownMenuOptions/dropDownMenuOptions";

export async function getOptionsForDropdown(
  dropdownName: string,
): Promise<any> {
  try {
    const response = await getDropdownOptions(dropdownName);
    if (response.isSuccess) {
      const respData = response.data.legalValueSet;
      const options = respData.map(
        (item: { legalValue: string; id: number }) => ({
          label: item.legalValue,
          value: item.id,
        }),
      );
      return options;
    }
    return [];
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all users.",
      details: error.message,
    };
  }
}

export async function getPatientsCaseManagerList(): Promise<any> {
  try {
    const response = await getCaseManagerList();
    if (response.isSuccess) {
      const respData = response.dataList;
      const options = respData.map(
        (item: {
          firstName: string;
          lastName: string;
          officeKey: number;
          email: string;
          id: string;
        }) => ({
          label: `${item.firstName} ${item.lastName}`,
          value: item.id,
        }),
      );
      return options;
    }
    return [];
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching getting the case manager list.",
      details: error.message,
    };
  }
}
export async function getPatientsCaseManagerAndAdminList(): Promise<any> {
  try {
    const response = await getCaseManagerAndAdminList();
    if (response.isSuccess) {
      const respData = response.dataList;
      const options = respData.map(
        (item: {
          firstName: string;
          lastName: string;
          officeKey: number;
          email: string;
          id: string;
        }) => ({
          label: `${item.firstName} ${item.lastName}`,
          value: item.id,
        }),
      );
      return options;
    }
    return [];
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching getting the case manager list.",
      details: error.message,
    };
  }
}
interface Option {
  id: string | number;
  name: string;
}
const convertToLabelValueArray = (
  options: Option[],
): { label: string; value: string | number }[] =>
  options.map((option) => ({
    label: option.name,
    value: option.id,
  }));

export async function getAllRaceAndEthnicityForPatient(): Promise<any> {
  try {
    const response = await getRaceAndEthnicityList();
    if (response.isSuccess) {
      return {
        ethnicityList: convertToLabelValueArray(response.data.ethnicityList),
        raceList: convertToLabelValueArray(response.data.raceList),
      };
    }
    return [];
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching Race abd ethnicity.",
      details: error.message,
    };
  }
}

export async function getAllCountryAndStates(): Promise<any> {
  try {
    const response = await getListOfCountry();
    if (response.isSuccess) {
      return response.dataList;
    }
    return [];
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all country.",
      details: error.message,
    };
  }
}

export async function getOptionsForPcpValues(): Promise<any> {
  try {
    const response = await getAllPcpValues();
    if (response.isSuccess) {
      const options = response.dataList.map(
        (item: {
          firstName: string;
          lastName: string;
          title: number;
          id: string;
        }) => ({
          label: `${item.title} ${item.firstName} ${item.lastName}`,
          value: item.id,
        }),
      );
      return options;
    }
    return [];
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching PCP values.",
      details: error.message,
    };
  }
}

export async function getAllOrganizationDropdownOptions({
  paginationDataAsParameter,
}: {
  paginationDataAsParameter: any;
}): Promise<any> {
  try {
    const response = await getAllOrganization({
      paginationDataAsParameter,
    });
    if (response.status && response?.data?.dataList) {
      if (Array.isArray(response.data.dataList)) {
        const options = response.data.dataList.map(
          (item: { organizationName: string; id: string }) => ({
            label: `${item.organizationName}`,
            value: item.id,
          }),
        );
        return options;
      }
    }
    return [];
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all Organization Data.",
      details: error.message,
    };
  }
}
