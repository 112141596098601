import React, { useState, useEffect } from "react";

export interface TimeInputProps {
  id?: string;
  label?: string;
  value?: string;
  onChange: (time: string) => void;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  name?: string;
  error?: string;
  format: "12-hour" | "24-hour";
}

export function TimeInput({
  id,
  label,
  value,
  onChange,
  className,
  name,
  disabled = false,
  required,
  format,
  error,
}: TimeInputProps) {
  const [hours, setHours] = useState<string>("HH");
  const [minutes, setMinutes] = useState<string>("MM");
  const [timeOccur, setTimeOccur] = useState<string>("AM");

  useEffect(() => {
    if (value) {
      const [timeValue, occur] = value.split(" ");
      const [hour, minute] = timeValue.split(":");
      setHours(hour);
      setMinutes(minute);
      if (format === "12-hour" && occur) {
        setTimeOccur(occur);
      }
    } else {
      setHours("HH");
      setMinutes("MM");
      setTimeOccur("AM");
    }
  }, [value, format]);

  const handleHourChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setHours(event.target.value);
    if (format === "12-hour") {
      onChange(`${event.target.value}:${minutes} ${timeOccur}`);
    } else {
      onChange(`${event.target.value}:${minutes}`);
    }
    if (minutes === "MM") {
      setMinutes("00");
      if (format === "12-hour") {
        onChange(`${event.target.value}:00 ${timeOccur}`);
      } else {
        onChange(`${event.target.value}:00`);
      }
    }
  };

  const handleMinuteChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setMinutes(event.target.value);
    if (format === "12-hour") {
      onChange(`${hours}:${event.target.value} ${timeOccur}`);
    } else {
      onChange(`${hours}:${event.target.value}`);
    }
  };

  const handleTimeOccurChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setTimeOccur(event.target.value);
    onChange(`${hours}:${minutes} ${event.target.value}`);
  };

  const generateHourOptions = () => {
    const hourOptions: string[] = ["HH"];
    if (format === "12-hour") {
      for (let i = 1; i <= 12; i += 1) {
        hourOptions.push(i.toString().padStart(2, "0"));
      }
    } else {
      for (let i = 0; i < 24; i += 1) {
        hourOptions.push(i.toString().padStart(2, "0"));
      }
    }
    return hourOptions;
  };

  const generateMinuteOptions = () => {
    const minuteOptions: string[] = ["MM"];
    for (let i = 0; i < 60; i += 1) {
      minuteOptions.push(i.toString().padStart(2, "0"));
    }
    return minuteOptions;
  };

  return (
    <div className="w-100">
      {label && (
        <label className="d-block" htmlFor={id}>
          {label} {required && <span className="text-danger"> *</span>}
        </label>
      )}
      <select
        id={`${id}-hour`}
        value={hours}
        onChange={handleHourChange}
        disabled={disabled}
        className={className}
        name={name}
      >
        {generateHourOptions().map((hour) => (
          <option key={hour} value={hour}>
            {hour}
          </option>
        ))}
      </select>
      <span>:</span>
      <select
        id={`${id}-minute`}
        value={minutes}
        onChange={handleMinuteChange}
        disabled={disabled}
      >
        {generateMinuteOptions().map((minute) => (
          <option key={minute} value={minute}>
            {minute}
          </option>
        ))}
      </select>
      {format === "12-hour" && (
        <select
          id={`${id}-occur`}
          value={timeOccur}
          onChange={handleTimeOccurChange}
          disabled={disabled}
        >
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      )}
      {error && <span className="text-danger error-message">{error}</span>}
    </div>
  );
}
