import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Navbar1 } from "../molecule/Navbar/Navbar";
import { SideMenu } from "../molecule/SideBarMenu/SideBarMenu";
import { Footer } from "../molecule/Footer/Footer";
import { Breadcrumb } from "../atom/Breadcrumb/Breadcrumb";
import { clearUserDetails } from "../../redux/userDetails/userDetailsSlice";

export function DashboardLayout() {
  const userDetails = useSelector((state: any) => state.userDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = () => {
      const loginTime = localStorage.getItem("loginTime");
      if (loginTime) {
        const loginDate = new Date(loginTime);
        const currentDate = new Date();
        const diff =
          (currentDate.getTime() - loginDate.getTime()) / (1000 * 60 * 60); // difference in hours
        if (diff >= 1) {
          // Logout the user
          dispatch(clearUserDetails());
          localStorage.clear();
          navigate("/login");
        }
      }
    };

    // Immediately check session when the component mounts
    checkSession();

    // Set interval to check session periodically
    const interval = setInterval(checkSession, 1000); // Check every seconds

    const events = [
      "click",
      "mousemove",
      "keydown",
      "touchstart",
      "unload",
      "beforeunload",
    ]; // Common user activity events

    events.forEach((event) => window.addEventListener(event, checkSession));

    // Cleanup on component unmount
    return () => {
      clearInterval(interval); // Cleanup interval
      events.forEach((event) =>
        window.removeEventListener(event, checkSession)
      );
    };
  }, [dispatch, navigate]);

  return (
    <div className="container-scroll">
      <Navbar1 userDetails={userDetails} />
      <div className="container-fluid page-body-wrapper">
        <SideMenu userDetails={userDetails} />

        <div className="main-panel">
          <div className="content-wrapper">
            <Breadcrumb />
            <div className="row">
              <div className="col-12 grid-margin">
                <Outlet />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
