import { useSelector } from "react-redux";
import { ChangeEvent, useEffect, useState } from "react";
import { Input } from "../../../../atom/Input/Input";
import { ModalComponent } from "../../../../molecule/Modal/ModalComponent";
import { showToastMessage } from "../../../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../../../constants/variables";
import { Textarea } from "../../../../atom/Textarea/Textarea";
import { formatDate } from "../../../../../services/utils/formatDate";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
  ValidationRules,
} from "../../../../../services/utils/validateDataAsPerRules";
import {
  createCaseNotesMedication,
  updateMedicationCaseNote,
} from "../../../../../services/caseNotes/caseNotes";

const defaultFormValues = {
  medicationName: "",
  sig: "",
  prescribersName: "",
  startDate: "",
  stopDate: "",
};

const validationRules: ValidationRules = {
  medicationName: {
    required: false,
    dataType: "any",
    displayName: "Medication Name",
    characterLimit: 100,
  },
  sig: {
    required: false,
    dataType: "any",
    displayName: "SIG",
    characterLimit: 200,
  },
  prescribersName: {
    required: false,
    dataType: "text",
    displayName: "Prescriber's Name",
    characterLimit: 50,
  },
  startDate: {
    required: true,
    dataType: "date",
    displayName: "Start Date",
  },
  stopDate: {
    required: true,
    dataType: "date",
    displayName: "Stop Date",
    isPastDateAllow: false,
    isFutureDateAllow: true,
  },
};

interface CreateMedicationProps {
  getMedicationData: () => void;
  editMedicationDetails: any;
  editMode: boolean;
  setEditMode: (value: boolean) => void;
  resetEditData: () => void;
}

export function AddMedications({
  getMedicationData,
  editMedicationDetails,
  editMode,
  setEditMode,
  resetEditData,
}: CreateMedicationProps) {
  const caseNotesDetails = useSelector((state: any) => state.activeCaseNotes);
  const caseNoteId = caseNotesDetails?.caseNotesDetails?.caseNoteId;
  const [editFlag, setEditFlag] = useState(false);

  const [formData, setFormData] = useState({
    values: defaultFormValues,
    errors: defaultFormValues,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (
    event: ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = event.target;
    const fieldError = validateFieldAsPerRules({
      rules: validationRules,
      fieldName: name,
      value,
    });

    setFormData((prevFormData) => ({
      values: { ...prevFormData.values, [name]: value },
      errors: { ...prevFormData.errors, [name]: fieldError },
    }));
  };

  const handleReset = () => {
    setFormData({
      values: defaultFormValues,
      errors: defaultFormValues,
    });
  };

  const handleClose = () => {
    if (editMedicationDetails) {
      handleReset();
      resetEditData();
    }
  };

  const handleSubmit = async () => {
    const formErrors = validateDataAsPerRules({
      formData: formData.values,
      rules: validationRules,
    });
    if (Object.values(formErrors).some((error) => error !== "")) {
      setFormData({
        ...formData,
        errors: formErrors,
      });
      return;
    }
    const requestFormObject = {
      ...formData.values,
      caseNoteDetailId: caseNoteId,
      startDate: formatDate(formData.values.startDate),
      stopDate: formatDate(formData.values.stopDate),
    };
    let addMedicationResponse;
    if (editFlag) {
      addMedicationResponse = await updateMedicationCaseNote({
        requestObject: requestFormObject,
        medicationId: editMedicationDetails.id,
      });
    } else {
      addMedicationResponse =
        await createCaseNotesMedication(requestFormObject);
    }
    if (addMedicationResponse.status) {
      showToastMessage(
        addMedicationResponse.message,
        TOAST_TYPE_OPTIONS.SUCCESS,
      );
      handleReset();
      getMedicationData();
      setIsModalOpen(false);
      setEditMode(false);
    } else {
      showToastMessage(addMedicationResponse.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  const checkForEditFlag = () => {
    setFormData({
      values: {
        medicationName: editMedicationDetails.medicationName,
        sig: editMedicationDetails.sig,
        prescribersName: editMedicationDetails.prescribersName,
        startDate: formatDate(editMedicationDetails.startDate, "YYYY-MM-DD"),
        stopDate: formatDate(editMedicationDetails.stopDate, "YYYY-MM-DD"),
      },
      errors: defaultFormValues,
    });
    setEditFlag(true);
  };

  useEffect(() => {
    if (editMode) {
      checkForEditFlag();
      setIsModalOpen(true);
    } else {
      setEditFlag(false);
    }
  }, [editMedicationDetails, editMode]);

  return (
    <ModalComponent
      buttonLeft={editFlag ? null : "Clear"}
      buttonRight={editFlag ? "Update" : "Save & Continue"}
      title={editFlag ? "Update Medication" : "Add Medication"}
      buttonShow="Add"
      onSubmit={handleSubmit}
      closeAction={handleClose}
      showAddUserPopup={isModalOpen}
      setShowAddUserPopup={setIsModalOpen}
      showToggleAsAddButton
    >
      <div className="accordion-collapse">
        <form className="row g-3" onSubmit={handleSubmit}>
          <div className="col-lg-6 col-sm-6">
            <Input
              type="text"
              className="form-control form-control-sm"
              label={validationRules.medicationName.displayName}
              value={formData.values.medicationName}
              error={formData.errors.medicationName}
              onChange={handleChange}
              name="medicationName"
              required={validationRules.medicationName.required}
            />
          </div>
          <div className="col-lg-6 col-sm-6">
            <Input
              type="text"
              className="form-control form-control-sm"
              label={validationRules.prescribersName.displayName}
              value={formData.values.prescribersName}
              error={formData.errors.prescribersName}
              onChange={handleChange}
              name="prescribersName"
              required={validationRules.prescribersName.required}
            />
          </div>
          <div className="col-lg-12 col-sm-12">
            <Textarea
              className="form-control form-control-sm"
              placeholder="Text"
              rows={4}
              label={validationRules.sig.displayName}
              value={formData.values.sig}
              error={formData.errors.sig}
              onChange={handleChange}
              name="sig"
              required={validationRules.sig.required}
            />
          </div>
          <div className="col-lg-6 col-sm-6">
            <Input
              type="date"
              className="form-control form-control-sm"
              label={validationRules.startDate.displayName}
              value={formData.values.startDate}
              error={formData.errors.startDate}
              onChange={handleChange}
              name="startDate"
              required={validationRules.startDate.required}
            />
          </div>{" "}
          <div className="col-lg-6 col-sm-6">
            <Input
              type="date"
              className="form-control form-control-sm"
              label={validationRules.stopDate.displayName}
              value={formData.values.stopDate}
              error={formData.errors.stopDate}
              onChange={handleChange}
              name="stopDate"
              required={validationRules.stopDate.required}
              min={new Date().toISOString().split("T")[0]}
            />
          </div>
        </form>
      </div>
    </ModalComponent>
  );
}
