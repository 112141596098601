/* eslint-disable complexity */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../components/atom/Buttons/Button";
import { InputDropdown } from "../../../components/atom/InputDropDown/InputDropDown";
import { Input } from "../../../components/atom/Input/Input";
import { addPatientContact } from "../../../services/patients/patients";
import { getAllCountryAndStates } from "../../../services/dropDownMenuOptions/dropDownMenuOptions";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import { setContactDetails } from "../../../redux/activePatient/activePatientSlice";
import { validateEmail } from "../../../services/validation/email";
import {
  validateMobileNumber,
  validateMobileNumberAndNullCheck,
} from "../../../services/validation/mobileNumber";
import { isUserHaveOnlyViewPermission } from "../../../services/users/users";
import { allKeysEmpty } from "../../../services/utils/allKeyEmpty";
import Logger from "../../../utils/Logger";

interface PatientContactInfoProps {
  nextTab: () => void;
}
export function PatientContactInfo({ nextTab }: PatientContactInfoProps) {
  const dispatch = useDispatch();
  const activePatient = useSelector((state: any) => state.activePatient);
  const loginUserDetails = useSelector((state: any) => state.userDetails);

  const [isLoadingSaveButtonStatus, setIsLoadingSaveButtonStatus] = useState(
    false
  );
  const [countryMasterData, setCountryMasterData] = useState([]);
  const [stateMasterData, setStateMasterData] = useState([]);
  const [cityMasterData, setCityMasterData] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState<any>([]);
  const [zipCodeList, setZipCodeList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [viewPermissionOnlyFlag, setViewPermissionOnlyFlag] = useState(false);
  const [localFormData, setLocalFormData] = useState({
    address1: "",
    address2: "",
    preferredPhone: "",
    mobileNumber: "",
    email: "",
    zipCode: "",
    country: "",
    state: "",
    city: "",
  });
  const [errors, setErrors] = useState({
    address1: "",
    address2: "",
    preferredPhone: "",
    mobileNumber: "",
    email: "",
    zipCode: "",
    country: "",
    state: "",
    city: "",
  });
  const handleChange = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target;

    if (name === "address1" || name === "address2") {
      if (value.length > 50) {
        const placeholder = event.target.getAttribute("placeholder");
        setErrors({
          ...errors,
          [name]: `${placeholder} cannot exceed 50 characters`,
        });
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    }
    if (name === "email") {
      const validateEmailResponse = validateEmail(value);
      if (!validateEmailResponse.status) {
        setErrors({
          ...errors,
          [name]: validateEmailResponse.message || "InValid email",
        });
      } else {
        setErrors({ ...errors, [name]: "" });
      }
      if (value.length === 0) {
        setErrors({ ...errors, [name]: "" });
      }
    }

    if (name === "preferredPhone") {
      const validateMobileNumberResponse = validateMobileNumberAndNullCheck(
        value,
        "Preferred Phone"
      );
      if (validateMobileNumberResponse.status) {
        setErrors({ ...errors, [name]: "" });
      } else {
        setErrors({
          ...errors,
          [name]:
            validateMobileNumberResponse.message ||
            "Preferred Phone is invalid",
        });
      }
    }
    if (name === "mobileNumber") {
      if (value.length > 0) {
        const validateMobileNumberResponse = validateMobileNumber(
          value,
          "Mobile Phone"
        );
        if (validateMobileNumberResponse.status) {
          setErrors({ ...errors, [name]: "" });
        } else {
          setErrors({
            ...errors,
            [name]:
              validateMobileNumberResponse.message ||
              "Mobile number is invalid",
          });
        }
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    }
    if (name === "country") {
      if (!value) {
        setErrors({ ...errors, country: "Please select a country" });
      } else {
        setErrors({ ...errors, country: "" });
      }
    }
    if (name === "city") {
      if (!value) {
        setErrors({ ...errors, city: "Please select a city" });
      } else {
        setErrors({ ...errors, city: "" });
      }
    }
    if (name === "state") {
      if (!value) {
        setErrors({ ...errors, state: "Please select a state1" });
      } else {
        setErrors({ ...errors, state: "" });
      }
    }
    if (name === "zipCode") {
      if (!value) {
        setErrors({ ...errors, zipCode: "Please select a zipCode" });
      } else {
        setErrors({ ...errors, zipCode: "" });
      }
    }

    setLocalFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleNext = async () => {
    const patientID = activePatient.patientDetails.id;
    const newErrors = { ...errors };

    if (localFormData.address1 === "") {
      newErrors.address1 = "Please enter address1";
    }
    if (!localFormData.country || localFormData.country === "") {
      newErrors.country = "Please enter country";
    }
    if (!localFormData.state || localFormData.state === "") {
      newErrors.state = "Please enter state";
    }
    if (!localFormData.city || localFormData.city === "") {
      newErrors.city = "Please enter city";
    }
    if (!localFormData.zipCode || localFormData.zipCode === "") {
      newErrors.zipCode = "Please enter zipCode";
    }
    if (localFormData.preferredPhone === "") {
      newErrors.preferredPhone = "Please enter preferred phone";
    }
    setErrors({ ...newErrors });

    const isError = allKeysEmpty(newErrors);

    if (!isError) {
      showToastMessage(
        "Please fill all mandatory fields",
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }
    setIsLoadingSaveButtonStatus(true);
    Logger.logInfo("Patient Contact Info for add/update", {
      data: localFormData,
    });
    const addingContactInfo = await addPatientContact(localFormData, patientID);
    setIsLoadingSaveButtonStatus(false);
    setEditMode(true);
    if (addingContactInfo.status) {
      Logger.logInfo("Patient Contact Info added", { data: addingContactInfo });
      showToastMessage(addingContactInfo.message, TOAST_TYPE_OPTIONS.SUCCESS);
      dispatch(setContactDetails(addingContactInfo.data));
      nextTab();
    } else {
      Logger.logError("failed to add/update", { data: addingContactInfo });
      showToastMessage(addingContactInfo.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  const handleClear = () => {
    setErrors({
      address1: "",
      address2: "",
      preferredPhone: "",
      mobileNumber: "",
      email: "",
      zipCode: "",
      country: "",
      state: "",
      city: "",
    });
    setLocalFormData({
      address1: "",
      address2: "",
      preferredPhone: "",
      mobileNumber: "",
      email: "",
      zipCode: "",
      country: "",
      state: "",
      city: "",
    });
  };

  const handleCityChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    if (!e.target.value || e.target.value === "") {
      setErrors({ ...errors, city: "Please select a city" });
    } else {
      setErrors({ ...errors, city: "" });
      const cityListMaster: any = cityMasterData.find(
        (cities: any) => cities.cityName === e.target.value
      );
      const zipCodeListOptions = cityListMaster.zipCodes.map(
        (zipCode: string) => ({
          label: zipCode,
          value: zipCode,
        })
      );

      setZipCodeList(zipCodeListOptions);
      setLocalFormData({
        ...localFormData,
        city: e.target.value,
        zipCode: cityListMaster.zipCode,
      });
    }
  };

  const handleStateChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    if (!e.target.value || e.target.value === "") {
      setErrors({ ...errors, state: "Please select a state" });
    } else {
      setErrors({ ...errors, state: "" });
      setLocalFormData({
        ...localFormData,
        state: e.target.value,
        city: "",
        zipCode: "",
      });
      const cityListMaster: any = stateMasterData.find(
        (state: any) => state.stateName === e.target.value
      );
      if (cityListMaster?.cities) {
        const cityListOptions = cityListMaster.cities.map(
          (city: { cityName: string }) => ({
            label: city.cityName,
            value: city.cityName,
          })
        );
        setCityMasterData(cityListMaster.cities);
        setCityList(cityListOptions);
      } else {
        setCityList([]);
      }
    }
  };

  const handleCountryChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    if (!e.target.value || e.target.value === "") {
      setErrors({ ...errors, country: "Please select a country" });
    } else {
      setErrors({ ...errors, country: "" });
      setLocalFormData({
        ...localFormData,
        country: e.target.value,
        zipCode: "",
        state: "",
        city: "",
      });
      const stateListMaster: any = countryMasterData.find(
        (country: any) => country.countryName === e.target.value
      );
      setStateMasterData(stateListMaster.states);
      if (stateListMaster) {
        const stateListOptions = stateListMaster.states.map(
          (state: { stateName: string }) => ({
            label: state.stateName,
            value: state.stateName,
          })
        );
        setStateList(stateListOptions);
      } else {
        setStateList([]);
      }
    }
  };

  const checkForEditFlag = async () => {
    const { contactDetails } = activePatient;
    if (activePatient.patientDetails.id === "") {
      showToastMessage(
        "Patient is not been selected.",
        TOAST_TYPE_OPTIONS.ERROR
      );
    }
    if (contactDetails.primaryAddress) {
      if (contactDetails.country) {
        let countryMasterList = countryMasterData;
        if (countryMasterList.length === 0) {
          countryMasterList = await getAllCountryAndStates();
        }

        const countryListMaster: any = countryMasterList.find(
          (country: any) => country.countryName === contactDetails?.country
        );
        if (countryListMaster) {
          const stateListOptions = countryListMaster.states.map(
            (state: { stateName: string }) => ({
              label: state.stateName,
              value: state.stateName,
            })
          );
          setStateMasterData(countryListMaster.states);
          setStateList(stateListOptions);

          const stateListMaster: any = countryListMaster.states.find(
            (state: { stateName: string }) =>
              state.stateName === contactDetails.state
          );

          const cityListOptions = stateListMaster.cities.map(
            (city: { cityName: string }) => ({
              label: city.cityName,
              value: city.cityName,
            })
          );
          setCityList(cityListOptions);
          if (stateListMaster.cities) {
            const cityListMaster: any = stateListMaster.cities.find(
              (city: { cityName: string }) =>
                city.cityName === contactDetails.city
            );
            if (cityListMaster.zipCodes) {
              const zipCodeListOptions = cityListMaster.zipCodes.map(
                (zipCode: string) => ({
                  label: zipCode,
                  value: zipCode,
                })
              );

              setZipCodeList(zipCodeListOptions);
            }
          }
        }
      }
      setLocalFormData({
        address1: contactDetails?.primaryAddress,
        address2: contactDetails?.secondaryAddress,
        preferredPhone: contactDetails?.preferredPhone,
        mobileNumber: contactDetails?.mobileNumber,
        email: contactDetails?.email,
        zipCode: contactDetails?.zipCode,
        country: contactDetails?.country,
        state: contactDetails?.state,
        city: contactDetails?.city,
      });
    }
    setEditMode(activePatient.editStatusFlag);

    if (loginUserDetails) {
      const isOnlyViewPermission = isUserHaveOnlyViewPermission({
        permissions: loginUserDetails.userPermission,
        module: "PATIENT",
      });

      if (isOnlyViewPermission) {
        setViewPermissionOnlyFlag(true);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const countryListResponse = await getAllCountryAndStates();
      setCountryMasterData(countryListResponse);

      const countryOptions = countryListResponse.reduce(
        (
          result: { label: string; value: string }[],
          item: { countryName: string }
        ) => {
          result.push({ label: item.countryName, value: item.countryName });
          return result;
        },
        []
      );
      setCountryList(countryOptions);
      checkForEditFlag();
    };
    fetchData();
  }, []);

  useEffect(() => {
    const { contactDetails } = activePatient;
    if (contactDetails) {
      const countryListMaster: any = countryMasterData.find(
        (country: any) => country.countryName === contactDetails?.country
      );
      if (countryListMaster) {
        const stateListOptions = countryListMaster.states.map(
          (state: { stateName: string }) => ({
            label: state.stateName,
            value: state.stateName,
          })
        );
        setStateList(stateListOptions);

        const stateListMaster: any = countryListMaster.states.find(
          (state: { stateName: string }) =>
            state.stateName === contactDetails.state
        );

        const cityListOptions = stateListMaster.cities.map(
          (city: { cityName: string }) => ({
            label: city.cityName,
            value: city.cityName,
          })
        );
        setCityList(cityListOptions);
        if (stateListMaster.cities) {
          const cityListMaster: any = stateListMaster.cities.find(
            (city: { cityName: string }) =>
              city.cityName === contactDetails.city
          );
          if (cityListMaster.zipCodes) {
            const zipCodeListOptions = cityListMaster.zipCodes.map(
              (zipCode: string) => ({
                label: zipCode,
                value: zipCode,
              })
            );

            setZipCodeList(zipCodeListOptions);
          }
        }
      }
    }
  }, [countryMasterData]);

  return (
    <div
      className="tab-content col-lg-12 col-sm-12 form-container"
      id="v-pills-tabContent"
    >
      <div
        className="tab-pane fade show active"
        id="v-pills-patient"
        role="tabpanel"
        aria-labelledby="v-pills-patient-tab"
      >
        <h6 className="border-bottom border-top p-3">Contact Information</h6>

        <form className="row g-4 p-3">
          <div className="col-lg-6 col-sm-6">
            <Input
              label="Address 1"
              required
              type="text"
              id="address1"
              name="address1"
              value={localFormData.address1}
              onChange={handleChange}
              placeholder="Address 1"
              className="form-control form-control-sm"
              disabled={viewPermissionOnlyFlag}
              error={errors.address1}
            />
          </div>
          <div className="col-lg-6 col-sm-6">
            <Input
              label="Address 2"
              type="text"
              id="address2"
              name="address2"
              value={localFormData.address2}
              onChange={handleChange}
              placeholder="Address 2"
              className="form-control form-control-sm"
              disabled={viewPermissionOnlyFlag}
              error={errors.address2}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <InputDropdown
              label="Country"
              required
              options={countryList}
              className="form-select form-select-sm"
              onSelect={handleCountryChange}
              name="country"
              value={localFormData.country}
              disabled={viewPermissionOnlyFlag}
              error={errors.country}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <InputDropdown
              label="State"
              required
              options={stateList}
              className="form-select form-select-sm"
              onSelect={handleStateChange}
              name="state"
              value={localFormData.state}
              disabled={viewPermissionOnlyFlag}
              error={errors.state}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <InputDropdown
              label="City"
              required
              options={cityList}
              className="form-select form-select-sm"
              onSelect={handleCityChange}
              name="city"
              value={localFormData.city}
              disabled={viewPermissionOnlyFlag}
              error={errors.city}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <InputDropdown
              label="ZipCode"
              required
              options={zipCodeList}
              className="form-select form-select-sm"
              onSelect={handleChange}
              name="zipCode"
              value={localFormData.zipCode}
              disabled={viewPermissionOnlyFlag}
              error={errors.zipCode}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Input
              label="Preferred Phone"
              required
              type="text"
              id="preferredPhone"
              name="preferredPhone"
              value={localFormData.preferredPhone}
              onChange={handleChange}
              placeholder="Preferred Phone"
              className="form-control form-control-sm"
              disabled={viewPermissionOnlyFlag}
              error={errors.preferredPhone}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Input
              label="Mobile Number"
              type="text"
              id="mobileNumber"
              name="mobileNumber"
              value={localFormData.mobileNumber}
              onChange={handleChange}
              placeholder="Mobile Number"
              className="form-control form-control-sm"
              disabled={viewPermissionOnlyFlag}
              error={errors.mobileNumber}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Input
              label="Email"
              type="text"
              id="email"
              name="email"
              value={localFormData.email}
              onChange={handleChange}
              placeholder="Email"
              className="form-control form-control-sm"
              disabled={viewPermissionOnlyFlag}
              error={errors.email}
            />
          </div>
          <div className="col-12 d-flex justify-content-between mt-5">
            <div>
              {!editMode && (
                <Button
                  onClick={handleClear}
                  variant="outlined"
                  className="btn btn-danger btn-sm"
                  label="Clear"
                />
              )}
            </div>
            <div>
              <Button
                onClick={handleNext}
                variant="outlined"
                className="btn btn-primary btn-sm px-4"
                label="Save & Continue"
                disabled={viewPermissionOnlyFlag}
                isLoading={isLoadingSaveButtonStatus}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
