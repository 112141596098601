import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "../../components/atom/Cards/Cards";
import { Input } from "../../components/atom/Input/Input";
import { Button } from "../../components/atom/Buttons/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { Image } from "../../components/atom/Images/Image";
import "./Login.css";
import { login } from "../../services/login/login";
import { showToastMessage } from "../../utils/showToastMessage/showToastMessage";
import {
  PATIENT_ROLE_NAME,
  TOAST_TYPE_OPTIONS,
} from "../../constants/variables";
import { setUserDetails } from "../../redux/userDetails/userDetailsSlice";
import {
  clearActivePatient,
  resetContactDetails,
  resetProviderDetails,
  resetRaceAndEthnicityDetails,
  setActivePatient,
  setContactDetails,
  setProviderDetails,
  setRaceAndEthnicityDetails,
} from "../../redux/activePatient/activePatientSlice";
import { getPatientDetailsFromId } from "../../services/patients/patients";
import Logger from "../../utils/Logger";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
  ValidationRules,
} from "../../services/utils/validateDataAsPerRules";

export function Login() {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    officeKey: "",
  });
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    officeKey: "",
  });

  const validationRules: ValidationRules = {
    username: {
      required: true,
      dataType: "any",
      displayName: "User Name",
    },
    password: {
      required: true,
      dataType: "any",
      displayName: "Password",
    },
    officeKey: {
      required: true,
      dataType: "number",
      characterLimit: 6,
      minCharacterLimit: 6,
      displayName: "Office Key",
    },
  };
  const [isLoading, setIsLoading] = useState(false);
  const userDetails = useSelector((state: any) => state.userDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const newError = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: name,
      fieldName: name,
      value,
    });
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: newError,
    }));
  };

  const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    localStorage.removeItem("popupStatus");
    Logger.logInfo("user is trying to login", {
      data: { username: formData.username },
    });
    const formErrors = validateDataAsPerRules({
      formData,
      rules: validationRules,
    });

    setErrors((prev) => ({ ...prev, ...formErrors }));
    if (Object.keys(formErrors).length > 0) {
      showToastMessage("Please fill correct value", TOAST_TYPE_OPTIONS.ERROR);
      return;
    }
    setIsLoading(true);
    const response = await login(formData);
    setIsLoading(false);
    if (response.status) {
      Logger.logInfo("user login successful", {
        data: { username: formData.username },
      });
      if (response.data.data.isPasswordReset) {
        showToastMessage(
          "Please update the password",
          TOAST_TYPE_OPTIONS.SUCCESS
        );
        navigate("/update-password");
      } else {
        dispatch(setUserDetails({ ...response.data }));
        dispatch(clearActivePatient());
        if (response.data.roleName === PATIENT_ROLE_NAME) {
          const patientInfo = await getPatientDetailsFromId(response.data.id);

          const {
            contactInformation,
            providerInfo,
            raceEthnicityDTO,
            ...activePatientInfo
          } = patientInfo;
          dispatch(setActivePatient(activePatientInfo));
          if (contactInformation) {
            dispatch(setContactDetails(contactInformation));
          } else {
            dispatch(resetContactDetails());
          }
          if (providerInfo) {
            dispatch(setProviderDetails(providerInfo));
          } else {
            dispatch(resetProviderDetails(providerInfo));
          }
          if (raceEthnicityDTO) {
            dispatch(setRaceAndEthnicityDetails(raceEthnicityDTO));
          } else {
            dispatch(resetRaceAndEthnicityDetails(raceEthnicityDTO));
          }
        }
        // Store the login time in localStorage
        localStorage.setItem("loginTime", new Date().toISOString());
        showToastMessage("login successful", TOAST_TYPE_OPTIONS.SUCCESS);
        navigate("/dashboard");
      }
    } else {
      Logger.logError("user login failed");
      showToastMessage(response.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  useEffect(() => {
    if (userDetails.email !== "") {
      navigate("/dashboard");
    }
  }, [userDetails.email, navigate]);

  return (
    <form className="auth-container" onSubmit={handleLogin}>
      <Card className="card-container">
        <Image
          src="assets/images/MDTel-logo.png"
          alt="Image Alt Text"
          className="md-tel-logo"
        />
        <div>
          <Input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            placeholder="Enter your login name"
            className="input-styling input-margin"
            label="Login Name:"
            labelClassName="mb-2"
            error={errors.username}
          />
        </div>
        <div>
          <Input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Enter your password"
            className="input-styling input-margin"
            label="Password:"
            labelClassName="mb-2"
            error={errors.password}
          />
        </div>
        <div>
          <Input
            type="text"
            id="officeKey"
            name="officeKey"
            value={formData.officeKey}
            onChange={handleChange}
            placeholder="Enter your Office Key"
            className="input-styling input-margin"
            label={validationRules.officeKey.displayName}
            labelClassName="mb-2"
            error={errors.officeKey}
          />
        </div>
        <div className="mb-3 text-end">
          <Link to="/forget-password" className="text-decoration-none">
            Forgot password?
          </Link>
        </div>
        <Button
          type="submit"
          label="Login"
          className=" button default-btn btn btn-gradient-primary login-btn"
          isLoading={isLoading}
        />{" "}
      </Card>
    </form>
  );
}
