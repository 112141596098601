/* eslint-disable complexity */
import React, { useEffect, useState } from "react";
import { ModalComponent } from "../../molecule/Modal/ModalComponent";
import { InputDropdown } from "../../atom/InputDropDown/InputDropDown";
import { Input } from "../../atom/Input/Input";
import {
  CreateOrganizationRecord,
  createOrganization,
  updateOrganization,
} from "../../../services/masterData/masterData";
import {
  getAllCountryAndStates,
  getOptionsForDropdown,
} from "../../../services/dropDownMenuOptions/dropDownMenuOptions";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { allKeysEmpty } from "../../../services/utils/allKeyEmpty";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../services/utils/validateDataAsPerRules";

interface CreateNewOrgOption {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  buttonTitle: string;
  onSubmitAction: () => void;
  editData: Record<string, string> | undefined;
}

export function CreateNewOrganization({
  show,
  setShow,
  buttonTitle,
  onSubmitAction,
  editData,
}: CreateNewOrgOption) {
  const [formData, setFormData] = useState({
    orgName: "",
    orgType: "",
    address1: "",
    address2: "",
    zipCode: "",
    country: "",
    state: "",
    city: "",
    contactNo: "",
    email: "",
    orgId: "",
    orgIdNpi: "",
    otherNo: "",
    fax: "",
  });
  const validationRules: any = {
    orgName: {
      required: true,
      dataType: "textWithNumber",
      characterLimit: 50,
      displayName: "Organization Name",
    },
    orgType: {
      required: false,
      dataType: "number",
      characterLimit: 150,
      displayName: "Organization Type",
    },
    address1: {
      required: true,
      dataType: "any",
      characterLimit: 50,
      displayName: "Address 1",
    },
    address2: {
      required: false,
      dataType: "any",
      characterLimit: 50,
      displayName: "Address 2",
    },
    zipCode: {
      required: true,
      dataType: "number",
      characterLimit: 10,
      displayName: "Zip Code",
    },
    country: {
      required: true,
      dataType: "text",
      characterLimit: 100,
      displayName: "Country",
    },
    state: {
      required: true,
      dataType: "text",
      characterLimit: 100,
      displayName: "State",
    },
    city: {
      required: true,
      dataType: "text",
      characterLimit: 100,
      displayName: "City",
    },
    contactNo: {
      required: false,
      dataType: "number",
      characterLimit: 10,
      minCharacterLimit: 10,
      displayName: "Contact-Number",
    },
    email: {
      required: false,
      dataType: "email",
      characterLimit: 150,
      displayName: "Email",
    },
    orgId: {
      required: false,
      dataType: "textOrNumber",
      characterLimit: 20,
      displayName: "Org ID",
    },
    orgIdNpi: {
      required: false,
      dataType: "number",
      characterLimit: 50,
      displayName: "Org NPI",
    },
    otherNo: {
      required: false,
      dataType: "number",
      characterLimit: 10,
      displayName: "Other Number",
    },
    fax: {
      required: false,
      dataType: "number",
      characterLimit: 10,
      displayName: "Fax",
    },
  };
  const [errors, setErrors] = useState({
    orgName: "",
    orgType: "",
    address1: "",
    address2: "",
    zipCode: "",
    country: "",
    state: "",
    city: "",
    email: "",
    contactNo: "",
    orgId: "",
    orgIdNpi: "",
    otherNo: "",
    fax: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [organizationTypeOptions, setOrganizationTypeOptions] = useState([]);
  const [countryMasterData, setCountryMasterData] = useState([]);
  const [stateMasterData, setStateMasterData] = useState([]);
  const [cityMasterData, setCityMasterData] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState<any>([]);
  const [zipCodeList, setZipCodeList] = useState([]);

  const handleChange = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const { name, value } = event.target;

    const newErrors = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: name,
      fieldName: name,
      value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: newErrors,
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCityChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    if (name === "city") {
      if (!value || value === "") {
        setErrors({ ...errors, city: "Please select a city" });
      } else {
        setErrors({ ...errors, city: "" });
        const cityListMaster: any = cityMasterData.find(
          (cities: any) => cities.cityName === e.target.value,
        );
        const zipCodeListOptions = cityListMaster.zipCodes.map(
          (zipCode: string) => ({
            label: zipCode,
            value: zipCode,
          }),
        );

        setZipCodeList(zipCodeListOptions);
        setFormData({
          ...formData,
          city: e.target.value,
          zipCode: cityListMaster.zipCode,
        });
      }
    }
  };

  const handleStateChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    if (name === "state") {
      if (!value || value === "") {
        setErrors({ ...errors, state: "Please select a state" });
      } else {
        setErrors({ ...errors, state: "" });
        setFormData({
          ...formData,
          state: e.target.value,
          city: "",
          zipCode: "",
        });
        const cityListMaster: any = stateMasterData.find(
          (state: any) => state.stateName === e.target.value,
        );
        if (cityListMaster?.cities) {
          const cityListOptions = cityListMaster.cities.map(
            (city: { cityName: string }) => ({
              label: city.cityName,
              value: city.cityName,
            }),
          );
          setCityMasterData(cityListMaster.cities);
          setCityList(cityListOptions);
        } else {
          setCityList([]);
        }
      }
    }
  };

  const handleCountryChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    if (name === "country") {
      if (!value || value === "") {
        setErrors({ ...errors, country: "Please select a country" });
      } else {
        setErrors({ ...errors, country: "" });
        setFormData({
          ...formData,
          country: e.target.value,
          zipCode: "",
          state: "",
          city: "",
        });
        const stateListMaster: any = countryMasterData.find(
          (country: any) => country.countryName === e.target.value,
        );
        setStateMasterData(stateListMaster.states);
        if (stateListMaster) {
          const stateListOptions = stateListMaster.states.map(
            (state: { stateName: string }) => ({
              label: state.stateName,
              value: state.stateName,
            }),
          );
          setStateList(stateListOptions);
        } else {
          setStateList([]);
        }
      }
    }
  };

  const handleSubmit = async (): Promise<void> => {
    const formErrors = validateDataAsPerRules({
      formData,
      rules: validationRules,
    });
    setErrors({
      ...errors,
      ...formErrors,
    });

    const isInvalid = allKeysEmpty(formErrors);
    if (!isInvalid) {
      showToastMessage("Invalid data", TOAST_TYPE_OPTIONS.ERROR);
      return;
    }
    setErrors({
      orgName: "",
      orgType: "",
      address1: "",
      address2: "",
      zipCode: "",
      country: "",
      state: "",
      city: "",
      email: "",
      contactNo: "",
      orgId: "",
      orgIdNpi: "",
      otherNo: "",
      fax: "",
    });

    const requestParameterObj: CreateOrganizationRecord = {
      organizationName: formData.orgName!,
      organizationType: formData.orgType!,
      address1: formData.address1!,
      address2: formData.address2 || "",
      zipCode: formData.zipCode!,
      country: formData.country!,
      state: formData.state!,
      city: formData.city!,
      contactNumber: formData.contactNo || "",
      email: formData.email || "",
      organizationId: formData.orgId || "",
      npi: formData.orgIdNpi || "",
      otherNo: formData.otherNo || "",
      fax: formData.fax || "",
    };

    const response =
      editMode && editData?.id
        ? await updateOrganization(requestParameterObj, editData.id)
        : await createOrganization(requestParameterObj);
    if (response.status) {
      showToastMessage(response.message, TOAST_TYPE_OPTIONS.SUCCESS);
      onSubmitAction();
      setShow(false);
      setFormData({
        orgName: "",
        orgType: "",
        address1: "",
        address2: "",
        zipCode: "",
        country: "",
        state: "",
        city: "",
        contactNo: "",
        email: "",
        orgId: "",
        orgIdNpi: "",
        otherNo: "",
        fax: "",
      });
    } else {
      showToastMessage(response.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  const checkForUpdateFlow = () => {
    if (editData) {
      if (editData.country) {
        const countryListMaster: any = countryMasterData.find(
          (country: any) => country.countryName === editData?.country,
        );
        if (countryListMaster) {
          const stateListOptions = countryListMaster.states.map(
            (state: { stateName: string }) => ({
              label: state.stateName,
              value: state.stateName,
            }),
          );
          setStateMasterData(countryListMaster.states);
          setStateList(stateListOptions);

          const stateListMaster: any = countryListMaster.states.find(
            (state: { stateName: string }) =>
              state.stateName === editData.state,
          );

          const cityListOptions = stateListMaster.cities.map(
            (city: { cityName: string }) => ({
              label: city.cityName,
              value: city.cityName,
            }),
          );
          setCityList(cityListOptions);
          if (stateListMaster.cities) {
            const cityListMaster: any = stateListMaster.cities.find(
              (city: { cityName: string }) => city.cityName === editData.city,
            );
            if (cityListMaster.zipCodes) {
              const zipCodeListOptions = cityListMaster.zipCodes.map(
                (zipCode: string) => ({
                  label: zipCode,
                  value: zipCode,
                }),
              );

              setZipCodeList(zipCodeListOptions);
            }
          }
        }
      }
      setFormData({
        orgName: editData.organizationName,
        orgType: editData.organizationType,
        address1: editData.address1,
        address2: editData.address2,
        zipCode: editData.zipCode,
        country: editData.country,
        state: editData.state,
        city: editData.city,
        contactNo: editData.contactNumber,
        email: editData.email,
        orgId: editData.organizationId,
        orgIdNpi: editData.npi,
        otherNo: editData.otherNumber,
        fax: editData.fax,
      });
      setShow(!show);
      setEditMode(true);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const organizationTypeListOptions =
        await getOptionsForDropdown("OrganizationType");
      setOrganizationTypeOptions(organizationTypeListOptions);
    };
    fetchData();
  }, []);

  useEffect(() => {
    checkForUpdateFlow();
  }, [editData]);

  const addNewOrganizationButtonAction = (status: boolean) => {
    setShow(status);
    setFormData({
      orgName: "",
      orgType: "",
      address1: "",
      address2: "",
      zipCode: "",
      country: "",
      state: "",
      city: "",
      contactNo: "",
      email: "",
      orgId: "",
      orgIdNpi: "",
      otherNo: "",
      fax: "",
    });
    setEditMode(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      const countryListResponse = await getAllCountryAndStates();
      setCountryMasterData(countryListResponse);

      const countryOptions = countryListResponse.reduce(
        (
          result: { label: string; value: string }[],
          item: { countryName: string },
        ) => {
          result.push({ label: item.countryName, value: item.countryName });
          return result;
        },
        [],
      );

      setCountryList(countryOptions);
    };
    fetchData();
  }, []);
  return (
    <div>
      <ModalComponent
        showAddUserPopup={show}
        setShowAddUserPopup={addNewOrganizationButtonAction}
        title={editMode ? "Update Organization" : "Add Organization"}
        buttonShow={buttonTitle}
        buttonLeft={editMode ? null : "Clear"}
        buttonRight={editMode ? "Update" : "Save & Continue"}
        onSubmit={handleSubmit}
        additionalToggleButtonClasses="text-end btn btn-primary btn-sm d-flex align-items-center px-2 py-1"
        size="lg"
        additionalResetButtonClasses="btn btn-primary btn-sm px-5 ms-3 text-white"
      >
        <form>
          <div className="row g-3">
            <div className="col-lg-6 col-sm-6">
              <Input
                type="text"
                id="orgName"
                name="orgName"
                value={formData.orgName}
                onChange={handleChange}
                placeholder="Enter First Name"
                className="form-control form-control-sm"
                label={validationRules.orgName.displayName}
                required={validationRules.orgName.required}
                error={errors.orgName}
              />
            </div>
            <div className="col-lg-6 col-sm-6">
              <InputDropdown
                options={organizationTypeOptions}
                className="form-select form-select-sm"
                name="orgType"
                value={formData.orgType}
                onSelect={handleChange}
                label={validationRules.orgType.displayName}
                required={validationRules.orgType.required}
                error={errors.orgType}
              />
            </div>
            <div className="col-lg-6 col-sm-6">
              <Input
                type="address1"
                id="address1"
                name="address1"
                value={formData.address1}
                onChange={handleChange}
                placeholder="Address 1"
                className="form-control form-control-sm"
                label={validationRules.address1.displayName}
                required={validationRules.address1.required}
                error={errors.address1}
              />
            </div>
            <div className="col-lg-6 col-sm-6">
              <Input
                type="address2"
                id="address2"
                name="address2"
                value={formData.address2}
                onChange={handleChange}
                placeholder="Address 2"
                className="form-control form-control-sm"
                label={validationRules.address2.displayName}
                required={validationRules.address2.required}
                error={errors.address2}
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <InputDropdown
                options={countryList}
                className="form-select form-select-sm"
                onSelect={handleCountryChange}
                name="country"
                value={formData.country}
                label={validationRules.country.displayName}
                required={validationRules.country.required}
                error={errors.country}
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <InputDropdown
                options={stateList}
                className="form-select form-select-sm"
                onSelect={handleStateChange}
                name="state"
                value={formData.state}
                label={validationRules.state.displayName}
                required={validationRules.state.required}
                error={errors.state}
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <InputDropdown
                options={cityList}
                className="form-select form-select-sm"
                onSelect={handleCityChange}
                name="city"
                value={formData.city}
                label={validationRules.city.displayName}
                required={validationRules.city.required}
                error={errors.city}
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <InputDropdown
                options={zipCodeList}
                className="form-select form-select-sm"
                onSelect={handleChange}
                name="zipCode"
                value={formData.zipCode}
                label={validationRules.zipCode.displayName}
                required={validationRules.zipCode.required}
                error={errors.zipCode}
              />
            </div>

            <div className="col-lg-3 col-sm-6">
              <Input
                type="number"
                id="contactNo"
                name="contactNo"
                value={formData.contactNo}
                onChange={handleChange}
                placeholder="xxx-xxx-xxxx"
                className="form-control form-control-sm"
                label={validationRules.contactNo.displayName}
                required={validationRules.contactNo.required}
                error={errors.contactNo}
              />
            </div>

            <div className="col-lg-3 col-sm-6">
              <Input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                className="form-control form-control-sm"
                label={validationRules.email.displayName}
                required={validationRules.email.required}
                error={errors.email}
              />
            </div>

            <div className="col-lg-3 col-sm-6">
              <Input
                type="number"
                id="orgId"
                name="orgId"
                value={formData.orgId}
                onChange={handleChange}
                placeholder="xxx-xxx-xxxx"
                className="form-control form-control-sm"
                label={validationRules.orgId.displayName}
                required={validationRules.orgId.required}
                error={errors.orgId}
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <Input
                type="number"
                id="orgIdNpi"
                name="orgIdNpi"
                value={formData.orgIdNpi}
                onChange={handleChange}
                placeholder="xxx-xxx-xxxx"
                className="form-control form-control-sm"
                label={validationRules.orgIdNpi.displayName}
                required={validationRules.orgIdNpi.required}
                error={errors.orgIdNpi}
              />
            </div>
          </div>
        </form>
      </ModalComponent>
    </div>
  );
}
