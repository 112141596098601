import React from "react";
import { HorizontalTabs } from "../../../atom/HorizontalTab/HorizontalTab";
import { DiagnosticResults } from "./DiagnosticResults/DiagnosticResults";
import { LabResults } from "./LabResults/LabResults";

const tabContents = [
  {
    key: "1",
    title: "Lab Results",
    content: <LabResults />,
  },
  {
    key: "2",
    title: "Diagnostic Results",
    content: <DiagnosticResults />,
  },
];

export function RecentTests() {
  return (
    <div className="accordion-collapse">
      <div className="table-responsive care-manager-table">
        <HorizontalTabs tabs={tabContents} />
      </div>
    </div>
  );
}
