import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "../../../atom/Table/Table";
import { AddVitals } from "./AddVitals/AddVitals";
import {
  getVitalCaseNote,
  getUnitDropDownValues,
  getCaseNotesVitalById,
  deleteCaseNotesVitalByIdService,
} from "../../../../services/caseNotes/caseNotes";
import { TOAST_TYPE_OPTIONS } from "../../../../constants/variables";
import { showToastMessage } from "../../../../utils/showToastMessage/showToastMessage";
import { FullScreenLoader } from "../../../atom/FullScreenLoader/FullScreenLoader";

const tableHeaders = {
  createdDate: "Date",
  height: "Height",
  weight: "Weight",
  bmi: "BMI",
  bp: "BP",
  pulse: "Pulse",
  temperature: "Temperature",
  respiratoryRate: "Respiratory Rate",
  ozSaturation: "Oxygen Saturation",
  pain: "Pain",
  action: "Action",
};
interface Vital {
  id: number;
  height: string;
  heightUOMId: number;
  weight: string;
  weightUOMId: number;
  bmi: string;
  bp: number;
  bpUOMId: number;
  pulse: string;
  pulseUOMId: number;
  temperature: string;
  temperatureUOMId: number;
  respiratoryRate: string;
  respiratoryRateUOMId: number;
  ozSaturation: string;
  ozSaturationUOMId: number;
  pain: string;
  createdDate: string;
}
interface UnitOfMeasure {
  uomId: number;
  uom: string;
}

interface VitalItem {
  name: string;
  unitOfMeasures: UnitOfMeasure[];
}

interface VitalsProps {
  isViewMode: boolean;
}
export function Vitals({ isViewMode }: VitalsProps) {
  interface VitalList {
    id: number;
    createdDate: string;
    height: string;
    weight: string;
    bmi: string;
    bp: string;
    pulse: string;
    temperature: string;
    respiratoryRate: string;
    ozSaturation: string;
    pain: string;
  }
  const caseNotesDetails = useSelector((state: any) => state.activeCaseNotes);
  const caseNoteId = caseNotesDetails?.caseNotesDetails?.caseNoteId;
  const [fullScreenLoaderFlag, setFullScreenLoaderFlag] = useState(false);
  const [editVitalListData, seteditVitalListData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [vitalListData, setVitalListData] = useState<VitalList[]>([]);

  const fetchData = async () => {
    setFullScreenLoaderFlag(true);
    const vitalListResponse = await getVitalCaseNote(caseNoteId);
    const unitDropDownValues = await getUnitDropDownValues();
    const uomResponse = unitDropDownValues.data.dataList;
    const uomMap: Record<string, UnitOfMeasure[]> = Object.fromEntries(
      uomResponse.map((item: VitalItem) => [
        item.name.toLowerCase(),
        item.unitOfMeasures,
      ]),
    );
    const processedVitals = vitalListResponse.data.dataList.map(
      (vital: Vital) => {
        const processedVital: Partial<VitalList> = {};
        Object.entries(vital).forEach(([key, value]) => {
          if (key === "id" || typeof value === "string") {
            const uomIdKey = `${key}UOMId`;
            const uomId = vital[uomIdKey as keyof Vital];
            const uom = uomMap[key.toLowerCase()]?.find(
              (uomItem) => uomItem.uomId === uomId,
            );
            if (uom) {
              const processedValue = `${value} ${uom.uom}`;
              processedVital[key as keyof VitalList] = processedValue as any;
            } else {
              processedVital[key as keyof VitalList] = value as any;
            }
          }
        });
        return processedVital as VitalList;
      },
    );
    setVitalListData(processedVitals);
    setFullScreenLoaderFlag(false);
  };
  const editRecord = async (rowData: any) => {
    const unitDropDownValues = await getCaseNotesVitalById(rowData.id);
    seteditVitalListData(unitDropDownValues.data.data);
    setEditMode(true);
  };
  const resetEditData = () => {
    setEditMode(false);
  };
  const deleteVitals = async (rowData: any) => {
    const deleteVitalCaseNotesResponse = await deleteCaseNotesVitalByIdService(
      rowData.id,
    );
    if (deleteVitalCaseNotesResponse.status) {
      showToastMessage(
        `Vital Deleted Successfully `,
        TOAST_TYPE_OPTIONS.SUCCESS,
      );
      await fetchData();
    } else {
      showToastMessage(
        deleteVitalCaseNotesResponse.message,
        TOAST_TYPE_OPTIONS.ERROR,
      );
    }
    await fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div
      className="accordion-collapse"
      data-bs-parent="#accordionFlushExample-parent"
    >
      <FullScreenLoader show={fullScreenLoaderFlag} />
      <div className="d-flex justify-content-end mb-2">
        {isViewMode ? (
          <div />
        ) : (
          <AddVitals
            getVitalsData={fetchData}
            editVitalsDetails={editVitalListData}
            editMode={editMode}
            setEditMode={setEditMode}
            resetEditData={resetEditData}
          />
        )}
      </div>
      <div className="table-responsive care-manager-table">
        <Table
          headers={tableHeaders}
          enableEditAction={editRecord}
          isEditDisabled={() => isViewMode}
          isDeleteDisabled={() => isViewMode}
          data={vitalListData}
          enableDeleteAction={deleteVitals}
          className="table table-bordered table-striped table-hover"
        />
      </div>
    </div>
  );
}
