import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { InsurancePrimary } from "./InsurancePrimary";
import { AccordionAtom } from "../../../components/atom/Accordion/Accordion";
import { Card } from "../../../components/atom/Cards/Cards";
import { ProfileCard } from "../../../components/molecule/ProfileCard/ProfileCard";

import { getPatientInsurance } from "../../../services/insurance/insurance";

export function Insurance() {
  const activePatient = useSelector((state: any) => state.activePatient);
  const [insuranceData, setInsuranceData] = useState({
    primary: { insuranceId: "" },
    secondary: { insuranceId: "" },
  });
  const [showFullScreenLoaderStatus, setShowFullScreenLoaderStatus] =
    useState(true);

  const fetchData = async () => {
    const insuranceInfo = await getPatientInsurance(
      activePatient.patientDetails.id,
    );
    setShowFullScreenLoaderStatus(false);
    setInsuranceData(insuranceInfo);
  };
  const accordionItems = [
    {
      id: "1",
      title: "Primary",
      content: (
        <InsurancePrimary
          insuranceType="Primary"
          insuranceData={insuranceData.primary}
          onSubmitAction={fetchData}
          showFullScreenLoaderStatus={showFullScreenLoaderStatus}
          setShowFullScreenLoaderStatus={setShowFullScreenLoaderStatus}
          secondaryInsuranceId={insuranceData?.secondary?.insuranceId}
        />
      ),
    },
    {
      id: "2",
      title: "Secondary",
      content: (
        <InsurancePrimary
          insuranceType="Secondary"
          insuranceData={insuranceData.secondary}
          isDisabled={
            insuranceData?.primary?.insuranceId === undefined ||
            insuranceData?.primary?.insuranceId === ""
          }
          onSubmitAction={fetchData}
          showFullScreenLoaderStatus={showFullScreenLoaderStatus}
          setShowFullScreenLoaderStatus={setShowFullScreenLoaderStatus}
        />
      ),
    },
  ];
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <ProfileCard />
      <Card>
        <h5>Insurance</h5>
        <div>
          <AccordionAtom items={accordionItems} />
        </div>
      </Card>
    </div>
  );
}
