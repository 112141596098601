import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ChartData, ChartOptions } from "chart.js";
import { LineChart } from "../../molecule/LineChart/LineChart";

interface ChartDataValue {
  eventName: string;
  data: number[];
}

export function DisplayLineChart({ chartData }: { chartData: any }) {
  const [chartDataParsed, setChartDataParsed] = useState<ChartData<"line">>();
  const activePatient = useSelector((state: any) => state.activePatient);
  const patientId = activePatient.patientDetails?.id;

  const getColorByIndex = (
    index: number,
  ): { backgroundColor: string; borderColor: string } => {
    const colors = [
      {
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
      },
      {
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
      },
      {
        backgroundColor: "rgba(255, 206, 86, 0.2)",
        borderColor: "rgba(255, 206, 86, 1)",
      },
      {
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
      },
      {
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderColor: "rgba(153, 102, 255, 1)",
      },
      {
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        borderColor: "rgba(255, 159, 64, 1)",
      },
    ];
    const colorIndex = index % colors.length;
    return colors[colorIndex];
  };

  const options: ChartOptions<"line"> = {
    responsive: true,
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Month",
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Value",
        },
        ticks: {
          stepSize: 1, // Ensure ticks are at integer intervals
          callback(value) {
            if (Number.isInteger(value)) {
              return value; // Display only integer values
            }
            return null; // Skip non-integer values
          },
        },
      },
    },
  };

  function convertData(apiData: any) {
    if (apiData && apiData.labels) {
      const labelsSet = apiData.labels;
      const labels = Array.from(labelsSet);
      const categoryMap: { [key: string]: ChartDataValue } = {};

      if (apiData.categories) {
        apiData.categories.forEach((item: any) => {
          const { categoryValue } = item;

          if (!categoryMap[categoryValue]) {
            categoryMap[categoryValue] = {
              eventName: categoryValue,
              data: new Array(labels.length).fill(0),
            };
          }

          item.counts.forEach((count: number, index: number) => {
            categoryMap[categoryValue].data[index] = count;
          });
        });
      }

      const values = Object.values(categoryMap);

      return {
        data: {
          labels,
          values,
        },
      };
    }
    return false;
  }

  useEffect(() => {
    async function fetchData() {
      const formattedData = convertData(chartData);
      if (formattedData && formattedData.data) {
        setChartDataParsed({
          labels: formattedData.data.labels,
          datasets: formattedData.data.values.map((value, index) => {
            const { backgroundColor, borderColor } = getColorByIndex(index);
            return {
              label: value.eventName,
              data: value.data,
              backgroundColor,
              borderColor,
            };
          }),
        });
      }
    }
    fetchData();
  }, [chartData]);

  if (patientId && chartDataParsed && chartDataParsed.datasets.length > 0) {
    return (
      <div className="container h-50">
        <LineChart data={chartDataParsed} options={options} />
      </div>
    );
  }

  return <div />;
}
