import React, { useState } from "react";
import { ModalComponent } from "../../../../molecule/Modal/ModalComponent";
import { Input } from "../../../../atom/Input/Input";
import { Textarea } from "../../../../atom/Textarea/Textarea";

export function ExampleGoalModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <ModalComponent
      buttonShow="See example"
      onSubmit={() => setIsModalOpen(false)}
      setShowAddUserPopup={setIsModalOpen}
      showAddUserPopup={isModalOpen}
      title="Example of Smart Goals"
      size="lg"
      className=""
    >
      <form className="row g-3 bg-info p-1 mt-3 pb-3">
        <div className="col-12">
          <Textarea
            id="Problems"
            name="Problems"
            value="Interdum et malesuada fames ac ante ipsum primis in faucibus. Nam odio nisi, lobortis a nisi consequat, iaculis sodales quam. Donec sit amet sapien mollis, lacinia leo in, mollis nisl. Sed in orci vulputate, ultrices ex ut, blandit purus. Praesent id odio arcu. Aenean condimentum diam et tristique iaculis. Sed nec pulvinarligula. Cras eget augue lorem. Ut vestibulum tristique erat utultricies. Nam id finibus ipsum. Vivamus lectus arcu, interdum non nisi eget, maximus tristique augue. Integer dapibus pharetra arcu,sed finibus neque mattis gravida. Pellentesque vel nunc ut elit malesuada efficitur"
            onChange={() => {}}
            placeholder=""
            rows={4}
            className="form-control form-control-sm"
            label="Problems"
            disabled
            required
          />
        </div>
        <div className="col-12">
          <Textarea
            id="goals"
            name="goals"
            value="Morbi pharetra tellus nisl, vel egestas magna dignissim eu. Duisccumsan id lacus id efficitur. Sed hendrerit gravida est, sedrutrumellus egestas sed. Nunc nec mi vestibulum, gravida massa rutrum,malesuada mi. Nam sit amet nunc sapien. Nulla hendrerit enim sitamet lorem dapibus, sed facilisis risus faucibus. Aliquam sit ametjusto tristique, varius ante a, scelerisque leo."
            onChange={() => {}}
            placeholder=""
            rows={4}
            className="form-control form-control-sm"
            label="Goal"
            disabled
            required
          />
        </div>
        <div className="col-12">
          <Textarea
            id="Barrier"
            name="Barrier"
            value="Morbi pharetra tellus nisl, vel egestas magna dignissim eu. Duisccumsan id lacus id efficitur. Sed hendrerit gravida est, sedrutrumellus egestas sed. Nunc nec mi vestibulum, gravida massa rutrum,malesuada mi. Nam sit amet nunc sapien. Nulla hendrerit enim sitamet lorem dapibus, sed facilisis risus faucibus. Aliquam sit ametjusto tristique, varius ante a, scelerisque leo."
            onChange={() => {}}
            placeholder=""
            rows={4}
            className="form-control form-control-sm"
            label="Barrier"
            disabled
            required
          />
        </div>
        <div className="col-lg-6 col-sm-6">
          <Input
            type="date"
            className="form-control form-control-sm "
            placeholder="23/01/2024"
            disabled
            onChange={() => {}}
            value=""
            label="Goal due date"
          />
        </div>
      </form>
    </ModalComponent>
  );
}
