/* eslint-disable complexity */
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { ModalComponent } from "../../molecule/Modal/ModalComponent";
import { InputDropdown } from "../../atom/InputDropDown/InputDropDown";
import { Input } from "../../atom/Input/Input";
import { Button } from "../../atom/Buttons/Button";

import {
  getAllCountryAndStates,
  getOptionsForDropdown,
} from "../../../services/dropDownMenuOptions/dropDownMenuOptions";

import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";

import { getOrganizationInfoService } from "../../../services/masterData/organization";
import {
  CreatePcpRecord,
  createPcp,
  updatePcp,
} from "../../../services/masterData/pcp";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../services/utils/validateDataAsPerRules";
import { getOrganizationDetailsAPI } from "../../../dataLayer/referrals/referrals";
import { defaultFormValues, validationRules } from "./helperFunctions/constant";

interface CreateNewPCPOption {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  buttonTitle: string;
  getAllPCPDataForTable: () => void;
  editData: Record<string, string>;
  resetEditData: () => void;
}

export function CreateNewPCP({
  show,
  setShow,
  buttonTitle,
  getAllPCPDataForTable,
  editData,
  resetEditData,
}: CreateNewPCPOption) {
  type Option = {
    label: string;
    value: string;
  };
  const [formData, setFormData] = useState({
    values: defaultFormValues,
    errors: defaultFormValues,
  });
  const { values, errors } = formData;
  const [countryMasterData, setCountryMasterData] = useState([]);
  const [stateMasterData, setStateMasterData] = useState([]);
  const [cityMasterData, setCityMasterData] = useState([]);
  const [countryList, setCountryList] = useState<Option[]>([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState<any>([]);
  const [zipCodeList, setZipCodeList] = useState([]);
  const [editFlag, setEditFlag] = useState(false);

  const loginUserDetails = useSelector((state: any) => state.userDetails);
  const handleChange = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const newErrors = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: name,
      fieldName: name,
      value,
    });
    setFormData((prevState) => ({
      values: {
        ...prevState.values,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: newErrors,
      },
    }));
  };

  const checkForEditFlow = async () => {
    if (editData) {
      if (editData.country) {
        const countryListMaster: any = countryMasterData.find(
          (country: any) => country.countryName === editData?.country
        );
        if (countryListMaster) {
          const stateListOptions = countryListMaster.states.map(
            (state: { stateName: string }) => ({
              label: state.stateName,
              value: state.stateName,
            })
          );
          setStateMasterData(countryListMaster.states);
          setStateList(stateListOptions);

          const stateListMaster: any = countryListMaster.states.find(
            (state: { stateName: string }) => state.stateName === editData.state
          );

          const cityListOptions = stateListMaster.cities.map(
            (city: { cityName: string }) => ({
              label: city.cityName,
              value: city.cityName,
            })
          );
          setCityList(cityListOptions);
          if (stateListMaster.cities) {
            const cityListMaster: any = stateListMaster.cities.find(
              (city: { cityName: string }) => city.cityName === editData.city
            );
            if (cityListMaster.zipCodes) {
              const zipCodeListOptions = cityListMaster.zipCodes.map(
                (zipCode: string) => ({
                  label: zipCode,
                  value: zipCode,
                })
              );

              setZipCodeList(zipCodeListOptions);
            }
          }
        }
      }
      let formDataValues = { ...formData.values };
      if (editData.organizationId) {
        const organizationDetails = await getOrganizationInfoService(
          editData.organizationId
        );

        formDataValues = {
          ...formDataValues,
          organization: organizationDetails.data.id,
          organizationName: organizationDetails.data.organizationName,
        };

        setFormData({
          ...formData,
          values: formDataValues,
        });
      }

      setFormData({
        ...formData,
        values: {
          ...formDataValues,
          title: editData.title,
          firstName: editData.firstName,
          lastName: editData.lastName,
          address1: editData.address1,
          address2: editData.address2,
          zipCode: editData.zipCode,
          country: editData.country,
          state: editData.state,
          city: editData.city,
          officeNumber: editData.officeNumber,
          otherNumber: editData.otherNumber,
          fax: editData.fax,
          email: editData.email,
          speciality: editData.speciality,
          practice: editData.practice,
          taxonomy: editData.taxonomy,
          npi: editData.npi,
          upin: editData.upin,
          providerTypeId: editData.providerTypeId,
        },
      });
      setEditFlag(true);
      setShow(!show);
    }
  };
  const [providerTypeOptions, setProviderTypeOptions] = useState([]);

  const fetchData = async () => {
    const providerType = await getOptionsForDropdown("providerType");
    setProviderTypeOptions(providerType);
    const countryListResponse = await getAllCountryAndStates();
    setCountryMasterData(countryListResponse);
    const organizationDetails = await getOrganizationDetailsAPI(
      loginUserDetails.organizationId
    );
    if (organizationDetails.status) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        values: {
          ...prevFormData.values,
          organization: organizationDetails.data.id,
          organizationName: organizationDetails.data.organizationName,
        },
      }));
    }

    const countryOptions = countryListResponse.reduce(
      (
        result: { label: string; value: string }[],
        item: { countryName: string }
      ) => {
        result.push({ label: item.countryName, value: item.countryName });
        return result;
      },
      []
    );
    setCountryList(countryOptions);
    checkForEditFlow();
  };

  useEffect(() => {
    checkForEditFlow();
  }, [editData]);

  const handleCityChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    if (name === "city") {
      if (!value || value === "") {
        setFormData((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            city: "Please select a city",
          },
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            city: "",
          },
        }));
        const cityListMaster: any = cityMasterData.find(
          (cities: any) => cities.cityName === e.target.value
        );
        const zipCodeListOptions = cityListMaster.zipCodes.map(
          (zipCode: string) => ({
            label: zipCode,
            value: zipCode,
          })
        );

        setZipCodeList(zipCodeListOptions);
        setFormData((prevState) => ({
          ...prevState,
          values: {
            ...prevState.values,
            city: e.target.value,
            zipCode: cityListMaster.zipCode,
          },
        }));
      }
    }
  };

  const handleStateChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;

    if (name === "state") {
      if (!value || value === "") {
        setFormData((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            state: "Please select a state",
          },
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            state: "",
          },
        }));
        setFormData((prevState) => ({
          ...prevState,
          values: {
            ...prevState.values,
            state: e.target.value,
            city: "",
            zipCode: "",
          },
        }));
        const cityListMaster: any = stateMasterData.find(
          (state: any) => state.stateName === e.target.value
        );
        if (cityListMaster?.cities) {
          const cityListOptions = cityListMaster.cities.map(
            (city: { cityName: string }) => ({
              label: city.cityName,
              value: city.cityName,
            })
          );
          setCityMasterData(cityListMaster.cities);
          setCityList(cityListOptions);
        } else {
          setCityList([]);
        }
      }
    }
  };

  const handleCountryChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    if (name === "country") {
      if (!value || value === "") {
        setFormData((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            country: "Please select a country",
          },
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            country: "",
          },
        }));

        setFormData((prevState) => ({
          ...prevState,
          values: {
            ...prevState.values,
            country: e.target.value,
            zipCode: "",
            state: "",
            city: "",
          },
        }));
        const stateListMaster: any = countryMasterData.find(
          (country: any) => country.countryName === e.target.value
        );
        setStateMasterData(stateListMaster.states);
        if (stateListMaster) {
          const stateListOptions = stateListMaster.states.map(
            (state: { stateName: string }) => ({
              label: state.stateName,
              value: state.stateName,
            })
          );
          setStateList(stateListOptions);
        } else {
          setStateList([]);
        }
      }
      setFormData((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          country: "",
        },
      }));
    }
  };

  const handleNewForm = () => {
    const url = "https://npiregistry.cms.hhs.gov/search";
    window.open(url, "_blank");
  };

  const resetErrorAndValue = () => {
    setFormData({ values: defaultFormValues, errors: defaultFormValues });
  };

  const handleSubmit = async () => {
    const formErrors = validateDataAsPerRules({
      formData: values,
      rules: validationRules,
    });
    setFormData((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        ...formErrors,
      },
    }));
    if (Object.keys(formErrors).length > 0) {
      showToastMessage(
        "Please fill all the required fields",
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }
    const requestParameter: CreatePcpRecord = {
      title: values.title,
      firstName: values.firstName,
      lastName: values.lastName,
      organizationId: values.organization,
      address1: values.address1,
      address2: values.address2,
      zipCode: values.zipCode,
      country: values.country,
      state: values.state,
      city: values.city,
      officeNumber: values.officeNumber,
      otherNumber: values.otherNumber,
      fax: values.fax,
      email: values.email,
      speciality: values.speciality,
      practice: values.practice,
      taxonomy: values.taxonomy,
      npi: values.npi,
      upin: values.upin,
      providerTypeId: values.providerTypeId,
    };
    let response: {
      status?: boolean;
      message?: string;
      data?: any;
    } = {};
    if (editFlag && editData.id) {
      response = await updatePcp(requestParameter, editData.id);
    } else {
      response = await createPcp(requestParameter);
    }

    if (response.status) {
      showToastMessage(response.message, TOAST_TYPE_OPTIONS.SUCCESS);
      resetErrorAndValue();
      setShow(!show);
      getAllPCPDataForTable();
    } else {
      showToastMessage(response.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };
  const closeAction = () => {
    resetErrorAndValue();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const addNewPcpButtonAction = (status: boolean) => {
    fetchData();
    setShow(status);
    resetErrorAndValue();
    resetEditData();
    setEditFlag(false);
  };

  return (
    <div>
      <ModalComponent
        showAddUserPopup={show}
        setShowAddUserPopup={addNewPcpButtonAction}
        title={
          editFlag
            ? "Update Primary & Specialty Care provider"
            : "Add Primary & Specialty Care provider"
        }
        buttonShow={buttonTitle}
        buttonLeft={editFlag ? null : "Clear"}
        buttonRight={editFlag ? "update & Continue" : "Save & Continue"}
        onSubmit={handleSubmit}
        additionalToggleButtonClasses="text-end btn btn-primary btn-sm d-flex align-items-center px-2 py-1"
        size="lg"
        closeAction={closeAction}
        additionalResetButtonClasses="btn btn-primary btn-sm px-5 ms-3 text-white"
      >
        <form>
          <div className="row g-3">
            <div className="col-lg-2 col-sm-6">
              <Input
                type="title"
                id="title"
                name="title"
                value={values.title}
                onChange={handleChange}
                placeholder="Title"
                className="form-control form-control-sm"
                error={errors.title}
                required={validationRules.title.required}
                label={validationRules.title.displayName}
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <Input
                type="text"
                id="firstName"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                placeholder="Enter First Name"
                className="form-control form-control-sm"
                error={errors.firstName}
                required={validationRules.firstName.required}
                label={validationRules.firstName.displayName}
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <Input
                type="text"
                id="lastN ame"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                placeholder="Enter Last Name"
                className="form-control form-control-sm"
                error={errors.lastName}
                required={validationRules.lastName.required}
                label={validationRules.lastName.displayName}
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <InputDropdown
                options={providerTypeOptions}
                className="form-select form-select-sm"
                onSelect={handleChange}
                name="providerTypeId"
                value={values.providerTypeId}
                error={errors.providerTypeId}
                required={validationRules.providerTypeId.required}
                label={validationRules.providerTypeId.displayName}
              />
            </div>

            <div className="col-lg-6 col-sm-6">
              <Input
                type="address1"
                id="address1"
                name="address1"
                value={values.address1}
                onChange={handleChange}
                placeholder="Address 1"
                className="form-control form-control-sm"
                error={errors.address1}
                required={validationRules.address1.required}
                label={validationRules.address1.displayName}
              />
            </div>
            <div className="col-lg-6 col-sm-6">
              <Input
                type="address2"
                id="address2"
                name="address2"
                value={values.address2}
                onChange={handleChange}
                placeholder="Address 2"
                className="form-control form-control-sm"
                error={errors.address2}
                required={validationRules.address2.required}
                label={validationRules.address2.displayName}
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <InputDropdown
                options={countryList}
                className="form-select form-select-sm"
                onSelect={handleCountryChange}
                name="country"
                value={values.country}
                error={errors.country}
                required={validationRules.country.required}
                label={validationRules.country.displayName}
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <InputDropdown
                options={stateList}
                className="form-select form-select-sm"
                onSelect={handleStateChange}
                name="state"
                value={values.state}
                error={errors.state}
                required={validationRules.state.required}
                label={validationRules.state.displayName}
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <InputDropdown
                options={cityList}
                className="form-select form-select-sm"
                onSelect={handleCityChange}
                name="city"
                value={values.city}
                error={errors.city}
                required={validationRules.city.required}
                label={validationRules.city.displayName}
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <InputDropdown
                options={zipCodeList}
                className="form-select form-select-sm"
                onSelect={handleChange}
                name="zipCode"
                value={values.zipCode}
                error={errors.zipCode}
                required={validationRules.zipCode.required}
                label={validationRules.zipCode.displayName}
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <Input
                type="organization"
                id="organization"
                name="organization"
                value={values.organizationName}
                onChange={handleChange}
                placeholder="Organization Name"
                className="form-control form-control-sm"
                disabled
                error={errors.organizationName}
                required={validationRules.organizationName.required}
                label={validationRules.organizationName.displayName}
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <Input
                type="number"
                id="office"
                name="officeNumber"
                value={values.officeNumber}
                onChange={handleChange}
                placeholder="xxx-xxx-xxxx"
                className="form-control form-control-sm"
                error={errors.officeNumber}
                required={validationRules.officeNumber.required}
                label={validationRules.officeNumber.displayName}
              />
            </div>

            <div className="col-lg-3 col-sm-6">
              <Input
                type="number"
                id="fax"
                name="fax"
                value={values.fax}
                onChange={handleChange}
                placeholder="xxx-xxx-xxxx"
                className="form-control form-control-sm"
                error={errors.fax}
                required={validationRules.fax.required}
                label={validationRules.fax.displayName}
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <Input
                type="email"
                id="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder="Email"
                className="form-control form-control-sm"
                error={errors.email}
                required={validationRules.email.required}
                label={validationRules.email.displayName}
              />
            </div>

            <div className="col-lg-2 col-sm-3">
              <Input
                type="text"
                id="speciality"
                name="speciality"
                value={values.speciality}
                onChange={handleChange}
                placeholder={validationRules.speciality.displayName}
                className="form-control form-control-sm"
                error={errors.speciality}
                required={validationRules.speciality.required}
                label={validationRules.speciality.displayName}
              />
            </div>
            <div className="col-lg-2 col-sm-3">
              <Input
                type="text"
                id="practice"
                name="practice"
                value={values.practice}
                onChange={handleChange}
                placeholder="Practice"
                className="form-control form-control-sm"
                error={errors.practice}
                required={validationRules.practice.required}
                label={validationRules.practice.displayName}
              />
            </div>
            <div className="col-lg-2 col-sm-3">
              <Input
                type="text"
                id="taxonomy"
                name="taxonomy"
                value={values.taxonomy}
                onChange={handleChange}
                placeholder="Taxonomy"
                className="form-control form-control-sm"
                error={errors.taxonomy}
                required={validationRules.taxonomy.required}
                label={validationRules.taxonomy.displayName}
              />
            </div>

            <div className="col-lg-2 col-sm-3">
              <Input
                type="text"
                id="upin"
                name="upin"
                value={values.upin}
                onChange={handleChange}
                placeholder="upin"
                className="form-control form-control-sm"
                error={errors.upin}
                required={validationRules.upin.required}
                label={validationRules.upin.displayName}
              />
            </div>
            <div className="col-lg-4 col-sm-4">
              <Input
                type="text"
                id="npi"
                name="npi"
                value={values.npi}
                onChange={handleChange}
                placeholder="NPI"
                className="form-control form-control-sm"
                error={errors.npi}
                required={validationRules.npi.required}
                label={validationRules.npi.displayName}
              />
              <div className="text-end">
                <Button
                  onClick={handleNewForm}
                  type="button"
                  label="NPI Registry"
                  className="btn btn-sm btn-link text-decoration-none text-sm"
                  variant="secondary"
                />
              </div>
            </div>
          </div>
        </form>
      </ModalComponent>
    </div>
  );
}
