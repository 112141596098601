/* eslint-disable complexity */
import ApiCaller from "../../utils/apiCaller";
import { API_ENDPOINTS } from "../../constants/apiList";
import { STATUS_CODES } from "../../constants/variables";

export async function getAllPatientList({
  paginationDataAsParameter,
}: {
  paginationDataAsParameter: any;
}): Promise<any> {
  const { pageNo, pageSize, sortingFieldName, sortingOrder } =
    paginationDataAsParameter;
  let getUsersUrl = `${API_ENDPOINTS.getAllPatientList}?pageNo=${pageNo}&pageSize=${pageSize}`;
  if (
    sortingFieldName &&
    sortingFieldName !== "" &&
    sortingOrder &&
    sortingOrder !== ""
  ) {
    getUsersUrl = `${getUsersUrl}&sortBy=${sortingFieldName}&sortDir=${sortingOrder}`;
  }
  try {
    const response: any = await ApiCaller.get(getUsersUrl);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "User fetch Successfully",
        data: response,
      };
    }
    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getPatientDetails(patientId: string): Promise<any> {
  const getPatientDetailsUrl = `${API_ENDPOINTS.getPatientDetails}/${patientId}`;

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(getPatientDetailsUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Patient Info Retrieve Successfully",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function deletePatientDetails(patientId: string): Promise<any> {
  const deletePatientDetailsUrl = `${API_ENDPOINTS.deletePatientDetails}/${patientId}`;

  try {
    const response = await ApiCaller.delete<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(deletePatientDetailsUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Patient Info deleted Successfully",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
export async function getPatientProfile(patientId: string): Promise<any> {
  const getPatientDetailsUrl = `${API_ENDPOINTS.getPatientProfileImage}/${patientId}`;
  try {
    const imageUrl: any = await ApiCaller.getImage(getPatientDetailsUrl);
    if (imageUrl) {
      return {
        status: true,
        code: STATUS_CODES.OK,
        message: "",
        data: imageUrl,
      };
    }
    return {
      status: false,
      code: 400,
      message: "",
      data: "/assets/images/patient-icon-1.png",
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function createNewPatientInfo(requestObject: any) {
  const addNewPatientUrl = API_ENDPOINTS.createNewPatientBasicInfo;

  try {
    const response = await ApiCaller.multiMediaPost<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(addNewPatientUrl, requestObject);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "New Patient added successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to Add new patient",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function UpdatePatientBasicInfo(
  requestObject: any,
  patientId: string,
) {
  const updatePatientUrl = `${API_ENDPOINTS.UpdatePatientBasicInfo}/${patientId}`;
  try {
    const response = await ApiCaller.multiMediaPut<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(updatePatientUrl, requestObject);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Patient Info Updated successfully",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to Update patient Info",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function addPatientContactAPI(formData: any): Promise<any> {
  const addPatientContactUrl = API_ENDPOINTS.createPatientContactInfo;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(addPatientContactUrl, formData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Patient Contact information added successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMsg?.[0] || "Failed to Add contact Info patient",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
export async function updatePatientContactAPI(
  formData: any,
  contactId: string,
): Promise<any> {
  const updatePatientContactUrl = `${API_ENDPOINTS.updatePatientContactInfo}/${contactId}`;

  try {
    const response = await ApiCaller.put<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(updatePatientContactUrl, formData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Patient Contact information added successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMsg?.[0] || "Failed to Add contact Info patient",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function addPatientProviderInfoAPI(formData: any): Promise<any> {
  const addPatientProviderInfoUrl = API_ENDPOINTS.createPatientProviderInfo;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(addPatientProviderInfoUrl, formData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Patient provider information added successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMsg?.[0] ||
        "Failed to add patient provider information",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function updatePatientProviderInfoAPI(
  formData: any,
  providerId: number,
): Promise<any> {
  const updatePatientBasicInfoPatientProviderInfoUrl = `${API_ENDPOINTS.updatePatientProviderInfo}/${providerId}`;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(updatePatientBasicInfoPatientProviderInfoUrl, formData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Patient provider information Update successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMsg?.[0] ||
        "Failed to update patient provider information",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function addRaceAndEthnicityAPI(formData: any): Promise<any> {
  const addRaceAndEthnicityUrl = API_ENDPOINTS.createRaceAndEthnicity;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(addRaceAndEthnicityUrl, formData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Race and Ethnicity information added successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to add Race and Ethnicity",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function createNewQuickPatientInfo(requestObject: any) {
  const addNewPatientUrl = API_ENDPOINTS.createNewQuickPatientBasicInfo;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(addNewPatientUrl, requestObject);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "New Patient added successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to Add new patient",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getSearchPatientList(patientText: string): Promise<any> {
  const getUsersUrl = `${API_ENDPOINTS.searchPatientName}/${patientText}`;

  try {
    const response = await ApiCaller.get(getUsersUrl);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
