import { createBrowserRouter, Navigate } from "react-router-dom";
import { loginRoutes } from "./login/login";
import { dashboardRoutes } from "./dashboard/dashboard";

const isAuthenticated = true;

export const router = createBrowserRouter([
  ...loginRoutes,
  ...(isAuthenticated ? dashboardRoutes : []),
  {
    path: "*",
    element: <Navigate to="/login" />,
  },
]);
