import { STATUS_CODES } from "../../constants/variables";
import { API_ENDPOINTS } from "../../constants/apiList";
import ApiCaller from "../../utils/apiCaller";

interface LoginParams {
  username: string;
  password: string;
  officeKey: string;
}
interface ForgotPasswordParams {
  email: string;
}
export async function loginViaEmail(params: LoginParams): Promise<any> {
  const { username, password, officeKey } = params;

  if (!username || !password || !officeKey) {
    return {
      status: false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: "Username, password, and office key are required.",
      details: "Username, password, and office key are required.",
    };
  }

  const loginUrl = API_ENDPOINTS.loginViaEmail;

  const requestData = {
    username,
    password,
    officeKey,
  };

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
    }>(loginUrl, requestData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: response?.responseMsg?.[0],
        data: response,
      };
    }
    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    if (error?.response?.status === 401) {
      return {
        status: false,
        code: 401,
        message: "Login Failed-Incorrect credentials",
        details: "API call failed",
      };
    }
    return {
      status: false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: "API failed",
      details: "API call failed",
    };
  }
}

export async function forgotPasswordViaEmail(
  params: ForgotPasswordParams,
): Promise<any> {
  const { email } = params;

  if (!email) {
    return {
      status: false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: "email are required.",
      details: "email are required.",
    };
  }

  const loginUrl = API_ENDPOINTS.forgotPasswordViaEmail;

  const requestData = {
    email,
  };

  try {
    const response: {
      isSuccess: boolean;
      responseMsg?: Array<string>;
    } = await ApiCaller.post(loginUrl, requestData);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: response.responseMsg?.[0],
        data: response,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: unknown) {
    return {
      status: false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: "API failed",
      details: "API call is failed",
    };
  }
}

export async function getLoginUserRoles(): Promise<any> {
  const getUsersUrl = API_ENDPOINTS.getAllUserRoles;

  try {
    const response: {
      isSuccess: boolean;
      responseMsg?: Array<string>;
      dataList?: Array<string>;
    } = await ApiCaller.get(getUsersUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: response.responseMsg?.[0],
        data: response.dataList,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
