import React, { useState, ChangeEvent, useEffect } from "react";
import { useSelector } from "react-redux";
import { Input } from "../../atom/Input/Input";
import { Textarea } from "../../atom/Textarea/Textarea";
import { Image } from "../../atom/Images/Image";
import {
  createLabResult,
  updateLabResult,
} from "../../../services/labAndDiagnosticResult/labResult";
import { Button } from "../../atom/Buttons/Button";
import {
  FILE_SIZE_MAX,
  TOAST_TYPE_OPTIONS,
} from "../../../constants/variables";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../services/utils/validateDataAsPerRules";
import { formatDate } from "../../../services/utils/formatDate";
import { allKeysEmpty } from "../../../services/utils/allKeyEmpty";
import Logger from "../../../utils/Logger";
import { FileField } from "../../atom/FileField/FileField";

export interface CreateLabResultProps {
  editLabData: any;
  onSubmitAction: () => void;
}

export function CreateLabResult({
  editLabData,
  onSubmitAction,
}: CreateLabResultProps) {
  const activePatient = useSelector((state: any) => state.activePatient);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [uploadFile, setUploadFile] = useState<File | undefined>();
  const [filesFromResponse, setFilesFromResponse] = useState<
    string | undefined
  >();
  const [editFlag, setEditFlag] = useState(false);
  const [formData, setFormData] = useState({
    documentName: "",
    labType: "",
    labDate: "",
    providerName: "",
    comment: "",
    labResultPath: "",
  });
  const [errors, setErrors] = useState<any>({
    documentName: "",
    labType: "",
    labDate: "",
    providerName: "",
    comment: "",
    labResultPath: "",
    fileSlide1: "",
  });
  const validationRules: any = {
    documentName: {
      required: false,
      dataType: "text",
      characterLimit: 50,
      displayName: "Document Name",
    },
    labType: {
      required: true,
      dataType: "any",
      characterLimit: 50,
      displayName: "Lab Type",
    },
    labDate: {
      required: true,
      dataType: "date",
      characterLimit: 250,
      isPastDateAllow: true,
      isFutureDateAllow: true,
      displayName: "Lab Date",
    },
    providerName: {
      required: false,
      dataType: "text",
      characterLimit: 50,
      displayName: "Provider Name",
    },
    comment: {
      required: false,
      dataType: "any",
      characterLimit: 1000,
      displayName: "Comment",
    },
    labResultPath: {
      required: true,
      dataType: "any",
      characterLimit: 500,
      displayName: "Upload Document",
    },
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    const newErrors = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: name,
      fieldName: name,
      value,
    });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: newErrors,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleReset = () => {
    setSelectedImage(null);
    setFilesFromResponse(undefined);
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setUploadFile(file);
    if (file) {
      const fileName = file.name;
      const fileType = file.type;
      const fileSize = file.size;
      if (fileSize > FILE_SIZE_MAX) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          labResultPath: "The selected file exceeds 10 MB",
        }));
      } else {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          labResultPath: "",
        }));
      }
      if (fileName) {
        formData.labResultPath = fileName;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        if (fileType === "application/pdf") {
          handleReset();
          setFilesFromResponse(URL.createObjectURL(file));
        } else if (fileType === "image/jpeg") {
          handleReset();
          setSelectedImage(base64String);
        } else if (fileType === "image/png") {
          handleReset();
          setSelectedImage(base64String);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleClear = () => {
    setFormData({
      documentName: "",
      labType: "",
      labDate: "",
      providerName: "",
      comment: "",
      labResultPath: "",
    });
    handleReset();
    setUploadFile(undefined);
    setEditFlag(false);
  };

  const handleSubmit = async () => {
    if (activePatient?.patientDetails?.id === "") {
      showToastMessage("Please select patient", TOAST_TYPE_OPTIONS.ERROR);
      return;
    }
    const formErrors = validateDataAsPerRules({
      formData,
      rules: validationRules,
    });
    setErrors({
      ...errors,
      ...formErrors,
    });
    const fileSize = uploadFile ? uploadFile.size : 0;
    if (Object.keys(formErrors).length > 0 || fileSize > FILE_SIZE_MAX) {
      showToastMessage("Please fill correct value", TOAST_TYPE_OPTIONS.ERROR);
      return;
    }
    const { labDate, ...tempFormData } = formData;
    const isEmpty = allKeysEmpty(tempFormData);
    if (isEmpty) {
      showToastMessage(
        "Please fill at least one field",
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }

    const requestObject = {
      patientId: activePatient.patientDetails.id,
      patientName:
        activePatient.patientDetails.firstName +
        activePatient.patientDetails.middleName +
        activePatient.patientDetails.lastName,
      uploadFile,
      ...formData,
    };

    let createLabResultResponse: {
      status?: boolean;
      message?: string;
      data?: any;
    } = {};
    if (editFlag) {
      Logger.logInfo("Updating Lab Result", { data: requestObject });
      createLabResultResponse = await updateLabResult(
        requestObject,
        editLabData.id
      );
    } else {
      Logger.logInfo("Creating Lab Result", { data: requestObject });
      createLabResultResponse = await createLabResult(requestObject);
      setEditFlag(false);
    }

    if (createLabResultResponse.status) {
      showToastMessage(
        createLabResultResponse.message,
        TOAST_TYPE_OPTIONS.SUCCESS
      );
      onSubmitAction();
      handleClear();
    } else {
      showToastMessage(
        createLabResultResponse.message,
        TOAST_TYPE_OPTIONS.ERROR
      );
    }
  };
  useEffect(() => {
    if (editLabData?.id && editLabData?.id !== "") {
      setEditFlag(true);
      setFormData({
        documentName: editLabData.documentName,
        labType: editLabData.labType,
        labDate: formatDate(editLabData.labDate, "YYYY-MM-DD"),
        providerName: editLabData.providerName,
        comment: editLabData.comment,
        labResultPath: editLabData.labResultPath,
      });
      if (editLabData?.images) {
        if (editLabData?.labResultPath?.endsWith(".pdf")) {
          setFilesFromResponse(editLabData?.images?.loadFileOrImg);
        } else {
          setSelectedImage(editLabData?.images?.loadFileOrImg);
        }
      }
    }
  }, [editLabData]);

  return (
    <div className="row g-3">
      <div className="col-lg-6 col-sm-6">
        <div>
          {selectedImage && (
            <Image
              src={selectedImage}
              alt={formData.labResultPath}
              title={formData.labResultPath}
              width="100%"
            />
          )}
        </div>
        <div>
          {filesFromResponse && (
            <embed
              src={filesFromResponse}
              width="100%"
              height="500"
              type="application/pdf"
            />
          )}
        </div>
      </div>
      <div className="col-lg-6 col-sm-6">
        <form>
          <div>
            <Input
              type="text"
              id="documentName"
              name="documentName"
              value={formData.documentName}
              onChange={handleChange}
              placeholder={validationRules.documentName.displayName}
              className="form-control form-control-sm"
              label={validationRules.documentName.displayName}
              error={errors.documentName}
            />
          </div>
          <div>
            <Input
              type="text"
              id="labType"
              name="labType"
              value={formData.labType}
              onChange={handleChange}
              placeholder={validationRules.labType.displayName}
              className="form-control form-control-sm"
              label={validationRules.labType.displayName}
              error={errors.labType}
              required={validationRules.labType.required}
            />
          </div>
          <div>
            <Input
              type="date"
              id="labDate"
              name="labDate"
              value={formData.labDate}
              onChange={handleChange}
              placeholder={validationRules.labDate.displayName}
              className="form-control form-control-sm"
              label={validationRules.labDate.displayName}
              error={errors.labDate}
              required={validationRules.labDate.required}
            />
          </div>
          <div>
            <Input
              type="text"
              id="providerName"
              name="providerName"
              value={formData.providerName}
              onChange={handleChange}
              placeholder={validationRules.providerName.displayName}
              className="form-control form-control-sm"
              label={validationRules.providerName.displayName}
              error={errors.providerName}
              required={validationRules.providerName.required}
            />
          </div>
          <div>
            <Textarea
              className="form-control form-control-sm "
              id="comment"
              name="comment"
              placeholder={validationRules.comment.displayName}
              rows={2}
              value={formData.comment}
              label={validationRules.comment.displayName}
              error={errors.comment}
              onChange={handleChange}
              required={validationRules.comment.required}
            />
          </div>
          <div>
            <FileField
              name="fileSlide1"
              accept="image/*.pdf"
              filePath={formData.labResultPath}
              error={errors.labResultPath}
              onChange={handleImageChange}
              required={validationRules.labResultPath.required}
              label={validationRules.labResultPath.displayName}
            />
          </div>
          <div className="col-12 d-flex justify-content-between mt-5">
            <div>
              {!editFlag && (
                <Button
                  onClick={() => handleClear()}
                  variant="outlined"
                  className="btn btn-danger btn-sm"
                  label="Clear"
                />
              )}
            </div>

            <Button
              onClick={() => handleSubmit()}
              variant="outlined"
              className="btn btn-primary btn-sm px-4"
              label={editFlag ? "Update" : "Save & Continue"}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
