import { HorizontalTabs } from "../../../components/atom/HorizontalTab/HorizontalTab";
import { Forms } from "./Forms";
import { IRA } from "./IRA";
import { ProfileCard } from "../../../components/molecule/ProfileCard/ProfileCard";

export function FormsAndIRA() {
  const tabs = [
    {
      key: "1",
      title: "Forms",
      content: <Forms displayLabel="Forms" />,
    },
    {
      key: "2",
      title: "IRA",
      content: <IRA displayLabel="Initial Risk Assessment" moduleName="IRA" />,
    },
  ];
  return (
    <div>
      <div>
        <ProfileCard />
        <HorizontalTabs tabs={tabs} />
      </div>
    </div>
  );
}
