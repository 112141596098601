/* eslint-disable complexity */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ModalComponent } from "../../molecule/Modal/ModalComponent";
import { Input } from "../../atom/Input/Input";
import { InputDropdown } from "../../atom/InputDropDown/InputDropDown";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import {
  createUserService,
  CreateUserParams,
  getAllUsersOptions,
  updateUserService,
  isSuperUser,
} from "../../../services/users/users";

import { getAllOrganizationDropdownOptions } from "../../../services/dropDownMenuOptions/dropDownMenuOptions";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../services/utils/validateDataAsPerRules";
import Logger from "../../../utils/Logger";

interface FormValues {
  firstName: string;
  userName: string;
  lastName: string;
  emailId: string;
  officeKey: number | null;
  role: string;
  status: string;
  organizationId: string;
}

interface Option {
  value: string;
  label: string;
}
interface CreateNewUserModalProps {
  getAllUserData: () => void;
  editUserDetails: any;
  editMode: boolean;
  setEditMode: (value: boolean) => void;
}

export function CreateNewUserModal({
  getAllUserData,
  editUserDetails,
  editMode,
  setEditMode,
}: CreateNewUserModalProps) {
  const loginUserDetails = useSelector((state: any) => state.userDetails);
  const [showAddUserPopup, setShowAddUserPopup] = useState<boolean>(false);
  const [userAsSuperUser, setUserAsSuperUser] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const statusOptions: Option[] = [
    { value: "1", label: "Active" },
    { value: "0", label: "InActive" },
  ];
  const [paginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });
  const [organizationOption, setOrganizationOption] = useState([]);
  const [formData, setFormData] = useState<FormValues>({
    firstName: "",
    userName: "",
    lastName: "",
    emailId: "",
    officeKey: null,
    role: "",
    status: "",
    organizationId: "",
  });
  const validationRules: any = {
    firstName: {
      required: true,
      dataType: "text",
      characterLimit: 50,
      displayName: "First Name",
    },
    userName: {
      required: true,
      dataType: "textOrNumber",
      characterLimit: 10,
      minCharacterLimit: 4,
      displayName: "User Name",
    },
    lastName: {
      required: true,
      dataType: "text",
      characterLimit: 50,
      displayName: "Last Name",
    },
    emailId: {
      required: true,
      dataType: "email",
      characterLimit: 50,
      displayName: "Email Id",
    },
    officeKey: {
      required: true,
      dataType: "number",
      characterLimit: 6,
      minCharacterLimit: 6,
      displayName: "Office Key",
    },
    role: {
      required: true,
      dataType: "number",
      characterLimit: 50,
      displayName: "Role",
    },
    status: {
      required: true,
      dataType: "number",
      characterLimit: 50,
      displayName: "Status",
    },
    organizationId: {
      required: true,
      dataType: "number",
      characterLimit: 50,
      displayName: "Organization",
    },
  };
  const [errors, setErrors] = useState({
    firstName: "",
    userName: "",
    lastName: "",
    emailId: "",
    officeKey: "",
    role: "",
    status: "",
    organizationId: "",
  });
  const handleChange = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    const newErrors = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: name,
      fieldName: name,
      value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: newErrors,
    }));

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]:
        name === "officeKey" && /^[0-9]+$/.test(value)
          ? parseInt(value, 10)
          : value,
    }));
  };

  const handleOptionSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange(event);
  };
  const addData = async () => {
    const rulesToValidate = JSON.parse(JSON.stringify(validationRules));

    if (userAsSuperUser) {
      rulesToValidate.organizationId.required = true;
    } else {
      rulesToValidate.organizationId.required = false;
    }

    const formErrors = validateDataAsPerRules({
      formData,
      rules: rulesToValidate,
    });

    setErrors({
      ...errors,
      ...formErrors,
    });
    if (Object.keys(formErrors).length > 0) {
      showToastMessage(
        "Please fill all the required fields",
        TOAST_TYPE_OPTIONS.ERROR,
      );
      return;
    }

    const requestParameter: CreateUserParams = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      statusId: formData.status,
      roleId: formData.role,
      officeKey: formData.officeKey,
      email: formData.emailId,
      userName: formData.userName,
      organizationId: formData.organizationId,
    };
    const updateRequestObject = {
      userId: editUserDetails?.id,
      updatedUserData: requestParameter,
      organizationId: formData.organizationId,
    };
    setIsLoading(true);
    if (editMode) {
      Logger.logInfo("updating the user info", { data: updateRequestObject });
      const response = await updateUserService(updateRequestObject);
      if (response.status) {
        getAllUserData();
        showToastMessage(response.message, TOAST_TYPE_OPTIONS.SUCCESS);
        setFormData({
          firstName: "",
          userName: "",
          lastName: "",
          emailId: "",
          officeKey: null,
          role: "",
          status: "",
          organizationId: "",
        });
        setShowAddUserPopup(false);
      } else {
        showToastMessage(response.message, TOAST_TYPE_OPTIONS.ERROR);
      }
      setIsLoading(false);
    } else {
      Logger.logInfo("Creating the user info", { data: requestParameter });
      const response = await createUserService(requestParameter);
      setIsLoading(false);
      if (response.status) {
        getAllUserData();
        showToastMessage(response.message, TOAST_TYPE_OPTIONS.SUCCESS);
        setFormData({
          firstName: "",
          userName: "",
          lastName: "",
          emailId: "",
          officeKey: null,
          role: "",
          status: "",
          organizationId: "",
        });
        setShowAddUserPopup(false);
      } else {
        showToastMessage(response.message, TOAST_TYPE_OPTIONS.ERROR);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let isLoginUserIsSuperUser = false;
      if (loginUserDetails) {
        isLoginUserIsSuperUser = isSuperUser({
          permissions: loginUserDetails.userPermission,
        });
        if (isLoginUserIsSuperUser) {
          setUserAsSuperUser(true);
          validationRules.organizationId.required = true;
        } else {
          setFormData({
            ...formData,
            organizationId: loginUserDetails.organizationId,
          });

          validationRules.organizationId.required = false;
        }
      }
      const roleOptionsList = await getAllUsersOptions(isLoginUserIsSuperUser);
      const organizationData = await getAllOrganizationDropdownOptions({
        paginationDataAsParameter: paginationData,
      });

      setOrganizationOption(organizationData);
      setRoleOptions(roleOptionsList);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (editUserDetails && editMode) {
      setFormData({
        firstName: editUserDetails.firstName,
        userName: editUserDetails.username,
        lastName: editUserDetails.lastName,
        emailId: editUserDetails.email,
        officeKey: editUserDetails.officeKey,
        role: editUserDetails.roleId,
        status: editUserDetails.statusId,
        organizationId: editUserDetails.organizationId,
      });
      setShowAddUserPopup(true);
    }
  }, [editUserDetails]);

  const addNewUserButtonAction = () => {
    setShowAddUserPopup(!showAddUserPopup);
    setEditMode(false);
    setFormData({
      ...formData,
      firstName: "",
      userName: "",
      lastName: "",
      emailId: "",
      officeKey: null,
      role: "",
      status: "",
    });
    setErrors({
      firstName: "",
      userName: "",
      lastName: "",
      emailId: "",
      officeKey: "",
      role: "",
      status: "",
      organizationId: "",
    });
  };

  return (
    <div>
      <div className="card-title d-flex justify-content-between align-items-center">
        <h5>Admin</h5>
        <ModalComponent
          buttonShow=" + Add New User"
          buttonLeft="Close"
          buttonRight={editMode ? "Update" : "Save"}
          title={editMode ? "Update User" : "Add New User"}
          onSubmit={addData}
          className="text-end "
          showAddUserPopup={showAddUserPopup}
          setShowAddUserPopup={addNewUserButtonAction}
          submitButtonLoadingStatus={isLoading}
        >
          <form className="row g-3">
            <div className="col-lg-6 col-sm-12">
              <Input
                type="text"
                id="userName"
                name="userName"
                value={formData.userName}
                onChange={handleChange}
                placeholder="User Name"
                className="form-control form-control-sm"
                disabled={editMode ?? false}
                error={errors.userName}
                required={validationRules.userName.required}
                label={validationRules.userName.displayName}
              />
            </div>
            <div className="col-lg-6 col-sm-6">
              <Input
                type="text"
                id="emailId"
                name="emailId"
                value={formData.emailId}
                onChange={handleChange}
                placeholder="Email"
                className="form-control form-control-sm"
                disabled={editMode ?? false}
                error={errors.emailId}
                required={validationRules.emailId.required}
                label={validationRules.emailId.displayName}
              />
            </div>
            <div className="col-lg-6 col-sm-6">
              <Input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="First Name"
                className="form-control form-control-sm"
                disabled={editMode ?? false}
                error={errors.firstName}
                required={validationRules.firstName.required}
                label={validationRules.firstName.displayName}
              />
            </div>
            <div className="col-lg-6 col-sm-6">
              <Input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Last Name"
                className="form-control form-control-sm"
                disabled={editMode ?? false}
                error={errors.lastName}
                required={validationRules.lastName.required}
                label={validationRules.lastName.displayName}
              />
            </div>

            <div className="col-lg-6 col-sm-6">
              <Input
                type="text"
                id="officeKey"
                name="officeKey"
                value={
                  formData.officeKey !== null
                    ? formData.officeKey.toString()
                    : ""
                }
                onChange={handleChange}
                placeholder="Office Key"
                className="form-control form-control-sm"
                disabled={editMode ?? false}
                error={errors.officeKey}
                required={validationRules.officeKey.required}
                label={validationRules.officeKey.displayName}
              />
            </div>
            <div className="col-lg-6 col-sm-6">
              <InputDropdown
                options={roleOptions}
                className="form-select form-select-sm"
                onSelect={handleOptionSelect}
                name="role"
                value={formData.role}
                disabled={editMode ?? false}
                error={errors.role}
                required={validationRules.role.required}
                label={validationRules.role.displayName}
              />
            </div>
            <div className="col-lg-6 col-sm-6">
              <InputDropdown
                options={statusOptions}
                className="form-select form-select-sm"
                onSelect={handleOptionSelect}
                name="status"
                value={formData.status}
                error={errors.status}
                required={validationRules.status.required}
                label={validationRules.status.displayName}
              />
            </div>

            <div className="col-lg-6 col-sm-6">
              <InputDropdown
                options={organizationOption}
                className="form-select form-select-sm"
                onSelect={handleOptionSelect}
                name="organizationId"
                value={formData.organizationId}
                disabled={(editMode || !userAsSuperUser) ?? false}
                error={errors.organizationId}
                required={validationRules.organizationId.required}
                label={validationRules.organizationId.displayName}
              />
            </div>
          </form>
        </ModalComponent>
      </div>
    </div>
  );
}
