import React, { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Input } from "../../../../atom/Input/Input";
import { ModalComponent } from "../../../../molecule/Modal/ModalComponent";
import {
  createCarePlanAllergies,
  updateCarePlanAllergiesForPatient,
} from "../../../../../services/carePlan/carePlan";
import { showToastMessage } from "../../../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../../../constants/variables";
import { Textarea } from "../../../../atom/Textarea/Textarea";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
  ValidationRules,
} from "../../../../../services/utils/validateDataAsPerRules";
import { formatDate } from "../../../../../services/utils/formatDate";
import { allKeysEmpty } from "../../../../../services/utils/allKeyEmpty";

const validationRules: ValidationRules = {
  date: {
    required: false,
    dataType: "date",
    displayName: "Date",
  },
  details: {
    required: false,
    dataType: "text",
    displayName: "Details",
    characterLimit: 4000,
  },
};

export interface AddAllergiesProps {
  fetchData: () => void;
  editData: any;
  setEditData: (data: any) => void;
}

export function AddAllergies({
  fetchData,
  editData,
  setEditData,
}: AddAllergiesProps) {
  const activePatient = useSelector((state: any) => state.activePatient);
  const activeCarePlan = useSelector((state: any) => state.activeCarePlan);
  const [editFlagStatus, setEditFlagStatus] = useState(false);
  const [formData, setFormData] = useState({
    values: {
      date: "",
      details: "",
    },
    errors: {
      date: "",
      details: "",
    },
  });
  const [isModelOpen, setIsModelOpen] = useState(false);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;

    const fieldError = validateFieldAsPerRules({
      rules: validationRules,
      fieldName: name,
      value,
    });

    setFormData({
      values: { ...formData.values, [name]: value },
      errors: { ...formData.errors, [name]: fieldError },
    });
  };

  const handleReset = () => {
    setFormData({
      values: {
        date: "",
        details: "",
      },
      errors: {
        date: "",
        details: "",
      },
    });
    setEditFlagStatus(false);
    setEditData({});
  };

  const handleSubmit = async () => {
    const formErrors = validateDataAsPerRules({
      formData: formData.values,
      rules: validationRules,
    });

    if (Object.keys(formErrors).length) {
      setFormData({
        ...formData,
        errors: formErrors,
      });
      return;
    }
    const ifAllEmpty = allKeysEmpty(formData.values);
    if (ifAllEmpty) {
      showToastMessage(
        "Please fill at least one field",
        TOAST_TYPE_OPTIONS.ERROR,
      );
      return;
    }
    let addAllergiesResponse;
    if (editFlagStatus) {
      const requestObject = {
        allergyDate: formData.values.date,
        details: formData.values.details,
        id: editData.id,
      };
      addAllergiesResponse = await updateCarePlanAllergiesForPatient({
        formData: requestObject,
        carePlanId: activeCarePlan?.carePlanDetails?.carePlanId,
        patientId: activePatient.patientDetails.id,
      });
    } else {
      addAllergiesResponse = await createCarePlanAllergies({
        formData,
        carePlanId: activeCarePlan?.carePlanDetails?.carePlanId,
        patientId: activePatient.patientDetails.id,
      });
    }

    if (addAllergiesResponse.status) {
      showToastMessage(
        addAllergiesResponse.message,
        TOAST_TYPE_OPTIONS.SUCCESS,
      );
      handleReset();
      setIsModelOpen(false);
      fetchData();
    } else {
      showToastMessage(addAllergiesResponse.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  useEffect(() => {
    if (editData?.id) {
      setFormData({
        values: {
          date: formatDate(editData.allergyDate, "YYYY-MM-DD"),
          details: editData.details,
        },
        errors: {
          date: "",
          details: "",
        },
      });
      setIsModelOpen(true);
      setEditFlagStatus(true);
    }
  }, [editData]);

  return (
    <ModalComponent
      buttonLeft={editFlagStatus ? null : "Clear"}
      buttonRight={editFlagStatus ? "Update & Continue" : "Save & Continue"}
      buttonShow="Add Allergy"
      title={editFlagStatus ? "Update Allergy" : "Add Allergy"}
      onSubmit={handleSubmit}
      closeAction={handleReset}
      showAddUserPopup={isModelOpen}
      setShowAddUserPopup={setIsModelOpen}
      showToggleAsAddButton
    >
      <form
        title="add-allergies-form"
        className="row g-3"
        onSubmit={handleSubmit}
      >
        <div className="col-lg-12 col-sm-12">
          <Input
            type="date"
            placeholder="Date"
            className="form-control form-control-sm"
            label={validationRules.date.displayName}
            value={formData.values.date}
            error={formData.errors.date}
            onChange={handleChange}
            name="date"
            required={validationRules.date.required}
          />
        </div>

        <div className="col-lg-12 col-sm-12">
          <Textarea
            className="form-control form-control-sm "
            placeholder="Text"
            rows={4}
            label={validationRules.details.displayName}
            value={formData.values.details}
            error={formData.errors.details}
            onChange={handleChange}
            name="details"
            required={validationRules.details.required}
          />
        </div>
      </form>
    </ModalComponent>
  );
}
