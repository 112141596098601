import React, { useState } from "react";

interface DisplayTextWithExpandProps {
  text: string;
  maxCharacter?: number;
  className?: string;
}

export function DisplayTextWithExpand({
  text,
  maxCharacter = 100,
  className,
}: DisplayTextWithExpandProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      toggleExpand();
    }
  };
  if (!text) {
    return null;
  }
  const textString = text.toString();
  const shouldShowButton = textString.length > maxCharacter;
  const displayedText = isExpanded
    ? textString
    : textString.slice(0, maxCharacter);

  return (
    <span className={className} aria-label="display text">
      {displayedText}
      {shouldShowButton && (
        <>
          {!isExpanded && "... "}
          <span
            onClick={toggleExpand}
            onKeyPress={handleKeyPress}
            role="button"
            tabIndex={0}
            aria-label={isExpanded ? "Show less" : "Show more"}
            style={{
              color: "blue",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            {isExpanded ? "Show less" : "Show more"}
          </span>
        </>
      )}
    </span>
  );
}
