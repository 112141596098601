import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { ModalComponent } from "../../molecule/Modal/ModalComponent";
import { Input } from "../../atom/Input/Input";
import { InputDropdown } from "../../atom/InputDropDown/InputDropDown";
import { createTask, updateTaskService } from "../../../services/tasks/tasks";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS, USER_ROLES } from "../../../constants/variables";
import {
  getOptionsForDropdown,
  getPatientsCaseManagerList,
} from "../../../services/dropDownMenuOptions/dropDownMenuOptions";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../services/utils/validateDataAsPerRules";
import {
  defaultValues,
  adminStatusList,
  CMStatusList,
  validationRules,
  dateAdjustments,
} from "./helperFunctions/constant";
import {
  DropdownOption,
  SearchDropdown,
} from "../../atom/SearchDropdown/SearchDropdown";
import { getSearchPatientName } from "../../../services/patients/patients";
import { Textarea } from "../../atom/Textarea/Textarea";
import { formatDate } from "../../../services/utils/formatDate";

interface CreateNewTaskProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  fetchData: () => void;
  taskDetails: Record<string, string>;
}

export function CreateNewTask({
  show,
  setShow,
  fetchData,
  taskDetails,
}: CreateNewTaskProps) {
  const loginUserDetails = useSelector((state: any) => state.userDetails);
  const [searchResultList, setSearchResultList] = useState<any[]>([]);
  const [taskTypeOption, setTaskTypeOption] = useState([]);
  const [assignToOption, setAssignToOption] = useState([]);
  const [isCaseManger, setIsCaseManager] = useState(false);
  const [editFlow, setEditFlow] = useState(false);
  const [formData, setFormData] = useState({
    values: {
      ...defaultValues,
      authorName: loginUserDetails.username,
    },
    errors: defaultValues,
  });
  const { values, errors } = formData;

  const statusList = useMemo(() => {
    if (
      loginUserDetails?.userPermission?.roleName === USER_ROLES.superAdmin ||
      loginUserDetails?.userPermission?.roleName === USER_ROLES.admin
    ) {
      setIsCaseManager(false);
      return adminStatusList;
    }
    if (loginUserDetails?.userPermission?.roleName === USER_ROLES.caseManager) {
      setIsCaseManager(true);
      return CMStatusList;
    }
    return [];
  }, [loginUserDetails?.userPermission?.roleName]);

  const handleDateChange = (duration: string) => {
    let newDate = new Date();
    switch (duration) {
      case "today":
        newDate = new Date();
        break;
      case "+1d":
        newDate.setDate(newDate.getDate() + 1);
        break;
      case "+1w":
        newDate.setDate(newDate.getDate() + 7);
        break;
      case "+1m":
        newDate.setMonth(newDate.getMonth() + 1);
        break;
      case "+1y":
        newDate.setFullYear(newDate.getFullYear() + 1);
        break;
      default:
        break;
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      values: {
        ...prevFormData.values,
        reminderDate: newDate.toISOString().split("T")[0],
      },
      errors: {
        ...prevFormData.errors,
        reminderDate: "",
      },
    }));
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = event.target;
    const newError = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: name,
      fieldName: name,
      value,
    });

    setFormData((prevState) => ({
      values: {
        ...prevState.values,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: newError,
      },
    }));
  };

  const handlePatientSelect = (option: DropdownOption) => {
    const { value, dob } = option;
    const newError = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: "patientId",
      fieldName: "patientId",
      value,
    });

    setFormData((prevState) => ({
      values: {
        ...prevState.values,
        patientId: value,
        dateOfBirth: dob ? new Date(dob).toISOString().split("T")[0] : "",
      },
      errors: {
        ...prevState.errors,
        patientId: newError,
      },
    }));
  };

  const resetFormDataValue = () => {
    setFormData({
      values: {
        ...defaultValues,
        authorName: loginUserDetails.username,
      },
      errors: defaultValues,
    });
    setEditFlow(false);
  };

  const handleSubmit = async () => {
    const formErrors = validateDataAsPerRules({
      formData: values,
      rules: validationRules,
    });
    setFormData((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        ...formErrors,
      },
    }));

    if (Object.keys(formErrors).length > 0) {
      showToastMessage(
        "Please fill all mandatory fields",
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }

    if (taskDetails && taskDetails?.id !== "") {
      const updateTaskResponse = await updateTaskService(
        values,
        taskDetails.id
      );
      if (updateTaskResponse.status) {
        showToastMessage(
          updateTaskResponse.message,
          TOAST_TYPE_OPTIONS.SUCCESS
        );
        resetFormDataValue();
        fetchData();
        setShow(!show);
      } else {
        showToastMessage(updateTaskResponse.message, TOAST_TYPE_OPTIONS.ERROR);
      }
    } else {
      const createNewTaskResponse = await createTask({
        ...values,
      });

      if (createNewTaskResponse.status) {
        showToastMessage(
          createNewTaskResponse.message,
          TOAST_TYPE_OPTIONS.SUCCESS
        );
        resetFormDataValue();
        fetchData();
        setShow(!show);
      } else {
        showToastMessage(
          createNewTaskResponse.message,
          TOAST_TYPE_OPTIONS.ERROR
        );
      }
    }
  };

  const initData = async () => {
    const taskTypeOptionList = await getOptionsForDropdown("taskType");
    setTaskTypeOption(taskTypeOptionList);

    const assignToOptionList = await getPatientsCaseManagerList();
    setAssignToOption(assignToOptionList);
  };

  const searchPatient = async (value: string) => {
    const patientsList = await getSearchPatientName(value);
    setSearchResultList(patientsList);
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (taskDetails && taskDetails?.id !== "") {
      setShow(!show);
      setFormData((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          ...taskDetails,
          dateOfBirth: formatDate(taskDetails.dob, "YYYY-MM-DD"),
          reminderDate: formatDate(taskDetails.reminderDate, "YYYY-MM-DD"),
        },
        errors: defaultValues,
      }));
      setEditFlow(true);
    }
  }, [taskDetails]);

  const handleClose = () => {
    resetFormDataValue();
  };

  return (
    <ModalComponent
      showAddUserPopup={show}
      setShowAddUserPopup={setShow}
      title={editFlow ? "Update Task " : "Add Task"}
      buttonShow="+ Add Task"
      buttonLeft={editFlow ? null : "clear"}
      buttonRight={editFlow ? "Update & Continue" : "Save & Continue"}
      onSubmit={handleSubmit}
      className="text-end"
      size="lg"
      closeAction={handleClose}
      disableAddButton={isCaseManger}
    >
      <form className="row g-3">
        <div className="col-lg-12 col-sm-12">
          <Textarea
            className="form-control form-control-sm"
            id="details"
            name="details"
            placeholder="Enter task details"
            rows={4}
            value={values.details}
            onChange={handleChange}
            label={validationRules.details.displayName}
            required={validationRules.details.required}
            error={errors.details}
            disabled={isCaseManger}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <InputDropdown
            options={assignToOption}
            onSelect={handleChange}
            name="assignTo"
            value={values.assignTo}
            className="form-select form-select-sm"
            error={errors.assignTo}
            label={validationRules.assignTo.displayName}
            required={validationRules.assignTo.required}
            disabled={isCaseManger}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <SearchDropdown
            onSelect={handlePatientSelect}
            searchCallback={searchPatient}
            searchField
            placeholder="Search Patient"
            options={searchResultList}
            error={errors.patientId}
            label={validationRules.patientId.displayName}
            required={validationRules.patientId.required}
            displayValue={values.patientName || ""}
            disabled={isCaseManger}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            type="date"
            id="dateOfBirth"
            name="dateOfBirth"
            value={values.dateOfBirth}
            onChange={handleChange}
            className="form-control form-control-sm"
            max={new Date().toISOString().split("T")[0]}
            error={errors.dateOfBirth}
            label={validationRules.dateOfBirth.displayName}
            required={validationRules.dateOfBirth.required}
            disabled={isCaseManger}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <div className="input-group input-group-sm d-flex">
            <Input
              type="date"
              id="reminderDate"
              name="reminderDate"
              value={values.reminderDate}
              onChange={handleChange}
              className="form-control"
              ariaLabel="Text input with segmented dropdown button"
              min={new Date().toISOString().split("T")[0]}
              error={errors.reminderDate}
              label={validationRules.reminderDate.displayName}
              required={validationRules.reminderDate.required}
              disabled={isCaseManger}
            />

            <div className="mt-3">
              <Dropdown>
                <Dropdown.Toggle
                  variant="light"
                  className="btn btn-outline-light dropdown-toggle dropdown-toggle-split text-secondary border"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  disabled={isCaseManger}
                >
                  <span className="visually-hidden">Toggle Dropdown</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {dateAdjustments.map((option) => (
                    <Dropdown.Item
                      key={option.value}
                      onClick={() => handleDateChange(option.value)}
                    >
                      {option.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            type="text"
            id="author"
            name="authorName"
            value={values.authorName}
            onChange={handleChange}
            placeholder="Text"
            disabled
            className="form-control form-control-sm"
            label={validationRules.authorName.displayName}
            required={validationRules.authorName.required}
            error={errors.authorName}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <InputDropdown
            options={taskTypeOption}
            onSelect={handleChange}
            name="taskTypeId"
            value={values.taskTypeId}
            className="form-select form-select-sm"
            label="Task Type"
            error={errors.taskTypeId}
            disabled={isCaseManger}
          />
        </div>

        <div className="col-lg-4 col-sm-4">
          <InputDropdown
            className="form-select form-select-sm"
            options={statusList}
            onSelect={handleChange}
            name="status"
            label="Status"
            value={values.status}
          />
        </div>
      </form>
    </ModalComponent>
  );
}
