import { useEffect, useState } from "react";
import { getOptionsForDropdown } from "../../../services/dropDownMenuOptions/dropDownMenuOptions";
import { Input } from "../../../components/atom/Input/Input";
import { InputDropdown } from "../../../components/atom/InputDropDown/InputDropDown";
import { SearchDropdown } from "../../../components/atom/SearchDropdown/SearchDropdown";
import { getSearchForPcpValues } from "../../../services/searchFilter/searchFilter";
import {
  validateFieldAsPerRules,
  ValidationRules,
} from "../../../services/utils/validateDataAsPerRules";
import { getPCPInfoService } from "../../../services/masterData/pcp";

interface ReferralSidebarProps {
  filters: any;
  referralType: any;
  setFilters: (filters: any) => void;
  showFilter: boolean;
}

export function ReferralSidebar({
  filters,
  referralType,
  setFilters,
  showFilter,
}: ReferralSidebarProps) {
  const [referralStatus, setReferralStatus] = useState([]);
  const [referredByOptions, setReferredByOptions] = useState([]);
  const [referredToOptions, setReferredToOptions] = useState([]);
  const [displayValueForReferredTo, setDisplayValueForReferredTo] = useState(
    ""
  );
  const [displayValueForReferredBy, setDisplayValueForReferredBy] = useState(
    ""
  );

  const validationRules: ValidationRules = {
    referredTo: {
      required: false,
      dataType: referralType === "OUT" ? "textOrNumber" : "text",
      characterLimit: 50,
      displayName: "Referred To",
    },
    referredBy: {
      required: false,
      dataType: referralType === "IN" ? "textOrNumber" : "text",
      characterLimit: 50,
      displayName: "Referred By",
    },
    organization: {
      required: false,
      dataType: "any",
      characterLimit: 50,
      displayName: "Organization",
    },
    authorizationId: {
      required: false,
      dataType: "number",
      minValue: 1,
      maxValue: 100,
      characterLimit: 3,
      displayName: "Authorization Id",
    },
    fromDate: {
      required: false,
      dataType: "date",
      characterLimit: 50,
      isPastDateAllow: true,
      isFutureDateAllow: true,
      displayName: "From Date",
    },
    toDate: {
      required: false,
      dataType: "date",
      characterLimit: 50,
      isPastDateAllow: true,
      isFutureDateAllow: true,
      displayName: "To Date",
    },
    status: {
      required: false,
      dataType: "any",
      characterLimit: 50,
      displayName: "status",
    },
  };
  const [errors, setErrors] = useState({
    referredTo: "",
    referredBy: "",
    organization: "",
    authorizationId: "",
    fromDate: "",
    toDate: "",
    status: "",
  });
  const handleChange = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    const newErrors = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: name,
      fieldName: name,
      value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: newErrors,
    }));
    setFilters((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleOptionSelects = (event: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange(event);
  };

  const initData = async () => {
    const referralStatusOption = await getOptionsForDropdown("referralStatus");
    setReferralStatus(referralStatusOption);

    if (referralType === "OUT") {
      const getPcpDetails = await getPCPInfoService(filters?.referredBy);
      if (getPcpDetails.status) {
        setDisplayValueForReferredBy(
          `${getPcpDetails.data.title} ${getPcpDetails.data.firstName} ${getPcpDetails.data.lastName}`
        );
      }
    } else if (referralType === "IN") {
      const getPcpDetails = await getPCPInfoService(filters?.referredTo);
      if (getPcpDetails.status) {
        setDisplayValueForReferredTo(
          `${getPcpDetails.data.title} ${getPcpDetails.data.firstName} ${getPcpDetails.data.lastName}`
        );
      }
    }
  };
  const updateReferredByList = async (value: string) => {
    if (value) {
      const referredByValues = await getSearchForPcpValues(value);
      setReferredByOptions(referredByValues);
      setErrors((prevErrors) => ({
        ...prevErrors,
        referredBy: "",
      }));
    } else {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        referredBy: "",
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        referredBy: "Referred By is required",
      }));
    }
  };

  const updateReferredToList = async (value: string) => {
    if (value) {
      const referredToValues = await getSearchForPcpValues(value);
      setReferredToOptions(referredToValues);
      setErrors((prevErrors) => ({
        ...prevErrors,
        referredTo: "",
      }));
    } else {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        referredTo: "",
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        referredTo: "Referred To is required",
      }));
    }
  };

  useEffect(() => {
    if (showFilter) {
      initData();
    }
  }, [showFilter]);

  useEffect(() => {
    if (filters.referredTo === "") {
      setDisplayValueForReferredTo("");
    }
    if (filters.referredBy === "") {
      setDisplayValueForReferredBy("");
    }
  }, [filters]);
  return (
    <div className="row g-3">
      {referralType === "OUT" && (
        <>
          <div className="col-12">
            <SearchDropdown
              placeholder="Referred By"
              onSelect={(option) => {
                setFilters({
                  ...filters,
                  referredBy: option.value,
                });
              }}
              searchCallback={updateReferredByList}
              searchField
              options={referredByOptions}
              displayValue={displayValueForReferredBy}
              error={errors.referredBy}
              label={validationRules.referredBy.displayName}
              required={validationRules.referredBy.required}
            />
          </div>
          <div className="col-12">
            <Input
              type="text"
              id="referredTo"
              name="referredTo"
              value={filters.referredTo}
              onChange={handleChange}
              placeholder="Referred To"
              className="form-control form-control-sm"
              error={errors.referredTo}
              label={validationRules.referredTo.displayName}
              required={validationRules.referredTo.required}
            />
          </div>
        </>
      )}
      {referralType === "IN" && (
        <>
          <div className="col-12">
            <Input
              type="text"
              id="referredBy"
              name="referredBy"
              value={filters.referredBy}
              onChange={handleChange}
              placeholder="Referred By"
              className="form-control form-control-sm"
              error={errors.referredBy}
              label={validationRules.referredBy.displayName}
              required={validationRules.referredBy.required}
            />
          </div>
          <div className="col-12">
            <SearchDropdown
              placeholder="Referred To"
              onSelect={(option) => {
                setFilters({
                  ...filters,
                  referredTo: option.value,
                });
              }}
              searchCallback={updateReferredToList}
              searchField
              options={referredToOptions}
              displayValue={displayValueForReferredTo}
              error={errors.referredTo}
              label={validationRules.referredTo.displayName}
              required={validationRules.referredTo.required}
            />
          </div>
        </>
      )}
      <div className="col-12">
        <Input
          type="text"
          onChange={handleChange}
          name="organization"
          value={filters.organization}
          className="form-control form-control-sm"
          id="organization"
          placeholder="organization"
          error={errors.organization}
          label={validationRules.organization.displayName}
          required={validationRules.organization.required}
        />
      </div>
      <div className="col-12">
        <Input
          type="date"
          id="fromDate"
          name="fromDate"
          value={filters.fromDate}
          onChange={handleChange}
          placeholder="Search"
          className="form-control form-control-sm border-end-0"
          error={errors.fromDate}
          label={validationRules.fromDate.displayName}
          required={validationRules.fromDate.required}
        />
      </div>
      <div className="col-12">
        <Input
          type="date"
          id="toDate"
          name="toDate"
          value={filters.toDate}
          onChange={handleChange}
          placeholder="Search"
          className="form-control form-control-sm border-end-0"
          error={errors.toDate}
          label={validationRules.toDate.displayName}
          required={validationRules.toDate.required}
        />
      </div>
      <div className="col-12">
        <InputDropdown
          options={referralStatus}
          className="form-select form-select-sm"
          onSelect={handleOptionSelects}
          name="status"
          value={filters.status}
          error={errors.status}
          label={validationRules.status.displayName}
          required={validationRules.status.required}
        />
      </div>
      <div className="col-12">
        <div className="">
          <Input
            type="text"
            id="authorizationId"
            name="authorizationId"
            value={filters.authorizationId}
            onChange={handleChange}
            placeholder="authorizationId"
            className="form-control form-control-sm"
            error={errors.authorizationId}
            label={validationRules.authorizationId.displayName}
            required={validationRules.authorizationId.required}
          />
        </div>
      </div>
    </div>
  );
}
