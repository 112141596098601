import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "../../../components/atom/Buttons/Button";
import "./RaceAndEthnicity.css";
import { getAllRaceAndEthnicityForPatient } from "../../../services/dropDownMenuOptions/dropDownMenuOptions";
import { addRaceAndEthnicity } from "../../../services/patients/patients";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import { isUserHaveOnlyViewPermission } from "../../../services/users/users";
import Logger from "../../../utils/Logger";

interface OptionType {
  value: string;
  label: string;
}
interface RaceAndEthnicityProps {
  nextTab: () => void;
}
export function RaceAndEthnicity({ nextTab }: RaceAndEthnicityProps) {
  const defaultOptionValues: OptionType[] = [{ value: "1", label: "Race 1" }];
  const MAX_ITEM_SELECTION_NUMBER = 20;
  const [editMode, setEditMode] = useState(false);
  const [availableRaces, setAvailableRaces] = useState<OptionType[]>(
    defaultOptionValues
  );
  const [selectedRaces, setSelectedRaces] = useState<OptionType[]>([]);
  const [searchData, setSearchData] = useState({
    raceText: "",
    availableEthnicityText: "",
    selectedRaceText: "",
    selectedEthnicityText: "",
  });
  const [availableEthnicities, setAvailableEthnicities] = useState<
    { value: string; label: string }[]
  >(defaultOptionValues);
  const [selectedEthnicities, setSelectedEthnicities] = useState<OptionType[]>(
    []
  );
  const [
    selectedEthnicitiesMasterData,
    setSelectedEthnicitiesMasterData,
  ] = useState<OptionType[]>([]);

  const [
    availableEthnicitiesMasterData,
    setAvailableEthnicitiesMasterData,
  ] = useState<OptionType[]>(defaultOptionValues);
  const [availableRacesMasterData, setAvailableRacesMasterData] = useState<
    OptionType[]
  >(defaultOptionValues);

  const [selectedRacesMasterData, setSelectedRacesMasterData] = useState<
    OptionType[]
  >([]);

  const activePatient = useSelector((state: any) => state.activePatient);
  const loginUserDetails = useSelector((state: any) => state.userDetails);
  const [checkForUpdateFlag, setCheckForUpdateFlag] = useState(false);
  const [viewPermissionOnlyFlag, setViewPermissionOnlyFlag] = useState(false);

  const moveRightRace = () => {
    const selectedOptions = Array.from(
      document.querySelectorAll("#available-races option:checked")
    ).map((option) => ({
      value: option.getAttribute("value") || "",
      label: option.textContent || "",
    }));

    if (selectedOptions.length > MAX_ITEM_SELECTION_NUMBER) {
      showToastMessage(
        `You can select only ${MAX_ITEM_SELECTION_NUMBER} races`,
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }
    setAvailableRaces((prevRaces) =>
      prevRaces.filter(
        (item) =>
          !selectedOptions.find(
            (option) => String(option.value) === String(item.value)
          )
      )
    );
    setSelectedRaces((prevRaces) => {
      const uniqueSelectedOptions = selectedOptions.filter(
        (option) =>
          !prevRaces.some((race) => String(race.value) === String(option.value))
      );
      return [...prevRaces, ...uniqueSelectedOptions];
    });
    setSelectedRacesMasterData((prevRaces) => {
      const uniqueSelectedOptions = selectedOptions.filter(
        (option) =>
          !prevRaces.some((race) => String(race.value) === String(option.value))
      );
      return [...prevRaces, ...uniqueSelectedOptions];
    });
  };

  const moveLeftRace = () => {
    const selectedOptions = Array.from(
      document.querySelectorAll("#selected-races option:checked")
    ).map((option) => ({
      value: option.getAttribute("value") || "",
      label: option.textContent || "",
    }));

    if (selectedOptions.length > MAX_ITEM_SELECTION_NUMBER) {
      showToastMessage(
        `You can select only ${MAX_ITEM_SELECTION_NUMBER} races`,
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }
    setSelectedRaces((prevRaces) =>
      prevRaces.filter(
        (item) =>
          !selectedOptions.find(
            (option) => String(option.value) === String(item.value)
          )
      )
    );
    setSelectedRacesMasterData((prevRaces) =>
      prevRaces.filter(
        (item) =>
          !selectedOptions.find(
            (option) => String(option.value) === String(item.value)
          )
      )
    );
    setAvailableRaces((prevRaces) => [
      ...prevRaces,
      ...selectedOptions.filter(
        (option) =>
          !prevRaces.some((race) => String(race.value) === String(option.value))
      ),
    ]);
  };

  const moveRightEthnicity = () => {
    const selectedOptions = Array.from(
      document.querySelectorAll("#available-ethnicities option:checked")
    ).map((option) => ({
      value: option.getAttribute("value") || "",
      label: option.textContent || "",
    }));
    if (selectedOptions.length > MAX_ITEM_SELECTION_NUMBER) {
      showToastMessage(
        `You can select only ${MAX_ITEM_SELECTION_NUMBER} ethnicities`,
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }
    setAvailableEthnicities((prevEthnicities) =>
      prevEthnicities.filter(
        (item) =>
          !selectedOptions.find(
            (option) => String(option.value) === String(item.value)
          )
      )
    );
    setSelectedEthnicities((prevEthnicities) => {
      const uniqueSelectedOptions = selectedOptions.filter(
        (option) =>
          !prevEthnicities.some(
            (ethnicity) => String(ethnicity.value) === String(option.value)
          )
      );
      return [...prevEthnicities, ...uniqueSelectedOptions];
    });
    setSelectedEthnicitiesMasterData((prevEthnicities) => {
      const uniqueSelectedOptions = selectedOptions.filter(
        (option) =>
          !prevEthnicities.some(
            (ethnicity) => String(ethnicity.value) === String(option.value)
          )
      );
      return [...prevEthnicities, ...uniqueSelectedOptions];
    });
  };

  const moveLeftEthnicity = () => {
    const selectedOptions = Array.from(
      document.querySelectorAll("#selected-ethnicities option:checked")
    ).map((option) => ({
      value: option.getAttribute("value") || "",
      label: option.textContent || "",
    }));
    if (selectedOptions.length > MAX_ITEM_SELECTION_NUMBER) {
      showToastMessage(
        `You can select only ${MAX_ITEM_SELECTION_NUMBER} ethnicities`,
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }
    setSelectedEthnicities((prevEthnicities) =>
      prevEthnicities.filter(
        (item) =>
          !selectedOptions.find(
            (option) => String(option.value) === String(item.value)
          )
      )
    );
    setSelectedEthnicitiesMasterData((prevEthnicities) =>
      prevEthnicities.filter(
        (item) =>
          !selectedOptions.find(
            (option) => String(option.value) === String(item.value)
          )
      )
    );
    setAvailableEthnicities((prevEthnicities) => {
      const uniqueSelectedOptions = selectedOptions.filter(
        (option) =>
          !prevEthnicities.some((ethnicity) => ethnicity.value === option.value)
      );
      return [...prevEthnicities, ...uniqueSelectedOptions];
    });
  };

  const handleSave = async () => {
    const patientId = activePatient.patientDetails.id;
    if (selectedRaces.length > 0 && selectedEthnicities.length === 0) {
      showToastMessage(
        "Please select at least one ethnicity",
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }
    if (selectedRaces.length === 0 && selectedEthnicities.length > 0) {
      showToastMessage(
        "Please select at least one race",
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }
    Logger.logInfo("Race and ethnicity for add/update", {
      data: { selectedRaces, selectedEthnicities },
    });
    const addingRaceAndEthnicity = await addRaceAndEthnicity(
      { selectedRaces, selectedEthnicities },
      patientId
    );
    if (addingRaceAndEthnicity.status) {
      Logger.logInfo("Race and ethnicity added/updated", {
        data: { selectedRaces, selectedEthnicities },
      });

      showToastMessage(
        addingRaceAndEthnicity.message,
        TOAST_TYPE_OPTIONS.SUCCESS
      );
      nextTab();
    } else {
      Logger.logError("Race and ethnicity not added/updated", {
        data: { selectedRaces, selectedEthnicities },
      });
      showToastMessage(
        addingRaceAndEthnicity.message,
        TOAST_TYPE_OPTIONS.ERROR
      );
    }
  };

  const handleCancel = () => {
    console.log("Cancel clicked");
  };

  const transformData = (data: Array<{ id: string; name: string }>) =>
    data.map((item: { id: string; name: string }) => ({
      value: item.id,
      label: item.name,
    }));

  const checkForUpdate = () => {
    setEditMode(activePatient.editStatusFlag);
    if (activePatient.patientDetails.id === "") {
      showToastMessage(
        "Patient is not been selected.",
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }

    if (activePatient.raceAndEthnicity.raceList.length > 0) {
      const selectedRaceData = transformData(
        activePatient.raceAndEthnicity.raceList
      );

      setSelectedRaces(selectedRaceData);
      setSelectedRacesMasterData(selectedRaceData);
      const updatedAvailableRace = availableRaces.filter(
        (race) =>
          !selectedRaceData.some(
            (selected) => String(selected.value) === String(race.value)
          )
      );
      setAvailableRaces(updatedAvailableRace);
    }
    if (activePatient.raceAndEthnicity.ethnicityList.length > 0) {
      const selectedEthnicityData = transformData(
        activePatient.raceAndEthnicity.ethnicityList
      );
      setSelectedEthnicities(selectedEthnicityData);
      setSelectedEthnicitiesMasterData(selectedEthnicityData);
      const updatedAvailableEthnicity = availableEthnicities.filter(
        (ethnicity) =>
          !selectedEthnicityData.some(
            (selected) => String(selected.value) === String(ethnicity.value)
          )
      );

      setAvailableEthnicities(updatedAvailableEthnicity);
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setSearchData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "raceText") {
      const filteredRaces = availableRacesMasterData.filter((race) =>
        race.label.toLowerCase().includes(value.toLowerCase())
      );

      setAvailableRaces(filteredRaces);
    }
    if (name === "selectedRaceText") {
      const filteredRaces = selectedRacesMasterData.filter((race) =>
        race.label.toLowerCase().includes(value.toLowerCase())
      );

      setSelectedRaces(filteredRaces);
    }
    if (name === "availableEthnicityText") {
      const filteredEthnicity = availableEthnicitiesMasterData.filter((race) =>
        race.label.toLowerCase().includes(value.toLowerCase())
      );
      setAvailableEthnicities(filteredEthnicity);
    }
    if (name === "selectedEthnicityText") {
      const filteredEthnicity = selectedEthnicitiesMasterData.filter((race) =>
        race.label.toLowerCase().includes(value.toLowerCase())
      );
      setSelectedEthnicities(filteredEthnicity);
    }
  };

  const updateRacesForDropdown = (raceListData: any) => {
    if (selectedRaces.length > 0) {
      const updatedAvailableRace = availableRaces.filter(
        (race) =>
          !selectedRaces.some(
            (selected) => String(selected.value) === String(race.value)
          )
      );
      setAvailableRaces(updatedAvailableRace);
    } else {
      setAvailableRaces(raceListData);
    }
  };
  const fetchData = async () => {
    const raceAndEthnicity = await getAllRaceAndEthnicityForPatient();
    updateRacesForDropdown(raceAndEthnicity?.raceList);
    setAvailableRacesMasterData(raceAndEthnicity?.raceList);

    setAvailableEthnicities(raceAndEthnicity?.ethnicityList);
    setAvailableEthnicitiesMasterData(raceAndEthnicity?.ethnicityList);

    if (loginUserDetails) {
      const isOnlyViewPermission = isUserHaveOnlyViewPermission({
        permissions: loginUserDetails.userPermission,
        module: "PATIENT",
      });

      if (isOnlyViewPermission) {
        setViewPermissionOnlyFlag(true);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (
      activePatient.patientDetails.id !== "" &&
      availableRaces.length > 1 &&
      !checkForUpdateFlag
    ) {
      checkForUpdate();
      setCheckForUpdateFlag(true);
    }
  }, [activePatient, availableRaces, checkForUpdateFlag]);

  return (
    <div className="p-3 form-container">
      <div className="row">
        <h6 className="border-bottom border-top p-3">Races and Ethnicity</h6>
      </div>
      <div className="row">
        <p className="fw-bold">Race Selection</p>
        <div className="col-5">
          <div className="form-control">
            <h5 className="text-primary fw-semibold">Available</h5>
            <label htmlFor="filter">Filter Races</label>
            <div className="input-group mb-2">
              <input
                id="raceText"
                name="raceText"
                value={searchData.raceText}
                onChange={handleChange}
                placeholder="Search to select race"
                className="form-control form-control-sm"
              />
              <span className="fa fa-solid fa-search bg-white border-start-0 input-group-text fs-6 py-0" />
            </div>
            <select id="available-races" multiple>
              {availableRaces.map((item) => (
                <option
                  key={`availableRace_${item.value}_${Math.random()}`}
                  value={item.value}
                >
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-2 d-flex flex-column justify-content-center g-3">
          <Button
            type="button"
            onClick={moveRightRace}
            className="btn btn-primary btn-sm mb-2"
            label=""
            disabled={viewPermissionOnlyFlag}
          >
            <i className="fa fa-solid fa-chevron-right" />
          </Button>
          <Button
            type="button"
            onClick={moveLeftRace}
            className="btn btn-primary btn-sm"
            label=""
            disabled={viewPermissionOnlyFlag}
          >
            <i className="fa fa-solid fa-chevron-left" />
          </Button>
        </div>

        <div className="col-5 ">
          <div className="form-control">
            <h5 className="text-warning fw-semibold">Selected</h5>
            <label htmlFor="filter">Filter Races</label>
            <div className="input-group mb-2">
              <input
                id="selectedRaceText"
                name="selectedRaceText"
                value={searchData.selectedRaceText}
                onChange={handleChange}
                placeholder="Search to selected race"
                className="form-control form-control-sm"
              />
              <span className="fa fa-solid fa-search bg-white border-start-0 input-group-text fs-6 py-0" />
            </div>
            <select id="selected-races" multiple>
              {selectedRaces.map((item) => (
                <option
                  key={`selectedRace_${item.value}_${Math.random()}`}
                  value={item.value}
                >
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <p className="fw-bold">Ethnicity Selection</p>
        <div className="col-5">
          <div className="form-control">
            <h5 className="text-primary fw-semibold">Available</h5>
            <label htmlFor="filter">Filter Ethnicity</label>
            <div className="input-group mb-2">
              <input
                id="availableEthnicityText"
                name="availableEthnicityText"
                value={searchData.availableEthnicityText}
                onChange={handleChange}
                placeholder="Search to select race"
                className="form-control form-control-sm"
              />
              <span className="fa fa-solid fa-search bg-white border-start-0 input-group-text fs-6 py-0" />
            </div>

            <select id="available-ethnicities" multiple>
              {availableEthnicities.map((item) => (
                <option
                  key={`availableEthnicity_${item.value}_${Math.random()}`}
                  value={item.value}
                >
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-2 d-flex flex-column justify-content-center ">
          <Button
            type="button"
            onClick={moveRightEthnicity}
            className="btn btn-primary btn-sm mb-2"
            label=""
            disabled={viewPermissionOnlyFlag}
          >
            <i className="fa fa-solid fa-chevron-right" />
          </Button>
          <Button
            type="button"
            onClick={moveLeftEthnicity}
            className="btn btn-primary btn-sm mb-2"
            label=""
            disabled={viewPermissionOnlyFlag}
          >
            <i className="fa fa-solid fa-chevron-left" />
          </Button>
        </div>

        <div className="col-5 ">
          <div className="form-control">
            <h5 className="text-warning fw-semibold">Selected</h5>
            <label htmlFor="filter">Filter Ethnicity</label>
            <div className="input-group mb-2">
              <input
                id="selectedEthnicityText"
                name="selectedEthnicityText"
                value={searchData.selectedEthnicityText}
                onChange={handleChange}
                placeholder="Search to select Ethnicity"
                className="form-control form-control-sm"
              />
              <span className="fa fa-solid fa-search bg-white border-start-0 input-group-text fs-6 py-0" />
            </div>
            <select id="selected-ethnicities" multiple>
              {selectedEthnicities.map((item) => (
                <option
                  key={`selectedEthnicity_${item.value}_${Math.random()}`}
                  value={item.value}
                >
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="col-12 d-flex justify-content-end mt-5">
        {!editMode && (
          <Button
            className="btn btn-outline-danger btn-sm"
            onClick={handleCancel}
            label="Clear"
          >
            Clear
          </Button>
        )}
        <Button
          className="btn btn-primary btn-sm px-4 ms-2"
          onClick={handleSave}
          label="Save and continue"
          disabled={viewPermissionOnlyFlag}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
