interface PendingTaskProps {
  pendingTaskCount: Number;
}
export function PendingTask({ pendingTaskCount }: PendingTaskProps) {
  if (pendingTaskCount === 0) return null;
  return (
    <div
      className="alert alert-danger alert-dismissible fade show position-relative"
      role="alert"
    >
      <strong>{pendingTaskCount.toString()} Task Pending</strong> You should
      check in on some of those fields below.
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      />
    </div>
  );
}
