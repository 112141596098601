import React from "react";
import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export interface LineChartProps {
  data: any;
  options?: ChartOptions<"line">;
}

export function LineChart({ data, options }: LineChartProps) {
  if (data) {
    return <Line data={data} options={options} />;
  }
  return <div title="no-data" />;
}
