import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { getTaskCountForUser } from "../../services/tasks/tasks";

export function Dashboard() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pendingTaskCount, setPendingTaskCount] = useState(0);
  const navigate = useNavigate();

  const checkTaskCount = async () => {
    const taskCountResponse = await getTaskCountForUser();
    if (
      taskCountResponse.status &&
      taskCountResponse?.data?.countOfPendingTask > 0
    ) {
      setPendingTaskCount(taskCountResponse?.data?.countOfPendingTask);
      const popupStatus = localStorage.getItem("popupStatus");
      if (!popupStatus || popupStatus !== "true") {
        setIsModalOpen(true);
      }
    }
  };
  useEffect(() => {
    checkTaskCount();
  }, []);

  const handleClose = () => {
    setIsModalOpen(false);
    // redirect to /dashboard/task
    navigate("/dashboard/tasks");
    localStorage.setItem("popupStatus", "true");
  };

  return (
    <div>
      <Modal
        show={isModalOpen}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>There are {pendingTaskCount} task pending!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <div>home</div>
    </div>
  );
}
