import React, { useState } from "react";
import { Pagination as BootstrapPagination } from "react-bootstrap";
import { InputDropdown } from "../InputDropDown/InputDropDown";
import "./Pagination.css";

export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  pageSize?: number;
  onPageChange: (page: number, pageSize?: number) => void;
}

export function Pagination({
  currentPage,
  totalPages,
  pageSize,
  onPageChange,
}: PaginationProps) {
  const [paginationPageSize, setPaginationPageSize] = useState<string>(
    pageSize?.toString() || "10",
  );
  const handlePageChange = (page: number) => {
    onPageChange(page, parseInt(paginationPageSize, 10));
  };

  if (totalPages >= 0) {
    return (
      <BootstrapPagination role="navigation" className="w-100">
        <div className="d-flex justify-content-start align-items-center w-100">
          <div className="pagination-arrows d-flex">
            <BootstrapPagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 0}
            >
              {"<"}
            </BootstrapPagination.Prev>
          </div>
          <div className="pagination-pages d-flex">
            {[...Array(Math.min(totalPages, 30))].map((_, index) => (
              <BootstrapPagination.Item
                key={`page-${index + 1}`}
                active={index === currentPage}
                onClick={() => handlePageChange(index)}
              >
                {index + 1}
              </BootstrapPagination.Item>
            ))}
          </div>
          <div className="pagination-arrows d-flex">
            <BootstrapPagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage + 1 === totalPages}
            >
              {">"}
            </BootstrapPagination.Next>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center w-48">
          <label htmlFor="maritalStatus">Items per page:</label>
          <InputDropdown
            options={[
              { label: "10", value: "10" },
              { label: "25", value: "25" },
              { label: "50", value: "50" },
            ]}
            className="form-select form-select-sm w-30"
            onSelect={(event) => {
              const { value } = event.target;
              setPaginationPageSize(value);
              onPageChange(0, parseInt(value, 10));
            }}
            name="maritalStatus"
            value={paginationPageSize}
          />
        </div>
      </BootstrapPagination>
    );
  }
}
