import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";

interface Option {
  value: string;
  label: string;
}

export interface InputDropdownProps {
  options: Option[];
  onSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  className?: string;
  name?: string;
  value?: string | number;
  disabled?: boolean;
  label?: string;
  hideEmptyOption?: boolean;
  required?: boolean;
  error?: string;
}

export function InputDropdown({
  options,
  onSelect,
  className,
  name,
  value,
  disabled = false,
  label,
  hideEmptyOption,
  required,
  error,
}: InputDropdownProps) {
  const [selectedValue, setSelectedValue] = useState(value || "");

  useEffect(() => {
    setSelectedValue(value || "");
  }, [value]);

  return (
    <>
      {!!label && (
        <label htmlFor="select">
          {label} {!!required && <span className="text-danger">*</span>}
        </label>
      )}
      <Form.Select
        className={className}
        onChange={onSelect}
        name={name}
        value={selectedValue}
        disabled={disabled}
      >
        {!hideEmptyOption && <option value="">Select an option</option>}
        {(Array.isArray(options) ? options : []).map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Select>
      {error && <span className="text-danger error-message">{error}</span>}
    </>
  );
}
