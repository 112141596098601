import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "../../../components/atom/Table/Table";
import { Card } from "../../../components/atom/Cards/Cards";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import {
  TASK_COMPLETE_STATUS,
  TOAST_TYPE_OPTIONS,
  USER_ROLES,
} from "../../../constants/variables";
import { CreateNewTask } from "../../../components/organism/CreateNewTask/CreateNewTask";
import {
  deleteTaskService,
  getAllTasks,
  getFilterTasks,
  getTaskDetailsWithId,
} from "../../../services/tasks/tasks";
import { SideFilterBar } from "../../../components/atom/SideFilterBar/SideFilterBar";
import { TaskSidebar } from "./TaskSidebar";
import { Pagination } from "../../../components/atom/Pagination/Pagination";
import { PendingTask } from "./PendingTask";
import { allKeysEmpty } from "../../../services/utils/allKeyEmpty";
import { FullScreenLoader } from "../../../components/atom/FullScreenLoader/FullScreenLoader";

export function Tasks() {
  interface CareTeamListType {
    dueDate: string;
    task: string;
    patient: string;
    details: string;
    actions: string;
  }
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userListData, setUserListData] = useState<CareTeamListType[]>([]);
  const [pendingTask, setPendingTask] = useState<any>(0);
  const [taskDetails, setTaskDetails] = useState<any>();
  const loginUserDetails = useSelector((state: any) => state.userDetails);
  const hasFilterAssess = useMemo(() => {
    if (
      loginUserDetails?.userPermission?.roleName === USER_ROLES.superAdmin ||
      loginUserDetails?.userPermission?.roleName === USER_ROLES.admin
    ) {
      return true;
    }
    return false;
  }, [loginUserDetails?.userPermission?.roleName]);

  const [paginationData, setPaginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });

  const [filters, setFilters] = useState<{
    reminderDate: string;
    taskTypeId: string;
    patientName: string;
    details: string;
  }>({
    reminderDate: "",
    taskTypeId: "",
    patientName: "",
    details: "",
  });
  const headersForTable: Record<string, string> = {
    reminderDate: "Due Date",
    taskType: "Task Type",
    patientName: "Patient",
    details: "Details",
    status: "Status",
    action: "Actions",
  };
  const sortingHeaderMap: Record<string, string> = {
    reminderDate: "DueDate",
    taskType: "Task",
    patientName: "Patient",
    status: "Status",
    details: "Details",
  };

  const fetchData = async (newPaginationData?: any) => {
    try {
      const paginationDataAsParameter = newPaginationData || paginationData;
      setIsLoading(true);
      const allTaskResponse = await getAllTasks({
        paginationDataAsParameter,
      });

      if (!allTaskResponse.status) {
        showToastMessage(allTaskResponse.message, TOAST_TYPE_OPTIONS.ERROR);
      } else {
        setPaginationData({
          ...paginationData,
          pageNo: allTaskResponse.data.pageNo,
          totalPages: allTaskResponse.data.totalPages,
          totalElements: allTaskResponse.data.totalElements,
          pageSize: allTaskResponse.data.pageSize,
        });
        setUserListData(allTaskResponse.data.dataList);
        setPendingTask(allTaskResponse.data.NoOfPendingTask);
        setTaskDetails(undefined);
      }
      setIsLoading(false);
    } catch (error) {
      showToastMessage("Error fetching all users:", TOAST_TYPE_OPTIONS.ERROR);
      console.error("Error fetching all users:", error);
    }
  };

  const fetchFilterData = async (
    newPaginationData?: any,
    newFilterData?: any,
    isClearFilter = false
  ) => {
    try {
      const paginationDataAsParameter = newPaginationData || paginationData;
      const filtersAsParameter = newFilterData || filters;
      const ifAllEmpty = allKeysEmpty(filtersAsParameter);
      if (ifAllEmpty && !isClearFilter) {
        showToastMessage(
          "Please fill at least one field",
          TOAST_TYPE_OPTIONS.ERROR
        );
        return;
      }
      setIsLoading(true);
      const allTaskResponse = await getFilterTasks({
        paginationDataAsParameter,
        filters: filtersAsParameter,
      });
      setShowFilter(false);
      if (!allTaskResponse.status) {
        showToastMessage(allTaskResponse.message, TOAST_TYPE_OPTIONS.ERROR);
      } else {
        setPaginationData({
          ...paginationData,
          pageNo: allTaskResponse.data.pageNo,
          totalPages: allTaskResponse.data.totalPages,
          totalElements: allTaskResponse.data.totalElements,
          pageSize: allTaskResponse.data.pageSize,
        });
        setUserListData(allTaskResponse.data.dataList);
        setPendingTask(allTaskResponse.data.NoOfPendingTask);
        setTaskDetails(undefined);
      }
      setIsLoading(false);
    } catch (error) {
      showToastMessage("Error fetching all users:", TOAST_TYPE_OPTIONS.ERROR);
      console.error("Error fetching all users:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteTaskValue = async (rowData: any) => {
    const deleteMemoResponse = await deleteTaskService(rowData.id);
    if (deleteMemoResponse.status) {
      showToastMessage(deleteMemoResponse.message, TOAST_TYPE_OPTIONS.SUCCESS);
      fetchData();
    } else {
      showToastMessage(deleteMemoResponse.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  const editUserTask = async (rowData: any) => {
    const taskDetailsResponse = await getTaskDetailsWithId({
      taskId: rowData.id,
    });
    if (taskDetailsResponse.status) {
      if (taskDetailsResponse.data.status === TASK_COMPLETE_STATUS) {
        showToastMessage(
          "Task is already completed, So can't edit it",
          TOAST_TYPE_OPTIONS.ERROR
        );
      } else {
        setTaskDetails({
          ...taskDetailsResponse.data,
        });
      }
    }
  };

  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    fetchData({ ...paginationData, pageNo, pageSize });
  };

  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    fetchData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };

  const clearFilterButtonAction = () => {
    const resetFilterValues = {
      reminderDate: "",
      taskTypeId: "",
      patientName: "",
      details: "",
    };
    setFilters(resetFilterValues);
    fetchData();
  };

  const isActionDisabled = (rowData: any) => {
    if (rowData?.status === "Complete") {
      return true;
    }
    return false;
  };

  return (
    <div>
      <FullScreenLoader show={isLoading} />
      {pendingTask > 0 && <PendingTask pendingTaskCount={pendingTask} />}
      <Card>
        <div className="d-flex justify-content-between">
          <h5>Tasks</h5>
          <div className="d-flex text-end">
            <CreateNewTask
              setShow={setShowModal}
              show={showModal}
              fetchData={fetchData}
              taskDetails={taskDetails}
            />
            {hasFilterAssess && (
              <SideFilterBar
                className="btn btn-warning btn-sm text-white ms-2 px-3"
                clearBtn="Clear"
                saveBtn="Apply Filter"
                heading="Filter"
                title="Filter"
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                filterButtonAction={fetchFilterData}
                clearFilterButtonAction={clearFilterButtonAction}
                body={<TaskSidebar setFilters={setFilters} filters={filters} />}
              />
            )}
          </div>
        </div>

        <div className="table-responsive care-manager-table">
          <Table
            className="table table-bordered table-striped table-hover"
            data={userListData}
            headers={headersForTable}
            enableDeleteAction={deleteTaskValue}
            enableEditAction={editUserTask}
            isEditDisabled={isActionDisabled}
            isDeleteDisabled={isActionDisabled}
            sortingHeaderMap={sortingHeaderMap}
            sortingFunction={sortingFunction}
            cssForTableKeyServicesForTableData={{
              reminderDate: {
                pastDue: {
                  true: "bg-danger",
                },
              },
            }}
          />
          {paginationData.totalPages >= 0 && (
            <Pagination
              currentPage={paginationData.pageNo}
              totalPages={paginationData.totalPages}
              pageSize={paginationData.pageSize}
              onPageChange={(page, pageSize) => {
                updatePaginationData(page, pageSize);
              }}
            />
          )}
        </div>
      </Card>
    </div>
  );
}
