import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  caseNotesDetails: { caseNoteId: null },
  caseNotesDetailsLoading: false,
  caseNotesDetailsError: null,
};

const activeCaseNote = createSlice({
  name: "activeCaseNotes",
  initialState,
  reducers: {
    initCaseNotesDetails: (state, action) => {
      state.caseNotesDetails = { caseNoteId: action.payload.caseNoteId };
    },
    resetCaseNotesDetails: (state) => {
      state.caseNotesDetails = { caseNoteId: null };
    },
  },
});

export const { initCaseNotesDetails, resetCaseNotesDetails } =
  activeCaseNote.actions;
export default activeCaseNote.reducer;
