import React, { ChangeEvent, useState } from "react";
import {
  ValidationRules,
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../../../services/utils/validateDataAsPerRules";
import { Textarea } from "../../../../atom/Textarea/Textarea";
import { showToastMessage } from "../../../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../../../constants/variables";

interface CompleteGoalModalProps {
  onClose: () => void;
  onConfirm: () => void;
  onReasonSubmit: (reason: string) => void;
}

const validationRules: ValidationRules = {
  reason: {
    required: true,
    dataType: "text",
    displayName: "Reason",
    characterLimit: 2000,
  },
};
const defaultFormValues = {
  reason: "",
};
export function CompleteGoalModal({
  onClose,
  onConfirm,
  onReasonSubmit,
}: CompleteGoalModalProps) {
  const [showReasonInput, setShowReasonInput] = useState(false);

  const [formData, setFormData] = useState({
    values: defaultFormValues,
    errors: defaultFormValues,
  });

  const handleNoClick = () => {
    setShowReasonInput(true);
  };
  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = event.target;

    const fieldError = validateFieldAsPerRules({
      rules: validationRules,
      fieldName: name,
      value,
    });

    setFormData({
      values: { ...formData.values, [name]: value },
      errors: { ...formData.errors, [name]: fieldError },
    });
  };

  const handleReasonSubmit = () => {
    const formErrors = validateDataAsPerRules({
      formData: formData.values,
      rules: validationRules,
    });

    if (Object.keys(formErrors).length) {
      showToastMessage("Please fill reason", TOAST_TYPE_OPTIONS.ERROR);
      setFormData({ ...formData, errors: formErrors });
      return;
    }
    onReasonSubmit(formData.values.reason);
    onClose();
  };

  return (
    <div className="modal fade show d-block" tabIndex={-1} role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Goal Complete Confirmation</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={onClose}
            />
          </div>
          <div className="modal-body">
            {!showReasonInput ? (
              <p>Are you sure you want to mark this goal as complete?</p>
            ) : (
              <>
                <p>Please provide a reason for not completing the goal:</p>
                <Textarea
                  className="form-control form-control-sm "
                  placeholder="Text"
                  rows={4}
                  value={formData.values.reason}
                  label={validationRules.reason.displayName}
                  error={formData.errors.reason}
                  onChange={handleChange}
                  name="reason"
                  required={validationRules.reason.required}
                />
              </>
            )}
          </div>
          <div className="modal-footer">
            {!showReasonInput ? (
              <>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleNoClick}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={onConfirm}
                >
                  Yes
                </button>
              </>
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleReasonSubmit}
              >
                Submit Reason
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
