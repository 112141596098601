import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Button } from "../Buttons/Button";

export interface SideFilterBarProps {
  heading: string;
  title: string;
  body: React.ReactNode;
  className: string;
  clearBtn: string;
  saveBtn: string;
  showFilter: boolean;
  setShowFilter: (status: boolean) => void;
  filterButtonAction?: () => void;
  clearFilterButtonAction?: () => void;
}

export function SideFilterBar({
  heading,
  title,
  body,
  className,
  clearBtn,
  saveBtn,
  showFilter,
  setShowFilter,
  filterButtonAction,
  clearFilterButtonAction,
}: SideFilterBarProps) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setShowFilter(false);
  };

  const handleShow = () => {
    setShow(true);
    setShowFilter(true);
  };

  const handleClear = () => {
    if (clearFilterButtonAction) {
      clearFilterButtonAction();
    }
    handleClose();
  };

  const handleSubmit = () => {
    if (filterButtonAction) {
      filterButtonAction();
    }
  };

  useEffect(() => {
    setShow(showFilter);
  }, [showFilter]);

  return (
    <>
      <Button
        variant="outlined"
        label=""
        onClick={handleShow}
        className={className}
      >
        <i className="mdi mdi-filter me-2" />
        {heading}
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="offcanvas-title text-primary">
            {title}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="offcanvas-body d-flex flex-column justify-content-between">
          {body}
          <div className="row g-3">
            <div className="col-12 d-flex justify-content-end mt-5 align-items-">
              <Button
                label={clearBtn}
                onClick={() => handleClear()}
                className="btn text-danger bg-transparent btn-sm me-2"
              />

              <Button
                label={saveBtn}
                onClick={handleSubmit}
                className="btn btn-primary btn-sm px-4"
              />
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
