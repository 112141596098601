import { STATUS_CODES } from "../../constants/variables";
import { API_ENDPOINTS } from "../../constants/apiList";
import ApiCaller from "../../utils/apiCaller";

export async function getSearchFilterReferralIcd10(
  fieldName: string,
): Promise<any> {
  const getUsersUrl = `${API_ENDPOINTS.searchIcd10List}/${fieldName}`;

  try {
    const response = await ApiCaller.get(getUsersUrl);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getSearchFilterReferralProcCode(
  fieldName: string,
): Promise<any> {
  const getUsersUrl = `${API_ENDPOINTS.searchProcCodeList}/${fieldName}`;

  try {
    const response = await ApiCaller.get(getUsersUrl);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getSearchFilterReferralOrganisation(
  fieldName: string,
): Promise<any> {
  const getUsersUrl = `${API_ENDPOINTS.searchOrganisationList}/${fieldName}`;

  try {
    const response = await ApiCaller.get(getUsersUrl);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
