import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  carePlanDetails: { carePlanId: 0, editCarePlanStatus: false },
  carePlanDetailsLoading: false,
  carePlanDetailsError: null,
};

const activeCarePlan = createSlice({
  name: "activeCarePlan",
  initialState,
  reducers: {
    initCarePlanDetails: (state, action) => {
      state.carePlanDetails.carePlanId = action.payload.carePlanId;
    },
    setCarePlanEditFlag: (state, action) => {
      state.carePlanDetails.editCarePlanStatus =
        action.payload.editCarePlanStatus;
      state.carePlanDetails.carePlanId = action.payload.carePlanId;
    },
  },
});

export const { initCarePlanDetails, setCarePlanEditFlag } =
  activeCarePlan.actions;
export default activeCarePlan.reducer;
