/* eslint-disable complexity */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ModalComponent } from "../../molecule/Modal/ModalComponent";
import { Input } from "../../atom/Input/Input";
import {
  createCareTeamWithSearchResultProvider,
  getSearchForCareTeamEmployeeList,
  updateCareTeamServices,
} from "../../../services/careTeam/careTeam";

import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import {
  MODULE_NAME_OPTION,
  TOAST_TYPE_OPTIONS,
} from "../../../constants/variables";
import { InputDropdown } from "../../atom/InputDropDown/InputDropDown";
import { getOptionsForDropdown } from "../../../services/dropDownMenuOptions/dropDownMenuOptions";
import { SearchDropdown } from "../../atom/SearchDropdown/SearchDropdown";
import { getSearchForOrganizationValues } from "../../../services/masterData/organization";
import { AddCareTeamAndCaseManager } from "./AddCareTeamAndCaseManager";
import { getPCPInfoService } from "../../../services/masterData/pcp";
import {
  ValidationRules,
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../services/utils/validateDataAsPerRules";
import { Textarea } from "../../atom/Textarea/Textarea";
import { displayTitleForModal } from "./helperFunctions/displayTitleForModal";
import { getSearchForCaseManagerListFromEmployee } from "../../../services/caseManager/caseManager";
import Logger from "../../../utils/Logger";
import { FullScreenLoader } from "../../atom/FullScreenLoader/FullScreenLoader";

interface CreateCareTeamAndCaseManagerProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  editData: Record<string, any>;
  fetchCareTeamData: () => void;
  moduleName: string;
}

export function CreateCareTeamAndCaseManager({
  show,
  setShow,
  editData,
  fetchCareTeamData,
  moduleName,
}: CreateCareTeamAndCaseManagerProps) {
  const activePatient = useSelector((state: any) => state.activePatient);
  const [editFlag, setEditFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [organizationList, setOrganizationList] = useState([]);
  const [providerTypeOptionList, setProviderTypeOptionList] = useState<any[]>(
    []
  );

  const [statusOption, setStatusOption] = useState([]);
  const [pcpList, setPcpList] = useState([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [displayValueForPcp, setDisplayValueForPcp] = useState("");
  const [inputFieldDisableFlag, setInputFieldDisableFlag] = useState({
    pcpName: false,
    organization: true,
    providerName: true,
    providerType: true,
    contactNumber: true,
    fax: true,
    email: true,
    fromDate: true,
    endDate: true,
    status: true,
    notes: true,
  });

  const [caseManager] = useState<any>({
    organization: "",
    providerName: "",
    providerType: "",
    contactNumber: "",
    fax: "",
    email: "",
    status: "",
    fromDate: "",
    endDate: "",
    id: "",
    notes: "",
    address1: "",
    address2: "",
    city: "",
    zipCode: "",
    organizationId: "",
    officeNumber: "",
    speciality: "",
    practice: "",
    taxonomy: "",
    upin: "",
    npi: "",
    organizationName: "",
  });

  const [formData, setFormData] = useState({
    organization: "",
    providerName: "",
    providerType: "",
    providerTypeName: "",
    contactNumber: "",
    fax: "",
    email: "",
    status: "",
    fromDate: "",
    endDate: "",
    patientId: "",
    pcpName: "",
    id: "",
    notes: "",
    organizationId: "",
    officeNumber: "",
    organizationName: "",
    employeeId: "",
  });
  const [errors, setErrors] = useState({
    organization: "",
    providerName: "",
    providerType: "",
    contactNumber: "",
    fax: "",
    email: "",
    status: "",
    fromDate: "",
    endDate: "",
    patientId: "",
    pcpName: "",
    id: "",
    notes: "",
    address1: "",
    address2: "",
    city: "",
    zipCode: "",
    organizationId: "",
    officeNumber: "",
    speciality: "",
    practice: "",
    taxonomy: "",
    upin: "",
    npi: "",
  });

  const validationRules: ValidationRules = {
    status: {
      required: false,
      dataType: "number",
      characterLimit: 50,
      displayName: "Status",
    },
    fromDate: {
      required: false,
      dataType: "date",
      characterLimit: 50,
      displayName: "From Date",
    },
    endDate: {
      required: false,
      dataType: "date",
      characterLimit: 50,
      displayName: "End Date",
    },
    notes: {
      required: false,
      dataType: "any",
      characterLimit: 300,
      displayName: "Notes",
    },
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    const newErrors = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: name,
      fieldName: name,
      value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: newErrors,
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const resetValueAndErrors = () => {
    setFormData({
      organization: "",
      providerName: "",
      providerType: "",
      providerTypeName: "",
      contactNumber: "",
      fax: "",
      email: "",
      status: "",
      fromDate: "",
      endDate: "",
      patientId: "",
      pcpName: "",
      id: "",
      notes: "",
      organizationId: "",
      officeNumber: "",
      organizationName: "",
      employeeId: "",
    });
    setErrors({
      organization: "",
      providerName: "",
      providerType: "",
      contactNumber: "",
      fax: "",
      email: "",
      status: "",
      fromDate: "",
      endDate: "",
      patientId: "",
      pcpName: "",
      id: "",
      notes: "",
      address1: "",
      address2: "",
      city: "",
      zipCode: "",
      organizationId: "",
      officeNumber: "",
      speciality: "",
      practice: "",
      taxonomy: "",
      upin: "",
      npi: "",
    });
    setInputFieldDisableFlag({
      organization: true,
      providerName: true,
      providerType: true,
      contactNumber: true,
      fax: true,
      email: true,
      fromDate: true,
      endDate: true,
      status: true,
      notes: true,
      pcpName: false,
    });
    setEditFlag(false);
  };

  const patientId = activePatient.patientDetails.id;
  const handleSubmit = async () => {
    const requestObject = {
      ...formData,
      patientId,
      moduleName,
    };

    const formErrors = validateDataAsPerRules({
      formData,
      rules: validationRules,
    });
    setErrors({
      ...errors,
      ...formErrors,
    });

    if (Object.keys(formErrors).length > 0) {
      showToastMessage(
        "Please fill all the required fields",
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }

    setErrors({
      organization: "",
      providerName: "",
      providerType: "",
      contactNumber: "",
      fax: "",
      email: "",
      status: "",
      fromDate: "",
      endDate: "",
      patientId: "",
      pcpName: "",
      id: "",
      notes: "",
      address1: "",
      address2: "",
      city: "",
      zipCode: "",
      organizationId: "",
      officeNumber: "",
      speciality: "",
      practice: "",
      taxonomy: "",
      upin: "",
      npi: "",
    });

    if (editFlag) {
      const updateCareTeamResponse = await updateCareTeamServices(
        requestObject,
        editData.id
      );
      if (updateCareTeamResponse.status) {
        showToastMessage(
          updateCareTeamResponse.message,
          TOAST_TYPE_OPTIONS.SUCCESS
        );
        setShow(false);
        resetValueAndErrors();
        fetchCareTeamData();
        setEditFlag(false);
      } else {
        showToastMessage(
          updateCareTeamResponse.message,
          TOAST_TYPE_OPTIONS.ERROR
        );
      }
    } else {
      Logger.logInfo("adding new care team member", { data: requestObject });
      const createNewCareTeam = await createCareTeamWithSearchResultProvider(
        requestObject
      );
      if (createNewCareTeam.status) {
        showToastMessage(createNewCareTeam.message, TOAST_TYPE_OPTIONS.SUCCESS);
        setShow(false);
        resetValueAndErrors();
        fetchCareTeamData();
      } else {
        showToastMessage(createNewCareTeam.message, TOAST_TYPE_OPTIONS.ERROR);
      }
    }
  };
  const handleEdit = async () => {
    if (editData && editData?.id && editData?.id !== "") {
      setFormData({
        ...formData,
        organization: editData.organization,
        organizationName: editData.organization,
        providerName: editData.providerName,
        providerTypeName: editData.providerType,
        providerType: editData.providerType,
        contactNumber: editData.contactNumber,
        notes: editData.notes,
        patientId: editData.patientId,
        status: editData.status,
        employeeId: editData.employeeId,
        fromDate: editData.fromDate,
        endDate: editData.endDate,
        email: editData.email,
        fax: editData.fax,
      });
      const getPcpDetails = await getPCPInfoService(editData?.pcpId);
      if (getPcpDetails.status) {
        setDisplayValueForPcp(
          `${getPcpDetails.data.title} ${getPcpDetails.data.firstName} ${getPcpDetails.data.lastName}`
        );
      }
      setInputFieldDisableFlag({
        ...inputFieldDisableFlag,
        fromDate: false,
        endDate: false,
        status: false,
        notes: false,
        pcpName: true,
      });
      setShow(!show);
      setEditFlag(true);
    }
  };

  const initData = async () => {
    const statusOptionList = await getOptionsForDropdown("CareProviderStatus");
    setStatusOption(statusOptionList);
  };

  useEffect(() => {
    initData();
  }, []);
  useEffect(() => {
    handleEdit();
  }, [editData]);

  const handleClose = () => {
    resetValueAndErrors();
  };
  const updateOrganizationList = async (value: string) => {
    const orgValue = await getSearchForOrganizationValues(value);
    setOrganizationList(orgValue);
  };

  const updatePcpList = async (value: string) => {
    let pcpValues = [];
    const providerTypes = providerTypeOptionList.flatMap((item) => item.value);
    if (moduleName === MODULE_NAME_OPTION.careTeam) {
      pcpValues = await getSearchForCareTeamEmployeeList(
        value,
        patientId,
        providerTypes
      );
    } else {
      pcpValues = await getSearchForCaseManagerListFromEmployee(
        value,
        patientId,
        providerTypes
      );
    }

    if (pcpValues) {
      setPcpList(pcpValues);
      setErrors({
        ...errors,
        pcpName: "",
      });
    } else {
      setErrors({
        ...errors,
        pcpName: "Please select Primary Care Provider",
      });
    }
  };
  const selectedCareTeamDetails = async (careTeamDetails: any) => {
    setFormData((prevState: any) => ({
      ...prevState,
      organizationName: careTeamDetails?.data?.organizationName ?? "",
      contactNumber: careTeamDetails?.data?.officeNumber ?? "",
      providerName: careTeamDetails?.data?.providerName ?? "",
      providerTypeName: careTeamDetails?.data?.providerTypeName ?? "",
      employeeId: careTeamDetails?.data?.employeeId ?? "",
      providerType: careTeamDetails?.data?.providerType ?? "",
      fax: careTeamDetails?.data?.fax ?? "",
      email: careTeamDetails?.data?.email ?? "",
    }));
    setInputFieldDisableFlag({
      ...inputFieldDisableFlag,
      fromDate: false,
      endDate: false,
      status: false,
      notes: false,
    });
  };
  const closePopup = () => {
    setShow(!show);
  };

  const fetchData = async () => {
    const dropValueOptionForProviderType =
      moduleName === "CASE_MANAGER"
        ? "CaseManagerProviderType"
        : "providerType";
    setIsLoading(true);
    const providerTypeOptions = await getOptionsForDropdown(
      dropValueOptionForProviderType
    );
    setIsLoading(false);
    setProviderTypeOptionList(providerTypeOptions);
  };

  useEffect(() => {
    fetchData();
  }, [moduleName]);

  return (
    <>
      <FullScreenLoader show={isLoading} />
      <ModalComponent
        showAddUserPopup={show}
        setShowAddUserPopup={setShow}
        title={displayTitleForModal({ editFlag, moduleName })}
        buttonShow={displayTitleForModal({ editFlag: false, moduleName })}
        buttonLeft={editFlag ? null : "Clear"}
        buttonRight={editFlag ? "Update & Continue" : "Save & Continue"}
        onSubmit={handleSubmit}
        className="text-end"
        size="lg"
        closeAction={handleClose}
        disableAddButton={activePatient.patientDetails.id === ""}
      >
        <div className="card mb-2 p-1">
          <div className="card-body pb-5">
            <form className="row g-3">
              <div className="col-lg-9 col-sm-9">
                <div className="input-group input-group-sm me-5 d-flex">
                  <SearchDropdown
                    onSelect={(option) => {
                      selectedCareTeamDetails(option);
                    }}
                    searchCallback={updatePcpList}
                    searchField
                    options={pcpList}
                    displayValue={displayValueForPcp}
                    disabled={inputFieldDisableFlag.pcpName}
                  />
                  <div className="ms-4 ">
                    <AddCareTeamAndCaseManager
                      show={showModal}
                      setShow={setShowModal}
                      editData={caseManager}
                      fetchCaseManagerData={fetchCareTeamData}
                      moduleName={moduleName}
                      closePopup={closePopup}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <form className="row g-3">
          <div className="col-lg-4 col-sm-6">
            <SearchDropdown
              onSelect={(option) => {
                setFormData({
                  ...formData,
                  providerName: option.label,
                  organization: option.value,
                });
              }}
              searchCallback={updateOrganizationList}
              searchField
              options={organizationList}
              placeholder="Organization"
              displayValue={formData.organizationName}
              disabled={inputFieldDisableFlag.organization}
              label="Organization"
              error={errors.organization}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Input
              type="text"
              id="providerName"
              name="providerName"
              value={formData.providerName}
              onChange={handleChange}
              placeholder="providerName"
              className="form-control form-control-sm"
              disabled={inputFieldDisableFlag.providerName}
              label="Provider Name"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Input
              type="text"
              id="providerType"
              name="providerType"
              value={formData.providerTypeName}
              onChange={handleChange}
              placeholder="providerType"
              className="form-control form-control-sm"
              disabled={inputFieldDisableFlag.providerType}
              label="Provider Type"
            />
          </div>

          <div className="col-lg-4 col-sm-6">
            <Input
              type="text"
              id="contactNumber"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              placeholder="contactNumber"
              className="form-control form-control-sm"
              disabled={inputFieldDisableFlag.contactNumber}
              label="Contact Number"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Input
              type="text"
              id="fax"
              name="fax"
              value={formData.fax}
              onChange={handleChange}
              placeholder="fax"
              className="form-control form-control-sm"
              disabled={inputFieldDisableFlag.fax}
              label="Fax"
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Input
              type="text"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="email"
              className="form-control form-control-sm"
              disabled={inputFieldDisableFlag.email}
              label="Email"
            />
          </div>

          <div className="col-lg-4 col-sm-6">
            <InputDropdown
              options={statusOption}
              className="form-select form-select-sm"
              onSelect={handleChange}
              value={formData.status}
              name="status"
              label={validationRules.status.displayName}
              error={errors.status}
              required={validationRules.status.required}
              disabled={inputFieldDisableFlag.status}
            />
          </div>

          <div className="col-lg-4 col-sm-6">
            <Input
              type="date"
              id="fromDate"
              name="fromDate"
              value={formData.fromDate}
              onChange={handleChange}
              className="form-control form-control-sm"
              disabled={inputFieldDisableFlag.fromDate}
              min={new Date().toISOString().split("T")[0]}
              label={validationRules.fromDate.displayName}
              error={errors.fromDate}
              required={validationRules.fromDate.required}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Input
              type="date"
              id="endDate"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
              className="form-control form-control-sm"
              disabled={inputFieldDisableFlag.endDate}
              min={new Date().toISOString().split("T")[0]}
              label={validationRules.endDate.displayName}
              error={errors.endDate}
              required={validationRules.endDate.required}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Textarea
              id="notes"
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              placeholder="notes"
              className="form-control form-control-sm"
              label={validationRules.notes.displayName}
              error={errors.notes}
              required={validationRules.notes.required}
              disabled={inputFieldDisableFlag.notes}
            />
          </div>
        </form>
      </ModalComponent>
    </>
  );
}
