/* eslint-disable complexity */
import { STATUS_CODES } from "../../constants/variables";
import { API_ENDPOINTS } from "../../constants/apiList";
import ApiCaller from "../../utils/apiCaller";

export async function getAllForms({
  paginationDataAsParameter,
  patientId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
}) {
  const { pageNo, pageSize, sortingFieldName, sortingOrder } =
    paginationDataAsParameter;
  let getAllLabUrl = `${API_ENDPOINTS.getAllForms}/${patientId}?pageNo=${pageNo}&pageSize=${pageSize}`;
  if (
    sortingFieldName &&
    sortingFieldName !== "" &&
    sortingOrder &&
    sortingOrder !== ""
  ) {
    getAllLabUrl = `${getAllLabUrl}&sortBy=${sortingFieldName}&sortDir=${sortingOrder}`;
  }

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(getAllLabUrl);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Form data retrieved successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to retrieve form data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}


export async function createNewForm(requestObject: any) {
  const getCreateLabResultsUrl = API_ENDPOINTS.addForm;
  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(getCreateLabResultsUrl, requestObject);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Form data saved successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to save form data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getFormByIdAPI(formId: string | number) {
  const updateLabResultUrl = `${API_ENDPOINTS.getForm}/${formId}`;
  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(updateLabResultUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Form data retrieved successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to fetch Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function updateExitingForm(requestObject: any, formId: string) {
  const updateLabUrl = `${API_ENDPOINTS.updateForm}/${formId}`;
  try {
    const response = await ApiCaller.put<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(updateLabUrl, requestObject);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Form data updated successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to update form data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
export async function deleteFormAPI(formId: number | string): Promise<any> {
  const deleteLabUrl = `${API_ENDPOINTS.deleteForm}/${formId}`;

  try {
    const response: { isSuccess: boolean; responseMsg?: Array<string> } =
      await ApiCaller.delete(deleteLabUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Form data deleted successfully",
        data: response,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getFormFileOrImgByIdAPI(
  LabResultId: string | number,
): Promise<any> {
  const updateLabResultUrl = `${API_ENDPOINTS.getFormFileOrImg}/${LabResultId}`;
  try {
    const response = await ApiCaller.getImage(updateLabResultUrl);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
