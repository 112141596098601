import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Card } from "../../../components/atom/Cards/Cards";
import { Input } from "../../../components/atom/Input/Input";
import { Button } from "../../../components/atom/Buttons/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { Image } from "../../../components/atom/Images/Image";
import "./UpdatePassword.css";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import {
  resetPasswordService,
  validateTheAccessToken,
} from "../../../services/users/users";
import { setUserDetails } from "../../../redux/userDetails/userDetailsSlice";
import { FullScreenLoader } from "../../../components/atom/FullScreenLoader/FullScreenLoader";
import { ExpiredLinkMessage } from "./ExpiredLinkMessage";

export function UpdatePassword() {
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLinkValidStatusFlag, setIsLinkValidStatusFlag] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleLogin = async () => {
    if (formData.newPassword === formData.confirmPassword) {
      setIsLoading(true);
      const response = await resetPasswordService(formData);
      setIsLoading(false);
      if (response.status) {
        dispatch(setUserDetails({ ...response.data }));
        showToastMessage(response.message, TOAST_TYPE_OPTIONS.SUCCESS);
        localStorage.removeItem("accessToken");
        navigate("/login");
      } else {
        showToastMessage(response.message, TOAST_TYPE_OPTIONS.ERROR);
      }
      setFormData({ ...formData, newPassword: "", confirmPassword: "" });
    } else {
      showToastMessage("Passwords do not match", TOAST_TYPE_OPTIONS.ERROR);
    }
  };
  const checkForAccessToken = async () => {
    const token = searchParams.get("token") || "";
    if (token !== "") {
      localStorage.setItem("accessToken", token);
      const validateTokenResponse = await validateTheAccessToken(token);
      if (validateTokenResponse.status) {
        setIsLinkValidStatusFlag(1);
      } else {
        setIsLinkValidStatusFlag(2);
      }
    } else {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        setIsLinkValidStatusFlag(1);
      } else {
        setIsLinkValidStatusFlag(2);
      }
    }
  };
  useEffect(() => {
    checkForAccessToken();
  }, []);
  if (isLinkValidStatusFlag === 1 || isLinkValidStatusFlag === 0) {
    return (
      <div>
        <FullScreenLoader show={isLinkValidStatusFlag === 0} />
        <Image
          src="assets/images/login-bkg.jpg"
          alt="Image Alt Text"
          className="Login-background-image"
        />
        <Card className="card-container">
          <Image
            src="assets/images/MDTel-logo.png"
            alt="Image Alt Text"
            className="md-tel-logo"
          />
          <p className="fs-4 fw-semibold pt-4 pb-3">Change Password</p>
          <div className="mb-2">
            <Input
              type="password"
              id="newPassword"
              name="newPassword"
              value={formData.newPassword}
              onChange={handleChange}
              placeholder="New Password"
              className="input-styling input-margin"
            />
          </div>
          <div className="mb-2">
            <Input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              placeholder="Confirm Password"
              className="input-styling input-margin"
            />
          </div>
          <div className="link-btn">
            <Button
              label="Update Password"
              onClick={handleLogin}
              className="button default-btn btn btn-gradient-primary send-link-btn"
              isLoading={isLoading}
            />{" "}
          </div>
        </Card>
      </div>
    );
  }
  return <ExpiredLinkMessage />;
}
