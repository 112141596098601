import React from "react";
import "./Input.css";

export interface InputProps {
  type?: string;
  placeholder?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  label?: string;
  name?: string;
  className: string;
  labelClassName?: string;
  disabled?: boolean;
  ariaLabel?: string;
  max?: string;
  min?: string;
  ref?: React.RefObject<HTMLInputElement>;
  fullWidth?: boolean;
  required?: boolean;
  error?: string;
  autoComplete?: string;
}

export function Input({
  type,
  placeholder,
  value,
  onChange,
  id,
  label,
  name,
  className,
  disabled = false,
  autoComplete = "off",
  ariaLabel,
  max,
  min,
  ref,
  fullWidth,
  required,
  error,
  labelClassName = "",
}: InputProps) {
  return (
    <div className={`${fullWidth && "w-100"}`}>
      {label && (
        <label className={labelClassName} htmlFor={id}>
          {label} {required && <span className="text-danger"> *</span>}
        </label>
      )}
      <input
        type={type || "text"}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        id={id}
        className={className}
        name={name}
        disabled={disabled}
        aria-label={ariaLabel}
        max={max}
        min={min}
        ref={ref}
        autoComplete={autoComplete}
      />
      {error && <span className="text-danger error-message">{error}</span>}
    </div>
  );
}
