import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { AccordionAtom } from "../../atom/Accordion/Accordion";
import { Button } from "../../atom/Buttons/Button";
import { BasicDetails } from "./BasicDetails/BasicDetails";
import { defaultBasicDetailsValues } from "./helperFunction/constants";
import {
  createIRAService,
  finalizeIRAService,
  updateIRAService,
} from "../../../services/FormsAndIRA/ira";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import { formatDate } from "../../../services/utils/formatDate";
import IRAQuestions from "./IRAQuestions/IRAQuestions";
import { TimeSpent } from "./TimeSpent/TimeSpent";
import {
  IRARecord,
  validateAssessment,
} from "../../../dataLayer/formsAndIRA/ira";
import { FullScreenLoader } from "../../atom/FullScreenLoader/FullScreenLoader";
import { ModalComponent } from "../../molecule/Modal/ModalComponent";

export interface CreateIRAProps {
  handleShowTable: () => void;
  IRAEditData: IRARecord | undefined;
  isViewMode: boolean;
}

export function CreateIRA({
  handleShowTable,
  IRAEditData,
  isViewMode,
}: CreateIRAProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [showReviewConfirm, setShowReviewConfirm] = useState(false);
  const [basicFormData, setBasicFormData] = useState({
    values: { ...defaultBasicDetailsValues },
    errors: {
      name: "",
      timeSpent: "",
    },
  });
  const [editFlag, setEditFlag] = useState(false);
  const activePatient = useSelector((state: any) => state.activePatient);
  const hasQuestionsTabAccess = useMemo(() => basicFormData.values.id === 0, [
    basicFormData.values.id,
  ]);
  const handleReset = () => {
    setBasicFormData({
      values: { ...defaultBasicDetailsValues },
      errors: {
        name: "",
        timeSpent: "",
      },
    });
  };

  const handleSubmitBasicDetails = async () => {
    const basicDetails = basicFormData.values;
    const request = {
      patientId: activePatient.patientDetails.id || "",
      name: basicDetails.name,
      statusId: basicDetails.statusId,
    };
    let iraResponse: any;
    setIsLoading(true);
    if (basicDetails?.id) {
      iraResponse = await updateIRAService(basicDetails, basicDetails.id);
    } else {
      iraResponse = await createIRAService(request);
    }
    if (iraResponse.status) {
      showToastMessage(iraResponse.message, TOAST_TYPE_OPTIONS.SUCCESS);
      setBasicFormData((prevState) => ({
        ...prevState,
        values: {
          ...iraResponse.data,
          createdOn: formatDate(iraResponse.data.createdOn, "YYYY-MM-DD"),
          lastModifiedOn: formatDate(
            iraResponse.data.lastModifiedOn,
            "YYYY-MM-DD"
          ),
        },
      }));
      if (iraResponse.data?.timeSpent) {
        setEditFlag(true);
      } else {
        setEditFlag(false);
      }
    } else {
      showToastMessage(iraResponse.message, TOAST_TYPE_OPTIONS.ERROR);
    }
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    const { values } = basicFormData;
    setIsLoading(true);
    const iraResponse = await finalizeIRAService(values.id);
    if (iraResponse.status) {
      showToastMessage(iraResponse.message, TOAST_TYPE_OPTIONS.SUCCESS);
      handleShowTable();
    } else {
      showToastMessage(iraResponse.message, TOAST_TYPE_OPTIONS.ERROR);
    }
    setIsLoading(false);
  };

  const handleOpenConfirm = async () => {
    const { values } = basicFormData;
    if (values.id) {
      if (!values.name) {
        showToastMessage(
          "Please fill all mandatory fields in basic details",
          TOAST_TYPE_OPTIONS.ERROR
        );
        return;
      }
      if (!values.timeSpent) {
        showToastMessage(
          "Please fill all mandatory fields in time spent",
          TOAST_TYPE_OPTIONS.ERROR
        );
        return;
      }
      setIsLoading(true);
      const iraResponse = await validateAssessment(values.id);
      if (iraResponse.status) {
        setShowReviewConfirm(true);
      } else {
        showToastMessage(iraResponse.message, TOAST_TYPE_OPTIONS.ERROR);
      }
      setIsLoading(false);
    } else {
      showToastMessage(
        "Please fill all mandatory fields",
        TOAST_TYPE_OPTIONS.ERROR
      );
    }
  };

  const tabData = [
    {
      id: "1",
      title: "Basic Detail",
      disabled: false,
      content: (
        <BasicDetails
          formData={basicFormData}
          setFormData={setBasicFormData}
          handleReset={handleReset}
          handleSubmitBasicDetails={handleSubmitBasicDetails}
          isViewMode={isViewMode}
        />
      ),
    },

    {
      id: "2",
      title: "About Your Health",
      disabled: hasQuestionsTabAccess,
    },
    {
      id: "3",
      title: "About Your Conditions",
      disabled: hasQuestionsTabAccess,
    },
    {
      id: "4",
      title: "About Your Doctors",
      disabled: hasQuestionsTabAccess,
    },
    {
      id: "5",
      title: "About Your Medical Needs",
      disabled: hasQuestionsTabAccess,
    },
    {
      id: "6",
      title: "About Your Medicine",
      disabled: hasQuestionsTabAccess,
    },
    {
      id: "7",
      title: "Any Other Medical Needs",
      disabled: hasQuestionsTabAccess,
    },
    {
      id: "8",
      title: "Your Personal Needs",
      disabled: hasQuestionsTabAccess,
    },
    {
      id: "9",
      title: "Social Determinants Of Health",
      disabled: hasQuestionsTabAccess,
    },
    {
      id: "10",
      title: "Time Spent",
      content: (
        <TimeSpent
          formData={basicFormData}
          setFormData={setBasicFormData}
          handleSubmitBasicDetails={handleSubmitBasicDetails}
          isViewMode={isViewMode}
          editFlag={editFlag}
        />
      ),
      disabled: hasQuestionsTabAccess,
    },
  ];

  const updatedTabs = tabData.map((tab) => {
    if (tab?.content) {
      return tab;
    }
    return {
      ...tab,
      content: (
        <IRAQuestions
          apiKey={tab.title.replaceAll(" ", "")}
          iraDetails={basicFormData.values}
          isViewMode={isViewMode}
        />
      ),
    };
  });

  useEffect(() => {
    if (IRAEditData) {
      setBasicFormData((prev) => ({
        ...prev,
        values: {
          ...prev.values,
          ...IRAEditData,
          createdOn: formatDate(IRAEditData.createdOn, "YYYY-MM-DD"),
          lastModifiedOn: formatDate(IRAEditData.lastModifiedOn, "YYYY-MM-DD"),
        },
      }));
      if (IRAEditData?.timeSpent) {
        setEditFlag(true);
      } else {
        setEditFlag(false);
      }
    }
  }, [IRAEditData]);

  return (
    <>
      <FullScreenLoader show={isLoading} />

      <AccordionAtom items={updatedTabs} />
      <div className="card-footer d-flex justify-content-between">
        <Button
          type="button"
          className="btn btn-danger btn-sm"
          onClick={handleShowTable}
          label="Close"
        />
        <Button
          type="button"
          className="btn btn-primary btn-sm px-4 ms-3"
          onClick={() => handleOpenConfirm()}
          label="Review & Complete"
          hidden={isViewMode}
        />
      </div>
      {showReviewConfirm && (
        <ModalComponent
          buttonShow="Add New Form"
          buttonLeft="No"
          buttonRight="Yes"
          title="Please Confirm"
          onSubmit={handleSubmit}
          className="text-end"
          showAddUserPopup={showReviewConfirm}
          setShowAddUserPopup={setShowReviewConfirm}
          closeAction={() => setShowReviewConfirm(false)}
          showToggleAsButton={false}
        >
          <p>
            IRA data would be finalized without any further edits and required
            information would be updated in Care plan
          </p>
        </ModalComponent>
      )}
    </>
  );
}
