import {
  deleteDiagnosticResultAPI,
  updateExitingDiagnosticResult,
  getAllDiagnosticResult,
  createNewDiagnosticResult,
  getDiagnosticResultByIdAPI,
  getDiagnosticResultFileOrImgByIdAPI,
} from "../../dataLayer/labAndDiagnosticResult/diagnosticResult";
import { formatDate } from "../utils/formatDate";

export interface CreateDiagnosticResultRecord {
  uploadFile?: any;
  patientId: string;
  patientName: string;
  documentName: string;
  diagnosticResult: string;
  diagnosedDate: string;
  providerName: string;
  comment: string;
  diagnosticResultPath: string;
  files?: File;
  icd10?: string;
}

export async function getAllDiagnosticResultServices({
  paginationDataAsParameter,
  patientId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
}): Promise<any> {
  try {
    const allReferrals = await getAllDiagnosticResult({
      paginationDataAsParameter,
      patientId,
    });
    if (allReferrals.status) {
      return allReferrals.data;
    }
    return allReferrals;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all Referrals In.",
      details: error.message,
    };
  }
}

export async function getDiagnosticResultByIdService(
  DiagnosticId: string | number,
): Promise<any> {
  if (!DiagnosticId) {
    return {
      status: false,
      code: 500,
      message: "Diagnostic ID is required.",
      details: "Diagnostic ID is required.",
    };
  }

  try {
    const response = await getDiagnosticResultByIdAPI(DiagnosticId);
    if (!response || !response.data) {
      return {
        status: false,
        code: 500,
        message: "Diagnostic result data not found.",
        details: "Diagnostic result data not found.",
      };
    }

    const diagnosticResultData: Record<any, any> = response;
    const diagnosticResultWithImages =
      await getDiagnosticResultFileOrImgByIdAPI(diagnosticResultData.data.id);

    if (diagnosticResultWithImages) {
      diagnosticResultData.data.images = {
        loadFileOrImg: diagnosticResultWithImages,
      };
    }

    return diagnosticResultData;
  } catch (error: any) {
    console.error(error);
    return {
      status: false,
      code: 500,
      message: "Error fetching Diagnostic result value.",
      details: error.message,
    };
  }
}

export async function createDiagnosticResult(
  formData: CreateDiagnosticResultRecord,
): Promise<any> {
  try {
    const formDataRequest = new FormData();
    formDataRequest.append(
      "diagnosticData",
      JSON.stringify({
        patientId: formData.patientId,
        patientName: formData.patientName,
        documentName: formData.documentName,
        diagnosedDate: formatDate(formData.diagnosedDate),
        providerName: formData.providerName,
        comment: formData.comment,
        diagnosticResultPath: formData.diagnosticResultPath,
        diagnosticResult: formData.diagnosticResult,
      }),
    );

    if (formData.uploadFile) {
      formDataRequest.append("files", formData.uploadFile);
    }
    const newUser = await createNewDiagnosticResult(formDataRequest);
    return newUser;
  } catch (error: any) {
    console.error(error);
    return {
      status: false,
      code: 500,
      message: "Error creating user.",
      details: error.message,
    };
  }
}

export async function updateDiagnosticResult(
  params: CreateDiagnosticResultRecord,
  diagnosticId: string,
): Promise<any> {
  try {
    const formDataRequest = new FormData();
    formDataRequest.append(
      "diagnosticData",
      JSON.stringify({
        patientId: params.patientId,
        patientName: params.patientName,
        documentName: params.documentName,
        diagnosticResult: params.diagnosticResult?.toString(),
        diagnosedDate: formatDate(params.diagnosedDate),
        providerName: params.providerName,
        comment: params.comment,
        diagnosticResultPath: params.diagnosticResultPath,
      }),
    );
    if (params.uploadFile) {
      formDataRequest.append("files", params.uploadFile);
    } else {
      formDataRequest.append("files", "");
    }
    const newUser = await updateExitingDiagnosticResult(
      formDataRequest,
      diagnosticId,
    );
    return newUser;
  } catch (error: any) {
    console.error(error);
    return {
      status: false,
      code: 500,
      message: "Error updating diagnostic result.",
      details: error.message,
    };
  }
}

export async function deleteDiagnosticResultService(
  diagnosticId: string | number,
): Promise<any> {
  if (!diagnosticId) {
    return {
      status: false,
      code: 500,
      message: "Diagnostic ID is required.",
      details: "Diagnostic ID is required.",
    };
  }

  try {
    const response = await deleteDiagnosticResultAPI(diagnosticId);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting diagnostic result value.",
      details: error.message,
    };
  }
}
