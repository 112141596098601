import { HorizontalTabs } from "../../../components/atom/HorizontalTab/HorizontalTab";
import { ReferralIn } from "./ReferralIn";
import { ProfileCard } from "../../../components/molecule/ProfileCard/ProfileCard";

export function Referrals() {
  const tabs = [
    {
      key: "1",
      title: "Referral In",
      content: <ReferralIn referralType="IN" displayLabel="Referral In" />,
    },
    {
      key: "2",
      title: "Referral Out",
      content: <ReferralIn referralType="OUT" displayLabel="Referral Out" />,
    },
  ];
  return (
    <div>
      <div>
        <ProfileCard />
        <HorizontalTabs tabs={tabs} />
      </div>
    </div>
  );
}
