import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import userDetailsSlice from "./userDetails/userDetailsSlice";
import activePatientSlice from "./activePatient/activePatientSlice";
import activeCarePlan from "./activeCarePlan/activeCarePlan";
import activeCaseNotes from "./activeCaseNote/activeCaseNote";

const persistConfig = {
  key: "root",
  storage,
};
const rootReducer = combineReducers({
  userDetails: userDetailsSlice,
  activePatient: activePatientSlice,
  activeCarePlan,
  activeCaseNotes,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
