/* eslint-disable complexity */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ModalComponent } from "../../molecule/Modal/ModalComponent";
import { Input } from "../../atom/Input/Input";
import { InputDropdown } from "../../atom/InputDropDown/InputDropDown";
import {
  createReferral,
  CreateReferralParameter,
  getIcd10InfoService,
  getProcedureCodeInfoService,
  getReferredByInfoService,
  getReferredToInfoService,
  updateReferralService,
} from "../../../services/referrals/referrals";
import { formatDate } from "../../../services/utils/formatDate";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import { getOptionsForDropdown } from "../../../services/dropDownMenuOptions/dropDownMenuOptions";
import { SearchDropdown } from "../../atom/SearchDropdown/SearchDropdown";
import { getSearchForPcpValues } from "../../../services/searchFilter/searchFilter";
import {
  getSearchForReferralIcd10Values,
  getSearchForReferralProcCodeValues,
} from "../../../services/referrals/searchReferralFilter";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
  ValidationRules,
} from "../../../services/utils/validateDataAsPerRules";
import { Textarea } from "../../atom/Textarea/Textarea";

interface CreateNewReferralProps {
  getReferralData: () => void;
  editReferralDetails: any;
  editMode: boolean;
  setEditMode: (value: boolean) => void;
  referralType: string;
  buttonTitle: string;
  resetEditData: () => void;
}

export function CreateNewReferral({
  getReferralData,
  editReferralDetails,
  editMode,
  setEditMode,
  referralType,
  buttonTitle,
  resetEditData,
}: CreateNewReferralProps) {
  const [showAddUserPopup, setShowAddUserPopup] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [
    displayValueForProcedureCode,
    setDisplayValueForProcedureCode,
  ] = useState("");

  const [displayValueForIcd10, setDisplayValueForIcd10] = useState("");

  const [displayValueForReferredTo, setDisplayValueForReferredTo] = useState(
    ""
  );

  const [displayValueForReferredBy, setDisplayValueForReferredBy] = useState(
    ""
  );

  const [formData, setFormData] = useState({
    referral: "",
    referredTo: "",
    referredBy: "",
    effectiveDate: "",
    icd10: "",
    authorizationId: "",
    maxAuthorization: "",
    organization: "",
    reason: "",
    notes: "",
    status: "",
    procedureCode: "",
    authStatus: "",
    insCarrier: "",
    referralType,
    patientId: "",
  });
  const validationRules: ValidationRules = {
    referral: {
      required: false,
      dataType: "number",
      characterLimit: 10,
      displayName: "Referral#",
    },
    referredTo: {
      required: true,
      dataType: referralType === "IN" ? "textOrNumber" : "text",
      characterLimit: 50,
      displayName: "Referred To",
    },
    referredBy: {
      required: true,
      dataType: referralType === "OUT" ? "textOrNumber" : "text",
      characterLimit: 50,
      displayName: "Referred By",
    },
    effectiveDate: {
      required: true,
      dataType: "date",
      characterLimit: 50,
      displayName: referralType === "IN" ? "Effective Date" : "Created Date",
    },
    authorizationId: {
      required: false,
      dataType: "number",
      minValue: 1,
      maxValue: 100,
      characterLimit: 3,
      displayName: "Authorization Id",
    },
    maxAuthorization: {
      required: false,
      dataType: "number",
      minValue: 1,
      maxValue: 100,
      characterLimit: 3,
      displayName: "Max Authorization",
    },
    icd10: {
      required: false,
      dataType: "any",
      characterLimit: 50,
      displayName: "ICD 10",
    },
    procedureCode: {
      required: false,
      dataType: "any",
      characterLimit: 50,
      displayName: "Procedure Codes",
    },
    authStatus: {
      required: false,
      dataType: "any",
      characterLimit: 50,
      displayName: "Auth Status",
    },
    insCarrier: {
      required: false,
      dataType: "any",
      characterLimit: 50,
      displayName: "INS Carrier",
    },
    notes: {
      required: false,
      dataType: "any",
      characterLimit: 300,
      displayName: "Add Notes",
    },
    reason: {
      required: false,
      dataType: "any",
      characterLimit: 300,
      displayName: "Referral Reason",
    },
    organization: {
      required: false,
      dataType: "any",
      characterLimit: 50,
      displayName: "Organization",
    },
    status: {
      required: false,
      dataType: "any",
      characterLimit: 50,
      displayName: "Status",
    },
  };
  const [errors, setErrors] = useState({
    referral: "",
    referredTo: "",
    referredBy: "",
    effectiveDate: "",
    authorizationId: "",
    maxAuthorization: "",
    organization: "",
    reason: "",
    notes: "",
    insCarrier: "",
    referralType,
    patientId: "",
  });

  const [authStatus, setAuthStatus] = useState([]);
  const [referralStatus, setReferralStatus] = useState([]);

  const [referredTo, setReferredTo] = useState([]);
  const [referredBy, setReferredBy] = useState([]);
  const [icd10, setIcd10] = useState([]);
  const [procCode, setProcCode] = useState([]);

  const debounce = (func: Function, delay: number) => {
    let timeoutId: any;
    return function (this: any, ...args: any[]) {
      const context = this;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const debouncedSearchReferredTo = debounce(async (value: string) => {
    try {
      const referredToValues = await getSearchForPcpValues(value);
      setReferredTo(referredToValues);
    } catch (error) {
      console.error("Error fetching Referred To values:", error);
    }
  }, 900);

  const debouncedSearchReferredBy = debounce(async (value: string) => {
    try {
      const referredByValues = await getSearchForPcpValues(value);
      setReferredBy(referredByValues);
    } catch (error) {
      console.error("Error fetching ReferredBy values:", error);
    }
  }, 900);
  const debouncedSearchIcd10 = debounce(async (value: string) => {
    try {
      const referredIcd10Values = await getSearchForReferralIcd10Values(value);
      setIcd10(referredIcd10Values);
    } catch (error) {
      console.error("Error fetching ICD10 values:", error);
    }
  }, 900);
  const debouncedSearchProcCode = debounce(async (value: string) => {
    try {
      const referredProcCodeValues = await getSearchForReferralProcCodeValues(
        value
      );
      setProcCode(referredProcCodeValues);
    } catch (error) {
      console.error("Error fetching Procedure Code:", error);
    }
  }, 900);

  const updateReferredToList = async (value: string) => {
    if (value) {
      const referredToValues = await getSearchForPcpValues(value);
      setReferredTo(referredToValues);
      if (referredTo) {
        setErrors({
          ...errors,
          referredTo: "",
        });
      }
    } else {
      formData.referredTo = "";
      setErrors({
        ...errors,
        referredTo: "Referred To is required",
      });
    }
  };
  const updateReferredByList = async (value: string) => {
    if (value) {
      const referredByValues = await getSearchForPcpValues(value);
      setReferredBy(referredByValues);
      if (referredBy) {
        setErrors({
          ...errors,
          referredBy: "",
        });
      }
    } else {
      formData.referredBy = "";
      setErrors({
        ...errors,
        referredBy: "Referred By is required",
      });
    }
  };
  const updateReferredIcd10List = async (value: string) => {
    const referredIcd10Values = await getSearchForReferralIcd10Values(value);
    setIcd10(referredIcd10Values);
  };
  const updateReferredProcCodeList = async (value: string) => {
    const referredProcCodeValues = await getSearchForReferralProcCodeValues(
      value
    );
    setProcCode(referredProcCodeValues);
  };

  const activePatient = useSelector((state: any) => state.activePatient);

  const checkForNumber = (value: string | number): number | "" => {
    if (Number.isNaN(Number(value))) {
      return "";
    }
    return parseInt(String(value), 10);
  };
  const handleChange = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    const newErrors = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: name,
      fieldName: name,
      value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: newErrors,
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "referredTo") {
      if (referralType === "IN") {
        if (!value) {
          setErrors({ ...errors, [name]: "Please enter referral to" });
        } else {
          debouncedSearchReferredTo(value);
        }
      }
    }
    if (name === "referredBy") {
      if (referralType === "OUT") {
        if (!value) {
          setErrors({ ...errors, [name]: "Please enter referral to" });
        } else {
          debouncedSearchReferredBy(value);
        }
      }
    }
    if (name === "icd10" && value.trim() !== "") {
      debouncedSearchIcd10(value);
    }
    if (name === "procedureCode" && value.trim() !== "") {
      debouncedSearchProcCode(value);
    }

    if (referralType === "OUT" || referralType === "Out") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]:
          name === "referredBy" ||
          name === "maxAuthorization" ||
          name === "authorization"
            ? checkForNumber(value)
            : value,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]:
          name === "referredTo" || name === "authorization"
            ? checkForNumber(value)
            : value,
      }));
    }
  };

  const resetErrorAndValue = () => {
    setDisplayValueForReferredBy("");
    setDisplayValueForReferredTo("");
    setDisplayValueForIcd10("");
    setDisplayValueForProcedureCode("");
    setFormData({
      referral: "",
      referredTo: "",
      referredBy: "",
      effectiveDate: "",
      icd10: "",
      authorizationId: "",
      maxAuthorization: "",
      organization: "",
      reason: "",
      notes: "",
      status: "",
      procedureCode: "",
      authStatus: "",
      insCarrier: "",
      referralType,
      patientId: "",
    });
    setErrors({
      referral: "",
      referredTo: "",
      referredBy: "",
      effectiveDate: "",
      authorizationId: "",
      maxAuthorization: "",
      organization: "",
      reason: "",
      notes: "",
      insCarrier: "",
      referralType,
      patientId: "",
    });
  };

  const handleSubmit = async () => {
    if (referralType === "IN") {
      const formErrors = validateDataAsPerRules({
        formData,
        rules: validationRules,
      });
      setErrors({
        ...errors,
        ...formErrors,
      });
      if (Object.keys(formErrors).length > 0) {
        showToastMessage(
          "Please fill all the required fields and try again.",
          TOAST_TYPE_OPTIONS.ERROR
        );
        return;
      }
    } else {
      const formErrors = validateDataAsPerRules({
        formData,
        rules: validationRules,
      });
      setErrors({
        ...errors,
        ...formErrors,
      });
      if (Object.keys(formErrors).length > 0) {
        showToastMessage(
          "Please fill all the required fields and try again.",
          TOAST_TYPE_OPTIONS.ERROR
        );
        return;
      }
    }
    if (referralType === "IN") {
      setShowAddUserPopup(true);
      setIsLoading(true);
      if (editMode) {
        const requestParameter: CreateReferralParameter = {
          referral: formData.referral,
          referredTo: formData.referredTo,
          referredBy: formData.referredBy,
          effectiveDate: formatDate(formData.effectiveDate),
          icd10: formData.icd10,
          authorizationId: formData.authorizationId,
          maxAuthorization: formData.maxAuthorization,
          organization: formData.organization,
          reason: formData.reason,
          notes: formData.notes,
          status: formData.status,
          procedureCode: formData.procedureCode,
          authStatus: formData.authStatus,
          insCarrier: formData.insCarrier,
          patientId: activePatient.patientDetails.id,
          referralType,
        };
        const updateRequestObject = {
          userId: editReferralDetails?.id,
          updatedReferralData: requestParameter,
        };
        const response = await updateReferralService(updateRequestObject);
        if (response.status) {
          getReferralData();
          showToastMessage(response.message, TOAST_TYPE_OPTIONS.SUCCESS);
          setShowAddUserPopup(false);
          resetErrorAndValue();
          setShowAddUserPopup(false);
        } else {
          showToastMessage(response.message, TOAST_TYPE_OPTIONS.ERROR);
        }
        setIsLoading(false);
      } else {
        const formattedData = {
          ...formData,
          effectiveDate: formatDate(formData.effectiveDate),
          patientId: activePatient.patientDetails.id,
        };
        const response = await createReferral(formattedData);
        setIsLoading(false);
        if (response.status) {
          getReferralData();
          showToastMessage(response.message, TOAST_TYPE_OPTIONS.SUCCESS);
          setShowAddUserPopup(false);
          resetErrorAndValue();
          setShowAddUserPopup(false);
        } else {
          showToastMessage(response.message, TOAST_TYPE_OPTIONS.ERROR);
        }
      }
    } else if (referralType === "OUT") {
      setShowAddUserPopup(true);
      setIsLoading(true);
      if (editMode) {
        const requestParameter: CreateReferralParameter = {
          referral: formData.referral,
          referredTo: formData.referredTo,
          referredBy: formData.referredBy,
          effectiveDate: formatDate(formData.effectiveDate),

          icd10: formData.icd10,
          authorizationId: formData.authorizationId,
          maxAuthorization: formData.maxAuthorization,
          organization: formData.organization,
          reason: formData.reason,
          notes: formData.notes,
          status: formData.status,
          procedureCode: formData.procedureCode,
          authStatus: formData.authStatus,
          insCarrier: formData.insCarrier,
          patientId: activePatient.patientDetails.id,
          referralType,
        };
        const updateRequestObject = {
          userId: editReferralDetails?.id,
          updatedReferralData: requestParameter,
        };
        const response = await updateReferralService(updateRequestObject);
        if (response.status) {
          getReferralData();
          showToastMessage(response.message, TOAST_TYPE_OPTIONS.SUCCESS);
          setShowAddUserPopup(false);
          resetErrorAndValue();
          setShowAddUserPopup(false);
        } else {
          showToastMessage(response.message, TOAST_TYPE_OPTIONS.ERROR);
        }
        setIsLoading(false);
      } else {
        const formattedData = {
          ...formData,
          effectiveDate: formatDate(formData.effectiveDate),

          patientId: activePatient.patientDetails.id,
        };
        const response = await createReferral(formattedData);
        setIsLoading(false);
        if (response.status) {
          getReferralData();
          showToastMessage(response.message, TOAST_TYPE_OPTIONS.SUCCESS);
          setShowAddUserPopup(false);
          resetErrorAndValue();
          setShowAddUserPopup(false);
        } else {
          showToastMessage(response.message, TOAST_TYPE_OPTIONS.ERROR);
        }
      }
    }
    if (editReferralDetails) {
      resetEditData();
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const authListOption = await getOptionsForDropdown("authStatus");
      setAuthStatus(authListOption);
      const referralStatusOption = await getOptionsForDropdown(
        "referralStatus"
      );
      setReferralStatus(referralStatusOption);
    };
    fetchData();
  }, []);
  interface ReferralStatus {
    label: string;
    value: string;
  }
  const checkForEditFlag = async () => {
    if (activePatient.patientDetails.id === "") {
      showToastMessage(
        "Patient is not been selected.",
        TOAST_TYPE_OPTIONS.ERROR
      );
    }
    const statusValue =
      (referralStatus.find(
        (status: ReferralStatus) => status.label === editReferralDetails.status
      ) as ReferralStatus | undefined)?.value ?? "";
    setFormData({
      referral: editReferralDetails.referral,
      referredTo: editReferralDetails.referredTo,
      referredBy: editReferralDetails.referredBy,
      effectiveDate: formatDate(
        editReferralDetails.effectiveDate,
        "YYYY-MM-DD"
      ),
      icd10: editReferralDetails.icd10,
      status: statusValue,
      procedureCode: editReferralDetails.procedureCode,
      authStatus: editReferralDetails.authStatus,
      authorizationId: editReferralDetails.authorizationId,
      maxAuthorization: editReferralDetails.maxAuthorization,
      organization: editReferralDetails.organization,
      reason: editReferralDetails.reason,
      notes: editReferralDetails.notes,

      insCarrier: editReferralDetails.insCarrier,
      referralType,
      patientId: activePatient.patientDetails.id,
    });
    if (referralType === "IN") {
      const getReferredToDetails = await getReferredToInfoService(
        editReferralDetails?.id
      );
      if (getReferredToDetails.status) {
        setDisplayValueForReferredTo(
          `${getReferredToDetails.data.referralProvider} `
        );
      }
    } else if (referralType === "OUT") {
      const getReferredByDetails = await getReferredByInfoService(
        editReferralDetails?.id
      );
      if (getReferredByDetails.status) {
        setDisplayValueForReferredBy(
          `${getReferredByDetails.data.referralProvider}`
        );
      }
    }

    const getProcedureCodeDetails = await getProcedureCodeInfoService(
      editReferralDetails?.procedureCode
    );
    if (getProcedureCodeDetails.status) {
      setDisplayValueForProcedureCode(
        `${getProcedureCodeDetails.data.code} ${getProcedureCodeDetails.data.shortDescription}`
      );
    }
    const getIcd10Details = await getIcd10InfoService(
      editReferralDetails?.icd10
    );
    if (getIcd10Details.status) {
      setDisplayValueForIcd10(
        `${getIcd10Details.data.code} ${getIcd10Details.data.shortDescription}`
      );
    }
  };

  useEffect(() => {
    if (editReferralDetails && editMode) {
      resetErrorAndValue();
      setShowAddUserPopup(true);
      checkForEditFlag();
    }
  }, [editReferralDetails]);

  const addNewUserButtonAction = () => {
    setShowAddUserPopup(!showAddUserPopup);
    setEditMode(false);
    setFormData({
      referral: "",
      referredTo: "",
      referredBy: "",
      effectiveDate: "",
      icd10: "",
      authorizationId: "",
      organization: "",
      maxAuthorization: "",
      reason: "",
      notes: "",
      status: "",
      procedureCode: "",
      authStatus: "",
      insCarrier: "",
      referralType,
      patientId: "",
    });
    setErrors({
      referral: "",
      referredTo: "",
      referredBy: "",
      effectiveDate: "",
      authorizationId: "",
      organization: "",
      maxAuthorization: "",
      reason: "",
      notes: "",
      insCarrier: "",
      referralType,
      patientId: "",
    });
  };

  const closeAction = () => {
    if (editReferralDetails) {
      resetEditData();
      resetErrorAndValue();
    }
  };

  return (
    <ModalComponent
      buttonShow={buttonTitle}
      buttonLeft={editMode ? null : "Clear"}
      buttonRight={editMode ? "Update" : "Save"}
      title={editMode ? "Update Referral" : "Add New Referral"}
      onSubmit={handleSubmit}
      className="text-end"
      size="lg"
      showAddUserPopup={showAddUserPopup}
      setShowAddUserPopup={addNewUserButtonAction}
      submitButtonLoadingStatus={isLoading}
      closeAction={closeAction}
    >
      <form className="row g-3">
        <div className="col-lg-6 col-sm-6">
          <Input
            type="text"
            id="referral"
            name="referral"
            value={formData.referral}
            onChange={handleChange}
            placeholder="Referral"
            className="form-control form-control-sm"
            label={validationRules.referral.displayName}
            error={errors.referral}
          />
        </div>
        <div className="col-lg-6 col-sm-6">
          <InputDropdown
            options={referralStatus}
            className="form-select form-select-sm"
            onSelect={handleChange}
            name="status"
            value={formData.status}
            label={validationRules.status.displayName}
          />
        </div>
        {referralType === "IN" && (
          <>
            <div className="col-lg-6 col-sm-6">
              <SearchDropdown
                placeholder="Referred To"
                onSelect={(option) => {
                  setFormData({
                    ...formData,
                    referredTo: option.value,
                  });
                }}
                searchCallback={updateReferredToList}
                searchField
                options={referredTo}
                displayValue={displayValueForReferredTo}
                label={validationRules.referredTo.displayName}
                required={validationRules.referredTo.required}
                error={errors.referredTo}
              />
            </div>
            <div className="col-lg-6 col-sm-6">
              <Input
                type="text"
                value={formData.referredBy}
                name="referredBy"
                onChange={handleChange}
                className="form-control form-control-sm"
                id="referredBy"
                placeholder="Referred By"
                error={errors.referredBy}
                label={validationRules.referredBy.displayName}
                required={validationRules.referredBy.required}
              />
            </div>
          </>
        )}
        {referralType === "OUT" && (
          <>
            <div className="col-lg-6 col-sm-6">
              <Input
                type="text"
                value={formData.referredTo}
                name="referredTo"
                onChange={handleChange}
                className="form-control form-control-sm"
                id="referredTo"
                placeholder="Referred To"
                label={validationRules.referredTo.displayName}
                required={validationRules.referredTo.required}
                error={errors.referredTo}
              />
            </div>
            <div className="col-lg-6 col-sm-6">
              <SearchDropdown
                placeholder="Referred By"
                onSelect={(option) => {
                  setFormData({
                    ...formData,
                    referredBy: option.value,
                  });
                }}
                searchCallback={updateReferredByList}
                searchField
                options={referredBy}
                displayValue={displayValueForReferredBy}
                label={validationRules.referredBy.displayName}
                required={validationRules.referredBy.required}
                error={errors.referredBy}
              />
            </div>
          </>
        )}
        <div className="col-lg-6 col-sm-6">
          <Input
            type="date"
            id="effectiveDate"
            name="effectiveDate"
            value={formData.effectiveDate}
            onChange={handleChange}
            className="form-control form-control-sm"
            min={new Date().toISOString().split("T")[0]}
            error={errors.effectiveDate}
            label={validationRules.effectiveDate.displayName}
            required={validationRules.effectiveDate.required}
          />
        </div>
        <div className="col-lg-6 col-sm-6">
          <SearchDropdown
            placeholder="ICD 10"
            onSelect={(option) => {
              setFormData({
                ...formData,
                icd10: option.value,
              });
            }}
            searchCallback={updateReferredIcd10List}
            searchField
            options={icd10}
            label={validationRules.icd10.displayName}
            displayValue={displayValueForIcd10}
          />
        </div>
        <div className="col-lg-6 col-sm-6">
          <SearchDropdown
            placeholder="Procedure Codes"
            onSelect={(option) => {
              setFormData({
                ...formData,
                procedureCode: option.value,
              });
            }}
            searchCallback={updateReferredProcCodeList}
            searchField
            options={procCode}
            displayValue={displayValueForProcedureCode}
            label={validationRules.procedureCode.displayName}
          />
        </div>
        <div className="col-lg-6 col-sm-6">
          <InputDropdown
            options={authStatus}
            className="form-select form-select-sm"
            onSelect={handleChange}
            name="authStatus"
            value={formData.authStatus}
            label={validationRules.authStatus.displayName}
          />
        </div>
        {referralType === "IN" && (
          <>
            <div className="col-lg-3 col-sm-3">
              <Input
                type="number"
                max="100"
                value={formData.maxAuthorization}
                name="maxAuthorization"
                onChange={handleChange}
                className="form-control form-control-sm"
                id="maxAuthorization"
                placeholder="Max Authorization"
                error={errors.maxAuthorization}
                label={validationRules.maxAuthorization.displayName}
              />
            </div>
            <div className="col-lg-3 col-sm-3">
              <Input
                type="number"
                max="100"
                value={formData.authorizationId}
                name="authorizationId"
                onChange={handleChange}
                className="form-control form-control-sm"
                id="authorizationId"
                placeholder="authorizationId"
                error={errors.authorizationId}
                label={validationRules.authorizationId.displayName}
              />
            </div>
            <div className="col-lg-3 col-sm-3">
              <Input
                type="text"
                onChange={handleChange}
                name="insCarrier"
                value={formData.insCarrier}
                className="form-control form-control-sm"
                id="insCarrier"
                placeholder="INS Carrier"
                error={errors.insCarrier}
                label={validationRules.insCarrier.displayName}
              />
            </div>
          </>
        )}
        {referralType === "OUT" && (
          <>
            <div className="col-lg-4 col-sm-4">
              <Input
                type="text"
                max="100"
                value={formData.authorizationId}
                name="authorizationId"
                onChange={handleChange}
                className="form-control form-control-sm"
                id="authorizationId"
                placeholder="authorizationId"
                error={errors.authorizationId}
                label={validationRules.authorizationId.displayName}
              />
            </div>
            <div className="col-lg-4 col-sm-4">
              <Input
                type="text"
                onChange={handleChange}
                name="insCarrier"
                value={formData.insCarrier}
                className="form-control form-control-sm"
                id="insCarrier"
                placeholder="INS Carrier"
                error={errors.insCarrier}
                label={validationRules.insCarrier.displayName}
              />
            </div>
          </>
        )}
        <div className="col-lg-3 col-sm-3">
          <Input
            type="text"
            onChange={handleChange}
            name="organization"
            value={formData.organization}
            className="form-control form-control-sm"
            id="organization"
            placeholder="organization"
            error={errors.organization}
            label={validationRules.organization.displayName}
          />
        </div>
        <div className="col-lg-12 col-sm-12">
          <Textarea
            className="form-control form-control-sm"
            id="reason"
            name="reason"
            placeholder="Enter Reason"
            rows={4}
            value={formData.reason}
            onChange={handleChange}
            error={errors.reason}
            label={validationRules.reason.displayName}
          />
        </div>
        <div className="col-lg-12 col-sm-12">
          <Textarea
            className="form-control form-control-sm"
            id="notes"
            name="notes"
            placeholder="Enter Note"
            rows={4}
            value={formData.notes}
            onChange={handleChange}
            error={errors.notes}
            label={validationRules.notes.displayName}
          />
        </div>
      </form>
    </ModalComponent>
  );
}
