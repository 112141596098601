import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "../../../components/atom/Table/Table";
import { Card } from "../../../components/atom/Cards/Cards";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import { Pagination } from "../../../components/atom/Pagination/Pagination";
import { Button } from "../../../components/atom/Buttons/Button";
import { CreateIRA } from "../../../components/organism/CreateIRA/CreateIRA";
import {
  deleteIRAService,
  getAllIRAServices,
  getIRAByIdService,
} from "../../../services/FormsAndIRA/ira";
import { FullScreenLoader } from "../../../components/atom/FullScreenLoader/FullScreenLoader";
import { IRARecord } from "../../../dataLayer/formsAndIRA/ira";
import { getCarePlanInitialRiskAssessmentService } from "../../../services/carePlan/carePlan";

interface IRAProps {
  displayLabel: string;
  moduleName: string;
}

const defaultHeadersForTable = {
  name: "Name of the Assessment",
  completionDate: "Completion date",
  status: "Status",
  action: "Action",
};
export function IRA({ displayLabel, moduleName }: IRAProps) {
  const [showTable, setShowTable] = useState(true);
  const [fullScreenLoaderStatus, setFullScreenLoaderStatus] = useState(false);
  const activePatient = useSelector((state: any) => state.activePatient);
  const activeCarePlan = useSelector((state: any) => state.activeCarePlan);
  const [IRADataList, setIRADataList] = useState<IRARecord[]>([]);
  const [IRAEditData, setIRAEditData] = useState<IRARecord | undefined>();
  const [isViewMode, setIsViewMode] = useState(false);

  const [paginationData, setPaginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });

  const [headersForTable, setHeadersForTable] = useState<
    Record<string, string>
  >(defaultHeadersForTable);
  const sortingHeaderMap: Record<string, string> = {
    name: "name",
    completionDate: "completionDate",
    status: "status",
  };

  const getAllIRADataForTable = async (newPaginationData?: any) => {
    try {
      const paginationDataAsParameter = newPaginationData || paginationData;
      const patientId = activePatient.patientDetails.id;
      if (patientId !== "") {
        setFullScreenLoaderStatus(true);
        let iraListResponse: any;
        if (moduleName === "CarePlan") {
          iraListResponse = await getCarePlanInitialRiskAssessmentService({
            paginationDataAsParameter,
            patientId,
            carePlanId: activeCarePlan.carePlanDetails.carePlanId || "",
          });
        } else {
          iraListResponse = await getAllIRAServices({
            paginationDataAsParameter,
            patientId,
          });
        }

        setFullScreenLoaderStatus(false);
        if (iraListResponse.isSuccess) {
          setPaginationData({
            ...paginationData,
            pageNo: iraListResponse.pageNo,
            totalPages: iraListResponse.totalPages,
            totalElements: iraListResponse.totalElements,
            pageSize: iraListResponse.pageSize,
          });

          setIRADataList(iraListResponse.dataList || []);
        } else {
          setIRADataList([]);
        }
      }
    } catch (error) {
      showToastMessage("Error fetching all ira:", TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  const deleteIRARecord = async (rowData: any) => {
    setFullScreenLoaderStatus(true);
    const deleteResponse = await deleteIRAService(rowData.id);
    setFullScreenLoaderStatus(false);
    if (deleteResponse.status) {
      showToastMessage(deleteResponse.message, TOAST_TYPE_OPTIONS.SUCCESS);
      getAllIRADataForTable();
    } else {
      showToastMessage(deleteResponse.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  const enableViewAction = async (rowData: any) => {
    setFullScreenLoaderStatus(true);
    const getResponse = await getIRAByIdService(rowData.id);
    setFullScreenLoaderStatus(false);
    if (getResponse?.status) {
      setIRAEditData(getResponse?.data);
      setIsViewMode(true);
      setShowTable(false);
    } else {
      setIRAEditData(undefined);
      setShowTable(true);
      setIsViewMode(false);
    }
  };
  const editRowAction = async (rowData: any) => {
    setFullScreenLoaderStatus(true);
    const getResponse = await getIRAByIdService(rowData.id);
    setFullScreenLoaderStatus(false);
    if (getResponse?.status) {
      setIRAEditData(getResponse?.data);
      setShowTable(false);
    } else {
      setIRAEditData(undefined);
      setShowTable(true);
    }
  };

  useEffect(() => {
    getAllIRADataForTable();
  }, []);

  useEffect(() => {
    if (!activePatient.patientDetails.id) {
      setIRADataList([]);
    }
  }, [activePatient]);

  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    getAllIRADataForTable({ ...paginationData, pageNo, pageSize });
  };

  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    getAllIRADataForTable({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };

  const addIRA = () => {
    setShowTable(false);
    setIRAEditData(undefined);
    setIsViewMode(false);
  };

  const isActionDisabled = (rowData: any) => {
    if (rowData?.completionDate) {
      return true;
    }
    return false;
  };

  const handleShowTable = () => {
    getAllIRADataForTable();
    setShowTable(true);
    setIRAEditData(undefined);
    setIsViewMode(false);
  };

  useEffect(() => {
    if (moduleName === "CarePlan") {
      setHeadersForTable((prevHeaders) => {
        const { action, ...rest } = prevHeaders;
        return rest;
      });
    } else {
      setHeadersForTable(defaultHeadersForTable);
    }
  }, [moduleName]);

  return (
    <div>
      {fullScreenLoaderStatus && (
        <FullScreenLoader show={fullScreenLoaderStatus} />
      )}
      <Card>
        <div className="card-title d-flex justify-content-between align-items-center">
          <h5>{displayLabel}</h5>
          <div className="d-flex text-end">
            {showTable ? (
              <div>
                {moduleName === "IRA" && (
                  <Button
                    variant="primary"
                    className="primary btn btn-primary d-flex align-items-center  py-1 float-end btn-sm px-5 ms-3"
                    onClick={addIRA}
                  >
                    <i className="fa fa-plus me-2 fs-5" /> Add IRA
                  </Button>
                )}
              </div>
            ) : (
              <Button
                variant="primary"
                className="primary btn btn-primary d-flex align-items-center  py-1 float-end btn-sm px-5 ms-3"
                onClick={() => handleShowTable()}
                label="Back"
              />
            )}
          </div>
        </div>

        {showTable ? (
          <div className="table-responsive care-manager-table">
            <Table
              className="table table-bordered table-striped table-hover"
              data={IRADataList}
              headers={headersForTable}
              enableViewAction={enableViewAction}
              onRowClick={enableViewAction}
              enableDeleteAction={
                moduleName === "IRA" ? deleteIRARecord : undefined
              }
              enableEditAction={
                moduleName === "IRA" ? editRowAction : undefined
              }
              isDeleteDisabled={isActionDisabled}
              isEditDisabled={isActionDisabled}
              sortingHeaderMap={sortingHeaderMap}
              sortingFunction={sortingFunction}
              hyperlinkField={moduleName === "CarePlan" ? "name" : ""}
            />
            {paginationData.totalPages >= 0 && (
              <Pagination
                currentPage={paginationData.pageNo}
                totalPages={paginationData.totalPages}
                pageSize={paginationData.pageSize}
                onPageChange={(page, pageSize) => {
                  updatePaginationData(page, pageSize);
                }}
              />
            )}
          </div>
        ) : (
          <CreateIRA
            handleShowTable={() => handleShowTable()}
            IRAEditData={IRAEditData}
            isViewMode={isViewMode}
          />
        )}
      </Card>
    </div>
  );
}
