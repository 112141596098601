/* eslint-disable complexity */
import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "../../../components/atom/Images/Image";
import { Button } from "../../../components/atom/Buttons/Button";
import { InputDropdown } from "../../../components/atom/InputDropDown/InputDropDown";
import { Input } from "../../../components/atom/Input/Input";
import {
  getOptionsForDropdown,
  getPatientsCaseManagerList,
} from "../../../services/dropDownMenuOptions/dropDownMenuOptions";
import "./PatientDetails.css";
import {
  createPatientBasicInfo,
  getPatientDetailsFromId,
} from "../../../services/patients/patients";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import {
  CASE_MANAGER_ROLE_NAME,
  FILE_SIZE_MAX,
  TOAST_TYPE_OPTIONS,
} from "../../../constants/variables";
import { setActivePatient } from "../../../redux/activePatient/activePatientSlice";
import { formatDate } from "../../../services/utils/formatDate";
import { FullScreenLoader } from "../../../components/atom/FullScreenLoader/FullScreenLoader";
import { validateName } from "../../../services/validation/name";
import { validateOnlyAlphabets } from "../../../services/validation/onlyAlphabets";
import { calculateAge } from "../../../services/utils/calculateAge";
import { isUserHaveOnlyViewPermission } from "../../../services/users/users";
import { allKeysEmpty } from "../../../services/utils/allKeyEmpty";
import Logger from "../../../utils/Logger";
import { isFutureDate } from "../../../services/utils/validateDataAsPerRules";

interface PatientDetailsProps {
  nextTab: () => void;
}

export function PatientDetails({ nextTab }: PatientDetailsProps) {
  const dispatch = useDispatch();
  const activeUserDetails = useSelector((state: any) => state.activePatient);
  const loginUserDetails = useSelector((state: any) => state.userDetails);
  const [userTitle, setUserTitle] = useState([]);
  const [sex, setSet] = useState([]);
  const [patientAge, setPatientAge] = useState("");
  const [genderIdentity, setGenderIdentity] = useState([]);
  const [orientation, setOrientation] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [partyName, setPartyName] = useState([]);
  const [preferredLanguage, setPreferredLanguage] = useState([]);
  const [caseManager, setCaseManager] = useState([]);
  const [isUpdateFlag, setIsUpdateFlag] = useState(false);
  const [showFullScreenLoaderStatus, setShowFullScreenLoaderStatus] = useState(
    true
  );
  const [loadingSaveButtonStatus, setLoadingSaveButtonStatus] = useState(false);
  const [viewPermissionOnlyFlag, setViewPermissionOnlyFlag] = useState(false);
  const [patientFormData, setPatientFormData] = useState({
    userTitle: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    sex: "",
    genderIdentity: "",
    orientation: "",
    maritalStatus: "",
    chartNumber: "",
    partyName: "",
    occupation: "",
    caseManager: "",
    sexualOrientation: "",
    preferredLanguage: "",
    patientId: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    occupation: "",
    dob: "",
    sex: "",
    genderIdentity: "",
    sexualOrientation: "",
    maritalStatus: "",
    preferredLanguage: "",
    partyName: "",
    caseManager: "",
  });
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [fileData, setFileData] = useState<File | null | undefined>(null);

  const handleChange = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    if (name === "firstName" || name === "lastName") {
      const validation = validateName(value, name);
      if (validation.status) {
        setErrors({ ...errors, [name]: "" });
      } else {
        setErrors({
          ...errors,
          [name]: validation.message,
        });
      }
    }
    if (name === "middleName" || name === "occupation") {
      const validation = validateOnlyAlphabets(value, name, false, 50);
      if (validation.status) {
        setErrors({ ...errors, [name]: "" });
      } else {
        setErrors({
          ...errors,
          [name]: validation.message,
        });
      }
    }
    if (name === "sex") {
      if (!value) {
        setErrors({ ...errors, [name]: "Please select a sex" });
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    }
    if (name === "dob") {
      const age = calculateAge(value);
      setPatientFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      setPatientAge(age);
      const isFuture = isFutureDate(new Date(value));
      if (!value) {
        setErrors({ ...errors, [name]: "Please select a date" });
      } else if (isFuture) {
        setErrors({ ...errors, [name]: "DOB does not allow future dates" });
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    }
    if (name === "partyName") {
      if (!value) {
        setErrors({ ...errors, [name]: "Please select a partyName" });
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    }
    if (name === "genderIdentity") {
      if (!value) {
        setErrors({ ...errors, [name]: "Please select a genderIdentity" });
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    }
    if (name === "sexualOrientation") {
      if (!value) {
        setErrors({ ...errors, [name]: "Please select a sexualOrientation" });
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    }
    if (name === "maritalStatus") {
      if (!value) {
        setErrors({ ...errors, [name]: "Please select a maritalStatus" });
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    }
    if (name === "preferredLanguage") {
      if (!value) {
        setErrors({ ...errors, [name]: "Please select a preferredLanguage" });
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    }
    if (name === "caseManager") {
      if (!value) {
        setErrors({ ...errors, [name]: "Please select a caseManager" });
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    }

    setPatientFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleOptionSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange(event);
  };

  const handleNext = async () => {
    const patientDataToSave = patientFormData;
    const newErrors = errors;

    if (patientDataToSave.firstName === "") {
      newErrors.firstName = "First Name is required";
    }
    if (patientDataToSave.lastName === "") {
      newErrors.lastName = "Last Name is required";
    }
    if (
      patientDataToSave.dob === "NaN-NaN-NaN" ||
      patientDataToSave.dob === ""
    ) {
      newErrors.dob = "Date is required";
    }
    if (patientDataToSave.sex === "") {
      newErrors.sex = "Sex is required";
    }
    if (patientDataToSave.genderIdentity === "") {
      newErrors.genderIdentity = "Gender Identity is required";
    }
    if (patientDataToSave.sexualOrientation === "") {
      newErrors.sexualOrientation = "Sexual Orientation is required";
    }
    if (patientDataToSave.maritalStatus === "") {
      newErrors.maritalStatus = "Marital Status is required";
    }
    if (patientDataToSave.preferredLanguage === "") {
      newErrors.preferredLanguage = "Preferred Language is required";
    }
    if (patientDataToSave.partyName === "") {
      newErrors.partyName = "Party Name is required";
    }
    if (patientDataToSave.caseManager === "") {
      newErrors.caseManager = "Case Manager is required";
    }

    setErrors({ ...newErrors });

    const isError = allKeysEmpty(errors);

    if (!isError) {
      showToastMessage(
        "Please fill all mandatory fields",
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }
    if (isUpdateFlag) {
      patientDataToSave.patientId = patientFormData.chartNumber;
    }
    setLoadingSaveButtonStatus(true);
    Logger.logInfo("Patient Details for add/update", { data: patientFormData });
    const createPatient = await createPatientBasicInfo({
      profileImage: fileData,
      ...patientFormData,
    });
    setLoadingSaveButtonStatus(false);
    if (createPatient?.status) {
      Logger.logInfo("Patient Details added", { data: createPatient });
      showToastMessage(createPatient?.message, TOAST_TYPE_OPTIONS.SUCCESS);
      if (createPatient?.data?.id) {
        const patientInfo = await getPatientDetailsFromId(
          createPatient?.data?.id
        );

        const {
          contactInformation,
          providerInfo,
          raceEthnicityDTO,
          ...userDetails
        } = patientInfo;
        dispatch(setActivePatient(userDetails));
      }

      nextTab();
    } else {
      Logger.logError("failed to add/update", { data: createPatient });
      showToastMessage(createPatient?.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  const handleDelete = () => {
    setPatientFormData({
      userTitle: "",
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      sex: "",
      genderIdentity: "",
      orientation: "",
      maritalStatus: "",
      chartNumber: "",
      partyName: "",
      occupation: "",
      caseManager: "",
      sexualOrientation: "",
      preferredLanguage: "",
      patientId: "",
    });
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setFileData(file);
    if (file) {
      if (file.size > FILE_SIZE_MAX) {
        showToastMessage(
          "File size exceeds 10 MB. Please select a smaller file.",
          TOAST_TYPE_OPTIONS.ERROR
        );
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setSelectedImage(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const checkForEditFlag = () => {
    const { patientDetails } = activeUserDetails;
    if (patientDetails.id !== "") {
      setPatientFormData({
        ...patientFormData,
        userTitle: patientDetails.title,
        firstName: patientDetails.firstName,
        middleName: patientDetails.middleName,
        lastName: patientDetails.lastName,
        dob: formatDate(patientDetails.dob, "YYYY-MM-DD"),
        sex: patientDetails.sex,
        genderIdentity: patientDetails.genderIdentity,
        maritalStatus: patientDetails.maritalStatus,
        chartNumber: patientDetails.id,
        partyName: patientDetails.responsiblePartyName,
        occupation: patientDetails.occupation,
        caseManager: patientDetails.caseManagerId,
        sexualOrientation: patientDetails.sexualOrientation,
        preferredLanguage: patientDetails.preferredLanguage,
      });
      const age = calculateAge(patientDetails.dob);
      setPatientAge(age);
      setSelectedImage(patientDetails.profileImage);
      setIsUpdateFlag(true);
    } else {
      setIsUpdateFlag(false);

      if (loginUserDetails.userPermission.roleName === CASE_MANAGER_ROLE_NAME) {
        setPatientFormData({
          ...patientFormData,
          caseManager: loginUserDetails.id,
        });
      }

      const age = calculateAge(new Date().toISOString().split("T")[0]);
      setPatientAge(age);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setShowFullScreenLoaderStatus(true);
        const titleMaster = await getOptionsForDropdown("title");
        setUserTitle(titleMaster);

        const sexResp = await getOptionsForDropdown("sex");
        setSet(sexResp);

        const genderIdentityResp = await getOptionsForDropdown(
          "genderIdentity"
        );
        setGenderIdentity(genderIdentityResp);

        const orientationResp = await getOptionsForDropdown(
          "sexualOrientation"
        );
        setOrientation(orientationResp);

        const maritalStatusResp = await getOptionsForDropdown("maritalStatus");
        setMaritalStatus(maritalStatusResp);

        const partyNameResp = await getOptionsForDropdown(
          "ResponsiblePartyName"
        );
        setPartyName(partyNameResp);

        const preferredLanguageResp = await getOptionsForDropdown(
          "preferredLanguage"
        );
        setPreferredLanguage(preferredLanguageResp);

        const caseManagerList = await getPatientsCaseManagerList();
        setCaseManager(caseManagerList);
        checkForEditFlag();
        setShowFullScreenLoaderStatus(false);
        if (loginUserDetails) {
          const isOnlyViewPermission = isUserHaveOnlyViewPermission({
            permissions: loginUserDetails.userPermission,
            module: "PATIENT",
          });

          if (isOnlyViewPermission) {
            setViewPermissionOnlyFlag(true);
          }
        }
      } catch (error) {
        console.error("Error fetching title options:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div
      className="tab-content col-lg-12 col-sm-12 form-container"
      id="v-pills-tabContent"
    >
      <div
        className="tab-pane fade show active"
        id="v-pills-patient"
        role="tabpanel"
        aria-labelledby="v-pills-patient-tab"
      >
        <h6 className="border-bottom border-top p-3">Patient Details</h6>
        <form className="row g-4 p-3">
          <div className="col-lg-2 col-sm-6 d-flex flex-column align-items-center justify-content-center">
            {selectedImage && (
              <Image
                src={selectedImage}
                alt="Selected Image"
                width={40}
                height={40}
                className="rounded-circle"
              />
            )}
            <label
              htmlFor="inputFile"
              className="btn btn-sm btn-outline-primary align-items-center d-flex px-1 mt-0"
            >
              {" "}
              <i className="mdi mdi-camera me-0.5" /> Upload
            </label>
            <input
              type="file"
              accept="image/*"
              id="inputFile"
              name="inputFile"
              className="d-none"
              onChange={handleFileUpload}
              disabled={viewPermissionOnlyFlag}
            />
          </div>
          <div className="col-lg-1 col-sm-6 title-col  ">
            <InputDropdown
              label="Title"
              options={userTitle}
              className="form-select form-select-sm"
              onSelect={handleOptionSelect}
              name="userTitle"
              value={patientFormData.userTitle}
              disabled={viewPermissionOnlyFlag}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Input
              label="First Name"
              required
              type="text"
              id="firstName"
              name="firstName"
              value={patientFormData.firstName}
              onChange={handleChange}
              placeholder="First Name"
              className="form-control form-control-sm"
              disabled={viewPermissionOnlyFlag}
              error={errors.firstName}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Input
              label="Middle Name"
              type="text"
              id="firstName"
              name="middleName"
              value={patientFormData.middleName}
              onChange={handleChange}
              placeholder="Middle Name"
              className="form-control form-control-sm"
              disabled={viewPermissionOnlyFlag}
              error={errors.middleName}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Input
              label="Last Name"
              required
              type="text"
              id="lastName"
              name="lastName"
              value={patientFormData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              className="form-control form-control-sm"
              disabled={viewPermissionOnlyFlag}
              error={errors.lastName}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Input
              label="Date of Birth"
              required
              type="Date"
              id="dob"
              name="dob"
              value={patientFormData.dob}
              onChange={handleChange}
              placeholder="DOB"
              className="form-control form-control-sm"
              max={new Date().toISOString().split("T")[0]}
              disabled={viewPermissionOnlyFlag}
              error={errors.dob}
            />
          </div>
          <div className="col-lg-2 col-sm-6">
            <Input
              label="Age"
              type="text"
              id="age"
              name="age"
              value={patientAge}
              onChange={handleChange}
              placeholder="age"
              className="form-control form-control-sm bg-light"
              disabled
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <InputDropdown
              label="Sex"
              required
              options={sex}
              className="form-select form-select-sm"
              onSelect={handleChange}
              name="sex"
              value={patientFormData.sex}
              disabled={viewPermissionOnlyFlag}
              error={errors.sex}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <InputDropdown
              label="Gender Identity"
              required
              options={genderIdentity}
              className="form-select form-select-sm"
              onSelect={handleChange}
              name="genderIdentity"
              value={patientFormData.genderIdentity}
              disabled={viewPermissionOnlyFlag}
              error={errors.genderIdentity}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <InputDropdown
              label="Sexual Orientation"
              required
              options={orientation}
              className="form-select form-select-sm"
              onSelect={handleChange}
              name="sexualOrientation"
              value={patientFormData.sexualOrientation}
              disabled={viewPermissionOnlyFlag}
              error={errors.sexualOrientation}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <InputDropdown
              label="Marital Status"
              required
              options={maritalStatus}
              className="form-select form-select-sm"
              onSelect={handleChange}
              name="maritalStatus"
              value={patientFormData.maritalStatus}
              disabled={viewPermissionOnlyFlag}
              error={errors.maritalStatus}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Input
              label="Chart Number"
              type="text"
              id="chartNumber"
              name="chartNumber"
              value={patientFormData.chartNumber}
              onChange={handleChange}
              placeholder="ChartNumber"
              className="form-control form-control-sm bg-light"
              disabled
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <InputDropdown
              label="Preferred Language"
              required
              options={preferredLanguage}
              className="form-select form-select-sm"
              onSelect={handleChange}
              name="preferredLanguage"
              value={patientFormData.preferredLanguage}
              disabled={viewPermissionOnlyFlag}
              error={errors.preferredLanguage}
            />
          </div>

          <div className="col-lg-4 col-sm-6">
            <InputDropdown
              label="Responsible Party Name"
              required
              options={partyName}
              className="form-select form-select-sm"
              onSelect={handleChange}
              name="partyName"
              value={patientFormData.partyName}
              disabled={viewPermissionOnlyFlag}
              error={errors.partyName}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Input
              label="Occupation"
              type="text"
              id="occupation"
              name="occupation"
              value={patientFormData.occupation}
              onChange={handleChange}
              placeholder="Occupation"
              className="form-control form-control-sm"
              disabled={viewPermissionOnlyFlag}
              error={errors.occupation}
            />
          </div>
          <hr />
          <div className="col-lg-6 col-sm-6 mt-2">
            <InputDropdown
              label="Case Manager"
              required
              options={caseManager}
              className="form-select form-select-sm"
              onSelect={handleChange}
              name="caseManager"
              value={patientFormData.caseManager}
              disabled={viewPermissionOnlyFlag}
              error={errors.caseManager}
            />
          </div>
          <div className="col-12 d-flex justify-content-between mt-5">
            <div>
              {!isUpdateFlag && (
                <Button
                  onClick={handleDelete}
                  variant="outlined"
                  className="btn btn-danger btn-sm"
                  label="Clear"
                  disabled={viewPermissionOnlyFlag}
                />
              )}
            </div>

            <Button
              onClick={handleNext}
              variant="outlined"
              className="btn btn-primary btn-sm px-4"
              label="Save & Continue"
              disabled={viewPermissionOnlyFlag}
              isLoading={loadingSaveButtonStatus}
            />
          </div>
        </form>
      </div>
      <FullScreenLoader show={showFullScreenLoaderStatus} />
    </div>
  );
}
