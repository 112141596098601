import React, { useState } from "react";
import { Nav, TabContent, Tab } from "react-bootstrap";
import "./HorizontalTab.css";

interface HorizontalTab {
  key: string;
  title: string;
  content: React.ReactNode;
}

export interface HorizontalTabsProps {
  tabs: HorizontalTab[];
}

export function HorizontalTabs({ tabs }: HorizontalTabsProps) {
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  const handleTabClick = (tabKey: string) => {
    setActiveTab(tabKey);
  };

  return (
    <div>
      <Nav variant="tabs" className="tab-nav">
        {tabs.map((tab) => (
          <Nav.Item
            key={tab.key}
            className={`tab-item ${activeTab === tab.key ? "active" : ""}`}
          >
            <Nav.Link
              active={activeTab === tab.key}
              onClick={() => handleTabClick(tab.key)}
              className={activeTab === tab.key ? "active-tab-link" : ""}
            >
              {tab.title}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>

      <TabContent>
        {tabs.map((tab) => (
          <Tab.Pane
            mountOnEnter
            key={tab.key}
            eventKey={tab.key}
            active={activeTab === tab.key}
            className={activeTab === tab.key ? "d-block" : "d-none"}
          >
            {tab.content}
          </Tab.Pane>
        ))}
      </TabContent>
    </div>
  );
}
