import Logger from "../../utils/Logger";
import {
  createTimelineForPatientAPI,
  getTimelineDataForEventAPI,
  getTimelineDataForGraph,
  timelineListForPatient,
  updateTimelineForPatientAPI,
} from "../../dataLayer/timeline/timeline";
import { formatDate } from "../utils/formatDate";

export async function createTimelineForPatient({
  formData,
  patientId,
}: {
  formData: any;
  patientId: string;
}): Promise<any> {
  try {
    const requestData = {
      patientId,
      category: formData.values.category,
      categoryValue: formData.values.categoryValue,
      evidence: formData.values.evidence,
      eventDetails: formData.values.event,
      eventDate: formatDate(formData.values.date),
    };
    Logger.logData("Create Timeline Data request Data", { data: requestData });
    const createTimelineData = await createTimelineForPatientAPI(requestData);
    Logger.logData("Create Timeline Data Response Data", {
      data: createTimelineData,
    });
    return createTimelineData;
  } catch (error: any) {
    Logger.logError("Error creating timeline.", { error });
    return {
      status: false,
      code: 500,
      message: "Error creating timeline.",
      details: error.message,
    };
  }
}

export async function updateTimelineForPatient({
  formData,
  eventId,
}: {
  formData: any;
  eventId: string;
}): Promise<any> {
  try {
    Logger.logData("Updating Timeline Data request Data", { data: formData });
    const requestData = {
      category: formData.category,
      categoryValue: formData.categoryValue,
      evidence: formData.evidence,
      eventDetails: formData.event,
      eventDate: formatDate(formData.date),
      patientId: formData.patientId,
    };

    const updateTimelineData = await updateTimelineForPatientAPI({
      formData: requestData,
      eventId,
    });
    Logger.logData("Updating Timeline Data Response Data", {
      data: updateTimelineData,
    });
    return updateTimelineData;
  } catch (error: any) {
    Logger.logError("Error updating timeline.", { error });
    return {
      status: false,
      code: 500,
      message: "Error updating timeline.",
      details: error.message,
    };
  }
}

export async function getTimelineForPatient({
  paginationDataAsParameter,
  patientId,
  filterCategoryId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
  filterCategoryId: string;
}): Promise<any> {
  try {
    Logger.logData("Fetching Timeline Data request Data", {
      data: { paginationDataAsParameter, patientId },
    });
    const getAllTimelineData = await timelineListForPatient({
      paginationDataAsParameter,
      patientId,
      filterCategoryId,
    });
    Logger.logData("Fetching Timeline Data Response Data", {
      data: getAllTimelineData,
    });
    return getAllTimelineData;
  } catch (error: any) {
    Logger.logError("Error fetching all memos.", { error });
    return {
      status: false,
      code: 500,
      message: "Error fetching all memos.",
      details: error.message,
    };
  }
}

export async function getEventDataForTimeline({
  eventId,
}: {
  eventId: string;
}): Promise<any> {
  try {
    Logger.logData("Fetching Timeline Data request Data", { data: eventId });
    const categoryTimelineData = await getTimelineDataForEventAPI({
      eventId,
    });
    Logger.logData("Fetching Timeline Data Response Data", {
      data: categoryTimelineData,
    });
    return categoryTimelineData;
  } catch (error: any) {
    Logger.logError("Error fetching all memos.", { error });
    return {
      status: false,
      code: 500,
      message: "Error fetching all memos.",
      details: error.message,
    };
  }
}
//

export async function getEventDataForTimelineForGraph({
  patientId,
  categoryIdForFilter,
}: {
  patientId: string;
  categoryIdForFilter: string;
}): Promise<any> {
  try {
    if (!patientId) {
      return false;
    }
    Logger.logData("Fetching Timeline Data request Data", { data: patientId });
    const categoryTimelineData = await getTimelineDataForGraph({
      patientId,
      categoryIdForFilter,
    });
    Logger.logData("Fetching Timeline Data Response Data", {
      data: categoryTimelineData,
    });
    return categoryTimelineData;
  } catch (error: any) {
    Logger.logError("Error fetching all Timeline data for graph.", { error });
    return {
      status: false,
      code: 500,
      message: "Error fetching all Timeline data for graph.",
      details: error.message,
    };
  }
}
