import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { HorizontalTabs } from "../../../components/atom/HorizontalTab/HorizontalTab";
import { Organisation } from "./Organisation";
import { Pcp } from "./Pcp";
import { isSuperUser } from "../../../services/users/users";

export function MasterData() {
  const loginUserDetails = useSelector((state: any) => state.userDetails);
  const [tabs, setTabs] = useState([
    {
      key: "1",
      title: "Primary & Specialty Care provider",
      content: <Pcp displayLabel="Primary & Specialty Care provider" />,
    },
  ]);
  const initData = () => {
    let isLoginUserIsSuperUser = false;
    if (loginUserDetails) {
      isLoginUserIsSuperUser = isSuperUser({
        permissions: loginUserDetails.userPermission,
      });
      if (isLoginUserIsSuperUser) {
        const tabsToDisplay = [...tabs];
        const existingTab = tabsToDisplay.find((tab) => tab.key === "2");
        if (!existingTab) {
          tabsToDisplay.push({
            key: "2",
            title: "Organisation",
            content: <Organisation displayLabel="Organisation" />,
          });

          setTabs(tabsToDisplay);
        }
      }
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <div>
      <div>
        <HorizontalTabs tabs={tabs} />
      </div>
    </div>
  );
}
