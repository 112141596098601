import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ModalComponent } from "../../molecule/Modal/ModalComponent";
import { Input } from "../../atom/Input/Input";
import {
  createAppointment,
  updateAppointment,
} from "../../../services/appointments/appointments";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import {
  getSearchForReferralIcd10Values,
  getSearchForReferralProcCodeValues,
} from "../../../services/referrals/searchReferralFilter";
import { SearchDropdown } from "../../atom/SearchDropdown/SearchDropdown";
import { TimeInput } from "../../atom/TImeInput/TimeInput";
import { getOptionsForDropdown } from "../../../services/dropDownMenuOptions/dropDownMenuOptions";
import { formatDate } from "../../../services/utils/formatDate";
import {
  getProcedureCodeInfoService,
  getIcd10InfoService,
} from "../../../services/referrals/referrals";
import {
  ValidationRules,
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../services/utils/validateDataAsPerRules";
import { InputDropdown } from "../../atom/InputDropDown/InputDropDown";
import Logger from "../../../utils/Logger";

export interface CreateNewAppointmentProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  fetchData: () => void;
  editAppointmentData: Record<string, string> | undefined;
}

interface FormValues {
  visit: string;
  appointmentDate: string;
  appointmentTime: string;
  icd10: string;
  cptCode: string;
  status: string;
  reason: string;
  appRescheduleDate: string;
  appRescheduleTime: string;
}

export function CreateNewAppointment({
  show,
  setShow,
  fetchData,
  editAppointmentData,
}: CreateNewAppointmentProps) {
  const activePatient = useSelector((state: any) => state.activePatient);
  const [icd10OptionList, setIcd10OptionList] = useState([]);
  const [editFlag, setEditFlag] = useState(false);
  const [appointmentStatusOptions, setAppointmentStatusOptions] = useState([]);
  const [appointmentReasonOptions, setAppointmentReasonOptions] = useState([]);
  const [displayValueForCptCode, setDisplayValueForCptCode] = useState("");
  const [displayValueForIcd10, setDisplayValueForIcd10] = useState("");
  const [CptCode, setCptCode] = useState([]);
  const [formData, setFormData] = useState<FormValues>({
    visit: "",
    appointmentDate: "",
    appointmentTime: "",
    icd10: "",
    cptCode: "",
    status: "",
    reason: "",
    appRescheduleDate: "",
    appRescheduleTime: "",
  });

  const validationRules: ValidationRules = {
    visit: {
      required: true,
      dataType: "number",
      characterLimit: 10,
      displayName: "Visit",
    },
    reason: {
      required: true,
      dataType: "number",
      characterLimit: 50,
      displayName: "Reason / Appt Type",
    },
    appointmentDate: {
      required: false,
      dataType: "date",
      characterLimit: 100,
      isPastDateAllow: false,
      isFutureDateAllow: true,
      displayName: "Appt Date",
    },
    appointmentTime: {
      required: false,
      dataType: "any",
      displayName: "Appt Time",
    },
    appRescheduleDate: {
      required: false,
      dataType: "date",
      characterLimit: 100,
      isPastDateAllow: false,
      isFutureDateAllow: true,
      displayName: "Reschedule Date",
    },
    appRescheduleTime: {
      required: false,
      dataType: "any",
      displayName: "Reschedule Time",
    },
  };

  const [errors, setErrors] = useState({
    visit: "",
    reason: "",
    appointmentDate: "",
    appRescheduleDate: "",
  });
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    const newErrors = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: name,
      fieldName: name,
      value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: newErrors,
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // todo: Sachin - Can we use the above handle change for date change?
  const handleDateChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { value, name } = event.target;
    const newDate = new Date(value);
    const newErrors = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: name,
      fieldName: name,
      value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: newErrors,
    }));
    setFormData((prevFormData) => {
      const newFormData = {
        ...prevFormData,
        appointmentDate: newDate.toISOString().split("T")[0],
      };
      return newFormData;
    });
  };
  const resetFormValue = () => {
    setDisplayValueForIcd10("");
    setDisplayValueForCptCode("");
    setFormData({
      visit: "",
      appointmentDate: "",
      appointmentTime: "",
      icd10: "",
      cptCode: "",
      status: "",
      reason: "",
      appRescheduleDate: "",
      appRescheduleTime: "",
    });
    setErrors({
      visit: "",
      reason: "",
      appointmentDate: "",
      appRescheduleDate: "",
    });
  };

  const closeAction = () => {
    setShow(false);
    resetFormValue();
    setEditFlag(false);
  };
  const searchForICDValue = async (value: string) => {
    const icd10OptionResponse = await getSearchForReferralIcd10Values(value);
    setIcd10OptionList(icd10OptionResponse);
  };

  const updateCptCodeList = async (value: string) => {
    const CptCodeValues = await getSearchForReferralProcCodeValues(value);
    setCptCode(CptCodeValues);
  };

  const getCptProcedureCode = async (cptCode: string, icd10Code: string) => {
    const getCptCodeDetails = await getProcedureCodeInfoService(cptCode);
    if (getCptCodeDetails.status) {
      setDisplayValueForCptCode(
        `${getCptCodeDetails.data.code} ${getCptCodeDetails.data.shortDescription}`
      );
    }
    const getIcd10Details = await getIcd10InfoService(icd10Code);
    if (getIcd10Details.status) {
      setDisplayValueForIcd10(
        `${getIcd10Details.data.code} ${getIcd10Details.data.shortDescription}`
      );
    }
  };
  const addNewAppointmentButtonAction = () => {
    resetFormValue();
    const patientId = activePatient.patientDetails.id;
    if (patientId === "") {
      showToastMessage("Please select a patient", TOAST_TYPE_OPTIONS.ERROR);
      setShow(false);
      return;
    }

    resetFormValue();
    setShow(true);
  };

  const handleSubmit = async () => {
    const patientId = activePatient.patientDetails.id;
    if (editFlag && editAppointmentData && editAppointmentData.id) {
      const formErrors = validateDataAsPerRules({
        formData,
        rules: validationRules,
      });
      setErrors({
        ...errors,
        ...formErrors,
      });
      if (Object.keys(formErrors).length > 0) {
        showToastMessage(
          "Please fill all the required fields",
          TOAST_TYPE_OPTIONS.ERROR
        );
        return;
      }
      Logger.logInfo("updating appointment", {
        data: { patientId, ...formData },
      });
      const updateUserAppointment = await updateAppointment(
        {
          ...formData,
          patientId,
        },
        editAppointmentData.id
      );
      if (updateUserAppointment.status) {
        showToastMessage(
          updateUserAppointment.message,
          TOAST_TYPE_OPTIONS.SUCCESS
        );
        closeAction();
        fetchData();
        Logger.logInfo("updated appointment", {
          data: { patientId, ...formData },
        });
      } else {
        Logger.logError("update appointment", {
          data: { patientId, ...formData },
        });
        showToastMessage(
          updateUserAppointment.message,
          TOAST_TYPE_OPTIONS.ERROR
        );
      }
    } else {
      const formErrors = validateDataAsPerRules({
        formData,
        rules: validationRules,
      });
      setErrors({
        ...errors,
        ...formErrors,
      });
      if (Object.keys(formErrors).length > 0) {
        showToastMessage(
          "Please fill all the required fields",
          TOAST_TYPE_OPTIONS.ERROR
        );
        return;
      }
      Logger.logInfo("adding appointment", {
        data: { patientId, ...formData },
      });
      const addNewAppointment = await createAppointment({
        ...formData,
        patientId,
      });
      if (addNewAppointment.status) {
        Logger.logInfo("added appointment", {
          data: { patientId, ...formData },
        });
        showToastMessage(addNewAppointment.message, TOAST_TYPE_OPTIONS.SUCCESS);
        closeAction();
        fetchData();
      } else {
        Logger.logError("add appointment", {
          data: { patientId, ...formData },
        });
        showToastMessage(addNewAppointment.message, TOAST_TYPE_OPTIONS.ERROR);
      }
    }
  };

  const initData = async () => {
    const dropdownValueForStatus = await getOptionsForDropdown(
      "AppointmentStatus"
    );
    setAppointmentStatusOptions(dropdownValueForStatus);

    const dropdownValueForReason = await getOptionsForDropdown(
      "AppointmentReason"
    );
    setAppointmentReasonOptions(dropdownValueForReason);
  };
  useEffect(() => {
    initData();
  }, []);
  useEffect(() => {
    if (editAppointmentData) {
      setEditFlag(true);
      setFormData({
        visit: editAppointmentData.visit,
        appointmentDate: formatDate(
          editAppointmentData.appointmentDate,
          "YYYY-MM-DD"
        ),
        appointmentTime: editAppointmentData.appointmentTime,
        icd10: editAppointmentData.icd10,
        cptCode: editAppointmentData.cptCode,
        status: editAppointmentData.status,
        reason: editAppointmentData.reason,
        appRescheduleDate: formatDate(
          editAppointmentData.appRescheduleDate,
          "YYYY-MM-DD"
        ),
        appRescheduleTime: editAppointmentData.appRescheduleTime,
      });
      getCptProcedureCode(
        editAppointmentData.cptCode,
        editAppointmentData.icd10
      );
    }
  }, [editAppointmentData]);

  useEffect(() => {
    initData();
  }, []);
  return (
    <ModalComponent
      showAddUserPopup={show}
      setShowAddUserPopup={addNewAppointmentButtonAction}
      title={editFlag ? "Update Appointment" : "Add Appointment"}
      buttonShow="Add Appointment"
      buttonLeft={editFlag ? null : "Clear"}
      buttonRight={editFlag ? "Update & Continue" : "Save & Continue"}
      onSubmit={handleSubmit}
      className="text-end"
      size="lg"
      closeAction={closeAction}
      disableAddButton={activePatient.patientDetails.id === ""}
    >
      <form className="row g-3">
        <div className="col-lg-4 col-sm-4">
          <Input
            type="text"
            id="visit"
            name="visit"
            value={formData.visit}
            onChange={handleChange}
            placeholder="Text"
            className="form-control form-control-sm"
            label={validationRules.visit.displayName}
            required={validationRules.visit.required}
            error={errors.visit}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            type="date"
            id="appointmentDate"
            name="appointmentDate"
            placeholder="Appt Date"
            value={formData.appointmentDate}
            onChange={handleDateChange}
            className="form-control form-control-sm"
            min={new Date().toISOString().split("T")[0]}
            disabled={editFlag}
            label={validationRules.appointmentDate.displayName}
            required={validationRules.appointmentDate.required}
            error={errors.appointmentDate}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <TimeInput
            format="12-hour"
            id="appointmentTime"
            name="appointmentTime"
            value={formData.appointmentTime}
            onChange={(timeValue) => {
              setFormData({
                ...formData,
                appointmentTime: timeValue,
              });
            }}
            disabled={editFlag}
            label={validationRules.appointmentTime.displayName}
            required={validationRules.appointmentTime.required}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <SearchDropdown
            placeholder="ICD 10"
            label="ICD 10"
            onSelect={(option: any) => {
              setFormData({
                ...formData,
                icd10: option.value,
              });
            }}
            searchCallback={searchForICDValue}
            searchField
            options={icd10OptionList}
            displayValue={displayValueForIcd10}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <SearchDropdown
            placeholder="CPT code"
            onSelect={(option) => {
              setFormData({
                ...formData,
                cptCode: option.value,
              });
            }}
            searchCallback={updateCptCodeList}
            searchField
            options={CptCode}
            displayValue={displayValueForCptCode}
            label="CPT code"
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <InputDropdown
            options={appointmentStatusOptions}
            onSelect={handleChange}
            name="status"
            value={formData.status}
            className="form-select form-select-sm"
            label="Status / Action"
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <InputDropdown
            options={appointmentReasonOptions}
            onSelect={handleChange}
            name="reason"
            value={formData.reason}
            className="form-select form-select-sm"
            label={validationRules.reason.displayName}
            required={validationRules.reason.required}
            error={errors.reason}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            type="date"
            placeholder="Reschedule Date"
            id="appRescheduleDate"
            name="appRescheduleDate"
            value={formData.appRescheduleDate}
            onChange={handleChange}
            className="form-control form-control-sm"
            disabled={!editFlag}
            min={new Date().toISOString().split("T")[0]}
            label={validationRules.appRescheduleDate.displayName}
            required={validationRules.appRescheduleDate.required}
            error={errors.appRescheduleDate}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <TimeInput
            format="12-hour"
            id="appRescheduleTime"
            name="appRescheduleTime"
            value={formData.appRescheduleTime}
            onChange={(timeValue) => {
              setFormData({
                ...formData,
                appRescheduleTime: timeValue,
              });
            }}
            disabled={!editFlag}
            label={validationRules.appRescheduleTime.displayName}
            required={validationRules.appRescheduleTime.required}
          />
        </div>
      </form>
    </ModalComponent>
  );
}
