import React from "react";

export function Footer() {
  return (
    <footer className="footer">
      <div className="container-fluid d-flex justify-content-center">
        <span className="text-dark d-block text-center text-sm-start d-sm-inline-block">
          © MD Tel. All Rights Reserved
        </span>
      </div>
    </footer>
  );
}
