type SortOrder = "asc" | "desc";

export function sortArray<T>(
  array: T[],
  keyToSort: keyof T,
  order: SortOrder = "asc",
): T[] {
  if (!Array.isArray(array)) {
    return array;
  }

  return array.sort((a: T, b: T) => {
    if (a[keyToSort] < b[keyToSort]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[keyToSort] > b[keyToSort]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });
}
