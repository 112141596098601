import { ValidationRules } from "../../../../../services/utils/validateDataAsPerRules";

export const validationRules: ValidationRules = {
  memberId: {
    required: false,
    dataType: "text",
    displayName: "Member Id",
  },
  dob: {
    required: false,
    dataType: "date",
    displayName: "Date of Birth",
  },
  primaryLanguage: {
    required: false,
    dataType: "text",
    displayName: "Primary Language",
  },
  currentRiskLevel: {
    required: false,
    dataType: "text",
    displayName: "Current Risk Level",
  },
  gender: {
    required: false,
    dataType: "text",
    displayName: "Gender",
  },
  homePhone: {
    required: false,
    dataType: "number",
    displayName: "Home Phone",
  },
  mobilePhone: {
    required: false,
    dataType: "number",
    displayName: "Mobile Number",
  },
  primaryPhone: {
    required: false,
    dataType: "number",
    displayName: "Preferred phone",
  },
  emailAddress: {
    required: false,
    dataType: "email",
    displayName: "Email Address",
  },
  mailingAddress: {
    required: false,
    dataType: "email",
    displayName: "Mailing Address",
  },
  bestTimeToReach: {
    required: true,
    dataType: "text",
    displayName: "Best Time To Reach",
  },
  enrollmentStatus: {
    required: true,
    dataType: "text",
    displayName: "Enrollment Status",
  },
  enrollmentDate: {
    required: true,
    dataType: "date",
    displayName: "Enrollment Date",
  },
  dueDate: {
    required: true,
    dataType: "date",
    displayName: "Due Date",
  },
  lastModifiedOn: {
    required: false,
    dataType: "date",
    displayName: "Last Modified Date",
  },
  lastModifiedBy: {
    required: false,
    dataType: "text",
    displayName: "Last Modified By",
  },
  createdOn: {
    required: false,
    dataType: "date",
    displayName: "Created Date",
  },
  createdBy: {
    required: false,
    dataType: "text",
    displayName: "Created By",
  },
  status: {
    required: false,
    dataType: "text",
    displayName: "Status",
  },
  address1: {
    required: false,
    dataType: "text",
    displayName: "Address1",
  },
  address2: {
    required: false,
    dataType: "text",
    displayName: "Address2",
  },
};
