import { useSelector } from "react-redux";
import { ChangeEvent, useEffect, useState } from "react";
import { Input } from "../../../../atom/Input/Input";
import { ModalComponent } from "../../../../molecule/Modal/ModalComponent";
import { showToastMessage } from "../../../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../../../constants/variables";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
  ValidationRules,
} from "../../../../../services/utils/validateDataAsPerRules";
import {
  createVitalsCaseNote,
  getUnitDropDownValues,
  updateVitalsCaseNote,
} from "../../../../../services/caseNotes/caseNotes";
import { InputDropdown } from "../../../../atom/InputDropDown/InputDropDown";
import { allKeysEmpty } from "../../../../../services/utils/allKeyEmpty";

interface UnitOfMeasure {
  uomId: string;
  uom: string;
}

interface UnitDropDownValueOption {
  name: string;
  unitOfMeasures: UnitOfMeasure[];
}
const defaultFormValues = {
  height: "",
  heightUOMId: "",
  weight: "",
  weightUOMId: "",
  bmi: "",
  bp: "",
  bpUOMId: "",
  pulse: "",
  pulseUOMId: "",
  temperature: "",
  temperatureUOMId: "",
  respiratoryRate: "",
  respiratoryRateUOMId: "",
  ozSaturation: "",
  ozSaturationUOMId: "",
  pain: "",
};

const validationRules: ValidationRules = {
  height: {
    required: false,
    dataType: "number",
    displayName: "Height",
    characterLimit: 4,
  },
  weight: {
    required: false,
    dataType: "number",
    displayName: "Weight",
    characterLimit: 4,
  },
  bmi: {
    required: false,
    dataType: "number",
    displayName: "BMI",
    characterLimit: 6,
  },
  bp: {
    required: false,
    dataType: "any",
    displayName: "BP",
    characterLimit: 6,
  },
  pulse: {
    required: false,
    dataType: "number",
    displayName: "Pulse",
    characterLimit: 3,
  },
  temperature: {
    required: false,
    dataType: "numberWithSpecialCharacters",
    displayName: "Temperature",
    characterLimit: 5,
  },
  respiratoryRate: {
    required: false,
    dataType: "number",
    displayName: "Respiratory Rate",
    characterLimit: 6,
  },
  ozSaturation: {
    required: false,
    dataType: "number",
    displayName: "OZ Saturation",
    characterLimit: 4,
  },
  pain: {
    required: false,
    dataType: "number",
    displayName: "Pain",
    characterLimit: 2,
  },
};

interface CreateVitalsProps {
  getVitalsData: () => void;
  editVitalsDetails: any;
  editMode: boolean;
  setEditMode: (value: boolean) => void;
  resetEditData: () => void;
}

export function AddVitals({
  getVitalsData,
  editVitalsDetails,
  editMode,
  setEditMode,
  resetEditData,
}: CreateVitalsProps) {
  const caseNotesDetails = useSelector((state: any) => state.activeCaseNotes);
  const caseNoteId = caseNotesDetails?.caseNotesDetails?.caseNoteId;
  const [editFlag, setEditFlag] = useState(false);
  const [formData, setFormData] = useState({
    values: defaultFormValues,
    errors: defaultFormValues,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [unitDropDownValuesOption, setUnitDropDownValuesOption] = useState<
    UnitDropDownValueOption[]
  >([]);

  const handleChange = (
    event: ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = event.target;
    const fieldError = validateFieldAsPerRules({
      rules: validationRules,
      fieldName: name,
      value,
    });

    setFormData((prevFormData) => ({
      values: { ...prevFormData.values, [name]: value },
      errors: { ...prevFormData.errors, [name]: fieldError },
    }));
  };

  const handleReset = () => {
    setFormData({
      values: defaultFormValues,
      errors: defaultFormValues,
    });
  };
  const handleClose = () => {
    if (editVitalsDetails) {
      handleReset();
      resetEditData();
    }
  };
  const fetchDropDownValues = async () => {
    const unitDropDownValues = await getUnitDropDownValues();
    setUnitDropDownValuesOption(unitDropDownValues.data.dataList);
  };

  const handleSubmit = async () => {
    const formErrors = validateDataAsPerRules({
      formData: formData.values,
      rules: validationRules,
    });

    if (Object.keys(formErrors).length > 0) {
      showToastMessage("Please fill correct value", TOAST_TYPE_OPTIONS.ERROR);
      return;
    }
    const inputFieldsToCheck = [
      "height",
      "weight",
      "bmi",
      "bp",
      "pulse",
      "temperature",
      "respiratoryRate",
      "ozSaturation",
      "pain",
    ];

    const { values } = formData;

    type InputFieldsObject = {
      [key: string]: string;
    };
    type VitalsValues = typeof formData.values;

    const inputFieldsObject = inputFieldsToCheck.reduce(
      (acc: InputFieldsObject, key: string) => {
        acc[key as keyof VitalsValues] = values[key as keyof VitalsValues];
        return acc;
      },
      {} as InputFieldsObject,
    );

    const ifAllEmpty = allKeysEmpty(inputFieldsObject);
    if (ifAllEmpty) {
      showToastMessage(
        "Please fill at least one field",
        TOAST_TYPE_OPTIONS.ERROR,
      );
      return;
    }
    const requestFormObject = {
      ...formData.values,
      caseNoteDetailId: caseNoteId,
    };
    let addVitalsResponse;
    if (editFlag) {
      addVitalsResponse = await updateVitalsCaseNote({
        requestObject: requestFormObject,
        vitalId: editVitalsDetails.id,
      });
    } else {
      addVitalsResponse = await createVitalsCaseNote(requestFormObject);
    }

    if (addVitalsResponse.status) {
      showToastMessage(addVitalsResponse.message, TOAST_TYPE_OPTIONS.SUCCESS);
      handleReset();
      getVitalsData();
      setIsModalOpen(false);
      setEditMode(false);
    } else {
      showToastMessage(addVitalsResponse.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  const checkForEditFlag = () => {
    setFormData({
      values: {
        height: editVitalsDetails.height,
        heightUOMId: editVitalsDetails.heightUOMId,
        weight: editVitalsDetails.weight,
        weightUOMId: editVitalsDetails.weightUOMId,
        bmi: editVitalsDetails.bmi,
        bp: editVitalsDetails.bp,
        bpUOMId: editVitalsDetails.bpUOMId,
        pulse: editVitalsDetails.pulse,
        pulseUOMId: editVitalsDetails.pulseUOMId,
        temperature: editVitalsDetails.temperature,
        temperatureUOMId: editVitalsDetails.temperatureUOMId,
        respiratoryRate: editVitalsDetails.respiratoryRate,
        respiratoryRateUOMId: editVitalsDetails.respiratoryRateUOMId,
        ozSaturation: editVitalsDetails.ozSaturation,
        ozSaturationUOMId: editVitalsDetails.ozSaturationUOMId,
        pain: editVitalsDetails.pain,
      },
      errors: {
        height: "",
        heightUOMId: "",
        weight: "",
        weightUOMId: "",
        bmi: "",
        bp: "",
        bpUOMId: "",
        pulse: "",
        pulseUOMId: "",
        temperature: "",
        temperatureUOMId: "",
        respiratoryRate: "",
        respiratoryRateUOMId: "",
        ozSaturation: "",
        ozSaturationUOMId: "",
        pain: "",
      },
    });
    setEditFlag(true);
  };

  useEffect(() => {
    fetchDropDownValues();
    if (editVitalsDetails && editMode) {
      checkForEditFlag();
      setIsModalOpen(true);
    } else {
      setEditFlag(false);
      handleReset();
    }
  }, [editVitalsDetails, editMode]);

  const getUnitOptions = (name: string) => {
    const unit = unitDropDownValuesOption.find(
      (option) => option.name.toLowerCase() === name.toLowerCase(),
    );
    return unit
      ? unit.unitOfMeasures.map((uom) => ({ value: uom.uomId, label: uom.uom }))
      : [];
  };
  useEffect(() => {
    if (unitDropDownValuesOption) {
      Object.keys(formData.values).forEach((fieldName) => {
        const unitOptions = getUnitOptions(
          fieldName.charAt(0).toUpperCase() + fieldName.slice(1),
        );
        if (
          unitOptions.length === 1 &&
          formData.values[
            `${fieldName}UOMId` as keyof typeof formData.values
          ] !== unitOptions[0].value
        ) {
          setFormData((prev) => ({
            ...prev,
            values: {
              ...prev.values,
              [`${fieldName}UOMId`]: unitOptions[0].value,
            },
          }));
        }
      });
    }
  }, [unitDropDownValuesOption, formData.values]);

  const handleSelectChange =
    (unitField: string) =>
    (
      selectedOption:
        | { value: string; label: string }
        | React.ChangeEvent<HTMLSelectElement>,
    ) => {
      const value =
        typeof selectedOption === "object"
          ? (selectedOption as unknown as { value: string }).value
          : (selectedOption as React.ChangeEvent<HTMLSelectElement>).target
              ?.value || "";
      setFormData((prev) => ({
        ...prev,
        values: { ...prev.values, [unitField]: value },
      }));
    };

  return (
    <ModalComponent
      buttonLeft={editFlag ? null : "Clear"}
      buttonRight={editFlag ? "Update" : "Save & Continue"}
      title={editFlag ? "Update Vital" : "Add Vital"}
      buttonShow="Add"
      onSubmit={handleSubmit}
      closeAction={handleClose}
      showAddUserPopup={isModalOpen}
      setShowAddUserPopup={setIsModalOpen}
      showToggleAsAddButton
      size="lg"
    >
      <div className="accordion-collapse">
        <form className="row g-3" onSubmit={handleSubmit}>
          <div className="row g-3">
            <div className="col-lg-4 col-sm-6">
              <div className="d-flex">
                <div className="me-1 flex-grow-1">
                  <Input
                    type="text"
                    className="form-control form-control-sm"
                    label={validationRules.height.displayName}
                    value={formData.values.height}
                    error={formData.errors.height}
                    onChange={handleChange}
                    name="height"
                    required={validationRules.height.required}
                  />
                </div>
                <div className="flex-shrink-1">
                  <InputDropdown
                    options={getUnitOptions("Height")}
                    onSelect={handleSelectChange("heightUOMId")}
                    className="form-select form-select-sm"
                    value={formData.values.heightUOMId}
                    label="Unit"
                    error={formData.errors.heightUOMId}
                    required={false}
                    name="heightUOMId"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="d-flex">
                <div className="me-1 flex-grow-1">
                  <Input
                    type="text"
                    className="form-control form-control-sm"
                    label={validationRules.weight.displayName}
                    value={formData.values.weight}
                    error={formData.errors.weight}
                    onChange={handleChange}
                    name="weight"
                    required={validationRules.weight.required}
                  />
                </div>
                <div className="flex-shrink-1">
                  <InputDropdown
                    options={getUnitOptions("Weight")}
                    onSelect={handleSelectChange("weightUOMId")}
                    className="form-select form-select-sm"
                    value={formData.values.weightUOMId}
                    label="Unit"
                    error={formData.errors.weightUOMId}
                    required={false}
                    name="weightUOMId"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="d-flex">
                <div className="me-1 flex-grow-1">
                  <Input
                    type="text"
                    className="form-control form-control-sm"
                    label={validationRules.bmi.displayName}
                    value={formData.values.bmi}
                    error={formData.errors.bmi}
                    onChange={handleChange}
                    name="bmi"
                    required={validationRules.bmi.required}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row g-3">
            <div className="col-lg-4 col-sm-6">
              <div className="d-flex">
                <div className="me-1 flex-grow-1">
                  <Input
                    type="text"
                    className="form-control form-control-sm"
                    label={validationRules.bp.displayName}
                    value={formData.values.bp}
                    error={formData.errors.bp}
                    onChange={handleChange}
                    name="bp"
                    required={validationRules.bp.required}
                  />
                </div>
                <div className="flex-shrink-1">
                  <InputDropdown
                    options={getUnitOptions("BP")}
                    onSelect={handleSelectChange("bpUOMId")}
                    className="form-select form-select-sm"
                    value={formData.values.bpUOMId}
                    label="Unit"
                    error={formData.errors.bpUOMId}
                    required={false}
                    name="bpUOMId"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="d-flex">
                <div className="me-1 flex-grow-1">
                  <Input
                    type="text"
                    className="form-control form-control-sm"
                    label={validationRules.pulse.displayName}
                    value={formData.values.pulse}
                    error={formData.errors.pulse}
                    onChange={handleChange}
                    name="pulse"
                    required={validationRules.pulse.required}
                  />
                </div>
                <div className="flex-shrink-1">
                  <InputDropdown
                    options={getUnitOptions("Pulse")}
                    onSelect={handleSelectChange("pulseUOMId")}
                    className="form-select form-select-sm"
                    value={formData.values.pulseUOMId}
                    label="Unit"
                    error={formData.errors.pulseUOMId}
                    required={false}
                    name="pulseUOMId"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="d-flex">
                <div className="me-1 flex-grow-1">
                  <Input
                    type="text"
                    className="form-control form-control-sm"
                    label={validationRules.temperature.displayName}
                    value={formData.values.temperature}
                    error={formData.errors.temperature}
                    onChange={handleChange}
                    name="temperature"
                    required={validationRules.temperature.required}
                  />
                </div>
                <div className="flex-shrink-1">
                  <InputDropdown
                    options={getUnitOptions("Temperature")}
                    onSelect={handleSelectChange("temperatureUOMId")}
                    className="form-select form-select-sm"
                    value={formData.values.temperatureUOMId}
                    label="Unit"
                    error={formData.errors.temperatureUOMId}
                    required={false}
                    name="temperatureUOMId"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-lg-4 col-sm-6">
              <div className="d-flex">
                <div className="me-1 flex-grow-1">
                  <Input
                    type="text"
                    className="form-control form-control-sm"
                    label={validationRules.respiratoryRate.displayName}
                    value={formData.values.respiratoryRate}
                    error={formData.errors.respiratoryRate}
                    onChange={handleChange}
                    name="respiratoryRate"
                    required={validationRules.respiratoryRate.required}
                  />
                </div>
                <div className="flex-shrink-1">
                  <InputDropdown
                    options={getUnitOptions("RespiratoryRate")}
                    onSelect={handleSelectChange("respirationUOMId")}
                    className="form-select form-select-sm"
                    value={formData.values.respiratoryRateUOMId}
                    label="Unit"
                    error={formData.errors.respiratoryRateUOMId}
                    required={false}
                    name="respirationUOMId"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="d-flex">
                <div className="me-1 flex-grow-1">
                  <Input
                    type="text"
                    className="form-control form-control-sm"
                    label={validationRules.ozSaturation.displayName}
                    value={formData.values.ozSaturation}
                    error={formData.errors.ozSaturation}
                    onChange={handleChange}
                    name="ozSaturation"
                    required={validationRules.ozSaturation.required}
                  />
                </div>
                <div className="flex-shrink-1">
                  <InputDropdown
                    options={getUnitOptions("OzSaturation")}
                    onSelect={handleSelectChange("spo2UOMId")}
                    className="form-select form-select-sm"
                    value={formData.values.ozSaturationUOMId}
                    label="Unit"
                    error={formData.errors.ozSaturationUOMId}
                    required={false}
                    name="spo2UOMId"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="d-flex">
                <div className="me-1 flex-grow-1">
                  <Input
                    type="text"
                    className="form-control form-control-sm"
                    label={validationRules.pain.displayName}
                    value={formData.values.pain}
                    error={formData.errors.pain}
                    onChange={handleChange}
                    name="pain"
                    required={validationRules.pain.required}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </ModalComponent>
  );
}
