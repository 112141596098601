import { ValidationRules } from "../../../../services/utils/validateDataAsPerRules";

export const validationRules: ValidationRules = {
  details: {
    required: false,
    dataType: "textOrNumber",
    characterLimit: 255,
    displayName: "Details",
  },
  assignTo: {
    required: true,
    dataType: "number",
    characterLimit: 50,
    displayName: "Assign To",
  },
  taskTypeId: {
    required: false,
    dataType: "number",
    characterLimit: 50,
    displayName: "Task Type",
  },
  authorName: {
    required: false,
    dataType: "any",
    characterLimit: 50,
    displayName: "Author",
  },
  patientId: {
    required: true,
    dataType: "any",
    characterLimit: 50,
    displayName: "Patient Name",
  },
  reminderDate: {
    required: true,
    dataType: "date",
    characterLimit: 100,
    isPastDateAllow: false,
    isFutureDateAllow: true,
    displayName: "Reminder Date",
  },
  dateOfBirth: {
    required: false,
    dataType: "date",
    characterLimit: 100,
    isPastDateAllow: true,
    isFutureDateAllow: false,
    displayName: "Date of Birth",
  },
};

export const defaultValues = {
  details: "",
  assignTo: "",
  dateOfBirth: "",
  reminderDate: "",
  taskTypeId: "",
  patientId: "",
  patientName: "",
  authorName: "",
  status: "InComplete",
};

export const adminStatusList = [
  { value: "InComplete", label: "InComplete" },
  { value: "Complete", label: "Complete" },
];

export const CMStatusList = [
  { value: "InComplete", label: "InComplete" },
  { value: "CMComplete", label: "CM Complete" },
];

export const dateAdjustments = [
  { label: "Today", value: "today" },
  { label: "+1 d", value: "+1d" },
  { label: "+1 w", value: "+1w" },
  { label: "+1 m", value: "+1m" },
  { label: "+1 y", value: "+1y" },
];
