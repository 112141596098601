import { useEffect, useState } from "react";
import { Table } from "../../../components/atom/Table/Table";
import { Card } from "../../../components/atom/Cards/Cards";
import { CreateNewUserModal } from "../../../components/organism/CreateNewUserModal/CreateNewUserModal";
import { getAllUsersService } from "../../../services/users/users";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import {
  TOAST_TYPE_OPTIONS,
  USER_STATUS_ADMIN,
} from "../../../constants/variables";
import { Pagination } from "../../../components/atom/Pagination/Pagination";
import { FullScreenLoader } from "../../../components/atom/FullScreenLoader/FullScreenLoader";

interface UserData {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  officeKey: string;
  roleId: number;
  statusId: number;
}
export function UsersList() {
  const [editMode, setEditMode] = useState(false);
  const [fullScreenLoaderStatus, setFullScreenLoaderStatus] = useState(false);
  const [userListData, setUserListData] = useState<UserData[]>([]);
  const [editUserData, setEditUserData] = useState<UserData>();
  const [paginationData, setPaginationData] = useState({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });
  const headersForTable: Record<string, string> = {
    firstName: "First Name",
    lastName: "Last Name",
    username: "User Name",
    email: "Email Id",
    officeKey: "Office Key",
    roleName: "Role Name",
    status: "Status",
    action: "Action",
  };
  const sortingHeaderMap: Record<string, string> = {
    firstName: "FirstName",
    lastName: "LastName",
    username: "UserName",
    email: "Email",
    officeKey: "OfficeKey",
    roleName: "RoleName",
    status: "Status",
  };
  const mapUserStatus = (statusId: number) => {
    const statusObject = USER_STATUS_ADMIN.find(
      (status) => status.value === String(statusId),
    );
    return statusObject ? statusObject.label : "Unknown status";
  };

  const userListDataWithRoleAndStatus =
    userListData?.map((user) => ({
      ...user,
      status: mapUserStatus(user.statusId),
    })) || [];

  const getAllUserData = async (newPaginationData?: any) => {
    try {
      const paginationDataAsParameter = newPaginationData || paginationData;
      setFullScreenLoaderStatus(true);
      const response = await getAllUsersService({ paginationDataAsParameter });
      setFullScreenLoaderStatus(false);
      if (response.allUsers) {
        setPaginationData({
          ...newPaginationData,
          pageNo: response.allUsers.pageNo,
          totalPages: response.allUsers.totalPages,
          totalElements: response.allUsers.totalElements,
          pageSize: response.allUsers.pageSize,
        });
      } else {
        showToastMessage(response.message, TOAST_TYPE_OPTIONS.ERROR);
      }

      setUserListData(response.allUsers.dataList);
    } catch (error) {
      showToastMessage("Error fetching all users:", TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  const editUserDetails = async (rowData: any) => {
    setEditUserData(rowData);
    setEditMode(true);
  };

  useEffect(() => {
    (async () => {
      getAllUserData();
    })();
  }, []);

  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize,
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    getAllUserData({ ...paginationData, pageNo, pageSize });
  };
  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    getAllUserData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };
  return (
    <div>
      <FullScreenLoader show={fullScreenLoaderStatus} />
      <Card>
        <CreateNewUserModal
          getAllUserData={getAllUserData}
          editUserDetails={editUserData}
          editMode={editMode}
          setEditMode={setEditMode}
        />
        <div className="table-responsive care-manager-table">
          <Table
            className="table table-bordered table-striped table-hover"
            data={userListDataWithRoleAndStatus}
            headers={headersForTable}
            enableEditAction={editUserDetails}
            sortingFunction={sortingFunction}
            sortingHeaderMap={sortingHeaderMap}
          />
          {paginationData.totalPages >= 0 && (
            <Pagination
              currentPage={paginationData.pageNo}
              totalPages={paginationData.totalPages}
              pageSize={paginationData.pageSize}
              onPageChange={(page, pageSize) => {
                updatePaginationData(page, pageSize);
              }}
            />
          )}
        </div>
      </Card>
    </div>
  );
}
