import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "../../../atom/Table/Table";
import { AddMedications } from "./AddMedications/AddMedications";
import {
  deleteCaseNotesMedicationByIdService,
  getAllCaseNoteMedication,
} from "../../../../services/caseNotes/caseNotes";
import { showToastMessage } from "../../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../../constants/variables";
import { Pagination } from "../../../atom/Pagination/Pagination";
import { FullScreenLoader } from "../../../atom/FullScreenLoader/FullScreenLoader";

const sortingHeaderMap: Record<string, string> = {
  medicationName: "MedicationName",
  sig: "Sig",
  prescribersName: "PrescribersName",
  startDate: "MedicationStartDate",
  stopDate: "MedicationStopDate",
};

const tableHeaders = {
  medicationName: "Medication Name",
  sig: "SIG",
  prescribersName: "Prescriber's Name",
  startDate: "Start Date",
  stopDate: "Stop Date",
  action: "Action",
};

interface MedicationsProps {
  isViewMode: boolean;
}
export function Medications({ isViewMode }: MedicationsProps) {
  interface MedicationList {
    medicationName: string;
    sig: string;
    prescribersName: string;
    startDate: string;
    stopDate: string;
  }
  const caseNotesDetails = useSelector((state: any) => state.activeCaseNotes);
  const [editMedicationListData, seteditMedicationListData] = useState([]);
  const [fullScreenLoaderFlag, setFullScreenLoaderFlag] = useState(false);

  const [editMode, setEditMode] = useState<any>(null);
  const [medicationListData, setMedicationListData] = useState<
    MedicationList[]
  >([]);

  const [paginationData, setPaginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });
  const fetchData = async (newPaginationData?: any) => {
    try {
      setFullScreenLoaderFlag(true);
      const paginationDataAsParameter = newPaginationData || paginationData;
      const response = await getAllCaseNoteMedication({
        paginationDataAsParameter,
        caseNoteId: caseNotesDetails?.caseNotesDetails?.caseNoteId,
      });
      if (response.status) {
        setMedicationListData(response.data.dataList);
        setPaginationData({
          ...paginationData,
          pageNo: response.data.pageNo,
          totalPages: response.data.totalPages,
          totalElements: response.data.totalElements,
          pageSize: response.data.pageSize,
        });
        setFullScreenLoaderFlag(false);
      }
    } catch (error) {
      showToastMessage(
        "Error fetching case notes data:",
        TOAST_TYPE_OPTIONS.ERROR,
      );
      console.error("Error in fetching case notes data :", error);
    }
  };

  const editRecord = async (rowData: any) => {
    seteditMedicationListData(rowData);
    setEditMode(true);
  };

  const resetEditData = () => {
    setEditMode(false);
  };
  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize,
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    fetchData({ ...paginationData, pageNo, pageSize });
  };
  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    fetchData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };
  const deleteMedication = async (rowData: any) => {
    const deleteMedicationCaseNotesResponse =
      await deleteCaseNotesMedicationByIdService(rowData.id);
    if (deleteMedicationCaseNotesResponse.status) {
      showToastMessage(
        `Medications Deleted Successfully`,
        TOAST_TYPE_OPTIONS.SUCCESS,
      );
      await fetchData();
    } else {
      showToastMessage(
        deleteMedicationCaseNotesResponse.message,
        TOAST_TYPE_OPTIONS.ERROR,
      );
    }
    await fetchData();
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div
      className="accordion-collapse"
      data-bs-parent="#accordionFlushExample-parent"
    >
      <FullScreenLoader show={fullScreenLoaderFlag} />
      <div className="d-flex justify-content-end mb-2">
        {isViewMode ? (
          <div />
        ) : (
          <AddMedications
            getMedicationData={fetchData}
            editMedicationDetails={editMedicationListData}
            editMode={editMode}
            setEditMode={setEditMode}
            resetEditData={resetEditData}
          />
        )}
      </div>
      <div className="table-responsive care-manager-table">
        <Table
          headers={tableHeaders}
          enableEditAction={editRecord}
          data={medicationListData}
          sortingFunction={sortingFunction}
          isEditDisabled={() => isViewMode}
          isDeleteDisabled={() => isViewMode}
          sortingHeaderMap={sortingHeaderMap}
          enableDeleteAction={deleteMedication}
          className="table table-bordered table-striped table-hover"
        />
        {paginationData.totalPages >= 0 && (
          <Pagination
            currentPage={paginationData.pageNo}
            totalPages={paginationData.totalPages}
            pageSize={paginationData.pageSize}
            onPageChange={(page, pageSize) => {
              updatePaginationData(page, pageSize);
            }}
          />
        )}
      </div>
    </div>
  );
}
