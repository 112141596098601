import {
  ServiceResponse,
  generateErrorResponse,
} from "./generateErrorResponse";

export function isValidEmail(email: string): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}
export function validateEmail(email: string | undefined): ServiceResponse {
  if (!email) {
    return generateErrorResponse("Email is required.", 400);
  }

  if (!isValidEmail(email)) {
    return generateErrorResponse("Invalid email format.", 400);
  }
  return { status: true };
}
