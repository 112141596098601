import React, { useEffect, useState, FormEvent, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { Input } from "../../../atom/Input/Input";
import { Button } from "../../../atom/Buttons/Button";
import { getCareManagerDetailsForCareTeam } from "../../../../services/caseManager/caseManager";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../../services/utils/validateDataAsPerRules";
import { validationRules } from "./helperFunctions/constants";
import { updateCaseManagerForCarePlan } from "../../../../services/carePlan/carePlan";
import { showToastMessage } from "../../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../../constants/variables";

const defaultFormValues = {
  name: "",
  contactNumber: "",
  email: "",
};

interface CaseManagerProps {
  isViewMode?: boolean;
}

export function CaseManager({ isViewMode }: CaseManagerProps) {
  const carePlanDetails = useSelector((state: any) => state.activeCarePlan);
  const activePatient = useSelector((state: any) => state.activePatient);
  const [editFlagStatus, setEditFlagStatus] = useState(false);
  const [formData, setFormData] = useState({
    values: defaultFormValues,
    errors: defaultFormValues,
  });

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;
    const fieldError = validateFieldAsPerRules({
      rules: validationRules,
      fieldName: name,
      value,
    });

    setFormData({
      ...formData,
      values: { ...formData.values, [name]: value },
      errors: { ...formData.errors, [name]: fieldError },
    });
  };
  const fetchData = async () => {
    const detailsOfCareManager = await getCareManagerDetailsForCareTeam({
      carePlanId: carePlanDetails.carePlanDetails.carePlanId,
      patientId: activePatient.patientDetails.id,
    });
    if (detailsOfCareManager.status) {
      setFormData({
        ...formData,
        values: {
          name: detailsOfCareManager.data.caseManagerName,
          contactNumber: detailsOfCareManager.data.caseManagerContactNumber,
          email: detailsOfCareManager.data.caseManagerEmail,
        },
      });
      if (detailsOfCareManager.data.caseManagerName) {
        setEditFlagStatus(true);
      }
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formErrors = validateDataAsPerRules({
      formData,
      rules: validationRules,
    });

    if (Object.keys(formErrors).length) {
      showToastMessage("Invalid data", TOAST_TYPE_OPTIONS.ERROR);
      setFormData({ ...formData, errors: formErrors });
    }

    const savingCaseManagerDataApiResponse = await updateCaseManagerForCarePlan(
      {
        patientId: activePatient.patientDetails.id,
        formData: formData.values,
        carePlanId: carePlanDetails.carePlanDetails.carePlanId,
      },
    );
    if (savingCaseManagerDataApiResponse.status) {
      showToastMessage(
        savingCaseManagerDataApiResponse.message,
        TOAST_TYPE_OPTIONS.SUCCESS,
      );
    } else {
      showToastMessage(
        savingCaseManagerDataApiResponse.message,
        TOAST_TYPE_OPTIONS.ERROR,
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleReset = () => {
    setFormData({
      ...formData,
      values: defaultFormValues,
      errors: defaultFormValues,
    });
  };

  return (
    <div>
      <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-lg-4 col-sm-4">
          <Input
            type="text"
            className="form-control form-control-sm "
            placeholder="Name"
            disabled
            label="Name"
            name="name"
            onChange={handleChange}
            value={formData.values.name}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            type="text"
            className="form-control form-control-sm "
            placeholder="xyz@gmail.com"
            disabled
            label="Email"
            name="email"
            onChange={handleChange}
            value={formData.values.email}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            type="text"
            className="form-control form-control-sm"
            placeholder="xxx-xxx-xxxx"
            label="Contact Number"
            onChange={handleChange}
            value={formData.values.contactNumber}
            name="contactNumber"
            error={formData.errors.contactNumber}
            disabled={isViewMode}
          />
        </div>
        <div className="col-12 d-flex justify-content-between mt-5">
          {editFlagStatus ? (
            <div />
          ) : (
            <Button
              className="btn btn-danger btn-sm"
              type="reset"
              onClick={handleReset}
              label="Clear"
            />
          )}
          {isViewMode ? (
            <div />
          ) : (
            <Button
              className="btn btn-primary btn-sm px-4"
              type="submit"
              label={editFlagStatus ? "Update & Continue" : "Save & Continue"}
            />
          )}
        </div>
      </form>
    </div>
  );
}
