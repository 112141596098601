import {
  useEffect,
  ChangeEvent,
  FormEvent,
  useState,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "../../../atom/Input/Input";
import { Button } from "../../../atom/Buttons/Button";
import { InputDropdown } from "../../../atom/InputDropDown/InputDropDown";
import {
  createCaseNote,
  getCaseNoteDetails,
  updateCaseNote,
} from "../../../../services/caseNotes/caseNotes";
import { initCaseNotesDetails } from "../../../../redux/activeCaseNote/activeCaseNote";

import { showToastMessage } from "../../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../../constants/variables";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../../services/utils/validateDataAsPerRules";
import { getOptionsForDropdown } from "../../../../services/dropDownMenuOptions/dropDownMenuOptions";
import { validationRules } from "./helperFunctions/constants";
import { formatDate } from "../../../../services/utils/formatDate";
import { FullScreenLoader } from "../../../atom/FullScreenLoader/FullScreenLoader";
import { getIdByLegalValue } from "../../CreateNewCarePlan/MemberDetail/helperFunctions/getIdByLegalValue";
import { calculateAge } from "../../../../services/utils/calculateAge";

const defaultFormValues = {
  encounterTypeId: "",
  noteTypeId: "",
  statusId: "",
  date: "",
  ageAtEncounter: "",
  organization: "",
  seenBy: "",
  lastModifiedOn: "",
  lastModifiedBy: "",
  createdOn: "",
  createdBy: "",
};

interface CreateNewCaseNotesDetailsProps {
  isViewMode: boolean;
}
export function CaseNoteDetails({
  isViewMode,
}: CreateNewCaseNotesDetailsProps) {
  const [encounterTypeOptions, setEncounterTypeOptions] = useState([]);
  const [noteTypeOptions, setNoteTypeOptions] = useState([]);
  const [statusTypeOptions, setStatusTypeOptions] = useState([]);
  const [fullScreenLoaderFlag, setFullScreenLoaderFlag] = useState(false);

  const activePatient = useSelector((state: any) => state.activePatient);
  const caseNotesDetails = useSelector((state: any) => state.activeCaseNotes);
  const dispatch = useDispatch();
  const [editFlag, setEditFlag] = useState(false);
  const caseNoteId = caseNotesDetails?.caseNotesDetails?.caseNoteId;

  const [formData, setFormData] = useState({
    values: defaultFormValues,
    errors: defaultFormValues,
    labels: {
      encounterType: "Encounter Type",
      noteTypeId: "Note Type",
      date: "Date",
      ageAtEncounter: "Age at Encounter",
      organization: "Organization",
      seenBy: "Seen By",
      statusId: "Status",
    },
  });

  const handleReset = () => {
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        encounterTypeId: "",
        noteTypeId: "",
        date: "",
        organization: "",
        seenBy: "",
      },
      errors: defaultFormValues,
    });
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;
    const fieldError = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: formData.labels[name as keyof typeof formData.labels],
      fieldName: name,
      value,
    });

    setFormData((prevFormData) => ({
      ...prevFormData,
      values: { ...prevFormData.values, [name]: value },
      errors: { ...prevFormData.errors, [name]: fieldError || "" },
    }));
  };

  const fetchData = useCallback(async () => {
    const updatedValues = {
      ...formData.values,
      ageAtEncounter: calculateAge(activePatient.patientDetails.dob),
    };
    setFullScreenLoaderFlag(true);

    try {
      const encounterList = await getOptionsForDropdown("encounterType");
      const noteType = await getOptionsForDropdown("noteType");
      const statusType = await getOptionsForDropdown("CarePlanStatus");
      setEncounterTypeOptions(encounterList);
      setNoteTypeOptions(noteType);
      setStatusTypeOptions(statusType);
      updatedValues.statusId = getIdByLegalValue("Not started", statusType);
      if (caseNotesDetails?.caseNotesDetails?.caseNoteId) {
        setEditFlag(true);
        const caseNoteDetailsData = await getCaseNoteDetails(caseNoteId);
        if (caseNoteDetailsData.isSuccess) {
          setFormData({
            ...formData,
            values: {
              ...updatedValues,
              encounterTypeId: caseNoteDetailsData.data.encounterTypeId,
              noteTypeId: caseNoteDetailsData.data.noteTypeId,
              statusId: caseNoteDetailsData.data.statusId,
              date: formatDate(caseNoteDetailsData.data.date, "YYYY-MM-DD"),
              ageAtEncounter: caseNoteDetailsData.data.ageAtEncounter,
              organization: caseNoteDetailsData.data.organization,
              seenBy: caseNoteDetailsData.data.seenBy,
              createdBy: caseNoteDetailsData.data.createdBy,
              createdOn: caseNoteDetailsData.data.createdOn,
              lastModifiedBy: caseNoteDetailsData.data.lastModifiedBy,
              lastModifiedOn: caseNoteDetailsData.data.lastModifiedOn,
            },
          });
        }
      } else {
        setEditFlag(false);
        setFormData({
          ...formData,
          values: updatedValues,
        });
      }
    } finally {
      setFullScreenLoaderFlag(false);
    }
  }, [activePatient, caseNotesDetails, formData]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formErrors = validateDataAsPerRules({
      rules: validationRules,
      formData: formData.values,
    });
    if (Object.values(formErrors).some((error) => error !== "")) {
      setFormData({
        ...formData,
        errors: formErrors,
      });
      return;
    }

    const statusType = await getOptionsForDropdown("CarePlanStatus");
    setStatusTypeOptions(statusType);
    const requestObject = {
      ...formData.values,
      patientId: activePatient.patientDetails.id,
      date: formatDate(formData.values.date),
    };
    let createNewCaseNoteResponse;
    if (caseNoteId) {
      createNewCaseNoteResponse = await updateCaseNote({
        requestObject,
        caseNoteId,
      });
      if (createNewCaseNoteResponse.status) {
        showToastMessage(
          createNewCaseNoteResponse.message,
          TOAST_TYPE_OPTIONS.SUCCESS,
        );
        dispatch(
          initCaseNotesDetails({
            caseNoteId: createNewCaseNoteResponse.data.caseNoteDetailId,
          }),
        );
        fetchData();
      } else {
        showToastMessage(
          createNewCaseNoteResponse.message,
          TOAST_TYPE_OPTIONS.ERROR,
        );
      }
    } else {
      createNewCaseNoteResponse = await createCaseNote(requestObject);
      if (createNewCaseNoteResponse.isSuccess) {
        showToastMessage(
          createNewCaseNoteResponse.message,
          TOAST_TYPE_OPTIONS.SUCCESS,
        );
        dispatch(
          initCaseNotesDetails({
            caseNoteId: createNewCaseNoteResponse.data.caseNoteDetailId,
          }),
        );
        fetchData();
      } else {
        showToastMessage(
          createNewCaseNoteResponse.message,
          TOAST_TYPE_OPTIONS.ERROR,
        );
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (caseNotesDetails.caseNotesDetails.caseNoteId) {
      fetchData();
    }
  }, [caseNotesDetails]);
  return (
    <div className="accordion-collapse">
      <FullScreenLoader show={fullScreenLoaderFlag} />
      <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-lg-4 col-sm-4">
          <InputDropdown
            options={encounterTypeOptions}
            className="form-select form-select-sm"
            onSelect={handleChange}
            label={validationRules.encounterTypeId.displayName}
            name="encounterTypeId"
            value={formData.values.encounterTypeId}
            error={formData.errors.encounterTypeId}
            required={validationRules.encounterTypeId.required}
            disabled={isViewMode}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <InputDropdown
            options={noteTypeOptions}
            className="form-select form-select-sm"
            onSelect={handleChange}
            label={validationRules.noteTypeId.displayName}
            name="noteTypeId"
            value={formData.values.noteTypeId}
            error={formData.errors.noteTypeId}
            required={validationRules.noteTypeId.required}
            disabled={isViewMode}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            type="date"
            id="date"
            name="date"
            value={formData.values.date}
            onChange={handleChange}
            placeholder="Date"
            className="form-control form-control-sm"
            label={validationRules.date.displayName}
            error={formData.errors.date}
            required={validationRules.date.required}
            disabled={isViewMode}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            type="text"
            className="form-control form-control-sm"
            placeholder="Organization"
            label={validationRules.organization.displayName}
            name="organization"
            onChange={handleChange}
            value={formData.values.organization}
            error={formData.errors.organization}
            required={validationRules.organization.required}
            disabled={isViewMode}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            type="text"
            className="form-control form-control-sm"
            placeholder="Seen By"
            label={validationRules.seenBy.displayName}
            name="seenBy"
            onChange={handleChange}
            value={formData.values.seenBy}
            error={formData.errors.seenBy}
            disabled={isViewMode}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            type="text"
            className="form-control form-control-sm"
            placeholder="Age at Encounter"
            label={validationRules.ageAtEncounter.displayName}
            name="ageAtEncounter"
            disabled
            onChange={handleChange}
            error={formData.errors.ageAtEncounter}
            value={formData.values.ageAtEncounter}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <InputDropdown
            options={statusTypeOptions}
            className="form-select form-select-sm"
            onSelect={handleChange}
            name="statusId"
            value={formData.values.statusId}
            label={validationRules.statusId.displayName}
            error={formData.errors.statusId}
            required={validationRules.statusId.required}
            disabled
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            className="form-control form-control-sm "
            placeholder="Created By"
            disabled
            onChange={handleChange}
            value={formData.values.createdBy}
            label={validationRules.createdBy.displayName}
            error={formData.errors.createdBy}
            name="createdBy"
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            className="form-control form-control-sm "
            placeholder="Created On"
            disabled
            onChange={handleChange}
            value={formData.values.createdOn}
            label={validationRules.createdOn.displayName}
            error={formData.errors.createdOn}
            name="createdOn"
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            className="form-control form-control-sm "
            placeholder="Last Modified By"
            disabled
            onChange={handleChange}
            value={formData.values.lastModifiedBy}
            label={validationRules.lastModifiedBy.displayName}
            error={formData.errors.lastModifiedBy}
            name="lastModifiedBy"
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            className="form-control form-control-sm "
            placeholder="Last Modified On"
            disabled
            onChange={handleChange}
            value={formData.values.lastModifiedOn}
            label={validationRules.lastModifiedOn.displayName}
            error={formData.errors.lastModifiedOn}
            name="lastModifiedOn"
          />
        </div>
        <div className="col-12 d-flex justify-content-between mt-3">
          {editFlag ? (
            <div />
          ) : (
            <Button
              className="btn btn-danger btn-sm"
              type="reset"
              onClick={handleReset}
              label="Clear"
            />
          )}
          {isViewMode ? (
            <div />
          ) : (
            <Button
              type="submit"
              variant="outlined"
              className="btn btn-primary btn-sm px-4"
              label={editFlag ? "Update" : "Save & Continue"}
            />
          )}
        </div>
      </form>
    </div>
  );
}
