import { STATUS_CODES } from "../../constants/variables";
import { API_ENDPOINTS } from "../../constants/apiList";
import ApiCaller from "../../utils/apiCaller";

export interface CreateUserParams {
  username?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  officeKey: number | null;
  email?: string;
  statusId: number | string;
  roleId: number | string;
  organizationId?: number | string;
}

export async function createUser(userData: CreateUserParams): Promise<any> {
  const createUserUrl = API_ENDPOINTS.createUser;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(createUserUrl, userData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "User Added Successfully",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function deleteUser(userId: number): Promise<any> {
  const deleteUserUrl = `${API_ENDPOINTS.deleteUser}/${userId}`;

  try {
    const response = await ApiCaller.delete(deleteUserUrl);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getAllUsers({
  paginationDataAsParameter,
}: any): Promise<any> {
  const { pageNo, pageSize, sortingFieldName, sortingOrder } =
    paginationDataAsParameter;
  let getUsersUrl = `${API_ENDPOINTS.getAllUsers}?pageNo=${pageNo}&pageSize=${pageSize}`;
  if (
    sortingFieldName &&
    sortingFieldName !== "" &&
    sortingOrder &&
    sortingOrder !== ""
  ) {
    getUsersUrl = `${getUsersUrl}&sortBy=${sortingFieldName}&sortDir=${sortingOrder}`;
  }
  try {
    const response: { isSuccess: boolean; responseMsg?: Array<string> } =
      await ApiCaller.get(getUsersUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "user received successfully",
        data: response,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getActiveUsers(): Promise<any> {
  const getActiveUsersUrl = API_ENDPOINTS.getActiveUsers;

  try {
    const response = await ApiCaller.get(getActiveUsersUrl);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function updateUser(
  userId: number,
  updatedUserData: Partial<CreateUserParams>,
): Promise<any> {
  const updateUserUrl = `${API_ENDPOINTS.updateUser}/${userId}`;

  try {
    const response = await ApiCaller.put<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(updateUserUrl, updatedUserData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "User updated successfully",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function userPasswordReset(newPassword: string): Promise<any> {
  const resetUserPasswordUrl = API_ENDPOINTS.resetUserPassword;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(resetUserPasswordUrl, {
      password: newPassword,
      confirmPassword: newPassword,
    });
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: response?.responseMsg?.[0],
        data: response.data,
      };
    }
    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    if (error?.response?.status === 401) {
      return {
        status: false,
        code: 401,
        message: "Invalid link",
        details: "API call failed",
      };
    }
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function checkTokenLinkAPI(token: string): Promise<any> {
  const validateLinkUrl = API_ENDPOINTS.checkForTokenLink;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(validateLinkUrl, {
      token,
    });
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: response?.responseMsg?.[0],
        data: response.data,
      };
    }
    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    if (error?.response?.status === 401) {
      return {
        status: false,
        code: 401,
        message: "Invalid link",
        details: "API call failed",
      };
    }
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
