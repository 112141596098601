import Logger from "../../utils/Logger";
import {
  createNewCarePlan,
  getAllAllergiesForCarePlan,
  getAllCarePlanForPatientAPI,
  getCareTeamForCarePlan,
  updateCareGiverAPI,
  deleteCarePlan,
  createCarePlanAllergiesAPI,
  createAndUpdateICDTeamNotesAPI,
  getCarePlanMemberDetails,
  updateCaseManagerDetails,
  getAllMedicationNameForCarePlan,
  getAllDiagnosticHistoryOfPatient,
  fetchPatientLabResults,
  fetchDiagnosticResults,
  getCarePlanICD10Values,
  getCarePlanCareGiver,
  updatePatientCarePlan,
  createCareGiverAPI,
  createGoalForPatientAPI,
  getCarePlanGoalsAPI,
  updateCarePlanAllergiesAPI,
  updateGoalForPatientAPI,
  finalizeCarePlan,
  getCarePlanInitialRiskAssessment,
} from "../../dataLayer/carePlan/carePlan";
import { formatDate } from "../utils/formatDate";
import { RADIX_VALUE_FOR_DECIMAL } from "../../constants/variables";
import { RequestObjectForUpdateGoalsForPatient } from "./carePlanInterfaces";

export async function createCarePlan(formData: any): Promise<any> {
  try {
    const requestData = {
      patientId: parseInt(formData.patientId, RADIX_VALUE_FOR_DECIMAL),
      currentRiskLevel: parseInt(
        formData.values.currentRiskLevel,
        RADIX_VALUE_FOR_DECIMAL,
      ),
      bestTimeToReach: formData.values.bestTimeToReach.toString(),
      enrollmentStatus: parseInt(
        formData.values.enrollmentStatus,
        RADIX_VALUE_FOR_DECIMAL,
      ),
      enrollmentDate: formatDate(formData.values.enrollmentDate),
      dueDate: formatDate(formData.values.dueDate),
    };
    Logger.logInfo("creating new care plan", { data: requestData });
    const createNewCarePlanResponse = await createNewCarePlan(requestData);
    return createNewCarePlanResponse;
  } catch (error: any) {
    Logger.logError("Exception in creating plan", { error });
    return {
      status: false,
      code: 500,
      message: "Error creating appointment.",
      details: error.message,
    };
  }
}

// /updatePatientCarePlan

export async function updateCarePlan(
  formData: any,
  carePlanId: string,
): Promise<any> {
  try {
    const requestData = {
      patientId: parseInt(formData.patientId, RADIX_VALUE_FOR_DECIMAL),
      currentRiskLevel: parseInt(
        formData.values.currentRiskLevel,
        RADIX_VALUE_FOR_DECIMAL,
      ),
      bestTimeToReach: parseInt(
        formData.values.bestTimeToReach,
        RADIX_VALUE_FOR_DECIMAL,
      ),
      enrollmentStatus: parseInt(
        formData.values.enrollmentStatus,
        RADIX_VALUE_FOR_DECIMAL,
      ),
      enrollmentDate: formatDate(formData.values.enrollmentDate),
      dueDate: formatDate(formData.values.dueDate),
    };
    Logger.logInfo("updating care plan", {
      data: { requestData, carePlanId },
    });
    const updateExistingCarePlanResponse = await updatePatientCarePlan(
      requestData,
      carePlanId,
    );
    return updateExistingCarePlanResponse;
  } catch (error: any) {
    Logger.logError("Exception in creating plan", { error });
    return {
      status: false,
      code: 500,
      message: "Error creating appointment.",
      details: error.message,
    };
  }
}

export async function getAllCareTeamListForCarePlan({
  paginationDataAsParameter,
  patientId,
  carePlanId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
  carePlanId: string;
}): Promise<any> {
  try {
    const carePlanList = await getCareTeamForCarePlan({
      paginationDataAsParameter,
      patientId,
      carePlanId,
    });
    return carePlanList;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all Care team.",
      details: error.message,
    };
  }
}

export async function getAllAllergiesOfPatent({
  paginationDataAsParameter,
  carePlanId,
  patientId,
}: {
  paginationDataAsParameter: any;
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  try {
    const carePlanList = await getAllAllergiesForCarePlan({
      paginationDataAsParameter,
      carePlanId,
      patientId,
    });
    return carePlanList;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all Care team.",
      details: error.message,
    };
  }
}
export async function getListOfCarePlanForPatient({
  paginationDataAsParameter,
  patientId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
}): Promise<any> {
  try {
    const carePlanList = await getAllCarePlanForPatientAPI({
      paginationDataAsParameter,
      patientId,
    });
    return carePlanList;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all Care Plan.",
      details: error.message,
    };
  }
}

export async function createCarePlanAllergies({
  formData,
  carePlanId,
  patientId,
}: {
  formData: any;
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  try {
    const requestData = {
      allergyDate: formatDate(formData.values.date),
      details: formData.values.details,
    };
    Logger.logInfo("creating care plan allergies", {
      data: { requestData, carePlanId, patientId },
    });
    const createNewCarePlanResponse = await createCarePlanAllergiesAPI({
      formData: requestData,
      carePlanId,
      patientId,
    });
    return createNewCarePlanResponse;
  } catch (error: any) {
    console.error(error);
    return {
      status: false,
      code: 500,
      message: "Error creating allergies.",
      details: error.message,
    };
  }
}
// updateCarePlanAllergiesAPI

export async function updateCarePlanAllergiesForPatient({
  formData,
  carePlanId,
  patientId,
}: {
  formData: any;
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  try {
    const requestData = {
      allergyDate: formatDate(formData.date),
      details: formData.details,
      id: formData.id,
    };
    Logger.logInfo("updating care plan allergies", {
      data: { requestData, carePlanId, patientId },
    });
    const createNewCarePlanResponse = await updateCarePlanAllergiesAPI({
      formData: requestData,
      carePlanId,
      patientId,
    });
    return createNewCarePlanResponse;
  } catch (error: any) {
    console.error(error);
    return {
      status: false,
      code: 500,
      message: "Error creating allergies.",
      details: error.message,
    };
  }
}

export async function createAndUpdateICDTeamNoteDetails({
  formData,
  carePlanId,
  patientId,
  type,
}: {
  formData: any;
  carePlanId: string;
  type: string;
  patientId: string;
}): Promise<any> {
  try {
    const requestData = {
      note: formData.notes,
      timeSpent: formData.timeSpent,
    };
    Logger.logInfo("creating care plan ICD team notes", {
      data: { requestData, carePlanId, patientId, type },
    });
    const carePlanList = await createAndUpdateICDTeamNotesAPI({
      formData: requestData,
      carePlanId,
      type,
      patientId,
    });
    return carePlanList;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching Updating ICD team notes.",
      details: error.message,
    };
  }
}

export async function updateCaseManagerForCarePlan({
  formData,
  patientId,
  carePlanId,
}: {
  formData: any;
  patientId: string;
  carePlanId: string;
}): Promise<any> {
  try {
    const requestData = {
      caseManagerContactNumber: formData.contactNumber,
    };
    Logger.logInfo("updating care plan case manager", {
      data: { requestData, patientId, carePlanId },
    });
    const carePlanList = await updateCaseManagerDetails({
      formData: requestData,
      patientId,
      carePlanId,
    });
    return carePlanList;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching Updating ICD team notes.",
      details: error.message,
    };
  }
}
// /createGoalForPatientAPI

export async function createGoalForPatient({
  formData,
  carePlanId,
  patientId,
}: {
  formData: any;
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  try {
    const requestObject = {
      problems: formData.problems,
      goals: formData.goal,
      barrier: formData.barrier,
      goalBarrierDueDate: formData.dueDate,
      priority: parseInt(formData.priority, 10),
      interventions: formData.interventions,
      goalInterventionsDueDate: formData.interventionDueDate,
      // reason: "", // Default value as no reason provided
    };
    Logger.logInfo("creating care plan goal", {
      data: { requestObject, carePlanId, patientId },
    });
    const carePlanList = await createGoalForPatientAPI({
      formData: requestObject,
      carePlanId,
      patientId,
    });
    return carePlanList;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching adding Care giver.",
      details: error.message,
    };
  }
}

export async function updateGoalForPatient({
  formData,
  carePlanId,
  patientId,
}: {
  formData: any;
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  try {
    const requestObject: RequestObjectForUpdateGoalsForPatient = {
      problems: formData.problems,
      goals: formData.goal,
      barrier: formData.barrier,
      goalBarrierDueDate: formData.dueDate,
      priority: parseInt(formData.priority, RADIX_VALUE_FOR_DECIMAL),
      interventions: formData.interventions,
      goalInterventionsDueDate: formData.interventionDueDate,
      id: formData.id,
    };

    if (formData.carePlanStatus) {
      requestObject.status = parseInt(
        formData.carePlanStatus,
        RADIX_VALUE_FOR_DECIMAL,
      );
    }
    if (formData.reason) {
      requestObject.reason = formData.reason;
    }
    Logger.logInfo("updating care plan goal", {
      data: { requestObject, carePlanId, patientId },
    });
    const carePlanList = await updateGoalForPatientAPI({
      formData: requestObject,
      carePlanId,
      patientId,
    });
    return carePlanList;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching updating Goals.",
      details: error.message,
    };
  }
}
//

export async function createCarePlanCareGiver({
  formData,
  carePlanId,
  patientId,
}: {
  formData: any;
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  try {
    type RequestObject = {
      [key: string]: string;
    };
    const requestObject: RequestObject = {};

    if (formData.name) {
      requestObject.careGiverName = formData.name;
    }

    if (formData.relationship) {
      requestObject.careGiverRelationship = formData.relationship;
    }

    if (formData.contactNumber) {
      requestObject.careGiverContactNumber = formData.contactNumber;
    }

    if (formData.email) {
      requestObject.email = formData.email;
    }
    Logger.logInfo("creating care plan care giver", {
      data: { requestObject, carePlanId, patientId },
    });
    const carePlanList = await createCareGiverAPI({
      formData: requestObject,
      carePlanId,
      patientId,
    });
    return carePlanList;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching adding Care giver.",
      details: error.message,
    };
  }
}
export async function updateCarePlanCareGiver({
  formData,
  carePlanId,
  patientId,
}: {
  formData: any;
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  try {
    const requestObject = {
      careGiverName: formData.name,
      careGiverelationShip: formData.relationship,
      careGiverContactNumber: formData.contactNumber,
      email: formData.email,
    };
    Logger.logInfo("updating care plan care giver", {
      data: { requestObject, carePlanId, patientId },
    });
    const carePlanList = await updateCareGiverAPI({
      formData: requestObject,
      carePlanId,
      patientId,
    });
    return carePlanList;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching Updating Care giver.",
      details: error.message,
    };
  }
}

export async function deleteCarePlanService(
  carePlanId: string | number,
): Promise<any> {
  if (!carePlanId) {
    return {
      status: false,
      code: 500,
      message: "care Plan ID is required.",
      details: "care Plan ID is required.",
    };
  }

  try {
    Logger.logInfo("deleting care plan", { data: carePlanId });
    const response = await deleteCarePlan(carePlanId);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting memo value.",
      details: error.message,
    };
  }
}

//
export async function updateCarePlanForFinalize({
  carePlanId,
}: {
  carePlanId: string;
}): Promise<any> {
  if (!carePlanId) {
    return {
      status: false,
      code: 500,
      message: "care Plan ID is required.",
      details: "care Plan ID is required.",
    };
  }

  try {
    const response = await finalizeCarePlan({ carePlanId });
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting memo value.",
      details: error.message,
    };
  }
}

export async function getMembersDetails(
  patientId: string | number,
  carePlanId: string | number = 0,
): Promise<any> {
  if (!patientId) {
    return {
      status: false,
      code: 500,
      message: "patient ID is required.",
      details: "patient ID is required.",
    };
  }

  try {
    const response = await getCarePlanMemberDetails({ patientId, carePlanId });
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting memo value.",
      details: error.message,
    };
  }
}
// getCarePlanGoalsAPI

export async function getGoalsForCarePlan({
  patientId,
  carePlanId,
}: {
  patientId: string;
  carePlanId: string;
}): Promise<any> {
  if (!patientId) {
    return {
      status: false,
      code: 500,
      message: "patient ID is required.",
      details: "patient ID is required.",
    };
  }

  try {
    const response = await getCarePlanGoalsAPI({ patientId, carePlanId });
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting memo value.",
      details: error.message,
    };
  }
}

export async function getAllMedicationOfPatent({
  paginationDataAsParameter,
  carePlanId,
  patientId,
}: {
  paginationDataAsParameter: any;
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  try {
    const carePlanList = await getAllMedicationNameForCarePlan({
      paginationDataAsParameter,
      carePlanId,
      patientId,
    });
    return carePlanList;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all Care team.",
      details: error.message,
    };
  }
}

export async function getPatientDiagnosticHistoryDetails({
  paginationDataAsParameter,
  carePlanId,
  patientId,
}: {
  paginationDataAsParameter: any;
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  try {
    const carePlanList = await getAllDiagnosticHistoryOfPatient({
      paginationDataAsParameter,
      carePlanId,
      patientId,
    });
    return carePlanList;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching Diagnostic history.",
      details: error.message,
    };
  }
}

export async function getPatientLabResults({
  paginationDataAsParameter,
  carePlanId,
  patientId,
}: {
  paginationDataAsParameter: any;
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  try {
    const carePlanList = await fetchPatientLabResults({
      paginationParams: paginationDataAsParameter,
      carePlanId,
      patientId,
    });
    return carePlanList;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching Lab Result.",
      details: error.message,
    };
  }
}

export async function getPatientDiagnosticResults({
  paginationDataAsParameter,
  carePlanId,
  patientId,
}: {
  paginationDataAsParameter: any;
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  try {
    const carePlanList = await fetchDiagnosticResults({
      paginationParams: paginationDataAsParameter,
      carePlanId,
      patientId,
    });
    return carePlanList;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching Lab Result.",
      details: error.message,
    };
  }
}

export async function getPatientICD10Values({
  carePlanId,
  patientId,
}: {
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  try {
    const carePlanList = await getCarePlanICD10Values({
      carePlanId,
      patientId,
    });
    return carePlanList;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching ICD 10 values.",
      details: error.message,
    };
  }
}

export async function getCareGiverForPatient({
  carePlanId,
  patientId,
}: {
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  try {
    const carePlanList = await getCarePlanCareGiver({
      carePlanId,
      patientId,
    });
    return carePlanList;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching Care giver values.",
      details: error.message,
    };
  }
}

export async function getCarePlanInitialRiskAssessmentService({
  paginationDataAsParameter,
  patientId,
  carePlanId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
  carePlanId: string;
}): Promise<any> {
  try {
    const carePlanIRAList = await getCarePlanInitialRiskAssessment({
      paginationDataAsParameter,
      patientId,
      carePlanId,
    });
    if (carePlanIRAList.status) {
      return carePlanIRAList.data;
    }
    return carePlanIRAList;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all Care team.",
      details: error.message,
    };
  }
}
