import React, { useState } from "react";
import { Input } from "../../../components/atom/Input/Input";
import { Button } from "../../../components/atom/Buttons/Button";
import { resetPasswordService } from "../../../services/users/users";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";

interface FormData {
  newPassword: string;
  confirmPassword: string;
}
export function ResetUserPassword() {
  const [formData, setFormData] = useState<FormData>({
    newPassword: "",
    confirmPassword: "",
  });
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleResetPassword = async () => {
    const { newPassword, confirmPassword } = formData;

    if (newPassword.length < 8 || confirmPassword.length < 8) {
      setErrorMessage("Password must be at least 8 characters long.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    const response = await resetPasswordService(formData);
    if (response.status) {
      showToastMessage(response.message, TOAST_TYPE_OPTIONS.SUCCESS);
    } else {
      showToastMessage(response.message, TOAST_TYPE_OPTIONS.ERROR);
      setErrorMessage(response.message);
    }
  };

  return (
    <div>
      <div>
        <Input
          type="password"
          id="newPassword"
          name="newPassword"
          value={formData.newPassword}
          onChange={handleChange}
          placeholder="Enter your new password"
          className="input-styling input-margin"
          label="New Password:"
          labelClassName="mb-2"
        />
      </div>
      <div>
        <Input
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          placeholder="Confirm your new password"
          className="input-styling input-margin"
          label="Confirm Password:"
          labelClassName="mb-2"
        />
      </div>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <Button
        label="Reset Password"
        onClick={handleResetPassword}
        className="button default-btn btn"
      />
    </div>
  );
}
