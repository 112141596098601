import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "../../atom/Buttons/Button";
import "./Modal.css";

export interface ModalProps {
  buttonShow: string;
  title: string;
  buttonLeft?: string | null;
  buttonRight?: string;
  onSubmit: (formData: FormData) => void;
  className?: string;
  children: React.ReactNode;
  size?: "sm" | "lg" | "xl";
  showAddUserPopup: boolean;
  setShowAddUserPopup: (status: boolean) => void;
  submitButtonLoadingStatus?: boolean;
  disableAddButton?: boolean;
  closeAction?: () => void;
  showToggleAsAddButton?: boolean;
  showToggleAsButton?: boolean;
  additionalToggleButtonClasses?: string;
  additionalResetButtonClasses?: string;
}
export function ModalComponent({
  buttonShow,
  buttonLeft,
  buttonRight,
  title,
  onSubmit,
  className,
  children,
  size,
  showAddUserPopup,
  setShowAddUserPopup,
  submitButtonLoadingStatus = false,
  closeAction,
  disableAddButton = false,
  showToggleAsAddButton,
  showToggleAsButton = true,
  additionalToggleButtonClasses,
  additionalResetButtonClasses,
}: ModalProps) {
  const [show, setShow] = useState(showAddUserPopup);

  const handleClose = () => {
    setShow(false);
    setShowAddUserPopup(false);
    if (closeAction) {
      closeAction();
    }
  };
  const handleShow = () => {
    setShow(true);
    setShowAddUserPopup(true);
  };

  const handleFormSubmit = () => {
    onSubmit(new FormData());
  };

  useEffect(() => {
    setShow(showAddUserPopup);
  }, [showAddUserPopup]);

  return (
    <div className={className} aria-hidden="true">
      {showToggleAsButton && (
        <Button
          variant="primary"
          className={`btn btn-primary btn-sm d-flex align-items-center px-2 py-1 ${additionalToggleButtonClasses}`}
          onClick={handleShow}
          label={buttonShow}
          disabled={disableAddButton}
          isAddButton={showToggleAsAddButton}
        />
      )}

      <Modal
        size={size}
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="default-modal-header">
          <Modal.Title
            title="modal-title"
            className="default-modal-header-title"
          >
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="default-modal-body">{children}</Modal.Body>
        <Modal.Footer>
          {buttonLeft && (
            <Button
              variant="secondary"
              className={`btn text-danger btn-sm ${additionalResetButtonClasses}`}
              onClick={handleClose}
              label={buttonLeft}
            />
          )}

          {buttonRight && (
            <Button
              variant="primary"
              type="submit"
              onClick={handleFormSubmit}
              className="btn btn-primary btn-sm px-5 ms-3"
              label={buttonRight}
              isLoading={submitButtonLoadingStatus}
            />
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
