import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ModalComponent } from "../../molecule/Modal/ModalComponent";
import { Input } from "../../atom/Input/Input";
import { createMemo, updateMemoServices } from "../../../services/memos/memos";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS, USER_ROLES } from "../../../constants/variables";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../services/utils/validateDataAsPerRules";
import { allKeysEmpty } from "../../../services/utils/allKeyEmpty";
import { defaultFormValues, validationRules } from "./helperFunction/constants";
import { Textarea } from "../../atom/Textarea/Textarea";
import {
  DropdownOption,
  SearchDropdown,
} from "../../atom/SearchDropdown/SearchDropdown";
import { getSearchPatientName } from "../../../services/patients/patients";

interface CreateNewMemosProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  editData: Record<string, any>;
  fetchMemoData: () => void;
}

export function CreateNewMemos({
  show,
  setShow,
  editData,
  fetchMemoData,
}: CreateNewMemosProps) {
  const [searchResultList, setSearchResultList] = useState<DropdownOption[]>(
    []
  );
  const [editFlag, setEditFlag] = useState(false);
  const [formData, setFormData] = useState({
    values: defaultFormValues,
    errors: defaultFormValues,
  });
  const loginUserDetails = useSelector((state: any) => state.userDetails);

  const { values, errors } = formData;

  const hasAddAccess = useMemo(() => {
    if (loginUserDetails?.userPermission?.roleName === USER_ROLES.caseManager) {
      return false;
    }
    return true;
  }, [loginUserDetails?.userPermission?.roleName]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    const newError = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: name,
      fieldName: name,
      value,
    });
    setFormData((prevState) => ({
      values: {
        ...prevState.values,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: newError,
      },
    }));
  };

  const resetValueAndErrors = () => {
    setFormData({
      values: defaultFormValues,
      errors: defaultFormValues,
    });
  };

  const handlePatientSelect = (option: DropdownOption) => {
    const { value } = option;
    const newError = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: "patientId",
      fieldName: "patientId",
      value,
    });

    setFormData((prevState) => ({
      values: {
        ...prevState.values,
        patientId: value,
      },
      errors: {
        ...prevState.errors,
        patientId: newError,
      },
    }));
  };

  const searchPatient = async (value: string) => {
    const patientsList = await getSearchPatientName(value);
    setSearchResultList(patientsList);
  };
  const handleSubmit = async () => {
    const formErrors = validateDataAsPerRules({
      formData: values,
      rules: validationRules,
    });
    setFormData((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        ...formErrors,
      },
    }));
    if (Object.keys(formErrors).length > 0) {
      showToastMessage(
        "Please fill all mandatory fields",
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }

    const ifAllEmpty = allKeysEmpty(formData);
    if (ifAllEmpty) {
      showToastMessage(
        "Please fill at least one field",
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }
    if (editFlag) {
      const updateMemoResponse = await updateMemoServices(values, editData.id);
      if (updateMemoResponse.status) {
        showToastMessage(
          updateMemoResponse.message,
          TOAST_TYPE_OPTIONS.SUCCESS
        );
        setShow(false);
        resetValueAndErrors();
        fetchMemoData();
        setEditFlag(false);
      } else {
        showToastMessage(updateMemoResponse.message, TOAST_TYPE_OPTIONS.ERROR);
      }
    } else {
      const createNewMemo = await createMemo(values);
      if (createNewMemo.status) {
        showToastMessage(createNewMemo.message, TOAST_TYPE_OPTIONS.SUCCESS);
        setShow(false);
        resetValueAndErrors();
        fetchMemoData();
      } else {
        showToastMessage(createNewMemo.message, TOAST_TYPE_OPTIONS.ERROR);
      }
    }
  };

  useEffect(() => {
    if (editData && editData?.id && editData?.id !== "") {
      setFormData((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          ...editData,
        },
      }));
      setShow(!show);
      setEditFlag(true);
    }
  }, [editData]);

  const handleClose = () => {
    resetValueAndErrors();
  };

  const openMemoPopup = () => {
    setShow(!show);
    resetValueAndErrors();
    setEditFlag(false);
  };

  return (
    <ModalComponent
      showAddUserPopup={show}
      setShowAddUserPopup={() => openMemoPopup()}
      title={editFlag ? "Update Memo" : "Add Memo"}
      buttonShow="+ Add Memo"
      buttonLeft={editFlag ? null : "Clear"}
      buttonRight={editFlag ? "Update & Continue" : "Save & Continue"}
      onSubmit={handleSubmit}
      className="text-end"
      size="lg"
      closeAction={handleClose}
      disableAddButton={hasAddAccess}
    >
      <form className="row g-3">
        <div className="col-lg-12 col-sm-12">
          <div className="col-lg-12 col-sm-12">
            <SearchDropdown
              onSelect={handlePatientSelect}
              searchCallback={searchPatient}
              searchField
              placeholder="Search Patient"
              options={searchResultList}
              error={errors.patientId}
              required={validationRules.patientId.required}
              label={validationRules.patientId.displayName}
              displayValue={values.patientName ? values.patientName : ""}
            />
          </div>
          <Input
            type="date"
            id="memoDate"
            name="memoDate"
            value={values.memoDate}
            onChange={handleChange}
            className="form-control form-control-sm"
            min={new Date().toISOString().split("T")[0]}
            error={errors.memoDate}
            label={validationRules.memoDate.displayName}
            required={validationRules.memoDate.required}
          />
        </div>

        <div className="col-lg-12 col-sm-12">
          <Textarea
            className="form-control form-control-sm"
            id="memoDetails"
            name="memoDetails"
            placeholder="Text"
            rows={4}
            value={values.memoDetails}
            onChange={handleChange}
            error={errors.memoDetails}
            label={validationRules.memoDetails.displayName}
            required={validationRules.memoDetails.required}
          />
        </div>
      </form>
    </ModalComponent>
  );
}
