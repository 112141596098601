import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Input } from "../../../../atom/Input/Input";
import { InputDropdown } from "../../../../atom/InputDropDown/InputDropDown";
import { Button } from "../../../../atom/Buttons/Button";
import { ExampleGoalModal } from "../ExampleGoalModal/ExampleGoalModal";
import { Textarea } from "../../../../atom/Textarea/Textarea";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
  ValidationRules,
} from "../../../../../services/utils/validateDataAsPerRules";
import { usePriorityDropdownOptions } from "./helpers/usePriorityDropDownOptions";
import {
  createGoalForPatient,
  updateGoalForPatient,
} from "../../../../../services/carePlan/carePlan";
import { formatDate } from "../../../../../services/utils/formatDate";
import { showToastMessage } from "../../../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../../../constants/variables";

export interface AddGoalProps {
  toggleAddGoalScreen: (state: boolean) => void;
  editData: any;
  setEditData: (data: any) => void;
  viewFlag: boolean;
}

const defaultFormValues = {
  problems: "",
  goal: "",
  barrier: "",
  dueDate: "",
  priority: "",
  interventions: "",
  interventionDueDate: "",
  lastModifiedOn: "",
  lastModifiedBy: "",
  createdOn: "",
  createdBy: "",
};

const validationRules: ValidationRules = {
  problems: {
    required: true,
    dataType: "text",
    displayName: "Problems",
    characterLimit: 2000,
  },
  goal: {
    required: true,
    dataType: "text",
    displayName: "Goal",
    characterLimit: 2000,
  },
  barrier: {
    required: true,
    dataType: "text",
    displayName: "Barrier",
    characterLimit: 2000,
  },
  dueDate: {
    required: true,
    dataType: "date",
    displayName: "Goal Due Date",
    isPastDateAllow: false,
  },
  priority: {
    required: true,
    dataType: "text",
    displayName: "Priority",
  },
  interventions: {
    required: true,
    dataType: "text",
    displayName: "Interventions",
  },
  interventionDueDate: {
    required: true,
    dataType: "date",
    displayName: "Due Date",
  },
  lastModifiedOn: {
    required: false,
    dataType: "date",
    displayName: "Last Modified Date",
  },
  lastModifiedBy: {
    required: false,
    dataType: "text",
    displayName: "Last Modified By",
  },
  createdOn: {
    required: false,
    dataType: "date",
    displayName: "Created Date",
  },
  createdBy: {
    required: false,
    dataType: "text",
    displayName: "Created By",
  },
};

export function AddGoal({
  toggleAddGoalScreen,
  editData,
  setEditData,
  viewFlag,
}: AddGoalProps) {
  const activePatient = useSelector((state: any) => state.activePatient);
  const activeCarePlan = useSelector((state: any) => state.activeCarePlan);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    values: defaultFormValues,
    errors: defaultFormValues,
  });
  const { priorityDropdownOptions } = usePriorityDropdownOptions();

  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = event.target;

    const fieldError = validateFieldAsPerRules({
      rules: validationRules,
      fieldName: name,
      value,
    });

    setFormData({
      values: { ...formData.values, [name]: value },
      errors: { ...formData.errors, [name]: fieldError },
    });
  };

  const handleReset = () => {
    setFormData({
      values: defaultFormValues,
      errors: defaultFormValues,
    });
    setEditData({});
    setEditMode(false);
  };

  const handleClose = () => {
    toggleAddGoalScreen(false);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formErrors = validateDataAsPerRules({
      formData: formData.values,
      rules: validationRules,
    });

    if (Object.keys(formErrors).length) {
      showToastMessage(
        "Please fill all the required fields with proper data",
        TOAST_TYPE_OPTIONS.ERROR,
      );
      setFormData({ ...formData, errors: formErrors });
      return;
    }
    let createGoalResponse;
    if (editMode) {
      const requestObject = {
        ...formData.values,
        id: editData.id,
        dueDate: formData.values.dueDate
          ? formatDate(formData.values.dueDate)
          : "",
        interventionDueDate: formData.values.interventionDueDate
          ? formatDate(formData.values.interventionDueDate)
          : "",
      };
      createGoalResponse = await updateGoalForPatient({
        formData: requestObject,
        carePlanId: activeCarePlan?.carePlanDetails?.carePlanId,
        patientId: activePatient.patientDetails.id,
      });
      handleReset();
    } else {
      createGoalResponse = await createGoalForPatient({
        formData: {
          ...formData.values,
          dueDate: formData.values.dueDate
            ? formatDate(formData.values.dueDate)
            : "",
          interventionDueDate: formData.values.interventionDueDate
            ? formatDate(formData.values.interventionDueDate)
            : "",
        },
        carePlanId: activeCarePlan?.carePlanDetails?.carePlanId,
        patientId: activePatient.patientDetails.id,
      });
      handleReset();
    }

    if (createGoalResponse.status) {
      showToastMessage(createGoalResponse.message, TOAST_TYPE_OPTIONS.SUCCESS);
      toggleAddGoalScreen(false);
    }
  };

  useEffect(() => {
    if (editData?.id) {
      setFormData({
        values: {
          problems: editData.problems,
          goal: editData.goals,
          barrier: editData.barrier,
          dueDate: formatDate(editData.goalBarrierDueDate, "YYYY-MM-DD"),
          priority: editData.priority,
          interventions: editData.interventions,
          interventionDueDate: formatDate(
            editData.goalInterventionsDueDate,
            "YYYY-MM-DD",
          ),
          createdBy: editData.createdBy,
          lastModifiedOn: editData.lastModifiedOn,
          lastModifiedBy: editData.lastModifiedBy,
          createdOn: editData.createdOn,
        },
        errors: defaultFormValues,
      });
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [editData]);

  return (
    <div className="row">
      <div className="col-12 grid-margin">
        <form className="card" onSubmit={handleSubmit}>
          <div className="card-body">
            <div className="card-title">
              <h5>Add Goal</h5>
            </div>
            <p>
              Use SMART goals. SMART goals are Specific, Measurable, Achievable,
              Realistic and Timely. Specific, Clear and specific in defining
              what exactly needs to be accomplished.
            </p>
            <ExampleGoalModal />
            <div className="row g-3 bg-info p-1 mt-3 pb-3">
              <div className="col-12">
                <Textarea
                  className="form-control form-control-sm "
                  placeholder="Problems"
                  rows={4}
                  value={formData.values.problems}
                  label={validationRules.problems.displayName}
                  error={formData.errors.problems}
                  onChange={handleChange}
                  name="problems"
                  required={validationRules.problems.required}
                  disabled={viewFlag}
                />
              </div>
              <div className="col-12">
                <Textarea
                  className="form-control form-control-sm "
                  placeholder="Goal"
                  rows={4}
                  value={formData.values.goal}
                  label={validationRules.goal.displayName}
                  error={formData.errors.goal}
                  onChange={handleChange}
                  name="goal"
                  required={validationRules.goal.required}
                  disabled={viewFlag}
                />
              </div>
              <div className="col-12">
                <Textarea
                  className="form-control form-control-sm "
                  placeholder="Barrier"
                  rows={4}
                  value={formData.values.barrier}
                  label={validationRules.barrier.displayName}
                  error={formData.errors.barrier}
                  onChange={handleChange}
                  name="barrier"
                  required={validationRules.barrier.required}
                  disabled={viewFlag}
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <Input
                  type="date"
                  className="form-control form-control-sm"
                  onChange={handleChange}
                  value={formData.values.dueDate}
                  label={validationRules.dueDate.displayName}
                  error={formData.errors.dueDate}
                  name="dueDate"
                  placeholder="Due Date"
                  required={validationRules.dueDate.required}
                  min={new Date().toISOString().split("T")[0]}
                  disabled={viewFlag}
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <InputDropdown
                  options={priorityDropdownOptions}
                  onSelect={handleChange}
                  className="form-select form-select-sm"
                  value={formData.values.priority}
                  label={validationRules.priority.displayName}
                  error={formData.errors.priority}
                  required={validationRules.priority.required}
                  name="priority"
                  disabled={viewFlag}
                />
              </div>
            </div>
          </div>

          <div className="card-body">
            <div className="card-title">
              <h5>Add Interventions</h5>
            </div>
            <p>
              Interventions are intentional action steps taken to help reach the
              specific goal in question. They, too, have a timeline of their own
              to help monitor progress toward their goal
            </p>

            <div className="row g-3 bg-info p-1 mt-3 pb-3">
              <div className="col-12">
                <Textarea
                  className="form-control form-control-sm "
                  placeholder="Interventions"
                  rows={4}
                  onChange={handleChange}
                  name="interventions"
                  value={formData.values.interventions}
                  label={validationRules.interventions.displayName}
                  error={formData.errors.interventions}
                  required={validationRules.interventions.required}
                  disabled={viewFlag}
                />
              </div>
              <div className="col-lg-6 col-sm-6">
                <Input
                  type="date"
                  className="form-control form-control-sm"
                  onChange={handleChange}
                  placeholder="Intervention Due"
                  name="interventionDueDate"
                  value={formData.values.interventionDueDate}
                  label={validationRules.interventionDueDate.displayName}
                  error={formData.errors.interventionDueDate}
                  required={validationRules.interventionDueDate.required}
                  disabled={viewFlag}
                />
              </div>
            </div>
          </div>
          <div className="card-body row">
            <div className="col-lg-4 col-sm-4">
              <Input
                className="form-control form-control-sm "
                placeholder="Created By"
                disabled
                onChange={handleChange}
                value={formData.values.createdBy}
                label={validationRules.createdBy.displayName}
                error={formData.errors.createdBy}
                name="createdBy"
              />
            </div>
            <div className="col-lg-4 col-sm-4">
              <Input
                className="form-control form-control-sm "
                placeholder="Created On"
                disabled
                onChange={handleChange}
                value={formData.values.createdOn}
                label={validationRules.createdOn.displayName}
                error={formData.errors.createdOn}
                name="createdOn"
              />
            </div>
            <div className="col-lg-4 col-sm-4">
              <Input
                className="form-control form-control-sm "
                placeholder="Last Modified By"
                disabled
                onChange={handleChange}
                value={formData.values.lastModifiedBy}
                label={validationRules.lastModifiedBy.displayName}
                error={formData.errors.lastModifiedBy}
                name="lastModifiedBy"
              />
            </div>
            <div className="col-lg-4 col-sm-4">
              <Input
                className="form-control form-control-sm "
                placeholder="Last Modified On"
                disabled
                onChange={handleChange}
                value={formData.values.lastModifiedOn}
                label={validationRules.lastModifiedOn.displayName}
                error={formData.errors.lastModifiedOn}
                name="lastModifiedOn"
              />
            </div>
          </div>
          {editData?.reason && (
            <div className="card-body row">
              <div className="fw-bold">Reason</div>
              <div>{editData.reason}</div>
            </div>
          )}

          <div className="card-footer d-flex justify-content-end">
            {editMode || viewFlag ? (
              <div />
            ) : (
              <Button
                type="reset"
                className="btn text-danger btn-sm"
                onClick={handleReset}
                label="Clear"
              />
            )}

            {viewFlag ? (
              <div />
            ) : (
              <Button
                type="submit"
                className="btn btn-primary btn-sm px-3 ms-3"
                label={editMode ? "Update & Continue" : "Save & Continue"}
              />
            )}
            <Button
              type="reset"
              className="btn text-danger btn-sm"
              onClick={handleClose}
              label="Back"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
