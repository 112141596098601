import { useEffect, useState } from "react";
import { Input } from "../../../components/atom/Input/Input";
import { InputDropdown } from "../../../components/atom/InputDropDown/InputDropDown";
import { getOptionsForDropdown } from "../../../services/dropDownMenuOptions/dropDownMenuOptions";

interface TaskSidebarProps {
  filters: any;
  setFilters: (filters: any) => void;
}

export function TaskSidebar({ filters, setFilters }: TaskSidebarProps) {
  const [taskTypeOption, setTaskTypeOption] = useState([]);

  const statusMock = [
    { value: "InComplete", label: "InComplete" },
    { value: "Complete", label: "Complete" },
    { value: "CMComplete", label: "CM Complete" },
    { value: "None", label: "None" },
  ];

  const timeTypeMock = [
    { value: "0", label: "None" },
    { value: "1", label: "current" },
    { value: "2", label: "Last Seven Days" },
    { value: "3", label: "Next Seven Days" },
    { value: "4", label: "Next Thirty Days" },
    { value: "5", label: "Next Sixty Days" },
    { value: "6", label: "More then Sixty" },
  ];
  const handleChange = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setFilters((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleOptionSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange(event);
  };

  const initData = async () => {
    const taskTypeOptionList = await getOptionsForDropdown("taskType");
    setTaskTypeOption(taskTypeOptionList);
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <div className="row g-3">
      <div className="col-12">
        <InputDropdown
          options={taskTypeOption}
          className="form-select form-select-sm"
          onSelect={handleOptionSelect}
          name="taskTypeId"
          value={filters.taskTypeId}
          label="Task Type"
        />
      </div>
      <div className="col-12">
        <InputDropdown
          options={statusMock}
          className="form-select form-select-sm"
          onSelect={handleOptionSelect}
          name="status"
          value={filters.status}
          label="Status"
        />
      </div>

      <div className="col-12">
        <InputDropdown
          options={timeTypeMock}
          className="form-select form-select-sm"
          onSelect={handleOptionSelect}
          name="timeType"
          value={filters.timeType}
          label="Time"
        />
      </div>

      <div className="col-12">
        <div className="">
          <Input
            type="search"
            id="search"
            name="search"
            value={filters.search}
            onChange={handleChange}
            placeholder="Search"
            className="form-control form-control-sm"
            label="Search"
          />
        </div>
      </div>
    </div>
  );
}
