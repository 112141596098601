import React from "react";

export interface TextareaProps {
  placeholder?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  id?: string;
  label?: string;
  name?: string;
  className: string;
  disabled?: boolean;
  ariaLabel?: string;
  ref?: React.RefObject<HTMLTextAreaElement>;
  fullWidth?: boolean;
  required?: boolean;
  error?: string;
  rows?: number;
  cols?: number;
}

export function Textarea({
  placeholder,
  value,
  onChange,
  id,
  label,
  name,
  className,
  disabled = false,
  ariaLabel,
  ref,
  fullWidth,
  required,
  error,
  rows = 3,
  cols,
}: TextareaProps) {
  return (
    <div className={`${fullWidth ? "w-100" : ""}`}>
      {label && (
        <label htmlFor={id}>
          {label} {required && <span className="text-danger"> *</span>}
        </label>
      )}
      <textarea
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        id={id}
        className={className}
        name={name}
        disabled={disabled}
        aria-label={ariaLabel}
        ref={ref}
        rows={rows}
        cols={cols}
      />
      {error && <span className="text-danger error-message">{error}</span>}
    </div>
  );
}
