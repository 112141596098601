import { Image } from "../../../components/atom/Images/Image";
import { Card } from "../../../components/atom/Cards/Cards";

export function ExpiredLinkMessage() {
  return (
    <div className="link-expired-message-section">
      <Image
        src="assets/images/login-bkg.jpg"
        alt="Image Alt Text"
        className="Login-background-image"
      />
      <Card className="w-80 card">
        <div className="card-body p-4">
          <div className="d-flex justify-content-end">
            <img
              src="/assets/images/MDTel-logo.png"
              alt="MD Tel"
              title="MD Tel"
            />
          </div>

          <div className="row">
            <div className="col-2 ms-5 pe-0">
              <img
                src="/assets/images/time.png"
                alt="MD Tel"
                title="MD Tel"
                className="w-100"
              />
            </div>
            <div className="col-6 ps-0 mt-4">
              <h1 className="fw-bold text-muted mt-4 ms-3">Link Expired!</h1>
            </div>
          </div>

          <div className="row ms-5 mb-3 mt-3">
            <h5>
              Hello! you took too long to enable your account, Please request a
              new link
            </h5>
          </div>
        </div>
      </Card>
    </div>
  );
}
