export const TOAST_TYPE_OPTIONS = {
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning",
  INFO: "info",
};
export const SIDE_MENU_ORDER = [
  "VIEW_PATIENT",
  "VIEW_INSURANCE",
  "VIEW_LAB_RESULTS",
  "MANAGE_PATIENT",
  "MANAGE_INSURANCE",
  "MANAGE_REFERRALS",
  "MANAGE_CARETEAM",
  "MANAGE_CAREMANAGER",
  "MANAGE_CASEMANAGER",
  "MANAGE_CASENOTES",

  "MANAGE_CAREPLAN",
  "MANAGE_APPOINTMENT",
  "MANAGE_TASKS",
  "MANAGE_MEMOS",

  "MANAGE_MASTER_DATA",
  "MANAGE_USER",
  "MANAGE_TIMELINES",
  "MANAGE_FORMS",
  "MANAGE_LAB_RESULTS",
];
export const ADMIN_USER_PERMISSION = [
  "VIEW_PATIENT",
  "MANAGE_REFERRALS",
  "MANAGE_PATIENT",
  "MANAGE_USER",
  "MANAGE_CAREMANAGER",
  "MANAGE_CASEMANAGER",
  "MANAGE_CASENOTES",
  "MANAGE_INSURANCE",
  "MANAGE_CAREPLAN",
  "MANAGE_APPOINTMENTS",
  "MANAGE_TASKS",
  "MANAGE_MEMOS",
  "MANAGE_MASTER_DATA",
  "MANAGE_FORMS",
  "MANAGE_LAB_RESULTS",
  "MANAGE_TIMELINES",
];

export const FILE_SIZE_MAX = 1024 * 1024 * 10;
export const USER_STATUS_ADMIN = [
  { value: "1", label: "Active" },
  { value: "0", label: "InActive" },
];
export const USER_PERMISSION_SIDE_MENU: {
  [key: string]: {
    classForIcon: string;
    url: string;
    displayName: string;
    id: string;
  };
} = {
  MANAGE_PATIENT: {
    classForIcon: "mdi mdi-account-plus-outline menu-icon",
    url: "/dashboard/patient-Info",
    displayName: "Patient",
    id: "referralsOfPatients",
  },
  MANAGE_USER: {
    classForIcon: "fa-regular fa-bed menu-icon",
    url: "/dashboard/users-list",
    displayName: "Admin",
    id: "userListDetails",
  },
  MANAGE_CAREMANAGER: {
    classForIcon: "mdi mdi-account-group-outline menu-icon",
    url: "/dashboard/care-team",
    displayName: "Care Team",
    id: "careTeam",
  },
  MANAGE_CASEMANAGER: {
    classForIcon: "mdi mdi-account-group-outline menu-icon",
    url: "/dashboard/case-manager",
    displayName: "Case Manager",
    id: "caseManagerForPatients",
  },
  MANAGE_CASENOTES: {
    classForIcon: "mdi mdi-account-group-outline menu-icon",
    url: "/dashboard/case-notes",
    displayName: "Case Notes",
    id: "caseNotesForPatients",
  },
  MANAGE_INSURANCE: {
    classForIcon: "mdi mdi-shield-plus-outline menu-icon",
    url: "/dashboard/insurance",
    displayName: "Insurance",
    id: "insuranceForThePatients",
  },
  VIEW_PATIENT: {
    classForIcon: "mdi mdi-account-plus-outline menu-icon",
    url: "/dashboard/patient-Info",
    displayName: "My Info",
    id: "viewPatientInfo",
  },
  MANAGE_REFERRALS: {
    classForIcon: "mdi mdi-account-plus-outline menu-icon",
    url: "/dashboard/referrals",
    displayName: "Referrals",
    id: "manageReferralsForPatients",
  },
  MANAGE_Insurance: {
    classForIcon: "mdi mdi-account-plus-outline menu-icon",
    url: "/dashboard/insurance",
    displayName: "Insurance",
    id: "manageInsuranceForPatients",
  },
  VIEW_INSURANCE: {
    classForIcon: "mdi mdi-account-plus-outline menu-icon",
    url: "/dashboard/insurance",
    displayName: "My Insurance",
    id: "manageInsuranceForPatients",
  },
  MANAGE_FORMS: {
    classForIcon: "fa-solid fa-file-medical menu-icon ",
    url: "/dashboard/forms-data",
    displayName: "Forms",
    id: "FormsData",
  },
  VIEW_LAB_RESULTS: {
    classForIcon: "mdi mdi-account-plus-outline menu-icon",
    url: "/dashboard/insurance",
    displayName: "My Lab Results",
    id: "manageInsuranceForPatients",
  },

  MANAGE_APPOINTMENT: {
    classForIcon: "mdi mdi-account-plus-outline menu-icon",
    url: "/dashboard/appointments",
    displayName: "Appointments",
    id: "manageAppointmentForPatient",
  },
  MANAGE_TASKS: {
    classForIcon: "mdi mdi-account-plus-outline menu-icon",
    url: "/dashboard/tasks",
    displayName: "Tasks",
    id: "TaskForCareTeam",
  },
  MANAGE_MEMOS: {
    classForIcon: "mdi mdi-account-plus-outline menu-icon",
    url: "/dashboard/memos",
    displayName: "Memos",
    id: "MemosForCareTeam",
  },
  MANAGE_MASTER_DATA: {
    classForIcon: "mdi mdi-account-plus-outline menu-icon",
    url: "/dashboard/master-data",
    displayName: "Master Data",
    id: "MasterData",
  },
  MANAGE_LAB_RESULTS: {
    classForIcon: "mdi mdi-square-edit-outline menu-icon",
    url: "/dashboard/lab-result-data",
    displayName: "Lab Result/Diagnostic Result",
    id: "LabData",
  },
  MANAGE_PATIENT_QUICK_ADD: {
    classForIcon: "mdi mdi-account-plus-outline menu-icon",
    url: "/dashboard/quick-add",
    displayName: "Patient Quick Add",
    id: "MasterData",
  },
  MANAGE_CAREPLAN: {
    classForIcon: "mdi mdi-account-plus-outline menu-icon",
    url: "/dashboard/care-plan",
    displayName: "Care Plan",
    id: "manageCarePlanForPatients",
  },
  MANAGE_CARETEAM: {
    classForIcon: "mdi mdi-account-plus-outline menu-icon",
    url: "/dashboard/care-team",
    displayName: "Care Team",
    id: "manageCarePlanForPatients",
  },
  MANAGE_TIMELINES: {
    classForIcon: "fa-solid fa-stopwatch menu-icon",
    url: "/dashboard/manage-time-line",
    displayName: "TimeLine",
    id: "manageTimeLineForPatients",
  },
};

export const USER_ROLES = {
  superAdmin: "SUPER_ADMIN",
  admin: "ADMIN",
  caseManager: "CASE MANAGER",
};
export const MODULE_NAME_OPTION = {
  careTeam: "CARE_TEAM",
  careManager: "CASE_MANAGER",
};

export const STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
};

export const CASE_MANAGER_ROLE_NAME = "CASE MANAGER";
export const PATIENT_ROLE_NAME = "PATIENT";
export const MANAGE_PATIENT_QUICK_ADD = "MANAGE_PATIENT_QUICK_ADD";
export const TASK_COMPLETE_STATUS = "Complete";
export const TASK_CM_COMPLETE_STATUS = "CMComplete";
export const RADIX_VALUE_FOR_DECIMAL = 10;
