import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "../../../components/atom/Table/Table";
import { Card } from "../../../components/atom/Cards/Cards";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import {
  getAllDiagnosticResultServices,
  deleteDiagnosticResultService,
  getDiagnosticResultByIdService,
} from "../../../services/labAndDiagnosticResult/diagnosticResult";
import { Pagination } from "../../../components/atom/Pagination/Pagination";
import { Button } from "../../../components/atom/Buttons/Button";
import { CreateDiagnosticResult } from "../../../components/organism/CreateDiagnosticResult/CreateDiagnosticResult";
import { FullScreenLoader } from "../../../components/atom/FullScreenLoader/FullScreenLoader";
import { getIcd10InfoService } from "../../../services/referrals/referrals";

interface DiagnosticResultProps {
  displayLabel: string;
}
export function DiagnosticResult({ displayLabel }: DiagnosticResultProps) {
  interface CareTeamListType {
    patientId: string;
    patientName: string;
    documentName: string;
    diagnosticResult: string;
    diagnosedDate: string;
    providerName: string;
    comment: string;
  }

  const [showTable, setShowTable] = useState(true);
  const [showFullScreenLoaderStatus, setShowFullScreenLoaderStatus] =
    useState(false);
  const activePatient = useSelector((state: any) => state.activePatient);
  const [DiagnosticResultDataForTable, setDiagnosticResultDataForTable] =
    useState<CareTeamListType[]>([]);
  const [editData, setEditData] = useState();

  const [paginationData, setPaginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });
  const headersForTable: Record<string, string> = {
    patientName: "Patient Name",
    documentName: "Document Name",
    diagnosticResult: "Diagnostic Test",
    diagnosedDate: "Date",
    providerName: "Provider",
    comment: "Comment",
    action: "Action",
  };
  const sortingHeaderMap: Record<string, string> = {
    patientName: "patientName",
    documentName: "documentName",
    diagnosticResult: "diagnosticResult",
    diagnosedDate: "date",
    providerName: "providerName",
    comment: "comment",
  };

  const toggleTableStatus = () => {
    setShowTable(!showTable);
  };

  const mapDiagnosticIcd10 = async (diagnosticResult: number) => {
    try {
      const response = await getIcd10InfoService(diagnosticResult);
      if (response.status && response.data) {
        const { code, shortDescription } = response.data;
        return `${code} - ${shortDescription}`;
      }
      return "";
    } catch (error) {
      console.error(
        `Error fetching ICD-10 info for Diagnostic Result  ${diagnosticResult}:`,
        error,
      );
      return "";
    }
  };

  const processDiagnosticTest = async (data: CareTeamListType[]) => {
    try {
      const processedData = await Promise.all(
        data.map(async (diagnosticTest) => {
          try {
            const label = await mapDiagnosticIcd10(
              JSON.parse(diagnosticTest.diagnosticResult),
            );
            return {
              ...diagnosticTest,
              diagnosticResult: label,
            };
          } catch (error) {
            console.error("Error processing diagnostic test:", error);
            return {
              ...diagnosticTest,
              diagnosticResult: "Error processing result",
            };
          }
        }),
      );
      setDiagnosticResultDataForTable(processedData);
    } catch (error) {
      console.error("Error setting diagnostic result data for table:", error);
    }
  };
  const getAllDiagnosticResultDataForTable = async (
    newPaginationData?: any,
  ) => {
    try {
      const paginationDataAsParameter = newPaginationData || paginationData;
      const patientId = activePatient.patientDetails.id;
      if (patientId !== "") {
        const diagnosticResultListResponse =
          await getAllDiagnosticResultServices({
            paginationDataAsParameter,
            patientId,
          });
        if (diagnosticResultListResponse.isSuccess) {
          setPaginationData({
            ...paginationData,
            pageNo: diagnosticResultListResponse.pageNo,
            totalPages: diagnosticResultListResponse.totalPages,
            totalElements: diagnosticResultListResponse.totalElements,
            pageSize: diagnosticResultListResponse.pageSize,
          });
          processDiagnosticTest(diagnosticResultListResponse.dataList);
        } else {
          processDiagnosticTest(diagnosticResultListResponse.dataList);
        }
      }
    } catch (error) {
      showToastMessage("Error fetching all users:", TOAST_TYPE_OPTIONS.ERROR);
      console.error("Error fetching all users:", error);
    }
  };
  const deleteDiagnosticResultValue = async (rowData: any) => {
    const deleteDiagnosticResultResponse = await deleteDiagnosticResultService(
      rowData.id,
    );
    if (deleteDiagnosticResultResponse.status) {
      showToastMessage(
        deleteDiagnosticResultResponse.message,
        TOAST_TYPE_OPTIONS.SUCCESS,
      );
      getAllDiagnosticResultDataForTable();
    } else {
      showToastMessage(
        deleteDiagnosticResultResponse.message,
        TOAST_TYPE_OPTIONS.ERROR,
      );
    }
  };

  const editRowAction = async (rowData: any) => {
    setShowFullScreenLoaderStatus(true);
    const diagnosticResultData = await getDiagnosticResultByIdService(
      rowData.id,
    );
    setShowFullScreenLoaderStatus(false);
    if (diagnosticResultData.status) {
      setEditData(diagnosticResultData.data);
      toggleTableStatus();
    } else {
      setEditData(undefined);
      showToastMessage(diagnosticResultData.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };
  useEffect(() => {
    getAllDiagnosticResultDataForTable();
  }, []);

  useEffect(() => {
    if (!activePatient.patientDetails.id) {
      processDiagnosticTest([]);
    }
  }, [activePatient]);

  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize,
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    getAllDiagnosticResultDataForTable({ ...paginationData, pageNo, pageSize });
  };

  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    getAllDiagnosticResultDataForTable({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };

  const onSubmitAction = () => {
    setShowTable(true);
    getAllDiagnosticResultDataForTable();
    setEditData(undefined);
  };
  const addDiagnosticButtonAction = () => {
    setEditData(undefined);
    toggleTableStatus();
  };
  return (
    <div>
      <FullScreenLoader show={showFullScreenLoaderStatus} />
      <Card>
        <div className="card-title d-flex justify-content-between align-items-center">
          <h5>{displayLabel}</h5>
          <div className="d-flex text-end">
            {showTable ? (
              <Button
                variant="primary"
                className="primary btn btn-primary d-flex align-items-center  py-1 float-end btn-sm px-5 ms-3"
                onClick={addDiagnosticButtonAction}
              >
                <i className="fa fa-plus me-2 fs-5" /> Add Diagnostic Result
              </Button>
            ) : (
              <Button
                variant="primary"
                className="primary btn btn-primary d-flex align-items-center  py-1 float-end btn-sm px-5 ms-3"
                onClick={toggleTableStatus}
                label="Back"
              />
            )}
          </div>
        </div>

        {showTable ? (
          <div className="table-responsive care-manager-table">
            <Table
              className="table table-bordered table-striped table-hover"
              data={DiagnosticResultDataForTable}
              headers={headersForTable}
              enableDeleteAction={deleteDiagnosticResultValue}
              enableEditAction={(rowData) => {
                editRowAction(rowData);
              }}
              sortingHeaderMap={sortingHeaderMap}
              sortingFunction={sortingFunction}
            />
            {paginationData.totalPages >= 0 && (
              <Pagination
                currentPage={paginationData.pageNo}
                totalPages={paginationData.totalPages}
                pageSize={paginationData.pageSize}
                onPageChange={(page, pageSize) => {
                  updatePaginationData(page, pageSize);
                }}
              />
            )}
          </div>
        ) : (
          <CreateDiagnosticResult
            onSubmitAction={onSubmitAction}
            editData={editData}
          />
        )}
      </Card>
    </div>
  );
}
