/* eslint-disable complexity */
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ModalComponent } from "../../molecule/Modal/ModalComponent";
import { InputDropdown } from "../../atom/InputDropDown/InputDropDown";
import { Input } from "../../atom/Input/Input";
import {
  getAllCountryAndStates,
  getOptionsForDropdown,
} from "../../../services/dropDownMenuOptions/dropDownMenuOptions";

import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";

import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../services/utils/validateDataAsPerRules";
import {
  CreateCareTeamMemberRecord,
  createNewCareTeamMember,
} from "../../../services/careTeam/careTeam";
import { displayTitleForModal } from "./helperFunctions/displayTitleForModal";
import { defaultFormValues, validationRules } from "./helperFunctions/constant";

interface AddCareTeamAndCaseManagerProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  editData: Record<string, any>;
  fetchCaseManagerData: () => void;
  moduleName: string;
  closePopup: () => void;
}

export function AddCareTeamAndCaseManager({
  show,
  setShow,
  editData,
  fetchCaseManagerData,
  moduleName,
  closePopup,
}: AddCareTeamAndCaseManagerProps) {
  const activePatient = useSelector((state: any) => state.activePatient);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [statusOption, setStatusOption] = useState([]);
  const [providerTypeOptionList, setProviderTypeOptionList] = useState([]);
  const [formData, setFormData] = useState({
    values: defaultFormValues,
    errors: defaultFormValues,
  });
  const { values, errors } = formData;

  const [countryMasterData, setCountryMasterData] = useState([]);
  const [stateMasterData, setStateMasterData] = useState([]);
  const [cityMasterData, setCityMasterData] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState<any>([]);
  const [zipCodeList, setZipCodeList] = useState([]);
  const [editFlag, setEditFlag] = useState(false);

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;

    const newErrors = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: name,
      fieldName: name,
      value,
    });
    setFormData((prevState) => ({
      values: {
        ...prevState.values,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: newErrors,
      },
    }));
  };

  const checkForEditFlow = async () => {
    if (editData) {
      if (editData.country) {
        const countryListMaster: any = countryMasterData.find(
          (country: any) => country.countryName === editData?.country
        );
        if (countryListMaster) {
          const stateListOptions = countryListMaster.states.map(
            (state: { stateName: string }) => ({
              label: state.stateName,
              value: state.stateName,
            })
          );
          setStateMasterData(countryListMaster.states);
          setStateList(stateListOptions);

          const stateListMaster: any = countryListMaster.states.find(
            (state: { stateName: string }) => state.stateName === editData.state
          );

          const cityListOptions = stateListMaster.cities.map(
            (city: { cityName: string }) => ({
              label: city.cityName,
              value: city.cityName,
            })
          );
          setCityList(cityListOptions);
          if (stateListMaster.cities) {
            const cityListMaster: any = stateListMaster.cities.find(
              (city: { cityName: string }) => city.cityName === editData.city
            );
            if (cityListMaster.zipCodes) {
              const zipCodeListOptions = cityListMaster.zipCodes.map(
                (zipCode: string) => ({
                  label: zipCode,
                  value: zipCode,
                })
              );

              setZipCodeList(zipCodeListOptions);
            }
          }
        }
      }
      setFormData({
        ...formData,
        values: {
          title: editData.title,
          firstName: editData.firstName,
          lastName: editData.lastName,
          organization: editData.organization,
          providerType: editData.providerType,
          address1: editData.address1,
          address2: editData.address2,
          zipCode: editData.zipCode,
          country: editData.country,
          state: editData.state,
          city: editData.city,
          officeNumber: editData.officeNumber,
          contactNumber: editData.contactNumber,
          email: editData.email,
          fax: editData.fax,
          status: editData.status,
          fromDate: editData.fromDate,
          endDate: editData.endDate,
          speciality: editData.speciality,
          practice: editData.practice,
          taxonomy: editData.taxonomy,
          npi: editData.npi,
          upin: editData.upin,
          notes: editData.notes,
        },
      });
      setShow(!show);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const countryListResponse = await getAllCountryAndStates();
      setCountryMasterData(countryListResponse);

      const countryOptions = countryListResponse.reduce(
        (
          result: { label: string; value: string }[],
          item: { countryName: string }
        ) => {
          result.push({ label: item.countryName, value: item.countryName });
          return result;
        },
        []
      );
      setCountryList(countryOptions);
      checkForEditFlow();
    };
    fetchData();
  }, []);

  useEffect(() => {
    checkForEditFlow();
  }, [editData]);

  const handleCityChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    if (name === "city") {
      if (!value || value === "") {
        setFormData((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            city: "Please select a city",
          },
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            city: "",
          },
        }));
        const cityListMaster: any = cityMasterData.find(
          (cities: any) => cities.cityName === e.target.value
        );
        const zipCodeListOptions = cityListMaster.zipCodes.map(
          (zipCode: string) => ({
            label: zipCode,
            value: zipCode,
          })
        );

        setZipCodeList(zipCodeListOptions);
        setFormData((prevState) => ({
          ...prevState,
          values: {
            ...prevState.values,
            city: e.target.value,
            zipCode: cityListMaster.zipCode,
          },
        }));
      }
    }
  };

  const handleStateChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;

    if (name === "state") {
      if (!value || value === "") {
        setFormData((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            state: "Please select a state",
          },
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            state: "",
          },
        }));
        setFormData((prevState) => ({
          ...prevState,
          values: {
            ...prevState.values,
            state: e.target.value,
            city: "",
            zipCode: "",
          },
        }));
        const cityListMaster: any = stateMasterData.find(
          (state: any) => state.stateName === e.target.value
        );
        if (cityListMaster?.cities) {
          const cityListOptions = cityListMaster.cities.map(
            (city: { cityName: string }) => ({
              label: city.cityName,
              value: city.cityName,
            })
          );
          setCityMasterData(cityListMaster.cities);
          setCityList(cityListOptions);
        } else {
          setCityList([]);
        }
      }
    }
  };

  const handleCountryChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    if (name === "country") {
      if (!value || value === "") {
        setFormData((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            country: "Please select a country",
          },
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            country: "",
          },
        }));

        setFormData((prevState) => ({
          ...prevState,
          values: {
            ...prevState.values,
            country: e.target.value,
            zipCode: "",
            state: "",
            city: "",
          },
        }));
        const stateListMaster: any = countryMasterData.find(
          (country: any) => country.countryName === e.target.value
        );
        setStateMasterData(stateListMaster.states);
        if (stateListMaster) {
          const stateListOptions = stateListMaster.states.map(
            (state: { stateName: string }) => ({
              label: state.stateName,
              value: state.stateName,
            })
          );
          setStateList(stateListOptions);
        } else {
          setStateList([]);
        }
      }
      setFormData((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          country: "",
        },
      }));
    }
  };

  const resetErrorAndValue = () => {
    setFormData({ values: defaultFormValues, errors: defaultFormValues });
  };
  const isCaseManager = useMemo(() => {
    if (moduleName === "CASE_MANAGER") {
      return true;
    }
    return false;
  }, [moduleName]);

  const fetchData = async () => {
    const statusOptionList = await getOptionsForDropdown("CareProviderStatus");
    setStatusOption(statusOptionList);
    const dropValueOptionForProviderType = isCaseManager
      ? "CaseManagerProviderType"
      : "providerType";
    const providerTypeOptions = await getOptionsForDropdown(
      dropValueOptionForProviderType
    );
    setProviderTypeOptionList(providerTypeOptions);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleSubmit = async () => {
    const formErrors = validateDataAsPerRules({
      formData: formData.values,
      rules: validationRules,
    });
    setFormData((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        ...formErrors,
      },
    }));
    if (Object.keys(formErrors).length > 0) {
      showToastMessage("Please fill correct value", TOAST_TYPE_OPTIONS.ERROR);
      return;
    }
    const requestParameter: CreateCareTeamMemberRecord = {
      ...values,
      title: values.title,
      firstName: values.firstName,
      lastName: values.lastName,
      organization: values.organization,
      providerTypeId: values.providerType,
      address1: values.address1,
      address2: values.address2,
      zipCode: values.zipCode,
      country: values.country,
      state: values.state,
      city: values.city,
      officeNumber: values.officeNumber,
      contactNumber: values.contactNumber,
      fax: values.fax,
      email: values.email,
      status: values.status,
      fromDate: values.fromDate,
      endDate: values.endDate,
      notes: values.notes,
      patientId: activePatient.patientDetails.id,
      moduleName,
    };
    let response: {
      status?: boolean;
      message?: string;
      data?: any;
    } = {};
    response = await createNewCareTeamMember(requestParameter);
    if (response.status) {
      showToastMessage(response.message, TOAST_TYPE_OPTIONS.SUCCESS);
      resetErrorAndValue();
      setShow(!show);
      fetchCaseManagerData();
      setShowModal(!showModal);
      closePopup();
    } else {
      showToastMessage(response.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  const resetValueAndErrors = () => {
    resetErrorAndValue();
    setEditFlag(false);
  };
  const handleClose = () => {
    resetValueAndErrors();
  };
  const addButtonAction = () => {
    resetValueAndErrors();
    setShowModal(!showModal);
  };

  return (
    <ModalComponent
      showAddUserPopup={showModal}
      setShowAddUserPopup={addButtonAction}
      title={displayTitleForModal({ editFlag, moduleName })}
      buttonShow={displayTitleForModal({ editFlag, moduleName })}
      buttonLeft="Clear"
      buttonRight="Save & Continue"
      onSubmit={handleSubmit}
      className="text-end"
      size="lg"
      closeAction={handleClose}
    >
      <form>
        <div className="row g-3">
          <div className="col-lg-2 col-sm-6">
            <Input
              type="text"
              id="title"
              name="title"
              value={values.title}
              onChange={handleChange}
              placeholder="Title"
              className="form-control form-control-sm"
              error={errors.title}
              required={validationRules.title.required}
              label={validationRules.title.displayName}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Input
              type="text"
              id="firstName"
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              placeholder="Enter First Name"
              className="form-control form-control-sm"
              error={errors.firstName}
              label={validationRules.firstName.displayName}
              required={validationRules.firstName.required}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Input
              type="text"
              id="lastName"
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              placeholder="Enter Last Name"
              className="form-control form-control-sm"
              error={errors.lastName}
              label={validationRules.lastName.displayName}
              required={validationRules.lastName.required}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Input
              type="text"
              id="organization"
              name="organization"
              value={values.organization}
              onChange={handleChange}
              placeholder="Enter First Name"
              className="form-control form-control-sm"
              error={errors.organization}
              label={validationRules.organization.displayName}
              required={validationRules.organization.required}
            />
          </div>
          <div className="col-lg-4 col-sm-4">
            <InputDropdown
              options={providerTypeOptionList}
              className="form-select form-select-sm"
              onSelect={handleChange}
              value={values.providerType}
              name="providerType"
              label={validationRules.providerType.displayName}
              error={errors.providerType}
              required={validationRules.providerType.required}
            />
          </div>
          <div className="col-lg-4 col-sm-4">
            <Input
              type="address1"
              id="address1"
              name="address1"
              value={values.address1}
              onChange={handleChange}
              placeholder="Address 1"
              className="form-control form-control-sm"
              error={errors.address1}
              label={validationRules.address1.displayName}
              required={validationRules.address1.required}
            />
          </div>
          <div className="col-lg-4 col-sm-4">
            <Input
              type="address2"
              id="address2"
              name="address2"
              value={values.address2}
              onChange={handleChange}
              placeholder="Address 2"
              className="form-control form-control-sm"
              label={validationRules.address2.displayName}
              error={errors.address2}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <InputDropdown
              options={countryList}
              className="form-select form-select-sm"
              onSelect={handleCountryChange}
              name="country"
              value={values.country}
              label={validationRules.country.displayName}
              error={errors.country}
              required={validationRules.country.required}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <InputDropdown
              options={stateList}
              className="form-select form-select-sm"
              onSelect={handleStateChange}
              name="state"
              value={values.state}
              label={validationRules.state.displayName}
              error={errors.state}
              required={validationRules.state.required}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <InputDropdown
              options={cityList}
              className="form-select form-select-sm"
              onSelect={handleCityChange}
              name="city"
              value={values.city}
              label={validationRules.city.displayName}
              error={errors.city}
              required={validationRules.city.required}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <InputDropdown
              options={zipCodeList}
              className="form-select form-select-sm"
              onSelect={handleChange}
              name="zipCode"
              value={values.zipCode}
              label={validationRules.zipCode.displayName}
              error={errors.zipCode}
              required={validationRules.zipCode.required}
            />
          </div>
          <div className="col-lg-3 col-sm-3">
            <Input
              type="text"
              value={values.contactNumber}
              onChange={handleChange}
              className="form-control form-control-sm"
              id="from-date"
              placeholder="xxx-xxx-xxxx"
              label={validationRules.contactNumber.displayName}
              error={errors.contactNumber}
              required={validationRules.contactNumber.required}
              name="contactNumber"
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Input
              type="email"
              id="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              placeholder="Email"
              className="form-control form-control-sm"
              label={validationRules.email.displayName}
              error={errors.email}
              required={validationRules.email.required}
            />
          </div>
          <div className="col-lg-3 col-sm-6">
            <Input
              type="number"
              id="fax"
              name="fax"
              value={values.fax}
              onChange={handleChange}
              placeholder="xxx-xxx-xxxx"
              className="form-control form-control-sm"
              label={validationRules.fax.displayName}
              error={errors.fax}
              required={validationRules.fax.required}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <InputDropdown
              options={statusOption}
              className="form-select form-select-sm"
              onSelect={handleChange}
              value={values.status}
              name="status"
              label={validationRules.status.displayName}
              error={errors.status}
              required={validationRules.status.required}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Input
              type="date"
              id="fromDate"
              name="fromDate"
              value={values.fromDate}
              onChange={handleChange}
              className="form-control form-control-sm"
              min={new Date().toISOString().split("T")[0]}
              label={validationRules.fromDate.displayName}
              error={errors.fromDate}
              required={validationRules.fromDate.required}
            />
          </div>
          <div className="col-lg-4 col-sm-6">
            <Input
              type="date"
              id="endDate"
              name="endDate"
              value={values.endDate}
              onChange={handleChange}
              className="form-control form-control-sm"
              min={new Date().toISOString().split("T")[0]}
              label={validationRules.endDate.displayName}
              error={errors.endDate}
              required={validationRules.endDate.required}
            />
          </div>
          {!isCaseManager && (
            <>
              <div className="col-lg-2 col-sm-3">
                <Input
                  type="text"
                  id="speciality"
                  name="speciality"
                  value={values.speciality}
                  onChange={handleChange}
                  placeholder="Speciality"
                  className="form-control form-control-sm"
                  label={validationRules.speciality.displayName}
                  error={errors.speciality}
                  required={validationRules.speciality.required}
                />
              </div>
              <div className="col-lg-2 col-sm-3">
                <Input
                  type="text"
                  id="practice"
                  name="practice"
                  value={values.practice}
                  onChange={handleChange}
                  placeholder="Practice"
                  className="form-control form-control-sm"
                  label={validationRules.practice.displayName}
                  error={errors.practice}
                  required={validationRules.practice.required}
                />
              </div>
              <div className="col-lg-2 col-sm-3">
                <Input
                  type="text"
                  id="taxonomy"
                  name="taxonomy"
                  value={values.taxonomy}
                  onChange={handleChange}
                  placeholder="Taxonomy"
                  className="form-control form-control-sm"
                  label={validationRules.taxonomy.displayName}
                  error={errors.taxonomy}
                  required={validationRules.taxonomy.required}
                />
              </div>

              <div className="col-lg-2 col-sm-3">
                <Input
                  type="text"
                  id="upin"
                  name="upin"
                  value={values.upin}
                  onChange={handleChange}
                  placeholder="uPin"
                  className="form-control form-control-sm"
                  label={validationRules.upin.displayName}
                  error={errors.upin}
                  required={validationRules.upin.required}
                />
              </div>
              <div className="col-lg-4 col-sm-4">
                <Input
                  type="text"
                  id="npi"
                  name="npi"
                  value={values.npi}
                  onChange={handleChange}
                  placeholder="NPI"
                  className="form-control form-control-sm"
                  label={validationRules.npi.displayName}
                  error={errors.npi}
                  required={validationRules.npi.required}
                />
              </div>
            </>
          )}
          <div className="col-lg-12 col-sm-12">
            <textarea
              className="form-control form-control-sm"
              id="notes"
              name="notes"
              placeholder="Note"
              rows={4}
              value={values.notes}
              onChange={handleChange}
            />
          </div>
        </div>
      </form>
    </ModalComponent>
  );
}
