import {
  ServiceResponse,
  generateErrorResponse,
} from "./generateErrorResponse";

function isValidName(name: string): boolean {
  return /^[a-zA-Z\s]+$/.test(name);
}

export function validateOnlyAlphabets(
  name: string,
  fieldName: string,
  isMandatory: boolean = true,
  characterLimit: number = 50,
): ServiceResponse {
  if (isMandatory && !name) {
    return generateErrorResponse(`${fieldName} is required.`, 400);
  }

  if (name.length > characterLimit) {
    return generateErrorResponse(`You have exceeded the char limit.`, 400);
  }

  if (name && !isValidName(name)) {
    return generateErrorResponse(
      `${fieldName} should only contain alphabets.`,
      400,
    );
  }

  return { status: true };
}
