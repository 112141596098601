/* eslint-disable complexity */
import { STATUS_CODES } from "../../constants/variables";
import { API_ENDPOINTS } from "../../constants/apiList";
import ApiCaller from "../../utils/apiCaller";

export interface CreateReferralParams {
  referral: string;
  referredTo: string;
  referredBy: string;
  effectiveDate?: string;
  icd10: string;
  authorizationId: string;
  organization: string;
  maxAuthorization?: string;
  referralReason: string;
  addNotes: string;
  referralStatus: string;
  procedureCode: string;
  authStatus: string;
  insCarrier: string;
  referralType: string;
  patientId: string;
}

export async function getAllReferralsIn(
  paginationDataAsParameter: any,
  patientId: string,
): Promise<any> {
  const { pageNo, pageSize, sortingFieldName, sortingOrder } =
    paginationDataAsParameter;
  let getReferralUrl = `${API_ENDPOINTS.getAllReferralsIn}/${patientId}?pageNo=${pageNo}&pageSize=${pageSize}`;
  if (
    sortingFieldName &&
    sortingFieldName !== "" &&
    sortingOrder &&
    sortingOrder !== ""
  ) {
    getReferralUrl = `${getReferralUrl}&sortBy=${sortingFieldName}&sortDir=${sortingOrder}`;
  }
  try {
    const response: { isSuccess: boolean; responseMsg?: Array<string> } =
      await ApiCaller.get(getReferralUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Referral received successfully",
        data: response,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getReferralInDetails({
  paginationDataAsParameter,
  patientId,
}: any): Promise<any> {
  const { pageNo, pageSize, sortingFieldName, sortingOrder } =
    paginationDataAsParameter;
  let getReferralInUrl = `${API_ENDPOINTS.getAllReferralsIn}${patientId}?pageNo=${pageNo}&pageSize=${pageSize}`;
  if (sortingFieldName !== "" && sortingOrder !== "") {
    getReferralInUrl = `${getReferralInUrl}&sortBy=${sortingFieldName}&sortDir=${sortingOrder}`;
  }
  try {
    const response: { isSuccess: boolean; responseMsg?: Array<string> } =
      await ApiCaller.get(getReferralInUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Referral received successfully",
        data: response,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getReferralInByIdDetails(
  referralId: string | number,
): Promise<any> {
  const getReferralInByIdUrl = `${API_ENDPOINTS.getReferralsInById}/${referralId}`;
  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(getReferralInByIdUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Data Retrieved Successfully for Referral In By Id",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMsg?.[0] || "Failed to Retrieve Referral In By Id",
      data: response.data,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function filterReferralInAPI({
  paginationDataAsParameter,
  patientId,
  filters,
}: {
  paginationDataAsParameter: any;
  patientId: any;
  filters: {
    authorizationId?: string;
    organization?: string;
    fromDate?: string;
    toDate?: string;
    referredBy?: string;
    referredTo?: string;
    status?: string;
  };
}): Promise<any> {
  const { pageNo, pageSize, sortingFieldName, sortingOrder } =
    paginationDataAsParameter;
  let filterParams = "";
  if (filters.authorizationId !== undefined) {
    filterParams += filterParams
      ? `&authorizationId=${filters.authorizationId}`
      : `authorizationId=${filters.authorizationId}`;
  }
  if (filters.organization !== undefined) {
    filterParams += filterParams
      ? `&organization=${filters.organization}`
      : `organization=${filters.organization}`;
  }
  if (filters.fromDate !== undefined) {
    filterParams += filterParams
      ? `&fromDate=${filters.fromDate}`
      : `fromDate=${filters.fromDate}`;
  }
  if (filters.toDate !== undefined) {
    filterParams += filterParams
      ? `&toDate=${filters.toDate}`
      : `toDate=${filters.toDate}`;
  }
  if (filters.referredBy !== undefined) {
    filterParams += filterParams
      ? `&referredBy=${filters.referredBy}`
      : `referredBy=${filters.referredBy}`;
  }
  if (filters.referredTo !== undefined) {
    filterParams += filterParams
      ? `&referredTo=${filters.referredTo}`
      : `referredTo=${filters.referredTo}`;
  }
  if (filters.status) {
    filterParams += filterParams
      ? `&status=${filters.status}`
      : `status=${filters.status}`;
  }
  let getAllReferralInUrl = `${API_ENDPOINTS.getAllReferralsIn}/${patientId}`;
  if (filterParams) {
    getAllReferralInUrl += `?${filterParams}`;
  }
  if (
    sortingFieldName &&
    (sortingFieldName !== "" || sortingFieldName !== undefined) &&
    sortingOrder &&
    (sortingOrder !== "" || sortingOrder !== undefined)
  ) {
    getAllReferralInUrl += `&sortBy=${sortingFieldName}&sortDir=${sortingOrder}`;
  }
  if (pageNo >= 0 && pageSize) {
    getAllReferralInUrl += `&pageNo=${pageNo}&pageSize=${pageSize}`;
  }
  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(getAllReferralInUrl);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Filtered Referral In Data Retrieved Successfully",
        data: response,
      };
    }
    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMsg?.[0] || "Failed to Retrieve Referral In Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getAllReferralsOut({
  paginationDataAsParameter,
  patientId,
  filters,
}: {
  paginationDataAsParameter: any;
  patientId: any;
  filters: {
    authorizationId?: string;
    organization?: string;
    fromDate?: string;
    toDate?: string;
    referredBy?: string;
    referredTo?: string;
    status?: string;
  };
}): Promise<any> {
  const { pageNo, pageSize, sortingFieldName, sortingOrder } =
    paginationDataAsParameter;
  let filterParams = "";
  if (filters.authorizationId !== undefined) {
    filterParams += filterParams
      ? `&authorizationId=${filters.authorizationId}`
      : `authorizationId=${filters.authorizationId}`;
  }
  if (filters.organization !== undefined) {
    filterParams += filterParams
      ? `&organization=${filters.organization}`
      : `organization=${filters.organization}`;
  }
  if (filters.fromDate !== undefined) {
    filterParams += filterParams
      ? `&fromDate=${filters.fromDate}`
      : `fromDate=${filters.fromDate}`;
  }
  if (filters.toDate !== undefined) {
    filterParams += filterParams
      ? `&toDate=${filters.toDate}`
      : `toDate=${filters.toDate}`;
  }
  if (filters.referredBy !== undefined) {
    filterParams += filterParams
      ? `&referredBy=${filters.referredBy}`
      : `referredBy=${filters.referredBy}`;
  }
  if (filters.referredTo !== undefined) {
    filterParams += filterParams
      ? `&referredTo=${filters.referredTo}`
      : `referredTo=${filters.referredTo}`;
  }
  if (filters.status) {
    filterParams += filterParams
      ? `&status=${filters.status}`
      : `status=${filters.status}`;
  }
  let getAllReferralInUrl = `${API_ENDPOINTS.getAllReferralsOut}/${patientId}`;
  if (filterParams) {
    getAllReferralInUrl += `?${filterParams}`;
  }
  if (
    sortingFieldName &&
    (sortingFieldName !== "" || sortingFieldName !== undefined) &&
    sortingOrder &&
    (sortingOrder !== "" || sortingOrder !== undefined)
  ) {
    getAllReferralInUrl += `&sortBy=${sortingFieldName}&sortDir=${sortingOrder}`;
  }
  if (pageNo >= 0 && pageSize) {
    getAllReferralInUrl += `&pageNo=${pageNo}&pageSize=${pageSize}`;
  }
  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(getAllReferralInUrl);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Filtered Referral In Data Retrieved Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMsg?.[0] || "Failed to Retrieve Referral In Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
export async function deleteReferralInDetails(
  referralId: number,
): Promise<any> {
  const deleteReferralInDetailsUrl = `${API_ENDPOINTS.deleteReferralIn}/${referralId}`;
  try {
    const response = await ApiCaller.delete<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(deleteReferralInDetailsUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Referral Info deleted Successfully",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
export async function deleteReferralOutDetails(
  referralId: number,
): Promise<any> {
  const deleteReferralInDetailsUrl = `${API_ENDPOINTS.deleteReferralOut}/${referralId}`;
  try {
    const response = await ApiCaller.delete<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(deleteReferralInDetailsUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Referral Info deleted Successfully",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function createNewReferral(requestObject: any) {
  const getAllReferralsUrl =
    requestObject.referralType === "IN"
      ? API_ENDPOINTS.createReferralsIn
      : API_ENDPOINTS.createReferralsOut;
  delete requestObject.referralType;
  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(getAllReferralsUrl, requestObject);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Referral Data Retrieved Successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to Retrieve Referral Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function updateReferral(
  userId: number,
  updatedReferralData: Partial<CreateReferralParams>,
): Promise<any> {
  const updateUserUrl =
    updatedReferralData.referralType === "IN"
      ? `${API_ENDPOINTS.updateReferralsIn}/${userId}`
      : `${API_ENDPOINTS.updateReferralsOut}/${userId}`;
  delete updatedReferralData.referralType;
  try {
    const response = await ApiCaller.put<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(updateUserUrl, updatedReferralData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "User updated successfully",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
export async function getOrganizationDetailsAPI(
  organizationId: number | string,
): Promise<any> {
  if (organizationId) {
    const getOrganizationDetailsUrl = `${API_ENDPOINTS.getReferralDetails}/${organizationId}`;

    try {
      const response: {
        isSuccess: boolean;
        responseMsg?: Array<string>;
        data?: Record<string, string>;
      } = await ApiCaller.get(getOrganizationDetailsUrl);
      if (response.isSuccess) {
        return {
          status: response.isSuccess,
          code: STATUS_CODES.OK,
          message: "Organization fetch successfully",
          data: response.data,
        };
      }
      return {
        status: response.isSuccess,
        code: STATUS_CODES.INTERNAL_SERVER_ERROR,
        message: response.responseMsg?.[0],
        data: response,
      };
    } catch (error: any) {
      return {
        status: false,
        code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
        message: error?.message || "API failed",
        details: error?.response?.data?.error || "API call failed",
      };
    }
  }
  return {
    status: false,
    code: STATUS_CODES.INTERNAL_SERVER_ERROR,
    message: "API failed",
    details: "API call failed",
  };
}
export async function getProcedureCodeDetailsAPI(
  procedureCode: number | string,
): Promise<any> {
  const getPcpDetailsUrl = `${API_ENDPOINTS.searchProcCodeById}/${procedureCode}`;

  try {
    const response: {
      isSuccess: boolean;
      responseMsg?: Array<string>;
      data?: Record<string, string>;
    } = await ApiCaller.get(getPcpDetailsUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Procedure code fetch successfully",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
export async function getIcd10DetailsAPI(icd10: number | string): Promise<any> {
  const getPcpDetailsUrl = `${API_ENDPOINTS.searchIcd10CodeById}/${icd10}`;

  try {
    const response: {
      isSuccess: boolean;
      responseMsg?: Array<string>;
      data?: Record<string, string>;
    } = await ApiCaller.get(getPcpDetailsUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "ICD10 data fetch successfully",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
export async function getReferredToDetailsAPI(
  referredTo: number | string,
): Promise<any> {
  const getPcpDetailsUrl = `${API_ENDPOINTS.searchReferredToId}/${referredTo}`;

  try {
    const response: {
      isSuccess: boolean;
      responseMsg?: Array<string>;
      data?: Record<string, string>;
    } = await ApiCaller.get(getPcpDetailsUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Referral data fetch successfully",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
export async function getReferredByDetailsAPI(
  referredBy: number | string,
): Promise<any> {
  const getPcpDetailsUrl = `${API_ENDPOINTS.searchReferredById}/${referredBy}`;

  try {
    const response: {
      isSuccess: boolean;
      responseMsg?: Array<string>;
      data?: Record<string, string>;
    } = await ApiCaller.get(getPcpDetailsUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Referral data fetch successfully",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
