/* eslint-disable complexity */
import ApiCaller from "../../utils/apiCaller";
import { API_ENDPOINTS } from "../../constants/apiList";
import { STATUS_CODES } from "../../constants/variables";

export async function createNewCarePlan(formData: any): Promise<any> {
  const createNewCarePlanUrl = `${API_ENDPOINTS.createNewCarePlan}/${formData.patientId}/0`;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(createNewCarePlanUrl, formData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Care Plan Added Successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to add care plan Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function updatePatientCarePlan(
  formData: any,
  carePlanId: string,
): Promise<any> {
  const updatePatientCarePlanUrl = `${API_ENDPOINTS.updatePatientCarePlan}/${carePlanId}`;

  try {
    const response = await ApiCaller.put<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(updatePatientCarePlanUrl, formData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Care Plan Updated Successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to update care plan Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getCareTeamForCarePlan({
  paginationDataAsParameter,
  patientId,
  carePlanId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
  carePlanId: string;
}): Promise<any> {
  const { pageNo, pageSize, sortingFieldName, sortingOrder } =
    paginationDataAsParameter;
  let getCarePlanCareTeamUrl = `${API_ENDPOINTS.getCarePlanCareTeam}/${patientId}/${carePlanId}`;
  if (pageSize) {
    getCarePlanCareTeamUrl = `${getCarePlanCareTeamUrl}?pageNo=${pageNo}&pageSize=${pageSize}`;
  }
  if (
    sortingFieldName &&
    sortingFieldName !== "" &&
    sortingOrder &&
    sortingOrder !== ""
  ) {
    getCarePlanCareTeamUrl = `${getCarePlanCareTeamUrl}&sortBy=${sortingFieldName}&sortDir=${sortingOrder}`;
  }

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(getCarePlanCareTeamUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Care team Data Retrieved Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to Retrieve Care Team",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function createCareGiverAPI({
  formData,
  carePlanId,
  patientId,
}: {
  formData: any;
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  const updateCarePlanCareGiverUrl = `${API_ENDPOINTS.updateCareGiverDetails}/${patientId}/${carePlanId}`;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(updateCarePlanCareGiverUrl, formData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Care Giver added Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to add care giver Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function updateCareGiverAPI({
  formData,
  carePlanId,
  patientId,
}: {
  formData: any;
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  const updateCarePlanCareGiverUrl = `${API_ENDPOINTS.updateCareGiverDetails}/${patientId}/${carePlanId}`;

  try {
    const response = await ApiCaller.put<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(updateCarePlanCareGiverUrl, formData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Care Giver Updated Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to update care giver Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getAllAllergiesForCarePlan({
  paginationDataAsParameter,
  carePlanId,
  patientId,
}: {
  paginationDataAsParameter: any;
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  const { pageNo, pageSize, sortingFieldName, sortingOrder } =
    paginationDataAsParameter;
  let getPatientAllAllergiesUrl = `${API_ENDPOINTS.getPatientAllAllergies}/${patientId}/${carePlanId}`;
  if (pageSize) {
    getPatientAllAllergiesUrl = `${getPatientAllAllergiesUrl}?pageNo=${pageNo}&pageSize=${pageSize}`;
  }
  if (
    sortingFieldName &&
    sortingFieldName !== "" &&
    sortingOrder &&
    sortingOrder !== ""
  ) {
    getPatientAllAllergiesUrl = `${getPatientAllAllergiesUrl}&sortBy=${sortingFieldName}&sortDir=${sortingOrder}`;
  }

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(getPatientAllAllergiesUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Patient Allergies Retrieved Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMsg?.[0] || "Failed to Retrieve Patient Allergies",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getAllMedicationNameForCarePlan({
  paginationDataAsParameter,
  carePlanId,
  patientId,
}: {
  paginationDataAsParameter: any;
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  const { pageNo, pageSize, sortingFieldName, sortingOrder } =
    paginationDataAsParameter;
  let getPatientMedicationUrl = `${API_ENDPOINTS.getPatientMedication}/${patientId}/${carePlanId}`;
  if (pageSize) {
    getPatientMedicationUrl = `${getPatientMedicationUrl}?pageNo=${pageNo}&pageSize=${pageSize}`;
  }
  if (
    sortingFieldName &&
    sortingFieldName !== "" &&
    sortingOrder &&
    sortingOrder !== ""
  ) {
    getPatientMedicationUrl = `${getPatientMedicationUrl}&sortBy=${sortingFieldName}&sortDir=${sortingOrder}`;
  }

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(getPatientMedicationUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Patient Medication Retrieved Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMsg?.[0] || "Failed to Retrieve Patient Allergies",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
export async function createCarePlanAllergiesAPI({
  formData,
  carePlanId,
  patientId,
}: {
  formData: any;
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  const createCarePlanAllergiesUrl = `${API_ENDPOINTS.createCarePlanAllergies}/${patientId}/${carePlanId}`;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(createCarePlanAllergiesUrl, formData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Allergies Added Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to Allergies plan Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function updateCarePlanAllergiesAPI({
  formData,
  carePlanId,
  patientId,
}: {
  formData: any;
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  const updateCarePlanAllergiesUrl = `${API_ENDPOINTS.updateCarePlanAllergies}/${patientId}/${carePlanId}`;

  try {
    const response = await ApiCaller.put<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(updateCarePlanAllergiesUrl, formData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Allergies Updated Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMsg?.[0] || "Failed to update Allergies plan Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getAllCarePlanForPatientAPI({
  paginationDataAsParameter,
  patientId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
}): Promise<any> {
  const { pageNo, pageSize, sortingFieldName, sortingOrder } =
    paginationDataAsParameter;
  let getAllCarePlanForPatientUrl = `${API_ENDPOINTS.getAllCarePlanForPatient}/${patientId}`;
  if (pageSize) {
    getAllCarePlanForPatientUrl = `${getAllCarePlanForPatientUrl}?pageNo=${pageNo}&pageSize=${pageSize}`;
  }
  if (
    sortingFieldName &&
    sortingFieldName !== "" &&
    sortingOrder &&
    sortingOrder !== ""
  ) {
    getAllCarePlanForPatientUrl = `${getAllCarePlanForPatientUrl}&sortBy=${sortingFieldName}&sortDir=${sortingOrder}`;
  }

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(getAllCarePlanForPatientUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Care Plan Data Retrieved Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMsg?.[0] || "Failed to Retrieve Care Plan Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function deleteCarePlan(
  carePlanId: number | string,
): Promise<any> {
  const carePlanDeleteUrl = `${API_ENDPOINTS.deleteCarePlan}/${carePlanId}`;

  try {
    const response: { isSuccess: boolean; responseMsg?: Array<string> } =
      await ApiCaller.delete(carePlanDeleteUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Care Plan Deleted successfully",
        data: response,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getCarePlanMemberDetails({
  carePlanId,
  patientId,
}: {
  patientId: string | number;
  carePlanId: string | number;
}): Promise<any> {
  const getCarePlanMemberDetailsUrl = `${API_ENDPOINTS.getCarePlanMemberDetails}/${patientId}/${carePlanId}`;

  try {
    const response: {
      isSuccess: boolean;
      responseMsg?: Array<string>;
      data: Record<string, string>;
    } = await ApiCaller.get(getCarePlanMemberDetailsUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Care Plan Member Data Retrieve successfully",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function updateCaseManagerDetails({
  formData,
  patientId,
  carePlanId,
}: {
  formData: any;
  patientId: string;
  carePlanId: string;
}): Promise<any> {
  const updateCareManagerDetailsUrl = `${API_ENDPOINTS.updateCaseManagerDetails}/${patientId}/${carePlanId}`;

  try {
    const response = await ApiCaller.put<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(updateCareManagerDetailsUrl, formData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Case Manager Updated Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to update care giver Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getAllDiagnosticHistoryOfPatient({
  paginationDataAsParameter,
  patientId,
  carePlanId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
  carePlanId: string;
}): Promise<any> {
  const { pageNo, pageSize, sortingFieldName, sortingOrder } =
    paginationDataAsParameter;
  let getPatientDiagnosticHistoryUrl = `${API_ENDPOINTS.getDiagnosticHistoryPatient}/${patientId}/${carePlanId}`;
  if (pageSize) {
    getPatientDiagnosticHistoryUrl = `${getPatientDiagnosticHistoryUrl}?pageNo=${pageNo}&pageSize=${pageSize}`;
  }
  if (
    sortingFieldName &&
    sortingFieldName !== "" &&
    sortingOrder &&
    sortingOrder !== ""
  ) {
    getPatientDiagnosticHistoryUrl = `${getPatientDiagnosticHistoryUrl}&sortBy=${sortingFieldName}&sortDir=${sortingOrder}`;
  }

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(getPatientDiagnosticHistoryUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Diagnostic History Data Retrieved Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMsg?.[0] ||
        "Failed to Retrieve Diagnostic History Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function fetchPatientLabResults({
  paginationParams,
  patientId,
  carePlanId,
}: {
  paginationParams: any;
  patientId: string;
  carePlanId: string;
}): Promise<any> {
  const { pageNo, pageSize, sortField, sortOrder } = paginationParams;
  let labResultsUrl = `${API_ENDPOINTS.getLabResultsForPatient}/${patientId}/${carePlanId}`;

  if (pageSize) {
    labResultsUrl = `${labResultsUrl}?pageNo=${pageNo}&pageSize=${pageSize}`;
  }

  if (sortField && sortField !== "" && sortOrder && sortOrder !== "") {
    labResultsUrl = `${labResultsUrl}&sortBy=${sortField}&sortDir=${sortOrder}`;
  }

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMessage?: string[];
      data: Record<string, string>;
    }>(labResultsUrl);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Lab results retrieved successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMessage?.[0] || "Failed to retrieve lab results",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API request failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function fetchDiagnosticResults({
  paginationParams,
  patientId,
  carePlanId,
}: {
  paginationParams: any;
  patientId: string;
  carePlanId: string;
}): Promise<any> {
  const { pageNo, pageSize, sortField, sortOrder } = paginationParams;
  let diagnosticResultsUrl = `${API_ENDPOINTS.getDiagnosticResultsForPatient}/${patientId}/${carePlanId}`;

  if (pageSize) {
    diagnosticResultsUrl = `${diagnosticResultsUrl}?pageNo=${pageNo}&pageSize=${pageSize}`;
  }
  if (sortField && sortField !== "" && sortOrder && sortOrder !== "") {
    diagnosticResultsUrl = `${diagnosticResultsUrl}&sortBy=${sortField}&sortDir=${sortOrder}`;
  }

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMessage?: string[];
      data: Record<string, string>;
    }>(diagnosticResultsUrl);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Diagnostic results retrieved successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMessage?.[0] ||
        "Failed to retrieve diagnostic results",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API request failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getCarePlanICD10Values({
  patientId,
  carePlanId,
}: {
  patientId: string;
  carePlanId: string;
}): Promise<any> {
  const carePlanUrl = `${API_ENDPOINTS.getCarePlanICD10Values}/${patientId}/${carePlanId}`;

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMessage?: string[];
      data: Record<string, string>;
    }>(carePlanUrl);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "ICD10 retrieved successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMessage?.[0] || "Failed to retrieve ICD10  results",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API request failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function createAndUpdateICDTeamNotesAPI({
  formData,
  carePlanId,
  type,
  patientId,
}: {
  formData: any;
  carePlanId: string;
  type: string;
  patientId: string;
}): Promise<any> {
  const updateICDTeamNotesUrl = `${API_ENDPOINTS.updateICDTeamNotes}/${patientId}/${carePlanId}`;

  try {
    let response;
    let responseMessage = "";
    if (type === "CREATE") {
      response = await ApiCaller.post<{
        isSuccess: boolean;
        responseMsg?: string[];
        dataList: Record<string, string>;
      }>(updateICDTeamNotesUrl, formData);
      responseMessage = "ICD Team Notes Created Successfully";
    } else {
      response = await ApiCaller.put<{
        isSuccess: boolean;
        responseMsg?: string[];
        dataList: Record<string, string>;
      }>(updateICDTeamNotesUrl, formData);
      responseMessage = "ICD Team Notes Updated Successfully";
    }

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: responseMessage,
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMsg?.[0] || "Failed to update ICD Team Notes Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getCarePlanCareGiver({
  patientId,
  carePlanId,
}: {
  patientId: string;
  carePlanId: string;
}): Promise<any> {
  const carePlanUrl = `${API_ENDPOINTS.getCarePlanCareGiverValues}/${patientId}/${carePlanId}`;

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMessage?: string[];
      data: Record<string, string>;
    }>(carePlanUrl);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Care Giver retrieved successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMessage?.[0] ||
        "Failed to retrieve care giver  results",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API request failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function createGoalForPatientAPI({
  formData,
  carePlanId,
  patientId,
}: {
  formData: any;
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  const updateCarePlanCareGiverUrl = `${API_ENDPOINTS.createGoalForPatient}/${patientId}/${carePlanId}`;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(updateCarePlanCareGiverUrl, formData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Goal added Successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to add Goal Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function updateGoalForPatientAPI({
  formData,
  carePlanId,
  patientId,
}: {
  formData: any;
  carePlanId: string;
  patientId: string;
}): Promise<any> {
  const updateCarePlanCareGiverUrl = `${API_ENDPOINTS.updateGoalForPatient}/${patientId}/${carePlanId}`;

  try {
    const response = await ApiCaller.put<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(updateCarePlanCareGiverUrl, formData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Goal updated Successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to add Goal Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getCarePlanGoalsAPI({
  patientId,
  carePlanId,
}: {
  patientId: string;
  carePlanId: string;
}): Promise<any> {
  const carePlanUrl = `${API_ENDPOINTS.getCarePlanGoals}/${patientId}/${carePlanId}`;

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMessage?: string[];
      dataList: Record<string, string>;
    }>(carePlanUrl);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Goals retrieved successfully",
        data: response.dataList,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMessage?.[0] || "Failed to retrieve Goals  results",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API request failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function finalizeCarePlan({
  carePlanId,
}: {
  carePlanId: string;
}): Promise<any> {
  const carePlanUrl = `${API_ENDPOINTS.markTheCarePlanFinalize}/${carePlanId}`;

  try {
    const response = await ApiCaller.put<{
      isSuccess: boolean;
      responseMessage?: string[];
      dataList: Record<string, string>;
    }>(carePlanUrl);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Care Plan Updated Successfully",
        data: response.dataList,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMessage?.[0] || "Failed to retrieve Goals  results",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API request failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getCarePlanInitialRiskAssessment({
  paginationDataAsParameter,
  patientId,
  carePlanId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
  carePlanId: string;
}): Promise<any> {
  const { pageNo, pageSize, sortingFieldName, sortingOrder } =
    paginationDataAsParameter;
  let getCarePlanCareTeamUrl = `${API_ENDPOINTS.getCarePlanInitialRiskAssessment}/${patientId}/${carePlanId}`;
  if (pageSize) {
    getCarePlanCareTeamUrl = `${getCarePlanCareTeamUrl}?pageNo=${pageNo}&pageSize=${pageSize}`;
  }
  if (
    sortingFieldName &&
    sortingFieldName !== "" &&
    sortingOrder &&
    sortingOrder !== ""
  ) {
    getCarePlanCareTeamUrl = `${getCarePlanCareTeamUrl}&sortBy=${sortingFieldName}&sortDir=${sortingOrder}`;
  }

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(getCarePlanCareTeamUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Initial Risk Assessment Data Retrieved Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message:
        response?.responseMsg?.[0] ||
        "Failed to Retrieve Initial Risk Assessment",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
