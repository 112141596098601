import {
  UpdatePatientBasicInfo,
  addPatientContactAPI,
  addPatientProviderInfoAPI,
  addRaceAndEthnicityAPI,
  createNewPatientInfo,
  createNewQuickPatientInfo,
  deletePatientDetails,
  getAllPatientList,
  getPatientDetails,
  getPatientProfile,
  getSearchPatientList,
  updatePatientContactAPI,
} from "../../dataLayer/patients/patients";
import { formatDate } from "../utils/formatDate";
import { validateDateOfBirth } from "../validation/dateOfBirth";
import {
  ServiceResponse,
  generateErrorResponse,
} from "../validation/generateErrorResponse";
import { validateName } from "../validation/name";

export async function getAllPatientsListService({
  paginationDataAsParameter,
}: {
  paginationDataAsParameter: any;
}): Promise<any> {
  try {
    const allUsers = await getAllPatientList({ paginationDataAsParameter });
    return allUsers;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all users.",
      details: error.message,
    };
  }
}

export async function deletingPatientInfo(patientId: string): Promise<any> {
  try {
    const patientInfo = await deletePatientDetails(patientId);
    return patientInfo;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all users.",
      details: error.message,
    };
  }
}

export async function getPatientDetailsFromId(patientId: string): Promise<any> {
  try {
    const patientInfo = await getPatientDetails(patientId);
    const patientImage = await getPatientProfile(patientId);
    const imageUrl = patientImage.data;
    if (patientInfo.status) {
      const patientDetails = {
        ...patientInfo.data,
        profileImage: imageUrl,
      };
      return patientDetails;
    }
    return patientInfo;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all users.",
      details: error.message,
    };
  }
}
interface FormData {
  userTitle: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dob: string;
  sex: string;
  genderIdentity: string;
  orientation: string;
  maritalStatus: string;
  chartNumber: string;
  partyName: string;
  occupation: string;
  caseManager: string;
}

export function validateMandatoryFieldsForPatientInfoBasic(
  formData: FormData,
): ServiceResponse {
  if (!formData.firstName) {
    return generateErrorResponse("First name is required", 400);
  }

  if (!formData.lastName) {
    return generateErrorResponse("Last name is required", 400);
  }

  if (!formData.dob) {
    return generateErrorResponse("Date of Birth is required", 400);
  }

  return { status: true };
}

export function validateFormData(formData: FormData): ServiceResponse {
  const mandatoryErrors = validateMandatoryFieldsForPatientInfoBasic(formData);
  if (!mandatoryErrors.status) {
    return mandatoryErrors;
  }
  if (!validateName(formData.firstName, "FirstName").status) {
    return validateName(formData.firstName, "FirstName");
  }
  if (!validateName(formData.lastName, "lastName").status) {
    return validateName(formData.lastName, "lastName");
  }
  if (!validateDateOfBirth(formData.dob).status) {
    return validateDateOfBirth(formData.dob);
  }
  return { status: true };
}

export async function createPatientBasicInfo(formData: any) {
  try {
    const requestObject = {
      profileImage: formData.profileImage,
      patientDetailJson: {
        ...formData,
        dob: formatDate(formData.dob),
        responsiblePartyName: formData.partyName,
        caseManagerId: formData.caseManager,
        title: formData.userTitle,
      },
    };

    const formDataRequest = new FormData();
    const typedRequestObject: { [key: string]: any } = requestObject;

    Object.keys(typedRequestObject).forEach((key) => {
      const value = typedRequestObject[key];

      if (value instanceof File) {
        formDataRequest.append(key, value);
      } else {
        formDataRequest.append(key, JSON.stringify(value));
      }
    });

    const createPatientBasicInfoResponse =
      await createNewPatientInfo(formDataRequest);
    return createPatientBasicInfoResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all Referrals In.",
      details: error.message,
    };
  }
}

export async function updatePatientBasicInfo(formData: any) {
  try {
    const requestObject = {
      profileImage: formData.profileImage,
      patientDetailJson: {
        ...formData,
        dob: formatDate(formData.dob),
        responsiblePartyName: formData.partyName,
        caseManagerId: formData.caseManager,
        patientId: formData.chartNumber,
      },
    };

    const formDataRequest = new FormData();
    const typedRequestObject: { [key: string]: any } = requestObject;
    Object.keys(typedRequestObject).forEach((key) => {
      const value = typedRequestObject[key];

      if (value instanceof File) {
        formDataRequest.append(key, value);
      } else {
        formDataRequest.append(key, JSON.stringify(value));
      }
    });
    const patientId = formData.chartNumber;
    const createPatientBasicInfoResponse = await UpdatePatientBasicInfo(
      formDataRequest,
      patientId,
    );
    return createPatientBasicInfoResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error Updating the patient Info",
      details: error.message,
      data: {},
    };
  }
}
export async function addPatientContact(
  formData: any,
  patientId: number,
): Promise<any> {
  try {
    const requestData = {
      primaryAddress: formData.address1,
      secondaryAddress: formData.address2,
      country: formData.country,
      state: formData.state,
      city: formData.city,
      zipCode: formData.zipCode,
      preferredPhone: formData.preferredPhone,
      mobileNumber: formData.mobileNumber,
      email: formData.email,
      patientId,
    };

    const addContactResponse = await addPatientContactAPI(requestData);
    return addContactResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error adding patient contact information.",
      details: error.message,
    };
  }
}
export async function updatePatientContact(
  formData: any,
  contactId: string,
): Promise<any> {
  try {
    const requestData = {
      primaryAddress: formData.address1,
      secondaryAddress: formData.address2,
      country: formData.country,
      state: formData.state,
      city: formData.city,
      zipCode: parseInt(formData.zipCode, 10),
      preferredPhone: formData.preferredPhone,
      mobileNumber: formData.mobileNumber,
      email: formData.email,
      patientId: formData.patientId,
    };

    const addContactResponse = await updatePatientContactAPI(
      requestData,
      contactId,
    );
    return addContactResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error adding patient contact information.",
      details: error.message,
    };
  }
}

export async function addPatientProviderInfo(formData: any): Promise<any> {
  try {
    const requestData = {
      ...formData,
      patientAsOfDate: formatDate(formData.date),
    };
    const addProviderResponse = await addPatientProviderInfoAPI(requestData);
    return addProviderResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error adding patient provider information.",
      details: error.message,
    };
  }
}

export async function addRaceAndEthnicity(
  formData: any,
  patientId: number,
): Promise<any> {
  try {
    const requestData = {
      raceList: formData.selectedRaces.map((race: { value: string }) => ({
        id: parseInt(race.value, 10),
      })),
      ethnicityList: formData.selectedEthnicities.map(
        (ethnicity: { value: string }) => ({
          id: parseInt(ethnicity.value, 10),
        }),
      ),
      patientId,
    };
    const addProviderResponse = await addRaceAndEthnicityAPI(requestData);
    return addProviderResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error adding patient provider information.",
      details: error.message,
    };
  }
}

function mapToApiRequest(obj: any) {
  return {
    title: obj.userTitle,
    firstName: obj.firstName,
    middleName: obj.middleName,
    lastName: obj.lastName,
    dob: formatDate(obj.dateOfBirth),
    sex: obj.sex,
    genderIdentity: obj.genderIdentity,
    sexualOrientation: obj.sexualOrientation,
    preferredLanguage: obj.preferredLanguage,
    maritalStatus: obj.maritalStatus,
    responsiblePartyName: obj.responsiblePartyName,
    occupation: obj.occupation,
    caseManagerId: obj.caseManager,
    contactInformation: {
      primaryAddress: obj.address1,
      secondaryAddress: obj.address2,
      country: obj.country,
      state: obj.state,
      city: obj.city,
      zipCode: obj.zipCode,
      preferredPhone: obj.preferredPhone,
      email: obj.email,
    },
    providerInfo: {
      notes: obj.patientNotes,
      pcpId: obj.pcpName,
      patientStatus: obj.providerStatus,
      patientAsOfDate: formatDate(obj.patientAsOfDate),
      providerSpeciality: obj.providerSpecialities,
    },
  };
}

export async function createQuickPatientInfo(formData: any) {
  try {
    const requestObject = mapToApiRequest(formData);

    const apiResponse = await createNewQuickPatientInfo(requestObject);
    return apiResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all Referrals In.",
      details: error.message,
    };
  }
}

export async function getSearchPatientName(patientName: string): Promise<any> {
  try {
    if (patientName) {
      const response = await getSearchPatientList(
        patientName.replaceAll(" ", ""),
      );
      if (response.isSuccess) {
        const options = response.dataList.map(
          (item: {
            firstName: string;
            lastName: string;
            code: number;
            id: string;
          }) => ({
            ...item,
            label: `${item.firstName} ${item.lastName} `,
            value: item.id,
          }),
        );
        return options;
      }
    }

    return [];
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching Patient values.",
      details: error.message,
    };
  }
}
