import { ValidationRules } from "../../../../../services/utils/validateDataAsPerRules";

export const validationRules: ValidationRules = {
  name: {
    required: false,
    displayName: "Name",
    dataType: "text",
  },
  contactNumber: {
    required: false,
    displayName: "Contact Number",
    dataType: "number",
    characterLimit: 10,
    minCharacterLimit: 10,
  },
  email: {
    required: false,
    displayName: "Email",
    dataType: "email",
  },
};
