import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "../../../components/atom/Table/Table";
import { Card } from "../../../components/atom/Cards/Cards";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import { CreateNewMemos } from "../../../components/organism/CreateNewMemos/CreateNewMemos";
import { deleteMemoService, getAllMemos } from "../../../services/memos/memos";
import { formatDate } from "../../../services/utils/formatDate";
import { Pagination } from "../../../components/atom/Pagination/Pagination";

export function Memos() {
  interface CareTeamListType {
    dueDate: string;
    task: string;
    patient: string;
    details: string;
    actions: string;
  }
  const activePatient = useSelector((state: any) => state.activePatient);
  const [memoDetails, setMemoDetails] = useState<any>({
    memoDate: "",
    memoDetails: "",
    id: "",
  });
  const [paginationData, setPaginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [userListData, setUserListData] = useState<CareTeamListType[]>([]);
  const headersForTable: Record<string, string> = {
    memoDate: "Date",
    memoDetails: "Memos",
    patientName: "Patient",
    action: "Actions",
  };
  const sortingHeaderMap: Record<string, string> = {
    memoDate: "Date",
    patientName: "Patient",
    memoDetails: "Details",
  };
  const fetchData = async (newPaginationData?: any) => {
    try {
      const paginationDataAsParameter = newPaginationData || paginationData;
      const memoList = await getAllMemos({
        paginationDataAsParameter,
      });
      if (memoList.status) {
        setPaginationData({
          ...paginationData,
          pageNo: memoList.data.pageNo,
          totalPages: memoList.data.totalPages,
          totalElements: memoList.data.totalElements,
          pageSize: memoList.data.pageSize,
        });
        setUserListData(memoList.data.dataList);
        setMemoDetails({
          memoDate: "",
          memoDetails: "",
          id: "",
        });
      }
    } catch (error) {
      showToastMessage("Error fetching all users:", TOAST_TYPE_OPTIONS.ERROR);
      console.error("Error fetching all users:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!activePatient.patientDetails.id) {
      setUserListData([]);
    }
  }, [activePatient]);

  const deleteMemoValue = async (rowData: any) => {
    const deleteMemoResponse = await deleteMemoService(rowData.id);
    if (deleteMemoResponse.status) {
      showToastMessage(deleteMemoResponse.message, TOAST_TYPE_OPTIONS.SUCCESS);
      fetchData();
    } else {
      showToastMessage(deleteMemoResponse.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  const editMemo = (rowData: any) => {
    setMemoDetails({
      ...rowData,
      memoDate: formatDate(rowData.memoDate, "YYYY-MM-DD"),
    });
  };

  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    fetchData({ ...paginationData, pageNo, pageSize });
  };

  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    fetchData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };
  return (
    <div>
      <Card>
        <div className="d-flex justify-content-between">
          <h3>Memos</h3>
          <CreateNewMemos
            setShow={setShowModal}
            show={showModal}
            editData={memoDetails}
            fetchMemoData={fetchData}
          />
        </div>

        <div className="table-responsive care-manager-table">
          <Table
            className="table table-bordered table-striped table-hover"
            data={userListData}
            headers={headersForTable}
            enableDeleteAction={deleteMemoValue}
            enableEditAction={editMemo}
            sortingHeaderMap={sortingHeaderMap}
            sortingFunction={sortingFunction}
          />
          {paginationData.totalPages >= 0 && (
            <Pagination
              currentPage={paginationData.pageNo}
              totalPages={paginationData.totalPages}
              pageSize={paginationData.pageSize}
              onPageChange={(page, pageSize) => {
                updatePaginationData(page, pageSize);
              }}
            />
          )}
        </div>
      </Card>
    </div>
  );
}
