import {
  deletePcpAPI,
  getPcpDetailsAPI,
  updateExitingPcp,
} from "../../dataLayer/masterData/pcp";
import { validateOnlyAlphabets } from "../validation/onlyAlphabets";
import { validateName } from "../validation/name";
import { createNewPcp } from "../../dataLayer/masterData/masterData";
import { generateErrorResponse } from "../validation/generateErrorResponse";

export async function deletePCPService(pcpId: string | number): Promise<any> {
  if (!pcpId) {
    return {
      status: false,
      code: 500,
      message: "PCP ID is required.",
      details: "PCP ID is required.",
    };
  }

  try {
    const response = await deletePcpAPI(pcpId);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting PCP value.",
      details: error.message,
    };
  }
}
export async function getPCPInfoService(pcpId: string | number): Promise<any> {
  if (!pcpId) {
    return {
      status: false,
      code: 500,
      message: "PCP ID is required.",
      details: "PCP ID is required.",
    };
  }

  try {
    const response = await getPcpDetailsAPI(pcpId);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting PCP value.",
      details: error.message,
    };
  }
}
export interface CreatePcpRecord {
  title: string;
  firstName: string;
  lastName: string;
  organizationId?: string;
  address1: string;
  address2: string;
  zipCode: string;
  country: string;
  state: string;
  city: string;
  officeNumber: string;
  otherNumber: string;
  fax: string;
  email: string;
  speciality: string;
  practice: string;
  taxonomy: string;
  npi: string;
  upin: string;
  providerTypeId: string;
}

function findMissingFields(params: CreatePcpRecord): string[] {
  const requiredFields: (keyof CreatePcpRecord)[] = [
    "title",
    "firstName",
    "lastName",
    "address1",
    "zipCode",
    "country",
    "state",
    "city",
  ];
  return requiredFields.filter((field) => !(field in params));
}
export async function createPcp(params: CreatePcpRecord): Promise<any> {
  const {
    title,
    firstName,
    lastName,
    organizationId,
    address1,
    address2,
    officeNumber,
    otherNumber,
    country,
    state,
    city,
    zipCode,
    fax,
    email,
    speciality,
    practice,
    taxonomy,
    npi,
    upin,
    providerTypeId,
  } = params;

  const missingFields: string[] = findMissingFields(params);
  if (missingFields.length > 0) {
    return generateErrorResponse(
      "Required fields are missing.",
      500,
      `${missingFields.join(", ")} are required.`,
    );
  }
  if (title.length > 10) {
    return generateErrorResponse(
      "Title should not have more then 10 characters",
      400,
    );
  }
  const validateTitle = validateOnlyAlphabets("Title", title, true, 10);
  if (!validateTitle.status) {
    return validateTitle;
  }
  if (!validateName(params.firstName, "First name").status) {
    return validateName(params.firstName, "First name");
  }
  if (!validateName(params.lastName, "Last name").status) {
    return validateName(params.lastName, "Last name");
  }
  try {
    const requestObject = {
      title,
      firstName,
      lastName,
      organizationId,
      address1,
      address2,
      officeNumber,
      otherNumber,
      country,
      state,
      city,
      zipCode,
      fax,
      email,
      speciality,
      practice,
      taxonomy,
      npi,
      upin,
      providerTypeId,
    };
    const newUser = await createNewPcp(requestObject);
    return newUser;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating user.",
      details: error.message,
    };
  }
}

export async function updatePcp(
  params: CreatePcpRecord,
  pcpId: string,
): Promise<any> {
  const {
    title,
    firstName,
    lastName,
    organizationId,
    address1,
    address2,
    officeNumber,
    otherNumber,
    country,
    state,
    city,
    zipCode,
    fax,
    email,
    speciality,
    practice,
    taxonomy,
    npi,
    upin,
    providerTypeId,
  } = params;

  const missingFields: string[] = findMissingFields(params);
  if (missingFields.length > 0) {
    return generateErrorResponse(
      "Required fields are missing.",
      500,
      `${missingFields.join(", ")} are required.`,
    );
  }
  if (title.length > 10) {
    return generateErrorResponse(
      "Title should not have more then 10 characters",
      400,
    );
  }
  const validateTitle = validateOnlyAlphabets("Title", title, true, 10);
  if (!validateTitle.status) {
    return validateTitle;
  }
  if (!validateName(params.firstName, "First name").status) {
    return validateName(params.firstName, "First name");
  }
  if (!validateName(params.lastName, "Last name").status) {
    return validateName(params.lastName, "Last name");
  }
  try {
    const requestObject = {
      title,
      firstName,
      lastName,
      organizationId,
      address1,
      address2,
      officeNumber,
      otherNumber,
      country,
      state,
      city,
      zipCode,
      fax,
      email,
      speciality,
      practice,
      taxonomy,
      npi,
      upin,
      providerTypeId,
    };
    const newUser = await updateExitingPcp(requestObject, pcpId);
    return newUser;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error updating PCP.",
      details: error.message,
    };
  }
}
