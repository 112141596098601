/* eslint-disable complexity */
import ApiCaller from "../../utils/apiCaller";
import { API_ENDPOINTS } from "../../constants/apiList";
import { STATUS_CODES } from "../../constants/variables";

export async function createTimelineForPatientAPI(formData: any): Promise<any> {
  const createTimelineUrl = API_ENDPOINTS.createTimeline;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(createTimelineUrl, formData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Timeline Add Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to Retrieve Timeline Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function updateTimelineForPatientAPI({
  formData,
  eventId,
}: {
  formData: any;
  eventId: string;
}): Promise<any> {
  const updateTimelineUrl = `${API_ENDPOINTS.updateTimeline}/${eventId}`;

  try {
    const response = await ApiCaller.put<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(updateTimelineUrl, formData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Timeline Update Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to Retrieve Timeline Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function timelineListForPatient({
  paginationDataAsParameter,
  patientId,
  filterCategoryId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
  filterCategoryId: string;
}): Promise<any> {
  const { pageNo, pageSize, sortingFieldName, sortingOrder } =
    paginationDataAsParameter;

  let getAllTaskUrl = `${API_ENDPOINTS.getTimelineList}/${patientId}?`;

  if (pageNo >= 0 && pageSize) {
    getAllTaskUrl += `pageNo=${pageNo}&pageSize=${pageSize}`;
  }
  if (filterCategoryId && filterCategoryId !== "-1") {
    getAllTaskUrl += `&category=${filterCategoryId}`;
  }

  if (
    sortingFieldName &&
    sortingFieldName !== "" &&
    sortingOrder &&
    sortingOrder !== ""
  ) {
    getAllTaskUrl += `&sortBy=${sortingFieldName}&sortDir=${sortingOrder}`;
  }

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(getAllTaskUrl);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Filtered timeline Data Retrieved Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to Retrieve Task Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getTimelineDataForEventAPI({
  eventId,
}: {
  eventId: string;
}): Promise<any> {
  const getEventForTimelineUrl = `${API_ENDPOINTS.getTimelineForEvent}/${eventId}`;
  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(getEventForTimelineUrl);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Filtered timeline Data Retrieved Successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to Retrieve Task Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getTimelineDataForGraph({
  patientId,
  categoryIdForFilter,
}: {
  patientId: string;
  categoryIdForFilter: string;
}): Promise<any> {
  let getEventForTimelineUrl = `${API_ENDPOINTS.getTimelineForGraph}/${patientId}`;

  if (categoryIdForFilter && categoryIdForFilter !== "-1") {
    getEventForTimelineUrl += `?category=${categoryIdForFilter}`;
  }
  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(getEventForTimelineUrl);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Filtered timeline Data Retrieved Successfully",
        data: response.dataList,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to Retrieve Task Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
