import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { showToastMessage } from "../../../../utils/showToastMessage/showToastMessage";
import { Table } from "../../../atom/Table/Table";
import { getAllCareTeamListForCarePlan } from "../../../../services/carePlan/carePlan";
import { TOAST_TYPE_OPTIONS } from "../../../../constants/variables";
import { Pagination } from "../../../atom/Pagination/Pagination";

export function CareTeam() {
  const activePatient = useSelector((state: any) => state.activePatient);
  const carePlanDetails = useSelector((state: any) => state.activeCarePlan);
  const headers = {
    organizationName: "Organization",
    providerName: "Provider Name",
    providerType: "Provider Type",
    contactNumber: "Contact Number",
    fax: "Fax",
    email: "Email",
    statusValue: "Status",
    fromDate: "From Date",
    toDate: "End Date",
    notes: "Notes",
  };
  const sortingHeaderMap: Record<string, string> = {
    providerName: "ProviderName",
    organizationName: "Organization",
    providerType: "ProviderType",
    contactNumber: "ContactNumber",
    fax: "Fax",
    email: "Email",
    statusValue: "Status",
    fromDate: "FromDate",
    toDate: "EndDate",
    notes: "Notes",
  };
  const [careTeamListData, setCareTeamListData] = useState([]);
  const [paginationData, setPaginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });

  const fetchData = async (newPaginationData?: any) => {
    try {
      const paginationDataAsParameter = newPaginationData || paginationData;
      const patientId = activePatient.patientDetails.id;

      if (patientId !== "") {
        const appAppointmentListResponse = await getAllCareTeamListForCarePlan({
          paginationDataAsParameter,
          patientId,
          carePlanId: carePlanDetails.carePlanDetails.carePlanId,
        });
        if (appAppointmentListResponse.status) {
          setPaginationData({
            ...paginationData,
            pageNo: appAppointmentListResponse.data.pageNo,
            totalPages: appAppointmentListResponse.data.totalPages,
            totalElements: appAppointmentListResponse.data.totalElements,
            pageSize: appAppointmentListResponse.data.pageSize,
          });
          setCareTeamListData(appAppointmentListResponse.data.dataList);
        }
      }
    } catch (error) {
      showToastMessage("Error fetching all users:", TOAST_TYPE_OPTIONS.ERROR);
      console.error("Error fetching all users:", error);
    }
  };

  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize,
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    fetchData({ ...paginationData, pageNo, pageSize });
  };

  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    fetchData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      className="accordion-collapse"
      data-bs-parent="#accordionFlushExample-parent"
    >
      <div className="row mt-3 bg-white px-0 mx-0">
        <div className="col-12 grid-margin">
          <div className="d-flex justify-content-between align-items-center">
            <h5>Care Team</h5>
          </div>
          <div className="table-responsive care-manager-table">
            <Table
              headers={headers}
              data={careTeamListData}
              className="table"
              sortingHeaderMap={sortingHeaderMap}
              sortingFunction={sortingFunction}
            />
            {paginationData.totalPages >= 0 && (
              <Pagination
                currentPage={paginationData.pageNo}
                totalPages={paginationData.totalPages}
                pageSize={paginationData.pageSize}
                onPageChange={(page, pageSize) => {
                  updatePaginationData(page, pageSize);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
