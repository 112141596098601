import { ValidationRules } from "../../../../services/utils/validateDataAsPerRules";

export const validationRules: ValidationRules = {
  name: {
    required: true,
    dataType: "text",
    displayName: "Name Of The Assessment",
    characterLimit: 50,
  },
  lastModifiedOn: {
    required: false,
    dataType: "date",
    displayName: "Last Modified Date",
  },
  lastModifiedBy: {
    required: false,
    dataType: "text",
    displayName: "Last Modified By",
  },
  createdOn: {
    required: false,
    dataType: "date",
    displayName: "Created On",
  },
  createdBy: {
    required: false,
    dataType: "text",
    displayName: "Created By",
  },
  statusId: {
    required: false,
    dataType: "any",
    displayName: "IRA Status",
  },
};

export const timeSpentValidationRules: ValidationRules = {
  timeSpent: {
    required: true,
    dataType: "number",
    displayName: "Time Spent",
  },
};

export const defaultBasicDetailsValues = {
  name: "",
  lastModifiedOn: "",
  lastModifiedBy: "",
  createdOn: "",
  createdBy: "",
  statusId: 0,
  id: 0,
  timeSpent: "",
};

export const assessmentOptions = [
  {
    label: "No",
    value: 0,
  },
  {
    label: "Yes",
    value: 1,
  },
  {
    label: "Decline",
    value: 2,
  },
  {
    label: "N/A",
    value: 3,
  },
  {
    label: "High",
    value: 4,
  },
  {
    label: "Moderate",
    value: 5,
  },
  {
    label: "Low",
    value: 6,
  },
];
