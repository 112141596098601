import {
  createInsurance,
  deletePatientInsurance,
  getFirstSlideView,
  getInsuranceForPatient,
  getSecondSlideView,
  updateInsurance,
} from "../../dataLayer/insurance/insurance";
import { formatDate } from "../utils/formatDate";

interface PatientFormData {
  planName: string;
  patientId: string;
  carrierName: string;
  coverage?: string;
  groupId: string;
  enrollmentDate: string;
  termedDate: string;
  relation?: string;
  selectedImage?: string | null | ArrayBuffer;
  insuranceCardSlideOne?: File;
  insuranceCardSlideTwo?: File;
}

export async function createNewInsurance(
  formData: PatientFormData,
): Promise<any> {
  try {
    const requestObject = {
      insuranceData: {
        relationToPatient: formData.relation,
        ...formData,
        termedDate: formatDate(formData.termedDate),
        enrollmentDate: formatDate(formData.enrollmentDate),
      },
    };
    if (
      requestObject &&
      requestObject.insuranceData &&
      requestObject.insuranceData.selectedImage
    ) {
      delete requestObject.insuranceData.selectedImage;
    }
    if (requestObject.insuranceData.coverage === "") {
      delete requestObject.insuranceData.coverage;
    }
    if (requestObject.insuranceData.relation === "") {
      delete requestObject.insuranceData.relation;
    }
    const formDataRequest = new FormData();
    const typedRequestObject: { [key: string]: any } = requestObject;
    if (formData.insuranceCardSlideOne) {
      formDataRequest.append(
        "insuranceSide1File",
        formData.insuranceCardSlideOne,
      );
    }

    if (formData.insuranceCardSlideTwo) {
      formDataRequest.append(
        "insuranceSide2File",
        formData.insuranceCardSlideTwo,
      );
    }

    Object.keys(typedRequestObject).forEach((key) => {
      const value = typedRequestObject[key];

      if (value instanceof File) {
        formDataRequest.append(key, value);
      } else {
        formDataRequest.append(key, JSON.stringify(value));
      }
    });

    const response = await createInsurance(formDataRequest);

    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all users.",
      details: error.message,
    };
  }
}

export async function updateInsuranceForPatient(
  formData: PatientFormData,
  insuranceId: string,
): Promise<any> {
  try {
    const requestObject = {
      insuranceData: {
        relationToPatient: formData.relation,
        ...formData,
        termedDate: formatDate(formData.termedDate),
        enrollmentDate: formatDate(formData.enrollmentDate),
      },
    };
    if (requestObject.insuranceData.coverage === "") {
      delete requestObject.insuranceData.coverage;
    }
    if (requestObject.insuranceData.relation === "") {
      delete requestObject.insuranceData.relation;
    }

    if (
      requestObject &&
      requestObject.insuranceData &&
      requestObject.insuranceData.selectedImage
    ) {
      delete requestObject.insuranceData.selectedImage;
    }
    const formDataRequest = new FormData();
    const typedRequestObject: { [key: string]: any } = requestObject;
    if (formData.insuranceCardSlideOne) {
      formDataRequest.append(
        "insuranceSide1File",
        formData.insuranceCardSlideOne,
      );
    }

    if (formData.insuranceCardSlideTwo) {
      formDataRequest.append(
        "insuranceSide2File",
        formData.insuranceCardSlideTwo,
      );
    }

    Object.keys(typedRequestObject).forEach((key) => {
      const value = typedRequestObject[key];
      if (value instanceof File) {
        formDataRequest.append(key, value);
      } else if (value !== "") {
        formDataRequest.append(key, JSON.stringify(value));
      }
    });

    const response = await updateInsurance(formDataRequest, insuranceId);

    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all users.",
      details: error.message,
    };
  }
}
export async function getInsuranceImagesForPatient(
  insuranceId: string,
): Promise<any> {
  try {
    if (insuranceId) {
      const slideOneUrl = await getFirstSlideView(insuranceId);
      const slideTwoUrl = await getSecondSlideView(insuranceId);
      return {
        slide1: slideOneUrl === false ? "" : slideOneUrl,
        slide2: slideTwoUrl === false ? "" : slideTwoUrl,
      };
    }
    return false;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all users.",
      details: error.message,
    };
  }
}

export async function getPatientInsurance(patientId: string): Promise<any> {
  try {
    if (patientId) {
      const primaryInsurance = await getInsuranceForPatient(
        patientId,
        "Primary",
      );
      const secondaryInsurance = await getInsuranceForPatient(
        patientId,
        "Secondary",
      );
      const insuranceData = {
        primary: { slideOne: "", slideTwo: "" },
        secondary: { slideOne: "", slideTwo: "" },
      };
      if (primaryInsurance.status) {
        insuranceData.primary = primaryInsurance.data;
        const insuranceImage = await getInsuranceImagesForPatient(
          primaryInsurance.data.insuranceId,
        );
        insuranceData.primary.slideOne = insuranceImage.slide1;
        insuranceData.primary.slideTwo = insuranceImage.slide2;
      }

      if (secondaryInsurance.status) {
        insuranceData.secondary = secondaryInsurance.data;
        const insuranceImage = await getInsuranceImagesForPatient(
          secondaryInsurance.data.insuranceId,
        );
        insuranceData.secondary.slideOne = insuranceImage.slide1;
        insuranceData.secondary.slideTwo = insuranceImage.slide2;
      }

      return insuranceData;
    }
    return false;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all users.",
      details: error.message,
    };
  }
}

export async function deleteInsuranceForPatient(
  insuranceId: string,
): Promise<any> {
  try {
    if (insuranceId) {
      const response = await deletePatientInsurance(insuranceId);
      return response;
    }
    return false;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching deleting Insurance.",
      details: error.message,
    };
  }
}
