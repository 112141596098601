import { useDispatch, useSelector } from "react-redux";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Input } from "../../../atom/Input/Input";
import { InputDropdown } from "../../../atom/InputDropDown/InputDropDown";
import { Button } from "../../../atom/Buttons/Button";
import { getOptionsForDropdown } from "../../../../services/dropDownMenuOptions/dropDownMenuOptions";
import {
  createCarePlan,
  getMembersDetails,
  updateCarePlan,
} from "../../../../services/carePlan/carePlan";
import { initCarePlanDetails } from "../../../../redux/activeCarePlan/activeCarePlan";
import { showToastMessage } from "../../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../../constants/variables";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../../services/utils/validateDataAsPerRules";
import { validationRules } from "./helperFunctions/constants";
import { formatDate } from "../../../../services/utils/formatDate";
import { FullScreenLoader } from "../../../atom/FullScreenLoader/FullScreenLoader";
import { getIdByLegalValue } from "./helperFunctions/getIdByLegalValue";

const defaultFormValues = {
  memberId: "",
  dob: "",
  primaryLanguage: "",
  currentRiskLevel: "",
  gender: "",
  homePhone: "",
  mobilePhone: "",
  primaryPhone: "",
  emailAddress: "",
  mailingAddress: "",
  bestTimeToReach: "",
  enrollmentStatus: "",
  enrollmentDate: "",
  dueDate: "",
  lastModifiedOn: "",
  lastModifiedBy: "",
  createdOn: "",
  createdBy: "",
  status: "",
  address1: "",
  address2: "",
};

interface MemberDetailProps {
  isViewMode?: boolean;
}

export function MemberDetail({ isViewMode }: MemberDetailProps) {
  const activePatient = useSelector((state: any) => state.activePatient);
  const carePlanDetails = useSelector((state: any) => state.activeCarePlan);
  const dispatch = useDispatch();
  const [genderIdentity, setGenderIdentity] = useState([]);
  const [carePlanEditFlag, setCarePlanEditFlag] = useState(false);
  const [currentRiskLevel, setCurrentRiskLevel] = useState([]);
  const [statusOptionList, setStatusOptionList] = useState([]);
  const [bestTimeToReactOptionList, setBestTimeToReactOptionList] = useState(
    [],
  );
  const [enrollmentStatusOptionList, setEnrollmentStatusOptionList] = useState(
    [],
  );
  const [fullScreenLoaderFlag, setFullScreenLoaderFlag] = useState(false);
  const [formData, setFormData] = useState({
    values: defaultFormValues,
    errors: defaultFormValues,
  });

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;

    const fieldError = validateFieldAsPerRules({
      rules: validationRules,
      fieldName: name,
      value,
    });

    setFormData({
      values: { ...formData.values, [name]: value },
      errors: { ...formData.errors, [name]: fieldError },
    });
  };

  const handleReset = () => {
    setFormData({
      values: {
        ...formData.values,
        currentRiskLevel: "",
        enrollmentDate: "",
        dueDate: "",
        bestTimeToReach: "",
        enrollmentStatus: "",
      },
      errors: defaultFormValues,
    });
  };

  const fetchData = async () => {
    setFullScreenLoaderFlag(true);
    const genderIdentityResp = await getOptionsForDropdown("genderIdentity");
    setGenderIdentity(genderIdentityResp);

    const currentRiskLevelResp =
      await getOptionsForDropdown("currentRiskLevel");
    setCurrentRiskLevel(currentRiskLevelResp);

    const bestTimeToReactResp = await getOptionsForDropdown("bestTimeToReach");
    setBestTimeToReactOptionList(bestTimeToReactResp);
    const enrollmentStatusResp =
      await getOptionsForDropdown("enrollmentStatus");
    setEnrollmentStatusOptionList(enrollmentStatusResp);
    const statusOptionListResponse =
      await getOptionsForDropdown("CarePlanStatus");
    setStatusOptionList(statusOptionListResponse);
    const { carePlanId } = carePlanDetails.carePlanDetails;

    const getMemberDataAPIResponse = await getMembersDetails(
      activePatient.patientDetails.id,
      carePlanId,
    );
    let carePlanStatusValue;
    if (getMemberDataAPIResponse.status) {
      carePlanStatusValue = getMemberDataAPIResponse.data.carePlanStatus;
      if (getMemberDataAPIResponse.data.carePlanStatus === null) {
        carePlanStatusValue = getIdByLegalValue(
          "Not started",
          statusOptionListResponse,
        );
      }
      setFormData({
        ...formData,
        values: {
          ...formData.values,
          memberId: getMemberDataAPIResponse.data.memberId,
          primaryPhone: getMemberDataAPIResponse.data.primaryPhone,
          emailAddress: getMemberDataAPIResponse.data.emailAddress,
          dob: formatDate(
            getMemberDataAPIResponse.data.memberDob,
            "YYYY-MM-DD",
          ),
          gender: getMemberDataAPIResponse.data.gender,
          currentRiskLevel: getMemberDataAPIResponse.data.currentRiskLevel,
          primaryLanguage: getMemberDataAPIResponse.data.primaryLanguageValue,
          mobilePhone: getMemberDataAPIResponse.data.mobilePhone,
          enrollmentDate: formatDate(
            getMemberDataAPIResponse.data.enrollmentDate,
            "YYYY-MM-DD",
          ),
          enrollmentStatus: getMemberDataAPIResponse.data.enrollmentStatus,
          dueDate: formatDate(
            getMemberDataAPIResponse.data.dueDate,
            "YYYY-MM-DD",
          ),
          bestTimeToReach: getMemberDataAPIResponse.data.bestTimeToReach,
          createdBy: getMemberDataAPIResponse.data.createdBy,
          createdOn: getMemberDataAPIResponse.data.createdOn,
          lastModifiedBy: getMemberDataAPIResponse.data.lastModifiedBy,
          lastModifiedOn: getMemberDataAPIResponse.data.lastModifiedOn,
          address1: getMemberDataAPIResponse.data.address1,
          address2: getMemberDataAPIResponse.data.address2,
          status: carePlanStatusValue,
        },
      });
    }
    if (getMemberDataAPIResponse.data.carePlanStatusValue) {
      setFormData({
        ...formData,
        values: {
          ...formData.values,
          memberId: getMemberDataAPIResponse.data.memberId,
          primaryPhone: getMemberDataAPIResponse.data.primaryPhone,
          emailAddress: getMemberDataAPIResponse.data.emailAddress,
          dob: formatDate(
            getMemberDataAPIResponse.data.memberDob,
            "YYYY-MM-DD",
          ),
          gender: getMemberDataAPIResponse.data.gender,
          currentRiskLevel: getMemberDataAPIResponse.data.currentRiskLevel,
          primaryLanguage: getMemberDataAPIResponse.data.primaryLanguageValue,
          mobilePhone: getMemberDataAPIResponse.data.mobilePhone,
          enrollmentDate: formatDate(
            getMemberDataAPIResponse.data.enrollmentDate,
            "YYYY-MM-DD",
          ),
          enrollmentStatus: getMemberDataAPIResponse.data.enrollmentStatus,
          dueDate: formatDate(
            getMemberDataAPIResponse.data.dueDate,
            "YYYY-MM-DD",
          ),
          bestTimeToReach: getMemberDataAPIResponse.data.bestTimeToReach,
          createdBy: getMemberDataAPIResponse.data.createdBy,
          createdOn: getMemberDataAPIResponse.data.createdOn,
          lastModifiedBy: getMemberDataAPIResponse.data.lastModifiedBy,
          lastModifiedOn: getMemberDataAPIResponse.data.lastModifiedOn,
          address1: getMemberDataAPIResponse.data.address1,
          address2: getMemberDataAPIResponse.data.address2,
          status: carePlanStatusValue,
        },
      });
      setCarePlanEditFlag(true);
    } else {
      setCarePlanEditFlag(false);
    }
    setFullScreenLoaderFlag(false);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formErrors = validateDataAsPerRules({
      formData: formData.values,
      rules: validationRules,
    });

    if (Object.keys(formErrors).length) {
      setFormData({ ...formData, errors: formErrors });
      showToastMessage(
        "Please fill all the required fields with proper data",
        TOAST_TYPE_OPTIONS.ERROR,
      );
      return;
    }

    const requestObject = {
      ...formData,
      patientId: activePatient.patientDetails.id,
    };
    let createNewPlanResponse;
    if (carePlanEditFlag) {
      const { carePlanId } = carePlanDetails.carePlanDetails;
      createNewPlanResponse = await updateCarePlan(requestObject, carePlanId);
    } else {
      createNewPlanResponse = await createCarePlan(requestObject);
      setFormData({
        ...formData,
        values: {
          ...formData.values,
          memberId: createNewPlanResponse.data.memberId,
          primaryPhone: createNewPlanResponse.data.primaryPhone,
          emailAddress: createNewPlanResponse.data.emailAddress,
          dob: formatDate(createNewPlanResponse.data.memberDob, "YYYY-MM-DD"),
          gender: createNewPlanResponse.data.gender,
          currentRiskLevel: createNewPlanResponse.data.currentRiskLevel,
          primaryLanguage: createNewPlanResponse.data.primaryLanguageValue,
          mobilePhone: createNewPlanResponse.data.mobilePhone,
          enrollmentDate: formatDate(
            createNewPlanResponse.data.enrollmentDate,
            "YYYY-MM-DD",
          ),
          enrollmentStatus: createNewPlanResponse.data.enrollmentStatus,
          dueDate: formatDate(createNewPlanResponse.data.dueDate, "YYYY-MM-DD"),
          bestTimeToReach: createNewPlanResponse.data.bestTimeToReach,
          createdBy: createNewPlanResponse.data.createdBy,
          createdOn: createNewPlanResponse.data.createdOn,
          lastModifiedBy: createNewPlanResponse.data.lastModifiedBy,
          lastModifiedOn: createNewPlanResponse.data.lastModifiedOn,
          address1: createNewPlanResponse.data.address1,
          address2: createNewPlanResponse.data.address2,
          status: createNewPlanResponse.data.carePlanStatus,
        },
      });
      setCarePlanEditFlag(true);
    }

    if (createNewPlanResponse.status) {
      showToastMessage(
        createNewPlanResponse.message,
        TOAST_TYPE_OPTIONS.SUCCESS,
      );
      dispatch(
        initCarePlanDetails({
          carePlanId: createNewPlanResponse.data.carePlanId,
        }),
      );
    } else {
      showToastMessage(createNewPlanResponse.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <FullScreenLoader show={fullScreenLoaderFlag} />
      <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-lg-4 col-sm-4">
          <Input
            className="form-control form-control-sm "
            placeholder="xxxxxxxx"
            disabled
            value={formData.values.memberId}
            label={validationRules.memberId.displayName}
            error={formData.errors.memberId}
            onChange={handleChange}
            name="memberId"
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            className="form-control form-control-sm "
            placeholder="Enter Language"
            disabled
            onChange={handleChange}
            value={formData.values.primaryLanguage}
            label={validationRules.primaryLanguage.displayName}
            error={formData.errors.primaryLanguage}
            name="primaryLanguage"
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            className="form-control form-control-sm "
            placeholder="xxx-xxx-xxxx"
            disabled
            onChange={handleChange}
            value={formData.values.mobilePhone}
            label={validationRules.mobilePhone.displayName}
            error={formData.errors.mobilePhone}
            name="mobilePhone"
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            type="tel"
            className="form-control form-control-sm"
            placeholder="xxx-xxx-xxxx"
            disabled
            onChange={handleChange}
            value={formData.values.primaryPhone}
            label={validationRules.primaryPhone.displayName}
            error={formData.errors.primaryPhone}
            name="primaryPhone"
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            type="email"
            className="form-control form-control-sm"
            placeholder="Enter email"
            disabled
            onChange={handleChange}
            value={formData.values.emailAddress}
            label={validationRules.emailAddress.displayName}
            error={formData.errors.emailAddress}
            name="emailAddress"
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            type="date"
            className="form-control form-control-sm"
            value={formData.values.dob}
            label={validationRules.dob.displayName}
            error={formData.errors.dob}
            onChange={handleChange}
            name="dob"
            disabled
            required={validationRules.dob.required}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <InputDropdown
            options={genderIdentity}
            className="form-select form-select-sm"
            onSelect={handleChange}
            name="gender"
            disabled
            value={formData.values.gender}
            label={validationRules.gender.displayName}
            error={formData.errors.gender}
            required={validationRules.gender.required}
          />
        </div>
        <div className="col-lg-8 col-sm-8">
          <Input
            type="text"
            className="form-control form-control-sm"
            value={formData.values.address1}
            label={validationRules.address1.displayName}
            error={formData.errors.address1}
            required={validationRules.address1.required}
            onChange={handleChange}
            name="address1"
            disabled
          />
        </div>
        <div className="col-lg-8 col-sm-8">
          <Input
            type="text"
            className="form-control form-control-sm"
            value={formData.values.address2}
            label={validationRules.address2.displayName}
            error={formData.errors.address2}
            required={validationRules.address2.required}
            onChange={handleChange}
            name="address2"
            disabled
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <InputDropdown
            options={currentRiskLevel}
            onSelect={handleChange}
            className="form-select form-select-sm"
            value={formData.values.currentRiskLevel}
            label={validationRules.currentRiskLevel.displayName}
            error={formData.errors.currentRiskLevel}
            name="currentRiskLevel"
            required={validationRules.currentRiskLevel.required}
            disabled={isViewMode}
          />
        </div>

        <div className="col-lg-4 col-sm-4">
          <InputDropdown
            options={bestTimeToReactOptionList}
            onSelect={handleChange}
            className="form-select form-select-sm"
            value={formData.values.bestTimeToReach}
            label={validationRules.bestTimeToReach.displayName}
            error={formData.errors.bestTimeToReach}
            name="bestTimeToReach"
            required={validationRules.bestTimeToReach.required}
            disabled={isViewMode}
          />
        </div>

        <div className="col-lg-4 col-sm-4">
          <InputDropdown
            options={enrollmentStatusOptionList}
            className="form-select form-select-sm w-100"
            onSelect={handleChange}
            name="enrollmentStatus"
            value={formData.values.enrollmentStatus}
            required={validationRules.enrollmentStatus.required}
            label={validationRules.enrollmentStatus.displayName}
            error={formData.errors.enrollmentStatus}
            disabled={isViewMode}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            type="date"
            className="form-control form-control-sm w-100"
            onChange={handleChange}
            value={formData.values.enrollmentDate}
            name="enrollmentDate"
            fullWidth
            label={validationRules.enrollmentDate.displayName}
            required={validationRules.enrollmentDate.required}
            disabled={isViewMode}
          />

          {(formData.errors.enrollmentStatus ||
            formData.errors.enrollmentDate) && (
            <p className="text-danger fs-xs">
              {formData.errors.enrollmentDate}
            </p>
          )}
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            type="date"
            className="form-control form-control-sm mt-1"
            onChange={handleChange}
            value={formData.values.dueDate}
            label={validationRules.dueDate.displayName}
            error={formData.errors.dueDate}
            name="dueDate"
            required={validationRules.dueDate.required}
            disabled={isViewMode}
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <InputDropdown
            options={statusOptionList}
            className="form-select form-select-sm"
            onSelect={handleChange}
            name="status"
            value={formData.values.status}
            label={validationRules.status.displayName}
            error={formData.errors.status}
            required={validationRules.status.required}
            disabled
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            className="form-control form-control-sm "
            placeholder="Created By"
            disabled
            onChange={handleChange}
            value={formData.values.createdBy}
            label={validationRules.createdBy.displayName}
            error={formData.errors.createdBy}
            name="createdBy"
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            className="form-control form-control-sm "
            placeholder="Created On"
            disabled
            onChange={handleChange}
            value={formData.values.createdOn}
            label={validationRules.createdOn.displayName}
            error={formData.errors.createdOn}
            name="createdOn"
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            className="form-control form-control-sm "
            placeholder="Last Modified By"
            disabled
            onChange={handleChange}
            value={formData.values.lastModifiedBy}
            label={validationRules.lastModifiedBy.displayName}
            error={formData.errors.lastModifiedBy}
            name="lastModifiedBy"
          />
        </div>
        <div className="col-lg-4 col-sm-4">
          <Input
            className="form-control form-control-sm "
            placeholder="Last Modified On"
            disabled
            onChange={handleChange}
            value={formData.values.lastModifiedOn}
            label={validationRules.lastModifiedOn.displayName}
            error={formData.errors.lastModifiedOn}
            name="lastModifiedOn"
          />
        </div>
        <div className="col-12 d-flex justify-content-between mt-5">
          {carePlanEditFlag ? (
            <div />
          ) : (
            <Button
              className="btn btn-danger btn-sm"
              type="reset"
              onClick={handleReset}
              label="Clear"
            />
          )}
          {isViewMode ? (
            <div />
          ) : (
            <Button
              type="submit"
              variant="outlined"
              className="btn btn-primary btn-sm px-4"
              label={carePlanEditFlag ? "Update & continue" : "Save & Continue"}
            />
          )}
        </div>
      </form>
    </div>
  );
}
