import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AccordionAtom } from "../../atom/Accordion/Accordion";
import { Button } from "../../atom/Buttons/Button";
import { CaseNoteDetails } from "./CaseNoteDetails/CaseNoteDetails";
import { ChiefComplaints } from "./ChiefComplaints/ChiefComplaints";
import { HealthConcerns } from "./HealthConcerns/HealthConcerns";
import { Vitals } from "./Vitals/Vitals";
import { Medications } from "./Medications/Medications";
import { Plan } from "./Plan/Plan";
import { finalizeCaseNote } from "../../../services/caseNotes/caseNotes";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import { ModalComponent } from "../../molecule/Modal/ModalComponent";

interface CreateNewCaseNotesProps {
  toggleAddCaseNotes: (state: boolean) => void;
  fetchTableData: () => void;
  isViewMode: boolean;
}

export function CreateNewCaseNotes({
  toggleAddCaseNotes,
  fetchTableData,
  isViewMode,
}: CreateNewCaseNotesProps) {
  const caseNotesDetails = useSelector((state: any) => state.activeCaseNotes);
  const caseNoteId = caseNotesDetails?.caseNotesDetails?.caseNoteId;
  const [tabData, setTabData] = useState([
    {
      id: "1",
      title: "Case Note Details",
      content: <CaseNoteDetails isViewMode={isViewMode} />,
    },
    {
      id: "2",
      title: "Chief Complaints",
      content: <ChiefComplaints isViewMode={isViewMode} />,
      disabled: true,
    },
    {
      id: "3",
      title: "Health Concerns",
      content: <HealthConcerns isViewMode={isViewMode} />,
      disabled: true,
    },
    {
      id: "4",
      title: "Vitals",
      content: <Vitals isViewMode={isViewMode} />,
      disabled: true,
    },
    {
      id: "5",
      title: "Medications",
      content: <Medications isViewMode={isViewMode} />,
      disabled: true,
    },
    {
      id: "7",
      title: "Plan",
      content: <Plan isViewMode={isViewMode} />,
      disabled: true,
    },
  ]);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    toggleAddCaseNotes(false);
    fetchTableData();
  };

  const handleConfirmSubmit = async () => {
    if (caseNoteId) {
      const createNewCaseNoteResponse = await finalizeCaseNote({
        caseNoteId,
      });

      if (createNewCaseNoteResponse.status) {
        showToastMessage(
          createNewCaseNoteResponse.message,
          TOAST_TYPE_OPTIONS.SUCCESS,
        );
      } else {
        showToastMessage(
          createNewCaseNoteResponse.message,
          TOAST_TYPE_OPTIONS.ERROR,
        );
      }
    }
    handleClose();
  };

  const handleSubmit = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (caseNoteId) {
      setTabData((prevTabData) =>
        prevTabData.map((tab) => ({
          ...tab,
          disabled: false,
        })),
      );
    }
  }, [caseNotesDetails]);

  return (
    <>
      <AccordionAtom items={tabData} />
      <div className="col-12 d-flex justify-content-between mt-3">
        <Button
          onClick={handleClose}
          variant="outlined"
          className="btn btn-danger btn-sm"
          label="Close"
        />
        {showModal && (
          <Button
            onClick={handleSubmit}
            variant="outlined"
            className="btn btn-primary btn-sm px-4"
            label="Review & Complete"
          />
        )}
        {isViewMode ? (
          <div />
        ) : (
          <ModalComponent
            buttonShow="Review & Complete"
            buttonLeft="No"
            buttonRight="Yes"
            title="Confirmation"
            onSubmit={handleConfirmSubmit}
            showAddUserPopup={showModal}
            setShowAddUserPopup={setShowModal}
          >
            <p>
              Case Notes data would be finalized without any further edits and
              required information would be updated in the Care plan
            </p>
          </ModalComponent>
        )}
      </div>
    </>
  );
}
