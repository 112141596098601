import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "../../../components/atom/Table/Table";
import { Card } from "../../../components/atom/Cards/Cards";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import {
  getAllLabResultServices,
  deleteLabResultService,
  getLabResultByIdService,
} from "../../../services/labAndDiagnosticResult/labResult";
import { Pagination } from "../../../components/atom/Pagination/Pagination";
import { Button } from "../../../components/atom/Buttons/Button";

import { CreateLabResult } from "../../../components/organism/CreateLabResult/CreateLabResult";
import { FullScreenLoader } from "../../../components/atom/FullScreenLoader/FullScreenLoader";
import Logger from "../../../utils/Logger";

interface LabResultProps {
  displayLabel: string;
}
export function LabResult({ displayLabel }: LabResultProps) {
  interface CareTeamListType {
    patientId: string;
    patientName: string;
    documentName: string;
    labType: string;
    date: string;
    providerName: string;
    comment: string;
  }

  const [showTable, setShowTable] = useState(true);
  const [fullScreenLoaderStatus, setFullScreenLoaderStatus] = useState(false);
  const activePatient = useSelector((state: any) => state.activePatient);

  const [labResultDataForTable, setLabResultDataForTable] = useState<
    CareTeamListType[]
  >([]);
  const [editLabData, setEditLabData] = useState();
  const [paginationData, setPaginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });
  const headersForTable: Record<string, string> = {
    patientName: "Patient Name",
    documentName: "Document Name",
    UploadDocument: "Upload Document",
    labType: "Lab Type",
    labDate: "Date",
    providerName: "Provider Name",
    comment: "Comment",
    labResultPath: "Lab Result Path",
    action: "Action",
  };
  const sortingHeaderMap: Record<string, string> = {
    patientName: "patientName",
    documentName: "documentName",
    labType: "labType",
    labDate: "Date",
    providerName: "providerName",
    comment: "comment",
    labResultPath: "labResultPath",
  };

  const toggleTableStatus = () => {
    setEditLabData(undefined);
    setShowTable(!showTable);
  };

  const getAllLabResultDataForTable = async (newPaginationData?: any) => {
    try {
      const paginationDataAsParameter = newPaginationData || paginationData;
      const patientId = activePatient.patientDetails.id;
      if (patientId !== "") {
        const appLabResultListResponse = await getAllLabResultServices({
          paginationDataAsParameter,
          patientId,
        });
        if (appLabResultListResponse.isSuccess) {
          setPaginationData({
            ...paginationData,
            pageNo: appLabResultListResponse.pageNo,
            totalPages: appLabResultListResponse.totalPages,
            totalElements: appLabResultListResponse.totalElements,
            pageSize: appLabResultListResponse.pageSize,
          });
          const { dataList } = appLabResultListResponse;
          if (dataList) {
            setLabResultDataForTable(appLabResultListResponse.dataList);
          } else {
            setLabResultDataForTable([]);
          }
        } else {
          setLabResultDataForTable([]);
        }
      }
    } catch (error) {
      showToastMessage("Error fetching all users:", TOAST_TYPE_OPTIONS.ERROR);
      console.error("Error fetching all users:", error);
    }
  };

  const deleteLabResultValue = async (rowData: any) => {
    Logger.logInfo("Deleting Lab Result", { data: rowData });
    const deleteLabResultResponse = await deleteLabResultService(rowData.id);
    Logger.logInfo("Deleting Lab Result Response", {
      data: deleteLabResultResponse,
    });
    if (deleteLabResultResponse.status) {
      showToastMessage(
        deleteLabResultResponse.message,
        TOAST_TYPE_OPTIONS.SUCCESS,
      );
      getAllLabResultDataForTable();
    } else {
      showToastMessage(
        deleteLabResultResponse.message,
        TOAST_TYPE_OPTIONS.ERROR,
      );
    }
  };

  const editRowAction = async (rowData: any) => {
    setFullScreenLoaderStatus(true);
    const labResultData = await getLabResultByIdService(rowData.id);
    setFullScreenLoaderStatus(false);
    if (labResultData.status) {
      setShowTable(false);
      setEditLabData(labResultData.data);
    } else {
      showToastMessage(labResultData.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };
  useEffect(() => {
    getAllLabResultDataForTable();
  }, []);

  useEffect(() => {
    if (!activePatient.patientDetails.id) {
      setLabResultDataForTable([]);
    }
  }, [activePatient]);

  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize,
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    getAllLabResultDataForTable({ ...paginationData, pageNo, pageSize });
  };

  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    getAllLabResultDataForTable({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };

  const onSubmitAction = () => {
    setShowTable(true);
    getAllLabResultDataForTable();
  };

  return (
    <div>
      <FullScreenLoader show={fullScreenLoaderStatus} />
      <Card>
        <div className="card-title d-flex justify-content-between align-items-center">
          <h5>{displayLabel}</h5>
          <div className="d-flex text-end">
            {showTable ? (
              <Button
                variant="primary"
                className="primary btn btn-primary d-flex align-items-center  py-1 float-end btn-sm px-5 ms-3"
                onClick={toggleTableStatus}
              >
                <i className="fa fa-plus me-2 fs-5" /> Add Lab Result
              </Button>
            ) : (
              <Button
                variant="primary"
                className="primary btn btn-primary d-flex align-items-center  py-1 float-end btn-sm px-5 ms-3"
                onClick={toggleTableStatus}
                label="Back"
              />
            )}
          </div>
        </div>

        {showTable ? (
          <div className="table-responsive care-manager-table">
            <Table
              className="table table-bordered table-striped table-hover"
              data={labResultDataForTable}
              headers={headersForTable}
              enableDeleteAction={deleteLabResultValue}
              enableEditAction={(rowData) => {
                editRowAction(rowData);
              }}
              sortingHeaderMap={sortingHeaderMap}
              sortingFunction={sortingFunction}
            />
            {paginationData.totalPages >= 0 && (
              <Pagination
                currentPage={paginationData.pageNo}
                totalPages={paginationData.totalPages}
                pageSize={paginationData.pageSize}
                onPageChange={(page, pageSize) => {
                  updatePaginationData(page, pageSize);
                }}
              />
            )}
          </div>
        ) : (
          <CreateLabResult
            editLabData={editLabData}
            onSubmitAction={onSubmitAction}
          />
        )}
      </Card>
    </div>
  );
}
