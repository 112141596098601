import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreateNewCarePlan } from "../../../components/organism/CreateNewCarePlan/CreateNewCarePlan";
import { ProfileCard } from "../../../components/molecule/ProfileCard/ProfileCard";
import { Card } from "../../../components/atom/Cards/Cards";
import { Table } from "../../../components/atom/Table/Table";

import { Button } from "../../../components/atom/Buttons/Button";
import {
  deleteCarePlanService,
  getListOfCarePlanForPatient,
} from "../../../services/carePlan/carePlan";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";

import {
  // initCarePlanDetails,
  setCarePlanEditFlag,
} from "../../../redux/activeCarePlan/activeCarePlan";
import { Pagination } from "../../../components/atom/Pagination/Pagination";

const sortingHeaderMap: Record<string, string> = {
  memberId: "cin",
  memberName: "MemberName",
  memberDob: "MemberDOB",
  currentRiskLevelValue: "RiskLevel",
  enrollmentStatus: "EnrollmentStatus",
  statusDate: "date",
  dueDate: "dueDate",
  createdOn: "CreatedOn",
  lastModifiedOn: "lastModifiedOn",
};

export const tableHeaders = {
  memberName: "Member Name",
  memberDob: "Member DOB",
  currentRiskLevelValue: "Risk level",
  enrollmentStatusValue: "Enrollment status",
  createdOn: "Created Date",
  lastModifiedOn: "Last Modified Date",
  dueDate: "Due date",
  action: "Action",
};

export function CarePlan() {
  const activePatient = useSelector((state: any) => state.activePatient);
  const [allCarePlansData, setAllCarePlansData] = useState<any>([]);
  const [isAddNewCarePlan, setIsAddNewCarePlan] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const [paginationData, setPaginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });

  const dispatch = useDispatch();

  const handleAddNewCarePlan = () => {
    dispatch(
      setCarePlanEditFlag({
        editCarePlanStatus: false,
        carePlanId: 0,
      })
    );
    setIsAddNewCarePlan(!isAddNewCarePlan);
  };

  const fetchData = async (newPaginationData?: any) => {
    const paginationDataAsParameter = newPaginationData || paginationData;
    if (!activePatient.patientDetails.id) {
      return;
    }
    const listOfCarePlanResponse = await getListOfCarePlanForPatient({
      paginationDataAsParameter,
      patientId: activePatient.patientDetails.id,
    });
    if (listOfCarePlanResponse && listOfCarePlanResponse.status) {
      setAllCarePlansData(listOfCarePlanResponse.data.dataList);
      setPaginationData({
        ...paginationData,
        pageNo: listOfCarePlanResponse.data.pageNo,
        totalPages: listOfCarePlanResponse.data.totalPages,
        totalElements: listOfCarePlanResponse.data.totalElements,
        pageSize: listOfCarePlanResponse.data.pageSize,
      });
    }
  };

  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    fetchData({ ...paginationData, pageNo, pageSize });
  };

  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    fetchData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };

  const deleteCarePlan = async (rowData: any) => {
    if (rowData.carePlanStatusValue === "Completed") {
      showToastMessage(
        "Cannot Deleted completed care plan",
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }
    const deleteCarePlanResponse = await deleteCarePlanService(
      rowData.carePlanId
    );
    if (deleteCarePlanResponse.status) {
      showToastMessage(
        deleteCarePlanResponse.message,
        TOAST_TYPE_OPTIONS.SUCCESS
      );
      fetchData();
    } else {
      showToastMessage(
        deleteCarePlanResponse.message,
        TOAST_TYPE_OPTIONS.ERROR
      );
    }
  };
  const editCarePlan = async (rowData: any) => {
    setIsAddNewCarePlan(!isAddNewCarePlan);
    dispatch(
      setCarePlanEditFlag({
        editCarePlanStatus: true,
        carePlanId: rowData.carePlanId,
      })
    );
  };

  const enableViewAction = (rowData: any) => {
    dispatch(
      setCarePlanEditFlag({
        editCarePlanStatus: true,
        carePlanId: rowData.carePlanId,
      })
    );
    setIsViewMode(true);
    setIsAddNewCarePlan(true);
  };
  const isActionDisabled = (rowData: any) => {
    if (rowData.carePlanStatusValue === "Completed") {
      return false;
    }
    return true;
  };
  
  useEffect(() => {
    fetchData();
  }, [activePatient]);

  useEffect(() => {
    if (!activePatient.patientDetails.id) {
      setAllCarePlansData([]);
    }
  }, [activePatient]);

  const toggleAddCarePlan = (state: boolean) => {
    setIsViewMode(state);
    setIsAddNewCarePlan(state);
  };
  return (
    <>
      <ProfileCard />
      <Card>
        <div className="d-flex justify-content-between">
          <h3>Care Plan</h3>
          {!isAddNewCarePlan && (
            <Button
              variant="primary"
              onClick={handleAddNewCarePlan}
              className="btn btn-primary btn-sm px-2 my-2"
              disabled={activePatient.patientDetails.id === ""}
            >
              <i className="fa fa-plus me-2 fs-5" />
              Add Care Plan
            </Button>
          )}
        </div>

        {isAddNewCarePlan ? (
          <CreateNewCarePlan
            toggleAddCarePlan={toggleAddCarePlan}
            isViewMode={isViewMode}
          />
        ) : (
          <div className="table-responsive care-manager-table">
            <Table
              className="table table-bordered table-striped table-hover"
              data={allCarePlansData}
              headers={tableHeaders}
              enableDeleteAction={deleteCarePlan}
              sortingFunction={sortingFunction}
              sortingHeaderMap={sortingHeaderMap}
              enableEditAction={(rowData) => {
                editCarePlan(rowData);
              }}
              enableViewAction={enableViewAction}
              isDeleteDisabled={isActionDisabled}
              isEditDisabled={isActionDisabled}
            />
            {paginationData.totalPages >= 0 && (
              <Pagination
                currentPage={paginationData.pageNo}
                totalPages={paginationData.totalPages}
                pageSize={paginationData.pageSize}
                onPageChange={(page, pageSize) => {
                  updatePaginationData(page, pageSize);
                }}
              />
            )}
          </div>
        )}
      </Card>
    </>
  );
}
