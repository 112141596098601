import { ValidationRules } from "../../../../services/utils/validateDataAsPerRules";

export const validationRules: ValidationRules = {
  userTitle: {
    required: false,
    dataType: "number",
    characterLimit: 250,
    displayName: "User Title",
  },
  firstName: {
    required: true,
    dataType: "text",
    characterLimit: 50,
    displayName: "First Name",
  },
  middleName: {
    required: false,
    dataType: "text",
    characterLimit: 50,
    displayName: "Middle Name",
  },
  lastName: {
    required: true,
    dataType: "text",
    characterLimit: 50,
    displayName: "Last Name",
  },
  chartNumber: {
    required: false,
    dataType: "any",
    characterLimit: 250,
    displayName: "Chart Number",
  },
  address1: {
    required: true,
    dataType: "textWithNumber",
    characterLimit: 50,
    displayName: "Address 1",
  },
  address2: {
    required: false,
    dataType: "textWithNumber",
    characterLimit: 50,
    displayName: "Address 2",
  },
  country: {
    required: true,
    dataType: "text",
    characterLimit: 250,
    displayName: "Country",
  },
  state: {
    required: true,
    dataType: "text",
    characterLimit: 250,
    displayName: "State",
  },
  city: {
    required: true,
    dataType: "text",
    characterLimit: 250,
    displayName: "City",
  },
  zipCode: {
    required: true,
    dataType: "textOrNumber",
    characterLimit: 250,
    displayName: "Zip Code",
  },
  responsiblePartyName: {
    required: true,
    dataType: "number",
    characterLimit: 250,
    displayName: "Responsible Party Name",
  },
  preferredPhone: {
    required: true,
    dataType: "number",
    characterLimit: 10,
    minCharacterLimit: 10,
    displayName: "Preferred Phone",
  },
  email: {
    required: false,
    dataType: "email",
    characterLimit: 250,
    displayName: "Email",
  },
  patientNotes: {
    required: false,
    dataType: "any",
    characterLimit: 250,
    displayName: "Patient Notes",
  },
  sex: {
    required: true,
    dataType: "number",
    characterLimit: 250,
    displayName: "Sex",
  },
  genderIdentity: {
    required: true,
    dataType: "number",
    characterLimit: 250,
    displayName: "Gender Identity",
  },
  sexualOrientation: {
    required: true,
    dataType: "number",
    characterLimit: 250,
    displayName: "Sexual Orientation",
  },
  dateOfBirth: {
    required: true,
    dataType: "date",
    characterLimit: 250,
    isPastDateAllow: true,
    isFutureDateAllow: false,
    displayName: "Date of Birth",
  },
  maritalStatus: {
    required: true,
    dataType: "number",
    characterLimit: 250,
    displayName: "Marital Status",
  },
  financialClass: {
    required: false,
    dataType: "any",
    characterLimit: 250,
    displayName: "Financial Class",
  },
  pcpName: {
    required: true,
    dataType: "any",
    characterLimit: 250,
    displayName: "PCP Name",
  },
  providerSpeciality: {
    required: false,
    dataType: "any",
    characterLimit: 250,
    displayName: "Provider Specialty",
  },
  specialityProviderId: {
    required: false,
    dataType: "any",
    characterLimit: 250,
    displayName: "Specialty Provider",
  },
  employer: {
    required: false,
    dataType: "any",
    characterLimit: 250,
    displayName: "Employer",
  },
  occupation: {
    required: false,
    dataType: "text",
    characterLimit: 50,
    displayName: "Occupation",
  },
  age: {
    required: false,
    dataType: "any",
    characterLimit: 250,
    displayName: "Age",
  },
  preferredLanguage: {
    required: true,
    dataType: "number",
    characterLimit: 250,
    displayName: "Preferred Language",
  },
  providerStatus: {
    required: false,
    dataType: "any",
    characterLimit: 250,
    displayName: "Provider Status",
  },
  patientAsOfDate: {
    required: false,
    dataType: "any",
    characterLimit: 250,
    displayName: "Patient As Of Date",
  },
  caseManager: {
    required: true,
    dataType: "number",
    characterLimit: 250,
    displayName: "Case Manager",
  },
};
