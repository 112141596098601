import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPatientLabResults } from "../../../../../services/carePlan/carePlan";
import { showToastMessage } from "../../../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../../../constants/variables";
import { Table } from "../../../../atom/Table/Table";
import { Pagination } from "../../../../atom/Pagination/Pagination";

const tableHeaders = {
  documentName: "Document Name",
  labType: "Lab Type",
  labDate: "Date",
  providerName: "Provider",
  comment: "Comment",
};
const sortingHeaderMap: Record<string, string> = {
  patientName: "patientName",
  documentName: "documentName",
  labType: "labType",
  labDate: "Date",
  providerName: "providerName",
  comment: "comment",
  labResultPath: "labResultPath",
};

export function LabResults() {
  const activePatient = useSelector((state: any) => state.activePatient);
  const carePlanDetails = useSelector((state: any) => state.activeCarePlan);

  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortField: string;
    sortOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortField: "",
    sortOrder: "",
  });

  const fetchData = async (newPaginationData?: any) => {
    try {
      const paginationDataAsParameter = newPaginationData || paginationData;
      const patientId = activePatient.patientDetails.id;

      if (patientId !== "") {
        const patientLabResultAPIResponse = await getPatientLabResults({
          paginationDataAsParameter,
          patientId,
          carePlanId: carePlanDetails.carePlanDetails.carePlanId,
        });
        if (patientLabResultAPIResponse.status) {
          setPaginationData({
            ...paginationData,
            pageNo: patientLabResultAPIResponse.data.pageNo,
            totalPages: patientLabResultAPIResponse.data.totalPages,
            totalElements: patientLabResultAPIResponse.data.totalElements,
            pageSize: patientLabResultAPIResponse.data.pageSize,
          });
          setTableData(patientLabResultAPIResponse.data.dataList);
        }
      }
    } catch (error) {
      showToastMessage("Error fetching all users:", TOAST_TYPE_OPTIONS.ERROR);
      console.error("Error fetching all users:", error);
    }
  };

  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize,
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    fetchData({ ...paginationData, pageNo, pageSize });
  };

  const sortingFunction = (fieldName: string, sortOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortField: fieldName,
      sortOrder,
    });
    fetchData({
      ...paginationData,
      sortField: fieldName,
      sortOrder,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="card">
      <div className="card-body">
        <div className="card-title d-flex justify-content-between align-items-center">
          <h5>Lab results</h5>
        </div>
        <Table
          headers={tableHeaders}
          data={tableData}
          className="table table-bordered table-striped table-hover"
          sortingHeaderMap={sortingHeaderMap}
          sortingFunction={sortingFunction}
        />
        {paginationData.totalPages > 0 && (
          <Pagination
            currentPage={paginationData.pageNo}
            totalPages={paginationData.totalPages}
            pageSize={paginationData.pageSize}
            onPageChange={(page, pageSize) => {
              updatePaginationData(page, pageSize);
            }}
          />
        )}
      </div>
    </div>
  );
}
