import React, { useState } from "react";
import { Card } from "../../components/atom/Cards/Cards";
import { Input } from "../../components/atom/Input/Input";
import { Button } from "../../components/atom/Buttons/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { Image } from "../../components/atom/Images/Image";
import "./ForgetPassword.css";
import { forgotPassword } from "../../services/login/login";
import { showToastMessage } from "../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../constants/variables";
import { validateEmail } from "../../services/validation/email";
import Logger from "../../utils/Logger";

export function ForgetPassword() {
  const [emailId, setEmailId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailId(event.target.value);
  };

  const handleForgetPassword = async () => {
    if (!emailId) {
      showToastMessage("Please enter email id", TOAST_TYPE_OPTIONS.ERROR);
      return;
    }
    if (!validateEmail(emailId).status) {
      showToastMessage("Please enter valid email id", TOAST_TYPE_OPTIONS.ERROR);
      return;
    }
    setIsLoading(true);
    Logger.logInfo("ForgotPassword via email ", { data: emailId });
    const response = await forgotPassword({ email: emailId });
    setIsLoading(false);
    if (response.status) {
      Logger.logInfo("Email link send successfully", { data: response });
      showToastMessage(
        "email link send successful",
        TOAST_TYPE_OPTIONS.SUCCESS
      );
    } else {
      Logger.logError("Email link send failed", {
        data: { response, email: emailId },
      });
      showToastMessage(response.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  return (
    <div>
      <Image
        src="assets/images/login-bkg.jpg"
        alt="Image Alt Text"
        className="Login-background-image"
      />
      <Card className="card-container">
        <Image
          src="assets/images/MDTel-logo.png"
          alt="Image Alt Text"
          className="md-tel-logo"
        />
        <p className="fs-4 fw-semibold pt-4 pb-3">Forgot Password</p>
        <div className="mb-2">
          <Input
            type="email"
            id="username"
            value={emailId}
            onChange={handleEmailChange}
            placeholder="Email Id"
            name="email"
            className="input-styling input-margin"
            label="Enter registered email address:"
            labelClassName="mb-2 text-end"
          />
        </div>
        <Button
          label="Send Link"
          onClick={handleForgetPassword}
          className="button default-btn btn btn-gradient-primary send-link-btn"
          isLoading={isLoading}
        />{" "}
      </Card>
    </div>
  );
}
