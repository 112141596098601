import {
  createNewReferral,
  deleteReferralInDetails,
  deleteReferralOutDetails,
  filterReferralInAPI,
  getAllReferralsOut,
  getIcd10DetailsAPI,
  getProcedureCodeDetailsAPI,
  getReferralInByIdDetails,
  getReferredByDetailsAPI,
  getReferredToDetailsAPI,
  updateReferral,
} from "../../dataLayer/referrals/referrals";

export async function getReferralInById(
  referralId: string | number,
): Promise<any> {
  if (!referralId) {
    return {
      status: false,
      code: 500,
      message: "Referral ID is required.",
      details: "Referral ID is required.",
    };
  }

  try {
    const response = await getReferralInByIdDetails(referralId);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error getting referral by Id.",
      details: error.message,
    };
  }
}
export async function getAllReferralsInFilterServices({
  paginationDataAsParameter,
  patientId,
  filters,
}: {
  paginationDataAsParameter: any;
  patientId: any;
  filters: any;
}): Promise<any> {
  try {
    const allReferralsIn = await filterReferralInAPI({
      paginationDataAsParameter,
      patientId,
      filters,
    });
    if (allReferralsIn.status) {
      return allReferralsIn.data;
    }
    return allReferralsIn;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all Filter.",
      details: error.message,
    };
  }
}

interface DeleteUserParams {
  referralId: number;
}
export async function deleteReferralIn(params: DeleteUserParams): Promise<any> {
  const { referralId } = params;

  if (!referralId) {
    return {
      status: false,
      code: 500,
      message: "Referral ID is required.",
      details: "Referral ID is required.",
    };
  }

  try {
    await deleteReferralInDetails(referralId);
    return {
      status: true,
      code: 200,
      message: "Referral deleted successfully.",
    };
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting user.",
      details: error.message,
    };
  }
}

export async function deleteReferralOut(
  params: DeleteUserParams,
): Promise<any> {
  const { referralId } = params;

  if (!referralId) {
    return {
      status: false,
      code: 500,
      message: "Referral ID is required.",
      details: "Referral ID is required.",
    };
  }

  try {
    await deleteReferralOutDetails(referralId);
    return {
      status: true,
      code: 200,
      message: "Referral deleted successfully.",
    };
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting user.",
      details: error.message,
    };
  }
}

export async function getAllReferralsOutFilterServices({
  paginationDataAsParameter,
  patientId,
  filters,
}: {
  paginationDataAsParameter: any;
  patientId: string;
  filters: any;
}): Promise<any> {
  try {
    const allReferralsOut = await getAllReferralsOut({
      paginationDataAsParameter,
      patientId,
      filters,
    });
    if (allReferralsOut.status) {
      return allReferralsOut.data;
    }
    return allReferralsOut;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all Filter.",
      details: error.message,
    };
  }
}
export interface CreateReferralParameter {
  referral: string;
  referredTo: string;
  referredBy: string;
  effectiveDate?: string;
  icd10: string;
  authorizationId?: string;
  organization: string;
  maxAuthorization?: string;
  reason: string;
  notes: string;
  status: string;
  procedureCode: string;
  authStatus: string;
  insCarrier: string;
  referralType: string;
  patientId: string;
}

export async function createReferral(
  formData: CreateReferralParameter,
): Promise<any> {
  try {
    const requestData = {
      referral: formData.referral,
      status: formData.status,
      referredTo: formData.referredTo,
      referredBy: formData.referredBy,
      effectiveDate: formData.effectiveDate,
      icd10: formData.icd10,
      procedureCode: formData.procedureCode,
      authStatus: formData.authStatus,
      authorizationId: formData.authorizationId,
      maxAuthorization: formData.maxAuthorization,
      insCarrier: formData.insCarrier,
      organization: formData.organization,
      reason: formData.reason,
      notes: formData.notes,
      referralType: formData.referralType,
      patientId: formData.patientId,
    };
    if (formData.referralType === "OUT") {
      delete requestData.maxAuthorization;
    }
    const createReferralResponse = await createNewReferral(requestData);
    return createReferralResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error Creating Referrals In.",
      details: error.message,
    };
  }
}
interface UpdateReferralParams {
  userId: number;
  updatedReferralData: Partial<CreateReferralParameter>;
}
export async function updateReferralService(
  params: UpdateReferralParams,
): Promise<any> {
  const { userId, updatedReferralData } = params;

  if (!userId) {
    return {
      status: false,
      code: 500,
      message: "Referral ID is required.",
      details: "Referral ID is required.",
    };
  }

  try {
    const {
      referral,
      referredTo,
      referredBy,
      effectiveDate,
      icd10,
      authorizationId,
      organization,
      maxAuthorization,
      reason,
      notes,
      status,
      procedureCode,
      authStatus,
      insCarrier,
      referralType,
      patientId,
    } = updatedReferralData;

    const requestObject = {
      referral,
      referredTo,
      referredBy,
      effectiveDate,
      icd10,
      authorizationId,
      organization,
      maxAuthorization,
      reason,
      notes,
      status,
      procedureCode,
      authStatus,
      insCarrier,
      referralType,
      patientId,
    };
    const updatedReferral = await updateReferral(userId, requestObject);
    return updatedReferral;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error updating Referral.",
      details: error.message,
    };
  }
}

export async function getProcedureCodeInfoService(
  procedureCode: string | number,
): Promise<any> {
  if (!procedureCode) {
    return {
      status: false,
      code: 500,
      message: "ProcedureCode is required.",
      details: "ProcedureCode is required.",
    };
  }

  try {
    const response = await getProcedureCodeDetailsAPI(procedureCode);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching Procedure Code.",
      details: error.message,
    };
  }
}

export async function getIcd10InfoService(
  icd10: string | number,
): Promise<any> {
  if (!icd10) {
    return {
      status: false,
      code: 500,
      message: "ICD10 is required.",
      details: "ICD10 is required.",
    };
  }

  try {
    const response = await getIcd10DetailsAPI(icd10);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching ICD10 data.",
      details: error.message,
    };
  }
}

export async function getReferredToInfoService(
  referredTo: string | number,
): Promise<any> {
  if (!referredTo) {
    return {
      status: false,
      code: 500,
      message: "Referral id is required.",
      details: "Referral id is required.",
    };
  }

  try {
    const response = await getReferredToDetailsAPI(referredTo);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching referral data.",
      details: error.message,
    };
  }
}
export async function getReferredByInfoService(
  referredBy: string | number,
): Promise<any> {
  if (!referredBy) {
    return {
      status: false,
      code: 500,
      message: "Referral id is required.",
      details: "Referral id is required.",
    };
  }

  try {
    const response = await getReferredByDetailsAPI(referredBy);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching Referral data.",
      details: error.message,
    };
  }
}
