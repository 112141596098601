import React from "react";
import { Link } from "react-router-dom";
import {
  SIDE_MENU_ORDER,
  USER_PERMISSION_SIDE_MENU,
} from "../../../constants/variables";

export interface SideMenuProps {
  userDetails: any;
}

function DisplayItemBasedOnPermission({ userDetails }: { userDetails: any }) {
  const userPermissions: Array<{ id: number; privilegeName?: string }> =
    userDetails?.userPermission?.privilegesSet;
  const orderToDisplay = SIDE_MENU_ORDER;
  if (userPermissions) {
    return orderToDisplay.map((orderItem) => {
      const permissionData = userPermissions.find(
        (permission) => permission.privilegeName === orderItem,
      );
      if (
        permissionData &&
        permissionData.privilegeName &&
        USER_PERMISSION_SIDE_MENU[permissionData.privilegeName]
      ) {
        const menuData =
          USER_PERMISSION_SIDE_MENU[permissionData.privilegeName];
        return (
          <li
            className="nav-item"
            key={`${permissionData.privilegeName}-${menuData.id}`}
          >
            <Link className="nav-link" to={menuData.url}>
              <i className={menuData.classForIcon} />
              <span className="menu-title">{menuData.displayName}</span>
            </Link>
          </li>
        );
      }
      return null;
    });
  }
  return <div />;
}

export function SideMenu({ userDetails }: SideMenuProps) {
  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item">
          <Link className="nav-link" to="/dashboard">
            <i className="mdi mdi-office-building menu-icon" />
            <span className="menu-title">Dashboard</span>
          </Link>
        </li>
        <DisplayItemBasedOnPermission userDetails={userDetails} />
      </ul>
    </nav>
  );
}
