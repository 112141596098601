import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "../../../atom/Table/Table";
import { getAllMedicationOfPatent } from "../../../../services/carePlan/carePlan";

const tableHeaders = {
  medicationName: "Medication Name",
  sig: "SIG",
  prescribersName: "Prescriber's Name",
  startDate: "Start Date",
  stopDate: "Stop Date",
};

const sortingHeaderMap: Record<string, string> = {
  medicationName: "MedicationName",
  sig: "Sig",
  prescribersName: "PrescribersName",
  startDate: "MedicationStartDate",
  stopDate: "MedicationStopDate",
};

export function Medication() {
  const carePlanDetails = useSelector((state: any) => state.activeCarePlan);
  const activePatient = useSelector((state: any) => state.activePatient);
  const [paginationData, setPaginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });
  const [tableData, setTableData] = useState([]);

  const fetchData = async (newPaginationData?: any) => {
    const patientId = activePatient.patientDetails.id;
    const paginationDataAsParameter = newPaginationData || paginationData;
    const medicationAPIResponse = await getAllMedicationOfPatent({
      paginationDataAsParameter,
      patientId,
      carePlanId: carePlanDetails.carePlanDetails.carePlanId,
    });

    if (medicationAPIResponse.status) {
      setTableData(medicationAPIResponse.data.dataList);
      setPaginationData({
        ...paginationData,
        pageNo: medicationAPIResponse.data.pageNo,
        totalPages: medicationAPIResponse.data.totalPages,
        totalElements: medicationAPIResponse.data.totalElements,
        pageSize: medicationAPIResponse.data.pageSize,
      });
    }
  };
  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    fetchData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="accordion-collapse">
      <div className="table-responsive care-manager-table">
        <Table
          headers={tableHeaders}
          data={tableData}
          className="table table-bordered table-striped table-hover"
          sortingFunction={sortingFunction}
          sortingHeaderMap={sortingHeaderMap}
        />
      </div>
    </div>
  );
}
