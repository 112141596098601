/* eslint-disable complexity */
function containsOnlyAlphabets(value: string): boolean {
  return /^[a-zA-Z\s]+$/.test(value);
}

function containsOnlyNumbers(value: string): boolean {
  return /^[0-9]+$/.test(value);
}

function containsTextAndNumbers(value: string): boolean {
  return /[a-zA-Z]+.*[0-9]+|[0-9]+.*[a-zA-Z]+/.test(value);
}
function isValidEmail(email: string): boolean {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}
function containsTextOrNumbers(value: string): boolean {
  return /^[a-zA-Z0-9 ]+$/.test(value);
}
function containsTextWithNumbers(value: string): boolean {
  return /[a-zA-Z]+([0-9 ]+)?/.test(value);
}
function containsNumbersWithSpecialCharacters(value: string): boolean {
  return /^[0-9]+(\.[0-9]+)?$/.test(value);
}
function isPastDate(date: Date): boolean {
  const currentDate = new Date();
  const userDate = new Date(date);
  currentDate.setHours(0, 0, 0, 0);
  userDate.setHours(0, 0, 0, 0);
  return userDate < currentDate;
}

export function isFutureDate(date: Date): boolean {
  const currentDate = new Date();
  const userDate = new Date(date);
  return userDate >= currentDate;
}
function isValidDate(dateString: string) {
  const dateFormat = /^\d{4}-\d{2}-\d{2}$/;

  if (!dateString.match(dateFormat)) {
    return false;
  }

  const parts = dateString.split("-");
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  if (month < 1 || month > 12 || day < 1 || day > 31) {
    return false;
  }

  if (month === 2) {
    if (day > 29) {
      return false;
    }
    if (
      day === 29 &&
      !(year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0))
    ) {
      return false;
    }
  }

  if ([4, 6, 9, 11].includes(month) && day > 30) {
    return false;
  }

  return true;
}

export type ValidationRules = {
  [X: string]: {
    required: boolean;
    dataType:
      | "text"
      | "number"
      | "textAndNumber"
      | "textOrNumber"
      | "textWithNumber"
      | "date"
      | "email"
      | "numberWithSpecialCharacters"
      | "any";
    minCharacterLimit?: number;
    characterLimit?: number;
    minValue?: number; // Added minValue for number validation
    maxValue?: number;
    isPastDateAllow?: boolean;
    isFutureDateAllow?: boolean;
    displayName?: string;
  };
};

export type ValidateFieldAsPerRulesArgs = {
  rules: ValidationRules;
  displayFieldName?: string;
  fieldName: string;
  value: string;
};

export function validateFieldAsPerRules({
  rules,
  displayFieldName,
  fieldName,
  value,
}: ValidateFieldAsPerRulesArgs): string {
  const keyName = fieldName as keyof typeof rules;
  const displayFieldText = rules[keyName]?.displayName ?? displayFieldName;

  if (rules[keyName]) {
    const rule = rules[keyName];
    if (rule.required && !value) {
      if (rules[keyName]?.displayName) {
        return `${rules[keyName]?.displayName} is required`;
      }
      return `${displayFieldText} is required`;
    }
    if (value && rule.characterLimit) {
      if (value.length > rule.characterLimit) {
        return `You have exceeded the ${rule.characterLimit} character limit`;
      }
      if (rule.minCharacterLimit) {
        if (value.length < rule.minCharacterLimit) {
          return `${displayFieldText} must be at least ${rule.minCharacterLimit} characters long`;
        }
      }
      if (rule.dataType === "number") {
        const numValue = parseFloat(value);
        if (!containsOnlyNumbers(value)) {
          return `${displayFieldText} should only contain numbers`;
        }
        if (rule.minValue !== undefined && numValue < rule.minValue) {
          return `${displayFieldText} should be at least ${rule.minValue}`;
        }
        if (rule.maxValue !== undefined && numValue > rule.maxValue) {
          return `${displayFieldText} should be at most ${rule.maxValue}`;
        }
      }
      if (rule.dataType === "text" && !containsOnlyAlphabets(value)) {
        return `${displayFieldText} should only contain alphabets`;
      }
      if (rule.dataType === "textAndNumber" && !containsTextAndNumbers(value)) {
        return `${displayFieldText} should only contain alphabets and numbers`;
      }
      if (rule.dataType === "textOrNumber" && !containsTextOrNumbers(value)) {
        return `${displayFieldText} should only contain alphabets Or numbers`;
      }
      if (
        rule.dataType === "textWithNumber" &&
        !containsTextWithNumbers(value)
      ) {
        return `${displayFieldText} should contain at least one alphabets `;
      }
      if (rule.dataType === "email" && !isValidEmail(value)) {
        return `${displayFieldText} invalid`;
      }
      if (rule.dataType === "date" && !isValidDate(value)) {
        return `${displayFieldText} invalid`;
      }
      if (
        rule.dataType === "date" &&
        !rule.isPastDateAllow &&
        isPastDate(value as unknown as Date)
      ) {
        return `${displayFieldText} does not allow past dates`;
      }
      if (
        rule.dataType === "date" &&
        rule?.isFutureDateAllow === false &&
        isFutureDate(value as unknown as Date)
      ) {
        return `${displayFieldText} does not allow future dates`;
      }
      if (
        rule.dataType === "numberWithSpecialCharacters" &&
        !containsNumbersWithSpecialCharacters(value)
      ) {
        return `${displayFieldText} should only contain numbers and special characters`;
      }
      if (rule.dataType === "any") {
        return ``;
      }
    }
  }
  return "";
}

export function validateDataAsPerRules({
  rules,
  formData,
}: {
  rules: any;
  formData: any;
}) {
  try {
    const errors: any = {};
    Object.entries(formData).forEach(([fieldName, value]) => {
      const error = validateFieldAsPerRules({
        rules,
        displayFieldName:
          fieldName.charAt(0).toUpperCase() + fieldName.slice(1),
        fieldName,
        value: value as string,
      });
      if (error) {
        errors[fieldName] = error;
      }
    });
    return errors;
  } catch (error) {
    console.error(error);
    return "";
  }
}
