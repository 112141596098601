import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "../../../atom/Table/Table";
import { AddHealthConcern } from "./AddHealthConcerns/AddHealthConcerns";
import {
  deleteCaseNotesHealthConcernByIdService,
  getAllHealthConcernCaseNote,
} from "../../../../services/caseNotes/caseNotes";
import { showToastMessage } from "../../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../../constants/variables";
import { getIcd10InfoService } from "../../../../services/referrals/referrals";
import { Pagination } from "../../../atom/Pagination/Pagination";
import { FullScreenLoader } from "../../../atom/FullScreenLoader/FullScreenLoader";

const sortingHeaderMap: Record<string, string> = {
  healthConcernDate: "HealthConcernDate",
  healthConcern: "HealthConcern",
  note: "Note",
};

const tableHeaders = {
  healthConcernDate: "Date",
  healthConcern: "Health Concern",
  note: "Notes",
  action: "Action",
};

interface HealthConcernsProps {
  isViewMode: boolean;
}
export function HealthConcerns({ isViewMode }: HealthConcernsProps) {
  interface HealthConcernList {
    healthConcernDate: string;
    healthConcern: string;
    healthConcernId: string;
    note: string;
  }
  const caseNotesDetails = useSelector((state: any) => state.activeCaseNotes);
  const [edithealthConcernListData, setedithealthConcernListData] = useState(
    [],
  );
  const [fullScreenLoaderFlag, setFullScreenLoaderFlag] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [healthConcernListData, sethealthConcernListData] = useState<
    HealthConcernList[]
  >([]);
  const [paginationData, setPaginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });

  const mapHealthConcernIcd10 = async (healthConcernId: number) => {
    try {
      const response = await getIcd10InfoService(healthConcernId);
      if (response.status && response.data) {
        const { code, shortDescription } = response.data;
        return `${code} - ${shortDescription}`;
      }
      return "";
    } catch (error) {
      console.error(
        `Error fetching ICD-10 info for healthConcernId ${healthConcernId}:`,
        error,
      );
      return "";
    }
  };

  const processHealthConcernData = async (data: HealthConcernList[]) => {
    const processedData = await Promise.all(
      data.map(async (healthConcern) => {
        const label = await mapHealthConcernIcd10(
          JSON.parse(healthConcern.healthConcernId),
        );
        return {
          ...healthConcern,
          healthConcern: label,
        };
      }),
    );
    sethealthConcernListData(processedData);
  };

  const fetchData = async (newPaginationData?: any) => {
    try {
      setFullScreenLoaderFlag(true);

      const paginationDataAsParameter = newPaginationData || paginationData;
      const response = await getAllHealthConcernCaseNote({
        paginationDataAsParameter,
        caseNoteId: caseNotesDetails?.caseNotesDetails?.caseNoteId,
      });
      if (response.isSuccess) {
        setPaginationData({
          ...paginationData,
          pageNo: response.pageNo,
          totalPages: response.totalPages,
          totalElements: response.totalElements,
          pageSize: response.pageSize,
        });
        await processHealthConcernData(response.dataList);
      }
      setFullScreenLoaderFlag(false);
    } catch (error) {
      showToastMessage("Error fetching all users:", TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  const resetEditData = () => {
    setEditMode(false);
  };
  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize,
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    fetchData({ ...paginationData, pageNo, pageSize });
  };
  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    fetchData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };
  const editRecord = async (rowData: any) => {
    setedithealthConcernListData(rowData);
    setEditMode(true);
  };
  const deleteHealthConcern = async (rowData: any) => {
    const deleteHealthConcernCaseNotesResponse =
      await deleteCaseNotesHealthConcernByIdService(rowData.id);
    if (deleteHealthConcernCaseNotesResponse.status) {
      showToastMessage(`Medication : deleted  `, TOAST_TYPE_OPTIONS.SUCCESS);
      await fetchData();
    } else {
      showToastMessage(
        deleteHealthConcernCaseNotesResponse.message,
        TOAST_TYPE_OPTIONS.ERROR,
      );
    }
    await fetchData();
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div
      className="accordion-collapse"
      data-bs-parent="#accordionFlushExample-parent"
    >
      <FullScreenLoader show={fullScreenLoaderFlag} />
      <div className="d-flex justify-content-end mb-2">
        {isViewMode ? (
          <div />
        ) : (
          <AddHealthConcern
            getHealthConcernData={fetchData}
            editHealthConcernDetails={edithealthConcernListData}
            editMode={editMode}
            setEditMode={setEditMode}
            resetEditData={resetEditData}
          />
        )}
      </div>
      <div className="table-responsive care-manager-table">
        <Table
          headers={tableHeaders}
          enableEditAction={editRecord}
          sortingFunction={sortingFunction}
          isEditDisabled={() => isViewMode}
          isDeleteDisabled={() => isViewMode}
          sortingHeaderMap={sortingHeaderMap}
          data={healthConcernListData}
          enableDeleteAction={deleteHealthConcern}
          className="table table-bordered table-striped table-hover"
        />
        {paginationData.totalPages >= 0 && (
          <Pagination
            currentPage={paginationData.pageNo}
            totalPages={paginationData.totalPages}
            pageSize={paginationData.pageSize}
            onPageChange={(page, pageSize) => {
              updatePaginationData(page, pageSize);
            }}
          />
        )}
      </div>
    </div>
  );
}
