import { STATUS_CODES } from "../../constants/variables";
import { API_ENDPOINTS } from "../../constants/apiList";
import ApiCaller from "../../utils/apiCaller";

export async function createInsurance(insuranceData: any): Promise<any> {
  const createNewInsuranceUrl = API_ENDPOINTS.createNewInsurance;
  try {
    const response = await ApiCaller.multiMediaPost<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(createNewInsuranceUrl, insuranceData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Insurance Added Successfully",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function updateInsurance(
  insuranceData: any,
  insuranceID: string,
): Promise<any> {
  const updateInsuranceUrl = `${API_ENDPOINTS.updateInsurance}/${insuranceID}`;
  try {
    const response = await ApiCaller.multiMediaPut<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(updateInsuranceUrl, insuranceData);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Insurance Updated Successfully",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getInsuranceForPatient(
  patientId: string,
  insuranceType: string,
): Promise<any> {
  const getInsuranceForPatientUrl = `${API_ENDPOINTS.getInsuranceForPatient}/${patientId}/${insuranceType}`;
  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(getInsuranceForPatientUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Insurance retrieve Successfully",
        data: response.dataList,
      };
    }
    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function deletePatientInsurance(
  insuranceId: string,
): Promise<any> {
  const deletePatientInsuranceUrl = `${API_ENDPOINTS.deleteInsuranceForPatient}/${insuranceId}`;
  try {
    const response = await ApiCaller.delete<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(deletePatientInsuranceUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Insurance deleted Successfully",
        data: response.dataList,
      };
    }
    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getFirstSlideView(insuranceId: string): Promise<any> {
  const insuranceSlideFirstUrl = `${API_ENDPOINTS.getFirstSlideOfInsurance}/${insuranceId}`;
  try {
    const response = await ApiCaller.getImage(insuranceSlideFirstUrl);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getSecondSlideView(insuranceId: string): Promise<any> {
  const insuranceSlideFirstUrl = `${API_ENDPOINTS.getSecondSlideOfInsurance}/${insuranceId}`;
  try {
    const response = await ApiCaller.getImage(insuranceSlideFirstUrl);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
