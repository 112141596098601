/* eslint-disable complexity */
import React, { useState } from "react";
import { DeletePopup } from "../DeletePopup/DeletePopup";
import { DisplayTextWithExpand } from "../DisplayTextWithExpand/DisplayTextWithExpand";

export type RowData = {
  [key: string]: any;
};

interface NestedHeader {
  key: string;
  label: string;
}

export interface TableProps {
  className: string;
  data: RowData[];
  headers: Record<string, string | NestedHeader[]>;
  onRowClick?: (rowData: RowData) => void;
  enableViewAction?: (rowData: RowData) => void;
  enableEditAction?: (rowData: RowData) => void;
  enableDeleteAction?: (rowData: RowData) => void;
  enableCompleteAction?: (rowData: RowData) => void;
  enableDownloadAction?: (rowData: RowData) => void;
  enablePrintAction?: (rowData: RowData) => void;
  isDeleteDisabled?: (data: any) => boolean;
  isEditDisabled?: (data: any) => boolean;
  hyperlinkField?: string;
  sortingFunction?: (fieldName: string, sortOrder: string) => void;
  sortingHeaderMap?: Record<string, string>;
  cssForTableKeyServicesForText?: any;
  cssForTableKeyServicesForTableData?: any;
  viewPermissionOnlyFlag?: boolean;
}

export function Table({
  data,
  headers,
  className,
  onRowClick,
  enableViewAction,
  enableEditAction,
  enableDeleteAction,
  enableCompleteAction,
  enableDownloadAction,
  enablePrintAction,
  isDeleteDisabled,
  isEditDisabled,
  hyperlinkField,
  sortingFunction,
  sortingHeaderMap,
  cssForTableKeyServicesForText,
  cssForTableKeyServicesForTableData,
  viewPermissionOnlyFlag = false,
}: TableProps) {
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteRowData, setDeleteRowData] = useState<RowData | null>(null);
  const [sortingField, setSortingField] = useState<string>("");
  const [sortingOrder, setSortingOrder] = useState<string>("asc");

  const confirmDelete = () => {
    setShowDeletePopup(false);
    if (enableDeleteAction && deleteRowData) {
      enableDeleteAction(deleteRowData);
    }
  };
  const toggleSortingOrder = () => {
    setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
  };

  const handleHeaderClick = (fieldName: string) => {
    if (fieldName === "action") {
      return;
    }
    if (sortingHeaderMap && !sortingHeaderMap[fieldName]) {
      return;
    }
    let orderTypeForApi = sortingOrder;
    if (sortingFunction) {
      setSortingField(fieldName);
      if (sortingField === fieldName) {
        toggleSortingOrder();
        orderTypeForApi = orderTypeForApi === "asc" ? "desc" : "asc";
      }

      if (sortingHeaderMap) {
        sortingFunction(sortingHeaderMap[fieldName], orderTypeForApi);
      }
    }
  };

  const displayIconForSortingOrder = () => {
    if (sortingOrder === "asc") {
      return <i className="mdi mdi-arrow-up ms-1" />;
    }
    return <i className="mdi mdi-arrow-down ms-1" />;
  };

  const renderNestedData = (
    dataForNestedHeader: RowData,
    headerAsParameter: NestedHeader[],
  ) => (
    <div>
      {headerAsParameter.map((nestedHeader) => (
        <div key={nestedHeader.key}>
          <strong>{nestedHeader.label}: </strong>
          <span>{dataForNestedHeader[nestedHeader.key]}</span>
        </div>
      ))}
    </div>
  );

  const renderCellContent = (row: RowData, key: string) => {
    if (Array.isArray(headers[key])) {
      return renderNestedData(row, headers[key] as NestedHeader[]);
    }
    return <DisplayTextWithExpand text={row[key]} />;
  };

  return (
    <>
      <table className={className}>
        <thead>
          <tr>
            {Object.entries(headers).map(([key, value]) => (
              <th
                onClick={() => handleHeaderClick(key)}
                key={key}
                className={key === "action" ? "w-2" : ""}
              >
                {typeof value === "string" ? value : key}
                {key === sortingField ? displayIconForSortingOrder() : ""}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((row) => (
              <tr key={row.id}>
                {Object.entries(headers).map(([key]) => {
                  if (
                    key === "action" &&
                    (enableEditAction || enableDeleteAction || enableViewAction)
                  ) {
                    return (
                      <td key={key} className="col-2 text-center">
                        <div className="d-flex gap-1">
                          {enableViewAction && (
                            <span
                              role="presentation"
                              className="btn btn-sm btn-secondary ms-1 "
                              onClick={() => enableViewAction(row)}
                            >
                              <i className="fa-solid fa-eye fs-6" />
                            </span>
                          )}
                          {enableEditAction && (
                            <button
                              type="button"
                              className="btn btn-sm btn-secondary ms-1 "
                              onClick={() => enableEditAction(row)}
                              aria-label="edit"
                              disabled={
                                isEditDisabled ? isEditDisabled(row) : false
                              }
                            >
                              <i className="mdi mdi-pencil fs-6" />
                            </button>
                          )}
                          {enableDeleteAction && !viewPermissionOnlyFlag && (
                            <button
                              type="button"
                              className="btn btn-sm btn-danger ms-1 "
                              onClick={() => {
                                setDeleteRowData(row);
                                setShowDeletePopup(true);
                              }}
                              aria-label="delete"
                              disabled={
                                isDeleteDisabled ? isDeleteDisabled(row) : false
                              }
                            >
                              <i className="mdi mdi-delete fs-6" />
                            </button>
                          )}
                          {enableCompleteAction &&
                            row.status !== "Complete" && (
                              <button
                                type="button"
                                className="btn btn-sm btn-success ms-1 "
                                aria-label="Complete"
                                onClick={() => {
                                  enableCompleteAction(row);
                                }}
                                disabled={
                                  isEditDisabled ? isEditDisabled(row) : false
                                }
                              >
                                <i className="mdi mdi-checkbox-multiple-marked fs-6" />
                              </button>
                            )}

                          {enableDownloadAction && (
                            <span
                              role="presentation"
                              className="btn btn-sm btn-secondary ms-1 "
                              onClick={() => enableDownloadAction(row)}
                            >
                              <i className="fa-solid fa-download fs-6" />
                            </span>
                          )}
                          {enablePrintAction && (
                            <span
                              role="presentation"
                              className="btn btn-sm btn-secondary ms-1 "
                              onClick={() => enablePrintAction(row)}
                            >
                              <i className="fa-solid fa-print fs-6" />
                            </span>
                          )}
                        </div>
                      </td>
                    );
                  }
                  if (onRowClick && key === hyperlinkField) {
                    return (
                      <td
                        key={key}
                        onClick={() => onRowClick(row)}
                        className={
                          key === hyperlinkField
                            ? "text-primary text-decoration-underline cursor-pointer"
                            : ""
                        }
                      >
                        {row[key]}
                      </td>
                    );
                  }

                  if (
                    cssForTableKeyServicesForText &&
                    cssForTableKeyServicesForText[key]
                  ) {
                    const classValue =
                      cssForTableKeyServicesForText[key][row[key]];
                    return (
                      <td key={key}>
                        <span className={classValue}> {row[key]}</span>
                      </td>
                    );
                  }

                  if (
                    cssForTableKeyServicesForTableData &&
                    cssForTableKeyServicesForTableData[key]
                  ) {
                    const classValue =
                      cssForTableKeyServicesForTableData[key].pastDue[
                        row?.pastDue
                      ];

                    return (
                      <td
                        key={key}
                        className={classValue}
                        aria-label={row[key]}
                      >
                        <DisplayTextWithExpand text={row[key]} />
                      </td>
                    );
                  }

                  return (
                    <td key={key} aria-label={row[key]}>
                      {renderCellContent(row, key)}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={Object.keys(headers).length + 1}>No data found</td>
            </tr>
          )}
        </tbody>
      </table>
      {showDeletePopup && (
        <DeletePopup
          onClose={() => {
            setShowDeletePopup(false);
          }}
          onConfirm={() => {
            confirmDelete();
          }}
        />
      )}
    </>
  );
}
