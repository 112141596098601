import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "../../../components/atom/Table/Table";
import { Card } from "../../../components/atom/Cards/Cards";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import { CreateNewReferral } from "../../../components/organism/CreateNewReferral/CreateNewReferral";
import {
  deleteReferralIn,
  deleteReferralOut,
  getAllReferralsInFilterServices,
  getAllReferralsOutFilterServices,
} from "../../../services/referrals/referrals";
import { SideFilterBar } from "../../../components/atom/SideFilterBar/SideFilterBar";
import { ReferralSidebar } from "./ReferralSidebar";
import { Pagination } from "../../../components/atom/Pagination/Pagination";
import { getOptionsForDropdown } from "../../../services/dropDownMenuOptions/dropDownMenuOptions";
import { allKeysEmpty } from "../../../services/utils/allKeyEmpty";
import { FullScreenLoader } from "../../../components/atom/FullScreenLoader/FullScreenLoader";
import { formatDate } from "../../../services/utils/formatDate";

interface ReferralInProps {
  referralType: string;
  displayLabel: string;
}
export function ReferralIn({ referralType, displayLabel }: ReferralInProps) {
  interface CareTeamListType {
    referral: string;
    referredTo: string;
    referredBy: string;
    effectiveDate?: string;
    icd10: string;
    authorization: string;
    organization: string;
    maxAuthorization?: string;
    reason: string;
    notes: string;
    status: number;
    procedureCode: string;
    authStatus: string;
    insCarrier: string;
    referralType: string;
    patientId: string;
  }
  const activePatient = useSelector((state: any) => state.activePatient);
  const [referralData, setReferralData] = useState<CareTeamListType[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editReferralData, setEditUserData] = useState<CareTeamListType>();
  const [paginationData, setPaginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });

  const [filters, setFilters] = useState<{
    referredTo: string;
    referredBy: string;
    authorizationId: string;
    organization: string;
    fromDate: string;
    toDate: string;
    status: string;
  }>({
    referredTo: "",
    referredBy: "",
    organization: "",
    authorizationId: "",
    fromDate: "",
    toDate: "",
    status: "",
  });
  const headersForTable: Record<string, string> = {
    referral: "Referral#",
    reason: "Referral Reason",
    referralProvider: "Referral Provider",
    status: "Status",
    insCarrier: "Ins.Carrier",
    ...(referralType === "IN" ? { effectiveDate: "Effective Date" } : {}),
    ...(referralType === "OUT" ? { effectiveDate: "Created Date" } : {}),
    action: "Action",
  };

  const mapUserStatus = async (statusId: number) => {
    const referralStatusOption = await getOptionsForDropdown("referralStatus");
    const statusObject = referralStatusOption.find(
      (status: { value: number }) => status.value === statusId
    );
    return statusObject ? statusObject.label : "";
  };

  const processReferralData = async (data: CareTeamListType[]) => {
    const processedData = await Promise.all(
      data.map(async (user) => ({
        ...user,
        status: await mapUserStatus(user.status),
      }))
    );
    setReferralData(processedData);
  };

  // eslint-disable-next-line complexity
  const fetchFilterData = async (
    newPaginationData?: any,
    newFilterData?: any,
    filterType = ""
  ) => {
    try {
      const filtersAsParameter = newFilterData
        ? {
            ...newFilterData,
            fromDate: newFilterData.fromDate
              ? formatDate(newFilterData.fromDate)
              : "",
            toDate: newFilterData.toDate
              ? formatDate(newFilterData.toDate)
              : "",
          }
        : filters;

      const ifAllEmpty = allKeysEmpty(filtersAsParameter);
      if (ifAllEmpty && filterType !== "cancel") {
        showToastMessage(
          "Please fill all the required fields and try again.",
          TOAST_TYPE_OPTIONS.ERROR
        );
        return;
      }
      const patientId = activePatient.patientDetails.id;
      if (!patientId) {
        return;
      }
      const paginationDataAsParameter = newPaginationData || paginationData;
      setShowFilter(false);
      setIsLoading(true);
      if (referralType === "IN" || referralType === "In") {
        const response = await getAllReferralsInFilterServices({
          paginationDataAsParameter,
          patientId,
          filters: filtersAsParameter,
        });
        if (response.isSuccess) {
          setPaginationData({
            ...newPaginationData,
            pageNo: response.pageNo,
            totalPages: response.totalPages,
            totalElements: response.totalElements,
            pageSize: response.pageSize,
          });
          await processReferralData(response.dataList);
        } else {
          showToastMessage(response.message);
          setReferralData([]);
        }
      } else {
        const response = await getAllReferralsOutFilterServices({
          paginationDataAsParameter,
          patientId,
          filters: filtersAsParameter,
        });

        if (response.isSuccess) {
          setPaginationData({
            ...newPaginationData,
            pageNo: response.pageNo,
            totalPages: response.totalPages,
            totalElements: response.totalElements,
            pageSize: response.pageSize,
          });
          await processReferralData(response.dataList);
        } else {
          showToastMessage(response.message);
          setReferralData([]);
        }
      }
      setIsLoading(false);
    } catch (error) {
      showToastMessage("Error fetching all users:", TOAST_TYPE_OPTIONS.ERROR);
    }
  };
  const clearFilterButtonAction = () => {
    const updatedFilters = {
      referredTo: "",
      referredBy: "",
      organization: "",
      authorizationId: "",
      fromDate: "",
      toDate: "",
      status: "",
    };
    setFilters(updatedFilters);
    fetchFilterData(paginationData, updatedFilters, "cancel");
  };

  useEffect(() => {
    clearFilterButtonAction();
  }, []);

  useEffect(() => {
    if (!activePatient.patientDetails.id) {
      setReferralData([]);
    }
  }, [activePatient]);

  const sortingHeaderMap: Record<string, string> = {
    referral: "Referral",
    effectiveDate: "EffectiveDate",
    reason: "ReferralReason",
    referralProvider: "ReferralProvider",
    status: "Status",
    insCarrier: "InsCarrier",
  };

  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    fetchFilterData({ ...paginationData, pageNo, pageSize });
  };

  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    fetchFilterData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };

  const editRecord = async (rowData: any) => {
    setEditUserData(rowData);
    setEditMode(true);
  };

  const resetEditData = () => {
    setEditUserData({
      referral: "",
      referredTo: "",
      referredBy: "",
      effectiveDate: "",
      icd10: "",
      authorization: "",
      organization: "",
      maxAuthorization: "",
      reason: "",
      notes: "",
      status: 0,
      procedureCode: "",
      authStatus: "",
      insCarrier: "",
      referralType: "",
      patientId: "",
    });
    setEditMode(false);
  };

  const deleteReferral = async (rowData: any) => {
    const referralId = rowData.id;

    if (referralType === "IN" || referralType === "In") {
      const deleteReferralResponse = await deleteReferralIn({ referralId });

      if (deleteReferralResponse.status !== "404") {
        showToastMessage(
          `Referral-In deleted successfully `,
          TOAST_TYPE_OPTIONS.SUCCESS
        );
        await fetchFilterData();
      } else {
        showToastMessage(
          deleteReferralResponse.message,
          TOAST_TYPE_OPTIONS.ERROR
        );
      }
    } else {
      const deleteReferralResponse = await deleteReferralOut({ referralId });

      if (deleteReferralResponse.status) {
        showToastMessage(
          `Referral-Out deleted successfuly `,
          TOAST_TYPE_OPTIONS.SUCCESS
        );
        await fetchFilterData();
      } else {
        showToastMessage(
          deleteReferralResponse.message,
          TOAST_TYPE_OPTIONS.ERROR
        );
      }
    }
  };

  return (
    <div>
      <FullScreenLoader show={isLoading} />
      <Card>
        <div className="card-title d-flex justify-content-between align-items-center">
          <h5>{displayLabel}</h5>
          <div className="d-flex text-end">
            <CreateNewReferral
              getReferralData={fetchFilterData}
              editReferralDetails={editReferralData}
              editMode={editMode}
              setEditMode={setEditMode}
              referralType={referralType}
              buttonTitle={`Add Referral-${
                referralType === "IN" ? "in" : "out"
              }`}
              resetEditData={resetEditData}
            />
            <SideFilterBar
              className="btn btn-warning btn-sm text-white ms-2 px-3"
              clearBtn="Clear"
              saveBtn="Apply Filter"
              heading="Filter"
              title="Filter"
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              filterButtonAction={() =>
                fetchFilterData(paginationData, filters)
              }
              clearFilterButtonAction={clearFilterButtonAction}
              body={
                <ReferralSidebar
                  showFilter={showFilter}
                  setFilters={setFilters}
                  filters={filters}
                  referralType={referralType}
                />
              }
            />
          </div>
        </div>
        <div className="table-responsive care-manager-table">
          <Table
            className="table table-bordered table-striped table-hover"
            data={referralData}
            headers={headersForTable}
            enableEditAction={editRecord}
            enableDeleteAction={deleteReferral}
            sortingFunction={sortingFunction}
            sortingHeaderMap={sortingHeaderMap}
          />
          {paginationData.totalPages >= 0 && (
            <Pagination
              currentPage={paginationData.pageNo}
              totalPages={paginationData.totalPages}
              pageSize={paginationData.pageSize}
              onPageChange={(page, pageSize) => {
                updatePaginationData(page, pageSize);
              }}
            />
          )}
        </div>
      </Card>
    </div>
  );
}
