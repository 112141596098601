import ApiCaller from "../../utils/apiCaller";
import { API_ENDPOINTS } from "../../constants/apiList";
import { STATUS_CODES } from "../../constants/variables";

// Helper Functions
function buildCaseNotesUrl(baseUrl: string, id: string, params: any): string {
  const { pageNo, pageSize, sortingFieldName, sortingOrder } = params;
  let url = `${baseUrl}/${id}`;

  if (pageSize) {
    url += `?pageNo=${pageNo}&pageSize=${pageSize}`;
  }
  if (sortingFieldName && sortingOrder) {
    url += `&sortBy=${sortingFieldName}&sortDir=${sortingOrder}`;
  }
  return url;
}

function handleApiResponse(
  response: any,
  successMessage: string,
  failureMessage: string,
): any {
  if (response.isSuccess) {
    return {
      status: response.isSuccess,
      code: STATUS_CODES.OK,
      message: successMessage,
      data: response,
    };
  }

  return {
    status: response.isSuccess ?? false,
    code: STATUS_CODES.INTERNAL_SERVER_ERROR,
    message: response?.responseMsg?.[0] || failureMessage,
    data: response,
  };
}

function handleApiError(error: any): any {
  return {
    status: false,
    code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
    message: error?.message || "API failed",
    details: error?.response?.data?.error || "API call failed",
  };
}
//  Create Case Notes Details
export async function createNewCaseNote(formData: any): Promise<any> {
  const createNewCaseNoteUrl = API_ENDPOINTS.createNewCaseNote;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(createNewCaseNoteUrl, formData);
    return handleApiResponse(
      response,
      "Case Note Added Successfully",
      "Failed to add case note",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}
//  Get all case notes
export async function getAllCaseNotesForPatientAPI({
  paginationDataAsParameter,
  patientId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
}): Promise<any> {
  const getAllCaseNotesForPatientUrl = buildCaseNotesUrl(
    API_ENDPOINTS.getAllCaseNotesForPatient,
    patientId,
    paginationDataAsParameter,
  );

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(getAllCaseNotesForPatientUrl);
    return handleApiResponse(
      response,
      "Case Notes Data Retrieved Successfully",
      "Failed to Retrieve Case Notes",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}

// Update case Notes
export async function updateCaseNoteAPI({
  formData,
  caseNoteId,
}: {
  formData: any;
  caseNoteId: string | number;
}): Promise<any> {
  const updateCaseNoteUrl = `${API_ENDPOINTS.updateCaseNote}/${caseNoteId}`;

  try {
    const response = await ApiCaller.put<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(updateCaseNoteUrl, formData);
    return handleApiResponse(
      response,
      "Case Note Updated Successfully",
      "Failed to update case note",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}
// finalize case Notes
export async function finalizeCaseNoteAPI(
  caseNoteId: string | number,
): Promise<any> {
  const finalizeCaseNoteUrl = `${API_ENDPOINTS.finalizeCaseNote}/${caseNoteId}`;
  try {
    const response = await ApiCaller.put<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(finalizeCaseNoteUrl);
    return handleApiResponse(
      response,
      "Case Note finalized Successfully",
      "Failed to finalize case note",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}

// Get Case Note Details
export async function getCaseNoteDetailsAPI(
  caseNoteId: string | number,
): Promise<any> {
  const getCaseNoteDetailsUrl = `${API_ENDPOINTS.getCaseNoteDetails}/${caseNoteId}`;

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(getCaseNoteDetailsUrl);
    return handleApiResponse(
      response,
      "Case Note Details Retrieved Successfully",
      "Failed to Retrieve Case Note Details",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}

// Delete Case Notes
export async function deleteCaseNoteAPI(
  caseNoteId: string | number,
): Promise<any> {
  const deleteCaseNoteUrl = `${API_ENDPOINTS.deleteCaseNote}/${caseNoteId}`;

  try {
    const response: { isSuccess: boolean; responseMsg?: Array<string> } =
      await ApiCaller.delete(deleteCaseNoteUrl);
    return handleApiResponse(
      response,
      "Case Note Deleted Successfully",
      "Failed to delete case note",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}

// Create Health Concern
export async function createCaseNoteHealthConcernAPI(
  formData: any,
): Promise<any> {
  const createCaseNoteHealthConcernUrl = `${API_ENDPOINTS.CaseNotesHealthConcern}`;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(createCaseNoteHealthConcernUrl, formData);
    return handleApiResponse(
      response,
      "Health Concern Added Successfully",
      "Failed to add Health Concern",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}
// Get Health Concern
export async function getHealthConcernForCaseNotesAPI({
  paginationDataAsParameter,
  caseNoteId,
}: {
  paginationDataAsParameter: any;
  caseNoteId: string;
}): Promise<any> {
  const getCaseNoteHealthCareUrl = buildCaseNotesUrl(
    API_ENDPOINTS.getHealthConcernCaseNotes,
    caseNoteId,
    paginationDataAsParameter,
  );

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(getCaseNoteHealthCareUrl);
    return handleApiResponse(
      response,
      "Health Concern Data Retrieved Successfully",
      "Failed to Retrieve Health Concern",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}

// Update Health Concern
export async function updateHealthConcernCaseNoteAPI({
  formData,
  healthConcernId,
}: {
  formData: any;
  healthConcernId: string | number;
}): Promise<any> {
  const updateHealthConcernCaseNoteUrl = `${API_ENDPOINTS.CaseNotesHealthConcern}/${healthConcernId}`;

  try {
    const response = await ApiCaller.put<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(updateHealthConcernCaseNoteUrl, formData);
    return handleApiResponse(
      response,
      "Health Concern Updated Successfully",
      "Failed to update Health Concern",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}

// Delete Health Concern
export async function deleteHealthConcernCaseNoteAPI(
  healthConcernId: string | number,
): Promise<any> {
  const deleteHealthConcernCaseNoteUrl = `${API_ENDPOINTS.CaseNotesHealthConcern}/${healthConcernId}`;

  try {
    const response: { isSuccess: boolean; responseMsg?: Array<string> } =
      await ApiCaller.delete(deleteHealthConcernCaseNoteUrl);
    return handleApiResponse(
      response,
      "Health Concern Deleted Successfully",
      "Failed to delete Health Concern",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}

// Create Medication
export async function createCaseNoteMedicationAPI(formData: any): Promise<any> {
  const createCaseNoteMedicationUrl = `${API_ENDPOINTS.CaseNotesMedication}`;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(createCaseNoteMedicationUrl, formData);
    return handleApiResponse(
      response,
      "Medication Added Successfully",
      "Failed to add Medication",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}

// Get Medication
export async function getMedicationForCaseNotesAPI({
  paginationDataAsParameter,
  caseNoteId,
}: {
  paginationDataAsParameter: any;
  caseNoteId: string;
}): Promise<any> {
  const getCaseNoteMedicationUrl = buildCaseNotesUrl(
    API_ENDPOINTS.getMedicationCaseNotes,
    caseNoteId,
    paginationDataAsParameter,
  );

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(getCaseNoteMedicationUrl);
    return handleApiResponse(
      response,
      "Medication Data Retrieved Successfully",
      "Failed to Retrieve Medication",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}

// Update Health Concern
export async function updateMedicationCaseNoteAPI({
  formData,
  medicationId,
}: {
  formData: any;
  medicationId: string | number;
}): Promise<any> {
  const updateMedicationCaseNoteUrl = `${API_ENDPOINTS.CaseNotesMedication}/${medicationId}`;

  try {
    const response = await ApiCaller.put<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(updateMedicationCaseNoteUrl, formData);
    return handleApiResponse(
      response,
      "Medication Updated Successfully",
      "Failed to update Medication",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}

// Delete Medication
export async function deleteMedicationCaseNoteAPI(
  medicationId: string | number,
): Promise<any> {
  const deleteMedicationCaseNoteUrl = `${API_ENDPOINTS.CaseNotesMedication}/${medicationId}`;

  try {
    const response: { isSuccess: boolean; responseMsg?: Array<string> } =
      await ApiCaller.delete(deleteMedicationCaseNoteUrl);
    return handleApiResponse(
      response,
      "Medication Deleted Successfully",
      "Failed to delete Medication",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}

// Create Vitals
export async function createNewVitalsCaseNote(formData: any): Promise<any> {
  const createNewCaseNoteUrl = API_ENDPOINTS.vitalCaseNoteUrl;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(createNewCaseNoteUrl, formData);
    return handleApiResponse(
      response,
      "Vitals Added Successfully",
      "Failed to add Vitals",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}

// Vitals Units dropdown
export async function getUnitDropDownValuesOptions(): Promise<any> {
  const getCaseNoteDetailsUrl = `${API_ENDPOINTS.measureUnitDropdownValue}`;

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(getCaseNoteDetailsUrl);
    return handleApiResponse(
      response,
      "Unit Drop Down Values Retrieved Successfully",
      "Failed to Retrieve Unit Drop Down Values",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}

// Get Vitals
export async function getVitalCaseNotesAPI(caseNoteId: string): Promise<any> {
  const getVitalCaseNoteUrl = `${API_ENDPOINTS.getVitalsCaseNoteDetails}/${caseNoteId}`;

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(getVitalCaseNoteUrl);
    return handleApiResponse(
      response,
      "Vital Details Retrieved Successfully",
      "Failed to Retrieve Vital Details",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}

// Get Vitals By Id
export async function getCaseNotesVitalByIdValues(
  caseNoteVitalId: number,
): Promise<any> {
  const getCaseNoteVitalByIdUrl = `${API_ENDPOINTS.vitalCaseNoteUrl}/${caseNoteVitalId}`;

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(getCaseNoteVitalByIdUrl);
    return handleApiResponse(
      response,
      "Vital Details Retrieved Successfully",
      "Failed to Retrieve Vital Details",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}

// Update Health Concern
export async function updateVitalsCaseNoteAPI({
  formData,
  vitalId,
}: {
  formData: any;
  vitalId: string | number;
}): Promise<any> {
  const updateVitalsCaseNoteUrl = `${API_ENDPOINTS.vitalCaseNoteUrl}/${vitalId}`;

  try {
    const response = await ApiCaller.put<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(updateVitalsCaseNoteUrl, formData);
    return handleApiResponse(
      response,
      "Vitals Updated Successfully",
      "Failed to update Vitals",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}

// Delete Vitals
export async function deleteCaseNotesVitalById(
  VitalIdcaseNote: string | number,
): Promise<any> {
  const deleteVitalByIdCaseNoteUrl = `${API_ENDPOINTS.vitalCaseNoteUrl}/${VitalIdcaseNote}`;

  try {
    const response: { isSuccess: boolean; responseMsg?: Array<string> } =
      await ApiCaller.delete(deleteVitalByIdCaseNoteUrl);
    return handleApiResponse(
      response,
      "Vital Deleted Successfully",
      "Failed to delete Vital",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}

// Create Chief Complaints
export async function createNewChiefComplaintCaseNote(
  formData: any,
  editFlag: boolean,
): Promise<any> {
  const createNewChiefComplaintCaseNoteUrl =
    API_ENDPOINTS.createChiefComplaintCaseNotes;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(createNewChiefComplaintCaseNoteUrl, formData);
    if (editFlag) {
      return handleApiResponse(
        response,
        "Chief Complaint Updated Successfully",
        "Failed to add Chief Complaint",
      );
    }
    return handleApiResponse(
      response,
      "Chief Complaint Added Successfully",
      "Failed to add Chief Complaint",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}

// Create Plan
export async function createNewPlanCaseNote(
  formData: any,
  editFlag: boolean,
): Promise<any> {
  const createNewPlanCaseNoteUrl = API_ENDPOINTS.createPlanCaseNotes;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(createNewPlanCaseNoteUrl, formData);
    if (editFlag) {
      return handleApiResponse(
        response,
        "Plan Updated Successfully",
        "Failed to add Plan",
      );
    }
    return handleApiResponse(
      response,
      "Plan Added Successfully",
      "Failed to add Plan",
    );
  } catch (error: any) {
    return handleApiError(error);
  }
}
