import Logger from "../../utils/Logger";
import {
  createNewCaseNote,
  getAllCaseNotesForPatientAPI,
  getCaseNoteDetailsAPI,
  updateCaseNoteAPI,
  deleteCaseNoteAPI,
  createCaseNoteHealthConcernAPI,
  getHealthConcernForCaseNotesAPI,
  createNewVitalsCaseNote,
  getVitalCaseNotesAPI,
  createCaseNoteMedicationAPI,
  getMedicationForCaseNotesAPI,
  getUnitDropDownValuesOptions,
  getCaseNotesVitalByIdValues,
  deleteCaseNotesVitalById,
  createNewChiefComplaintCaseNote,
  createNewPlanCaseNote,
  deleteHealthConcernCaseNoteAPI,
  deleteMedicationCaseNoteAPI,
  updateHealthConcernCaseNoteAPI,
  updateMedicationCaseNoteAPI,
  updateVitalsCaseNoteAPI,
  finalizeCaseNoteAPI,
} from "../../dataLayer/caseNotes/caseNotes";

// Create Case Note Details
export async function createCaseNote(formData: any): Promise<any> {
  try {
    delete formData.ageAtEncounter;
    delete formData.seenBy;
    Logger.logInfo("creating new care notes", { data: formData });
    const createNewCaseNoteResponse = await createNewCaseNote(formData);
    return createNewCaseNoteResponse.data;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating case note.",
      details: error.message,
    };
  }
}

// Get all Case Notes
export async function getAllCaseNotesForPatient({
  paginationDataAsParameter,
  patientId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
}): Promise<any> {
  try {
    const caseNotesList = await getAllCaseNotesForPatientAPI({
      paginationDataAsParameter,
      patientId,
    });
    return caseNotesList;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all case notes.",
      details: error.message,
    };
  }
}

// Get Case Details by Id
export async function getCaseNoteDetails(
  caseNoteId: string | number,
): Promise<any> {
  if (!caseNoteId) {
    return {
      status: false,
      code: 500,
      message: "Case note ID is required.",
      details: "Case note ID is required.",
    };
  }
  try {
    const caseNoteDetails = await getCaseNoteDetailsAPI(caseNoteId);
    return caseNoteDetails.data;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching case note details.",
      details: error.message,
    };
  }
}

// Update Case Note Details
export async function updateCaseNote({
  requestObject,
  caseNoteId,
}: {
  requestObject: any;
  caseNoteId: string;
}): Promise<any> {
  try {
    Logger.logInfo("updating case note", {
      data: { requestObject, caseNoteId },
    });
    const updateCaseNoteResponse = await updateCaseNoteAPI({
      formData: requestObject,
      caseNoteId,
    });
    return updateCaseNoteResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error updating case note.",
      details: error.message,
    };
  }
}

// finalize Case Note Details
export async function finalizeCaseNote({
  caseNoteId,
}: {
  caseNoteId: string;
}): Promise<any> {
  try {
    Logger.logInfo("finalizing case note", {
      data: { caseNoteId },
    });
    const updateCaseNoteResponse = await finalizeCaseNoteAPI(caseNoteId);
    return updateCaseNoteResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error updating case note.",
      details: error.message,
    };
  }
}

// Delete Case Note
export async function deleteCaseNoteService(
  caseNoteId: string | number,
): Promise<any> {
  if (!caseNoteId) {
    return {
      status: false,
      code: 500,
      message: "Case note ID is required.",
      details: "Case note ID is required.",
    };
  }

  try {
    Logger.logInfo("deleting case note", { data: { caseNoteId } });
    const response = await deleteCaseNoteAPI(caseNoteId);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting case note.",
      details: error.message,
    };
  }
}

// Create Health Concern
export async function createCaseNotesHealthConcern(
  formData: any,
): Promise<any> {
  try {
    Logger.logInfo("creating new health concern", { data: formData });
    const createNewCaseNoteResponse =
      await createCaseNoteHealthConcernAPI(formData);
    return createNewCaseNoteResponse;
  } catch (error: any) {
    console.error(error);
    return {
      status: false,
      code: 500,
      message: "Error creating allergies.",
      details: error.message,
    };
  }
}

// Get Health Concern
export async function getAllHealthConcernCaseNote({
  caseNoteId,
  paginationDataAsParameter,
}: {
  caseNoteId: string;
  paginationDataAsParameter: any;
}): Promise<any> {
  if (!caseNoteId) {
    return {
      status: false,
      code: 500,
      message: "Case note ID is required.",
      details: "Case note ID is required.",
    };
  }
  try {
    const healthConcernList = await getHealthConcernForCaseNotesAPI({
      caseNoteId,
      paginationDataAsParameter,
    });
    return healthConcernList.data;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all case notes.",
      details: error.message,
    };
  }
}

// Update Health Concern Details
export async function updateHealthConcernCaseNote({
  requestObject,
  healthConcernId,
}: {
  requestObject: any;
  healthConcernId: string;
}): Promise<any> {
  try {
    Logger.logInfo("updating health concern", {
      data: { requestObject, healthConcernId },
    });
    const updateHealthConcernCaseNoteResponse =
      await updateHealthConcernCaseNoteAPI({
        formData: requestObject,
        healthConcernId,
      });
    return updateHealthConcernCaseNoteResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error updating Health Concern.",
      details: error.message,
    };
  }
}

// Delete Health Concern By Id
export async function deleteCaseNotesHealthConcernByIdService(
  healthConcernId: string | number,
): Promise<any> {
  if (!healthConcernId) {
    return {
      status: false,
      code: 500,
      message: "Health Concern ID is required.",
      details: "Health Concern ID is required.",
    };
  }

  try {
    Logger.logInfo("deleting health concern", {
      data: { healthConcernId },
    });
    const response = await deleteHealthConcernCaseNoteAPI(healthConcernId);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting Health Concern.",
      details: error.message,
    };
  }
}

// Create Medication
export async function createCaseNotesMedication(formData: any): Promise<any> {
  try {
    Logger.logInfo("creating new medication", { data: formData });
    const createNewCaseNoteResponse =
      await createCaseNoteMedicationAPI(formData);
    return createNewCaseNoteResponse;
  } catch (error: any) {
    console.error(error);
    return {
      status: false,
      code: 500,
      message: "Error creating allergies.",
      details: error.message,
    };
  }
}

// Get Medication
export async function getAllCaseNoteMedication({
  caseNoteId,
  paginationDataAsParameter,
}: {
  caseNoteId: string;
  paginationDataAsParameter: any;
}): Promise<any> {
  if (!caseNoteId) {
    return {
      status: false,
      code: 500,
      message: "Case note ID is required.",
      details: "Case note ID is required.",
    };
  }
  try {
    const healthConcernList = await getMedicationForCaseNotesAPI({
      caseNoteId,
      paginationDataAsParameter,
    });
    return healthConcernList;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all case notes.",
      details: error.message,
    };
  }
}

// Update Medication Details
export async function updateMedicationCaseNote({
  requestObject,
  medicationId,
}: {
  requestObject: any;
  medicationId: string;
}): Promise<any> {
  try {
    Logger.logInfo("updating medication", {
      data: { requestObject, medicationId },
    });
    const updateMedicationCaseNoteResponse = await updateMedicationCaseNoteAPI({
      formData: requestObject,
      medicationId,
    });
    return updateMedicationCaseNoteResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error updating Health Concern.",
      details: error.message,
    };
  }
}

// Delete Medication By Id
export async function deleteCaseNotesMedicationByIdService(
  medicationId: string | number,
): Promise<any> {
  if (!medicationId) {
    return {
      status: false,
      code: 500,
      message: "Medication ID is required.",
      details: "Medication ID is required.",
    };
  }

  try {
    Logger.logInfo("deleting medication", {
      data: { medicationId },
    });
    const response = await deleteMedicationCaseNoteAPI(medicationId);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting Health Concern.",
      details: error.message,
    };
  }
}

// Create Vitals
export async function createVitalsCaseNote(formData: any): Promise<any> {
  try {
    Logger.logInfo("creating new vitals", { data: formData });
    const createNewVitalsCaseNoteResponse =
      await createNewVitalsCaseNote(formData);
    return createNewVitalsCaseNoteResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating case note.",
      details: error.message,
    };
  }
}

// Get Vitals
export async function getVitalCaseNote(caseNoteId: string): Promise<any> {
  if (!caseNoteId) {
    return {
      status: false,
      code: 500,
      message: "Case note ID is required.",
      details: "Case note ID is required.",
    };
  }
  try {
    const healthConcernList = await getVitalCaseNotesAPI(caseNoteId);
    return healthConcernList;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all case notes.",
      details: error.message,
    };
  }
}

// Get Vital unit dropdown
export async function getUnitDropDownValues(): Promise<any> {
  try {
    const getVitalsDropdownResponse = await getUnitDropDownValuesOptions();
    return getVitalsDropdownResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating case note.",
      details: error.message,
    };
  }
}

// Get Vital By Id
export async function getCaseNotesVitalById(
  caseNoteVitalId: number,
): Promise<any> {
  try {
    const getVitalsDropdownResponse =
      await getCaseNotesVitalByIdValues(caseNoteVitalId);
    return getVitalsDropdownResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating case note.",
      details: error.message,
    };
  }
}

// Update Vital
export async function updateVitalsCaseNote({
  requestObject,
  vitalId,
}: {
  requestObject: any;
  vitalId: string;
}): Promise<any> {
  try {
    Logger.logInfo("updating vitals", {
      data: { requestObject, vitalId },
    });
    const updateVitalsCaseNoteResponse = await updateVitalsCaseNoteAPI({
      formData: requestObject,
      vitalId,
    });
    return updateVitalsCaseNoteResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error updating Vitals.",
      details: error.message,
    };
  }
}

// Delete Vital By Id
export async function deleteCaseNotesVitalByIdService(
  VitalIdCaseNote: string | number,
): Promise<any> {
  if (!VitalIdCaseNote) {
    return {
      status: false,
      code: 500,
      message: "Vital ID is required.",
      details: "Vital ID is required.",
    };
  }

  try {
    Logger.logInfo("deleting vitals", {
      data: { VitalIdCaseNote },
    });
    const response = await deleteCaseNotesVitalById(VitalIdCaseNote);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting case note.",
      details: error.message,
    };
  }
}

// Create Chief Complaints
export async function createChiefComplaintCaseNotesService(
  formData: any,
  editFlag: boolean,
): Promise<any> {
  try {
    Logger.logInfo("creating new chief complaint", { data: formData });
    const createNewChiefComplaintCaseNoteResponse =
      await createNewChiefComplaintCaseNote(formData, editFlag);
    return createNewChiefComplaintCaseNoteResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating case note.",
      details: error.message,
    };
  }
}

// Create Plan
export async function createPlanCaseNotesService(
  formData: any,
  editFlag: boolean,
): Promise<any> {
  try {
    Logger.logInfo("creating new plan", { data: formData });
    const createNewPlanCaseNoteResponse = await createNewPlanCaseNote(
      formData,
      editFlag,
    );
    return createNewPlanCaseNoteResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating case note.",
      details: error.message,
    };
  }
}
