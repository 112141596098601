import React, { useEffect, useState } from "react";
import { Table } from "../../../components/atom/Table/Table";
import { Card } from "../../../components/atom/Cards/Cards";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import {
  deleteOrganizationService,
  getAllOrganizationServices,
} from "../../../services/masterData/masterData";
import { CreateNewOrganization } from "../../../components/organism/CreateNewOrganization/CreateNewOrganization";
import { Pagination } from "../../../components/atom/Pagination/Pagination";
import Logger from "../../../utils/Logger";

interface ReferralInProps {
  displayLabel: string;
}
export function Organisation({ displayLabel }: ReferralInProps) {
  interface CareTeamListType {
    id: string;
    title: string;
    firstName: string;
    lastName: string;
  }

  const [showModal, setShowModal] = useState<boolean>(false);
  const [editOrganizationData, setEditOrganizationData] = useState();
  const [organizationDataForTable, setReferralData] = useState<
    CareTeamListType[]
  >([]);
  const headersForTable: Record<string, string> = {
    organizationName: "Name",
    city: "City",
    action: "Action",
  };
  const sortingHeaderMap: Record<string, string> = {
    organizationName: "Name",
    city: "City",
  };
  const [paginationData, setPaginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });

  const getAllOrganizationDataForTable = async (newPaginationData?: any) => {
    try {
      const paginationDataAsParameter = newPaginationData || paginationData;
      const organizationData = await getAllOrganizationServices({
        paginationDataAsParameter,
      });
      if (organizationData.status) {
        setReferralData(organizationData.data.dataList);
        setPaginationData({
          ...paginationData,
          pageNo: organizationData.data.pageNo,
          totalPages: organizationData.data.totalPages,
          totalElements: organizationData.data.totalElements,
          pageSize: organizationData.data.pageSize,
        });
      } else {
        showToastMessage(organizationData.message, TOAST_TYPE_OPTIONS.ERROR);
      }
    } catch (error) {
      showToastMessage("Error fetching all users:", TOAST_TYPE_OPTIONS.ERROR);
      console.error("Error fetching all users:", error);
    }
  };

  useEffect(() => {
    getAllOrganizationDataForTable();
  }, []);

  const deleteOrganization = async (rowData: any) => {
    Logger.logInfo("Deleting Organization data", { data: rowData });
    const deleteOrganizationResponse = await deleteOrganizationService(
      rowData.id,
    );
    if (deleteOrganizationResponse.status) {
      showToastMessage(
        deleteOrganizationResponse.message,
        TOAST_TYPE_OPTIONS.SUCCESS,
      );

      getAllOrganizationDataForTable();
    } else {
      showToastMessage(
        deleteOrganizationResponse.message,
        TOAST_TYPE_OPTIONS.ERROR,
      );
    }
  };
  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize,
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    getAllOrganizationDataForTable({ ...paginationData, pageNo, pageSize });
  };

  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    getAllOrganizationDataForTable({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };
  return (
    <div>
      <Card>
        <div className="card-title d-flex justify-content-between align-items-center">
          <h5>{displayLabel}</h5>
          <div className="d-flex text-end">
            <CreateNewOrganization
              setShow={setShowModal}
              show={showModal}
              buttonTitle="Add Organization"
              onSubmitAction={() => {
                getAllOrganizationDataForTable();
              }}
              editData={editOrganizationData}
            />
          </div>
        </div>
        <div className="table-responsive care-manager-table">
          <Table
            className="table table-bordered table-striped table-hover"
            data={organizationDataForTable}
            headers={headersForTable}
            enableDeleteAction={deleteOrganization}
            enableEditAction={(row: any) => {
              setEditOrganizationData(row);
            }}
            sortingHeaderMap={sortingHeaderMap}
            sortingFunction={sortingFunction}
          />
          {paginationData.totalPages >= 0 && (
            <Pagination
              currentPage={paginationData.pageNo}
              totalPages={paginationData.totalPages}
              pageSize={paginationData.pageSize}
              onPageChange={(page, pageSize) => {
                updatePaginationData(page, pageSize);
              }}
            />
          )}
        </div>
      </Card>
    </div>
  );
}
