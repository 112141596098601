import ApiCaller from "../../utils/apiCaller";
import { API_ENDPOINTS } from "../../constants/apiList";
import { STATUS_CODES } from "../../constants/variables";

export async function getDropdownOptions(fieldName: string): Promise<any> {
  const getUsersUrl = `${API_ENDPOINTS.getDropDownOptions}/${fieldName}`;

  try {
    const response = await ApiCaller.get(getUsersUrl);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getCaseManagerList(): Promise<any> {
  const caseManagerListUrl = `${API_ENDPOINTS.getPatientCaseManagerList}`;

  try {
    const response = await ApiCaller.get(caseManagerListUrl);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getCaseManagerAndAdminList(): Promise<any> {
  const caseManagerAndAdminListUrl = `${API_ENDPOINTS.getPatientCaseManagerAndAdminList}`;

  try {
    const response = await ApiCaller.get(caseManagerAndAdminListUrl);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getRaceAndEthnicityList(): Promise<any> {
  const raceAndEthnicity = `${API_ENDPOINTS.getAllRaceAndEthnicity}`;

  try {
    const response = await ApiCaller.get(raceAndEthnicity);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getListOfCountry(): Promise<any> {
  const listOfCountry = `${API_ENDPOINTS.listOfCountry}`;

  try {
    const response = await ApiCaller.get(listOfCountry);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getAllPcpValues(): Promise<any> {
  const listOfPcp = `${API_ENDPOINTS.listOfAllPcp}`;

  try {
    const response = await ApiCaller.get(listOfPcp);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
