import { STATUS_CODES } from "../../constants/variables";
import { API_ENDPOINTS } from "../../constants/apiList";
import ApiCaller from "../../utils/apiCaller";

export async function deletePcpAPI(pcpId: number | string): Promise<any> {
  const deletePCPUrl = `${API_ENDPOINTS.deletePcp}/${pcpId}`;

  try {
    const response: { isSuccess: boolean; responseMsg?: Array<string> } =
      await ApiCaller.delete(deletePCPUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "PCP Deleted successfully",
        data: response,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getPcpDetailsAPI(pcpId: number | string): Promise<any> {
  const getPcpDetailsUrl = `${API_ENDPOINTS.getPcpDetails}/${pcpId}`;

  try {
    const response: {
      isSuccess: boolean;
      responseMsg?: Array<string>;
      data?: Record<string, string>;
    } = await ApiCaller.get(getPcpDetailsUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "PCP info fetch successfully",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function updateExitingPcp(requestObject: any, pcpId: string) {
  const updatePcpUrl = `${API_ENDPOINTS.updatePcpDetails}/${pcpId}`;
  try {
    const response = await ApiCaller.put<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(updatePcpUrl, requestObject);

    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "PCP Data update Successfully",
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to Update PCP Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
