import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Race {
  id: number | string;
  name: string;
}
interface ActivePatientState {
  patientDetails: {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    dob: string;
    sex: string;
    genderIdentity: string;
    sexualOrientation: string;
    maritalStatus: string;
    chartNumber: string;
    preferredLanguage: string;
    responsiblePartyName: string;
    occupation: string;
    caseManagerId: string;
  };
  contactDetails: {
    id: number | string;
    primaryAddress: string;
    secondaryAddress: string;
    country: string;
    state: string;
    city: string;
    zipCode: number | string;
    preferredPhone: number | string;
    mobileNumber: number | string;
    email: string;
    patientId: number | string;
  };
  editStatusFlag: boolean;
  providerInfo: {
    id: number | string;
    providerSpecialty: string;
    relationship: string;
    employer: string;
    occupation: string;
    notes: string;
    patientStatus: string;
    patientAsOfDate: string;
    pcpId: number | string;
    patientId?: number | string;
  };
  raceAndEthnicity: {
    id: number | string;
    raceList: Race[];
    ethnicityList: Race[];
    patientId: number | string;
  };
}

const initialState: ActivePatientState | undefined = {
  patientDetails: {
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    sex: "",
    genderIdentity: "",
    sexualOrientation: "",
    maritalStatus: "",
    chartNumber: "",
    preferredLanguage: "",
    responsiblePartyName: "",
    occupation: "",
    caseManagerId: "",
  },
  contactDetails: {
    id: "",
    primaryAddress: "",
    secondaryAddress: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    preferredPhone: "",
    mobileNumber: "",
    email: "",
    patientId: "",
  },
  editStatusFlag: false,
  providerInfo: {
    id: "",
    providerSpecialty: "",
    relationship: "",
    employer: "",
    occupation: "",
    notes: "",
    patientStatus: "",
    patientAsOfDate: "",
    pcpId: "",
  },
  raceAndEthnicity: {
    id: "",
    raceList: [],
    ethnicityList: [],
    patientId: "",
  },
};

const activePatientSlice = createSlice({
  name: "activePatient",
  initialState,
  reducers: {
    setActivePatient: (
      state: ActivePatientState,
      action: PayloadAction<
        Partial<ActivePatientState | undefined> | undefined
      >,
    ) => ({
      ...state,
      patientDetails: {
        ...state.patientDetails,
        ...action.payload,
      },
    }),
    setContactDetails: (
      state: ActivePatientState,
      action: PayloadAction<Partial<ActivePatientState["contactDetails"]>>,
    ) => ({
      ...state,
      contactDetails: {
        ...state.contactDetails,
        ...action.payload,
      },
    }),
    resetContactDetails: (state: ActivePatientState) => ({
      ...state,
      contactDetails: {
        ...initialState.contactDetails,
      },
    }),
    setRaceAndEthnicityDetails: (
      state: ActivePatientState,
      action: PayloadAction<Partial<ActivePatientState["raceAndEthnicity"]>>,
    ) => ({
      ...state,
      raceAndEthnicity: {
        ...state.raceAndEthnicity,
        ...action.payload,
      },
    }),
    resetRaceAndEthnicityDetails: (state: ActivePatientState) => ({
      ...state,
      raceAndEthnicity: {
        ...initialState.raceAndEthnicity,
      },
    }),

    setProviderDetails: (
      state: ActivePatientState,
      action: PayloadAction<Partial<ActivePatientState["providerInfo"]>>,
    ) => ({
      ...state,
      providerInfo: {
        ...state.providerInfo,
        ...action.payload,
      },
    }),
    resetProviderDetails: (state: ActivePatientState) => ({
      ...state,
      providerInfo: {
        ...initialState.providerInfo,
      },
    }),
    enablePatientEdit: (state: ActivePatientState) => ({
      ...state,
      editStatusFlag: true,
    }),
    clearActivePatient: () => initialState,
  },
});

export const {
  setActivePatient,
  clearActivePatient,
  setContactDetails,
  enablePatientEdit,
  setProviderDetails,
  resetContactDetails,
  resetProviderDetails,
  setRaceAndEthnicityDetails,
  resetRaceAndEthnicityDetails,
} = activePatientSlice.actions;
export default activePatientSlice.reducer;
