/* eslint-disable complexity */
import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Input } from "../../../components/atom/Input/Input";
import { InputDropdown } from "../../../components/atom/InputDropDown/InputDropDown";
import { Button } from "../../../components/atom/Buttons/Button";
import { Image } from "../../../components/atom/Images/Image";
import {
  createNewInsurance,
  deleteInsuranceForPatient,
  updateInsuranceForPatient,
} from "../../../services/insurance/insurance";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import {
  FILE_SIZE_MAX,
  TOAST_TYPE_OPTIONS,
} from "../../../constants/variables";
import { getOptionsForDropdown } from "../../../services/dropDownMenuOptions/dropDownMenuOptions";
import { formatDate } from "../../../services/utils/formatDate";
import { DeletePopup } from "../../../components/atom/DeletePopup/DeletePopup";
import { isUserHaveOnlyViewPermission } from "../../../services/users/users";
import { FullScreenLoader } from "../../../components/atom/FullScreenLoader/FullScreenLoader";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../services/utils/validateDataAsPerRules";
import Logger from "../../../utils/Logger";

interface InsuranceProps {
  insuranceType: string;
  insuranceData: string | any;
  isDisabled?: boolean;
  onSubmitAction: () => void;
  showFullScreenLoaderStatus: boolean;
  setShowFullScreenLoaderStatus: (status: boolean) => void;
  secondaryInsuranceId?: string;
}

export function InsurancePrimary({
  insuranceType,
  insuranceData,
  isDisabled = false,
  onSubmitAction,
  showFullScreenLoaderStatus,
  setShowFullScreenLoaderStatus,
  secondaryInsuranceId,
}: InsuranceProps) {
  const activePatient = useSelector((state: any) => state.activePatient);
  const loginUserDetails = useSelector((state: any) => state.userDetails);
  const [viewPermissionOnlyFlag, setViewPermissionOnlyFlag] = useState(
    isDisabled
  );
  const [relationshipList, setRelationshipList] = useState([]);
  const [coverage, setCoverage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [insuranceCardSlideOne, setInsuranceCardSlideOne] = useState<
    File | undefined
  >();
  const [insuranceCardSlideTwo, setInsuranceCardSlideTwo] = useState<
    File | undefined
  >();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedImageTwo, setSelectedImageTwo] = useState<string | null>(null);
  const [filesFromFirstResponse, setFilesFromFirstResponse] = useState<
    string | undefined
  >();
  const [filesFromSecondResponse, setFilesFromSecondResponse] = useState<
    string | undefined
  >();
  const [filesFirst, setFilesFirst] = useState<File | undefined>();
  const [filesSecond, setFilesSecond] = useState<File | undefined>();
  const [editFlow, setEditFlow] = useState(false);
  const [insuranceFormData, setInsuranceFormData] = useState({
    planName: "",
    carrierName: "",
    coverage: "",
    groupId: "",
    enrollmentDate: "",
    termedDate: "",
    relation: "",
  });
  const [errors, setErrors] = useState({
    planName: "",
    carrierName: "",
    coverage: "",
    groupId: "",
    enrollmentDate: "",
    termedDate: "",
    relation: "",
  });

  const validationRules: any = {
    planName: {
      required: false,
      dataType: "any",
      characterLimit: 50,
      displayName: "Plan Name",
    },
    carrierName: {
      required: false,
      dataType: "text",
      characterLimit: 50,
      displayName: "Carrier Name",
    },
    coverage: {
      required: false,
      dataType: "number",
      characterLimit: 50,
      displayName: "Coverage",
    },
    groupId: {
      required: false,
      dataType: "any",
      characterLimit: 10,
      displayName: "Group ID",
    },
    enrollmentDate: {
      required: false,
      dataType: "date",
      isPastDateAllow: true,
      isFutureDateAllow: false,
      displayName: `Enrollment Date For ${insuranceType} Coverage`,
    },
    termedDate: {
      required: false,
      dataType: "date",
      isPastDateAllow: false,
      isFutureDateAllow: true,
      displayName: `Termed Date For ${insuranceType} Coverage`,
    },
    relation: {
      required: false,
      dataType: "number",
      characterLimit: 250,
      displayName: "Relation to Patient",
    },
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    if (name === "planName") {
      if (value && value.length > 50) {
        setErrors({ ...errors, planName: "You have reached the limit" });
      } else {
        setErrors({ ...errors, planName: "" });
      }
    }
    if (name === "carrierName") {
      if (value && value.length > 50) {
        setErrors({ ...errors, carrierName: "You have reached the limit" });
      } else {
        setErrors({ ...errors, carrierName: "" });
      }
    }
    if (name === "groupId") {
      if (value && value.length > 10) {
        setErrors({ ...errors, groupId: "You have reached the limit" });
      } else {
        setErrors({ ...errors, groupId: "" });
      }
    }
    const newErrors = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: name,
      fieldName: name,
      value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: newErrors,
    }));
    setInsuranceFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const { name } = event.target;

    const fileType = event.target.files?.[0]?.type;

    if (file) {
      if (file.size > FILE_SIZE_MAX) {
        showToastMessage(
          "File size exceeds the limit",
          TOAST_TYPE_OPTIONS.ERROR
        );
        return;
      }

      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result as string;
        if (name === "fileSlide1") {
          if (fileType === "application/pdf") {
            setFilesFirst(file);
            setSelectedImage(null);
            setInsuranceCardSlideOne(file);
          } else {
            setFilesFirst(undefined);
            setFilesFromFirstResponse(undefined);
            setSelectedImage(base64String);
            setInsuranceCardSlideOne(file);
          }
        } else if (fileType === "application/pdf") {
          setFilesSecond(file);
          setSelectedImageTwo(null);
          setInsuranceCardSlideTwo(file);
        } else {
          setFilesSecond(undefined);
          setFilesFromSecondResponse(undefined);
          setSelectedImageTwo(base64String);
          setInsuranceCardSlideTwo(file);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOptionSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange(event);
  };

  const handleCancel = () => {
    setInsuranceFormData({
      planName: "",
      carrierName: "",
      coverage: "",
      groupId: "",
      enrollmentDate: "",
      termedDate: "",
      relation: "",
    });
    setSelectedImage("");
    setSelectedImageTwo("");
    setFilesFromFirstResponse(undefined);
    setFilesFromSecondResponse(undefined);
    setFilesSecond(undefined);
    setFilesFirst(undefined);
    setInsuranceCardSlideOne(undefined);
    setInsuranceCardSlideTwo(undefined);
  };
  const allKeysEmpty = (obj: any) =>
    Object.values(obj).every((value) => value === "");

  const handleSave = async () => {
    const isError = await allKeysEmpty(errors);

    if (!isError) {
      showToastMessage("Invalid data", TOAST_TYPE_OPTIONS.ERROR);
      return;
    }
    const formErrors = validateDataAsPerRules({
      formData: insuranceFormData,
      rules: validationRules,
    });
    setErrors({
      ...errors,
      ...formErrors,
    });
    if (Object.keys(formErrors).length > 0) {
      showToastMessage("Please fill correct value", TOAST_TYPE_OPTIONS.ERROR);
      return;
    }
    if (
      (insuranceFormData.planName ?? "") === "" &&
      (insuranceFormData.carrierName ?? "") === "" &&
      (insuranceFormData.coverage ?? "") === "" &&
      (insuranceFormData.groupId ?? "") === "" &&
      (insuranceFormData.enrollmentDate ?? "") === "" &&
      (insuranceFormData.termedDate ?? "") === "" &&
      (insuranceFormData.relation ?? "") === "" &&
      (selectedImage ?? "") === "" &&
      (selectedImageTwo ?? "") === ""
    ) {
      showToastMessage("No value is been enter", TOAST_TYPE_OPTIONS.ERROR);
      return;
    }
    if (activePatient.patientDetails.id) {
      const dataToSave = {
        patientId: activePatient.patientDetails.id,
        selectedImage,
        insuranceCardSlideOne,
        insuranceCardSlideTwo,
        insuranceType,
        ...insuranceFormData,
      };
      if (insuranceData?.insuranceId) {
        setShowFullScreenLoaderStatus(true);
        setIsLoading(true);
        Logger.logInfo("Updating the patient Insurance", { data: dataToSave });
        const updateInsuranceResponse = await updateInsuranceForPatient(
          dataToSave,
          insuranceData?.insuranceId
        );
        onSubmitAction();
        setIsLoading(false);
        if (updateInsuranceResponse.status) {
          showToastMessage(
            updateInsuranceResponse.message,
            TOAST_TYPE_OPTIONS.SUCCESS
          );
        } else {
          showToastMessage(
            updateInsuranceResponse.message,
            TOAST_TYPE_OPTIONS.ERROR
          );
        }
      } else {
        setIsLoading(true);
        Logger.logInfo("creating the patient Insurance", { data: dataToSave });
        const createInsurance = await createNewInsurance(dataToSave);
        setIsLoading(false);
        onSubmitAction();
        if (createInsurance.status) {
          showToastMessage(createInsurance.message, TOAST_TYPE_OPTIONS.SUCCESS);
        } else {
          Logger.logError("failed to create a insurance", {
            data: createInsurance,
          });
          showToastMessage(createInsurance.message, TOAST_TYPE_OPTIONS.ERROR);
        }
      }
    } else {
      showToastMessage(
        "Please select the patient to add any insurance",
        TOAST_TYPE_OPTIONS.ERROR
      );
    }
  };

  const checkForUpdateData = async () => {
    setSelectedImage("");
    setSelectedImageTwo("");
    setFilesFromFirstResponse(undefined);
    setFilesFromSecondResponse(undefined);

    if (
      insuranceData &&
      insuranceData.insuranceId &&
      insuranceData.insuranceId !== ""
    ) {
      setInsuranceFormData({
        planName: insuranceData.planName,
        carrierName: insuranceData.carrierName,
        groupId: insuranceData.groupId,
        enrollmentDate: formatDate(insuranceData.enrollmentDate, "YYYY-MM-DD"),
        termedDate: formatDate(insuranceData.termedDate, "YYYY-MM-DD"),
        relation: insuranceData.relationToPatient,
        coverage: insuranceData.coverage,
      });
      if (insuranceData.insuranceCardSide1Path?.endsWith(".pdf")) {
        setFilesFromFirstResponse(insuranceData.slideOne);
        setSelectedImage(null);
      } else {
        setSelectedImage(insuranceData.slideOne);
        setFilesFirst(undefined);
        setFilesFromFirstResponse(undefined);
      }

      if (insuranceData.insuranceCardSide2Path?.endsWith(".pdf")) {
        setFilesFromSecondResponse(insuranceData.slideTwo);
        setSelectedImageTwo(null);
      } else {
        setSelectedImageTwo(insuranceData.slideTwo);
        setFilesSecond(undefined);
        setFilesFromSecondResponse(undefined);
      }
      setEditFlow(true);
    } else {
      setEditFlow(false);
      handleCancel();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const relationshipListOption = await getOptionsForDropdown(
        "relationship"
      );
      setRelationshipList(relationshipListOption);
      const coverageOption = await getOptionsForDropdown(
        "InsuranceCoverageValue"
      );
      setCoverage(coverageOption);
      checkForUpdateData();
      if (loginUserDetails) {
        const isOnlyViewPermission = isUserHaveOnlyViewPermission({
          permissions: loginUserDetails.userPermission,
          module: "INSURANCE",
        });

        if (isOnlyViewPermission || isDisabled) {
          setViewPermissionOnlyFlag(true);
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (isDisabled) {
      setViewPermissionOnlyFlag(true);
    } else if (loginUserDetails) {
      const isOnlyViewPermission = isUserHaveOnlyViewPermission({
        permissions: loginUserDetails.userPermission,
        module: "INSURANCE",
      });
      setViewPermissionOnlyFlag(isOnlyViewPermission);
    }
  }, [isDisabled]);

  const confirmDelete = async () => {
    Logger.logInfo("deleting the insurance for patient ", {
      data: insuranceData,
    });
    const response = await deleteInsuranceForPatient(insuranceData.insuranceId);
    if (insuranceType === "Primary" && secondaryInsuranceId) {
      Logger.logInfo("Deleting the patient Insurance", { data: insuranceData });
      await deleteInsuranceForPatient(secondaryInsuranceId);
    }
    if (response.status) {
      Logger.logInfo("insurance deleted successfully", {
        data: response,
      });
      showToastMessage(response.message, TOAST_TYPE_OPTIONS.SUCCESS);
      setShowDeletePopup(false);
    } else {
      Logger.logError("insurance not deleted", {
        data: response,
      });
      showToastMessage(response.message, TOAST_TYPE_OPTIONS.ERROR);
      setShowDeletePopup(false);
    }
    setInsuranceFormData({
      planName: "",
      carrierName: "",
      coverage: "",
      groupId: "",
      enrollmentDate: "",
      termedDate: "",
      relation: "",
    });
    setSelectedImage("");
    setSelectedImageTwo("");
    setFilesFromFirstResponse(undefined);
    setFilesFromSecondResponse(undefined);
    setInsuranceCardSlideOne(undefined);
    setInsuranceCardSlideTwo(undefined);
    setFilesFirst(undefined);
    setFilesSecond(undefined);
    onSubmitAction();
  };

  useEffect(() => {
    checkForUpdateData();
  }, [insuranceData]);

  return (
    <div>
      <FullScreenLoader show={showFullScreenLoaderStatus} />
      {showDeletePopup && (
        <DeletePopup
          onClose={() => {
            setShowDeletePopup(false);
          }}
          onConfirm={() => {
            confirmDelete();
          }}
        />
      )}
      <div className="row g-4 form-container">
        <div className="col-lg-6">
          <label htmlFor="title">Insurance Card Side 1</label>
          <div className="file-upload bg-white rounded">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div>
                {selectedImage && (
                  <Image
                    src={selectedImage}
                    alt="Selected Image"
                    width={200}
                    height={100}
                  />
                )}
              </div>
              <div>
                {filesFirst && (
                  <embed
                    src={URL.createObjectURL(filesFirst)}
                    width={200}
                    height={100}
                    type="application/pdf"
                  />
                )}
              </div>
              <div>
                {filesFromFirstResponse && (
                  <embed
                    src={filesFromFirstResponse}
                    width={200}
                    height={100}
                    type="application/pdf"
                  />
                )}
              </div>
              <span className="mdi mdi-cloud-upload-outline fs-1 text-primary" />
              <a href="/" className="fon">
                Browse
              </a>
              <span>Supported Formats: JPEG,PNG,PDF</span>
            </div>
            <input
              type="file"
              name="fileSlide1"
              accept="image/* .pdf"
              disabled={viewPermissionOnlyFlag}
              onChange={handleImageChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <label htmlFor="title">Insurance Card Side 2</label>
          <div className="file-upload bg-white rounded">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div>
                {selectedImageTwo !== "" && selectedImageTwo !== null && (
                  <Image
                    src={selectedImageTwo}
                    alt="Selected Image"
                    width={200}
                    height={100}
                  />
                )}
              </div>
              <div>
                {filesSecond && (
                  <embed
                    src={URL.createObjectURL(filesSecond)}
                    width={200}
                    height={100}
                    type="application/pdf"
                  />
                )}
              </div>
              <div>
                {filesFromSecondResponse && (
                  <embed
                    src={filesFromSecondResponse}
                    width={200}
                    height={100}
                    type="application/pdf"
                  />
                )}
              </div>
              <span className="mdi mdi-cloud-upload-outline fs-1 text-primary" />
              <a href="/">Browse</a>
              <span>Supported Formats: JPEG,PNG,PDF</span>
            </div>
            <input
              type="file"
              name="fileSlide2"
              accept="image/*.pdf"
              disabled={viewPermissionOnlyFlag}
              onChange={handleImageChange}
            />
          </div>
        </div>
        <div className="col-lg-4 col-sm-6">
          <Input
            type="text"
            id="planName"
            name="planName"
            value={insuranceFormData.planName}
            onChange={handleChange}
            placeholder="Plan Name"
            className="form-control form-control-sm"
            disabled={viewPermissionOnlyFlag}
            label={validationRules.planName.displayName}
            error={errors.planName}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <Input
            type="text"
            id="carrierName"
            name="carrierName"
            value={insuranceFormData.carrierName}
            onChange={handleChange}
            placeholder="Carrier Name"
            className="form-control form-control-sm"
            disabled={viewPermissionOnlyFlag}
            label={validationRules.carrierName.displayName}
            error={errors.carrierName}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <InputDropdown
            options={coverage}
            className="form-select form-select-sm"
            onSelect={handleOptionSelect}
            name="coverage"
            value={insuranceFormData.coverage}
            disabled={viewPermissionOnlyFlag}
            label={validationRules.coverage.displayName}
            error={errors.coverage}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <Input
            type="text"
            id="groupId"
            name="groupId"
            value={insuranceFormData.groupId}
            onChange={handleChange}
            placeholder="Group Id"
            className="form-control form-control-sm"
            disabled={viewPermissionOnlyFlag}
            label={validationRules.groupId.displayName}
            error={errors.groupId}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <Input
            type="date"
            id="enrollmentDate"
            name="enrollmentDate"
            value={insuranceFormData.enrollmentDate}
            onChange={handleChange}
            placeholder="dd-mm-yyyy"
            className="form-control form-control-sm"
            max={new Date().toISOString().split("T")[0]}
            disabled={viewPermissionOnlyFlag}
            error={errors.enrollmentDate}
            label={validationRules.enrollmentDate.displayName}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <Input
            type="date"
            id="termedDate"
            name="termedDate"
            value={insuranceFormData.termedDate}
            onChange={handleChange}
            placeholder="dd-mm-yyyy"
            className="form-control form-control-sm"
            min={new Date().toISOString().split("T")[0]}
            disabled={viewPermissionOnlyFlag}
            error={errors.termedDate}
            label={validationRules.termedDate.displayName}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <InputDropdown
            options={relationshipList}
            className="form-select form-select-sm"
            onSelect={handleOptionSelect}
            name="relation"
            value={insuranceFormData.relation}
            disabled={viewPermissionOnlyFlag}
            label={validationRules.relation.displayName}
          />
        </div>
        <div className="col-12 d-flex justify-content-end mt-3">
          {!editFlow && (
            <Button
              onClick={handleCancel}
              variant="outlined"
              className="btn text-danger btn-sm me-3"
              label="clear"
              disabled={viewPermissionOnlyFlag}
            />
          )}
          {editFlow && (
            <Button
              onClick={() => {
                setShowDeletePopup(true);
              }}
              variant="outlined"
              className="btn text-danger btn-sm me-3"
              label="Delete"
              disabled={viewPermissionOnlyFlag}
            />
          )}
          <Button
            onClick={handleSave}
            variant="outlined"
            className="btn btn-primary btn-sm px-4"
            label="Save & Continue"
            isLoading={isLoading}
            disabled={viewPermissionOnlyFlag}
          />
        </div>
      </div>
    </div>
  );
}
