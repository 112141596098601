import {
  deleteLabResultAPI,
  updateExitingLabResult,
  getLabResultByIdAPI,
  getLabResultFileOrImgByIdAPI,
  createNewLabResult,
  getAllLabResult,
} from "../../dataLayer/labAndDiagnosticResult/labResult";
import { formatDate } from "../utils/formatDate";

interface CreateLabResultRecord {
  uploadFile?: any;
  patientId: string;
  patientName: string;
  documentName: string;
  labType: string;
  labDate: string;
  providerName: string;
  comment: string;
  labResultPath: string | undefined;
  files?: File;
}

export async function getAllLabResultServices({
  paginationDataAsParameter,
  patientId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
}): Promise<any> {
  try {
    const allReferrals = await getAllLabResult({
      paginationDataAsParameter,
      patientId,
    });
    if (allReferrals.status) {
      return allReferrals.data;
    }
    return allReferrals;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all Referrals In.",
      details: error.message,
    };
  }
}
export async function getLabResultFileImageByIdService(
  labId: string | number | undefined,
): Promise<any> {
  try {
    if (labId) {
      const LoadImgOrFileUrl = await getLabResultFileOrImgByIdAPI(labId);
      return { loadFileOrImg: LoadImgOrFileUrl };
    }
    return false;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all users.",
      details: error.message,
    };
  }
}
export async function getLabResultByIdService(
  labId: string | number,
): Promise<any> {
  if (!labId) {
    return {
      status: false,
      code: 500,
      message: "Lab ID is required.",
      details: "Lab ID is required.",
    };
  }

  try {
    const response = await getLabResultByIdAPI(labId);
    if (!response || !response.data) {
      return {
        status: false,
        code: 500,
        message: "Lab result data not found.",
        details: "Lab result data not found.",
      };
    }

    const labResultData: Record<any, any> = response;
    const labResultWithImages = await getLabResultFileImageByIdService(
      labResultData.data.id,
    );
    if (labResultWithImages) {
      labResultData.data.images = labResultWithImages;
    }

    return labResultData;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching Lab result value.",
      details: error.message,
    };
  }
}

export async function createLabResult(
  formData: CreateLabResultRecord,
): Promise<any> {
  try {
    const formDataRequest = new FormData();
    formDataRequest.append(
      "labReportData",
      JSON.stringify({
        patientId: formData.patientId,
        patientName: formData.patientName,
        documentName: formData.documentName,
        labType: formData.labType,
        labDate: formatDate(formData.labDate),
        providerName: formData.providerName,
        comment: formData.comment,
        labResultPath: formData.labResultPath,
      }),
    );
    if (formData.uploadFile) {
      formDataRequest.append("files", formData.uploadFile);
    } else {
      formDataRequest.append("files", "");
    }
    const newUser = await createNewLabResult(formDataRequest);
    return newUser;
  } catch (error: any) {
    console.error(error);
    return {
      status: false,
      code: 500,
      message: "Error creating user.",
      details: error.message,
    };
  }
}

export async function updateLabResult(
  params: CreateLabResultRecord,
  LabId: string,
): Promise<any> {
  try {
    const formDataRequest = new FormData();
    formDataRequest.append(
      "labReportData",
      JSON.stringify({
        patientId: params.patientId,
        patientName: params.patientName,
        documentName: params.documentName,
        labType: params.labType,
        labDate: formatDate(params.labDate),
        providerName: params.providerName,
        comment: params.comment,
        labResultPath: params.labResultPath,
      }),
    );
    if (params.uploadFile) {
      formDataRequest.append("files", params.uploadFile);
    } else {
      formDataRequest.append("files", "");
    }
    const newUser = await updateExitingLabResult(formDataRequest, LabId);
    return newUser;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error updating Lab result.",
      details: error.message,
    };
  }
}

export async function deleteLabResultService(
  labId: string | number,
): Promise<any> {
  if (!labId) {
    return {
      status: false,
      code: 500,
      message: "Lab ID is required.",
      details: "Lab ID is required.",
    };
  }

  try {
    const response = await deleteLabResultAPI(labId);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting Lab result value.",
      details: error.message,
    };
  }
}
