import React, { useEffect, useState } from "react";
import { Table } from "../../../components/atom/Table/Table";
import { Card } from "../../../components/atom/Cards/Cards";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import { getAllPcpServices } from "../../../services/masterData/masterData";
import { CreateNewPCP } from "../../../components/organism/CreateNewPCP/CreateNewPCP";
import {
  deletePCPService,
  getPCPInfoService,
} from "../../../services/masterData/pcp";
import { Pagination } from "../../../components/atom/Pagination/Pagination";
import Logger from "../../../utils/Logger";

interface PCPProps {
  displayLabel: string;
}
export function Pcp({ displayLabel }: PCPProps) {
  interface CareTeamListType {
    id: string;
    title: string;
    firstName: string;
    lastName: string;
  }

  const [showModal, setShowModal] = useState<boolean>(false);
  const [pcpEditData, setPcpEditData] = useState<any>(null);
  const [pcpDataForTable, setPcpDataForTable] = useState<CareTeamListType[]>(
    []
  );
  const [paginationData, setPaginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });
  const headersForTable: Record<string, string> = {
    firstName: "First Name",
    lastName: "Last Name",
    providerTypeName: "Provider Type",
    action: "Action",
  };
  const sortingHeaderMap: Record<string, string> = {
    firstName: "FirstName",
    lastName: "LastName",
    providerTypeName: "ProviderType",
  };
  const getAllPCPDataForTable = async (newPaginationData?: any) => {
    const paginationDataAsParameter = newPaginationData || paginationData;
    const pcpDataForTableResponse = await getAllPcpServices({
      paginationDataAsParameter,
    });
    if (pcpDataForTableResponse.isSuccess) {
      setPcpDataForTable(pcpDataForTableResponse.dataList);
      setPaginationData({
        ...paginationData,
        pageNo: pcpDataForTableResponse.pageNo,
        totalPages: pcpDataForTableResponse.totalPages,
        totalElements: pcpDataForTableResponse.totalElements,
        pageSize: pcpDataForTableResponse.pageSize,
      });
    } else {
      showToastMessage(
        pcpDataForTableResponse.message,
        TOAST_TYPE_OPTIONS.ERROR
      );
    }
  };

  const deletePCPValue = async (rowData: any) => {
    Logger.logInfo("Deleting PCP info", { data: rowData });
    const deletePCPResponse = await deletePCPService(rowData.id);
    if (deletePCPResponse.status) {
      showToastMessage(deletePCPResponse.message, TOAST_TYPE_OPTIONS.SUCCESS);
      getAllPCPDataForTable();
    } else {
      showToastMessage(deletePCPResponse.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  const editRowAction = async (rowData: any) => {
    const pcpData = await getPCPInfoService(rowData.id);
    if (pcpData.status) {
      setPcpEditData(pcpData.data);
    } else {
      showToastMessage(pcpData.message, TOAST_TYPE_OPTIONS.ERROR);
      setPcpEditData(null);
    }
  };
  useEffect(() => {
    getAllPCPDataForTable();
  }, []);

  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    getAllPCPDataForTable({ ...paginationData, pageNo, pageSize });
  };

  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    getAllPCPDataForTable({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };

  return (
    <div>
      <Card>
        <div className="card-title d-flex justify-content-between align-items-center">
          <h5>{displayLabel}</h5>
          <div className="d-flex text-end">
            <CreateNewPCP
              setShow={setShowModal}
              show={showModal}
              buttonTitle="+ Add Primary & Specialty Care provider"
              getAllPCPDataForTable={getAllPCPDataForTable}
              editData={pcpEditData}
              resetEditData={() => setPcpEditData(null)}
            />
          </div>
        </div>
        <div className="table-responsive care-manager-table">
          <Table
            className="table table-bordered table-striped table-hover"
            data={pcpDataForTable}
            headers={headersForTable}
            enableDeleteAction={deletePCPValue}
            enableEditAction={(rowData) => {
              editRowAction(rowData);
            }}
            sortingHeaderMap={sortingHeaderMap}
            sortingFunction={sortingFunction}
          />
          {paginationData.totalPages >= 0 && (
            <Pagination
              currentPage={paginationData.pageNo}
              totalPages={paginationData.totalPages}
              pageSize={paginationData.pageSize}
              onPageChange={(page, pageSize) => {
                updatePaginationData(page, pageSize);
              }}
            />
          )}
        </div>
      </Card>
    </div>
  );
}
