/* eslint-disable complexity */
import ApiCaller from "../../utils/apiCaller";
import { API_ENDPOINTS } from "../../constants/apiList";
import { MODULE_NAME_OPTION, STATUS_CODES } from "../../constants/variables";

export async function getAllCareTeamList({
  paginationDataAsParameter,
  patientId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
}): Promise<any> {
  const { pageNo, pageSize, sortingFieldName, sortingOrder } =
    paginationDataAsParameter;
  let getCareTeamsUrl = `${API_ENDPOINTS.getAllCareTeamList}/${patientId}`;
  if (pageSize) {
    getCareTeamsUrl = `${getCareTeamsUrl}?pageNo=${pageNo}&pageSize=${pageSize}`;
  }
  if (
    sortingFieldName &&
    sortingFieldName !== "" &&
    sortingOrder &&
    sortingOrder !== ""
  ) {
    getCareTeamsUrl = `${getCareTeamsUrl}&sortBy=${sortingFieldName}&sortDir=${sortingOrder}`;
  }

  try {
    const response = await ApiCaller.get<{
      isSuccess: boolean;
      responseMsg?: string[];
      dataList: Record<string, string>;
    }>(getCareTeamsUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "CareTeam Data Retrieved Successfully",
        data: response,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to Retrieve CareTeam Data",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
export async function addCareTeamFromProviderSearch(requestObject: any) {
  const addNewCareTeamUrl =
    requestObject.moduleName === MODULE_NAME_OPTION.careTeam
      ? API_ENDPOINTS.addCareTeamFromProvider
      : API_ENDPOINTS.addCareManagerFromProvider;
  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(addNewCareTeamUrl, requestObject);

    if (response.isSuccess) {
      const messageToReturn =
        requestObject.moduleName === MODULE_NAME_OPTION.careTeam
          ? "New Care Team added successfully"
          : "New Case Manager added successfully";
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: messageToReturn,
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to add new CareTeam",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function createNewCareTeam(requestObject: any) {
  const addNewCareTeamUrl =
    requestObject.moduleName === MODULE_NAME_OPTION.careTeam
      ? API_ENDPOINTS.createNewCareTeam
      : API_ENDPOINTS.createNewCaseManager;

  try {
    const response = await ApiCaller.post<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(addNewCareTeamUrl, requestObject);

    if (response.isSuccess) {
      const messageToReturn =
        requestObject.moduleName === MODULE_NAME_OPTION.careTeam
          ? "New Care Team added successfully"
          : "New Case Manager added successfully";
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: messageToReturn,
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to add new CareTeam",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function updateExistingCareTeam(
  requestObject: any,
  careTeamId: string,
) {
  const updateExistingCareTeamUrl =
    requestObject.moduleName === MODULE_NAME_OPTION.careTeam
      ? `${API_ENDPOINTS.updateCareTeam}/${careTeamId}`
      : `${API_ENDPOINTS.updateCaseManager}/${careTeamId}`;

  try {
    const response = await ApiCaller.put<{
      isSuccess: boolean;
      responseMsg?: string[];
      data: Record<string, string>;
    }>(updateExistingCareTeamUrl, requestObject);

    if (response.isSuccess) {
      const messageToReturn =
        requestObject.moduleName === MODULE_NAME_OPTION.careTeam
          ? "Update Care Team successfully"
          : "Update Case Manager successfully";
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: messageToReturn,
        data: response.data,
      };
    }

    return {
      status: response.isSuccess ?? false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response?.responseMsg?.[0] || "Failed to update CareTeam",
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function deleteCareTeamAPI(
  careTeamId: number | string,
): Promise<any> {
  const deleteCareTeam = `${API_ENDPOINTS.deleteNewCareTeam}/${careTeamId}`;

  try {
    const response: { isSuccess: boolean; responseMsg?: Array<string> } =
      await ApiCaller.delete(deleteCareTeam);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "CareTeam Deleted successfully",
        data: response,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getCareTeamDetailsAPI(
  careTeamId: number | string,
): Promise<any> {
  const getCareTeamUrl = `${API_ENDPOINTS.getCareTeamDetails}/${careTeamId}`;

  try {
    const response: {
      isSuccess: boolean;
      responseMsg?: Array<string>;
      data: Record<string, string>;
    } = await ApiCaller.get(getCareTeamUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "CareTeam Details Retrieve successfully",
        data: response.data,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getSearchForCareTeamEmployeeListAPI(
  searchString: string,
  patientId: string | number,
  providerTypes: any[],
): Promise<any> {
  if (searchString) {
    const searchStringUrl = `${API_ENDPOINTS.searchCareTeamEmployeeList}/${searchString}?patientId=${patientId}&providerTypeArray=${providerTypes}`;

    try {
      const response = await ApiCaller.get(searchStringUrl);
      return response;
    } catch (error: any) {
      return {
        status: false,
        code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
        message: error?.message || "API failed",
        details: error?.response?.data?.error || "API call failed",
      };
    }
  }
  return null;
}
