import React from "react";

interface Options {
  option: string;
  optionSeq: number;
}

interface RadioButtonProps {
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  options: Options[];
  id?: string;
  label?: string;
  name?: string;
  disabled?: boolean;
  ariaLabel?: string;
  ref?: React.RefObject<HTMLInputElement>;
  fullWidth?: boolean;
  required?: boolean;
  error?: string;
}

export function RadioButton({
  value,
  onChange,
  options,
  id,
  label,
  name,
  disabled = false,
  ariaLabel,
  ref,
  fullWidth,
  required,
  error,
}: RadioButtonProps) {
  return (
    <div className={`${fullWidth ? "w-100" : ""} mb-2`}>
      {label && (
        <label htmlFor={id} className="d-block">
          {label}
          {required && <span className="text-danger"> *</span>}
        </label>
      )}
      <div className="mx-4">
        {options?.map((option) => (
          <div key={option.optionSeq} className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name={name}
              id={`${id}-${option.optionSeq}`}
              value={option.option}
              checked={value === option.option}
              onChange={onChange}
              disabled={disabled}
              aria-label={ariaLabel}
              ref={ref}
            />
            <label
              className="form-check-label"
              htmlFor={`${id}-${option.optionSeq}`}
            >
              {option.option}
            </label>
          </div>
        ))}
      </div>

      {error && <span className="text-danger error-message">{error}</span>}
    </div>
  );
}
