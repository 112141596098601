const baseURL = "http://67.227.158.154";

export const API_ENDPOINTS = {
  loginViaEmail: `${baseURL}/api/auth/signin`,
  forgotPasswordViaEmail: `${baseURL}/api/auth/forgotPwd`,
  createUser: `${baseURL}/api/user/create`,
  deleteUser: `${baseURL}/api/user`,
  getAllUsers: `${baseURL}/api/user/all/withPagination`,
  getActiveUsers: `${baseURL}/api/user/allActive`,
  updateUser: `${baseURL}/api/user`,
  getAllUserRoles: `${baseURL}/api/role/all`,

  getAllPatientList: `${baseURL}/api/patient/all/withPagination`,
  getDropDownOptions: `${baseURL}/api/dropdownValues`,
  createNewInsurance: `${baseURL}/api/insurance/create`,
  updateInsurance: `${baseURL}/api/insurance`,

  getInsuranceForPatient: `${baseURL}/api/insurance`,
  deleteInsuranceForPatient: `${baseURL}/api/insurance`,
  getFirstSlideOfInsurance: `${baseURL}/api/insurance/viewInsuranceSide1`,
  getSecondSlideOfInsurance: `${baseURL}/api/insurance/viewInsuranceSide2`,

  getAllInsurance: `${baseURL}/api/insurance/all`,
  getAllReferralsIn: `${baseURL}/api/referral/in/all/withPagination`,
  getReferralsInById: `${baseURL}/api/referral/in`,
  getAllReferralsOut: `${baseURL}/api/referral/out/all/withPagination`,
  getReferralsOutById: `${baseURL}/api/referral/out`,
  deleteReferralIn: `${baseURL}/api/referral/in`,
  deleteReferralOut: `${baseURL}/api/referral/out`,
  createReferralsIn: `${baseURL}/api/referral/in/create`,
  createReferralsOut: `${baseURL}/api/referral/out/create`,
  updateReferralsIn: `${baseURL}/api/referral/in`,
  updateReferralsOut: `${baseURL}/api/referral/out`,
  searchIcd10List: `${baseURL}/api/icd10Code/search`,
  searchProcCodeList: `${baseURL}/api/procedureCode/search`,
  searchOrganisationList: `${baseURL}/api/organization/search`,

  // patient
  createNewQuickPatientBasicInfo: `${baseURL}/api/patient/quickAdd`,
  createNewPatientBasicInfo: `${baseURL}/api/patient/details`,
  UpdatePatientBasicInfo: `${baseURL}/api/patient`,
  getPatientDetails: `${baseURL}/api/patient`,
  deletePatientDetails: `${baseURL}/api/patient`,
  getPatientProfileImage: `${baseURL}/api/patient/image`,

  createPatientContactInfo: `${baseURL}/api/patient/contactInfo`,
  updatePatientContactInfo: `${baseURL}/api/patient/contactInfo`,
  createPatientProviderInfo: `${baseURL}/api/patient/providerInfo`,
  updatePatientProviderInfo: `${baseURL}/api/patient/providerInfo`,
  createRaceAndEthnicity: `${baseURL}/api/patient/raceEthnicity`,
  getAllRaceAndEthnicity: `${baseURL}/api/dropdownValues/raceEthnicity`,
  searchPatientName: `${baseURL}/api/patient/search`,

  // timeline
  createTimeline: `${baseURL}/api/timelines-events/create`,
  getTimelineList: `${baseURL}/api/timelines-events/all`,
  getTimelineForEvent: `${baseURL}/api/timelines-events`,
  getTimelineForGraph: `${baseURL}/api/timelines-events/all-months-category-count`,
  updateTimeline: `${baseURL}/api/timelines-events`,

  // memos
  createNewMemo: `${baseURL}/api/memo/create`,
  getAllMemoList: `${baseURL}/api/memo/all/withPagination`,
  deleteNewMemo: `${baseURL}/api/memo`,
  updateMemo: `${baseURL}/api/memo`,

  // task
  getAllTasks: `${baseURL}/api/task/all/withPagination`,
  getTasksDetails: `${baseURL}/api/task`,
  getTaskCount: `${baseURL}/api/task/alert`,
  deleteTasks: `${baseURL}/api/task`,
  updateTasks: `${baseURL}/api/task`,
  createNewTask: `${baseURL}/api/task/create`,
  filterTask: `${baseURL}/api/task/all/filter`,

  // appointments
  getAllAppointments: `${baseURL}/api/appointment/all`,
  getAppointmentById: `${baseURL}/api/appointment`,
  createNewAppointment: `${baseURL}/api/appointment/create`,
  deleteAppointment: `${baseURL}/api/appointment`,
  updateAppointment: `${baseURL}/api/appointment`,

  getAllCareTeamMembers: `${baseURL}/api/careTeamMembers`,
  createNewCareTeamMember: `${baseURL}/api/careTeamMembers/create`,
  getAllCaseManagerMembers: `${baseURL}/api/careTeamMembers`,
  createCaseManagerMember: `${baseURL}/api/careTeamMembers/create`,
  getPatientCaseManagerList: `${baseURL}/api/dropdownValues/caseManager`,
  getPatientCaseManagerAndAdminList: `${baseURL}/api/dropdownValues/adminAndCaseManager`,
  listOfCountry: `${baseURL}/api/country/all`,

  // pcp
  listOfAllPcp: `${baseURL}/api/pcp/all/withPagination`,
  createPcp: `${baseURL}/api/pcp/create`,
  searchPcpList: `${baseURL}/api/pcp/search`,
  searchSpecialtyProviderList: `${baseURL}/api/pcp/search/specialityProvider`,
  deletePcp: `${baseURL}/api/pcp`,
  getPcpDetails: `${baseURL}/api/pcp`,
  updatePcpDetails: `${baseURL}/api/pcp`,

  // organization
  createOrg: `${baseURL}/api/organization/create`,
  listOfAllOrganization: `${baseURL}/api/organization/all/withPagination`,
  deleteOrganization: `${baseURL}/api/organization`,
  updateOrganization: `${baseURL}/api/organization`,
  searchOrganizationWithWord: `${baseURL}/api/organization/search`,
  getReferralDetails: `${baseURL}/api/organization`,
  searchProcCodeById: `${baseURL}/api/procedureCode`,
  searchSpecialtyProviderById: `${baseURL}/api/pcp`,
  searchIcd10CodeById: `${baseURL}/api/icd10Code`,
  searchReferredToId: `${baseURL}/api/referral/in`,
  searchReferredById: `${baseURL}/api/referral/out`,

  // case Manager
  createNewCaseManager: `${baseURL}/api/careprovider/casemanager/create`,
  getAllCaseManagerList: `${baseURL}/api/careprovider/casemanager/all`,
  deleteNewCaseManager: `${baseURL}/api/careprovider/casemanager`,
  updateCaseManager: `${baseURL}/api/careprovider/casemanager`,
  addCareManagerFromProvider: `${baseURL}/api/careprovider/casemanager/add`,
  searchCaseManagerList: `${baseURL}/api/careprovider/search`,

  // care plan

  createNewCarePlan: `${baseURL}/api/carePlan/memberDetail`,
  updatePatientCarePlan: `${baseURL}/api/carePlan`,
  createCarePlanAllergies: `${baseURL}/api/carePlan/allergies`,
  createICDTeamNotes: `${baseURL}/api/carePlan/icd10`,
  createGoalForPatient: `${baseURL}/api/carePlan/goal`,
  updateCareGiverDetails: `${baseURL}/api/carePlan/careGiver`,
  updateCaseManagerDetails: `${baseURL}/api/carePlan/caseManager`,
  updateICDTeamNotes: `${baseURL}/api/carePlan/icd10`,
  updateCarePlanAllergies: `${baseURL}/api/carePlan/allergies`,
  updateGoalForPatient: `${baseURL}/api/carePlan/goal`,
  getCarePlanCareTeam: `${baseURL}/api/carePlan/careTeam/all/withPagination`,
  getCareManagerDetails: `${baseURL}/api/carePlan/caseManager`,
  getCarePlanMemberDetails: `${baseURL}/api/carePlan/memberDetail`,
  getPatientAllAllergies: `${baseURL}/api/carePlan/allergies/all/withPagination`,
  getPatientMedication: `${baseURL}/api/carePlan/medication/all/withPagination`,
  getAllCarePlanForPatient: `${baseURL}/api/carePlan/all/withPagination`,
  getDiagnosticHistoryPatient: `${baseURL}/api/carePlan/diagnosisHistory/all/withPagination`,
  getLabResultsForPatient: `${baseURL}/api/carePlan/labResult/all/withPagination`,
  getDiagnosticResultsForPatient: `${baseURL}/api/carePlan/diagnosticResult/all/withPagination`,
  getCarePlanICD10Values: `${baseURL}/api/carePlan/icd10`,
  getCarePlanGoals: `${baseURL}/api/carePlan/goal/all/withPagination`,
  getCarePlanCareGiverValues: `${baseURL}/api/carePlan/careGiver`,
  getCarePlanInitialRiskAssessment: `${baseURL}/api/carePlan/ira/all/withPagination`,
  markTheCarePlanFinalize: `${baseURL}/api/carePlan/finalizeCarePlan`,
  deleteCarePlan: `${baseURL}/api/carePlan`,

  // care team
  createNewCareTeam: `${baseURL}/api/careprovider/careteam/create`,
  addCareTeamFromProvider: `${baseURL}/api/careprovider/careteam/add`,

  getAllCareTeamList: `${baseURL}/api/careprovider/careteam/all`,
  deleteNewCareTeam: `${baseURL}/api/careprovider/careteam`,
  getCareTeamDetails: `${baseURL}/api/careprovider/careteam`,
  updateCareTeam: `${baseURL}/api/careprovider/careteam`,
  searchCareTeamEmployeeList: `${baseURL}/api/careprovider/search`,

  // lab and Diagnostic result
  getLabResultById: `${baseURL}/api/labResult`,
  createLabResult: `${baseURL}/api/labResult/create`,
  listOfAllLabResult: `${baseURL}/api/labResult/all/withPagination`,
  deleteLabResult: `${baseURL}/api/labResult`,
  updateLabResult: `${baseURL}/api/labResult`,
  getLabResultFileOrImg: `${baseURL}/api/labResult/image`,

  // Forms
  getAllForms: `${baseURL}/api/forms/all`,
  deleteForm: `${baseURL}/api/forms`,
  addForm: `${baseURL}/api/forms/create`,
  getForm: `${baseURL}/api/forms`,
  updateForm: `${baseURL}/api/forms`,
  getFormFileOrImg: `${baseURL}/api/forms/image`,

  // IRA
  getAllIRAs: `${baseURL}/api/ira/all/withPagination`,
  addIRA: `${baseURL}/api/ira/create`,
  getIRA: `${baseURL}/api/ira`,
  updateIRA: `${baseURL}/api/ira`,
  deleteIRA: `${baseURL}/api/ira`,
  finalizeIRA: `${baseURL}/api/ira/finalize`,
  validateAssessment: `${baseURL}/api/ira/validateAssessment`,

  // Assessment
  createAssessment: `${baseURL}/api/ira/assessment/create?type=`,
  getAssessment: `${baseURL}/api/ira/assessment`,
  updateAssessment: `${baseURL}/api/ira/assessment`,

  // Question
  getQuestions: `${baseURL}/api/questions`,

  // lab and Diagnostic result
  getDiagnosticResultById: `${baseURL}/api/diagnostics`,
  createDiagnosticResult: `${baseURL}/api/diagnostics/create`,
  listOfAllDiagnosticResult: `${baseURL}/api/diagnostics/all/withPagination`,
  deleteDiagnosticResult: `${baseURL}/api/diagnostics`,
  updateDiagnosticResult: `${baseURL}/api/diagnostics`,
  getDiagnosticResultFileOrImg: `${baseURL}/api/diagnostics/image`,

  // Case Notes
  createNewCaseNote: `${baseURL}/api/caseNote/detail/create`,
  getAllCaseNotesForPatient: `${baseURL}/api/caseNote/all/withPagination`,
  getCaseNoteDetails: `${baseURL}/api/caseNote`,
  updateCaseNote: `${baseURL}/api/caseNote`,
  deleteCaseNote: `${baseURL}/api/caseNote`,
  finalizeCaseNote: `${baseURL}/api/caseNote/finalize`,

  // Health Concern -Case Notes
  CaseNotesHealthConcern: `${baseURL}/api/caseNote/healthConcern`,
  getHealthConcernCaseNotes: `${baseURL}/api/caseNote/healthConcern/all`,

  // Medication- Case Notes
  CaseNotesMedication: `${baseURL}/api/caseNote/medication`,
  getMedicationCaseNotes: `${baseURL}/api/caseNote/medication/all`,

  // Vitals- Case Notes
  getVitalsCaseNoteDetails: `${baseURL}/api/caseNote/vital/all`,
  vitalCaseNoteUrl: `${baseURL}/api/caseNote/vital`,
  measureUnitDropdownValue: `${baseURL}/api/uom/all`,

  // Chief Complaints - Case Notes
  createChiefComplaintCaseNotes: `${baseURL}/api/caseNote/chiefComplaint`,

  // Paln - Case Notes
  createPlanCaseNotes: `${baseURL}/api/caseNote/plan`,

  // user & login
  resetUserPassword: `${baseURL}/api/user/resetPwd`,
  checkForTokenLink: `${baseURL}/api/auth/redirectToResetPwd`,
};
