import Logger from "../../utils/Logger";
import {
  createNewAppointmentAPI,
  getAppointmentByIdAPI,
  deleteAppointmentAPI,
  getAllAppointmentsAPI,
  updateExistingAppointmentAPI,
} from "../../dataLayer/appointments/appointments";
import { formatDate } from "../utils/formatDate";

export async function getAllAppointments({
  paginationDataAsParameter,
  patientId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
}): Promise<any> {
  try {
    const allAppointments = await getAllAppointmentsAPI({
      paginationDataAsParameter,
      patientId,
    });
    return allAppointments;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all appointments.",
      details: error.message,
    };
  }
}

export async function getAppointmentByIdService(
  appointmentId: string | number,
): Promise<any> {
  if (!appointmentId) {
    return {
      status: false,
      code: 500,
      message: "appointment ID is required.",
      details: "appointment ID is required.",
    };
  }

  try {
    const response = await getAppointmentByIdAPI(appointmentId);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error getting appointment value.",
      details: error.message,
    };
  }
}

export async function createAppointment(formData: any): Promise<any> {
  try {
    const requestData = {
      visit: parseInt(formData.visit, 10),
      appointmentDate: formatDate(formData.appointmentDate),
      appointmentTime: formData.appointmentTime,
      icd10: formData.icd10,
      cptCode: formData.cptCode,
      status: formData.status,
      reason: formData.reason,
      appRescheduleDate: formData.rescheduleDate,
      appRescheduleTime: formData.rescheduleTime,
      patientId: formData.patientId,
    };
    Logger.logInfo("creating new appointment", { data: requestData });
    const createAppointmentResponse =
      await createNewAppointmentAPI(requestData);
    return createAppointmentResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating appointment.",
      details: error.message,
    };
  }
}
export async function updateAppointment(
  formData: any,
  appointmentId: string,
): Promise<any> {
  try {
    const requestData = {
      visit: parseInt(formData.visit, 10),
      appointmentDate: formatDate(formData.appointmentDate),
      appointmentTime: formData.appointmentTime,
      icd10: formData.icd10,
      cptCode: formData.cptCode,
      status: formData.status,
      reason: formData.reason,
      appRescheduleDate: formatDate(formData.appRescheduleDate),
      appRescheduleTime: formData.appRescheduleTime,
      patientId: formData.patientId,
    };
    Logger.logInfo("updating appointment", {
      data: { requestData, appointmentId },
    });
    const createAppointmentResponse = await updateExistingAppointmentAPI(
      requestData,
      appointmentId,
    );
    return createAppointmentResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating appointment.",
      details: error.message,
    };
  }
}

export async function deleteAppointmentService(
  appointmentId: string | number,
): Promise<any> {
  if (!appointmentId) {
    return {
      status: false,
      code: 500,
      message: "appointment ID is required.",
      details: "appointment ID is required.",
    };
  }

  try {
    Logger.logInfo("deleting appointment", { data: appointmentId });
    const response = await deleteAppointmentAPI(appointmentId);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting memo value.",
      details: error.message,
    };
  }
}
