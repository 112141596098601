import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "../../../components/atom/Table/Table";
import { Card } from "../../../components/atom/Cards/Cards";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import {
  MODULE_NAME_OPTION,
  TOAST_TYPE_OPTIONS,
} from "../../../constants/variables";
import { CreateCareTeamAndCaseManager } from "../../../components/organism/CreateCareTeamAndCaseManager/CreateCareTeamAndCaseManager";
import { ProfileCard } from "../../../components/molecule/ProfileCard/ProfileCard";
import {
  deleteCareTeamService,
  getAllCareTeam,
  getCareTeamDetailsService,
} from "../../../services/careTeam/careTeam";

import { formatDate } from "../../../services/utils/formatDate";
import { Pagination } from "../../../components/atom/Pagination/Pagination";
import {
  deleteCaseManagerService,
  getAllCaseManager,
} from "../../../services/caseManager/caseManager";
import Logger from "../../../utils/Logger";

export function CareTeamAndCaseManager({ moduleName }: { moduleName: string }) {
  interface CareTeamListType {
    organization: string;
    providerName: string;
    providerType: string;
    primaryPhoneNumber: string;
    fax: string;
    email: string;
    status: string;
    fromDate: string;
    lastSeenDate: string;
    actions: string;
  }

  const activePatient = useSelector((state: any) => state.activePatient);
  const [careTeam, setCareTeam] = useState<any>({
    organization: "",
    providerName: "",
    providerType: "",
    contactNumber: "",
    fax: "",
    email: "",
    status: "",
    fromDate: "",
    endDate: "",
    id: "",
  });
  const [paginationData, setPaginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [userListData, setUserListData] = useState<CareTeamListType[]>([]);
  const headersForTable: Record<string, string> = {
    organizationName: "Organization",
    providerName: "Provider Name",
    providerTypeValue: "Provider Type",
    contactNumber: "Contact Number",
    fax: "Fax",
    email: "Email ",
    statusValue: "Status",
    fromDate: "From Date",
    toDate: "End Date",
    notes: "Notes",
    action: "Actions",
  };
  const sortingHeaderMap: Record<string, string> = {
    providerName: "ProviderName",
    providerTypeValue: "ProviderType",
    contactNumber: "ContactNumber",
    fax: "Fax",
    email: "Email",
    statusValue: "Status",
    fromDate: "FromDate",
    toDate: "EndDate",
    notes: "Notes",
  };
  const fetchData = async (newPaginationData?: any) => {
    try {
      const paginationDataAsParameter = newPaginationData || paginationData;
      const patientId = activePatient.patientDetails.id;
      if (patientId !== "") {
        let careTeamList;
        if (moduleName === MODULE_NAME_OPTION.careManager) {
          careTeamList = await getAllCaseManager({
            paginationDataAsParameter,
            patientId,
          });
        } else {
          careTeamList = await getAllCareTeam({
            paginationDataAsParameter,
            patientId,
          });
        }

        if (careTeamList.status) {
          setPaginationData({
            ...paginationData,
            pageNo: careTeamList.data.pageNo,
            totalPages: careTeamList.data.totalPages,
            totalElements: careTeamList.data.totalElements,
            pageSize: careTeamList.data.pageSize,
          });
          setUserListData(careTeamList.data.dataList);
          setCareTeam({
            organization: "",
            providerName: "",
            providerType: "",
            contactNumber: "",
            fax: "",
            email: "",
            status: "",
            fromDate: "",
            endDate: "",
            id: "",
          });
        } else {
          setUserListData([]);
        }
      }
    } catch (error) {
      showToastMessage("Error fetching all users:", TOAST_TYPE_OPTIONS.ERROR);
      console.error("Error fetching all users:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [moduleName]);

  useEffect(() => {
    if (!activePatient.patientDetails.id) {
      setUserListData([]);
    }
  }, [activePatient]);

  const deleteCareTeam = async (rowData: any) => {
    let deleteCareTeamResponse;
    Logger.logInfo("Care Team : Delete Operation", { data: rowData });
    if (rowData.isCaseManager && moduleName === MODULE_NAME_OPTION.careTeam) {
      showToastMessage(
        "Can't delete the case manager",
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }
    if (moduleName === MODULE_NAME_OPTION.careTeam) {
      deleteCareTeamResponse = await deleteCareTeamService(rowData.id);
    } else {
      deleteCareTeamResponse = await deleteCaseManagerService(rowData.id);
    }

    if (deleteCareTeamResponse.status) {
      showToastMessage(
        deleteCareTeamResponse.message,
        TOAST_TYPE_OPTIONS.SUCCESS
      );
      fetchData();
    } else {
      showToastMessage(
        deleteCareTeamResponse.message,
        TOAST_TYPE_OPTIONS.ERROR
      );
    }
  };

  const editCareTeam = async (rowData: any) => {
    Logger.logInfo("editCareTeam", { data: rowData });
    if (rowData.isCaseManager && moduleName === MODULE_NAME_OPTION.careTeam) {
      showToastMessage("Can't edit the case manager", TOAST_TYPE_OPTIONS.ERROR);
      return;
    }
    const careTeamDetails = await getCareTeamDetailsService(rowData.id);
    if (careTeamDetails.status) {
      setCareTeam({
        organization: careTeamDetails.data.organizationName,
        providerName: careTeamDetails.data.providerName,
        providerType: careTeamDetails.data.providerTypeValue,
        contactNumber: careTeamDetails.data.contactNumber,
        notes: careTeamDetails.data.notes,
        patientId: careTeamDetails.data.patientId,
        status: careTeamDetails.data.status,
        statusValue: careTeamDetails.data.statusValue,
        employeeId: careTeamDetails.data.employeeId,
        id: careTeamDetails.data.id,
        fromDate: formatDate(careTeamDetails.data.fromDate, "YYYY-MM-DD"),
        endDate: formatDate(careTeamDetails.data.toDate, "YYYY-MM-DD"),
        email: careTeamDetails.data.email,
        fax: careTeamDetails.data.fax,
      });
    } else {
      showToastMessage(careTeamDetails.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    fetchData({ ...paginationData, pageNo, pageSize });
  };

  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    fetchData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };
  return (
    <div>
      <ProfileCard />
      <Card>
        <div className="d-flex justify-content-between">
          <h3>
            {moduleName === MODULE_NAME_OPTION.careManager
              ? "Case Manager"
              : "Care Team"}
          </h3>
          <CreateCareTeamAndCaseManager
            setShow={setShowModal}
            show={showModal}
            editData={careTeam}
            fetchCareTeamData={fetchData}
            moduleName={moduleName}
          />
        </div>

        <div className="table-responsive care-team-table">
          <Table
            className="table table-bordered table-striped table-hover"
            data={userListData}
            headers={headersForTable}
            enableDeleteAction={deleteCareTeam}
            enableEditAction={editCareTeam}
            sortingHeaderMap={sortingHeaderMap}
            sortingFunction={sortingFunction}
          />
          {paginationData.totalPages >= 0 && (
            <Pagination
              currentPage={paginationData.pageNo}
              totalPages={paginationData.totalPages}
              pageSize={paginationData.pageSize}
              onPageChange={(page, pageSize) => {
                updatePaginationData(page, pageSize);
              }}
            />
          )}
        </div>
      </Card>
    </div>
  );
}
