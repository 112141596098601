/* eslint-disable complexity */
import React, { useEffect, useState } from "react";
import {
  createAssessmentService,
  getAssessmentByIdService,
  getIRAQuestionsService,
  updateAssessmentService,
} from "../../../../services/FormsAndIRA/ira";
import { sortArray } from "../../../../services/utils/sortArray";
import { RadioButton } from "../../../atom/RadioButton/RadioButton";
import { FullScreenLoader } from "../../../atom/FullScreenLoader/FullScreenLoader";
import { Button } from "../../../atom/Buttons/Button";
import {
  ValidationRules,
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../../services/utils/validateDataAsPerRules";
import { Textarea } from "../../../atom/Textarea/Textarea";
import {
  AssessmentRecord,
  IRARecord,
  QuestionsData,
  OptionsData,
} from "../../../../dataLayer/formsAndIRA/ira";
import { showToastMessage } from "../../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../../constants/variables";
import { assessmentOptions } from "../helperFunction/constants";
import { Input } from "../../../atom/Input/Input";

export interface IRAQuestionsProps {
  apiKey: string;
  iraDetails: IRARecord;
  isViewMode: boolean;
}

interface FormDataAndErrors {
  [key: string]: any;
}

export default function IRAQuestions({
  apiKey,
  iraDetails,
  isViewMode,
}: IRAQuestionsProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [validationRules, setValidationRules] = useState<ValidationRules>({});
  const [questionsList, setQuestionsList] = useState<QuestionsData[]>([]);
  const [assessments, setAssessments] = useState<AssessmentRecord[]>([]);
  const [formData, setFormData] = useState<{
    values: FormDataAndErrors;
    errors: FormDataAndErrors;
  }>({ values: {}, errors: {} });

  const { values, errors } = formData;

  const setInitialFormValue = (
    questions: QuestionsData[],
    assessmentList: AssessmentRecord[]
  ) => {
    const rules: ValidationRules = {};
    const initialFormData: FormDataAndErrors = {};
    questions.forEach((question) => {
      if (question.answerOptions.length > 0) {
        const assessment = assessmentList.find(
          (item) => item.assessmentQuestionnaireId === question.questionId
        );
        const refinedValue =
          assessmentOptions.find(
            (option) => option.value === assessment?.answer
          )?.label ?? "";
        const isNoteRequired =
          question.answerOptions.find(
            (option) => option.option === refinedValue
          )?.noteReq ?? false;
        initialFormData[question.questionId] = refinedValue;
        initialFormData[`note-${question.questionId}`] = assessment?.note || "";

        if (
          question?.fieldType === "number" ||
          question?.fieldType === "textarea" ||
          question?.fieldType === "text"
        ) {
          rules[`note-${question.questionId}`] = {
            required: question.answerOptions.length > 0,
            dataType: question?.dataType || "any",
            displayName: question.question,
            characterLimit: question.charLimit,
            maxValue: question.max,
          };
        } else {
          rules[question.questionId] = {
            required: question.answerOptions.length > 0,
            dataType: "any",
            displayName: question.question,
            characterLimit: question.charLimit,
            maxValue: question.max,
          };
          rules[`note-${question.questionId}`] = {
            required: isNoteRequired,
            dataType: "any",
            displayName: "Please Specify",
            characterLimit: 2000,
          };
        }
      }
      if (question.subQuestions.length > 0) {
        setInitialFormValue(question.subQuestions, assessmentList);
      }
    });
    setValidationRules((prev) => ({
      ...prev,
      ...rules,
    }));
    setFormData((prev) => ({
      values: { ...prev.values, ...initialFormData },
      errors: {},
    }));
  };

  const findFiledInfo = (answerOptions: OptionsData[]) => {
    switch (Array.isArray(answerOptions) && answerOptions[0]?.option) {
      case "TextArea":
        return { type: "textarea", charLimit: 2000, dataType: "any" };
      case "Text":
        return { type: "text", charLimit: 100, dataType: "text" };
      case "Number":
        return { type: "number", charLimit: 50, dataType: "number", max: 30 };
      default:
        return { type: "radio", dataType: "any" };
    }
  };

  const updateQuestions = (questions: QuestionsData[]) =>
    questions.map((question) => {
      const { type, charLimit, max, dataType } = findFiledInfo(
        question.answerOptions
      );
      const updatedQuestion = {
        ...question,
        fieldType: type,
        charLimit,
        max,
        dataType,
      };

      if (question.subQuestions && question.subQuestions.length > 0) {
        updatedQuestion.subQuestions = updateQuestions(question.subQuestions);
      }

      return updatedQuestion;
    });

  const getQuestions = async () => {
    if (apiKey) {
      setIsLoading(true);
      const questionsResponse = await getIRAQuestionsService(apiKey);
      const getEditResponse = await getAssessmentByIdService(
        apiKey,
        iraDetails.id
      );

      setIsLoading(false);
      const dataList: QuestionsData[] = Array.isArray(
        questionsResponse?.dataList
      )
        ? questionsResponse?.dataList[0]?.questions
        : [];
      const updatedQuestions = updateQuestions(dataList);

      const assessmentList: AssessmentRecord[] =
        getEditResponse.status && getEditResponse.data.data
          ? getEditResponse.data.data.assessments
          : [];
      setAssessments(assessmentList);
      if (assessmentList.length > 0) {
        setEditFlag(true);
      } else {
        setEditFlag(false);
      }
      if (Array.isArray(updatedQuestions)) {
        setInitialFormValue(updatedQuestions, assessmentList);
        setQuestionsList(updatedQuestions);
      } else {
        setQuestionsList([]);
        setAssessments([]);
      }
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    answerOptions?: OptionsData[]
  ) => {
    const { name, value } = e.target;
    const newErrors = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: name,
      fieldName: name,
      value,
    });
    setFormData((prev) => ({
      values: {
        ...prev.values,
        [name]: value,
      },
      errors: {
        ...prev.errors,
        [name]: newErrors,
      },
    }));
    if (!name.startsWith("note") && answerOptions) {
      const isNoteRequired =
        answerOptions.find((option) => option.option === value)?.noteReq ??
        false;
      if (!isNoteRequired) {
        setFormData((prev) => ({
          ...prev,
          values: {
            ...prev.values,
            [`note-${name}`]: "",
          },
        }));
      }
      setValidationRules((prev) => ({
        ...prev,
        [`note-${name}`]: {
          ...prev[`note-${name}`],
          required: isNoteRequired,
        },
      }));
    }
  };

  useEffect(() => {
    getQuestions();
  }, [apiKey]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formErrors = validateDataAsPerRules({
      formData: values,
      rules: validationRules,
    });

    setFormData((prevState) => ({
      ...prevState,
      errors: formErrors,
    }));

    if (Object.keys(formErrors).length === 0) {
      if (assessments.length > 0) {
        const assessmentsEntries = assessments.map((assessment) => {
          const refinedAnswer =
            assessmentOptions.find(
              (option) =>
                option.label === values?.[assessment.assessmentQuestionnaireId]
            )?.value ?? values?.[assessment.assessmentQuestionnaireId];
          return {
            ...assessment,
            answer: refinedAnswer,
            note:
              values?.[`note-${assessment.assessmentQuestionnaireId}`] || "",
          };
        });

        setIsLoading(true);
        const updateAssessmentRes = await updateAssessmentService(
          apiKey,
          iraDetails.id,
          {
            initialRiskAssessmentId: iraDetails.id,
            assessments: assessmentsEntries,
          }
        );
        if (updateAssessmentRes.status) {
          showToastMessage(
            updateAssessmentRes.message,
            TOAST_TYPE_OPTIONS.SUCCESS
          );
          getQuestions();
        } else {
          showToastMessage(
            updateAssessmentRes.message,
            TOAST_TYPE_OPTIONS.ERROR
          );
        }
      } else {
        const assessmentsEntries = Object.entries(values).reduce(
          (acc, [key, value]) => {
            const refinedAnswer =
              assessmentOptions.find((option) => option.label === value)
                ?.value ?? 0;

            if (!key.startsWith("note")) {
              acc.push({
                answer: refinedAnswer,
                assessmentQuestionnaireId: Number(key),
                note: values[`note-${key}`] || "",
              });
            }
            return acc;
          },
          [] as AssessmentRecord[]
        );
        setIsLoading(true);
        const createAssessment = await createAssessmentService(apiKey, {
          initialRiskAssessmentId: iraDetails.id,
          assessments: assessmentsEntries,
        });
        if (createAssessment.status) {
          showToastMessage(
            createAssessment.message,
            TOAST_TYPE_OPTIONS.SUCCESS
          );
          getQuestions();
        } else {
          showToastMessage(createAssessment.message, TOAST_TYPE_OPTIONS.ERROR);
        }
      }
    } else {
      showToastMessage(
        "Please fill all mandatory fields",
        TOAST_TYPE_OPTIONS.ERROR
      );
    }
    setIsLoading(false);
  };

  const renderNote = (question: QuestionsData) => {
    if (question.answerOptions) {
      const isNoteRequired = question.answerOptions.find(
        (option) => option.option === values?.[question.questionId]
      )?.noteReq;
      if (isNoteRequired) {
        return (
          <Textarea
            key={`note-${question.questionId}`}
            className="form-control form-control-sm"
            id={`note-${question.questionId}`}
            name={`note-${question.questionId}`}
            placeholder="Text"
            rows={2}
            label="Please Specify"
            value={values[`note-${question.questionId}`] || ""}
            error={errors[`note-${question.questionId}`]}
            onChange={handleChange}
            required
            disabled={isViewMode}
          />
        );
      }
      return null;
    }
    return null;
  };

  const renderQuestions = (questions: QuestionsData[]) => (
    <>
      {sortArray(questions, "sequence").map((question, index) => (
        <div key={question.questionId}>
          {question.fieldType === "radio" && (
            <RadioButton
              name={`${question.questionId}`}
              id={`question-${question.questionId}`}
              options={sortArray(question.answerOptions, "optionSeq")}
              label={`${index + 1}. ${question.question}`}
              value={values?.[question.questionId] || ""}
              error={errors?.[question.questionId]}
              onChange={(e) => handleChange(e, question.answerOptions)}
              required={
                validationRules?.[question.questionId]?.required || false
              }
              disabled={isViewMode}
            />
          )}
          {question.fieldType === "textarea" && (
            <Textarea
              id={`question-${question.questionId}`}
              label={`${index + 1}. ${question.question}`}
              name={`note-${question.questionId}`}
              value={values[`note-${question.questionId}`] || ""}
              error={errors[`note-${question.questionId}`]}
              onChange={(e) => handleChange(e)}
              required={
                validationRules?.[question.questionId]?.required || false
              }
              className="form-control form-control-sm"
              disabled={isViewMode}
            />
          )}
          {(question.fieldType === "number" ||
            question.fieldType === "text") && (
            <Input
              type={question.fieldType}
              label={`${index + 1}. ${question.question}`}
              id={`question-${question.questionId}`}
              name={`note-${question.questionId}`}
              value={values[`note-${question.questionId}`] || ""}
              error={errors[`note-${question.questionId}`]}
              onChange={handleChange}
              className="form-control form-control-sm"
              disabled={isViewMode}
            />
          )}
          <div className="mx-4 mb-2">{renderNote(question)}</div>
          <div className="mx-4">
            {question.subQuestions.length > 0 &&
              renderQuestions(question.subQuestions)}
          </div>
        </div>
      ))}
    </>
  );

  return (
    <>
      <FullScreenLoader show={isLoading} />
      <form onSubmit={handleSubmit}>
        {renderQuestions(questionsList)}
        <div className="col-12 d-flex justify-content-between">
          <div>
            {!editFlag && (
              <Button
                className="btn btn-danger btn-sm px-4"
                label="Clear"
                onClick={() => getQuestions()}
                hidden={isViewMode}
              />
            )}
          </div>
          <Button
            className="btn btn-primary btn-sm px-4"
            type="submit"
            label={editFlag ? "Update & continue" : "Save & Continue"}
            hidden={isViewMode}
          />
        </div>
      </form>
    </>
  );
}
