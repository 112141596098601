import { useEffect, useState } from "react";
import { getOptionsForDropdown } from "../../../../../../services/dropDownMenuOptions/dropDownMenuOptions";

export const usePriorityDropdownOptions = () => {
  const [priorityDropdownOptions, setPriorityDropdownOptions] = useState([]);

  const fetchData = async () => {
    const response = await getOptionsForDropdown("currentRiskLevel");
    setPriorityDropdownOptions(response);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { priorityDropdownOptions };
};
