import ApiCaller from "../../utils/apiCaller";
import { API_ENDPOINTS } from "../../constants/apiList";
import { STATUS_CODES } from "../../constants/variables";

export async function getSearchFilter(fieldName: string): Promise<any> {
  const getUsersUrl = `${API_ENDPOINTS.searchPcpList}/${fieldName}`;

  try {
    const response = await ApiCaller.get(getUsersUrl);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getSearchProviderSpecaltyFilter(
  fieldName: string,
): Promise<any> {
  const getUsersUrl = `${API_ENDPOINTS.searchSpecialtyProviderList}/${fieldName}`;

  try {
    const response = await ApiCaller.get(getUsersUrl);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

// getSearchSpecaltyProviderByIdAPI

export async function getSearchSpecaltyProviderByIdAPI(
  specialityProviderId: string | number,
): Promise<any> {
  const specialityProviderUrl = `${API_ENDPOINTS.searchSpecialtyProviderById}/${specialityProviderId}`;

  try {
    const response = await ApiCaller.get(specialityProviderUrl);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}
