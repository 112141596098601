import { useEffect, useState } from "react";
import { getOptionsForDropdown } from "../../../../../services/dropDownMenuOptions/dropDownMenuOptions";

export const useRelationshipDropdownOptions = () => {
  const [relationshipDropDownOptions, setRelationshipDropDownOptions] =
    useState([]);

  const fetchData = async () => {
    const relationshipListOptions = await getOptionsForDropdown("relationship");
    if (relationshipListOptions) {
      setRelationshipDropDownOptions(relationshipListOptions);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return { relationshipDropDownOptions };
};
