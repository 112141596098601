import {
  ServiceResponse,
  generateErrorResponse,
} from "./generateErrorResponse";

function isValidName(name: string): boolean {
  return /^[a-zA-Z]+$/.test(name);
}

export function validateName(name: string, fieldName: string): ServiceResponse {
  const label = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
  if (!name) {
    return generateErrorResponse(`${label} is required.`, 400);
  }

  if (name.length > 50) {
    return generateErrorResponse(`You have exceeded  the char limit.`, 400);
  }

  if (!isValidName(name)) {
    return generateErrorResponse(
      `${label} should only contain alphabets.`,
      400,
    );
  }
  return { status: true };
}
