import { ValidationRules } from "../../../../../services/utils/validateDataAsPerRules";

export const validationRules: ValidationRules = {
  encounterTypeId: {
    required: true,
    dataType: "number",
    characterLimit: 50,
    displayName: "Encounter Type",
  },
  noteTypeId: {
    required: true,
    dataType: "number",
    characterLimit: 50,
    displayName: "Note Type",
  },
  statusId: {
    required: false,
    dataType: "number",
    characterLimit: 50,
    displayName: "Status",
  },
  date: {
    required: true,
    dataType: "date",
    isPastDateAllow: true,
    isFutureDateAllow: true,
    characterLimit: 50,
    displayName: "Date",
  },
  ageAtEncounter: {
    required: false,
    dataType: "any",
    characterLimit: 50,
    displayName: "Age At Encounter",
  },
  organization: {
    required: false,
    dataType: "any",
    characterLimit: 50,
    displayName: "Organization",
  },
  seenBy: {
    required: false,
    dataType: "text",
    characterLimit: 50,
    displayName: "Seen By",
  },
  lastModifiedOn: {
    required: false,
    dataType: "date",
    displayName: "Last Modified On",
  },
  lastModifiedBy: {
    required: false,
    dataType: "text",
    displayName: "Last Modified By",
  },
  createdOn: {
    required: false,
    dataType: "date",
    displayName: "Created On",
  },
  createdBy: {
    required: false,
    dataType: "text",
    displayName: "Created By",
  },
};
