interface LogContext {
  page?: string;
  module?: string;
  section?: string;
  user?: string;
  additionalInfo?: Record<string, any>;
}

interface LogOptions {
  context?: LogContext;
  error?: any;
  data?: any;
}
const displayLogsFlag = true;
class Logger {
  private static formatMessage(
    level: string,
    message: string,
    context?: LogContext,
  ): string {
    let formattedMessage = `${level}: ${message}`;
    if (context) {
      formattedMessage += ` | Context: ${JSON.stringify(context)}`;
    }
    return formattedMessage;
  }

  static logError(message: string, options?: LogOptions): void {
    const formattedMessage = this.formatMessage(
      "ERROR",
      message,
      options?.context,
    );
    if (displayLogsFlag) {
      console.error(formattedMessage, options?.error);
    }
  }

  static logInfo(message: string, options?: LogOptions): void {
    const formattedMessage = this.formatMessage(
      "INFO",
      message,
      options?.context,
    );
    if (displayLogsFlag) {
      console.info(formattedMessage, options?.data);
    }
  }

  static logWarning(message: string, options?: LogOptions): void {
    const formattedMessage = this.formatMessage(
      "WARNING",
      message,
      options?.context,
    );
    if (displayLogsFlag) {
      console.warn(formattedMessage, options?.data);
    }
  }

  static logData(message: string, options?: LogOptions): void {
    const formattedMessage = this.formatMessage(
      "DATA",
      message,
      options?.context,
    );
    if (displayLogsFlag) {
      console.log(formattedMessage, options);
    }
  }

  static logCustom(level: string, message: string, options?: LogOptions): void {
    const formattedMessage = this.formatMessage(
      level.toUpperCase(),
      message,
      options?.context,
    );
    if (displayLogsFlag) {
      console.log(formattedMessage, options?.data);
    }
  }
}

export default Logger;
