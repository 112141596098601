import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "../../../components/atom/Table/Table";
import { Card } from "../../../components/atom/Cards/Cards";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import { CreateNewAppointment } from "../../../components/organism/CreateNewAppointment/CreateNewAppointment";
import {
  deleteAppointmentService,
  getAppointmentByIdService,
  getAllAppointments,
} from "../../../services/appointments/appointments";
import { ProfileCard } from "../../../components/molecule/ProfileCard/ProfileCard";
import Logger from "../../../utils/Logger";
import { Pagination } from "../../../components/atom/Pagination/Pagination";

export function Appointments() {
  interface CareTeamListType {
    visit: string;
    apptDate: string;
    apptTime: string;
    icd10: string;
    cptCode: string;
    status: string;
    reasonApptType: string;
    rescheduleDate: string;
    rescheduleTime: string;
  }

  const activePatient = useSelector((state: any) => state.activePatient);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [userListData, setUserListData] = useState<CareTeamListType[]>([]);
  const [editAppointmentData, setEditAppointmentData] = useState();
  const [paginationData, setPaginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });
  const headersForTable: Record<string, string> = {
    visit: "Visit",
    appointmentDate: "Appt Date",
    appointmentTime: "Appt Time",
    icd10Value: "ICD 10",
    cptCodeValue: "CPT code",
    statusValue: "Status",
    reasonValue: "Reason / Appt Type",
    appRescheduleDate: "Reschedule Date",
    appRescheduleTime: "Reschedule Time",
    action: "Action",
  };
  const sortingHeaderMap: Record<string, string> = {
    visit: "Visit",
    appointmentDate: "ApptDate",
    appointmentTime: "ApptTime",
    icd10Value: "ICD",
    cptCodeValue: "CPTCode",
    statusValue: "Status",
    reasonValue: "ApptType",
    appRescheduleDate: "RescheduleDate",
    appRescheduleTime: "RescheduleTime",
  };
  const cssForTableKeyServicesForTextValue = {
    statusValue: {
      Seen: "badge border-1 border-success p-2 text-bg-success text-white rounded-3 mx-auto ",
      Pending:
        "badge border-1 border-warning p-2 text-bg-warning text-white rounded-3 mx-auto ",
      "Cancel by Patient":
        "badge border-1 border-warning p-2  text-white bg-secondary rounded-3 mx-auto ",
      "No Show":
        "badge border-1 border-warning p-2  text-white bg-secondary rounded-3 mx-auto ",
      "Cancel by Provider":
        "badge border-1 border-warning p-2  text-white bg-secondary rounded-3 mx-auto ",
    },
  };
  const fetchData = async (newPaginationData?: any) => {
    try {
      const paginationDataAsParameter = newPaginationData || paginationData;
      const patientId = activePatient.patientDetails.id;

      if (patientId !== "") {
        Logger.logInfo("fetch all appointment for patient ", {
          data: { patientId },
        });
        const appAppointmentListResponse = await getAllAppointments({
          paginationDataAsParameter,
          patientId,
        });
        if (!appAppointmentListResponse.status) {
          Logger.logError("Error fetching all appointment", {
            data: { patientId },
          });
          showToastMessage(
            appAppointmentListResponse.message,
            TOAST_TYPE_OPTIONS.ERROR,
          );
        } else {
          Logger.logInfo("Success fetching all appointment", {
            data: { patientId },
          });

          setPaginationData({
            ...paginationData,
            pageNo: appAppointmentListResponse.data.pageNo,
            totalPages: appAppointmentListResponse.data.totalPages,
            totalElements: appAppointmentListResponse.data.totalElements,
            pageSize: appAppointmentListResponse.data.pageSize,
          });
          setUserListData(appAppointmentListResponse.data.dataList);
        }
      }
    } catch (error) {
      showToastMessage("Error fetching all users:", TOAST_TYPE_OPTIONS.ERROR);
      console.error("Error fetching all users:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!activePatient.patientDetails.id) {
      setUserListData([]);
    }
  }, [activePatient]);

  const deleteAppointment = async (rowData: any) => {
    Logger.logInfo("delete appointment", {
      data: { appointmentId: rowData.id },
    });
    const deleteAppointmentResponse = await deleteAppointmentService(
      rowData.id,
    );
    if (deleteAppointmentResponse.status) {
      Logger.logInfo("delete appointment", {
        data: { appointmentId: rowData.id },
      });
      showToastMessage(
        deleteAppointmentResponse.message,
        TOAST_TYPE_OPTIONS.SUCCESS,
      );
      fetchData();
    } else {
      Logger.logError("Error deleting appointment", {
        data: { appointmentId: rowData.id },
      });
      showToastMessage(
        deleteAppointmentResponse.message,
        TOAST_TYPE_OPTIONS.ERROR,
      );
    }
  };
  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize,
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    fetchData({ ...paginationData, pageNo, pageSize });
  };

  const editAction = async (rowData: any) => {
    const getResponseById = await getAppointmentByIdService(rowData.id);
    if (getResponseById.status === true) {
      setEditAppointmentData(getResponseById.data);
      setShowModal(true);
    }
  };
  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    fetchData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };
  return (
    <div>
      <ProfileCard />
      <Card>
        <div className="d-flex justify-content-between">
          <h3>Appointments</h3>
          <CreateNewAppointment
            setShow={setShowModal}
            show={showModal}
            fetchData={fetchData}
            editAppointmentData={editAppointmentData}
          />
        </div>

        <div className="table-responsive care-manager-table">
          <Table
            className="table table-bordered table-striped table-hover"
            data={userListData}
            headers={headersForTable}
            enableDeleteAction={deleteAppointment}
            enableEditAction={(rowData) => {
              editAction(rowData);
            }}
            sortingHeaderMap={sortingHeaderMap}
            sortingFunction={sortingFunction}
            cssForTableKeyServicesForText={cssForTableKeyServicesForTextValue}
          />
          {paginationData.totalPages >= 0 && (
            <Pagination
              currentPage={paginationData.pageNo}
              totalPages={paginationData.totalPages}
              pageSize={paginationData.pageSize}
              onPageChange={(page, pageSize) => {
                updatePaginationData(page, pageSize);
              }}
            />
          )}
        </div>
      </Card>
    </div>
  );
}
