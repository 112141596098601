import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Input } from "../../../atom/Input/Input";
import { Button } from "../../../atom/Buttons/Button";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../../services/utils/validateDataAsPerRules";
import { validationRules } from "../helperFunction/constants";
import { showToastMessage } from "../../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../../constants/variables";
import { getOptionsForDropdown } from "../../../../services/dropDownMenuOptions/dropDownMenuOptions";
import { InputDropdown } from "../../../atom/InputDropDown/InputDropDown";
import { IRARecord } from "../../../../dataLayer/formsAndIRA/ira";
import { getIdByLegalValue } from "../../../../services/utils/getIdByLegalValue";

interface BasicFormData {
  values: IRARecord;
  errors: {
    name: string;
    timeSpent: string;
  };
}
export interface BasicDetailsProps {
  formData: BasicFormData;
  setFormData: React.Dispatch<React.SetStateAction<BasicFormData>>;
  handleReset: () => void;
  handleSubmitBasicDetails: () => void;
  isViewMode: boolean;
}
export function BasicDetails({
  formData,
  setFormData,
  handleReset,
  handleSubmitBasicDetails,
  isViewMode,
}: BasicDetailsProps) {
  const [statusTypeOptions, setStatusTypeOptions] = useState<any[]>([]);
  const { values, errors } = formData;
  const editFlag = useMemo(() => values.id, [values.id]);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    const fieldError = validateFieldAsPerRules({
      rules: validationRules,
      fieldName: name,
      value,
    });

    setFormData((prevState) => ({
      values: {
        ...prevState.values,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: fieldError,
      },
    }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formErrors = validateDataAsPerRules({
      formData: formData.values,
      rules: validationRules,
    });
    const draftStatusId = getIdByLegalValue("In draft", statusTypeOptions);
    setFormData((prevState) => ({
      errors: {
        ...prevState.errors,
        ...formErrors,
      },
      values: {
        ...prevState.values,
        statusId: Number(draftStatusId) || 0,
      },
    }));
    values.statusId = Number(draftStatusId) || 0;
    if (Object.keys(formErrors).length > 0) {
      showToastMessage(
        "Please fill all mandatory fields",
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }
    handleSubmitBasicDetails();
  };
  const fetchData = async () => {
    const statusType = await getOptionsForDropdown("CarePlanStatus");
    setStatusTypeOptions(statusType);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (statusTypeOptions && !values?.id) {
      const notStartedStatusId = getIdByLegalValue(
        "Not started",
        statusTypeOptions
      );
      setFormData((prevState: any) => ({
        ...prevState,
        values: {
          ...prevState.values,
          statusId: notStartedStatusId,
        },
      }));
    }
  }, [values?.id, statusTypeOptions]);

  return (
    <div>
      <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-sm-6">
          <Input
            className="form-control form-control-sm"
            value={values.name}
            label={validationRules.name.displayName}
            required={validationRules.name.required}
            error={errors.name}
            onChange={handleChange}
            name="name"
            disabled={isViewMode}
          />
        </div>
        <div className="col-lg-6 col-sm-6">
          <InputDropdown
            className="form-control form-control-sm "
            disabled
            options={statusTypeOptions}
            onSelect={handleChange}
            name="createdBy"
            value={values.statusId}
            hideEmptyOption
            required={validationRules.statusId.required}
            label={validationRules.statusId.displayName}
          />
        </div>
        <div className="col-lg-6 col-sm-6">
          <Input
            className="form-control form-control-sm "
            disabled
            onChange={handleChange}
            value={values.createdBy}
            label={validationRules.createdBy.displayName}
            name="createdBy"
          />
        </div>
        <div className="col-lg-6 col-sm-6">
          <Input
            type="date"
            className="form-control form-control-sm "
            disabled
            onChange={handleChange}
            value={values.createdOn}
            label={validationRules.createdOn.displayName}
            name="createdOn"
          />
        </div>
        <div className="col-lg-6 col-sm-6">
          <Input
            className="form-control form-control-sm "
            disabled
            onChange={handleChange}
            value={values.lastModifiedBy}
            label={validationRules.lastModifiedBy.displayName}
            name="lastModifiedBy"
          />
        </div>
        <div className="col-lg-6 col-sm-6">
          <Input
            type="date"
            className="form-control form-control-sm "
            disabled
            onChange={handleChange}
            value={values.lastModifiedOn}
            label={validationRules.lastModifiedOn.displayName}
            name="lastModifiedOn"
          />
        </div>
        <div className="col-12 d-flex justify-content-between mt-5">
          <div>
            {!editFlag && (
              <Button
                className="btn btn-danger btn-sm"
                type="reset"
                onClick={handleReset}
                label="Clear"
                hidden={isViewMode}
              />
            )}
          </div>
          <Button
            className="btn btn-primary btn-sm px-4"
            type="submit"
            label={editFlag ? "Update & continue" : "Save & Continue"}
            hidden={isViewMode}
          />
        </div>
      </form>
    </div>
  );
}
