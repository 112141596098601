import React, { ReactNode, useState, Suspense } from "react";
import Accordion from "react-bootstrap/Accordion";

import "./Accordion.css";
import { AccordionEventKey } from "react-bootstrap/esm/AccordionContext";

interface AccordionItem {
  id: string;
  title: string;
  content: ReactNode;
  disabled?: boolean;
}

export interface AccordionAtomProps {
  items: AccordionItem[];
}

export function AccordionAtom({ items }: AccordionAtomProps) {
  const [activeKey, setActiveKey] = useState<string | null>(null);

  const handleSelect = (eventKey: AccordionEventKey) => {
    if (!eventKey) {
      setActiveKey("");
    }
    if (eventKey) {
      if (eventKey === activeKey) {
        setActiveKey("");
      } else {
        setActiveKey(eventKey?.toString());
      }
    }
  };

  return (
    <Accordion activeKey={activeKey} onSelect={handleSelect}>
      {items.map((item, index) => (
        <Accordion.Item
          eventKey={index.toString()}
          key={item.id}
          className="my-1"
        >
          <Accordion.Header className={item.disabled ? "disabled" : ""}>
            {item.title}
          </Accordion.Header>

          <Accordion.Body className="accordion-body">
            {activeKey === index.toString() && !item.disabled && (
              <Suspense fallback={<div>Loading...</div>}>
                {item.content}
              </Suspense>
            )}
            {item.disabled && (
              <div className="disabled">This section is disabled</div>
            )}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}
