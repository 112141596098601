import React from "react";
import { Login } from "../../pages/Login/Login";
import { ForgetPassword } from "../../pages/ForgetPassword/ForgetPassword";
import { UpdatePassword } from "../../pages/Dashboard/UpdatePassword/UpdatePassword";

export const loginRoutes = [
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "forget-password",
    element: <ForgetPassword />,
  },
  {
    path: "update-password",
    element: <UpdatePassword />,
  },
];
