import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ModalComponent } from "../../molecule/Modal/ModalComponent";
import { InputDropdown } from "../../atom/InputDropDown/InputDropDown";
import { Input } from "../../atom/Input/Input";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
  ValidationRules,
} from "../../../services/utils/validateDataAsPerRules";
import {
  createTimelineForPatient,
  updateTimelineForPatient,
} from "../../../services/timeline/timeline";
import { getOptionsForDropdown } from "../../../services/dropDownMenuOptions/dropDownMenuOptions";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import { formatDate } from "../../../services/utils/formatDate";

const defaultFormValues = {
  category: "",
  event: "",
  date: "",
  evidence: "",
};

const validationRules: ValidationRules = {
  category: {
    required: true,
    dataType: "text",
    displayName: "Category",
  },
  event: {
    required: true,
    dataType: "any",
    displayName: "Event",
    characterLimit: 1000,
  },
  date: {
    required: true,
    dataType: "date",
    isFutureDateAllow: false,
    displayName: "Date/Time",
  },
  evidence: {
    required: false,
    dataType: "any",
    displayName: "Evidence",
    characterLimit: 1000,
  },
};

interface CreateTimelineProps {
  fetchDataForTable: () => void;
  editData: any;
}

export function CreateTimeline({
  fetchDataForTable,
  editData,
}: CreateTimelineProps) {
  const activePatient = useSelector((state: any) => state.activePatient);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [timelineCategoryList, setTimelineCategoryList] = useState([]);
  const [formData, setFormData] = useState({
    values: defaultFormValues,
    errors: defaultFormValues,
  });

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;

    const fieldError = validateFieldAsPerRules({
      rules: validationRules,
      fieldName: name,
      value,
    });

    setFormData({
      ...formData,
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        [name]: fieldError,
      },
    });
  };

  const handleFormSubmit = async () => {
    const formErrors = validateDataAsPerRules({
      formData: formData.values,
      rules: validationRules,
    });

    if (Object.keys(formErrors).length) {
      setFormData({
        ...formData,
        errors: formErrors,
      });
      showToastMessage(
        "Fill in all mandate required fields",
        TOAST_TYPE_OPTIONS.ERROR,
      );
      return;
    }
    let formSubmitAPIResponse;
    if (editFlag) {
      const requestObject = {
        ...formData.values,
        eventDetails: formData.values.event,
        eventDate: formData.values.date,
        patientId: editData.patientId,
      };

      formSubmitAPIResponse = await updateTimelineForPatient({
        formData: requestObject,
        eventId: editData.id,
      });
    } else {
      formSubmitAPIResponse = await createTimelineForPatient({
        formData,
        patientId: activePatient.patientDetails.id,
      });
    }

    if (formSubmitAPIResponse.status) {
      showToastMessage(
        formSubmitAPIResponse.message,
        TOAST_TYPE_OPTIONS.SUCCESS,
      );
      setIsModelOpen(false);
      fetchDataForTable();
    } else {
      showToastMessage(formSubmitAPIResponse.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  const handleReset = () => {
    setFormData({
      values: defaultFormValues,
      errors: defaultFormValues,
    });
  };
  const fetchData = async () => {
    const categoryOptionResponse = await getOptionsForDropdown(
      "TimeLinesEventCategory",
    );
    setTimelineCategoryList(categoryOptionResponse);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (editData) {
      setFormData({
        values: {
          category: editData.category,
          event: editData.eventDetails,
          date: formatDate(editData.eventDate, "YYYY-MM-DD"),
          evidence: editData.evidence,
        },
        errors: defaultFormValues,
      });
      setIsModelOpen(true);
      setEditFlag(true);
    }
  }, [editData]);

  const addButtonAction = () => {
    setIsModelOpen(!isModelOpen);
    setEditFlag(false);
    handleReset();
  };

  return (
    <ModalComponent
      buttonLeft={editFlag ? null : "Clear"}
      buttonRight={editFlag ? "Update & Continue" : "Save & Continue"}
      title={editFlag ? "Update Event" : "Add Event"}
      buttonShow="Create Event"
      onSubmit={handleFormSubmit}
      closeAction={handleReset}
      showAddUserPopup={isModelOpen}
      setShowAddUserPopup={addButtonAction}
      showToggleAsAddButton
    >
      <form
        className="row g-3"
        onSubmit={handleFormSubmit}
        onReset={handleFormSubmit}
      >
        <div className="col-lg-12 col-sm-12">
          <InputDropdown
            options={timelineCategoryList}
            onSelect={handleChange}
            className="form-select form-select-sm"
            value={formData.values.category}
            error={formData.errors.category}
            label={validationRules.category.displayName}
            required={validationRules.category.required}
            name="category"
          />
        </div>

        <div className="col-lg-12 col-sm-12">
          <Input
            className="form-control form-control-sm"
            onChange={handleChange}
            name="event"
            placeholder="Text"
            value={formData.values.event}
            error={formData.errors.event}
            label={validationRules.event.displayName}
            required={validationRules.event.required}
          />
        </div>

        <div className="col-lg-12 col-sm-12">
          <Input
            type="date"
            className="form-control form-control-sm"
            onChange={handleChange}
            name="date"
            value={formData.values.date}
            error={formData.errors.date}
            required={validationRules.date.required}
            label={validationRules.date.displayName}
          />
        </div>

        <div className="col-lg-12 col-sm-12">
          <Input
            className="form-control form-control-sm"
            onChange={handleChange}
            name="evidence"
            value={formData.values.evidence}
            error={formData.errors.evidence}
            required={validationRules.evidence.required}
            label={validationRules.evidence.displayName}
          />
        </div>
      </form>
    </ModalComponent>
  );
}
