import React from "react";
import { NavDropdown as Dropdown } from "react-bootstrap";

interface NavDropdownOption {
  value: string;
  label: string;
}

export interface NavDropdownProps {
  options: NavDropdownOption[];
  onChange: (eventKey: string | null) => void;
  title: string;
}

export function NavDropdown({ options, onChange, title }: NavDropdownProps) {
  return (
    <Dropdown
      title={title || "Select an option"}
      id="navbarScrollingDropdown"
      onSelect={onChange}
    >
      {options.map((option) => (
        <Dropdown.Item key={option.value} eventKey={option.value}>
          {option.label}
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
}
