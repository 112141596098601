import {
  getSearchFilterReferralIcd10,
  getSearchFilterReferralProcCode,
  getSearchFilterReferralOrganisation,
} from "../../dataLayer/referrals/referralSearch";

export async function getSearchForReferralIcd10Values(
  FieldName: string,
): Promise<any> {
  try {
    if (FieldName) {
      const response = await getSearchFilterReferralIcd10(FieldName);
      if (response.isSuccess) {
        const options = response.dataList.map(
          (item: {
            longDescription: string;
            shortDescription: string;
            code: number;
            id: string;
          }) => ({
            label: `${item.code} ${item.shortDescription} `,
            value: item.id,
          }),
        );
        return options;
      }
    }

    return [];
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching PCP values.",
      details: error.message,
    };
  }
}
export async function getSearchForReferralProcCodeValues(
  fieldName: string,
): Promise<any> {
  try {
    if (fieldName) {
      const response = await getSearchFilterReferralProcCode(fieldName);
      if (response.isSuccess) {
        const options = response.dataList.map(
          (item: {
            longDescription: string;
            shortDescription: string;
            code: number;
            id: string;
          }) => ({
            label: `${item.code} ${item.shortDescription}`,
            value: item.id,
          }),
        );
        return options;
      }
    }

    return [];
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching PCP values.",
      details: error.message,
    };
  }
}
export async function getSearchForReferralOrganisationValues(
  FieldName: string,
): Promise<any> {
  try {
    const response = await getSearchFilterReferralOrganisation(FieldName);
    if (response.isSuccess) {
      const options = response.dataList.map(
        (item: {
          id: 12;
          organizationName: string;
          organizationType: string;
          address1: string;
          address2: string;
          country: string;
          state: string;
          city: string;
          zipCode: string;
          email: string;
        }) => ({
          label: `${item.organizationName} ${item.organizationType} ${item.country}${item.state}${item.zipCode}`,
          value: item.id,
        }),
      );
      return options;
    }
    return [];
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching PCP values.",
      details: error.message,
    };
  }
}
