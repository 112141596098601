import { ValidationRules } from "../../../../services/utils/validateDataAsPerRules";

export const defaultFormValues = {
  memoDate: "",
  memoDetails: "",
  patientId: "",
  patientName: "",
  id: 0,
};

export const validationRules: ValidationRules = {
  memoDate: {
    required: true,
    dataType: "date",
    characterLimit: 100,
    isPastDateAllow: false,
    displayName: "Date",
  },
  memoDetails: {
    required: true,
    dataType: "any",
    characterLimit: 250,
    displayName: "Memo",
  },
  patientId: {
    required: true,
    dataType: "any",
    characterLimit: 50,
    displayName: "Patient",
  },
};
