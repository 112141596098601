import { STATUS_CODES } from "../../constants/variables";
import { API_ENDPOINTS } from "../../constants/apiList";
import ApiCaller from "../../utils/apiCaller";

export async function deleteOrganization(
  organizationId: number | string,
): Promise<any> {
  const deleteUserUrl = `${API_ENDPOINTS.deleteOrganization}/${organizationId}`;

  try {
    const response: { isSuccess: boolean; responseMsg?: Array<string> } =
      await ApiCaller.delete(deleteUserUrl);
    if (response.isSuccess) {
      return {
        status: response.isSuccess,
        code: STATUS_CODES.OK,
        message: "Organization Deleted successfully",
        data: response,
      };
    }
    return {
      status: response.isSuccess,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: response.responseMsg?.[0],
      data: response,
    };
  } catch (error: any) {
    return {
      status: false,
      code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: error?.message || "API failed",
      details: error?.response?.data?.error || "API call failed",
    };
  }
}

export async function getSearchFilterForOrganization(
  fieldName: string,
): Promise<any> {
  if (fieldName) {
    const getUsersUrl = `${API_ENDPOINTS.searchOrganizationWithWord}/${fieldName}`;

    try {
      const response = await ApiCaller.get(getUsersUrl);
      return response;
    } catch (error: any) {
      return {
        status: false,
        code: error?.response?.status || STATUS_CODES.INTERNAL_SERVER_ERROR,
        message: error?.message || "API failed",
        details: error?.response?.data?.error || "API call failed",
      };
    }
  }
  return null;
}
