import { ValidationRules } from "../../../../services/utils/validateDataAsPerRules";

export const validationRules: ValidationRules = {
  categoryId: {
    required: true,
    dataType: "any",
    displayName: "Category",
  },
  sourceId: {
    required: false,
    dataType: "any",
    displayName: "Source",
  },
  dateOfPerformTest: {
    required: true,
    dataType: "date",
    characterLimit: 250,
    isPastDateAllow: true,
    isFutureDateAllow: false,
    displayName: "Date of perform test",
  },
  dateOfUploadDocument: {
    required: false,
    dataType: "date",
    characterLimit: 250,
    isPastDateAllow: true,
    isFutureDateAllow: true,
    displayName: "Date of upload document",
  },
  provider: {
    required: true,
    dataType: "text",
    characterLimit: 50,
    displayName: "Provider",
  },
  uploadDocument: {
    required: true,
    dataType: "any",
    displayName: "Upload Document",
  },
};

export const defaultFormValues = {
  categoryId: "",
  sourceId: "",
  dateOfPerformTest: "",
  uploadDocument: "",
  dateOfUploadDocument: "",
  provider: "",
  files: undefined,
};
