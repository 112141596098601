import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "../../../atom/Table/Table";
import { AddAllergies } from "./AddAllergies/AddAllergies";
import { Pagination } from "../../../atom/Pagination/Pagination";
import { getAllAllergiesOfPatent } from "../../../../services/carePlan/carePlan";

const tableHeaders = {
  allergyDate: "Date",
  details: "Details",
  action: "Action",
};
const sortingHeaderMap = {
  allergyDate: "allergyDate",
  details: "Details",
};
interface AllergiesProps {
  isViewMode?: boolean;
}

export function Allergies({ isViewMode }: AllergiesProps) {
  const carePlanDetails = useSelector((state: any) => state.activeCarePlan);
  const activePatient = useSelector((state: any) => state.activePatient);
  const [paginationData, setPaginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });
  const [tableData, setTableData] = useState([]);
  const [editAllergiesData, setEditAllergiesData] = useState();

  const fetchData = async (newPaginationData?: any) => {
    const paginationDataAsParameter = newPaginationData || paginationData;
    const patientId = activePatient?.patientDetails?.id;
    const allergiesResponse = await getAllAllergiesOfPatent({
      paginationDataAsParameter,
      patientId,
      carePlanId: carePlanDetails.carePlanDetails.carePlanId,
    });
    if (allergiesResponse.status) {
      setTableData(allergiesResponse.data.dataList);
      setPaginationData({
        ...paginationData,
        pageNo: allergiesResponse.data.pageNo,
        totalPages: allergiesResponse.data.totalPages,
        totalElements: allergiesResponse.data.totalElements,
        pageSize: allergiesResponse.data.pageSize,
      });
    }
  };

  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize,
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    fetchData({ ...paginationData, pageNo, pageSize });
  };

  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    fetchData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };

  const editAllergies = (rowData: any) => {
    setEditAllergiesData(rowData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      className="accordion-collapse"
      data-bs-parent="#accordionFlushExample-parent"
    >
      <div className="d-flex justify-content-end mb-2">
        {isViewMode ? (
          <div />
        ) : (
          <AddAllergies
            fetchData={fetchData}
            editData={editAllergiesData}
            setEditData={setEditAllergiesData}
          />
        )}
      </div>

      <div className="table-responsive care-manager-table">
        <Table
          headers={tableHeaders}
          data={tableData}
          className="table table-bordered table-striped table-hover"
          sortingHeaderMap={sortingHeaderMap}
          sortingFunction={sortingFunction}
          enableEditAction={editAllergies}
          isEditDisabled={() => isViewMode ?? false}
        />
        {paginationData.totalPages >= 0 && (
          <Pagination
            currentPage={paginationData.pageNo}
            totalPages={paginationData.totalPages}
            pageSize={paginationData.pageSize}
            onPageChange={(page, pageSize) => {
              updatePaginationData(page, pageSize);
            }}
          />
        )}
      </div>
    </div>
  );
}
