import React from "react";
import "./FileField.css";

export interface FileFieldProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  label?: string;
  name?: string;
  filePath?: string;
  accept?: string;
  disabled?: boolean;
  ariaLabel?: string;
  ref?: React.RefObject<HTMLInputElement>;
  fullWidth?: boolean;
  required?: boolean;
  error?: string;
}

export function FileField({
  onChange,
  id,
  label,
  filePath,
  name,
  disabled = false,
  accept = "image/*.pdf",
  ariaLabel,
  ref,
  fullWidth,
  required,
  error,
}: FileFieldProps) {
  return (
    <div title="file-field" className={`${fullWidth ? "w-100" : ""}`}>
      {label && (
        <label htmlFor={id}>
          {label} {required && <span className="text-danger"> *</span>}
        </label>
      )}
      <div className="file-upload bg-white rounded">
        <div className="d-flex flex-column justify-content-center align-items-center file-name-container">
          <span className="mdi mdi-cloud-upload-outline fs-1 text-primary" />
          <a href="/" className="fon">
            Browse
          </a>
          <a href="/" className="fon col-11 text-truncate text-center">
            {filePath}
          </a>
          <span>Supported Formats: JPEG,PNG,PDF</span>
        </div>
        <input
          ref={ref}
          type="file"
          name={name}
          accept={accept}
          onChange={onChange}
          disabled={disabled}
          aria-label={ariaLabel}
        />
      </div>
      {error && <p className="text-danger error-message">{error}</p>}
    </div>
  );
}
