import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Pagination } from "../../../components/atom/Pagination/Pagination";
import { RowData, Table } from "../../../components/atom/Table/Table";
import { Card } from "../../../components/atom/Cards/Cards";
import { InputDropdown } from "../../../components/atom/InputDropDown/InputDropDown";
import { CreateTimeline } from "../../../components/organism/CreateTimeline/CreateTimeline";
import {
  getEventDataForTimeline,
  getEventDataForTimelineForGraph,
  getTimelineForPatient,
} from "../../../services/timeline/timeline";
import { getOptionsForDropdown } from "../../../services/dropDownMenuOptions/dropDownMenuOptions";
import { DisplayLineChart } from "../../../components/organism/DisplayLineChart/DisplayLineChart";
import { ProfileCard } from "../../../components/molecule/ProfileCard/ProfileCard";
import { FullScreenLoader } from "../../../components/atom/FullScreenLoader/FullScreenLoader";

const tableHeaders = {
  eventDate: "Date",
  eventDetails: "Event",
  categoryValue: "Category",
  evidence: "Evidence",
  action: "Actions",
};
const sortingHeaderMap: Record<string, string> = {
  eventDate: "EventDate",
  eventDetails: "Event",
  categoryValue: "Category",
  evidence: "Evidence",
};

export function Timeline() {
  const activePatient = useSelector((state: any) => state.activePatient);
  const [showFullScreenLoaderFlag, setShowFullScreenLoaderFlag] =
    useState(false);
  const [tableData, setTableData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [editData, setEditData] = useState();
  const [filterCategoryId, setFilterCategoryId] = useState("");
  const [timelineCategoryList, setTimelineCategoryList] = useState([]);
  const [paginationData, setPaginationData] = useState({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });

  const handleEdit = async (rowData: RowData) => {
    // todo: edit api call goes here, remove the console log
    const getEventDataResponse = await getEventDataForTimeline({
      eventId: rowData.id,
    });
    if (getEventDataResponse.status) {
      setEditData(getEventDataResponse.data);
    }
  };

  const fetchDropDownList = async () => {
    const categoryOptionResponse = await getOptionsForDropdown(
      "TimeLinesEventCategory",
    );

    categoryOptionResponse.unshift({ label: "All Category", value: "-1" });
    setTimelineCategoryList(categoryOptionResponse);
  };

  const fetchData = async (
    newPaginationData?: any,
    filterCategoryIdAsParameter?: string,
  ) => {
    const paginationDataAsParameter = newPaginationData || paginationData;
    const patientId = activePatient.patientDetails.id;

    if (!patientId) {
      return;
    }
    const filterCategoryIdForTimeline =
      filterCategoryIdAsParameter !== undefined
        ? filterCategoryIdAsParameter
        : filterCategoryId;

    setShowFullScreenLoaderFlag(true);
    const tableDataResponse = await getTimelineForPatient({
      paginationDataAsParameter,
      patientId,
      filterCategoryId: filterCategoryIdForTimeline,
    });
    setShowFullScreenLoaderFlag(false);
    if (tableDataResponse.status) {
      setTableData(tableDataResponse.data.dataList);
      setPaginationData({
        ...paginationData,
        pageNo: tableDataResponse.data.pageNo,
        totalPages: tableDataResponse.data.totalPages,
        totalElements: tableDataResponse.data.totalElements,
        pageSize: tableDataResponse.data.pageSize,
      });
    }
  };

  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize,
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    fetchData({ ...paginationData, pageNo, pageSize });
  };

  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    fetchData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };

  const fetchGraphData = async (filterId?: string) => {
    const patientId = activePatient.patientDetails.id;
    if (!patientId) {
      return;
    }
    const categoryIdForFilter = filterId || filterCategoryId;
    const getGraphDataAPIResponse = await getEventDataForTimelineForGraph({
      patientId,
      categoryIdForFilter,
    });
    if (getGraphDataAPIResponse.status) {
      setGraphData(getGraphDataAPIResponse.data);
    }
  };
  const handleFilter = async (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setFilterCategoryId(value);
    fetchData({ ...paginationData }, value);
    fetchGraphData(value);
  };

  useEffect(() => {
    fetchGraphData();
    fetchData();
    fetchDropDownList();
  }, []);

  useEffect(() => {
    if (!activePatient.patientDetails.id) {
      setTableData([]);
      setGraphData([]);
      setTimelineCategoryList([]);
    }
  }, [activePatient]);

  return (
    <Card>
      <FullScreenLoader show={showFullScreenLoaderFlag} />
      <div className="d-flex justify-content-between">
        <h5>Timelines</h5>
      </div>
      <ProfileCard />
      <div className="col-12 mb-3">
        <DisplayLineChart chartData={graphData} />
      </div>

      <div className="table-responsive care-manager-table">
        <div className="my-4 d-flex justify-content-end gap-2 align-items-center">
          <InputDropdown
            options={timelineCategoryList}
            onSelect={handleFilter}
            className="px-3 w-auto py-1"
            hideEmptyOption
            name="category"
            value={filterCategoryId}
          />

          <CreateTimeline
            fetchDataForTable={() => {
              fetchData();
              fetchGraphData();
            }}
            editData={editData}
          />
        </div>

        <Table
          className="table table-bordered table-striped table-hover"
          data={tableData}
          headers={tableHeaders}
          enableEditAction={handleEdit}
          sortingHeaderMap={sortingHeaderMap}
          sortingFunction={sortingFunction}
        />

        {paginationData.totalPages >= 0 && (
          <Pagination
            currentPage={paginationData.pageNo}
            totalPages={paginationData.totalPages}
            pageSize={paginationData.pageSize}
            onPageChange={(page, pageSize) => {
              updatePaginationData(page, pageSize);
            }}
          />
        )}
      </div>
    </Card>
  );
}
