import React, { FormEvent } from "react";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../../services/utils/validateDataAsPerRules";
import { IRARecord } from "../../../../dataLayer/formsAndIRA/ira";
import { timeSpentValidationRules } from "../helperFunction/constants";
import { showToastMessage } from "../../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../../constants/variables";
import { TimeInput } from "../../../atom/TImeInput/TimeInput";
import { Button } from "../../../atom/Buttons/Button";

interface BasicFormData {
  values: IRARecord;
  errors: {
    name: string;
    timeSpent: string;
  };
}

export interface TimeSpentProps {
  formData: BasicFormData;
  setFormData: React.Dispatch<React.SetStateAction<BasicFormData>>;
  handleSubmitBasicDetails: () => void;
  isViewMode: boolean;
  editFlag: boolean;
}

export function TimeSpent({
  formData,
  setFormData,
  handleSubmitBasicDetails,
  isViewMode,
  editFlag,
}: TimeSpentProps) {
  const { values, errors } = formData;
  const handleChange = (name: string, value: string) => {
    const fieldError = validateFieldAsPerRules({
      rules: timeSpentValidationRules,
      fieldName: name,
      value,
    });

    setFormData((prevState) => ({
      values: {
        ...prevState.values,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: fieldError,
      },
    }));
  };

  const handleClear = () => {
    setFormData((prevState: any) => ({
      ...prevState,
      values: {
        ...prevState.values,
        timeSpent: "",
      },
    }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formErrors = validateDataAsPerRules({
      formData: { ...values, timeSpent: values.timeSpent || "" },
      rules: timeSpentValidationRules,
    });

    setFormData((prevState: any) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        ...formErrors,
      },
    }));
    if (Object.keys(formErrors).length > 0) {
      const errorString = Object.values(formErrors);
      showToastMessage(errorString.join("\n"), TOAST_TYPE_OPTIONS.ERROR);
      return;
    }
    handleSubmitBasicDetails();
  };

  return (
    <div>
      <form className="col-5" onSubmit={handleSubmit}>
        <div>
          <TimeInput
            format="24-hour"
            id="timeSpent"
            name="timeSpent"
            value={values.timeSpent}
            error={errors.timeSpent}
            onChange={(time) => handleChange("timeSpent", time)}
            label={timeSpentValidationRules.timeSpent.displayName}
            required={timeSpentValidationRules.timeSpent.required}
            disabled={isViewMode}
          />
        </div>
        <div className="d-flex justify-content-between mt-2">
          <div>
            <Button
              className="btn btn-danger btn-sm"
              type="reset"
              label="Clear"
              onClick={() => handleClear()}
              hidden={isViewMode || editFlag}
            />
          </div>
          <Button
            className="btn btn-primary btn-sm px-4"
            type="submit"
            label={editFlag ? "Update & continue" : "Save & Continue"}
            hidden={isViewMode}
          />
        </div>
      </form>
    </div>
  );
}
