import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Navbar from "react-bootstrap/Navbar";

import { Logo } from "../../atom/Logo/Logo";
import { Icon } from "../../atom/Icons/Icon";
import { clearUserDetails } from "../../../redux/userDetails/userDetailsSlice";
import { PatientQuickAddModal } from "../../organism/PatientQuickAdd/PatientQuickAdd";
import {
  MANAGE_PATIENT_QUICK_ADD,
  TOAST_TYPE_OPTIONS,
} from "../../../constants/variables";
import { NavDropdown } from "../../atom/NavDropDown/NavDropDown";
import { SearchDropdown } from "../../atom/SearchDropdown/SearchDropdown";
import {
  getPatientDetailsFromId,
  getSearchPatientName,
} from "../../../services/patients/patients";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import {
  resetContactDetails,
  resetProviderDetails,
  resetRaceAndEthnicityDetails,
  setActivePatient,
  setContactDetails,
  setProviderDetails,
  setRaceAndEthnicityDetails,
} from "../../../redux/activePatient/activePatientSlice";

interface NavBarProps {
  userDetails: any;
}

export function Navbar1({ userDetails }: NavBarProps) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchResultList, setSearchResultList] = useState([]);
  const onClose = () => {
    setShowModal(!showModal);
  };
  const options = [
    { value: "changePassword", label: "Reset Password" },
    { value: "signOut", label: "Sign Out" },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDropdownChange = (selectedValue: string | null) => {
    if (selectedValue === "signOut") {
      dispatch(clearUserDetails());
      localStorage.clear();
      navigate("/login");
    } else {
      navigate("/dashboard/reset-user-password");
    }
  };
  const hasPatientAddPermission = useMemo(() => {
    const permissions = userDetails?.userPermission?.privilegesSet || [];
    return permissions
      .flatMap(({ privilegeName }: { privilegeName: string }) => privilegeName)
      .includes(MANAGE_PATIENT_QUICK_ADD);
  }, [userDetails?.userPermission?.privilegesSet]);

  const searchPatient = async (value: string) => {
    if (value) {
      const referredIcd10Values = await getSearchPatientName(value);
      setSearchResultList(referredIcd10Values);
    } else {
      setSearchResultList([]);
    }
  };

  const retrievePatientData = async (patientDetails: any) => {
    if (patientDetails.value) {
      const patientInfo = await getPatientDetailsFromId(patientDetails.value);
      if (patientInfo && !patientInfo.id) {
        showToastMessage(patientInfo.message, TOAST_TYPE_OPTIONS.ERROR);
        return false;
      }

      const {
        contactInformation,
        providerInfo,
        raceEthnicityDTO,
        ...userDetailsInfo
      } = patientInfo;
      dispatch(setActivePatient(userDetailsInfo));
      if (contactInformation) {
        dispatch(setContactDetails(contactInformation));
      } else {
        dispatch(resetContactDetails());
      }
      if (providerInfo) {
        dispatch(setProviderDetails(providerInfo));
      } else {
        dispatch(resetProviderDetails(providerInfo));
      }
      if (raceEthnicityDTO) {
        dispatch(setRaceAndEthnicityDetails(raceEthnicityDTO));
      } else {
        dispatch(resetRaceAndEthnicityDetails(raceEthnicityDTO));
      }
      return true;
    }
    return true;
  };

  return (
    <Navbar bg="primary" className="bg-primary py-0">
      <Container fluid className="px-0">
        <Navbar.Brand href="#home" className="text-center bg-white">
          <Logo
            src="/assets/images/MDTel-logo.png"
            alt="Image Alt Text"
            className="img-fluid h-100"
          />
        </Navbar.Brand>
        <Icon className="fa fa-bars me-2 fs-5" />
        <Navbar.Toggle />
        <Form className="d-flex">
          <div>
            <SearchDropdown
              onSelect={(option) => {
                retrievePatientData(option);
                // Todo : on selection of patient, action
              }}
              searchCallback={searchPatient}
              searchField
              placeholder="Search Patient"
              options={searchResultList}
            />
          </div>
          {hasPatientAddPermission && (
            <div className="mx-3">
              <PatientQuickAddModal showModal={showModal} onClose={onClose} />
            </div>
          )}
        </Form>

        <Navbar.Collapse className="justify-content-end">
          <NavDropdown
            options={options}
            onChange={handleDropdownChange}
            title={userDetails.username}
          />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
