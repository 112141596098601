import { STATUS_CODES } from "../../constants/variables";
import {
  createAssessment,
  createIRA,
  deleteIRA,
  getAllIRA,
  getIRAById,
  getIRAQuestions,
  updateIRA,
  CreateIRARecord,
  updateAssessment,
  getAssessmentById,
  CreateAssessmentRecord,
  finalizeIRA,
  validateAssessment,
} from "../../dataLayer/formsAndIRA/ira";

export async function getAllIRAServices({
  paginationDataAsParameter,
  patientId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
}): Promise<any> {
  try {
    const allIRAs = await getAllIRA({
      paginationDataAsParameter,
      patientId,
    });
    if (allIRAs.status) {
      return allIRAs.data;
    }
    return allIRAs;
  } catch (error: any) {
    return {
      status: false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: "Error fetching all IRAs.",
      details: error.message,
    };
  }
}

export async function createIRAService(
  formData: CreateIRARecord,
): Promise<any> {
  try {
    const newUser = await createIRA(formData);
    return newUser;
  } catch (error: any) {
    return {
      status: false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: "Error creating IRA.",
      details: error.message,
    };
  }
}

export async function updateIRAService(
  formData: any,
  iraId: number,
): Promise<any> {
  try {
    const newUser = await updateIRA(formData, iraId);
    return newUser;
  } catch (error: any) {
    return {
      status: false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: "Error updating IRA.",
      details: error.message,
    };
  }
}
export async function finalizeIRAService(iraId: number): Promise<any> {
  try {
    const newUser = await finalizeIRA(iraId);
    return newUser;
  } catch (error: any) {
    return {
      status: false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: "Error updating IRA.",
      details: error.message,
    };
  }
}

export async function deleteIRAService(iraId: number): Promise<any> {
  if (!iraId) {
    return {
      status: false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: "IRA ID is required.",
      details: "IRA ID is required.",
    };
  }

  try {
    const response = await deleteIRA(iraId);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: "Error deleting IRA value.",
      details: error.message,
    };
  }
}

export async function getIRAByIdService(iraId: number): Promise<any> {
  if (!iraId) {
    return {
      status: false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: "IRA ID is required.",
      details: "IRA ID is required.",
    };
  }

  try {
    const response = await getIRAById(iraId);
    if (!response || !response.data) {
      return {
        status: false,
        code: STATUS_CODES.INTERNAL_SERVER_ERROR,
        message: "IRA data not found.",
        details: "IRA data not found.",
      };
    }

    return response;
  } catch (error: any) {
    return {
      status: false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: "Error fetching form value.",
      details: error.message,
    };
  }
}

export async function getIRAQuestionsService(apiKey: string): Promise<any> {
  try {
    const questionsResponse = await getIRAQuestions(apiKey);
    if (questionsResponse.status) {
      return questionsResponse.data;
    }
    return questionsResponse;
  } catch (error: any) {
    return {
      status: false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: "Error fetching all IRAs.",
      details: error.message,
    };
  }
}

export async function createAssessmentService(
  type: string,
  formData: CreateAssessmentRecord,
): Promise<any> {
  try {
    const newUser = await createAssessment(type, formData);
    return newUser;
  } catch (error: any) {
    return {
      status: false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: "Error creating Assessment.",
      details: error.message,
    };
  }
}

export async function updateAssessmentService(
  type: string,
  iraId: number,
  formData: CreateAssessmentRecord,
): Promise<any> {
  try {
    const newUser = await updateAssessment(type, iraId, formData);
    return newUser;
  } catch (error: any) {
    return {
      status: false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: "Error updating IRA.",
      details: error.message,
    };
  }
}

export async function getAssessmentByIdService(
  type: string,
  iraId: number,
): Promise<any> {
  if (!iraId) {
    return {
      status: false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: "IRA ID is required.",
      details: "IRA ID is required.",
    };
  }

  try {
    const response = await getAssessmentById(type, iraId);
    if (!response || !response.data) {
      return {
        status: false,
        code: STATUS_CODES.INTERNAL_SERVER_ERROR,
        message: "IRA data not found.",
        details: "IRA data not found.",
      };
    }

    return response;
  } catch (error: any) {
    return {
      status: false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: "Error fetching form value.",
      details: error.message,
    };
  }
}

export async function validateAssessmentService(iraId: number): Promise<any> {
  if (!iraId) {
    return {
      status: false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: "IRA ID is required.",
      details: "IRA ID is required.",
    };
  }

  try {
    const response = await validateAssessment(iraId);
    if (!response || !response.data) {
      return {
        status: false,
        code: STATUS_CODES.INTERNAL_SERVER_ERROR,
        message: "IRA data not found.",
        details: "IRA data not found.",
      };
    }

    return response;
  } catch (error: any) {
    return {
      status: false,
      code: STATUS_CODES.INTERNAL_SERVER_ERROR,
      message: "Error fetching form value.",
      details: error.message,
    };
  }
}
