import {
  getAllPcp,
  createNewOrg,
  getAllOrganization,
  updateOrganizationAPI,
} from "../../dataLayer/masterData/masterData";

import { generateErrorResponse } from "../validation/generateErrorResponse";
import { deleteOrganization } from "../../dataLayer/organization/organization";
import Logger from "../../utils/Logger";

export async function getAllPcpServices({
  paginationDataAsParameter,
}: {
  paginationDataAsParameter: {
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  };
}): Promise<any> {
  try {
    const allReferrals = await getAllPcp({ paginationDataAsParameter });
    if (allReferrals.status) {
      return allReferrals.data;
    }
    return allReferrals;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all Referrals In.",
      details: error.message,
    };
  }
}

export interface CreateOrganizationRecord {
  organizationName: string;
  organizationType: string;
  address1: string;
  address2?: string;
  zipCode: string;
  country: string;
  state: string;
  city: string;
  contactNumber: string;
  email: string;
  organizationId: string;
  npi: string;
  otherNo: string;
  fax: string;
}

function findMissingOrganisationalFields(
  params: CreateOrganizationRecord,
): string[] {
  const requiredFields: (keyof CreateOrganizationRecord)[] = [
    "address1",
    "zipCode",
    "country",
    "state",
    "city",
  ];
  return requiredFields.filter((field) => !params[field]);
}
export async function createOrganization(
  params: CreateOrganizationRecord,
): Promise<any> {
  const {
    organizationName,
    organizationType,
    address1,
    address2,
    country,
    state,
    city,
    zipCode,
    contactNumber,
    email,
    organizationId,
    npi,
    otherNo,
    fax,
  } = params;

  const missingFields: string[] = findMissingOrganisationalFields(params);
  if (missingFields.length > 0) {
    return generateErrorResponse(
      `${missingFields.join(", ")} are required.`,
      500,
      "Required fields are missing.",
    );
  }
  try {
    const requestObjectOrg = {
      organizationName,
      organizationType,
      address1,
      address2,
      country,
      state,
      city,
      zipCode,
      contactNumber,
      email,
      organizationId,
      npi,
      otherNo,
      fax,
    };
    const newUser = await createNewOrg(requestObjectOrg);
    return newUser;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating user.",
      details: error.message,
    };
  }
}

export async function updateOrganization(
  params: CreateOrganizationRecord,
  id: string,
): Promise<any> {
  const {
    organizationName,
    organizationType,
    address1,
    address2,
    country,
    state,
    city,
    zipCode,
    contactNumber,
    email,
    organizationId,
    npi,
    otherNo,
    fax,
  } = params;

  try {
    const requestObjectOrg = {
      organizationName,
      organizationType,
      address1,
      address2,
      country,
      state,
      city,
      zipCode,
      contactNumber,
      email,
      organizationId,
      npi,
      otherNo,
      fax,
    };
    Logger.logInfo("updating Organization Data", { data: requestObjectOrg });
    const newUser = await updateOrganizationAPI(requestObjectOrg, id);
    return newUser;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error updating Organization.",
      details: error.message,
    };
  }
}
export async function getAllOrganizationServices({
  paginationDataAsParameter,
}: {
  paginationDataAsParameter: any;
}): Promise<any> {
  try {
    const allOrganizationResponse = await getAllOrganization({
      paginationDataAsParameter,
    });

    return allOrganizationResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all Organization Data.",
      details: error.message,
    };
  }
}

export async function deleteOrganizationService(
  organizationId: string | number,
): Promise<any> {
  if (!organizationId) {
    return {
      status: false,
      code: 500,
      message: "Organization ID is required.",
      details: "Organization ID is required.",
    };
  }

  try {
    Logger.logInfo("Deleting Organization Data", { data: organizationId });
    const response = await deleteOrganization(organizationId);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting Organization.",
      details: error.message,
    };
  }
}
