export function getIdByLegalValue(legalValue: string, data: any): string {
  if (!legalValue) {
    return "";
  }
  if (!data) {
    return "";
  }
  const normalizedInput = legalValue.toLowerCase().replace(/\s+/g, "");

  const matchingItem = data.find(
    (item: any) =>
      item.label.toLowerCase().replace(/\s+/g, "") === normalizedInput,
  );

  return matchingItem ? matchingItem.value.toString() : null;
}
