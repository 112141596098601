import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Input } from "../../../atom/Input/Input";
import { Button } from "../../../atom/Buttons/Button";
import {
  getPatientICD10Values,
  createAndUpdateICDTeamNoteDetails,
} from "../../../../services/carePlan/carePlan";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
  ValidationRules,
} from "../../../../services/utils/validateDataAsPerRules";
import { Textarea } from "../../../atom/Textarea/Textarea";
import { showToastMessage } from "../../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../../constants/variables";

const validationRules: ValidationRules = {
  notes: {
    required: true,
    dataType: "text",
    displayName: "Notes",
  },
  timeSpent: {
    required: true,
    dataType: "number",
    displayName: "Time Spent",
  },
};
interface TeamNotesProps {
  isViewMode?: boolean;
}

export function TeamNotes({ isViewMode }: TeamNotesProps) {
  const activeCarePlan = useSelector((state: any) => state.activeCarePlan);
  const carePlanDetails = useSelector((state: any) => state.activeCarePlan);
  const activePatient = useSelector((state: any) => state.activePatient);
  const [editFlag, setEditFlag] = useState(false);
  const [formData, setFormData] = useState({
    values: {
      notes: "",
      timeSpent: "",
    },
    errors: {
      notes: "",
      timeSpent: "",
    },
  });

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;

    const fieldError = validateFieldAsPerRules({
      rules: validationRules,
      fieldName: name,
      value,
    });

    setFormData({
      values: { ...formData.values, [name]: value },
      errors: { ...formData.errors, [name]: fieldError },
    });
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formErrors = validateDataAsPerRules({
      formData: formData.values,
      rules: validationRules,
    });

    if (Object.keys(formErrors).length) {
      setFormData({ ...formData, errors: formErrors });
      return;
    }

    const createAndUpdateResponse = await createAndUpdateICDTeamNoteDetails({
      formData: formData.values,
      carePlanId: activeCarePlan.carePlanDetails.carePlanId,
      patientId: activePatient.patientDetails.id,
      type: editFlag ? "EDIT" : "CREATE",
    });
    if (createAndUpdateResponse.status) {
      showToastMessage(
        createAndUpdateResponse.message,
        TOAST_TYPE_OPTIONS.SUCCESS,
      );
    }
    setEditFlag(true);
  };

  const handleReset = () => {
    setFormData({
      values: {
        notes: "",
        timeSpent: "",
      },
      errors: {
        notes: "",
        timeSpent: "",
      },
    });
  };

  const fetchData = async () => {
    const patientICD10APIResponse = await getPatientICD10Values({
      carePlanId: carePlanDetails.carePlanDetails.carePlanId,
      patientId: activePatient.patientDetails.id,
    });

    if (patientICD10APIResponse.status) {
      setFormData({
        ...formData,
        values: {
          notes: patientICD10APIResponse.data.note,
          timeSpent: patientICD10APIResponse.data.timeSpent,
        },
      });
    } else {
      showToastMessage(
        patientICD10APIResponse.message,
        TOAST_TYPE_OPTIONS.ERROR,
      );
    }
    if (
      patientICD10APIResponse.status &&
      patientICD10APIResponse.data.note &&
      patientICD10APIResponse.data.note !== null
    ) {
      setEditFlag(true);
    } else {
      setEditFlag(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="accordion-collapse">
      <div className="accordion">
        <form className="row g-3" onSubmit={handleSubmit}>
          <div className="col-lg-6 col-sm-6">
            <Textarea
              className="form-control form-control-sm "
              placeholder="text"
              rows={3}
              value={formData.values.notes}
              label={validationRules.notes.displayName}
              error={formData.errors.notes}
              onChange={handleChange}
              name="notes"
              required={validationRules.notes.required}
              disabled={isViewMode}
            />
          </div>
          <div className="col-lg-6 col-sm-6">
            <Input
              type="time"
              className="form-control form-control-sm"
              onChange={handleChange}
              value={formData.values.timeSpent}
              label={validationRules.timeSpent.displayName}
              error={formData.errors.timeSpent}
              name="timeSpent"
              placeholder="Time Spent"
              required={validationRules.timeSpent.required}
              disabled={isViewMode}
            />
          </div>
          <div className="col-12 d-flex justify-content-between mt-5">
            {editFlag || isViewMode ? (
              <div />
            ) : (
              <Button
                className="btn btn-danger btn-sm"
                onClick={handleReset}
                type="reset"
              >
                Clear
              </Button>
            )}
            {isViewMode ? (
              <div />
            ) : (
              <Button
                type="submit"
                variant="outlined"
                className="btn btn-primary btn-sm px-4"
                label={editFlag ? "Update & Continue" : "Save & Continue"}
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
