import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AccordionAtom } from "../../atom/Accordion/Accordion";
import { MemberDetail } from "./MemberDetail/MemberDetail";
import { CareTeam } from "./CareTeam/CareTeam";
import { CaseManager } from "./CaseManager/CaseManager";
import { CareGiverDetails } from "./CareGiverDetails/CareGiverDetails";
import { Allergies } from "./Allergies/Allergies";
import { Medication } from "./Medication/Medication";
import { DiagnosisHistory } from "./DiagnosisHistory/DiagnosisHistory";
import { RecentTests } from "./RecentTests/RecentTests";
import { TeamNotes } from "./TeamNotes/TeamNotes";
import { ProblemsAndGoals } from "./ProblemsAndGoals/ProblemsAndGoals";
import { AddGoal } from "./ProblemsAndGoals/AddGoal/AddGoal";
import { Button } from "../../atom/Buttons/Button";
import { updateCarePlanForFinalize } from "../../../services/carePlan/carePlan";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import { IRA } from "../../../pages/Dashboard/FormsAndIRA/IRA";

export interface CreateNewCarePlanProps {
  toggleAddCarePlan: (state: boolean) => void;
  isViewMode?: boolean;
}

export function CreateNewCarePlan({
  toggleAddCarePlan,
  isViewMode,
}: CreateNewCarePlanProps) {
  const carePlanDetails = useSelector((state: any) => state.activeCarePlan);
  const [showAddGoalScreen, setShowAddGoalScreen] = useState(false);
  const [carePlanViewFlagStatus, setCarePlanViewFlagStatus] = useState(false);
  const [editDataForGoals, setEditDataForGoals] = useState();
  const [tabData, setTabData] = useState([
    {
      id: "1",
      title: "Member Detail",
      content: <MemberDetail isViewMode={isViewMode ?? false} />,
    },
    {
      id: "2",
      title: "Care Team",
      content: <CareTeam />,
      disabled: true,
    },
    {
      id: "3",
      title: "Case Manager",
      content: <CaseManager isViewMode={isViewMode} />,
      disabled: true,
    },
    {
      id: "4",
      title: "Care Giver / Contact Details",
      content: <CareGiverDetails isViewMode={isViewMode} />,
      disabled: true,
    },
    {
      id: "5",
      title: "Allergies",
      content: <Allergies isViewMode={isViewMode} />,
      disabled: true,
    },
    {
      id: "6",
      title: "Medication",
      content: <Medication />,
      disabled: true,
    },
    {
      id: "7",
      title: "Diagnosis History",
      content: <DiagnosisHistory />,
      disabled: true,
    },
    {
      id: "8",
      title: "Recent Tests",
      content: <RecentTests />,
      disabled: true,
    },
    {
      id: "9",
      title: "Problems, Goals, Barriers & Interventions",
      content: (
        <ProblemsAndGoals
          isViewMode={isViewMode}
          toggleAddGoalScreen={setShowAddGoalScreen}
          setEditDataForGoals={setEditDataForGoals}
          setCarePlanViewFlagStatus={setCarePlanViewFlagStatus}
        />
      ),
      disabled: true,
    },
    {
      id: "10",
      title: "ICD Team Notes and Team Name",
      content: <TeamNotes isViewMode={isViewMode ?? false} />,
      disabled: true,
    },
    {
      id: "11",
      title: "IRA Details",
      content: (
        <IRA displayLabel="Initial Risk Assessment" moduleName="CarePlan" />
      ),
      disabled: true,
    },
  ]);

  useEffect(() => {
    if (carePlanDetails.carePlanDetails.carePlanId) {
      setTabData((prevTabData) =>
        prevTabData.map((tab) => ({
          ...tab,
          disabled: false,
        })),
      );
    }
  }, [carePlanDetails]);

  const handleClose = () => {
    toggleAddCarePlan(false);
  };

  const handleSubmit = async () => {
    const carePlanId: string =
      carePlanDetails?.carePlanDetails?.carePlanId.toString();
    const finalizeResponse = await updateCarePlanForFinalize({
      carePlanId,
    });
    if (finalizeResponse.status) {
      showToastMessage(finalizeResponse.message, TOAST_TYPE_OPTIONS.SUCCESS);
    } else {
      showToastMessage(finalizeResponse.message, TOAST_TYPE_OPTIONS.ERROR);
    }
    handleClose();
  };

  return showAddGoalScreen ? (
    <AddGoal
      toggleAddGoalScreen={setShowAddGoalScreen}
      editData={editDataForGoals}
      setEditData={setEditDataForGoals}
      viewFlag={carePlanViewFlagStatus}
    />
  ) : (
    <>
      <AccordionAtom items={tabData} />
      <div className="card-footer d-flex justify-content-end">
        <Button
          type="button"
          className="btn text-danger btn-sm"
          onClick={handleClose}
        >
          Close
        </Button>

        <Button
          type="button"
          className="btn btn-info text-white btn-sm px-4 ms-3"
          onClick={handleSubmit}
          hidden={isViewMode}
        >
          Finalize
        </Button>
      </div>
    </>
  );
}
