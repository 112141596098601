import { useSelector } from "react-redux";
import { ChangeEvent, useEffect, useState } from "react";
import { Input } from "../../../../atom/Input/Input";
import { ModalComponent } from "../../../../molecule/Modal/ModalComponent";
import { showToastMessage } from "../../../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../../../constants/variables";
import { Textarea } from "../../../../atom/Textarea/Textarea";
import { formatDate } from "../../../../../services/utils/formatDate";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
  ValidationRules,
} from "../../../../../services/utils/validateDataAsPerRules";
import { getSearchForReferralIcd10Values } from "../../../../../services/referrals/searchReferralFilter";
import {
  createCaseNotesHealthConcern,
  updateHealthConcernCaseNote,
} from "../../../../../services/caseNotes/caseNotes";
import { SearchDropdown } from "../../../../atom/SearchDropdown/SearchDropdown";
import { getIcd10InfoService } from "../../../../../services/referrals/referrals";

const defaultFormValues = {
  healthConcernDate: "",
  healthConcernId: "",
  note: "",
};

const validationRules: ValidationRules = {
  healthConcernDate: {
    required: true,
    dataType: "date",
    displayName: "Date",
  },
  healthConcernId: {
    required: true,
    dataType: "text",
    displayName: "Health Concern",
  },
  note: {
    required: false,
    dataType: "text",
    displayName: "Note",
    characterLimit: 4000,
  },
};

interface CreateHealthConcernProps {
  getHealthConcernData: () => void;
  editHealthConcernDetails: any;
  editMode: boolean;
  setEditMode: (value: boolean) => void;
  resetEditData: () => void;
}

export function AddHealthConcern({
  getHealthConcernData,
  editHealthConcernDetails,
  editMode,
  setEditMode,
  resetEditData,
}: CreateHealthConcernProps) {
  const caseNotesDetails = useSelector((state: any) => state.activeCaseNotes);
  const caseNoteId = caseNotesDetails?.caseNotesDetails?.caseNoteId;
  const [displayValueForIcd10, setdisplayValueForIcd10] = useState("");
  const [editFlag, setEditFlag] = useState(false);
  const [icd10, setIcd10] = useState([]);
  const [formData, setFormData] = useState({
    values: defaultFormValues,
    errors: defaultFormValues,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (
    event: ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = event.target;
    const fieldError = validateFieldAsPerRules({
      rules: validationRules,
      fieldName: name,
      value,
    });

    setFormData((prevFormData) => ({
      values: { ...prevFormData.values, [name]: value },
      errors: { ...prevFormData.errors, [name]: fieldError },
    }));
  };

  const handleReset = () => {
    setdisplayValueForIcd10("");
    setFormData({
      values: defaultFormValues,
      errors: defaultFormValues,
    });
  };

  const handleClose = () => {
    if (editHealthConcernDetails) {
      setdisplayValueForIcd10("");
      setEditMode(false);
      handleReset();
      resetEditData();
    }
  };
  const updateReferredIcd10List = async (value: string) => {
    const referredIcd10Values = await getSearchForReferralIcd10Values(value);
    setIcd10(referredIcd10Values);
  };

  const handleSubmit = async () => {
    const formErrors = validateDataAsPerRules({
      formData: formData.values,
      rules: validationRules,
    });

    if (Object.values(formErrors).some((error) => error !== "")) {
      setFormData({
        ...formData,
        errors: formErrors,
      });
      return;
    }
    const requestFormObject = {
      ...formData.values,
      caseNoteDetailId: caseNoteId,
      healthConcernDate: formatDate(formData.values.healthConcernDate),
    };

    let addHealthConcernResponse;
    if (editFlag) {
      addHealthConcernResponse = await updateHealthConcernCaseNote({
        requestObject: requestFormObject,
        healthConcernId: editHealthConcernDetails.id,
      });
    } else {
      addHealthConcernResponse =
        await createCaseNotesHealthConcern(requestFormObject);
    }
    if (addHealthConcernResponse.status) {
      showToastMessage(
        addHealthConcernResponse.message,
        TOAST_TYPE_OPTIONS.SUCCESS,
      );
      handleReset();
      getHealthConcernData();
      setIsModalOpen(false);
      setEditMode(false);
    } else {
      showToastMessage(
        addHealthConcernResponse.message,
        TOAST_TYPE_OPTIONS.ERROR,
      );
    }
  };

  const checkForEditFlag = async () => {
    const getIcd10Details = await getIcd10InfoService(
      editHealthConcernDetails?.healthConcernId,
    );
    if (getIcd10Details.status) {
      setdisplayValueForIcd10(
        `${getIcd10Details.data.code} ${getIcd10Details.data.shortDescription}`,
      );
    }
    setFormData({
      values: {
        healthConcernDate: formatDate(
          editHealthConcernDetails.healthConcernDate,
          "YYYY-MM-DD",
        ),
        healthConcernId: editHealthConcernDetails.healthConcernId,
        note: editHealthConcernDetails.note,
      },
      errors: defaultFormValues,
    });
    setEditFlag(true);
  };

  useEffect(() => {
    if (editMode) {
      checkForEditFlag();
      setIsModalOpen(true);
    } else {
      setEditFlag(false);
    }
  }, [editHealthConcernDetails, editMode]);
  return (
    <ModalComponent
      buttonLeft={editFlag ? null : "Clear"}
      buttonRight={editFlag ? "Update" : "Save & Continue"}
      title={editFlag ? "Update Health Concern" : "Add Health Concern"}
      buttonShow="Add"
      onSubmit={handleSubmit}
      closeAction={handleClose}
      showAddUserPopup={isModalOpen}
      setShowAddUserPopup={setIsModalOpen}
      showToggleAsAddButton
    >
      <div className="accordion-collapse">
        <form className="row g-3" onSubmit={handleSubmit}>
          <div className="col-lg-6 col-sm-6">
            <SearchDropdown
              placeholder="ICD 10"
              onSelect={(option) => {
                setFormData({
                  ...formData,
                  values: { ...formData.values, healthConcernId: option.value },
                });
                setdisplayValueForIcd10(option.label);
              }}
              searchCallback={updateReferredIcd10List}
              searchField
              options={icd10}
              displayValue={displayValueForIcd10}
              label={validationRules.healthConcernId.displayName}
              required={validationRules.healthConcernId.required}
            />
          </div>
          <div className="col-lg-6 col-sm-6">
            <Input
              type="date"
              className="form-control form-control-sm"
              label={validationRules.healthConcernDate.displayName}
              value={formData.values.healthConcernDate}
              error={formData.errors.healthConcernDate}
              onChange={handleChange}
              name="healthConcernDate"
              required={validationRules.healthConcernDate.required}
            />
          </div>
          <div className="col-lg-12 col-sm-12">
            <Textarea
              className="form-control form-control-sm"
              placeholder="Text"
              rows={4}
              label={validationRules.note.displayName}
              value={formData.values.note}
              error={formData.errors.note}
              onChange={handleChange}
              name="note"
              required={validationRules.note.required}
            />
          </div>
        </form>
      </div>
    </ModalComponent>
  );
}
