import { DashboardLayout } from "../../components/layout/DashboardLayout";
import { ErrorUI } from "../../components/molecule/ErrorUI/ErrorUI";
import { Dashboard } from "../../pages/Dashboard/Dashboard";
import { UsersList } from "../../pages/Dashboard/UsersList/UsersList";
import { ResetUserPassword } from "../../pages/Dashboard/ResetUserPassword/ResetUserPassword";
import { PatientInfo } from "../../pages/Dashboard/PatientInfo/PatientInfo";
import { Insurance } from "../../pages/Dashboard/Insurance/Insurance";
import { CareTeamAndCaseManager } from "../../pages/Dashboard/CareTeamAndCaseManager/CareTeamAndCaseManager";
import { Appointments } from "../../pages/Dashboard/Appointments/Appointments";
import { Tasks } from "../../pages/Dashboard/Tasks/Tasks";
import { Memos } from "../../pages/Dashboard/Memos/Memos";
import { Referrals } from "../../pages/Dashboard/Referral/Referral";
import { MasterData } from "../../pages/Dashboard/MasterData/MasterData";
import { LabAndDiagnostic } from "../../pages/Dashboard/LabAndDiagnosticResult/LabAndDiagnostic";
import { FormsAndIRA } from "../../pages/Dashboard/FormsAndIRA/FormsAndIRA";
import { CarePlan } from "../../pages/Dashboard/CarePlan/CarePlan";
import { PatientQuickAdd } from "../../pages/Dashboard/PatientQuickAdded/PatientQuickAdded";
import { MODULE_NAME_OPTION } from "../../constants/variables";
import { Timeline } from "../../pages/Dashboard/Timeline/TImeline";
import { CaseNotes } from "../../pages/Dashboard/CaseNotes/CaseNotes";

export const dashboardRoutes = [
  {
    path: "dashboard",
    element: <DashboardLayout />,
    errorElement: <ErrorUI />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "users-list",
        element: <UsersList />,
      },
      {
        path: "reset-user-password",
        element: <ResetUserPassword />,
      },
      {
        path: "patient-Info",
        element: <PatientInfo />,
      },
      {
        path: "insurance",
        element: <Insurance />,
      },
      {
        path: "care-team",
        element: (
          <CareTeamAndCaseManager moduleName={MODULE_NAME_OPTION.careTeam} />
        ),
      },
      {
        path: "case-manager",
        element: (
          <CareTeamAndCaseManager moduleName={MODULE_NAME_OPTION.careManager} />
        ),
      },
      {
        path: "case-notes",
        element: <CaseNotes />,
      },
      {
        path: "appointments",
        element: <Appointments />,
      },
      {
        path: "tasks",
        element: <Tasks />,
      },
      {
        path: "memos",
        element: <Memos />,
      },
      {
        path: "referrals",
        element: <Referrals />,
      },
      {
        path: "master-data",
        element: <MasterData />,
      },
      {
        path: "lab-result-data",
        element: <LabAndDiagnostic />,
      },
      {
        path: "forms-data",
        element: <FormsAndIRA />,
      },
      {
        path: "care-plan",
        element: <CarePlan />,
      },
      {
        path: "quick-add",
        element: <PatientQuickAdd />,
      },
      {
        path: "manage-time-line",
        element: <Timeline />,
      },
    ],
  },
];
