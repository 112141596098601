import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "../../../components/atom/Cards/Cards";
import { Table } from "../../../components/atom/Table/Table";
import { Button } from "../../../components/atom/Buttons/Button";
import {
  getAllCaseNotesForPatient,
  deleteCaseNoteService,
} from "../../../services/caseNotes/caseNotes";
import {
  initCaseNotesDetails,
  resetCaseNotesDetails,
} from "../../../redux/activeCaseNote/activeCaseNote";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import { CreateNewCaseNotes } from "../../../components/organism/CreateNewCaseNotes/CreateNewCaseNotes";
import { getOptionsForDropdown } from "../../../services/dropDownMenuOptions/dropDownMenuOptions";
import { Pagination } from "../../../components/atom/Pagination/Pagination";
import { ProfileCard } from "../../../components/molecule/ProfileCard/ProfileCard";
import { FullScreenLoader } from "../../../components/atom/FullScreenLoader/FullScreenLoader";

const sortingHeaderMap: Record<string, string> = {
  encounterTypeId: "EncounterType",
  noteTypeId: "NoteType",
  date: "Date",
  ageAtEncounter: "AgeofEncounter",
  organization: "Organization",
  seenBy: "SeenBy",
  statusId: "StatusId",
  createdOn: "CreatedOn",
  lastModifiedOn: "lastModifiedOn",
};

export const tableHeaders = {
  encounterTypeId: "Encounter Type",
  noteTypeId: "Note Type",
  date: "Date",
  ageAtEncounter: "Age At Encounter",
  organization: "Organization",
  seenBy: "Seen By",
  statusId: "Status",
  createdOn: "Created Date",
  lastModifiedOn: "Last Modified Date",
  action: "Action",
};

export function CaseNotes() {
  const activePatient = useSelector((state: any) => state.activePatient);
  const dispatch = useDispatch();
  const [allCaseNotesData, setAllCaseNotesData] = useState<any[]>([]);
  const [isAddNewCaseNote, setIsAddNewCaseNote] = useState(false);
  const [fullScreenLoaderFlag, setfullScreenLoaderFlag] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const [paginationData, setPaginationData] = useState<{
    pageNo: number;
    totalPages: number;
    totalElements: number;
    pageSize: number;
    sortingFieldName: string;
    sortingOrder: string;
  }>({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });

  const fetchDropdownOptions = async () => {
    const encounterList = await getOptionsForDropdown("encounterType");
    const noteType = await getOptionsForDropdown("noteType");
    const statusType = await getOptionsForDropdown("CarePlanStatus");
    return { encounterList, noteType, statusType };
  };
  interface DropdownOptions {
    encounterList: any;
    noteType: any;
    statusType: any;
  }
  const fetchData = async (newPaginationData?: any) => {
    try {
      setfullScreenLoaderFlag(true);
      const dropdownOptions:
        | DropdownOptions
        | undefined = await fetchDropdownOptions();
      if (!dropdownOptions) {
        throw new Error("Dropdown options are not available.");
      }
      const { encounterList, noteType, statusType } = dropdownOptions;
      const paginationDataAsParameter = newPaginationData || paginationData;

      const response = await getAllCaseNotesForPatient({
        paginationDataAsParameter,
        patientId: activePatient.patientDetails.id,
      });
      if (response.status) {
        const mappedData = response.data.dataList.map((note: any) => ({
          ...note,
          encounterTypeId:
            encounterList.find(
              (option: any) => option.value === note.encounterTypeId
            )?.label || note.encounterTypeId,
          noteTypeId:
            noteType.find((option: any) => option.value === note.noteTypeId)
              ?.label || note.noteTypeId,
          statusId:
            statusType.find((option: any) => option.value === note.statusId)
              ?.label || note.statusId,
        }));
        setAllCaseNotesData(mappedData);
        setPaginationData({
          ...paginationData,
          pageNo: response.data.pageNo,
          totalPages: response.data.totalPages,
          totalElements: response.data.totalElements,
          pageSize: response.data.pageSize,
        });
        setfullScreenLoaderFlag(false);
      }
    } catch (error) {
      showToastMessage(
        "Error fetching case notes data:",
        TOAST_TYPE_OPTIONS.ERROR
      );
      console.error("Error in fetching case notes data :", error);
    }
  };
  const handleAddNewCaseNote = () => {
    setIsAddNewCaseNote(!isAddNewCaseNote);
    if (!isAddNewCaseNote) {
      dispatch(resetCaseNotesDetails());
    }
  };
  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    fetchData({ ...paginationData, pageNo, pageSize });
  };

  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    fetchData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };

  const deleteCaseNote = async (rowData: any) => {
    if (rowData.statusId === "Completed") {
      showToastMessage(
        "Cannot Deleted completed case Note",
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }
    const deleteCaseNoteResponse = await deleteCaseNoteService(rowData.id);
    if (deleteCaseNoteResponse.status) {
      showToastMessage(
        deleteCaseNoteResponse.message,
        TOAST_TYPE_OPTIONS.SUCCESS
      );
      fetchData();
    } else {
      showToastMessage(
        deleteCaseNoteResponse.message,
        TOAST_TYPE_OPTIONS.ERROR
      );
    }
  };
  const editRowAction = async (rowData: any) => {
    handleAddNewCaseNote();
    dispatch(
      initCaseNotesDetails({
        caseNoteId: rowData.caseNoteDetailId,
      })
    );
  };

  const isActionDisabled = (rowData: any) => {
    if (rowData?.isCompleted) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    fetchData();
  }, [activePatient]);

  useEffect(() => {
    if (!activePatient.patientDetails.id) {
      setAllCaseNotesData([]);
    }
  }, [activePatient]);

  const toggleAddCaseNotes = (state: boolean) => {
    setIsAddNewCaseNote(state);
    setIsViewMode(false);
  };

  return (
    <div>
      <ProfileCard />
      <FullScreenLoader show={fullScreenLoaderFlag} />
      <Card>
        <div className="d-flex justify-content-between">
          <h3>Case Notes</h3>
          {!isAddNewCaseNote && (
            <Button
              variant="primary"
              onClick={handleAddNewCaseNote}
              className="btn btn-primary btn-sm px-2 my-2"
              disabled={activePatient.patientDetails.id === ""}
            >
              <i className="fa fa-plus me-2 fs-5" />
              Add Case Note
            </Button>
          )}
        </div>

        {isAddNewCaseNote ? (
          <CreateNewCaseNotes
            toggleAddCaseNotes={toggleAddCaseNotes}
            fetchTableData={fetchData}
            isViewMode={isViewMode}
          />
        ) : (
          <div className="table-responsive care-manager-table">
            <Table
              className="table table-bordered table-striped table-hover"
              data={allCaseNotesData}
              headers={tableHeaders}
              enableDeleteAction={deleteCaseNote}
              sortingFunction={sortingFunction}
              sortingHeaderMap={sortingHeaderMap}
              enableEditAction={(rowData) => {
                editRowAction(rowData);
              }}
              enableViewAction={(rowData) => {
                setIsViewMode(true);
                setIsAddNewCaseNote(true);
                dispatch(
                  initCaseNotesDetails({
                    caseNoteId: rowData.caseNoteDetailId,
                  })
                );
              }}
              isDeleteDisabled={isActionDisabled}
              isEditDisabled={isActionDisabled}
            />
            {paginationData.totalPages >= 0 && (
              <Pagination
                currentPage={paginationData.pageNo}
                totalPages={paginationData.totalPages}
                pageSize={paginationData.pageSize}
                onPageChange={(page, pageSize) => {
                  updatePaginationData(page, pageSize);
                }}
              />
            )}
          </div>
        )}
      </Card>
    </div>
  );
}
