import React, { useState, useRef, useEffect } from "react";
import { Input } from "../Input/Input";

export interface DropdownOption {
  label: string;
  value: string;
  [x: string]: any;
}

export interface SearchDropdownProps {
  options: any[];
  onSelect: (option: DropdownOption) => void;
  searchField?: boolean;
  searchCallback: (searchText: string) => void;
  placeholder?: string;
  displayValue?: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  label?: string;
}

export function SearchDropdown({
  options,
  onSelect,
  searchField,
  searchCallback,
  placeholder,
  displayValue,
  disabled = false,
  required = false,
  error,
  label,
}: SearchDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [isValueSelectedByUser, setIsValueSelectedByUser] = useState(true);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: DropdownOption) => {
    onSelect(option);
    setIsOpen(false);
    setFilter(option.label);
    setIsValueSelectedByUser(!isValueSelectedByUser);
  };

  const handleSearch = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setFilter(event.target.value);
    setIsValueSelectedByUser(false);
    if (!event.target.value) {
      handleOptionClick({ label: "", value: "" });
      setFilter("");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!isValueSelectedByUser) {
      searchCallback(filter);
    }
  }, [filter]);

  useEffect(() => {
    if (displayValue) {
      setFilter(displayValue);
    } else {
      setFilter("");
    }
  }, [displayValue]);

  return (
    <>
      {!!label && (
        <label htmlFor="select">
          {label} {!!required && <span className="text-danger">*</span>}
        </label>
      )}
      <div className="position-relative">
        <div className="relative">
          {searchField && (
            <div className="relative">
              <Input
                type="text"
                id={placeholder}
                name={placeholder}
                value={filter}
                onChange={handleSearch}
                placeholder={placeholder}
                className="form-control form-control-sm"
                disabled={disabled}
              />
              <span
                style={{
                  top: "4px",
                  right: "4px",
                }}
                className="position-absolute bg-transparent text-gray"
              >
                <i className="border-0 fa-solid fa-magnifying-glass" />
              </span>
            </div>
          )}
        </div>
        {error && <p className="text-danger error-message">{error}</p>}

        {searchField && !isValueSelectedByUser && filter.length > 0 && (
          <ul
            id="select-option"
            className="position-absolute z-1 mt-2 max-h-64 w-full overflow-y-auto rounded bg-white p-1 pl-0 text-gray-700 shadow-lg border border-gray-200"
            style={{
              top: "26px",
              left: 0,
            }}
            onMouseLeave={handleToggle}
          >
            {options.length > 0 ? (
              options.map((option) => (
                <li
                  key={option.value}
                  role="presentation"
                  className="p-1 hover:bg-gray-200"
                  onClick={() => handleOptionClick(option)}
                >
                  {option.label}
                </li>
              ))
            ) : (
              <li className="p-1 text-gray-500">
                No matching search results found
              </li>
            )}
          </ul>
        )}
      </div>
    </>
  );
}
