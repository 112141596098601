import { HorizontalTabs } from "../../../components/atom/HorizontalTab/HorizontalTab";
import { LabResult } from "./LabResult";
import { DiagnosticResult } from "./DiagnosticResult";
import { ProfileCard } from "../../../components/molecule/ProfileCard/ProfileCard";

export function LabAndDiagnostic() {
  const tabs = [
    {
      key: "1",
      title: "Lab Result",
      content: <LabResult displayLabel="Lab Result" />,
    },
    {
      key: "2",
      title: "Diagnostic Result",
      content: <DiagnosticResult displayLabel="Diagnostic Result" />,
    },
  ];
  return (
    <div>
      <div>
        <ProfileCard />
        <HorizontalTabs tabs={tabs} />
      </div>
    </div>
  );
}
