import React, { useState, ChangeEvent, useEffect } from "react";
import { useSelector } from "react-redux";
import { Input } from "../../atom/Input/Input";
import {
  FILE_SIZE_MAX,
  TOAST_TYPE_OPTIONS,
} from "../../../constants/variables";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../services/utils/validateDataAsPerRules";
import { formatDate } from "../../../services/utils/formatDate";
import { allKeysEmpty } from "../../../services/utils/allKeyEmpty";
import { FileField } from "../../atom/FileField/FileField";
import { Image } from "../../atom/Images/Image";
import { InputDropdown } from "../../atom/InputDropDown/InputDropDown";
import { createForm, updateForm } from "../../../services/FormsAndIRA/forms";
import { getOptionsForDropdown } from "../../../services/dropDownMenuOptions/dropDownMenuOptions";
import { Button } from "../../atom/Buttons/Button";
import { fileToBase64 } from "../../../services/utils/fileToBase64";
import {
  defaultFormValues,
  validationRules,
} from "./helperFunctions/constants";

export interface CreateLabResultProps {
  editData: any;
  onSubmitAction: () => void;
}

export function CreateForm({ editData, onSubmitAction }: CreateLabResultProps) {
  const activePatient = useSelector((state: any) => state.activePatient);
  const [uploadFile, setUploadFile] = useState<File | undefined>();
  const [categoryList, setCategoryList] = useState([]);
  const [sourceList, setSourceList] = useState([]);
  const [editFlag, setEditFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [selectedPdf, setSelectedPdf] = useState<string>("");
  const [formData, setFormData] = useState({
    values: defaultFormValues,
    errors: defaultFormValues,
  });
  const { values, errors } = formData;

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = event.target;
    const newError = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: name,
      fieldName: name,
      value,
    });
    setFormData((prevState) => ({
      values: {
        ...prevState.values,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: newError,
      },
    }));
  };

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setUploadFile(file);
    if (file) {
      const fileName = file.name;
      const fileSize = file.size;
      const fileType = file.type;
      const base64String = await fileToBase64(file);

      if (fileSize > FILE_SIZE_MAX) {
        setFormData((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            uploadDocument: "The selected file exceeds 10 MB",
          },
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            uploadDocument: "",
          },
        }));
      }
      if (fileName) {
        setFormData((prevState) => ({
          ...prevState,
          values: {
            ...prevState.values,
            uploadDocument: fileName,
            dateOfUploadDocument: new Date().toISOString().split("T")[0],
          },
        }));
      }
      if (fileType === "application/pdf") {
        setSelectedPdf(URL.createObjectURL(file));
        setSelectedImage("");
      } else if (fileType.startsWith("image/")) {
        setSelectedPdf("");
        setSelectedImage(base64String);
      }
    }
  };

  const handleClear = () => {
    setFormData({
      values: defaultFormValues,
      errors: defaultFormValues,
    });
    setUploadFile(undefined);
    setEditFlag(false);
    setSelectedImage("");
    setSelectedPdf("");
  };

  const handleSubmit = async () => {
    if (activePatient?.patientDetails?.id === "") {
      showToastMessage("Please select patient", TOAST_TYPE_OPTIONS.ERROR);
      return;
    }
    const formErrors = validateDataAsPerRules({
      formData: values,
      rules: validationRules,
    });
    setFormData((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        ...formErrors,
      },
    }));
    const fileSize = uploadFile ? uploadFile.size : 0;
    if (Object.keys(formErrors).length > 0) {
      showToastMessage(
        "Please fill all mandatory fields",
        TOAST_TYPE_OPTIONS.ERROR,
      );
      return;
    }
    if (fileSize > FILE_SIZE_MAX) {
      showToastMessage(
        "The uploaded document exceeds 10 MB",
        TOAST_TYPE_OPTIONS.ERROR,
      );
      return;
    }
    const isEmpty = allKeysEmpty(values);
    if (isEmpty) {
      showToastMessage(
        "Please fill at least one field",
        TOAST_TYPE_OPTIONS.ERROR,
      );
      return;
    }

    const requestObject = {
      patientId: activePatient.patientDetails.id,
      ...values,
      files: uploadFile,
      dateOfPerformTest: formatDate(values.dateOfPerformTest),
    };

    let formResponse: {
      status?: boolean;
      message?: string;
      data?: any;
    } = {};
    setIsLoading(true);
    if (editFlag) {
      formResponse = await updateForm(requestObject, editData.id);
    } else {
      formResponse = await createForm(requestObject);
      setEditFlag(false);
    }
    if (formResponse.status) {
      showToastMessage(formResponse.message, TOAST_TYPE_OPTIONS.SUCCESS);
      onSubmitAction();
      handleClear();
    } else {
      showToastMessage(formResponse.message, TOAST_TYPE_OPTIONS.ERROR);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (editData?.id && editData?.id !== "") {
      const { fileSrc, dateOfPerformTest, dateOfUploadDocument, ...rest } =
        editData;
      setEditFlag(true);
      setFormData((prevState) => ({
        ...prevState,
        values: {
          ...rest,
          dateOfPerformTest: formatDate(dateOfPerformTest, "YYYY-MM-DD"),
          dateOfUploadDocument: new Date(dateOfUploadDocument)
            .toISOString()
            .split("T")[0],
        },
      }));
      if (fileSrc) {
        if (rest?.uploadDocument?.endsWith(".pdf")) {
          setSelectedPdf(fileSrc);
        } else {
          setSelectedImage(fileSrc);
        }
      }
    }
  }, [editData]);

  const fetchData = async () => {
    const categoryOption = await getOptionsForDropdown("FormsCategory");
    const sourceOptions = await getOptionsForDropdown("FormsSource");
    setCategoryList(categoryOption);
    setSourceList(sourceOptions);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="row g-3">
      <div className="col-lg-6 col-sm-6">
        <div>
          {selectedImage && (
            <Image
              src={selectedImage}
              alt={values.uploadDocument}
              title={values.uploadDocument}
              width="100%"
            />
          )}
        </div>
        <div>
          {selectedPdf && (
            <iframe
              title={values.uploadDocument}
              src={selectedPdf}
              width="100%"
              height="500"
            />
          )}
        </div>
      </div>
      <div className="col-lg-6 col-sm-6">
        <form>
          <div>
            <InputDropdown
              label="Category"
              options={categoryList}
              onSelect={handleChange}
              className="w-100 form-select-sm "
              name="categoryId"
              value={values.categoryId}
              error={errors.categoryId}
              required={validationRules.categoryId.required}
            />
          </div>
          <div>
            <Input
              type="date"
              id="dateOfUploadDocument"
              name="dateOfUploadDocument"
              value={values.dateOfUploadDocument}
              error={errors.dateOfUploadDocument}
              onChange={handleChange}
              placeholder="dateOfUploadDocument"
              className="form-control form-control-sm"
              label={validationRules.dateOfUploadDocument.displayName}
              required={validationRules.dateOfUploadDocument.required}
              disabled
            />
          </div>
          <div>
            <FileField
              name="uploadDocument"
              accept="image/*.pdf"
              filePath={values.uploadDocument}
              error={errors.uploadDocument}
              onChange={handleImageChange}
              label="Upload Document"
              required={validationRules.uploadDocument.required}
            />
          </div>
          <div>
            <Input
              type="date"
              id="dateOfPerformTest"
              name="dateOfPerformTest"
              value={values.dateOfPerformTest}
              error={errors.dateOfPerformTest}
              onChange={handleChange}
              placeholder="dateOfPerformTest"
              className="form-control form-control-sm"
              label={validationRules.dateOfPerformTest.displayName}
              required={validationRules.dateOfPerformTest.required}
            />
          </div>
          <div>
            <InputDropdown
              label="Source"
              options={sourceList}
              onSelect={handleChange}
              className="w-100 form-select-sm "
              name="sourceId"
              value={values.sourceId}
              error={errors.sourceId}
              required={validationRules.sourceId.required}
            />
          </div>
          <div>
            <Input
              type="text"
              id="provider"
              name="provider"
              value={values.provider}
              error={errors.provider}
              onChange={handleChange}
              placeholder="provider"
              className="form-control form-control-sm"
              label={validationRules.provider.displayName}
              required={validationRules.provider.required}
            />
          </div>
          <div className="col-12 mt-5">
            <div className=" d-flex justify-content-end gap-1 ">
              {!editFlag && (
                <Button
                  onClick={() => handleClear()}
                  variant="outlined"
                  className="btn btn-danger btn-sm"
                  label="Clear"
                />
              )}
              <Button
                onClick={() => handleSubmit()}
                variant="outlined"
                className="btn btn-primary btn-sm px-4"
                label={editFlag ? "Update" : "Save & Continue"}
                isLoading={isLoading}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
