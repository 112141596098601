export const formatDate = (value: string, format?: string): string => {
  if (value === "" || !value) return "";
  if (format && value) {
    if (format === "YYYY-MM-DD") {
      const date = new Date(value);
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      const formattedDate = date.toISOString().split("T")[0];
      return formattedDate;
    }
  } else {
    const date = new Date(value);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${month}-${day}-${year}`;
  }
  return value;
};
