import {
  ServiceResponse,
  generateErrorResponse,
} from "./generateErrorResponse";

function isValidMobileNumber(mobileNumber: string): boolean {
  return /^\d{10}$/.test(mobileNumber);
}

export function validateMobileNumberAndNullCheck(
  mobileNumber: string,
  fieldName: string,
): ServiceResponse {
  if (!mobileNumber) {
    return generateErrorResponse(`${fieldName} is required.`, 400);
  }

  if (!isValidMobileNumber(mobileNumber)) {
    return generateErrorResponse(
      `${fieldName} should be a valid 10-digit number.`,
      400,
    );
  }

  return { status: true };
}

export function validateMobileNumber(
  mobileNumber: string,
  fieldName: string,
): ServiceResponse {
  if (!isValidMobileNumber(mobileNumber)) {
    return generateErrorResponse(
      `${fieldName} should be a valid 10-digit number.`,
      400,
    );
  }

  return { status: true };
}
