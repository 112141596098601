/* eslint-disable complexity */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputDropdown } from "../../../components/atom/InputDropDown/InputDropDown";
import { Input } from "../../../components/atom/Input/Input";
import { Button } from "../../../components/atom/Buttons/Button";
import { SearchDropdown } from "../../../components/atom/SearchDropdown/SearchDropdown";
import "./PatientProviderInfo.css";
import { addPatientProviderInfo } from "../../../services/patients/patients";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import { setProviderDetails } from "../../../redux/activePatient/activePatientSlice";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import { getOptionsForDropdown } from "../../../services/dropDownMenuOptions/dropDownMenuOptions";
import {
  getSearchForPcpValues,
  getSearchSpecaltyProviderById,
  getSearchSpecaltyProviderValues,
} from "../../../services/searchFilter/searchFilter";
import { formatDate } from "../../../services/utils/formatDate";
import { getPCPInfoService } from "../../../services/masterData/pcp";
import { isUserHaveOnlyViewPermission } from "../../../services/users/users";
import { allKeysEmpty } from "../../../services/utils/allKeyEmpty";
import Logger from "../../../utils/Logger";
import { Textarea } from "../../../components/atom/Textarea/Textarea";

interface ProviderSpecialtyOption {
  label: string;
  value: number;
}
interface PatientProviderInfoProps {
  nextTab: () => void;
}
export function PatientProviderInfo({ nextTab }: PatientProviderInfoProps) {
  const dispatch = useDispatch();
  const activePatient = useSelector((state: any) => state.activePatient);
  const loginUserDetails = useSelector((state: any) => state.userDetails);
  const [providerProfile, setProviderProfile] = useState([]);
  const [isShowSpecialtyProvider, setIsShowSpecialtyProvider] = useState(false);
  const [isShowAddSpecialtyProvider, setIsShowAddSpecialtyProvider] = useState(
    false
  );

  const [pcpList, setPcpList] = useState([]);
  const [specialtyProviderList, setSpecialtyProviderList] = useState([]);

  const [patientStatus, setPatientStatus] = useState([]);
  const [displayValueForPcp, setDisplayValueForPcp] = useState("");
  const [viewPermissionOnlyFlag, setViewPermissionOnlyFlag] = useState(false);
  const [patientFormData, setPatientFormData] = useState({
    providerProfile: "" as string,
    specialtyProvider: "" as string,
    date: "",
    pcpName: "",
    patientStatus: "",
    patientNotes: "",
  });
  const [errors, setErrors] = useState({
    providerProfile: "",
    specialtyProvider: "",
    date: "",
    pcpName: "",
    patientStatus: "",
    patientNotes: "",
  });
  const [providerSpecialities, setProviderSpecialities] = useState<any[]>([]);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [displayValueSpecialtyProv, setdisplayValueSpecialtyProv] = useState(
    ""
  );

  const [editMode, setEditMode] = useState(false);
  const [disabledButtons, setDisabledButtons] = useState<number | null>(null);
  const debounce = (func: Function, delay: number) => {
    let timeoutId: any;
    return function (this: any, ...args: any[]) {
      const context = this;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const debouncedSearchPcp = debounce(async (value: string) => {
    try {
      const pcpValues = await getSearchForPcpValues(value);
      setPcpList(pcpValues);
    } catch (error) {
      console.error("Error fetching PCP values:", error);
    }
  }, 900);

  const debouncedSearchSpecialtyProvider = debounce(async (value: string) => {
    try {
      const specialtyProviderValues = await getSearchSpecaltyProviderValues(
        value
      );
      setSpecialtyProviderList(specialtyProviderValues);
    } catch (error) {
      console.error("Error fetching Specialty Provider values:", error);
    }
  }, 900);
  const handleChange = async (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;

    setPatientFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "pcpName" && value.trim() !== "") {
      debouncedSearchPcp(value);
    }
    if (name === "specialtyProvider" && value.trim() !== "") {
      debouncedSearchSpecialtyProvider(value);
    }

    if (name === "providerProfile") {
      setdisplayValueSpecialtyProv("");
      setPatientFormData((prevFormData) => ({
        ...prevFormData,
        specialtyProvider: "",
      }));
      if (!value) {
        setIsShowSpecialtyProvider(false);
        setErrors({ ...errors, [name]: "Please select a provider speciality" });
      } else {
        setdisplayValueSpecialtyProv("");
        setIsShowSpecialtyProvider(true);
        setErrors({ ...errors, [name]: "" });
      }
    }

    if (name === "date") {
      if (!value) {
        setErrors({ ...errors, [name]: "Please select a date" });
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    }
    if (name === "patientNotes") {
      if (value && value.length > 250) {
        setErrors({ ...errors, [name]: "You have reach the limit" });
      } else {
        setErrors({ ...errors, [name]: "" });
      }
    }
    if (name === "patientStatus") {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleNext = async () => {
    const newErrors = errors;
    if (patientFormData.pcpName === "") {
      newErrors.pcpName = "Primary Care Provider is required";
    }
    setErrors((prev) => ({ ...prev, newErrors }));
    const isError = allKeysEmpty(newErrors);
    if (!isError) {
      showToastMessage(
        "Please fill all mandatory fields",
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }
    if (
      (patientFormData.date === "" && patientFormData.patientStatus !== "") ||
      (patientFormData.date !== "" && patientFormData.patientStatus === "")
    ) {
      if (patientFormData.date === "" && patientFormData.patientStatus !== "") {
        setErrors({
          ...errors,
          date: "Please select Date",
        });
      }
      if (patientFormData.date !== "" && patientFormData.patientStatus === "") {
        setErrors({
          ...errors,
          patientStatus: "Please select Patient Status",
        });
      }
      showToastMessage(
        "Please select both Date or Patient Status",
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }
    setErrors({
      ...errors,
      date: "",
      patientStatus: "",
    });
    const formattedData = {
      providerSpeciality: providerSpecialities,
      notes: patientFormData.patientNotes,
      patientStatus: patientFormData.patientStatus,
      patientAsOfDate: formatDate(patientFormData.date, "MM-DD-YYYY"),
      pcpId: patientFormData.pcpName,
      patientId: activePatient.patientDetails.id,
    };
    Logger.logInfo("Patient Provider Info for add/update", {
      data: formattedData,
    });
    const addPatientProvider = await addPatientProviderInfo(formattedData);
    if (addPatientProvider.status) {
      Logger.logInfo("Patient Provider Info added/updated", {
        data: addPatientProvider.data,
      });
      showToastMessage(addPatientProvider.message, TOAST_TYPE_OPTIONS.SUCCESS);
      dispatch(setProviderDetails(addPatientProvider.data));
      nextTab();
    } else {
      Logger.logError("failed to add/update", { data: addPatientProvider });
      showToastMessage(addPatientProvider.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  const handleClear = () => {
    setDisplayValueForPcp("");
    setdisplayValueSpecialtyProv("");
    setIsShowSpecialtyProvider(false);
    setIsShowAddSpecialtyProvider(false);
    setPatientFormData({
      providerProfile: "",
      specialtyProvider: "",
      date: "",
      pcpName: "",
      patientStatus: "",
      patientNotes: "",
    });
    setErrors({
      providerProfile: "",
      specialtyProvider: "",
      date: "",
      pcpName: "",
      patientStatus: "",
      patientNotes: "",
    });
    setDisabledButtons(null);
  };
  const handleAddSpeciality = async () => {
    const specialtyProv = await getSearchSpecaltyProviderById(
      patientFormData.specialtyProvider
    );
    const providerProfileOptions: ProviderSpecialtyOption[] = providerProfile;

    const selectedProviderProfile = providerProfileOptions.find(
      (option) => option.value === Number(patientFormData.providerProfile)
    );
    const newSpeciality = {
      providerSpeciality: patientFormData.providerProfile,
      providerSpecialityLabel: selectedProviderProfile
        ? selectedProviderProfile.label
        : "",
      specialityProviderId: patientFormData.specialtyProvider,
      specialityProviderLabel: `${specialtyProv.title} ${specialtyProv.firstName} ${specialtyProv.lastName}`,
    };
    if (
      providerSpecialities.some(
        (speciality) =>
          speciality.providerSpeciality === newSpeciality.providerSpeciality &&
          speciality.specialityProviderId === newSpeciality.specialityProviderId
      )
    ) {
      showToastMessage(
        "This specialty is already added",
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }
    const updatedSpecialities = [...providerSpecialities];
    if (editIndex !== null) {
      updatedSpecialities[editIndex] = newSpeciality;
    } else {
      updatedSpecialities.push(newSpeciality);
    }

    setProviderSpecialities(updatedSpecialities);
    setPatientFormData((prevFormData) => ({
      ...prevFormData,
      providerProfile: "",
      specialtyProvider: "",
    }));
    setIsShowAddSpecialtyProvider(false);
    setdisplayValueSpecialtyProv("");
    setIsShowSpecialtyProvider(false);
    setEditIndex(null);
    setDisabledButtons(null);
  };
  const handleCancelUpdate = () => {
    setEditIndex(null);
    setDisabledButtons(null);
  };
  const handleEditSpeciality = (index: number) => {
    const specialityToEdit = providerSpecialities[index];
    setPatientFormData({
      ...patientFormData,
      providerProfile: specialityToEdit.providerSpeciality,
      specialtyProvider: specialityToEdit.specialityProviderId,
    });
    setdisplayValueSpecialtyProv(specialityToEdit.specialityProviderLabel);
    setEditIndex(index);
    setIsShowSpecialtyProvider(true);
    setIsShowAddSpecialtyProvider(true);
    setDisabledButtons(index);
  };
  const checkForEditFlag = async () => {
    const { providerInfo } = activePatient;
    setEditMode(activePatient.editStatusFlag);
    if (activePatient.patientDetails.id === "") {
      showToastMessage(
        "Patient is not been selected.",
        TOAST_TYPE_OPTIONS.ERROR
      );
    }

    setPatientFormData({
      providerProfile: "",
      specialtyProvider: "",
      date: formatDate(providerInfo?.patientAsOfDate, "YYYY-MM-DD"),
      pcpName: providerInfo?.pcpId,
      patientStatus: providerInfo?.patientStatus,
      patientNotes: providerInfo?.notes,
    });
    const getPcpDetails = await getPCPInfoService(providerInfo?.pcpId);
    if (getPcpDetails.status) {
      setDisplayValueForPcp(
        `${getPcpDetails.data.title} ${getPcpDetails.data.firstName} ${getPcpDetails.data.lastName}`
      );
    }
    if (loginUserDetails) {
      const isOnlyViewPermission = isUserHaveOnlyViewPermission({
        permissions: loginUserDetails.userPermission,
        module: "PATIENT",
      });

      if (isOnlyViewPermission) {
        setViewPermissionOnlyFlag(true);
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { providerInfo } = activePatient;
        const patientStatusList = await getOptionsForDropdown("patientStatus");
        setPatientStatus(patientStatusList);
        const providerSpecialityList = await getOptionsForDropdown(
          "providerSpecialty"
        );
        setProviderProfile(providerSpecialityList);
        setPatientFormData((prevFormData) => ({
          ...prevFormData,
          providerSpeciality: providerInfo.providerSpeciality,
        }));
        setPatientFormData((prevFormData) => ({
          ...prevFormData,
          providerSpeciality: providerInfo.providerSpeciality,
        }));
        const providerSpecialityDetails = await Promise.all(
          providerInfo.providerSpeciality.map(
            async (item: {
              providerSpeciality: any;
              specialityProviderId: string | number;
            }) => {
              const selectedProviderProfile = providerSpecialityList.find(
                (option: { value: number }) =>
                  option.value === Number(item.providerSpeciality)
              );
              const specialtyProv = await getSearchSpecaltyProviderById(
                item.specialityProviderId
              );
              return {
                providerSpeciality: item.providerSpeciality,
                providerSpecialityLabel: selectedProviderProfile
                  ? selectedProviderProfile.label
                  : "",
                specialityProviderId: item.specialityProviderId,
                specialityProviderLabel: `${specialtyProv.title} ${specialtyProv.firstName} ${specialtyProv.lastName}`,
              };
            }
          )
        );
        setProviderSpecialities(providerSpecialityDetails);

        checkForEditFlag();
      } catch (error) {
        showToastMessage(
          "Error fetching Provider Speciality:",
          TOAST_TYPE_OPTIONS.ERROR
        );
        console.error("Error fetching Provider Speciality:", error);
      }
    })();
  }, []);

  const handleDeleteSpeciality = (index: number) => {
    const updatedSpecialities = providerSpecialities.filter(
      (_, i) => i !== index
    );
    setProviderSpecialities(updatedSpecialities);
  };

  const updatePcpList = async (value: string) => {
    const pcpValues = await getSearchForPcpValues(value);
    if (pcpValues) {
      setPcpList(pcpValues);
      setErrors({
        ...errors,
        pcpName: "",
      });
    } else {
      setErrors({
        ...errors,
        pcpName: "Please select Primary Care Provider",
      });
    }
  };

  const updateSpecialtyProviderList = async (value: string) => {
    const specialtyProviderValues = await getSearchSpecaltyProviderValues(
      value
    );
    if (specialtyProviderValues) {
      setIsShowAddSpecialtyProvider(true);
      setSpecialtyProviderList(specialtyProviderValues);
      setErrors({
        ...errors,
        specialtyProvider: "",
      });
    } else {
      setErrors({
        ...errors,
        specialtyProvider: "Please select Primary Care Provider",
      });
    }
  };
  useEffect(() => {
    if (!editMode) {
      setPatientFormData((prevFormData) => ({
        ...prevFormData,
        providerProfile: "",
        specialtyProvider: "",
      }));
      setIsShowSpecialtyProvider(false);
      setIsShowAddSpecialtyProvider(false);
    }
  }, [editMode]);
  return (
    <div className="tab-content col-lg-12 col-sm-12 " id="v-pills-tabContent">
      <div
        className="tab-pane fade show active"
        id="v-pills-patient"
        role="tabpanel"
        aria-labelledby="v-pills-patient-tab"
      >
        <h6 className="border-bottom border-top p-3">Provider Information</h6>

        <form className="row g-4 p-3">
          <div className="row g-3">
            <div className="col-lg-4 col-sm-4">
              <InputDropdown
                options={providerProfile}
                className="form-select form-select-sm"
                onSelect={handleChange}
                name="providerProfile"
                value={patientFormData.providerProfile}
                error={errors.providerProfile}
                label="Provider Specialty"
              />
            </div>
            {isShowSpecialtyProvider && (
              <div className="col-lg-4 col-sm-4">
                <SearchDropdown
                  onSelect={(option) => {
                    setPatientFormData({
                      ...patientFormData,
                      specialtyProvider: option.value,
                    });
                  }}
                  searchCallback={updateSpecialtyProviderList}
                  placeholder="Specialty Provider"
                  label="Specialty provider"
                  searchField
                  options={specialtyProviderList}
                  displayValue={displayValueSpecialtyProv}
                  disabled={viewPermissionOnlyFlag}
                  error={errors.specialtyProvider}
                  required
                />
              </div>
            )}
            <div className="col-lg-3 col-sm-3  d-flex flex-column align-items-center justify-content-center pt-4">
              <Button
                className="btn btn-primary btn-sm"
                onClick={handleAddSpeciality}
                variant="outlined"
                label={
                  editIndex !== null ? "Update Specialty" : "Add Specialty"
                }
                hidden={!isShowAddSpecialtyProvider}
              />
            </div>
            {editIndex !== null && (
              <div className="col-lg-1 col-sm-1  d-flex flex-column align-items-center justify-content-center pt-4">
                <Button
                  className="btn btn-primary btn-sm"
                  onClick={handleCancelUpdate}
                  variant="outlined"
                  label="Cancel"
                  hidden={!isShowAddSpecialtyProvider}
                />
              </div>
            )}
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Provider Specialty</th>
                  <th scope="col">Specialty Provider</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {providerSpecialities.length === 0 ? (
                  <tr>
                    <td colSpan={3}>No data found</td>
                  </tr>
                ) : (
                  providerSpecialities.map((speciality, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={index}>
                      <td>{speciality.providerSpecialityLabel}</td>
                      <td>{speciality.specialityProviderLabel}</td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-sm btn-secondary me-1"
                          onClick={() => handleEditSpeciality(index)}
                          aria-label="edit"
                          disabled={
                            viewPermissionOnlyFlag || disabledButtons !== null
                          }
                        >
                          <i className="mdi mdi-pencil fs-6" />
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-danger"
                          onClick={() => handleDeleteSpeciality(index)}
                          aria-label="delete"
                          disabled={
                            viewPermissionOnlyFlag || disabledButtons !== null
                          }
                        >
                          <i className="mdi mdi-delete fs-6" />
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="col-lg-4 col-sm-4">
            <SearchDropdown
              onSelect={(option) => {
                setPatientFormData({
                  ...patientFormData,
                  pcpName: option.value,
                });
              }}
              label="Primary Care Provider"
              searchCallback={updatePcpList}
              searchField
              options={pcpList}
              displayValue={displayValueForPcp}
              disabled={viewPermissionOnlyFlag}
              error={errors.pcpName}
              required
            />
          </div>

          <div className="col-lg-12 col-sm-12">
            <Textarea
              label="Notes"
              className="form-control form-control-sm"
              placeholder="Text"
              onChange={handleChange}
              id="patientNotes"
              name="patientNotes"
              value={patientFormData.patientNotes}
              disabled={viewPermissionOnlyFlag}
              error={errors.patientNotes}
            />
          </div>

          <div className=" col-12 ">
            <div className="card rounded-0 bg-light p-2">
              <p className="text-decoration-underline"> Inactive/Deceased</p>
              <div className="row g-3">
                <div className="col-lg-6 col-sm-6">
                  <InputDropdown
                    label="Patient Status"
                    options={patientStatus}
                    className="form-select form-select-sm"
                    onSelect={handleChange}
                    name="patientStatus"
                    value={patientFormData.patientStatus}
                    disabled={viewPermissionOnlyFlag}
                    error={errors.patientStatus}
                  />
                </div>
                <div className="col-lg-6 col-sm-6">
                  <Input
                    label="Patient as of Date"
                    type="date"
                    id="date"
                    name="date"
                    value={patientFormData.date}
                    onChange={handleChange}
                    placeholder="Patient as of Date"
                    className="form-control form-control-sm"
                    disabled={viewPermissionOnlyFlag}
                    error={errors.date}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-between mt-5">
            <div>
              {!editMode && (
                <Button
                  className="btn btn-danger btn-sm"
                  onClick={handleClear}
                  variant="outlined"
                  label="Clear"
                  disabled={viewPermissionOnlyFlag}
                />
              )}
            </div>
            <div>
              <Button
                onClick={handleNext}
                variant="outlined"
                className="btn btn-primary btn-sm px-4"
                label="Save & Continue"
                disabled={viewPermissionOnlyFlag}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
