import { ValidationRules } from "../../../../services/utils/validateDataAsPerRules";

export const defaultFormValues = {
  title: "",
  firstName: "",
  lastName: "",
  organization: "",
  organizationName: "",
  address1: "",
  address2: "",
  zipCode: "",
  country: "",
  state: "",
  city: "",
  officeNumber: "",
  otherNumber: "",
  fax: "",
  email: "",
  speciality: "",
  practice: "",
  taxonomy: "",
  npi: "",
  upin: "",
  providerTypeId: "",
};

export const validationRules: ValidationRules = {
  title: {
    required: true,
    dataType: "text",
    characterLimit: 10,
    displayName: "Title",
  },
  firstName: {
    required: true,
    dataType: "text",
    characterLimit: 50,
    displayName: "First Name",
  },
  lastName: {
    required: true,
    dataType: "text",
    characterLimit: 50,
    displayName: "Last Name",
  },

  address1: {
    required: true,
    dataType: "any",
    characterLimit: 50,
    displayName: "Address 1",
  },
  address2: {
    required: false,
    dataType: "any",
    characterLimit: 50,
    displayName: "Address 2",
  },
  zipCode: {
    required: true,
    dataType: "number",
    characterLimit: 50,
    displayName: "Zip Code",
  },
  country: {
    required: true,
    dataType: "text",
    characterLimit: 50,
    displayName: "Country",
  },
  state: {
    required: true,
    dataType: "text",
    characterLimit: 50,
    displayName: "State",
  },
  city: {
    required: true,
    dataType: "text",
    characterLimit: 50,
    displayName: "City",
  },
  officeNumber: {
    required: false,
    dataType: "number",
    characterLimit: 10,
    minCharacterLimit: 10,
    displayName: "Office Number",
  },
  organizationName: {
    required: false,
    dataType: "any",
    characterLimit: 250,
    displayName: "Organization Name",
  },
  otherNumber: {
    required: false,
    dataType: "number",
    characterLimit: 10,
    displayName: "Other Number",
  },
  fax: {
    required: false,
    dataType: "number",
    characterLimit: 10,
    displayName: "Fax",
  },
  email: {
    required: false,
    dataType: "email",
    characterLimit: 150,
    displayName: "Email",
  },
  speciality: {
    required: false,
    dataType: "textOrNumber",
    characterLimit: 50,
    displayName: "Specialty",
  },
  practice: {
    required: false,
    dataType: "textOrNumber",
    characterLimit: 50,
    displayName: "Practice",
  },
  taxonomy: {
    required: false,
    dataType: "textOrNumber",
    characterLimit: 50,
    displayName: "Taxonomy",
  },
  npi: {
    required: false,
    dataType: "textOrNumber",
    characterLimit: 50,
    displayName: "NPI",
  },
  upin: {
    required: false,
    dataType: "textOrNumber",
    characterLimit: 50,
    displayName: "UPIN",
  },
  providerTypeId: {
    required: true,
    dataType: "any",
    characterLimit: 50,
    displayName: "Provider Type",
  },
};
