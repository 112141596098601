import { isFutureDate } from "./validateDataAsPerRules";

export const calculateAge = (dob: string): string => {
  const today = new Date();
  const birthDate = new Date(dob);
  const isFuture = isFutureDate(birthDate);
  let years = today.getFullYear() - birthDate.getFullYear();
  let months = today.getMonth() - birthDate.getMonth();
  if (isFuture) {
    return "0 Months";
  }
  if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
    years -= 1;
    months += 12;
  }

  if (years < 1) {
    return months === 1 ? `${months} month` : `${months} months`;
  }
  return years === 1 ? `${years} year` : `${years} years`;
};
