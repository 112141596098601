import { ValidationRules } from "../../../../../services/utils/validateDataAsPerRules";

export const validationRules: ValidationRules = {
  name: {
    required: false,
    dataType: "text",
    characterLimit: 100,
    displayName: "Name",
  },
  contactNumber: {
    required: false,
    dataType: "number",
    characterLimit: 10,
    minCharacterLimit: 10,
    displayName: "Contact Number",
  },
  email: {
    required: false,
    dataType: "email",
    characterLimit: 100,
    displayName: "Email",
  },
  relationship: {
    required: false,
    dataType: "text",
    displayName: "Relationship",
  },
};
