export interface ServiceResponse {
  status: boolean;
  code?: number;
  message?: string;
  details?: string;
}

export function generateErrorResponse(
  message?: string,
  code?: number,
  details?: string,
): ServiceResponse {
  return {
    status: false,
    code,
    message,
    details,
  };
}
