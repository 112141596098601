import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Input } from "../../../atom/Input/Input";
import { Button } from "../../../atom/Buttons/Button";
import { InputDropdown } from "../../../atom/InputDropDown/InputDropDown";
import {
  createCarePlanCareGiver,
  getCareGiverForPatient,
  updateCarePlanCareGiver,
} from "../../../../services/carePlan/carePlan";
import { showToastMessage } from "../../../../utils/showToastMessage/showToastMessage";
import { TOAST_TYPE_OPTIONS } from "../../../../constants/variables";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../../services/utils/validateDataAsPerRules";
import { validationRules } from "./helperFunctions/constants";
import { useRelationshipDropdownOptions } from "./helperFunctions/useRelationshipDropdownOptions";

export const defaultFormValues = {
  name: "",
  contactNumber: "",
  email: "",
  relationship: "",
};
interface CareGiverDetailsProps {
  isViewMode?: boolean;
}

export function CareGiverDetails({ isViewMode }: CareGiverDetailsProps) {
  const activeCarePlan = useSelector((state: any) => state.activeCarePlan);
  const activePatient = useSelector((state: any) => state.activePatient);
  const [editFlag, setEditFlag] = useState(false);
  const [formData, setFormData] = useState({
    values: defaultFormValues,
    errors: defaultFormValues,
  });
  const { relationshipDropDownOptions } = useRelationshipDropdownOptions();

  const handleReset = () => {
    setFormData({
      values: defaultFormValues,
      errors: defaultFormValues,
    });
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;

    const fieldError = validateFieldAsPerRules({
      rules: validationRules,
      fieldName: name,
      value,
    });

    setFormData({
      values: {
        ...formData.values,
        [name]: value,
      },
      errors: {
        ...formData.errors,
        [name]: fieldError,
      },
    });
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formErrors = validateDataAsPerRules({
      rules: validationRules,
      formData: formData.values,
    });

    if (formErrors) {
      setFormData({
        ...formData,
        errors: formErrors,
      });
    }
    const { carePlanId } = activeCarePlan.carePlanDetails;
    if (editFlag) {
      const updateCarePlanResponse = await updateCarePlanCareGiver({
        formData: formData.values,
        carePlanId,
        patientId: activePatient.patientDetails.id,
      });

      if (updateCarePlanResponse && updateCarePlanResponse.status) {
        showToastMessage(
          updateCarePlanResponse.message,
          TOAST_TYPE_OPTIONS.SUCCESS,
        );
      } else {
        showToastMessage(
          updateCarePlanResponse.message,
          TOAST_TYPE_OPTIONS.ERROR,
        );
      }
    } else {
      const createCarePlanAPIResponse = await createCarePlanCareGiver({
        formData: formData.values,
        carePlanId,
        patientId: activePatient.patientDetails.id,
      });
      if (createCarePlanAPIResponse.status) {
        showToastMessage(
          createCarePlanAPIResponse.message,
          TOAST_TYPE_OPTIONS.SUCCESS,
        );
        setFormData({
          values: {
            name: createCarePlanAPIResponse.data.careGiverName,
            relationship: createCarePlanAPIResponse.data.careGiverelationShip,
            email: createCarePlanAPIResponse.data.email,
            contactNumber:
              createCarePlanAPIResponse.data.careGiverContactNumber,
          },
          errors: defaultFormValues,
        });
        setEditFlag(true);
      } else {
        showToastMessage(
          createCarePlanAPIResponse.message,
          TOAST_TYPE_OPTIONS.ERROR,
        );
      }
    }
  };

  const fetchData = async () => {
    const { carePlanId } = activeCarePlan.carePlanDetails;
    const careGiverResponse = await getCareGiverForPatient({
      carePlanId,
      patientId: activePatient.patientDetails.id,
    });
    if (
      careGiverResponse.status &&
      careGiverResponse.data.careGiverName &&
      careGiverResponse.data.careGiverName !== null
    ) {
      setFormData({
        values: {
          name: careGiverResponse.data.careGiverName,
          relationship: careGiverResponse.data.careGiverelationShip,
          email: careGiverResponse.data.email,
          contactNumber: careGiverResponse.data.careGiverContactNumber,
        },
        errors: defaultFormValues,
      });
      setEditFlag(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="accordion-collapse">
      <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-lg-6 col-sm-6">
          <Input
            className="form-control form-control-sm "
            placeholder="Name"
            label={validationRules.name.displayName}
            required={validationRules.name.required}
            onChange={handleChange}
            value={formData.values.name}
            error={formData.errors.name}
            name="name"
            disabled={isViewMode}
          />
        </div>
        <div className="col-lg-6 col-sm-6">
          <InputDropdown
            options={relationshipDropDownOptions}
            onSelect={handleChange}
            className="form-select form-select-sm"
            aria-label="Default select example"
            name="relationship"
            value={formData.values.relationship}
            error={formData.errors.relationship}
            label={validationRules.relationship.displayName}
            required={validationRules.relationship.required}
            disabled={isViewMode}
          />
        </div>

        <div className="col-lg-6 col-sm-6">
          <Input
            type="text"
            className="form-control form-control-sm "
            placeholder="xyz@gmail.com"
            name="email"
            onChange={handleChange}
            value={formData.values.email}
            error={formData.errors.email}
            label={validationRules.email.displayName}
            required={validationRules.email.required}
            disabled={isViewMode}
          />
        </div>
        <div className="col-lg-6 col-sm-6">
          <Input
            type="number"
            className="form-control form-control-sm"
            placeholder="xxx-xxx-xxxx"
            onChange={handleChange}
            value={formData.values.contactNumber}
            name="contactNumber"
            error={formData.errors.contactNumber}
            label={validationRules.contactNumber.displayName}
            required={validationRules.contactNumber.required}
            disabled={isViewMode}
          />
        </div>
        <div className="col-12 d-flex justify-content-between mt-5">
          {editFlag || isViewMode ? (
            <div />
          ) : (
            <Button
              className="btn btn-danger btn-sm"
              type="reset"
              onClick={handleReset}
            >
              Clear
            </Button>
          )}
          {isViewMode ? (
            <div />
          ) : (
            <Button
              type="submit"
              variant="outlined"
              className="btn btn-primary btn-sm px-4"
              label={editFlag ? "Update & continue" : "Save & Continue"}
            />
          )}
        </div>
      </form>
    </div>
  );
}
