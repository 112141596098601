import {
  getSearchFilter,
  getSearchProviderSpecaltyFilter,
  getSearchSpecaltyProviderByIdAPI,
} from "../../dataLayer/searchFilter/searchFilter";

export async function getSearchForPcpValues(fieldName: string): Promise<any> {
  try {
    if (fieldName === "") {
      return [];
    }
    const response = await getSearchFilter(fieldName);
    if (response.isSuccess) {
      const options = response.dataList.map(
        (item: {
          firstName: string;
          lastName: string;
          title: number;
          id: string;
        }) => ({
          label: `${item.title} ${item.firstName} ${item.lastName}`,
          value: item.id,
        }),
      );
      return options;
    }
    return [];
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching PCP values.",
      details: error.message,
    };
  }
}

export async function getSearchSpecaltyProviderValues(
  fieldName: string,
): Promise<any> {
  try {
    if (fieldName === "") {
      return [];
    }
    const response = await getSearchProviderSpecaltyFilter(fieldName);
    if (response.isSuccess) {
      const options = response.dataList.map(
        (item: {
          firstName: string;
          lastName: string;
          title: number;
          id: string;
        }) => ({
          label: `${item.title} ${item.firstName} ${item.lastName}`,
          value: item.id,
        }),
      );
      return options;
    }
    return [];
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching PCP values.",
      details: error.message,
    };
  }
}
export async function getSearchSpecaltyProviderById(
  specialityProviderId: string | number,
): Promise<any> {
  if (!specialityProviderId) {
    return {
      status: false,
      code: 500,
      message: "Speciality ProviderId is required.",
      details: "Speciality ProviderId is required.",
    };
  }

  try {
    const response =
      await getSearchSpecaltyProviderByIdAPI(specialityProviderId);
    if (response.isSuccess) {
      return response.data;
    }
    return [];
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching Speciality Provider values.",
      details: error.message,
    };
  }
}
