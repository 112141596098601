import {
  createNewForm,
  deleteFormAPI,
  getAllForms,
  getFormByIdAPI,
  getFormFileOrImgByIdAPI,
  updateExitingForm,
} from "../../dataLayer/formsAndIRA/forms";

export interface CreateFormRecord {
  files?: File;
  id?: number;
  patientId: number | string;
  categoryId: number | string;
  sourceId: number | string;
  dateOfPerformTest: string;
  uploadDocument: string;
  dateOfUploadDocument: string;
  provider: string;
}
export async function getAllFormsServices({
  paginationDataAsParameter,
  patientId,
}: {
  paginationDataAsParameter: any;
  patientId: string;
}): Promise<any> {
  try {
    const allForms = await getAllForms({
      paginationDataAsParameter,
      patientId,
    });
    if (allForms.status) {
      return allForms.data;
    }
    return allForms;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all Forms.",
      details: error.message,
    };
  }
}

export async function createForm(formData: CreateFormRecord): Promise<any> {
  try {
    const formDataRequest = new FormData();
    if (formData.files) {
      formDataRequest.append("formFile", formData.files);
    } else {
      formDataRequest.append("formFile", "");
    }
    delete formData.files;
    formDataRequest.append(
      "formData",
      JSON.stringify({
        ...formData,
      }),
    );
    const newUser = await createNewForm(formDataRequest);
    return newUser;
  } catch (error: any) {
    console.error(error);
    return {
      status: false,
      code: 500,
      message: "Error creating form.",
      details: error.message,
    };
  }
}

export async function updateForm(
  formData: CreateFormRecord,
  formId: string,
): Promise<any> {
  try {
    const formDataRequest = new FormData();
    if (formData.files) {
      formDataRequest.append("formFile", formData.files);
    } else {
      formDataRequest.append("formFile", "");
    }
    delete formData.files;
    formDataRequest.append(
      "formData",
      JSON.stringify({
        ...formData,
      }),
    );
    const newUser = await updateExitingForm(formDataRequest, formId);
    return newUser;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error updating form result.",
      details: error.message,
    };
  }
}

export async function deleteFormService(labId: string | number): Promise<any> {
  if (!labId) {
    return {
      status: false,
      code: 500,
      message: "Form ID is required.",
      details: "Form ID is required.",
    };
  }

  try {
    const response = await deleteFormAPI(labId);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting Form value.",
      details: error.message,
    };
  }
}

export async function getFormFileImageByIdService(
  labId: string | number | undefined,
): Promise<any> {
  try {
    if (labId) {
      const LoadImgOrFileUrl = await getFormFileOrImgByIdAPI(labId);
      return LoadImgOrFileUrl;
    }
    return false;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all users.",
      details: error.message,
    };
  }
}

export async function getFormByIdService(labId: string | number): Promise<any> {
  if (!labId) {
    return {
      status: false,
      code: 500,
      message: "Lab ID is required.",
      details: "Lab ID is required.",
    };
  }

  try {
    const response = await getFormByIdAPI(labId);
    const formDataImages = await getFormFileImageByIdService(
      labId,
    );
    if (!response || !response.data) {
      return {
        status: false,
        code: 500,
        message: "Form data not found.",
        details: "Form data not found.",
      };
    }
    const formData: Record<any, any> = response;

    if (formDataImages) {
      formData.data.fileSrc = formDataImages;
    }
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching form value.",
      details: error.message,
    };
  }
}

