import { getOrganizationDetailsAPI } from "../../dataLayer/referrals/referrals";
import { getSearchFilterForOrganization } from "../../dataLayer/organization/organization";

export async function getSearchForOrganizationValues(
  FieldName: string,
): Promise<any> {
  try {
    const response = await getSearchFilterForOrganization(FieldName);
    if (response.isSuccess) {
      const options = response.dataList.map(
        (item: { organizationName: string; id: string }) => ({
          label: `${item.organizationName}`,
          value: item.id,
        }),
      );
      return options;
    }
    return [];
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching Organization values.",
      details: error.message,
    };
  }
}

export async function getOrganizationInfoService(
  organizationId: string | number,
): Promise<any> {
  if (!organizationId) {
    return {
      status: false,
      code: 500,
      message: "Error fetching Organization data.",
      details: "Organization ID is required.",
    };
  }

  try {
    const response = await getOrganizationDetailsAPI(organizationId);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting PCP value.",
      details: error.message,
    };
  }
}
