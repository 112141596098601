import Logger from "../../utils/Logger";
import {
  createNewTaskAPI,
  deleteTaskAPI,
  filterTasksAPI,
  getAllTasksAPI,
  getTaskCount,
  getTaskDetails,
  updateExistingTaskAPI,
} from "../../dataLayer/tasks/tasks";
import { formatDate } from "../utils/formatDate";

export async function createTask(formData: any): Promise<any> {
  try {
    const requestData = {
      ...formData,
      details: formData.details,
      assignTo: formData.assignTo,
      dob: formatDate(formData.dateOfBirth),
      reminderDate: formatDate(formData.reminderDate),
      taskTypeId: formData.taskTypeId,
      patientName: formData.patientName,
      authorId: formData.authorId,
      patientId: formData.patientId,
    };
    Logger.logInfo("creating new task", { data: requestData });
    const createTaskResponse = await createNewTaskAPI(requestData);
    return createTaskResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating task.",
      details: error.message,
    };
  }
}

export async function updateTaskService(
  formData: any,
  id: string,
): Promise<any> {
  try {
    const requestData = {
      ...formData,
      details: formData.details,
      assignTo: formData.assignTo,
      dob: formatDate(formData.dateOfBirth),
      reminderDate: formatDate(formData.reminderDate),
      taskTypeId: formData.taskTypeId,
      patientName: formData.patientName,
      status: formData.status,
      patientId: formData.patientId,
    };
    Logger.logInfo("Updating task", { data: { requestData, id } });
    const createTaskResponse = await updateExistingTaskAPI(requestData, id);
    return createTaskResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error creating task.",
      details: error.message,
    };
  }
}

export async function getAllTasks({
  paginationDataAsParameter,
}: {
  paginationDataAsParameter: any;
}): Promise<any> {
  try {
    const allMemos = await getAllTasksAPI({
      paginationDataAsParameter,
    });
    return allMemos;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all memos.",
      details: error.message,
    };
  }
}

export async function getFilterTasks({
  paginationDataAsParameter,
  filters,
}: {
  paginationDataAsParameter: any;
  filters: any;
}): Promise<any> {
  try {
    const allMemos = await filterTasksAPI({
      paginationDataAsParameter,
      filters,
    });
    return allMemos;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all memos.",
      details: error.message,
    };
  }
}

export async function deleteTaskService(taskId: string | number): Promise<any> {
  if (!taskId) {
    return {
      status: false,
      code: 500,
      message: "task ID is required.",
      details: "task ID is required.",
    };
  }

  try {
    Logger.logInfo("Deleting task", { data: { taskId } });
    const response = await deleteTaskAPI(taskId);
    return response;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error deleting task value.",
      details: error.message,
    };
  }
}

export async function getTaskDetailsWithId({
  taskId,
}: {
  taskId: string | number;
}): Promise<any> {
  try {
    const taskDetails = await getTaskDetails(taskId);
    return taskDetails;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all memos.",
      details: error.message,
    };
  }
}
//

export async function getTaskCountForUser(): Promise<any> {
  try {
    const taskCountResponse = await getTaskCount();
    return taskCountResponse;
  } catch (error: any) {
    return {
      status: false,
      code: 500,
      message: "Error fetching all memos.",
      details: error.message,
    };
  }
}
