import { toast, ToastOptions } from "react-toastify";
import { TOAST_TYPE_OPTIONS } from "../../constants/variables";

export const showToastMessage = (
  messageAsParameter: string | undefined,
  type: string = TOAST_TYPE_OPTIONS.SUCCESS,
  options?: ToastOptions,
) => {
  let message = messageAsParameter;
  if (messageAsParameter === null) {
    message = "Something went wrong..";
  }
  if (type === TOAST_TYPE_OPTIONS.ERROR) {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } else if (type === TOAST_TYPE_OPTIONS.SUCCESS) {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } else {
    toast.info(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      ...options,
    });
  }
};
