import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "../../../atom/Buttons/Button";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
  ValidationRules,
} from "../../../../services/utils/validateDataAsPerRules";
import { Textarea } from "../../../atom/Textarea/Textarea";
import {
  createChiefComplaintCaseNotesService,
  getCaseNoteDetails,
} from "../../../../services/caseNotes/caseNotes";
import { TOAST_TYPE_OPTIONS } from "../../../../constants/variables";
import { showToastMessage } from "../../../../utils/showToastMessage/showToastMessage";
import { FullScreenLoader } from "../../../atom/FullScreenLoader/FullScreenLoader";

const validationRules: ValidationRules = {
  chiefComplaints: {
    required: false,
    dataType: "any",
    displayName: "",
    characterLimit: 4000,
  },
};

interface ChiefComplaintsProps {
  isViewMode: boolean;
}
export function ChiefComplaints({ isViewMode }: ChiefComplaintsProps) {
  const caseNotesDetails = useSelector((state: any) => state.activeCaseNotes);
  const caseNoteId = caseNotesDetails?.caseNotesDetails?.caseNoteId;
  const [editFlag, setEditFlag] = useState(false);
  const [fullScreenLoaderFlag, setFullScreenLoaderFlag] = useState(false);
  const [formData, setFormData] = useState({
    values: {
      chiefComplaints: "",
    },
    errors: {
      chiefComplaints: "",
    },
  });

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;

    const fieldError = validateFieldAsPerRules({
      rules: validationRules,
      fieldName: name,
      value,
    });

    setFormData({
      values: { ...formData.values, [name]: value },
      errors: { ...formData.errors, [name]: fieldError },
    });
  };

  const fetchData = async () => {
    setFullScreenLoaderFlag(true);
    if (caseNoteId) {
      const caseNoteDetailsData = await getCaseNoteDetails(caseNoteId);
      if (caseNoteDetailsData.isSuccess) {
        const chiefComplaints = caseNoteDetailsData.data.chiefComplaints || "";
        if (chiefComplaints) {
          setFormData((prevData) => ({
            values: { ...prevData.values, chiefComplaints },
            errors: { ...prevData.errors, chiefComplaints: "" },
          }));
          setEditFlag(true);
        }
      }
    }
    setFullScreenLoaderFlag(false);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formErrors = validateDataAsPerRules({
      formData: formData.values,
      rules: validationRules,
    });

    if (Object.keys(formErrors).length) {
      setFormData({ ...formData, errors: formErrors });
      return;
    }
    const requestObject = {
      ...formData.values,
      caseNoteDetailId: caseNoteId,
    };
    if (caseNoteId) {
      const createNewChiefComplaintCaseNoteResponse =
        await createChiefComplaintCaseNotesService(requestObject, editFlag);

      if (createNewChiefComplaintCaseNoteResponse.status) {
        showToastMessage(
          createNewChiefComplaintCaseNoteResponse.message,
          TOAST_TYPE_OPTIONS.SUCCESS,
        );
        fetchData();
      } else {
        showToastMessage(
          createNewChiefComplaintCaseNoteResponse.message,
          TOAST_TYPE_OPTIONS.ERROR,
        );
      }
    }
  };

  const handleReset = () => {
    setFormData({
      values: {
        chiefComplaints: "",
      },
      errors: {
        chiefComplaints: "",
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="accordion-collapse">
      <FullScreenLoader show={fullScreenLoaderFlag} />
      <div className="accordion">
        <form className="row g-3" onSubmit={handleSubmit}>
          <div className="col-lg-12 col-sm-12">
            <Textarea
              className="form-control form-control-sm "
              placeholder="text"
              rows={3}
              value={formData.values.chiefComplaints}
              label={validationRules.chiefComplaints.displayName}
              error={formData.errors.chiefComplaints}
              onChange={handleChange}
              name="chiefComplaints"
              required={validationRules.chiefComplaints.required}
              disabled={isViewMode}
            />
          </div>
          <div className="col-12 d-flex justify-content-between mt-5">
            {editFlag || isViewMode ? (
              <div />
            ) : (
              <Button
                className="btn btn-danger btn-sm"
                type="reset"
                onClick={handleReset}
                label="Clear"
              />
            )}
            {isViewMode ? (
              <div />
            ) : (
              <Button
                type="submit"
                variant="outlined"
                className="btn btn-primary btn-sm px-4"
                label={editFlag ? "Update" : "Save & Continue"}
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
