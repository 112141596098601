import React from "react";
import { Image } from "../../atom/Images/Image";
import { Card } from "../../atom/Cards/Cards";

export function ErrorUI() {
  return (
    <div className="link-expired-message-section">
      <Image
        src="/assets/images/login-bkg.jpg"
        alt="Image Alt Text"
        className="Login-background-image"
      />
      <Card className="w-80 card">
        <div className="card-body p-4">
          <div className="d-flex justify-content-end">
            <img
              src="/assets/images/MDTel-logo.png"
              alt="MD Tel"
              title="MD Tel"
            />
          </div>

          <div className="row">
            <div className="col-2 ms-5 pe-0">
              <img
                src="/assets/images/caution.png"
                alt="caution-icon"
                title="caution-icon"
                className="w-100"
              />
            </div>
            <div className="col-6 ps-0 mt-4">
              <h1 className="fw-bold text-muted mt-4 ms-3">
                Oops!
                <br />
                Something went wrong
              </h1>
            </div>
          </div>

          <div className="row ms-5 mb-3 mt-3">
            <h5>
              Hello! you took too long to enable your account, Please request a
              new link
            </h5>
          </div>
        </div>
      </Card>
    </div>
  );
}
