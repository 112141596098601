import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ModalComponent } from "../../molecule/Modal/ModalComponent";
import { Input } from "../../atom/Input/Input";
import { InputDropdown } from "../../atom/InputDropDown/InputDropDown";
import {
  getAllCountryAndStates,
  getOptionsForDropdown,
  getPatientsCaseManagerList,
} from "../../../services/dropDownMenuOptions/dropDownMenuOptions";
import { SearchDropdown } from "../../atom/SearchDropdown/SearchDropdown";
import {
  getSearchForPcpValues,
  getSearchSpecaltyProviderById,
  getSearchSpecaltyProviderValues,
} from "../../../services/searchFilter/searchFilter";
import { calculateAge } from "../../../services/utils/calculateAge";
import {
  validateDataAsPerRules,
  validateFieldAsPerRules,
} from "../../../services/utils/validateDataAsPerRules";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import {
  CASE_MANAGER_ROLE_NAME,
  TOAST_TYPE_OPTIONS,
} from "../../../constants/variables";
import { allKeysEmpty } from "../../../services/utils/allKeyEmpty";
import { createQuickPatientInfo } from "../../../services/patients/patients";
import { FullScreenLoader } from "../../atom/FullScreenLoader/FullScreenLoader";
import { Button } from "../../atom/Buttons/Button";
import { validationRules } from "./helperFunction/constants";

interface PatientQuickAddModalProps {
  showModal: boolean;
  onClose: () => void;
}
interface ProviderSpecialtyOption {
  label: string;
  value: number;
}
export function PatientQuickAddModal({
  showModal,
  onClose,
}: PatientQuickAddModalProps) {
  const loginUserDetails = useSelector((state: any) => state.userDetails);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [specialtyProviderList, setSpecialtyProviderList] = useState([]);
  const [providerSpecialities, setProviderSpecialities] = useState<any[]>([]);
  const [isShowSpecialtyProvider, setIsShowSpecialtyProvider] = useState(false);
  const [displayValueSpecialtyProv, setdisplayValueSpecialtyProv] = useState(
    ""
  );
  const [isShowAddSpecialtyProvider, setIsShowAddSpecialtyProvider] = useState(
    false
  );
  const [formData, setFormData] = useState({
    userTitle: "",
    firstName: "",
    middleName: "",
    lastName: "",
    chartNumber: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    responsiblePartyName: "",
    preferredPhone: "",
    email: "",
    patientNotes: "",
    sex: "",
    genderIdentity: "",
    sexualOrientation: "",
    dateOfBirth: "",
    maritalStatus: "",
    financialClass: "",
    pcpName: "",
    providerSpeciality: "" as string,
    specialityProviderId: "" as string,
    employer: "",
    occupation: "",
    age: "",
    preferredLanguage: "",
    providerStatus: "",
    patientAsOfDate: "",
    caseManager: "",
  });
  const [errors, setErrors] = useState({
    userTitle: "",
    firstName: "",
    middleName: "",
    lastName: "",
    chartNumber: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    responsiblePartyName: "",
    preferredPhone: "",
    email: "",
    patientNotes: "",
    sex: "",
    genderIdentity: "",
    sexualOrientation: "",
    dateOfBirth: "",
    maritalStatus: "",
    financialClass: "",
    pcpName: "",
    providerSpeciality: "",
    specialityProviderId: "",
    employer: "",
    occupation: "",
    age: "",
    preferredLanguage: "",
    providerStatus: "",
    patientAsOfDate: "",
    caseManager: "",
  });

  const [showFullScreenLoaderStatus, setShowFullScreenLoaderStatus] = useState(
    false
  );

  const [userTitle, setUserTitle] = useState([]);
  const [sex, setSet] = useState([]);

  const [genderIdentity, setGenderIdentity] = useState([]);
  const [orientation, setOrientation] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [partyName, setPartyName] = useState([]);
  const [preferredLanguage, setPreferredLanguage] = useState([]);
  const [caseManager, setCaseManager] = useState([]);
  const [countryMasterData, setCountryMasterData] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateMasterData, setStateMasterData] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [patientStatus, setPatientStatus] = useState([]);
  const [cityMasterData, setCityMasterData] = useState([]);
  const [pcpList, setPcpList] = useState([]);
  const [cityList, setCityList] = useState<any>([]);
  const [zipCodeList, setZipCodeList] = useState([]);
  const [providerSpeciality, setproviderSpeciality] = useState([]);
  const [displayValueForPcp] = useState("");

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    const newErrors = validateFieldAsPerRules({
      rules: validationRules,
      displayFieldName: name,
      fieldName: name,
      value,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: newErrors,
    }));

    if (name === "providerSpeciality") {
      setdisplayValueSpecialtyProv("");
      if (!value) {
        setIsShowSpecialtyProvider(false);
        setErrors({ ...errors, [name]: "Please select a provider speciality" });
      } else {
        setdisplayValueSpecialtyProv("");
        setIsShowSpecialtyProvider(true);
        setErrors({ ...errors, [name]: "" });
      }
    }

    if (name === "dateOfBirth") {
      const age = calculateAge(value);
      setFormData((prevData) => ({
        ...prevData,
        age,
      }));
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCountryChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    if (!e.target.value || e.target.value === "") {
      setErrors({ ...errors, country: "Please select a country" });
    } else {
      setErrors({ ...errors, country: "" });
      setFormData({
        ...formData,
        country: e.target.value,
        zipCode: "",
        state: "",
        city: "",
      });
      const stateListMaster: any = countryMasterData.find(
        (country: any) => country.countryName === e.target.value
      );
      setStateMasterData(stateListMaster.states);
      if (stateListMaster) {
        const stateListOptions = stateListMaster.states.map(
          (state: { stateName: string }) => ({
            label: state.stateName,
            value: state.stateName,
          })
        );
        setStateList(stateListOptions);
      } else {
        setStateList([]);
      }
    }
  };

  const handleStateChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    if (!e.target.value || e.target.value === "") {
      setErrors({ ...errors, state: "Please select a state" });
    } else {
      setErrors({ ...errors, state: "" });
      setFormData({
        ...formData,
        state: e.target.value,
        city: "",
        zipCode: "",
      });
      const cityListMaster: any = stateMasterData.find(
        (state: any) => state.stateName === e.target.value
      );
      if (cityListMaster?.cities) {
        const cityListOptions = cityListMaster.cities.map(
          (city: { cityName: string }) => ({
            label: city.cityName,
            value: city.cityName,
          })
        );
        setCityMasterData(cityListMaster.cities);
        setCityList(cityListOptions);
      } else {
        setCityList([]);
      }
    }
  };
  const handleCityChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    if (!e.target.value || e.target.value === "") {
      setErrors({ ...errors, city: "Please select a city" });
    } else {
      setErrors({ ...errors, city: "" });
      const cityListMaster: any = cityMasterData.find(
        (cities: any) => cities.cityName === e.target.value
      );
      const zipCodeListOptions = cityListMaster.zipCodes.map(
        (zipCode: string) => ({
          label: zipCode,
          value: zipCode,
        })
      );

      setZipCodeList(zipCodeListOptions);
      setFormData({
        ...formData,
        city: e.target.value,
        zipCode: cityListMaster.zipCode,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setShowFullScreenLoaderStatus(true);
        const titleMaster = await getOptionsForDropdown("title");
        setUserTitle(titleMaster);

        const sexResp = await getOptionsForDropdown("sex");
        setSet(sexResp);

        const genderIdentityResp = await getOptionsForDropdown(
          "genderIdentity"
        );
        setGenderIdentity(genderIdentityResp);

        const orientationResp = await getOptionsForDropdown(
          "sexualOrientation"
        );
        setOrientation(orientationResp);

        const maritalStatusResp = await getOptionsForDropdown("maritalStatus");
        setMaritalStatus(maritalStatusResp);

        const partyNameResp = await getOptionsForDropdown(
          "ResponsiblePartyName"
        );
        setPartyName(partyNameResp);

        const preferredLanguageResp = await getOptionsForDropdown(
          "preferredLanguage"
        );
        setPreferredLanguage(preferredLanguageResp);

        const caseManagerList = await getPatientsCaseManagerList();
        setCaseManager(caseManagerList);

        const countryListResponse = await getAllCountryAndStates();
        setCountryMasterData(countryListResponse);

        const countryOptions = countryListResponse.reduce(
          (
            result: { label: string; value: string }[],
            item: { countryName: string }
          ) => {
            result.push({ label: item.countryName, value: item.countryName });
            return result;
          },
          []
        );
        setCountryList(countryOptions);
        const patientStatusList = await getOptionsForDropdown("patientStatus");
        setPatientStatus(patientStatusList);

        const providerSpecialityList = await getOptionsForDropdown(
          "providerSpecialty"
        );
        setproviderSpeciality(providerSpecialityList);
        setProviderSpecialities([]);
        setShowFullScreenLoaderStatus(false);

        if (
          loginUserDetails.userPermission.roleName === CASE_MANAGER_ROLE_NAME
        ) {
          setFormData({
            ...formData,
            caseManager: loginUserDetails.id,
          });
        }
      } catch (error) {
        console.error("Error fetching title options:", error);
      }
    };
    if (showModal) {
      fetchData();
    }
  }, [showModal]);

  const updatePcpList = async (value: string) => {
    const pcpValues = await getSearchForPcpValues(value);
    if (pcpValues) {
      setPcpList(pcpValues);
      setErrors({
        ...errors,
        pcpName: "",
      });
    } else {
      setErrors({
        ...errors,
        pcpName: "Please select Primary Care Provider",
      });
    }
  };

  const resetAllValues = () => {
    setFormData({
      userTitle: "",
      firstName: "",
      middleName: "",
      lastName: "",
      chartNumber: "",
      address1: "",
      address2: "",
      country: "",
      state: "",
      city: "",
      zipCode: "",
      responsiblePartyName: "",
      preferredPhone: "",
      email: "",
      patientNotes: "",
      sex: "",
      genderIdentity: "",
      sexualOrientation: "",
      dateOfBirth: "",
      maritalStatus: "",
      financialClass: "",
      pcpName: "",
      providerSpeciality: "",
      specialityProviderId: "",
      employer: "",
      occupation: "",
      age: "",
      preferredLanguage: "",
      providerStatus: "",
      patientAsOfDate: "",
      caseManager: "",
    });
    setErrors({
      userTitle: "",
      firstName: "",
      middleName: "",
      lastName: "",
      chartNumber: "",
      address1: "",
      address2: "",
      country: "",
      state: "",
      city: "",
      zipCode: "",
      responsiblePartyName: "",
      preferredPhone: "",
      email: "",
      patientNotes: "",
      sex: "",
      genderIdentity: "",
      sexualOrientation: "",
      dateOfBirth: "",
      maritalStatus: "",
      financialClass: "",
      pcpName: "",
      providerSpeciality: "",
      specialityProviderId: "",
      employer: "",
      occupation: "",
      age: "",
      preferredLanguage: "",
      providerStatus: "",
      patientAsOfDate: "",
      caseManager: "",
    });
  };

  const addPatientData = async () => {
    const formErrors = validateDataAsPerRules({
      formData,
      rules: validationRules,
    });
    setErrors({
      ...errors,
      ...formErrors,
    });
    if (Object.keys(formErrors).length > 0) {
      showToastMessage("Please fill correct value", TOAST_TYPE_OPTIONS.ERROR);
      return;
    }

    const ifAllEmpty = allKeysEmpty(formData);
    if (ifAllEmpty) {
      showToastMessage(
        "Please fill at least one field",
        TOAST_TYPE_OPTIONS.ERROR
      );
    }
    if (
      (formData.patientAsOfDate && !formData.providerStatus) ||
      (!formData.patientAsOfDate && formData.providerStatus)
    ) {
      showToastMessage(
        "Please fill both patient status and patient as of Date",
        TOAST_TYPE_OPTIONS.ERROR
      );
      return;
    }
    const apiResponse = await createQuickPatientInfo({
      ...formData,
      providerSpecialities,
    });
    if (apiResponse.status) {
      showToastMessage(apiResponse.message, TOAST_TYPE_OPTIONS.SUCCESS);
      resetAllValues();
      onClose();
    } else {
      showToastMessage(apiResponse.message, TOAST_TYPE_OPTIONS.ERROR);
    }
  };

  const handleAddSpeciality = async () => {
    const specialtyProv = await getSearchSpecaltyProviderById(
      formData.specialityProviderId
    );
    const providerProfileOptions: ProviderSpecialtyOption[] = providerSpeciality;

    const selectedProviderProfile = providerProfileOptions.find(
      (option: { value: number }) =>
        option.value === Number(formData.providerSpeciality)
    );
    const newSpeciality = {
      providerSpeciality: formData.providerSpeciality,
      providerSpecialityLabel: selectedProviderProfile
        ? selectedProviderProfile.label
        : "",
      specialityProviderId: formData.specialityProviderId,
      specialityProviderLabel: `${specialtyProv.title} ${specialtyProv.firstName} ${specialtyProv.lastName}`,
    };
    if (editIndex !== null) {
      const updatedSpecialities = [...providerSpecialities];
      updatedSpecialities[editIndex] = newSpeciality;
      setProviderSpecialities(updatedSpecialities);
      setEditIndex(null);
    } else {
      setProviderSpecialities([...providerSpecialities, newSpeciality]);
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      providerSpeciality: "",
      specialityProviderId: "",
    }));
    setdisplayValueSpecialtyProv("");
    setIsShowSpecialtyProvider(false);
    setIsShowAddSpecialtyProvider(false);
  };
  const handleEditSpeciality = (index: number) => {
    const specialityToEdit = providerSpecialities[index];
    setFormData({
      ...formData,
      providerSpeciality: specialityToEdit.providerSpeciality,
      specialityProviderId: specialityToEdit.specialityProviderId,
    });
    setdisplayValueSpecialtyProv(specialityToEdit.specialityProviderLabel);
    setEditIndex(index);
    setIsShowSpecialtyProvider(true);
    setIsShowAddSpecialtyProvider(true);
  };

  const handleDeleteSpeciality = (index: number) => {
    const updatedSpecialities = providerSpecialities.filter(
      (_, i) => i !== index
    );
    setProviderSpecialities(updatedSpecialities);
  };

  const updateSpecialtyProviderList = async (value: string) => {
    const specialtyProviderValues = await getSearchSpecaltyProviderValues(
      value
    );
    if (specialtyProviderValues) {
      setIsShowAddSpecialtyProvider(true);
      setSpecialtyProviderList(specialtyProviderValues);
      setErrors({
        ...errors,
        specialityProviderId: "",
      });
    } else {
      setErrors({
        ...errors,
        specialityProviderId: "Please select Primary Care Provider",
      });
    }
  };
  return (
    <ModalComponent
      showAddUserPopup={showModal}
      setShowAddUserPopup={onClose}
      buttonShow="Patient Quick Add"
      buttonLeft="clear"
      buttonRight="Ok"
      title="Patient Quick Add"
      className="text-end "
      size="lg"
      onSubmit={() => addPatientData()}
      closeAction={() => resetAllValues()}
    >
      <FullScreenLoader show={showFullScreenLoaderStatus} />
      <form className="row g-3">
        <div className="col-lg-3 col-sm-6">
          <InputDropdown
            options={userTitle}
            className="form-select form-select-sm"
            onSelect={handleChange}
            name="userTitle"
            value={formData.userTitle}
            label={validationRules.userTitle.displayName}
            required={validationRules.userTitle.required}
            error={errors.userTitle}
          />
        </div>
        <div className="col-lg-3 col-sm-6">
          <Input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="First Name"
            className="form-control form-control-sm"
            label={validationRules.firstName.displayName}
            required={validationRules.firstName.required}
            error={errors.firstName}
          />
        </div>
        <div className="col-lg-3 col-sm-6">
          <Input
            type="text"
            id="middleName"
            name="middleName"
            value={formData.middleName}
            onChange={handleChange}
            placeholder="Middle Name"
            className="form-control form-control-sm"
            label={validationRules.middleName.displayName}
            required={validationRules.middleName.required}
            error={errors.middleName}
          />
        </div>
        <div className="col-lg-3 col-sm-6">
          <Input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Last Name"
            className="form-control form-control-sm"
            label={validationRules.lastName.displayName}
            required={validationRules.lastName.required}
            error={errors.lastName}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <InputDropdown
            options={sex}
            className="form-select form-select-sm"
            onSelect={handleChange}
            name="sex"
            label={validationRules.sex.displayName}
            required={validationRules.sex.required}
            error={errors.sex}
            value={formData.sex}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <InputDropdown
            options={genderIdentity}
            className="form-select form-select-sm"
            onSelect={handleChange}
            name="genderIdentity"
            label={validationRules.genderIdentity.displayName}
            error={errors.genderIdentity}
            value={formData.genderIdentity}
            required={validationRules.genderIdentity.required}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <InputDropdown
            options={orientation}
            className="form-select form-select-sm"
            onSelect={handleChange}
            name="sexualOrientation"
            label={validationRules.sexualOrientation.displayName}
            error={errors.sexualOrientation}
            value={formData.sexualOrientation}
            required={validationRules.sexualOrientation.required}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <InputDropdown
            options={maritalStatus}
            className="form-select form-select-sm"
            onSelect={handleChange}
            name="maritalStatus"
            label={validationRules.maritalStatus.displayName}
            error={errors.maritalStatus}
            value={formData.maritalStatus}
            required={validationRules.maritalStatus.required}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <Input
            type="Date"
            id="dateOfBirth"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleChange}
            placeholder="dateOfBirth"
            className="form-control form-control-sm"
            max={new Date().toISOString().split("T")[0]}
            label={validationRules.dateOfBirth.displayName}
            error={errors.dateOfBirth}
            required={validationRules.dateOfBirth.required}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <Input
            type="text"
            id="age"
            name="age"
            value={formData.age}
            onChange={handleChange}
            placeholder="age"
            className="form-control form-control-sm"
            label={validationRules.age.displayName}
            error={errors.age}
            required={validationRules.age.required}
            disabled
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <InputDropdown
            options={preferredLanguage}
            className="form-select form-select-sm"
            onSelect={handleChange}
            name="preferredLanguage"
            label={validationRules.preferredLanguage.displayName}
            error={errors.preferredLanguage}
            value={formData.preferredLanguage}
            required={validationRules.preferredLanguage.required}
          />
        </div>

        <div className="col-lg-4 col-sm-6">
          <Input
            type="text"
            id="chartNumber"
            name="chartNumber"
            value={formData.chartNumber}
            onChange={handleChange}
            placeholder="Chart Number"
            className="form-control form-control-sm"
            label={validationRules.chartNumber.displayName}
            disabled
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <InputDropdown
            options={partyName}
            name="responsiblePartyName"
            value={formData.responsiblePartyName}
            onSelect={handleChange}
            className="form-control form-control-sm"
            label={validationRules.responsiblePartyName.displayName}
            required={validationRules.responsiblePartyName.required}
            error={errors.responsiblePartyName}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <Input
            type="text"
            id="occupation"
            name="occupation"
            value={formData.occupation}
            onChange={handleChange}
            placeholder="Occupation"
            className="form-control form-control-sm"
            label={validationRules.occupation.displayName}
            required={validationRules.occupation.required}
            error={errors.occupation}
          />
        </div>

        <div className="col-lg-4 col-sm-6">
          <Input
            type="text"
            id="address1"
            name="address1"
            value={formData.address1}
            onChange={handleChange}
            placeholder="Address 1"
            className="form-control form-control-sm"
            label={validationRules.address1.displayName}
            required={validationRules.address1.required}
            error={errors.address1}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <Input
            type="text"
            id="address2"
            name="address2"
            value={formData.address2}
            onChange={handleChange}
            placeholder="Address 2"
            className="form-control form-control-sm"
            label={validationRules.address2.displayName}
            error={errors.address2}
            required={validationRules.address2.required}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <InputDropdown
            options={countryList}
            className="form-select form-select-sm"
            name="country"
            onSelect={handleCountryChange}
            value={formData.country}
            label={validationRules.country.displayName}
            required={validationRules.country.required}
            error={errors.country}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <InputDropdown
            options={stateList}
            className="form-select form-select-sm"
            onSelect={handleStateChange}
            name="state"
            label={validationRules.state.displayName}
            required={validationRules.state.required}
            value={formData.state}
            error={errors.state}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <InputDropdown
            options={cityList}
            className="form-select form-select-sm"
            onSelect={handleCityChange}
            label={validationRules.city.displayName}
            name="city"
            required={validationRules.city.required}
            value={formData.city}
            error={errors.city}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <InputDropdown
            options={zipCodeList}
            className="form-select form-select-sm"
            onSelect={handleChange}
            name="zipCode"
            value={formData.zipCode}
            label={validationRules.zipCode.displayName}
            required={validationRules.zipCode.required}
            error={errors.zipCode}
          />
        </div>

        <div className="col-lg-4 col-sm-6">
          <Input
            type="number"
            id="preferredPhone"
            name="preferredPhone"
            value={formData.preferredPhone}
            onChange={handleChange}
            placeholder="Preferred Phone"
            className="form-control form-control-sm"
            label={validationRules.preferredPhone.displayName}
            required={validationRules.preferredPhone.required}
            error={errors.preferredPhone}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <Input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            className="form-control form-control-sm"
            label={validationRules.email.displayName}
            error={errors.email}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <Input
            type="text"
            id="patientNotes"
            name="patientNotes"
            value={formData.patientNotes}
            onChange={handleChange}
            placeholder="Patient Notes"
            className="form-control form-control-sm"
            label={validationRules.patientNotes.displayName}
            error={errors.patientNotes}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <InputDropdown
            options={patientStatus}
            className="form-select form-select-sm"
            onSelect={handleChange}
            name="providerStatus"
            label={validationRules.providerStatus.displayName}
            value={formData.providerStatus}
            error={errors.providerStatus}
          />
        </div>
        <div className="col-lg-4 col-sm-6">
          <Input
            type="Date"
            id="patientAsOfDate"
            name="patientAsOfDate"
            value={formData.patientAsOfDate}
            onChange={handleChange}
            placeholder="patientAsOfDate"
            className="form-control form-control-sm"
            max={new Date().toISOString().split("T")[0]}
            label={validationRules.patientAsOfDate.displayName}
            error={errors.patientAsOfDate}
            required={validationRules.patientAsOfDate.required}
          />
        </div>

        <div className="col-lg-4 col-sm-6">
          <SearchDropdown
            onSelect={(option) => {
              setFormData({
                ...formData,
                pcpName: option.value,
              });
            }}
            searchCallback={updatePcpList}
            searchField
            options={pcpList}
            displayValue={displayValueForPcp}
            label={validationRules.pcpName.displayName}
            required={validationRules.pcpName.required}
          />
        </div>

        <div className="col-lg-4 col-sm-6">
          <InputDropdown
            options={caseManager}
            className="form-select form-select-sm"
            onSelect={handleChange}
            name="caseManager"
            value={formData.caseManager}
            label={validationRules.caseManager.displayName}
            error={errors.caseManager}
            required={validationRules.caseManager.required}
          />
        </div>

        <div className="row g-3">
          <div className="col-lg-4 col-sm-4">
            <InputDropdown
              label={validationRules.providerSpeciality.displayName}
              options={providerSpeciality}
              className="form-select form-select-sm"
              onSelect={handleChange}
              name="providerSpeciality"
              value={formData.providerSpeciality}
              error={errors.providerSpeciality}
            />
          </div>
          {isShowSpecialtyProvider && (
            <div className="col-lg-4 col-sm-4">
              <SearchDropdown
                onSelect={(option) => {
                  setFormData({
                    ...formData,
                    specialityProviderId: option.value,
                  });
                }}
                label={validationRules.specialityProviderId.displayName}
                searchCallback={updateSpecialtyProviderList}
                placeholder="Specialty Provider"
                searchField
                options={specialtyProviderList}
                displayValue={displayValueSpecialtyProv}
                error={errors.specialityProviderId}
                required
              />
            </div>
          )}
          <div className="col-lg-3 col-sm-3  d-flex flex-column align-items-center justify-content-center pt-4">
            <Button
              className="btn btn-primary btn-sm"
              onClick={handleAddSpeciality}
              variant="outlined"
              label={editIndex !== null ? "Update Specialty" : "Add Specialty"}
              hidden={!isShowAddSpecialtyProvider}
            />
          </div>
          {editIndex !== null && (
            <div className="col-lg-1 col-sm-1  d-flex flex-column align-items-center justify-content-center pt-4">
              <Button
                className="btn btn-primary btn-sm"
                label="Cancel"
                onClick={() => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    providerSpeciality: "",
                    specialityProviderId: "",
                  }));
                  setdisplayValueSpecialtyProv("");
                  setIsShowAddSpecialtyProvider(false);
                  setEditIndex(null);
                }}
              />
            </div>
          )}
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Provider Specialty</th>
                <th scope="col">Specialty Provider</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {providerSpecialities.length === 0 ? (
                <tr>
                  <td colSpan={3}>No data found</td>
                </tr>
              ) : (
                providerSpecialities.map((speciality, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={index}>
                    <td>{speciality.providerSpecialityLabel}</td>
                    <td>{speciality.specialityProviderLabel}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-sm btn-secondary me-1"
                        onClick={() => handleEditSpeciality(index)}
                        aria-label="edit"
                        disabled={editIndex !== null}
                      >
                        <i className="mdi mdi-pencil fs-6" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-danger"
                        onClick={() => handleDeleteSpeciality(index)}
                        aria-label="delete"
                        disabled={editIndex !== null}
                      >
                        <i className="mdi mdi-delete fs-6" />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </form>
    </ModalComponent>
  );
}
