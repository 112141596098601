import { ValidationRules } from "../../../../services/utils/validateDataAsPerRules";

export const defaultFormValues = {
  title: "",
  firstName: "",
  lastName: "",
  organization: "",
  providerType: "",
  address1: "",
  address2: "",
  zipCode: "",
  country: "",
  state: "",
  city: "",
  officeNumber: "",
  email: "",
  fax: "",
  status: "",
  fromDate: "",
  endDate: "",
  speciality: "",
  practice: "",
  taxonomy: "",
  npi: "",
  upin: "",
  notes: "",
  contactNumber: "",
};

export const validationRules: ValidationRules = {
  title: {
    required: true,
    dataType: "text",
    characterLimit: 10,
    displayName: "Title1",
  },
  firstName: {
    required: true,
    dataType: "text",
    characterLimit: 50,
    displayName: "First Name",
  },
  lastName: {
    required: true,
    dataType: "text",
    characterLimit: 50,
    displayName: "Last Name",
  },
  organization: {
    required: true,
    dataType: "any",
    characterLimit: 50,
    displayName: "Organization",
  },
  providerType: {
    required: true,
    dataType: "any",
    characterLimit: 50,
    displayName: "Provider Type",
  },
  address1: {
    required: true,
    dataType: "any",
    characterLimit: 50,
    displayName: "Address1",
  },
  address2: {
    required: false,
    dataType: "any",
    characterLimit: 50,
    displayName: "Address2",
  },
  zipCode: {
    required: true,
    dataType: "number",
    characterLimit: 50,
    displayName: "Zip Code",
  },
  country: {
    required: true,
    dataType: "text",
    characterLimit: 50,
    displayName: "Country",
  },
  state: {
    required: true,
    dataType: "text",
    characterLimit: 50,
    displayName: "State",
  },
  city: {
    required: true,
    dataType: "text",
    characterLimit: 50,
    displayName: "City",
  },
  contactNumber: {
    required: false,
    dataType: "number",
    characterLimit: 10,
    minCharacterLimit: 10,
    displayName: "contact number",
  },

  fax: {
    required: false,
    dataType: "number",
    characterLimit: 10,
    displayName: "Fax",
  },
  email: {
    required: false,
    dataType: "email",
    characterLimit: 150,
    displayName: "Email",
  },
  speciality: {
    required: false,
    dataType: "textOrNumber",
    characterLimit: 50,
    displayName: "Speciality",
  },
  practice: {
    required: false,
    dataType: "textOrNumber",
    characterLimit: 50,
    displayName: "Practice",
  },
  taxonomy: {
    required: false,
    dataType: "textOrNumber",
    characterLimit: 50,
    displayName: "Taxonomy",
  },
  npi: {
    required: false,
    dataType: "textOrNumber",
    characterLimit: 50,
    displayName: "NPI",
  },
  upin: {
    required: false,
    dataType: "text",
    characterLimit: 50,
    displayName: "UPIN",
  },
  status: {
    required: false,
    dataType: "number",
    characterLimit: 50,
    displayName: "Status",
  },
  fromDate: {
    required: false,
    dataType: "date",
    characterLimit: 50,
    displayName: "From Date",
  },
  endDate: {
    required: false,
    dataType: "date",
    characterLimit: 50,
    displayName: "End Date",
  },
};
