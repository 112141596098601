import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "../../../components/atom/Cards/Cards";
import { VerticalTabs } from "../../../components/atom/VerticalTabs/VerticalTabs";
import { Table } from "../../../components/atom/Table/Table";
import { Button } from "../../../components/atom/Buttons/Button";
import { PatientDetails } from "./PatientDetails";
import { PatientContactInfo } from "./PatientContactInfo";
import { PatientProviderInfo } from "./PatientProviderInfo";
import { RaceAndEthnicity } from "./RaceAndEthnicity";
import { TOAST_TYPE_OPTIONS } from "../../../constants/variables";
import { showToastMessage } from "../../../utils/showToastMessage/showToastMessage";
import {
  deletingPatientInfo,
  getAllPatientsListService,
  getPatientDetailsFromId,
} from "../../../services/patients/patients";
import {
  clearActivePatient,
  enablePatientEdit,
  resetContactDetails,
  resetProviderDetails,
  resetRaceAndEthnicityDetails,
  setActivePatient,
  setContactDetails,
  setProviderDetails,
  setRaceAndEthnicityDetails,
} from "../../../redux/activePatient/activePatientSlice";
import { ProfileCard } from "../../../components/molecule/ProfileCard/ProfileCard";
import { Pagination } from "../../../components/atom/Pagination/Pagination";
import { isUserHaveOnlyViewPermission } from "../../../services/users/users";
import Logger from "../../../utils/Logger";

export function PatientInfo() {
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();
  const [enableTabKeys, setEnableTabKeys] = useState(["1"]);
  const [viewPermissionOnlyFlag, setViewPermissionOnlyFlag] = useState(false);
  const loginUserDetails = useSelector((state: any) => state.userDetails);

  const handleTabChange = () => {
    const tempActiveTab = parseInt(activeTab, 10);
    const tab = tempActiveTab + 1;
    setActiveTab(tab.toString());
    setEnableTabKeys(["1", "2", "3", "4"]);
  };
  const [paginationData, setPaginationData] = useState({
    pageNo: 0,
    totalPages: 0,
    totalElements: 0,
    pageSize: 10,
    sortingFieldName: "",
    sortingOrder: "",
  });
  const headersForTable: Record<string, string> = {
    chartNumber: "Chart Number",
    firstName: "First Name",
    lastName: "Last Name",
    dob: "Date Of Birth",
    email: "Email",
    action: "Action",
  };
  const sortingHeaderMap: Record<string, string> = {
    chartNumber: "ChartNumber",
    firstName: "FirstName",
    lastName: "LastName",
    dob: "DateOfBirth",
    email: "ContactInfoEmail",
  };

  const [showTable, setShowTable] = useState(true);
  const [patientsList, setPatientsList] = useState([]);

  const getPatientListData = async (newPaginationData?: any) => {
    try {
      const paginationDataAsParameter = newPaginationData || paginationData;
      const allUsers = await getAllPatientsListService({
        paginationDataAsParameter,
      });
      if (allUsers.status) {
        setPatientsList(allUsers.data.dataList);
        setPaginationData({
          ...newPaginationData,
          pageNo: allUsers.data.pageNo,
          totalPages: allUsers.data.totalPages,
          totalElements: allUsers.data.totalElements,
          pageSize: allUsers.data.pageSize,
        });
      }
    } catch (error) {
      showToastMessage(
        "Error fetching all patients:",
        TOAST_TYPE_OPTIONS.ERROR,
      );
      console.error("Error fetching all patients:", error);
    }
  };

  const toggleTableStatus = () => {
    setShowTable(!showTable);
    dispatch(clearActivePatient());
    setActiveTab("1");
    getPatientListData(paginationData);
    setEnableTabKeys(["1"]);
  };
  const tabs = [
    {
      key: "1",
      title: "Patient Details",
      content: <PatientDetails nextTab={handleTabChange} />,
    },
    {
      key: "2",
      title: "Contact Information",
      content: <PatientContactInfo nextTab={handleTabChange} />,
    },
    {
      key: "3",
      title: "Provider Information",
      content: <PatientProviderInfo nextTab={handleTabChange} />,
    },
    {
      key: "4",
      title: "Races and Ethnicity",
      content: <RaceAndEthnicity nextTab={toggleTableStatus} />,
    },
  ];

  useEffect(() => {
    (async () => {
      getPatientListData();
    })();
  }, []);

  const retrievePatientData = async (patientDetails: any) => {
    const patientInfo = await getPatientDetailsFromId(patientDetails.id);
    if (patientInfo && !patientInfo.id) {
      showToastMessage(patientInfo.message, TOAST_TYPE_OPTIONS.ERROR);
      return false;
    }

    const {
      contactInformation,
      providerInfo,
      raceEthnicityDTO,
      ...userDetails
    } = patientInfo;
    dispatch(setActivePatient(userDetails));
    if (contactInformation) {
      dispatch(setContactDetails(contactInformation));
    } else {
      dispatch(resetContactDetails());
    }
    if (providerInfo) {
      dispatch(setProviderDetails(providerInfo));
    } else {
      dispatch(resetProviderDetails(providerInfo));
    }
    if (raceEthnicityDTO) {
      dispatch(setRaceAndEthnicityDetails(raceEthnicityDTO));
    } else {
      dispatch(resetRaceAndEthnicityDetails(raceEthnicityDTO));
    }
    return true;
  };

  const getSelectedRow = () => async (row: any) => {
    Logger.logInfo("active Patient", { data: { patientDetails: row } });
    retrievePatientData(row);
  };
  const editRecord = () => async (row: any) => {
    Logger.logInfo("Editing Patient", { data: { patientDetails: row } });
    const response = await retrievePatientData(row);
    if (response) {
      setShowTable(false);
      dispatch(enablePatientEdit());
      setEnableTabKeys(["1", "2", "3", "4"]);
    }
  };

  const deletePatient = () => async (row: any) => {
    Logger.logInfo("Deleting Patient", { data: { patientDetails: row } });
    const deletePatientResponse = await deletingPatientInfo(row.id);
    if (deletePatientResponse.status) {
      Logger.logInfo("Patient deleted successfully", {
        data: { response: deletePatientResponse, patientDetails: row },
      });
      showToastMessage(
        `Patient : ${row.firstName} ${row.lastName} deleted `,
        TOAST_TYPE_OPTIONS.SUCCESS,
      );
    } else {
      Logger.logError("Error deleting patient", {
        data: { response: deletePatientResponse, patientDetails: row },
      });
      showToastMessage(deletePatientResponse.message, TOAST_TYPE_OPTIONS.ERROR);
    }
    await getPatientListData();
  };

  const updatePaginationData = (
    pageNo: number,
    pageSize: number = paginationData.pageSize,
  ) => {
    setPaginationData({ ...paginationData, pageNo, pageSize });
    getPatientListData({ ...paginationData, pageNo, pageSize });
  };

  const sortingFunction = (fieldName: string, sortingOrder: string) => {
    setPaginationData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
    getPatientListData({
      ...paginationData,
      sortingFieldName: fieldName,
      sortingOrder,
    });
  };

  const showTopSectionOfPage = () => (
    <div>
      {showTable ? (
        <div className="card-title d-flex justify-content-between align-items-center">
          <h5>Patient List</h5>
          <Button
            variant="primary"
            className="primary btn btn-primary d-flex align-items-center  py-1 float-end btn-sm px-5 ms-3"
            onClick={toggleTableStatus}
            disabled={viewPermissionOnlyFlag}
          >
            <i className="fa fa-plus me-2 fs-5" /> Add Patient
          </Button>
        </div>
      ) : (
        <Button
          variant="primary"
          className="primary btn btn-primary d-flex align-items-center  py-1 float-end btn-sm px-5 ms-3"
          onClick={toggleTableStatus}
          label="Back"
        />
      )}
    </div>
  );

  const initData = async () => {
    if (showTable) {
      await getPatientListData();
    }
    if (loginUserDetails) {
      const isOnlyViewPermission = isUserHaveOnlyViewPermission({
        permissions: loginUserDetails.userPermission,
        module: "PATIENT",
      });

      if (isOnlyViewPermission) {
        if (patientsList.length > 0) {
          retrievePatientData(patientsList?.[0]);
        }

        setViewPermissionOnlyFlag(true);
      }
    }
  };

  useEffect(() => {
    initData();
  }, [showTable]);

  return (
    <>
      <ProfileCard showErrorMessage={false} />

      <Card>
        {showTopSectionOfPage()}
        {showTable ? (
          <div className="table-responsive care-manager-table">
            <Table
              className="table table-bordered table-striped table-hover"
              data={patientsList}
              headers={headersForTable}
              onRowClick={getSelectedRow()}
              enableEditAction={editRecord()}
              enableDeleteAction={deletePatient()}
              hyperlinkField="chartNumber"
              sortingFunction={sortingFunction}
              sortingHeaderMap={sortingHeaderMap}
              viewPermissionOnlyFlag={viewPermissionOnlyFlag}
            />
            {paginationData.totalPages >= 0 && (
              <Pagination
                currentPage={paginationData.pageNo}
                totalPages={paginationData.totalPages}
                pageSize={paginationData.pageSize}
                onPageChange={(page, pageSize) => {
                  updatePaginationData(page, pageSize);
                }}
              />
            )}
          </div>
        ) : (
          <VerticalTabs
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            enableTabKeys={enableTabKeys}
          />
        )}
      </Card>
    </>
  );
}
